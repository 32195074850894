import { Link } from 'react-router-dom'

export default function Announcements() {
  return (
    <div className="flex flex-col items-center w-full">
      <div className="flex flex-col items-center justify-start text-left max-w-3xl pl-6 pr-6">
        <div className="w-full mt-24">
          <div className="text-3xl font-bold">Plural's first fund reaches $15m</div>
          <div className="text-sm mt-1 opacity-50">September 4, 2023</div>
        </div>
        <div className="text-base mt-12">
          We're happy to announce that Plural's inaugural fund has crossed $15M in commitments. We began with a simple
          vision to bring efficient, high-performing financial instruments to the world's top private-company builders.
          Today, we're proud to say that we've taken substantial steps towards realizing that vision.
          <br />
          <br />
          Our first fund is incredibly fortunate to gain exposure to many of our community's preeminent private
          companies, including SpaceX, Ramp, Anduril, Discord, and Canva. Each of these companies is charting a fresh
          path in its respective industry, and we're happy to be supporting their team members as they continue to
          remain focused and ambitious in pursuit of their goals.
          <br />
          <br />
          <div className="text-base font-bold mb-2">What's next?</div>
          We're excited to continue building Plural into a trusted wealth-creation mechanism for builders all over the
          world. If you're interested in joining Fund I before the December 31 close,{' '}
          <span className="underline">
            <Link to="/demo">talk to us</Link>
          </span>
          .
        </div>
      </div>
    </div>
  )
}
