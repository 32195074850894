import { Select } from '@radix-ui/themes'
import { useEffect, useState } from 'react'

export function MultiSelectCell({
  options,
  initiallySelected,
  onSelect,
  bgColor,
}: {
  options: string[]
  initiallySelected: string
  onSelect: (selected: string) => void
  bgColor?: string
}) {
  const [selected, setSelected] = useState(initiallySelected)

  useEffect(() => {
    setSelected(initiallySelected)
  }, [initiallySelected])

  return (
    <Select.Root
      value={selected}
      onValueChange={(value) => {
        setSelected(value)
        onSelect(value)
      }}>
      <Select.Trigger
        style={{
          boxShadow: 'none',
          width: '100%',
          cursor: 'pointer',
          outline: 'none',
          backgroundColor: bgColor,
        }}
      />
      <Select.Content>
        {options.map((option, i) => (
          <Select.Item value={option} key={`${option}-${i}`} className="pr-2">
            {option}
          </Select.Item>
        ))}
      </Select.Content>
    </Select.Root>
  )
}
