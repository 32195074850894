import { fundMultiple, lookbackV2TxMultiple } from "plural-shared/lookbackV2";
import { calculateCAGR } from "plural-shared/utils";

export function v2DealIrrforMultiple(
  companyMultiple: number,
  yearsToLiquidity: number,
  reserveRatio: number,
  fundMinCagr: number
): number {
  const companyCagrFromBase = calculateCAGR({
    returnMultiple: companyMultiple,
    years: yearsToLiquidity,
  });
  const txMult = lookbackV2TxMultiple(
    yearsToLiquidity,
    companyCagrFromBase,
    fundMinCagr,
    reserveRatio
  );
  const fundMult = fundMultiple(txMult, companyMultiple);
  const irr = calculateCAGR({
    returnMultiple: fundMult,
    years: yearsToLiquidity,
  });
  return irr;
}
