import { ColumnDef, createColumnHelper, FilterFnOption, SortingFnOption } from '@tanstack/react-table'
import EditableTanstackTable from 'components/editable-tanstack-table/EditableTanstackTable'
import { Button } from 'components/ui/button'
import { formatDuration, intervalToDuration } from 'date-fns'
import { ChevronDown, ChevronRight } from 'lucide-react'
import {
  numberToAbbreviatedDollarString,
  numberToReadableDollarString,
  numberToReadableString,
} from 'plural-shared/utils'
import { PluralLink } from 'providers/NavigationProvider'
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { CSVLink } from 'react-csv'
import { ArrayElement, isSome, Optional } from 'safety'
import { BasicButton } from '../components/BasicButton'
import { CompanyAvatar } from '../pages/admin/AdminCompanySheetPage'
import { RouterOutputs, trpc } from '../utils/trpc'
import { MassiveCompany, processRawMassiveCompany, RawMassiveCompany } from './admin/AdminCompanySheetPage'
import TwoLayerPieChart, { CustomTooltipProps } from './TwoLayerPieChart'
import { cn } from '../lib/utils'

type FundFile = RouterOutputs['fundFileRouter']['handleFundFileUpload'] | null
type FundPage = RouterOutputs['fundFileRouter']['processFundPage'] | null
type RouterCompanyRow = ArrayElement<RouterOutputs['fundFileRouter']['processFundPage']['companies']> & {
  companyData?: RawMassiveCompany[]
}
type ProcessedCompanyRow = ArrayElement<RouterOutputs['fundFileRouter']['processFundPage']['companies']> &
  Optional<MassiveCompany> & {
    timeFromMarkToLastFundingRound: string
    headcountGrowthSinceMark: number
  }

export type CsvDataByPage = { [pageNumber: number]: any }

function getTimeFromMarkToLastFundingRound(markDate: Date, lastFundingRoundDate: Date | undefined) {
  if (lastFundingRoundDate) {
    const suffix = markDate > lastFundingRoundDate ? ' before' : ' after'
    return (
      formatDuration(intervalToDuration({ start: markDate, end: lastFundingRoundDate }), {
        format: ['years', 'months'],
      }) + suffix
    )
  }
  return ''
}

export default function FundFileUploadPage() {
  const [fileEncodedString, setFileEncodedString] = useState('')
  const [filename, setFilename] = useState('')
  const [fullyLoaded, setFullyLoaded] = useState(true)
  const submitMutation = trpc.fundFileRouter.handleFundFileUpload.useMutation()
  const processPage = trpc.fundFileRouter.processFundPage.useMutation()
  const canPressComplete = !!fileEncodedString
  const [submitMessage, setSubmitMessage] = useState('')
  const [fileResponse, setFileResponse] = useState<FundFile>(null)
  const [responseData, setResponseData] = useState<FundPage>(null)
  const [chartData, setChartData] = useState<{
    innerData: { name: string; value: number; color: string }[]
    outerData: { name: string; value: number; color: string; share_class?: string }[]
  }>({ innerData: [], outerData: [] })
  const [atLeastOnePageLoaded, setAtLeastOnePageLoaded] = useState(false)

  const [fundInterestId, setFundInterestId] = useState<string>('');
  const fundDetailsQuery = trpc.fundFileRouter.getFundDetails.useQuery(fundInterestId ?? '', {
    enabled: fundInterestId !== ''
  });

  const [totalMismatch, setTotalMismatch] = useState<{ cost: number; value: number } | null>(null)
  const [csvDataByPage, setCsvDataByPage] = useState<CsvDataByPage>({})

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files?.[0]
    if (selectedFile) {
      const reader = new FileReader()
      reader.onloadend = () => {
        const base64String = reader.result as string
        setFileEncodedString(base64String)
      }
      reader.readAsDataURL(selectedFile)
      setFilename(selectedFile.name)
    }
  }

  const loadPages = useCallback(
    async (data: RouterOutputs['fundFileRouter']['handleFundFileUpload']) => {
      await Promise.all(
        data.pages.map(async (page, index) => {
          const pageData = await processPage.mutateAsync({
            fileEncodedString: fileEncodedString,
            pages: [page],
            total_investment_value: data.total_investment_value,
            fundInterestId: data.fundInterestId,
          })
          if (pageData.companies.length > 0) {
            setResponseData((prev) => ({
              ...prev!,
              companies: (prev?.companies ?? []).concat(pageData.companies),
              csvData: pageData.csvData ?? [],
            }));
            setCsvDataByPage((prev) => ({
              ...prev,
              [index + 1]: pageData.csvData,
            }))
            if (!atLeastOnePageLoaded) {
              setAtLeastOnePageLoaded(true)
            }
          }
        }),
      )
      setFullyLoaded(true)
    },
    [atLeastOnePageLoaded, fileEncodedString, processPage],
  )

  const loadExistingSnapshot = useCallback(
    async () => {
      if (fundDetailsQuery.data) {
        setResponseData({
          ...fundDetailsQuery.data,
          companies: fundDetailsQuery.data.companies ?? [],
          csvData: fundDetailsQuery.data.csvData ?? [],
        });
        setCsvDataByPage({ 1: fundDetailsQuery.data.csvData ?? [] });
        setAtLeastOnePageLoaded(true);
        setFullyLoaded(true);
      }
    },
    [fundDetailsQuery.data],
  )

  useEffect(() => {
    if (fundDetailsQuery.data) {
      loadExistingSnapshot();
    }
  }, [fundDetailsQuery.data, loadExistingSnapshot]);

  const onButtonClick = useCallback(() => {
    function submit() {
      setFullyLoaded(false)
      setAtLeastOnePageLoaded(false)
      if (fileEncodedString && filename) {
        submitMutation.mutate(
          { fileEncodedString: fileEncodedString, filename: filename },
          {
            onSuccess: (data) => {
              setFileResponse(data)
              if (data.alreadyExists) {
                setFundInterestId(data.fundInterestId)
                // Trigger loading of existing data
                loadExistingSnapshot()
              }
              else {
                loadPages(data)
                  .then()
                  .catch((e) => console.error('FAILED TO LOAD ALL PAGES', e))
              }
            },
            onError: (err) => {
              setFullyLoaded(true)
              setSubmitMessage(
                err.message.includes('duplicate')
                  ? 'This file has already been uploaded before.'
                  : "We couldn't process that file, but we're looking into it. Please try another file.",
              )
            },
          },
        )
      }
    }

    if (!canPressComplete) {
      return
    }
    setSubmitMessage('')
    setResponseData(null)
    submit()
  }, [canPressComplete, fileEncodedString, filename, submitMutation, loadExistingSnapshot, loadPages])

  const processChartData = (data: RouterCompanyRow[]) => {
    const colorMap: { [key: string]: [number, number, number] } = {}
    const generateColor = (baseColor: [number, number, number], totalChildren: number, level: number) => {
      const shadeIncrement = 1 / totalChildren
      const shadeFactor = 1 - shadeIncrement * level * 0.2
      return `rgba(${Math.round(baseColor[0] * shadeFactor)}, ${Math.round(baseColor[1] * shadeFactor)}, ${Math.round(baseColor[2] * shadeFactor)}, 1)`
    }

    const baseColors: [number, number, number][] = [
      [255, 99, 132], // red
      [54, 162, 235], // blue
      [255, 206, 86], // yellow
      [75, 192, 192], // green
      [153, 102, 255], // purple
      [255, 159, 64], // orange
    ]

    const groupedData = data.reduce((acc: any, item: RouterCompanyRow) => {
      if (!acc[item.company_legal_name]) {
        const baseColor = baseColors[Object.keys(acc).length % baseColors.length]
        colorMap[item.company_legal_name] = baseColor

        acc[item.company_legal_name] = {
          name: item.company_legal_name,
          value: item.value,
          cost: item.cost,
          children: item.subRows?.map((subRow) => ({
            name: `${subRow.company_legal_name} - ${subRow.share_class}`,
            value: subRow.value,
            cost: subRow.cost,
            share_class: subRow.share_class,
          })) ?? [
              {
                name: `${item.company_legal_name} - ${item.share_class}`,
                value: item.value,
                cost: item.cost,
                share_class: item.share_class,
              },
            ],
        }
      }
      return acc
    }, {})

    // inner circle is a solid color
    const innerData = Object.values(groupedData).map((company: any) => ({
      name: company.name,
      cost: company.cost,
      value: company.value,
      color: `rgba(${colorMap[company.name][0]}, ${colorMap[company.name][1]}, ${colorMap[company.name][2]}, 1)`,
    }))

    // outer circle is a solid color but darkened by the position (so 2nd slice is darker than first slice)
    const outerData = Object.values(groupedData).flatMap((company: any) => {
      return company.children.map((child: any, index: number) => ({
        ...child,
        color: generateColor(colorMap[company.name], company.children.length, index + 1),
      }))
    })

    return { innerData, outerData }
  }
  const cellClassName = 'max-w-full truncate p-2'
  const CustomTooltip: React.FC<CustomTooltipProps> = ({ payload, active }) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload
      return (
        <div
          style={{
            backgroundColor: 'white',
            border: '1px solid #ccc',
            padding: '10px',
            borderRadius: '4px',
            fontFamily: 'Arial, sans-serif',
            boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
          }}>
          <p
            style={{
              fontSize: '16px',
              fontWeight: 'bold',
              marginBottom: '5px',
            }}>
            {data.name}
          </p>

          <p
            style={{
              fontSize: '14px',
            }}>
            <span style={{ color: '#777' }}>{data.share_class ? 'Value: ' : 'Total Value at latest mark: '}</span>
            {numberToReadableDollarString(data.value)}
          </p>
          <p
            style={{
              fontSize: '14px',
            }}>
            <span style={{ color: '#777' }}>{data.share_class ? 'Cost: ' : 'Total Cost: '}</span>
            {numberToReadableDollarString(data.cost)}
          </p>
        </div>
      )
    }
    return null
  }

  function calculateHeadcountGrowthSinceMark(data: any[], markDate: string): number {
    const markDateObj = new Date(markDate)

    const sortedData = data.sort((a, b) => new Date(a.statsDate).getTime() - new Date(b.statsDate).getTime())
    const headCountAtMark = sortedData.find(
      (point) => new Date(point.statsDate).getDate() === markDateObj.getDate(),
    )?.count

    if (headCountAtMark === undefined) {
      return 0
    }
    const latestHeadCount = sortedData[sortedData.length - 1]?.count ?? 0
    const growthRate = (100 * (latestHeadCount - headCountAtMark)) / headCountAtMark
    return Math.round(growthRate * 100) / 100
  }

  function processCompanyRows(data: RouterCompanyRow[], date_of_mark: string): ProcessedCompanyRow[] {
    return data
      .map((row: RouterCompanyRow) => {
        let rawMassiveCompany = (row.companyData ?? [])[0]
        let company = {} as MassiveCompany
        let timeFromMarkToLastFundingRound = ''
        let headcountGrowthSinceMark = 0
        if (rawMassiveCompany && rawMassiveCompany.companyId) {
          company = processRawMassiveCompany(rawMassiveCompany)
          timeFromMarkToLastFundingRound = getTimeFromMarkToLastFundingRound(
            new Date(date_of_mark),
            company.lastFundingRoundClosedDate,
          )
          headcountGrowthSinceMark = calculateHeadcountGrowthSinceMark(company.allHeadcountStats, date_of_mark)
        }

        const entry_pps =
          row.entry_low_pps === row.entry_high_pps
            ? numberToReadableDollarString(row.entry_low_pps)
            : `${numberToReadableDollarString(row.entry_low_pps)} - ${numberToReadableDollarString(row.entry_high_pps)}`
        const marked_pps =
          row.marked_low_pps === row.marked_high_pps
            ? numberToReadableDollarString(row.marked_low_pps)
            : `${numberToReadableDollarString(row.marked_low_pps)} - ${numberToReadableDollarString(row.marked_high_pps)}`
        return {
          ...row,
          entry_pps: entry_pps,
          marked_pps: marked_pps,
          timeFromMarkToLastFundingRound: timeFromMarkToLastFundingRound,
          headcountGrowthSinceMark: headcountGrowthSinceMark,
          calculated_percent_of_total: isNaN(row.calculated_percent_of_total)
            ? row.calculated_percent_of_total
            : row.calculated_percent_of_total,
          shares_held: isNaN(parseInt(row.shares_held as unknown as string))
            ? row.shares_held
            : parseFloat(row.shares_held as unknown as string),
          ...company,
        } as unknown as ProcessedCompanyRow
      })
      .sort((company1, company2) => company2.value - company1.value)
  }

  const columnHelper = createColumnHelper<ProcessedCompanyRow>()

  useEffect(() => {
    if (responseData && responseData.companies && responseData.companies.length > 0) {
      const chartData = processChartData(responseData.companies)
      setChartData(chartData)
    }
  }, [responseData])

  const columns: ColumnDef<ProcessedCompanyRow, any>[] = useMemo(
    () => [
      columnHelper.accessor('company_legal_name', {
        id: 'company_legal_name',
        header: 'Company Legal Name',
        enableGlobalFilter: true,
        minSize: 200,
        meta: {
          className: 'z-30 sticky left-0 bg-white',
          headerClassName: 'z-40 sticky left-0 bg-white',
          isImmovable: true,
          isUnhideable: true,
        },
        filterFn: 'stringFn' as FilterFnOption<ProcessedCompanyRow>,
        size: 300,
        sortingFn: 'stringFn' as SortingFnOption<ProcessedCompanyRow>,
        // sortingFn: 'companySort' as SortingFnOption<ProcessedCompanyRow>,
        cell: (cell) => (
          <div
            className={`flex max-w-full items-center gap-3 truncate p-2`}
            style={{
              paddingLeft: cell.row.depth * 18 + 8,
            }}>
            <div className="w-20px item-center flex h-full shrink-0 justify-center">
              {cell.row.getCanExpand() ? (
                <button
                  {...{
                    onClick: cell.row.getToggleExpandedHandler(),
                    style: { cursor: 'pointer' },
                  }}>
                  {cell.row.getIsExpanded() ? (
                    <ChevronDown className="h-[20px] w-[20px]" />
                  ) : (
                    <ChevronRight className="h-[20px] w-[20px]" />
                  )}
                </button>
              ) : (
                <div className="w-[20px]"></div>
              )}
            </div>
            {/* only show avatar and name on parent row, and we know parent row if there's no sub rows */}
            <CompanyAvatar
              logo={cell.row.original.companyS3LogoUrl}
              alt={cell.row.original.company_legal_name}
              altLetter={cell.row.original.company_legal_name[0].toUpperCase()}
            />
            {cell.row.original.companyId ? (
              <PluralLink href={`/company/${cell.row.original.companyId}`}>{cell.getValue() as string}</PluralLink>
            ) : (
              <div>{cell.getValue() as string}</div>
            )}
          </div>
        ),
      }),
      columnHelper.accessor('share_class', {
        header: 'Share Class',
        id: 'share_class',
        size: 200,
        cell: (cell) => <div className="flex flex-row truncate pl-1">{cell.getValue()}</div>,
      }),
      columnHelper.accessor((row: ProcessedCompanyRow) => parseInt(row.shares_held as unknown as string), {
        header: 'Shares Held',
        id: 'shares_held',
        sortingFn: 'numberFn' as SortingFnOption<ProcessedCompanyRow>,
        filterFn: 'numberFn' as FilterFnOption<ProcessedCompanyRow>,
        size: 150,
        cell: (cell) => (
          <div className="flex flex-row pl-1">
            {cell.getValue() ? numberToReadableString(cell.getValue()) : undefined}
          </div>
        ),
      }),
      columnHelper.accessor((row: ProcessedCompanyRow) => row.marked_pps, {
        header: 'Marked PPS',
        id: 'marked_pps',
        sortingFn: 'numberFn' as SortingFnOption<ProcessedCompanyRow>,
        filterFn: 'numberFn' as FilterFnOption<ProcessedCompanyRow>,
        size: 150,
        cell: (cell) => (
          <div className="flex flex-row pl-1">
            {typeof cell.getValue() === 'string' ? cell.getValue() : numberToReadableDollarString(cell.getValue())}
          </div>
        ),
      }),
      // columnHelper.accessor('company_legal_name', {
      //   header: 'Company',
      //   id: 'company_legal_name',
      //   size: 200,
      //   cell: (cell) => <div className="flex flex-row truncate pl-1">{cell.getValue()}</div>,
      // }),
      columnHelper.accessor('value', {
        header: 'Value',
        id: 'value',
        sortingFn: 'numberFn' as SortingFnOption<ProcessedCompanyRow>,
        filterFn: 'numberFn' as FilterFnOption<ProcessedCompanyRow>,
        size: 150,
        cell: (cell) => <div className="flex flex-row pl-1">{numberToReadableDollarString(cell.getValue())}</div>,
      }),
      columnHelper.accessor('calculated_percent_of_total', {
        header: 'Percent of Total',
        id: 'calculated_percent_of_total',
        sortingFn: 'numberFn' as SortingFnOption<ProcessedCompanyRow>,
        size: 150,
        cell: (cell) => <div className="flex flex-row pl-1">{cell.getValue()}%</div>,
      }),
      columnHelper.accessor('latestHeadcount', {
        id: 'latestHeadcount',
        header: 'Latest Headcount',
        size: 150,
        filterFn: 'numberFn' as FilterFnOption<ProcessedCompanyRow>,
        sortingFn: 'numberFn' as SortingFnOption<ProcessedCompanyRow>,
        cell: (cell) => (
          <div className={cellClassName}>
            {cell.getValue() ? numberToReadableString(cell.getValue() as number) : undefined}
          </div>
        ),
      }) as ColumnDef<ProcessedCompanyRow>,
      columnHelper.accessor('oneYearOverallHeadcountGrowthRate', {
        header: '1 Year Headcount Growth',
        id: 'oneYearOverallHeadcountGrowthRate',
        size: 180,
        sortingFn: 'numberFn' as SortingFnOption<ProcessedCompanyRow>,
        filterFn: 'numberFn' as FilterFnOption<ProcessedCompanyRow>,

        cell: (cell) => (
          <div
            className={`max-w-full truncate p-2 ${((cell.getValue() as number | undefined) ?? 0) > 0 ? 'text-green-500' : 'text-red-500'}`}>
            {cell.getValue() as number} {cell.getValue() ? '%' : undefined}
          </div>
        ),
        meta: {
          filterEndAdornment: <div className="mr-4">%</div>,
        },
      }) as ColumnDef<ProcessedCompanyRow>,
      columnHelper.accessor('headcountGrowthSinceMark', {
        header: 'Headcount Growth Since Mark',
        id: 'headcountGrowthSinceMark',
        size: 210,
        sortingFn: 'numberFn' as SortingFnOption<ProcessedCompanyRow>,
        filterFn: 'numberFn' as FilterFnOption<ProcessedCompanyRow>,

        cell: (cell) => (
          <div
            className={`max-w-full truncate p-2 ${((cell.getValue() as number | undefined) ?? 0) > 0 ? 'text-green-500' : 'text-red-500'}`}>
            {cell.getValue() !== 0 && (cell.getValue() as number)}{' '}
            {cell.getValue() !== 0 && cell.getValue() ? '%' : undefined}
          </div>
        ),
        meta: {
          filterEndAdornment: <div className="mr-4">%</div>,
        },
      }) as ColumnDef<ProcessedCompanyRow>,
      columnHelper.accessor('lastFundingRoundClosedDate', {
        header: 'Latest Funding Round',
        id: 'lastFundingRoundClosedDate',
        size: 170,
        sortingFn: 'dateFn' as SortingFnOption<ProcessedCompanyRow>,
        filterFn: 'dateFn' as FilterFnOption<ProcessedCompanyRow>,
        cell: (cell) => (
          <div className={cellClassName}>
            {isSome(cell.getValue()) ? new Date(cell.getValue() as Date).toLocaleDateString() : ''}
          </div>
        ),
      }) as ColumnDef<ProcessedCompanyRow>,
      columnHelper.accessor('timeFromMarkToLastFundingRound', {
        header: 'Last Raised Since Mark',
        id: 'timeFromMarkToLastFundingRound',
        // todo: this should be dateFn, but we need to pass in a date and not string
        // sortingFn: 'numberFn' as SortingFnOption<ProcessedCompanyRow>,
        // filterFn: 'numberFn' as FilterFnOption<ProcessedCompanyRow>,
        size: 180,
        cell: (cell) => <div className="flex flex-row pl-1">{cell.getValue()}</div>,
      }),
      columnHelper.accessor('lastFundingRoundAmount', {
        header: 'Latest Funding Round Amount',
        id: 'lastFundingRoundAmount',
        size: 220,
        sortingFn: 'numberFn' as SortingFnOption<ProcessedCompanyRow>,
        filterFn: 'numberFn' as FilterFnOption<ProcessedCompanyRow>,
        cell: (cell) => (
          <div className={cellClassName}>
            {cell.getValue() ? numberToAbbreviatedDollarString(cell.getValue() as number) : ''}
          </div>
        ),
      }) as ColumnDef<ProcessedCompanyRow>,
      // columnHelper.accessor('sector', {
      //   header: 'Sector',
      //   id: 'sector',
      //   size: 200,
      //   cell: (cell) => <div className="flex flex-row pl-1">{cell.getValue()}</div>,
      // }),
      columnHelper.accessor((row: ProcessedCompanyRow) => row.gain_loss, {
        header: 'Gain/Loss',
        id: 'gain_loss',
        sortingFn: 'numberFn' as SortingFnOption<ProcessedCompanyRow>,
        filterFn: 'numberFn' as FilterFnOption<ProcessedCompanyRow>,
        size: 150,
        cell: (cell) => (
          <div
            className={`flex flex-row pl-1 ${cell.getValue() > 0 ? 'text-green-500' : cell.getValue() === 0 ? '' : 'text-red-500'}`}>
            {cell.getValue() === 0
              ? ''
              : cell.getValue() > 0
                ? numberToReadableDollarString(cell.getValue())
                : `(${numberToReadableDollarString(Math.abs(cell.getValue()))})`}
          </div>
        ),
      }),
      columnHelper.accessor((row: ProcessedCompanyRow) => row.percent_gain_loss, {
        header: '% Gain/Loss',
        id: 'percent_gain_loss',
        sortingFn: 'numberFn' as SortingFnOption<ProcessedCompanyRow>,
        filterFn: 'numberFn' as FilterFnOption<ProcessedCompanyRow>,
        size: 150,
        cell: (cell) => (
          <div
            className={`flex flex-row pl-1 ${cell.getValue() > 0 ? 'text-green-500' : cell.getValue() === 0 ? '' : 'text-red-500'}`}>
            {cell.getValue() === null || cell.getValue() === undefined || cell.getValue() === 0
              ? ''
              : `${numberToReadableString(cell.getValue())}%`}
          </div>
        ),
      }),
      columnHelper.accessor((row: ProcessedCompanyRow) => row.moic, {
        header: 'MOIC',
        id: 'moic',
        sortingFn: 'numberFn' as SortingFnOption<ProcessedCompanyRow>,
        filterFn: 'numberFn' as FilterFnOption<ProcessedCompanyRow>,
        size: 150,
        cell: (cell) => <div className="flex flex-row truncate pl-1">{cell.getValue()}</div>,
      }),
      columnHelper.accessor('cost', {
        header: 'Cost',
        id: 'cost',
        sortingFn: 'numberFn' as SortingFnOption<ProcessedCompanyRow>,
        filterFn: 'numberFn' as FilterFnOption<ProcessedCompanyRow>,
        size: 150,
        cell: (cell) => <div className="flex flex-row pl-1">{numberToReadableDollarString(cell.getValue())}</div>,
      }),
      columnHelper.accessor((row: ProcessedCompanyRow) => row.entry_pps, {
        header: 'Entry PPS',
        id: 'entry_pps',
        sortingFn: 'numberFn' as SortingFnOption<ProcessedCompanyRow>,
        filterFn: 'numberFn' as FilterFnOption<ProcessedCompanyRow>,
        size: 150,
        cell: (cell) => (
          <div className="flex flex-row pl-1">
            {typeof cell.getValue() === 'string' ? cell.getValue() : numberToReadableDollarString(cell.getValue())}
          </div>
        ),
      }),
      //
      //
      // columnHelper.accessor('companyDomain', {
      // id: 'companyDomain',
      // header: 'Domain',
      // enableGlobalFilter: true,
      // filterFn: 'stringFn' as FilterFnOption<ProcessedCompanyRow>,
      // size: 300,
      // sortingFn: 'stringFn' as SortingFnOption<ProcessedCompanyRow>,
      // cell: (cell) => <div className={cellClassName}>{cell.getValue() as string}</div>,
      // }) as ColumnDef<ProcessedCompanyRow>,
      // columnHelper.accessor('foundingDate', {
      // id: 'foundingDate',
      // header: 'Founding Date',
      // sortingFn: 'dateFn' as SortingFnOption<ProcessedCompanyRow>,
      // filterFn: 'dateFn' as FilterFnOption<ProcessedCompanyRow>,
      // size: 300,
      // cell: (cell) => (
      // <div className={cellClassName}>
      // {cell.getValue() ? new Date(cell.getValue() as string).toLocaleDateString() : undefined}
      // </div>
      // ),
      // }) as ColumnDef<ProcessedCompanyRow>,
      // columnHelper.accessor('latestOpenRoleCount', {
      // id: 'latestOpenRoleCount',
      // header: 'Latest Open Role Count',
      // size: 300,
      // sortingFn: 'numberFn' as SortingFnOption<ProcessedCompanyRow>,
      // filterFn: 'numberFn' as FilterFnOption<ProcessedCompanyRow>,
      // cell: (cell) => <div className={cellClassName}>{numberToReadableString(cell.getValue() as number)}</div>,
      // }) as ColumnDef<ProcessedCompanyRow>,
      //
      //
      // columnHelper.accessor('location', {
      // id: 'location',
      // header: 'Location',
      // size: 300,
      // filterFn: 'stringFn' as FilterFnOption<ProcessedCompanyRow>,
      // sortingFn: 'stringFn' as SortingFnOption<ProcessedCompanyRow>,
      // cell: (cell) => <div className={cellClassName}>{cell.getValue() as string}</div>,
      // }) as ColumnDef<ProcessedCompanyRow>,
      // columnHelper.accessor('cbCompanyDescription', {
      // id: 'cbCompanyDescription',
      // header: 'Company Description',
      // minSize: 400,
      // filterFn: 'stringFn' as FilterFnOption<ProcessedCompanyRow>,
      // size: 1000,
      // maxSize: 3000,
      // sortingFn: 'stringFn' as SortingFnOption<ProcessedCompanyRow>,
      // cell: (cell) => <div className={cellClassName}>{cell.getValue() as string}</div>,
      // }) as ColumnDef<ProcessedCompanyRow>,
      // columnHelper.accessor('sixMonthsOverallHeadcountValue', {
      // header: '6 Months Overall Headcount',
      // id: 'sixMonthsOverallHeadcountValue',
      // size: 300,
      // sortingFn: 'numberFn' as SortingFnOption<ProcessedCompanyRow>,
      // filterFn: 'numberFn' as FilterFnOption<ProcessedCompanyRow>,
      // cell: (cell) => <div className={cellClassName}>{numberToReadableString(cell.getValue() as number)}</div>,
      // }) as ColumnDef<ProcessedCompanyRow>,
      //
      // columnHelper.accessor('oneYearOverallHeadcountValue', {
      // header: '1 Year Overall Headcount',
      // id: 'oneYearOverallHeadcountValue',
      // size: 300,
      // sortingFn: 'numberFn' as SortingFnOption<ProcessedCompanyRow>,
      // filterFn: 'numberFn' as FilterFnOption<ProcessedCompanyRow>,
      // cell: (cell) => <div className={cellClassName}>{numberToReadableString(cell.getValue() as number)}</div>,
      // }) as ColumnDef<ProcessedCompanyRow>,
      //
      // columnHelper.accessor('twoYearsOverallHeadcountValue', {
      // header: '2 Years Overall Headcount',
      // id: 'twoYearsOverallHeadcountValue',
      // size: 300,
      // sortingFn: 'numberFn' as SortingFnOption<ProcessedCompanyRow>,
      // filterFn: 'numberFn' as FilterFnOption<ProcessedCompanyRow>,
      // cell: (cell) => <div className={cellClassName}>{numberToReadableString(cell.getValue() as number)}</div>,
      // }) as ColumnDef<ProcessedCompanyRow>,
      // columnHelper.accessor('twoYearsOverallHeadcountGrowthRate', {
      // header: '2 Years Headcount Growth Rate',
      // id: 'twoYearsOverallHeadcountGrowthRate',
      // size: 300,
      // sortingFn: 'numberFn' as SortingFnOption<ProcessedCompanyRow>,
      // filterFn: 'numberFn' as FilterFnOption<ProcessedCompanyRow>,
      //
      // cell: (cell) => (
      // <div
      // className={`max-w-full truncate p-2 ${((cell.getValue() as number | undefined) ?? 0) > 0 ? 'text-green-500' : 'text-red-500'}`}>
      // {cell.getValue() as number} {cell.getValue() ? '%' : undefined}
      // </div>
      // ),
      // meta: {
      // filterEndAdornment: <div className="mr-4">%</div>,
      // },
      // }) as ColumnDef<ProcessedCompanyRow>,

      // columnHelper.accessor('lastFundingRoundClosedDate', {
      //   header: 'Latest Funding Round Closed Date',
      //   id: 'lastFundingRoundClosedDate',
      //   size: 300,
      //   sortingFn: 'dateFn' as SortingFnOption<ProcessedCompanyRow>,
      //   filterFn: 'dateFn' as FilterFnOption<ProcessedCompanyRow>,
      //   cell: (cell) => (
      //     <div className={cellClassName}>
      //       {cell.getValue() ? new Date(cell.getValue() as Date).toLocaleDateString() : ''}
      //     </div>
      //   ),
      // }) as ColumnDef<ProcessedCompanyRow>,
    ],
    [columnHelper],
  )

  let processedResponseData = useMemo(
    () => processCompanyRows(responseData?.companies ?? [], fileResponse?.date_of_mark ?? fundDetailsQuery.data?.date_of_mark ?? ''),
    [responseData, fileResponse, fundDetailsQuery.data]
  )

  const calculateTotals = useCallback(() => {
    if (responseData?.companies && fileResponse) {
      const totalCost = responseData.companies.reduce((sum, company) => sum + company.cost, 0)
      const totalValue = responseData.companies.reduce((sum, company) => sum + company.value, 0)

      const costDifference = Math.abs(totalCost - fileResponse.total_invested)
      const valueDifference = Math.abs(totalValue - fileResponse.total_investment_value)

      if (costDifference > 0.01 || valueDifference > 0.01) {
        setTotalMismatch({ cost: costDifference, value: valueDifference })
      } else {
        setTotalMismatch(null)
      }
    }
  }, [responseData, fileResponse])

  useEffect(() => {
    if (fullyLoaded) {
      calculateTotals()
    }
  }, [fullyLoaded, calculateTotals])

  const orderedCsvData = useMemo(() => {
    const pageNumbers = Object.keys(csvDataByPage).map(Number).sort((a, b) => a - b)
    return pageNumbers.flatMap(pageNumber => csvDataByPage[pageNumber])
  }, [csvDataByPage])

  return (
    <div className="relative">
      <div className="ml-6 mr-6 mt-6 flex flex-col items-center">
        <div className="w-full max-w-page-max text-base">
          <div className="flex w-full flex-col items-center">
            <div className="w-full max-w-form-width">
              <div className="text-3xl font-semibold">Extract fund marks</div>
              <div className="mt-4 w-full text-base">
                <div className="mb-2 mt-4 text-sm font-medium">File</div>
                <input type="file" accept=".pdf" required onChange={handleFileChange} />
              </div>
              <div className="h-5" />
              <BasicButton
                onClick={onButtonClick}
                textSize="text-base"
                textHeight={20}
                className="w-full"
                isPressable={fullyLoaded && canPressComplete}>
                Submit
              </BasicButton>
              <div className="mt-9 w-full text-base">{submitMessage && submitMessage}</div>
            </div>
          </div>
        </div>
        <div className="absolute flex flex-col self-end" style={{ top: 200 }}>
          <div className="mr-[5%] self-end pt-2 text-2xl font-semibold">
            {fileResponse && numberToReadableDollarString(fileResponse.total_investment_value)}
          </div>
          <div className="mr-[5%] self-end text-xs font-semibold">{fileResponse && 'Total portfolio value'}</div>
          <div className="mr-[5%] self-end pt-2 text-2xl font-semibold">
            {fileResponse && numberToReadableDollarString(fileResponse.total_invested)}
          </div>
          <div className="mr-[5%] self-end text-xs font-semibold">{fileResponse && 'Total invested'}</div>
          <div className="mr-[5%] self-end text-xs font-semibold">
            {fileResponse &&
              new Intl.DateTimeFormat('en-US', { dateStyle: 'long' }).format(new Date(fileResponse.date_of_mark))}
          </div>
          <div className="mr-[5%] self-end pt-2"></div>
        </div>
        {(submitMutation.isLoading || !fullyLoaded) && !atLeastOnePageLoaded && <PieLoadingIndicator />}
        {responseData && chartData.innerData.length > 0 && chartData.outerData.length > 0 ? (
          <>
            <TwoLayerPieChart chartData={chartData} customTooltip={CustomTooltip} />
            {fullyLoaded && (
              <CSVLink className="pt-4" filename={`processed_${filename}.csv`} data={orderedCsvData}>
                <Button className="pt-2">Download</Button>
              </CSVLink>
            )}
          </>
        ) : null}
        {atLeastOnePageLoaded && !fullyLoaded && (
          <div className="text-semibold animate-pulse py-4 text-lg">Still processing...</div>
        )}
        {fullyLoaded && totalMismatch && (
          <div className={cn(
            "mt-4 p-4 rounded-md",
            "bg-yellow-100 border border-yellow-400 text-yellow-800"
          )}>
            <p className="font-semibold">Warning: Amount Mismatch</p>
            {totalMismatch.cost > 0 && <p>Total Investment Cost is off by: {numberToReadableDollarString(totalMismatch.cost)}</p>}
            {totalMismatch.value > 0 && <p>Total Investment Value is off by: {numberToReadableDollarString(totalMismatch.value)}</p>}
            <p>We are looking into it.</p>
          </div>
        )}
        {(((submitMutation.isLoading || !fullyLoaded) && !atLeastOnePageLoaded) || responseData) && (
          <div className="w-full max-w-full">
            <EditableTanstackTable
              borders="top"
              defaultSorting={{ id: 'company', desc: true }}
              stickyHeader
              stickyColumn
              fullPage
              data={processedResponseData ?? []}
              sortingFns={{}}
              filterFns={{}}
              columns={columns}
              expandedByDefault={false}
              isLoading={!atLeastOnePageLoaded}
            />
          </div>
        )}
      </div>
    </div>
  )
}

const PieLoadingIndicator = () => {
  return (
    <div className="flex animate-pulse flex-col items-center justify-center">
      <div className="h-[500px] w-[500px] animate-pulse rounded-full border-[48px] border-[#e2e2e2]"></div>
      <div className="mt-8">Loading pie chart..</div>
    </div>
  )
};