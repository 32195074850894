import { useLocation, useNavigate } from 'react-router-dom'
import { BasicButton } from '../components/BasicButton'
import { usePluralAuth } from '../providers/PluralAuthProvider'
import { useEffect, useState } from 'react'
import { trpc } from '../utils/trpc'
import { isSome } from 'safety'
import { setHasViewedAllowlistNotice } from '../utils/setHasViewedAllowlistNotice'
import { AuthStatus } from '../utils/authStatus'

export default function NoAllowlist() {
  const { user: pluralUser, possiblyRejectedUserEmail, authStatus } = usePluralAuth()
  const location = useLocation()
  const navigate = useNavigate()
  const returnTo = location.state?.returnTo || '/'
  const mutation = trpc.user.sendAllowlistReminderMessage.useMutation({
    onSuccess: () => {
      setDidSendMsg(true)
    },
  })
  const [didSendMsg, setDidSendMsg] = useState(false)

  const sendMessage = () => {
    if (isSome(possiblyRejectedUserEmail)) {
      mutation.mutate({
        userEmail: possiblyRejectedUserEmail,
      })
    }
  }

  useEffect(() => {
    setHasViewedAllowlistNotice(true)
  }, [])

  useEffect(() => {
    if (authStatus === AuthStatus.None || authStatus === AuthStatus.Allowed) {
      navigate(returnTo)
    }
  }, [navigate, returnTo, authStatus])

  function returnToPreviousPage() {
    navigate(returnTo)
  }

  return (
    <div className="flex flex-col items-center ml-6 mr-6">
      <div className="max-w-page-skinny w-full ">
        <div className="text-4xl mt-24 font-semibold">LP List</div>
        <div className="h-12" />
        <div className="text-base">
          Your email—{possiblyRejectedUserEmail}—is not included in our list of LPs. <br />
          <br />
          If this is a mistake, click the button below to send us a message, and we'll follow up.
        </div>
        {didSendMsg ? (
          <div className="mt-3">
            Thanks!{' '}
            <button className="mt-3 text-base underline font-medium" onClick={returnToPreviousPage}>
              Return to previous page
            </button>
          </div>
        ) : (
          <div>
            <BasicButton onClick={sendMessage} className="w-full mt-9" isLoading={mutation.isLoading}>
              Send Message
            </BasicButton>
            <button className="mt-3 text-sm underline opacity-80" onClick={returnToPreviousPage}>
              Return to previous page
            </button>
          </div>
        )}
      </div>
    </div>
  )
}
