import { useCallback, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { ReactComponent as AppIcon } from './images/pluralLogos/app-wordmark-light.svg'
import { ReactComponent as PluralBulb } from './images/pluralLogos/plural-bulb.svg'
import LandingFooter from './landingPage/LandingFooter'
import Team from './landingPage/Team'
import { trpc } from './utils/trpc'

export function TomorrowsUpside() {
  return (
    <div className="h-[400px] w-full max-w-[450px] rounded-4xl bg-black p-8 text-left text-4xl font-semibold text-white lg:p-12">
      <div className="max-w-[300px]">Get cash today. Keep tomorrow's upside.</div>
    </div>
  )
}

export function EmailCaptureBlock() {
  const [email, setEmail] = useState('')
  const navigate = useNavigate()
  const buttonIsPressable = email.length > 0 && email.includes('@')
  const submitMutation = trpc.interestGathering.logEmailInterestSubmission.useMutation()
  const buttonTextOpacity = buttonIsPressable ? 'opacity-100' : 'opacity-80'

  const onClick = useCallback(() => {
    if (!buttonIsPressable) {
      return
    }
    submitMutation.mutate({
      userEmail: email,
      elementName: 'tomorrows-upside',
      pathName: window.location.pathname,
    })
    navigate('/get-started?email=' + email)
  }, [buttonIsPressable, email, navigate, submitMutation])

  return (
    <div className="h-[400px] w-full max-w-[450px] rounded-4xl bg-[#F5F5F5] p-8 text-left text-xl lg:p-12">
      <div className="flex h-full flex-col items-start">
        <PluralBulb className="h-[52px] w-[52px]" />
        <div className="flex-grow" />
        {/* <div className="flex w-full flex-col space-y-4 text-lg font-medium">
          <input
            placeholder="What's your email?"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="h-15 rounded-xl border bg-white pl-4 pr-3 placeholder-[rgba(0,0,0,0.5)] outline-none"
          />
          <button
            className={`h-15 rounded-xl bg-[#F16241] pl-7 pr-7  text-white ${
              buttonIsPressable ? '' : 'pointer-events-none'
            }`}
            onClick={onClick}>
            <div className={`${buttonTextOpacity}`}>Get Started</div>
          </button>
        </div> */}
      </div>
    </div>
  )
}
function LandingBanner() {
  return (
    <div className="h-12 w-full bg-[#EBEBEB] pb-3 pl-4 pr-4 pt-3 text-center text-sm text-black">
      👋 Plural's first fund crosses $15m—
      <span className="underline">
        <Link to="/announcements">allocations available until December 31</Link>
      </span>
    </div>
  )
}

export default function LandingPage() {
  return (
    <div className="flex max-w-full flex-col items-center justify-start text-center">
      <div className="w-full">
        {/* <LandingBanner /> */}
        {/* <Hero /> */}
        <div className="mb-50 flex w-full justify-center">
          <AppIcon
            style={{
              width: '800px',
              height: '800px',
            }}
          />
        </div>
        {/* <div className="bg-black">
          <PropStack />
        </div> */}
        {/* <div className="mb-36 mt-36 flex w-full flex-col items-center pl-6 pr-6">
          <div className="max-w-3xl text-2xl font-medium">
            Trusted by the most sophisticated operators, GPs, and LPs in the industry. Check out our{' '}
            <a href="#teamLanding" className="text-[#F16241] underline">
              team.
            </a>
          </div>
          <div className="mt-12">
            <TeamLogoRow className="w-full" />
          </div>
        </div> */}
        {/* <PremierStartups /> */}
        {/* <div className="mt-48 flex w-full flex-col items-center justify-center space-y-10 pl-6 pr-6 lg:flex-row lg:space-x-10 lg:space-y-0">
          <TomorrowsUpside />
          <EmailCaptureBlock />
        </div> */}
        <div
          id="teamLanding"
          // className="pt-48"
          className="pt-10">
          <Team />
        </div>
        {/* <div className="pb-36 pt-36">
          <FAQs />
        </div> */}
        <div className="flex w-full flex-col items-center bg-black">
          <LandingFooter />
        </div>
      </div>
    </div>
  )
}
