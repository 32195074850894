import { IonItem, IonSpinner } from '@ionic/react'
import { AnyColumnProps } from 'components/editable-table/ColumnProps'
import {
  BigIntDollarsCellFormat,
  DateCellFormat,
  StringCellFormat,
} from 'components/editable-table/EditableTextCellFormat'
import ErrorAlertModal from 'components/ErrorAlertModal'
import { useState } from 'react'
import { ArrayElement, isSome, Optional } from 'safety'
import { EditableTable } from '../../components/editable-table/EditableTable'
import { useVanillaTRPC } from '../../providers/TRPCProvider'
import { RouterOutputs, trpc } from '../../utils/trpc'
import { useCompanySelectorContext } from './CompanySelectorContextProvider'
import { useCompanySelectorContext as useEnrichedCompanySelectorContext } from './massiveEnrichedCompany/MassiveEnrichedCompanySelectorContextProvider'

type RouterDebtEvent = ArrayElement<RouterOutputs['debtEvents']['getDebtEventsByCompanyIdQuery']>

enum ColumnHeaders {
  Date = 'Date',
  CashAmount = 'Cash Amount',
  Source = 'Source',
  Type = 'Type',
  UpdatedBy = 'Updated By',
  UpdatedAt = 'Updated At',
}

const DebtEventTypes = ['STRUCTURED_DEBT', 'LOAN', 'CREDIT_FACILITY', 'OTHER_FACILITY']

type CreatedObject = {
  [ColumnHeaders.Date]: Date
  [ColumnHeaders.Type]: string
  [ColumnHeaders.Source]: string
  [ColumnHeaders.CashAmount]: number
}

export function CompanyDebtEventsTable() {
  let { selectedCompany } = useCompanySelectorContext()
  const { selectedBaseCompany } = useEnrichedCompanySelectorContext()
  if (selectedBaseCompany.value) {
    selectedCompany = selectedBaseCompany
  }
  const [error, setError] = useState<{ title: string; body: string } | undefined>(undefined)
  const vanillaClient = useVanillaTRPC()
  const debtEventsQuery = trpc.debtEvents.getDebtEventsByCompanyIdQuery.useQuery(selectedCompany.value?.id ?? 0, {
    enabled: isSome(selectedCompany.value),
  })

  const columns: AnyColumnProps<RouterDebtEvent, CreatedObject>[] = [
    {
      header: ColumnHeaders.Date,
      inputType: 'input',
      textCellFormat: DateCellFormat,
      updateRemote: async (row: RouterDebtEvent, newValue: Optional<Date>) => {
        await vanillaClient.debtEvents.updateDebtEventMutation.mutate({
          id: row.id,
          data: {
            date: newValue ? newValue.toISOString() : undefined,
          },
        })
        debtEventsQuery.refetch()
      },
      minWidthPixels: 100,
      accessor: (row) => new Date(row.date),
      placeholder: '00/00/0000',
      createProps: {
        isRequired: true,
        createdObjectKey: ColumnHeaders.Date,
      },
    },

    {
      header: ColumnHeaders.CashAmount,
      inputType: 'input',
      textCellFormat: BigIntDollarsCellFormat,
      accessor: (row) => row.cashAmount,
      updateRemote: async (row: RouterDebtEvent, newValue: Optional<number>) => {
        await vanillaClient.debtEvents.updateDebtEventMutation.mutate({
          id: row.id,
          data: {
            cashAmount: newValue ?? undefined,
          },
        })
        debtEventsQuery.refetch()
      },
      placeholder: 'Cash Amount',
      createProps: {
        isRequired: false,
        createdObjectKey: ColumnHeaders.CashAmount,
      },
    },

    {
      header: ColumnHeaders.Type,
      inputType: 'multiSelect',
      options: DebtEventTypes.map((type) => type.toLowerCase()),
      accessor: (row) => row.debtType.toLowerCase(),
      updateRemote: async (row: RouterDebtEvent, newValue: Optional<string>) => {
        await vanillaClient.debtEvents.updateDebtEventMutation.mutate({
          data: {
            debtType: newValue ? newValue.toUpperCase() : undefined,
          },
          id: row.id,
        })
        debtEventsQuery.refetch()
      },
      minWidthPixels: 110,
      createProps: {
        defaultOption: 'Equity',
        isRequired: false,
        createdObjectKey: ColumnHeaders.Type,
      },
    },
    {
      header: ColumnHeaders.Source,
      inputType: 'input',
      textCellFormat: StringCellFormat,
      updateRemote: async (row: RouterDebtEvent, newValue: Optional<string>) => {
        await vanillaClient.debtEvents.updateDebtEventMutation.mutate({
          data: { source: newValue ? newValue : undefined },
          id: row.id,
        })
        debtEventsQuery.refetch()
      },
      accessor: (row) => row.source,
      placeholder: 'source',
      createProps: {
        isRequired: false,
        createdObjectKey: ColumnHeaders.Source,
      },
    },
    {
      header: ColumnHeaders.UpdatedBy,
      inputType: 'input',
      textCellFormat: StringCellFormat,
      accessor: (row) => row.updatedByUser?.email ?? '',
    },
    {
      header: ColumnHeaders.UpdatedAt,
      inputType: 'input',
      textCellFormat: DateCellFormat,
      accessor: (row) => (row.updatedAt ? new Date(row.updatedAt) : null),
    },
  ]

  return (
    <div className="w-full">
      {error && (
        <ErrorAlertModal
          title={error.title}
          body={error.body}
          open
          onClose={() => {
            setError(undefined)
          }}
        />
      )}
      <div className=" w-full">
        {debtEventsQuery.isLoading ? (
          <div className="loader mt-6 flex w-full flex-col items-center">
            <IonItem>
              <IonSpinner name="lines-sharp"></IonSpinner>
            </IonItem>
          </div>
        ) : (
          <div className="w-full">
            <EditableTable<RouterDebtEvent, CreatedObject>
              onRowCreate={async (createdObject: CreatedObject) => {
                await vanillaClient.debtEvents.createDebtEventMutation.mutate({
                  date: createdObject[ColumnHeaders.Date].toISOString(),
                  debtType: createdObject[ColumnHeaders.Type].toUpperCase(),
                  companyId: selectedCompany.value?.id ?? 0,
                  cashAmount: createdObject[ColumnHeaders.CashAmount],
                  source: createdObject[ColumnHeaders.Source],
                })
                debtEventsQuery.refetch()
              }}
              columns={columns}
              rowData={((debtEventsQuery.data ?? []) as RouterDebtEvent[]).sort(
                (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime(),
              )}
              stickyColumn={true}
              deleteProps={{
                onDelete: async (rowIndex) => {
                  let id = (debtEventsQuery.data as RouterDebtEvent[])[rowIndex].id

                  await vanillaClient.debtEvents.deleteDebtEventQuery.query(id)
                  debtEventsQuery.refetch()
                },
                confirmationModalProps: {
                  title: 'Are you sure you wish to delete this debt event?',
                  body: 'This action is permanent and may impact the offer calculator.',
                },
              }}
            />
          </div>
        )}
      </div>
    </div>
  )
}
