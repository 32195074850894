import { StateObject, makeStateObject } from 'plural-shared/utils'
import { createContext, useContext, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ArrayElement } from 'safety'
import { RouterOutputs, trpc } from '../../utils/trpc'

/*

stuff to add

- marks
- financing data
- positions that the fund has
- let's start with financing data

the other thing I could do is reorganize the revenue and stuff
let's do financing data first

the problem with all this forge data is we don't have a great way to sync it up
I mean we can probably just put it directly into the db 
j

*/

type Company = ArrayElement<RouterOutputs['companies']['allForSelector']>

type CompanyDataContext = {
  companyId: StateObject<number | undefined>
  selectedCompany: StateObject<Company | null>
  didSelectCompany: (company: Company) => void
  companies: Company[]
  isLoadingCompanies: boolean
  projectionIncrement: StateObject<number>
  refetch: () => void
}

const CompanySelectorContext = createContext<CompanyDataContext>({
  companyId: {
    value: undefined,
    setValue: () => {},
  },
  companies: [],
  isLoadingCompanies: false,
  didSelectCompany: () => {},
  selectedCompany: {
    value: null,
    setValue: () => {},
  },
  projectionIncrement: {
    value: 0,
    setValue: () => {},
  },
  refetch: () => {},
})

export function CompanySelectorContextProvider({ children }: { children: React.ReactNode }) {
  let params = useParams<{ companyId: string }>()
  let navigate = useNavigate()
  const [projectionIncrement, setProjectionIncrement] = useState(0)

  const companiesQuery = trpc.companies.allForSelector.useQuery()
  const companies = useMemo(() => {
    return companiesQuery.data?.sort((a: Company, b: Company) => a.name.localeCompare(b.name)) ?? []
  }, [companiesQuery.data])

  const [selectedCompany, setSelectedCompany] = useState<Company | null>(null)

  const companyId: StateObject<number | undefined> = useMemo(() => {
    const companyIdFromParams = (): number | undefined => {
      const companyIdParam = params.companyId
      if (companyIdParam === 'none') return undefined
      return companyIdParam ? parseInt(companyIdParam) : undefined
    }
    return {
      value: companyIdFromParams(),
      setValue: (id: number | undefined) => {
        if (id !== undefined) {
          navigate(`/company-editor/${id}`)
        }
      },
    }
  }, [params.companyId, navigate])

  function didSelectCompany(company: Company) {
    setSelectedCompany(company)
    companyId.setValue(company.id)
  }

  useEffect(() => {
    if (companyId.value) {
      let company = companies.find((company: Company) => company.id === companyId.value)
      if (company) {
        setSelectedCompany(company)
      }
    }
  }, [companyId, companies])

  return (
    <CompanySelectorContext.Provider
      value={{
        companyId: companyId,
        companies,
        selectedCompany: makeStateObject([selectedCompany, setSelectedCompany]),
        isLoadingCompanies: companiesQuery.isLoading,
        didSelectCompany,
        projectionIncrement: makeStateObject([projectionIncrement, setProjectionIncrement]),
        refetch: companiesQuery.refetch,
      }}>
      {children}
    </CompanySelectorContext.Provider>
  )
}

export const useCompanySelectorContext = () => {
  return useContext(CompanySelectorContext)
}

export const withCompanySelectorContext = (Component: React.ComponentType) => (props: any) => {
  return (
    <CompanySelectorContextProvider>
      <Component {...props} />
    </CompanySelectorContextProvider>
  )
}
