import BeforeUnload from 'components/BeforeUnload'
import ConfirmationModalButton from 'components/ConfirmationModalButton'
import { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react'
import { NavigateOptions, useNavigate } from 'react-router-dom'

const NavigationContext = createContext({
  setBlockNavigation: (() => {}) as React.Dispatch<React.SetStateAction<boolean>>,
  navigate: (where: string, opts?: NavigateOptions) => {},
  setBlockNavigationString: (() => {}) as React.Dispatch<React.SetStateAction<string>>,
  pushNavigate: (where: string) => {},
})
export function usePluralNavigation() {
  return useContext(NavigationContext)
}
export function NavigationProvider(props: PropsWithChildren<{}>) {
  const [blockNavigation, setBlockNavigation] = useState(false)
  const [blockNavigationString, setBlockNavigationString] = useState('Are you sure you want to leave this page?')
  const [confirmingNavigation, setConfirmingNavigation] = useState<string | undefined>(undefined)
  const actuallyNavigate = useNavigate()
  const navigate = (where: string, opts?: NavigateOptions) => {
    if (blockNavigation) {
      setConfirmingNavigation(where)
    } else {
      actuallyNavigate(where, opts)
    }
  }

  return (
    <NavigationContext.Provider
      value={{ setBlockNavigation, navigate, setBlockNavigationString, pushNavigate: actuallyNavigate }}>
      <ConfirmationModalButton
        title="Leave page?"
        open={confirmingNavigation !== undefined}
        body={blockNavigationString}
        confirmText="Leave"
        onOpenChange={(open) => {
          if (!open) {
            setConfirmingNavigation(undefined)
          }
        }}
        onConfirm={() => {
          setConfirmingNavigation(undefined)
          setBlockNavigation(false)
          actuallyNavigate(confirmingNavigation as string)
        }}
      />
      {blockNavigation && <BeforeUnload message={blockNavigationString} />}
      {props.children}
    </NavigationContext.Provider>
  )
}

// Context to control navigation blocking
export function useNavigationBlocker(block: boolean) {
  const { setBlockNavigation } = usePluralNavigation()

  useEffect(() => {
    setBlockNavigation(block)
    return () => setBlockNavigation(false)
  }, [block, setBlockNavigation])
}

export function useNavigationBlockerText(navigationString: string) {
  const { setBlockNavigationString } = usePluralNavigation()
  useEffect(() => {
    setBlockNavigationString(navigationString)
  }, [navigationString, setBlockNavigationString])
}

export function PluralLink(props: PropsWithChildren<{ href: string }>) {
  let { navigate } = usePluralNavigation()
  return (
    <a
      href={props.href}
      onClick={(e) => {
        e.preventDefault()
        navigate(props.href)
      }}
      className="cursor:pointer max-w-full truncate">
      {props.children}
    </a>
  )
}

export function PluralOutsideLink(props: PropsWithChildren<{ href: string; target: string }>) {
  return (
    <a href={props.href} className="cursor:pointer rounded px-1 hover:bg-gray-100" target={props.target}>
      {props.children}
    </a>
  )
}
