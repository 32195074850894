import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'

const CustomTooltip = ({ active, payload, label }: { active: boolean; payload: any[]; label: string }) => {
  if (active && payload && payload.length) {
    const data = payload[0].payload // Assuming your relevant data is in the first payload item
    const formattedDate = new Date(data.x).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    })

    return (
      <div className="custom-tooltip" style={{ backgroundColor: 'white', padding: '10px', border: '1px solid #ccc' }}>
        <p className="label">{formattedDate}</p>
        {payload.map((entry, index) => (
          <p key={index} className="value">{`${entry.name}: ${entry.value}`}</p>
        ))}
      </div>
    )
  }

  return null
}

export default function CompanyStatChart({
  scatterData,
  chartName,
}: {
  scatterData: {
    data: {
      x: Date
      y: number
    }[]
    label: string
    stroke?: string
    fill?: string
  }[]
  chartName: string
}) {
  const dates = scatterData.flatMap((ds) => ds.data.map((point) => point.x.getTime())) // Collect all date timestamps
  const minDate = Math.min(...dates)
  const maxDate = Math.max(...dates)

  return (
    <ResponsiveContainer width="100%" height={400}>
      <LineChart>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="x"
          type="number"
          domain={[minDate, maxDate]}
          tickFormatter={(date) => new Date(date).toLocaleDateString()}
        />
        <YAxis />
        <Tooltip
          content={
            <CustomTooltip
              active={true}
              payload={scatterData.map((ds, index) => ({
                name: ds.label,
                value: ds.data[0]?.y,
                color: ds.stroke,
              }))}
              label={chartName}
            />
          }
        />

        {scatterData.map((ds, index) => (
          <Line
            key={ds.label}
            type="monotone"
            data={ds.data}
            dataKey="y"
            name={ds.label}
            stroke={ds.stroke}
            dot={false}
            activeDot={{ r: 8 }}
          />
        ))}
      </LineChart>
    </ResponsiveContainer>
  )
}
