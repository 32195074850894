import { Coefficients, ExitScenario, FundingRound } from 'pages/valuation-calculator/types'
import { useCallback, useEffect, useRef, useState } from 'react'

const PLURAL_FMIN_API_ORIGIN = 'https://plural-mono-py-fmin.up.railway.app'
// old one below
// const PLURAL_FMIN_API_ORIGIN = 'https://plural-py-fmin-api-production.up.railway.app'

function useRecalculateCoefficients({
  incorporationDate,
  fundingRounds,
  exitScenarios,
}: {
  incorporationDate?: Date
  fundingRounds: FundingRound[]
  exitScenarios: ExitScenario[]
}) {
  const debounceRef = useRef<ReturnType<typeof setTimeout> | undefined>()

  const [coefficients, setCoefficients] = useState<Coefficients[]>([])
  const forceRecalculate = useCallback(async () => {
    if (!incorporationDate || !exitScenarios.length || !fundingRounds.length) {
      setCoefficients([])
      return
    }

    const startTimeout = debounceRef.current
    const fetchedCoefficients: Coefficients[] = []

    for (const es of exitScenarios) {
      // TODO: Use an API which can take multiple exit scenarios at once
      if (startTimeout !== debounceRef.current) {
        // Another request has been made since this one started, so don't update the coefficients
        return
      }

      const exitDate = es.date
      const exitValuation = es.valuation

      const body = {
        funding_round_data: fundingRounds.map((round) => ({
          date: round.date.toISOString(),
          valuation: round.valuation,
        })),
        incorporation_date: incorporationDate.toISOString(),
        exit_date: exitDate.toISOString(),
        exit_valuation: exitValuation,
      }

      const response = await fetch(`${PLURAL_FMIN_API_ORIGIN}/calculate_exponential_regression_coefficients`, {
        method: 'POST',
        headers: {
          accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      })

      if (!response.ok) {
        throw new Error('Failed to fetch coefficients')
      }

      const data = await response.json()
      fetchedCoefficients.push({
        a: Number(data.a),
        b: Number(data.b),
        c: Number(data.c),
      })
    }

    if (startTimeout !== debounceRef.current) {
      // Another request has been made since this one started, so don't update the coefficients
      return
    }

    debounceRef.current = undefined

    // Convert the strings of numbers to numbers
    setCoefficients(fetchedCoefficients)
  }, [exitScenarios, fundingRounds, incorporationDate])

  useEffect(() => {
    setCoefficients([])
    clearTimeout(debounceRef.current)

    // Debounce for 500ms to avoid making too many requests while the user is typing
    debounceRef.current = setTimeout(() => {
      forceRecalculate()
    }, 200)
  }, [forceRecalculate])

  return {
    coefficients,
  }
}

export { useRecalculateCoefficients }
