import { ArrayElement } from 'safety'
import { RouterOutputs } from '../../utils/trpc'

export type CompanyMetric = ArrayElement<RouterOutputs['attributedCompanyMetrics']['miscMetricsForCompany']>
type Operator = CompanyMetric['operator']

export function operatorToString(operator: Operator): string {
  switch (operator) {
    case 'EQUAL':
      return '='
    case 'GREATER_THAN':
      return '>'
    case 'LESS_THAN':
      return '<'
    case 'APPROXIMATELY':
      return '~'
  }
  return '='
}

export function stringToOperator(newValue: string): Operator {
  switch (newValue) {
    case '=':
      return 'EQUAL'
    case '>':
      return 'GREATER_THAN'
    case '<':
      return 'LESS_THAN'
    case '~':
      return 'APPROXIMATELY'
  }
  return 'EQUAL'
}
