import { Cross2Icon } from '@radix-ui/react-icons'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as Logo } from '../images/pluralLogos/app-wordmark-light.svg'

export default function PageModalLayout({
  children,
  returnTo = '/',
}: {
  children: React.ReactNode
  returnTo?: string
}) {
  const navigate = useNavigate()
  return (
    <div>
      <div className="relative z-50 pb-6">
        <div className="left-0 right-0 top-0">
          <div className="relative mt-6 flex w-full items-center justify-between pl-6 pr-6">
            <button
              onClick={() => {
                navigate('/')
              }}>
              <Logo style={{ height: '43px', width: '105px' }} className="m-2" />
            </button>
            <button
              onClick={() => {
                navigate(returnTo)
              }}>
              <Cross2Icon className="m-2 h-8 w-8" />
            </button>
          </div>
        </div>
      </div>
      {children}
    </div>
  )
}
