import { IrrDistributionGraphData } from 'plural-shared/lookbackV2/sensitivity/IrrDistributionGenerator'
import { numberToPercentString } from 'plural-shared/utils'
import { Bar, BarChart, Label, ResponsiveContainer, Tooltip, TooltipProps, XAxis, YAxis } from 'recharts'
import { RouterOutputs, trpc } from 'utils/trpc'

export interface IRRDistributionGraphProps {
  title: string
  subheading: string
  height: number
}

export default function IRRDistributionGraph({
  data,
  props,
}: {
  data: IrrDistributionGraphData
  props: IRRDistributionGraphProps
}) {
  return (
    <div className="w-full">
      <div className="mb-6">
        <div className="text-base font-medium">{props.title}</div>
        <div className="text-sm opacity-50">{props.subheading}</div>
      </div>
      <ResponsiveContainer width="100%" height={props.height}>
        <BarChart
          data={data.bars}
          margin={{
            top: 5,
            right: 20,
            left: 10,
            bottom: 15,
          }}
          barCategoryGap={10}>
          <XAxis dataKey="irrRangeString" interval={0} tickLine={false} className="text-[7px] lg:text-xs">
            <Label value="Irr Range" offset={-5} position="insideBottom" fontSize={'12px'} />
          </XAxis>
          <YAxis
            domain={[0, data.maxYDomain]}
            tickFormatter={(value) => numberToPercentString(value, 0)}
            tick={{ fontSize: '12px' }}
            tickLine={false}>
            <Label value="Frequency" angle={-90} position="center" dx={-25} fontSize={'12px'} />
          </YAxis>
          <Tooltip
            isAnimationActive={false}
            content={(tooltipProps: TooltipProps<any, any>) => {
              if (!tooltipProps.active || !tooltipProps.payload || !tooltipProps.label) {
                return null
              }
              let label: string = tooltipProps.label
              label = label.replace('<', 'Less than')
              label = label.replace('>', 'Greater than')
              return (
                <div
                  className="custom-tooltip flex flex-col items-start rounded-md pb-4 pl-4 pr-5 pt-3 text-white"
                  style={{ backgroundColor: '#1C2227' }}>
                  <div className="-mt-1 text-sm opacity-70">{label} IRR</div>
                  <div className="mt-2 text-lg font-bold">
                    {numberToPercentString(tooltipProps.payload?.[0].value, 0)}
                  </div>
                  <div className="-mt-1 text-sm opacity-70">Frequency</div>
                </div>
              )
            }}
          />
          <Bar dataKey="frequencyPercent" fill="#8C877F" isAnimationActive={false} />
        </BarChart>
      </ResponsiveContainer>
    </div>
  )
}
