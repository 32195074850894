import { DEAL_SENSITIVITY_INPUT_OPTIONS } from "../constants";

export function breakevenCAGRPercent() {
  const index = DEAL_SENSITIVITY_INPUT_OPTIONS.CAGRs.findIndex(
    (cagr) => cagr >= 0
  );
  let val = index / DEAL_SENSITIVITY_INPUT_OPTIONS.CAGRs.length;
  val += 0.015;
  return (val * 100).toFixed(2);
}
