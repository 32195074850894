import { EditableTextCellFormat } from 'components/editable-table/EditableTextCellFormat'
import {
  numberToAbbreviatedDollarString,
  numberToReadableDollarString,
  numberToReadableString,
} from 'plural-shared/utils'
import { CompanyMetric } from 'plural-trpc/routers/companiesRouter'
import { ArrayElement, Optional, isNone, isSome } from 'safety'
import { safeCastToNumber } from '../../pages/cashSimulator/CashSimulatorContext'
import { RouterOutputs } from '../../utils/trpc'

type MiscAttributedCompanyMetric = ArrayElement<RouterOutputs['attributedCompanyMetrics']['miscMetricsForCompany']>
type ProjectedAttributedCompanyMetric = ArrayElement<RouterOutputs['projectedCompanyMetrics']['metricsForCompany']>
export type AttributedCompanyMetric = MiscAttributedCompanyMetric | ProjectedAttributedCompanyMetric

function attributedMetricViewModeString(metric: CompanyMetric): string {
  const typeFormat = metric?.metricFormat
  const value = metric?.value
  const strValue = String(value)
  if (!typeFormat || isNone(value) || strValue === '') {
    return ''
  }
  if (typeFormat === 'GENERIC') {
    return new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value)
  } else if (typeFormat === 'DOLLAR') {
    return numberToAbbreviatedDollarString(value, 2, 2)
  } else if (typeFormat === 'PERCENTAGE') {
    return `${Number(value * 100).toFixed(2)}%`
  }
  return ''
}

function attributedMetricEditModeString(metric: CompanyMetric): string {
  const typeFormat = metric.metricFormat
  const value = metric?.value
  if (!typeFormat || isNone(value)) {
    return ''
  }
  if (typeFormat === 'GENERIC') {
    return String(value)
  } else if (typeFormat === 'DOLLAR') {
    return String(value)
  } else if (typeFormat === 'PERCENTAGE') {
    return String(value)
  }
  return String(value)
}

export const AttributedMetricCellFormat: EditableTextCellFormat<CompanyMetric> = {
  id: 'attributedMetric',
  viewModeString: (value) => attributedMetricViewModeString(value),
  floatingModeString: (value) => {
    const typeFormat = value?.metricFormat
    if (isNone(value.value)) {
      return ''
    }
    if (typeFormat === 'DOLLAR') {
      return numberToReadableDollarString(value.value)
    } else if (typeFormat === 'GENERIC') {
      return numberToReadableString(value.value)
    } else if (typeFormat === 'PERCENTAGE') {
      return `${Number(value.value * 100).toFixed(2)}%`
    }
    return ''
  },
  editModeString: (value) => attributedMetricEditModeString(value),
  valueFromEditModeString: (editModeString: string, initialValue: Optional<CompanyMetric>) => {
    let cast = safeCastToNumber(editModeString)
    if (isSome(cast) && isSome(initialValue)) {
      return { ...initialValue, value: cast }
    } else {
      return null
    }
  },
  onInputChange: (input) => {
    let newValue = input.replace(/[^0-9.-]/g, '')
    return newValue
  },
}
