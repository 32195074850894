import { numberToReadableDollarString } from 'plural-shared/utils'
import { Line, LineChart, ResponsiveContainer, Tooltip, TooltipProps, XAxis, YAxis } from 'recharts'
import { isSome } from 'safety'
import { trpc } from '../../utils/trpc'
import { PositionMark } from './CompanyMarks'
import { useCompanySelectorContext } from './CompanySelectorContextProvider'
import CompanyStatChart2 from './massiveEnrichedCompany/CompanyStatChart2'
import { useCompanySelectorContext as useEnrichedCompanySelectorContext } from './massiveEnrichedCompany/MassiveEnrichedCompanySelectorContextProvider'

export function MarksChart({ variant = 'default' }: { variant?: 'default' | 'companyVariant' }) {
  let { companyId } = useCompanySelectorContext()
  const { pluralCompanyId } = useEnrichedCompanySelectorContext()
  if (pluralCompanyId.value) {
    companyId = pluralCompanyId
  }
  const financingDataQuery = trpc.company.marks.useQuery(
    { companyId: companyId.value ?? 0 },
    {
      enabled: isSome(companyId.value),
    },
  )

  const positionMarks: PositionMark[] = financingDataQuery.data ?? []

  const plotData = positionMarks.map((mark) => {
    return { sharePrice: mark.sharePrice, markDate: new Date(mark.markDate).getTime() }
  })

  const plotData2 = positionMarks.map((mark) => {
    return { y: mark.sharePrice, x: new Date(mark.markDate) }
  })

  const CustomTooltip: React.FC<TooltipProps<any, any>> = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const value = payload[0].value
      const formattedPayloadValue = value.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 2,
      })
      const date = new Date(label)
      const formattedLabel = date.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })
      return (
        <div
          className="custom-tooltip flex flex-col items-center rounded-md pb-3 pl-4 pr-4 pt-3 text-white"
          style={{ backgroundColor: '#1C2227' }}>
          <div className="value mb-1 text-xl font-semibold">{formattedPayloadValue}</div>
          <div className="label text-sm opacity-70">{formattedLabel}</div>
        </div>
      )
    }
    return <> </>
  }

  const yMin = Math.min(...positionMarks.map((mark) => mark.sharePrice)) * 0.8
  const yMax = Math.max(...positionMarks.map((mark) => mark.sharePrice)) * 1.02
  return variant === 'companyVariant' && plotData2.length > 0 ? (
    <CompanyStatChart2
      continueLine
      untilToday
      scatterData={[{ data: plotData2, label: 'Mark Prices' }]}
      chartName="Mark Prices"
      dot
      tickFormatter={(value) => numberToReadableDollarString(value)}
    />
  ) : (
    <ResponsiveContainer width="100%" height={160}>
      <LineChart
        data={plotData}
        margin={{
          top: 0,
          right: 8,
          left: 0,
          bottom: 0,
        }}>
        <XAxis
          className="text-[7px] lg:text-xs"
          type="category"
          dataKey="markDate"
          tickFormatter={(value) => {
            return new Date(value).toLocaleDateString('en-US', { year: '2-digit', month: 'short' })
          }}
          tickLine={false}
        />
        <Tooltip content={<CustomTooltip />} isAnimationActive={false} />
        <YAxis
          domain={[yMin, yMax]}
          width={46}
          dataKey="sharePrice"
          tickFormatter={(value) => numberToReadableDollarString(value)}
          tickLine={false}
          className="text-[7px] lg:text-xs"
        />
        <Line
          type="monotone"
          dataKey="sharePrice"
          stroke="#06B823"
          strokeWidth={2}
          name="Mark Prices"
          activeDot={{ r: 5 }}
          isAnimationActive={false}
        />
      </LineChart>
    </ResponsiveContainer>
  )
}
