import { numberToAbbreviatedString } from 'plural-shared/utils'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import {
  Area,
  AreaChart,
  Bar,
  BarChart,
  CartesianGrid,
  Label,
  Line,
  LineChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { useIsSmallScreen } from 'several/hooks/navigation'
function getDateYearsAndMonthsAgo(yearsAgo: number, monthsAgo: number): Date {
  const currentDate = new Date()
  const targetYear = currentDate.getFullYear() - yearsAgo
  const targetMonth = currentDate.getMonth() - monthsAgo
  const targetDate = new Date(currentDate.setFullYear(targetYear))
  targetDate.setMonth(targetMonth)
  return targetDate
}
interface CustomLabelProps {
  viewBox: {
    x: number
    y: number
    width: number
    height: number
  }
  value: string
  fill: string
  onClick?: () => void
  color: string
}

const RenderCustomLabel: React.FC<CustomLabelProps> = ({ viewBox, value, onClick, color }) => {
  let ref = useRef<HTMLDivElement>(null)
  let refWidth = ref.current?.scrollWidth ?? 0

  return (
    <g>
      <foreignObject x={viewBox.x + viewBox?.width + 1} y={viewBox?.y - 8} width={refWidth + 2} height={30}>
        <div
          style={{ borderColor: color, color: color, position: 'relative' }}
          className={`flex flex-row items-center gap-1 text-nowrap rounded border bg-white px-1 text-xs font-medium ${onClick ? 'cursor-pointer hover:shadow-md' : ''} `}
          ref={ref}
          onClick={onClick}>
          {value.length > 11 ? value.substring(0, 8) + '...' : value}
          {onClick ? (
            <div className="flex items-center justify-center rounded  px-1 font-bold">x</div>
          ) : (
            <div style={{ width: '2px' }}>&nbsp;</div>
          )}
        </div>
      </foreignObject>
    </g>
  )
}

const CustomTooltip = ({
  active,
  payload,
  label,
  renderValue,
  renderValueClass,
  additionalData = {},
}: {
  active: boolean
  payload: any[]
  label: string
  renderValueClass?: (val: string, name: string) => string
  renderValue: (val: string, name: string) => string
  additionalData?: {
    [key: string]: (value: string, index: number) => string[]
  }
}) => {
  if (active && payload && payload.length) {
    const data = payload[0].payload // Assuming your relevant data is in the first payload item
    const formattedDate = new Date(data.x).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      timeZone: 'UTC',
    })

    return (
      <div
        className="custom-tooltip"
        style={{
          backgroundColor: 'white',
          padding: '10px',
          border: '1px solid #ccc',
          overflow: 'auto',
        }}>
        <p className="label">{formattedDate}</p>
        {payload
          .filter((entry) => entry.name !== 'y')
          .sort((a, b) => b.value - a.value)
          .map((entry, index) => {
            let entryAdditional = additionalData[entry.name]
            return (
              <React.Fragment key={index}>
                <p
                  style={{
                    color: `${renderValueClass ? renderValueClass(typeof entry.value === 'object' ? entry.value[0] : entry.value, entry.name) : ''}`,
                  }}
                  className={`value`}>
                  {`${entry.name}: ${typeof entry.value === 'object' ? renderValue(entry.value[0], entry.name) : renderValue(entry.value, entry.name)}`}
                </p>
                {entryAdditional ? (
                  <>
                    {entryAdditional(entry.value, index).map((additional: string, index) => {
                      return (
                        <p key={index} className="additional">
                          {additional}
                        </p>
                      )
                    })}
                  </>
                ) : (
                  <></>
                )}
              </React.Fragment>
            )
          })}
      </div>
    )
  }

  return null
}

export default function CompanyStatChart2({
  scatterData,
  chartName,
  tickFormatter,
  variant = 'line',
  dot = false,
  untilToday = true,
  continueLine = false,
  rightLabel = false,
  normalizeYAxis = false,
  yAxisLabel,
  asAPercentage = false,
  timeAgo,
}: {
  scatterData: {
    data: {
      x: Date
      y: number
    }[]
    label: string
    stroke?: string
    fill?: string
    tickFormatter?: (value: any) => string
    onClickLabel?: (label: string) => void
  }[]
  chartName: string
  variant?: 'line' | 'area' | 'bar'
  tickFormatter: (value: any, index: number) => string
  dot?: boolean
  untilToday?: boolean
  continueLine?: boolean
  rightLabel?: boolean
  normalizeYAxis?: boolean
  yAxisLabel?: string
  asAPercentage?: boolean
  timeAgo?: { years: number; months: number }
}) {
  const dates = scatterData.flatMap((ds) => ds.data.map((point) => point.x.getTime())) // Collect all date timestamps
  const minDate = Math.min(...dates)
  const maxDate = Math.max(...dates)

  let maxValue = Math.max(...scatterData.flatMap((ds) => ds.data.map((point) => point.y)))
  let defaultLengthOfXAxis = maxDate - minDate
  let minX = minDate - defaultLengthOfXAxis * 0.05

  let [xAxisBoundaries, setXAxisBoundaries] = useState<number[]>([minX, new Date().getTime()])

  let specifiedTimeAgo = useMemo(() => {
    return timeAgo ? getDateYearsAndMonthsAgo(timeAgo.years, timeAgo.months) : undefined
  }, [timeAgo])

  let percentageBasedData = scatterData
    .filter((ds) => ds.data.length > 0)
    .map((ds) => {
      let firstPoint = ds.data.sort((a, b) => a.x.getTime() - b.x.getTime())[0]
      return {
        ...ds,
        data: ds.data.map((point) => {
          return {
            x: point.x,
            y: (point.y - firstPoint.y) / firstPoint.y,
          }
        }),
      }
    })

  useEffect(() => {
    setXAxisBoundaries([specifiedTimeAgo?.getTime() ?? minX, new Date().getTime()])
  }, [JSON.stringify(scatterData)])

  let maxValuePercentage = Math.max(...percentageBasedData.flatMap((ds) => ds.data.map((point) => point.y)))
  let minValuePercentage = Math.min(...percentageBasedData.flatMap((ds) => ds.data.map((point) => point.y)))
  let percentageAxisBoundaries = [minValuePercentage - 0.1, maxValuePercentage * 1.2]
  let yAxisFloor = 0
  let yAxisBoundaries = [yAxisFloor, Math.ceil((maxValue * 1.1) / 10) * 10]
  let isSmallScreen = useIsSmallScreen()
  const chartMargin = { top: 0, right: isSmallScreen ? 40 : 90, bottom: 0, left: 30 }
  let Common = (
    <>
      <XAxis
        allowDuplicatedCategory={false}
        tickCount={10}
        allowDataOverflow={true}
        dataKey="x"
        type="number"
        domain={[specifiedTimeAgo?.getTime() ?? xAxisBoundaries[0], xAxisBoundaries[1]]}
        tickFormatter={(date) => new Date(date).toLocaleDateString()}
      />
      <CartesianGrid stroke="#f5f5f5" />
      <Tooltip
        content={
          <CustomTooltip
            // renderValueClass={asAPercentage ? (val) => `${parseFloat(val) > 0 ? 'green' : 'red'}` : undefined}
            renderValue={(val, name) => {
              if (asAPercentage) {
                return `${numberToAbbreviatedString(parseFloat(val) * 100)}%`
              }
              let tickFormatter = scatterData.find((ds) => ds.label === name)?.tickFormatter
              return tickFormatter ? tickFormatter(val) : val
            }}
            additionalData={
              asAPercentage
                ? scatterData.reduce(
                    (prev, curr) => {
                      prev[curr.label] = (val, index) => {
                        let dataset = scatterData.find((ds) => ds.label === curr.label)
                        let firstValue = dataset?.data.sort((a, b) => a.x.getTime() - b.x.getTime())[0].y
                        let tickFormatter = dataset?.tickFormatter
                        let numberValue = (firstValue ?? 0) + parseFloat(val) * (firstValue ?? 1)
                        return [`Value: ${tickFormatter ? tickFormatter(numberValue) : numberValue}`]
                      }
                      return prev
                    },
                    {} as { [name: string]: (val: string, idx: number) => string[] },
                  )
                : undefined
            }
            key={chartName}
            active={true}
            payload={scatterData.map((ds, index) => ({
              name: ds.label,
              value: ds.data[0]?.y,
              color: ds.stroke,
            }))}
            label={chartName}
          />
        }
      />
    </>
  )

  return (
    <div>
      <ResponsiveContainer width="100%" height={400}>
        {variant === 'line' ? (
          <LineChart margin={chartMargin}>
            {Common}
            {!normalizeYAxis && !asAPercentage && (
              <YAxis
                domain={yAxisBoundaries}
                yAxisId="main"
                tickFormatter={scatterData.length > 1 ? (val) => numberToAbbreviatedString(val) : tickFormatter}>
                <Label
                  style={{
                    textAnchor: 'middle',
                  }}
                  angle={-90}
                  value={yAxisLabel}
                  position="insideLeft"
                />
              </YAxis>
            )}
            {asAPercentage && (
              <YAxis
                domain={percentageAxisBoundaries}
                yAxisId="percent"
                tickFormatter={(val) => {
                  return `${numberToAbbreviatedString(Math.round(val * 100))}%`
                }}>
                <Label
                  style={{
                    textAnchor: 'middle',
                  }}
                  angle={-90}
                  offset={-20}
                  value={'Percent Change'}
                  position="insideLeft"
                />
              </YAxis>
            )}
            {asAPercentage ? (
              <ReferenceLine
                yAxisId="percent"
                x={minDate}
                y={0}
                stroke="black"
                strokeDasharray="3 3"
                label={{
                  value: '0%',
                  position: 'right',
                  fill: 'black',
                  style: {
                    fontSize: '12px',
                    fontWeight: 'bold',
                  },
                }}
              />
            ) : (
              <></>
            )}
            {!normalizeYAxis && !asAPercentage && (
              <YAxis
                domain={yAxisBoundaries}
                yAxisId="main"
                tickCount={6}
                tickFormatter={tickFormatter}
                width={isSmallScreen ? 0 : undefined}
                display={isSmallScreen ? 'none' : undefined}
                axisLine={isSmallScreen ? false : undefined}>
                <Label
                  style={{
                    textAnchor: 'middle',
                  }}
                  angle={-90}
                  value={yAxisLabel}
                  position="insideLeft"
                />
              </YAxis>
            )}
            {scatterData
              .filter((ds) => ds.data.length > 0 && ds.label)
              .map((ds, index) => {
                const lastPoint = asAPercentage
                  ? percentageBasedData
                      .find((_ds) => _ds.label === ds.label)!
                      .data.sort((a, b) => a.x.getTime() - b.x.getTime())[ds.data.length - 1]
                  : ds.data.sort((a, b) => a.x.getTime() - b.x.getTime())[ds.data.length - 1]
                const lastX = lastPoint.x.getTime()
                const lastY = lastPoint.y
                let datasetMaxY = Math.max(...ds.data.map((point) => point.y))

                return (
                  <React.Fragment key={ds.label}>
                    {ds.data.length > 0 && ds.label ? (
                      <React.Fragment>
                        {normalizeYAxis && !asAPercentage && (
                          <YAxis
                            domain={[0, Math.ceil((datasetMaxY * 1.15 * (1 + (index * 0.9) / 10)) / 10) * 10]}
                            dataKey="y"
                            yAxisId={ds.label}
                            tickCount={6}
                            tickFormatter={index === 0 ? tickFormatter : undefined}
                            width={index === 0 ? undefined : 0}
                            display={index === 0 ? undefined : 'none'}
                            axisLine={index === 0 ? undefined : false}
                          />
                        )}
                        <Line
                          yAxisId={asAPercentage ? 'percent' : normalizeYAxis ? ds.label : 'main'}
                          key={ds.label}
                          type="linear"
                          data={
                            asAPercentage
                              ? percentageBasedData.find((_ds) => _ds.label === ds.label)?.data ?? []
                              : ds.data.map((point) => ({ x: point.x.getTime(), y: point.y }))
                          }
                          dataKey="y"
                          name={ds.label}
                          stroke={ds.stroke ?? 'black'}
                          dot={dot}
                          isAnimationActive={false}
                          activeDot={{ r: 8 }}></Line>

                        {rightLabel && (
                          <ReferenceLine
                            key={ds.label}
                            yAxisId={asAPercentage ? 'percent' : normalizeYAxis ? ds.label : 'main'}
                            x={lastPoint.x.getTime()}
                            y={lastY}
                            stroke="transparent"
                            label={{
                              value: ds.label,
                              position: 'right',
                              fill: ds.stroke ?? 'black',
                              style: {
                                transform: 'translate(10px, 0)',
                                fontSize: '12px',
                                fontWeight: 'bold',
                                padding: '10px',
                              },
                              onClick: ds.onClickLabel ? () => ds.onClickLabel!(ds.label) : undefined,
                              // @ts-ignore
                              content: <RenderCustomLabel color={ds.stroke ?? 'black'} />,
                            }}
                          />
                        )}
                      </React.Fragment>
                    ) : (
                      <></>
                    )}

                    {continueLine && (
                      <Line
                        isAnimationActive={false}
                        yAxisId={asAPercentage ? 'percent' : normalizeYAxis ? ds.label : 'main'}
                        animationId={2 + index}
                        key={`${ds.label}-dotted`}
                        tooltipType="none"
                        dataKey="y"
                        type="linear"
                        data={[
                          { x: lastX, y: lastY },
                          { x: xAxisBoundaries[1] - 10, y: lastY },
                        ]}
                        stroke={ds.stroke ?? 'black'}
                        strokeDasharray="3 3"
                        dot={false}></Line>
                    )}
                  </React.Fragment>
                )
              })}
          </LineChart>
        ) : variant === 'area' ? (
          <AreaChart margin={chartMargin}>
            {Common}
            {scatterData
              .filter((ds) => ds.data.length > 0)
              .map((ds, index) => {
                const lastPoint = ds.data[ds.data.length - 1]
                const lastX = lastPoint.x.getTime()
                const lastY = lastPoint.y

                let datasetMaxY = Math.max(...ds.data.map((point) => point.y))
                return (
                  <React.Fragment key={ds.label}>
                    <YAxis
                      domain={[0, Math.ceil((datasetMaxY * 1.15) / 10) * 10]}
                      dataKey="y"
                      yAxisId={ds.label}
                      tickFormatter={index === 0 ? tickFormatter : undefined}
                      width={index === 0 ? undefined : 0}
                      display={index === 0 ? undefined : 'none'}
                      axisLine={index === 0 ? undefined : false}
                    />
                    <Area
                      yAxisId={ds.label}
                      key={ds.label}
                      type="monotone"
                      animationId={0}
                      data={ds.data}
                      dataKey="y"
                      name={ds.label}
                      stroke={ds.stroke || 'black'}
                      fill={ds.fill || 'rgba(0, 0, 0, 0.3)'}
                      dot={true}
                      activeDot={{ r: 8 }}
                      isAnimationActive={false}
                    />
                    {continueLine && (
                      <Area
                        key={`${ds.label}-dotted`}
                        animationId={1}
                        yAxisId={ds.label}
                        tooltipType="none"
                        dataKey="y"
                        type="linear"
                        data={[
                          { x: lastX, y: [lastY, lastY] },
                          { x: xAxisBoundaries[1] - 10, y: [lastY, lastY] },
                        ]}
                        stroke={ds.stroke ?? 'black'}
                        strokeDasharray="3 3"
                        dot={false}
                      />
                    )}
                  </React.Fragment>
                )
              })}
          </AreaChart>
        ) : variant === 'bar' ? (
          //  for now bar only shows one data set
          <BarChart data={scatterData[0].data} margin={chartMargin} barSize={isSmallScreen ? 30 : 50}>
            {Common}
            <YAxis domain={yAxisBoundaries} tickFormatter={tickFormatter} tickCount={6} yAxisId="main" />
            <Bar dataKey="y" fill={'black'} name={scatterData[0].label} yAxisId={'main'} />
          </BarChart>
        ) : (
          <div></div>
        )}
      </ResponsiveContainer>
    </div>
  )
}
