
export default function AiTermsOfUse() {
  return (
    <div className="mb-24 mt-24 flex pl-2 pr-2 lg:pl-16 lg:pr-16 items-start flex-wrap flex-col lg:flex-row">
      <aside className="lg:w-1/4 w-full p-6 lg:sticky lg:top-[20vh]">
        <nav className="lg:border-r pr-4">
          <ul>
            <li className="mb-2">
              <a href="/terms" className="block py-2 px-4 rounded-lg hover:bg-slate-100 font-medium">Terms of Use</a>
            </li>
            <li className="mb-2">
              <a href="/privacy" className="block py-2 px-4 rounded-lg hover:bg-slate-100 font-medium">Privacy Policy</a>
            </li>
            <li className="mb-2">
              <a href="/subprocessors" className="block py-2 px-4 rounded-lg hover:bg-slate-100 font-medium">Sub-processors</a>
            </li>
            <li className="mb-2">
              <a href="/terms/ai" className="block py-2 px-4 rounded-lg hover:bg-slate-100 font-medium">AI Terms</a>
            </li>
            <li className="mb-2">
              <a href="/aup" className="block py-2 px-4 rounded-lg hover:bg-slate-100 font-medium">Acceptable Use Policy</a>
            </li>
          </ul>
        </nav>
      </aside>

      <main className="lg:w-3/4 w-full p-6">
        <h1 className="text-3xl font-bold mb-8">Plural AI Terms</h1>
        <section className="mb-10">
          <p className="mb-4">
            These Plural AI Terms (“AI Terms”) apply to your access to and use of Plural AI (defined below). The AI Terms form a part of the agreement between you or your organization (collectively, “you” or “your”) and Massive Tech, Inc., dba Plural (“Plural”, “we”, “our”, or “us”), that governs your use of the Services, such as Plural’s Terms of Service or a master subscription agreement between you and Plural (your “Service Terms”). Capitalized terms used but not defined in these AI Terms have the meaning set forth in your Service Terms.  As used in these AI Terms, “Customer Data” also means “Your Content” (or similar term) as used in your Service Terms, and the “Services” also means the “Plural Products” (or similar term) as used in your Service Terms. In the event of a conflict between these AI Terms and your Service Terms, these AI Terms will control.
          </p>
          <h2 className="text-xl font-bold mb-6">1. Plural AI</h2>
          <p className="mb-4">
            As used in these AI Terms, “Plural AI” means the features and functionality of the Services made available to you that utilize generative artificial intelligence models. Plural AI is powered by the third-party AI platform and model providers listed on [[this page]], as updated from time to time (each a “Third-Party AI Provider”).  In consideration of your agreeing to and complying with these AI Terms, we grant you a non-exclusive, revocable, non-transferable, limited right to access and use Plural AI in accordance with your Service Terms, including these AI Terms.
          </p>
        </section>
        <section className="mb-10">
          <h2 className="text-xl font-bold mb-6">2. Input and Output</h2>
          <p className="mb-4">
            You may provide or make available prompts, data, text, or other input to be processed by Plural AI (“Input”) and receive data generated and returned by Plural AI based on the Input (“Output”). Input processed by Plural AI and the resulting Output are your Customer Data.
          </p>
        </section>
        <section className="mb-10">
          <h2 className="text-xl font-bold mb-6">3. Use Restrictions and Third-Party AI Provider Requirements</h2>
          <p className="mb-4">
            When you use Plural AI, you are solely responsible for the development, content, operation, maintenance, and use of Customer Data. You will ensure, and you represent and warrant, that Input and your use of Plural AI and Output will not violate: (a) any applicable law; (b) your Service Terms, including these AI Terms; or (c) Plural’s Acceptable Use Policy, as updated from time to time.  In addition, you will review and comply with the applicable Third-Party AI Provider terms and policies available here, as updated from time to time, which are incorporated into these AI Terms by reference.
          </p>
        </section>
        <section className="mb-10">
          <h2 className="text-xl font-bold mb-6">4. Limitations</h2>
          <p className="mb-4">
            You acknowledge that due to the nature of machine learning and the technology powering Plural AI, Output may not be unique, and Plural AI may generate the same or similar output to you and a third party. You should not rely on factual assertions in Output without independently fact-checking their accuracy; Output that appears accurate because of its detail or specificity may still contain material inaccuracies. Plural AI may not dynamically retrieve information, and Output may not account for events or changes to underlying facts occurring after the relevant model was trained. Output should be evaluated for accuracy and suitability for your use case, including by employing human review of the Output.
          </p>
        </section>
        <section className="mb-10">
          <h2 className="text-xl font-bold mb-6">5. Improving Plural AI</h2>
          <p className="mb-4">
            Your use of Plural AI does not grant Plural any right or license to use or share Customer Data in a manner that is inconsistent with your Service Terms, unless otherwise agreed by you. For clarity, Plural does not use, or permit others to use, Input or Output to train the generative artificial intelligence models used to provide Plural AI.
          </p>
        </section>
        <section className="mb-10">
          <h2 className="text-xl font-bold mb-6">6. Data Retention</h2>
          <p className="mb-4">
            Third-Party AI Providers will not (a) log Input or Output for human review, or (b) save or retain Input or Output.* Third-Party AI Providers may retain and use metadata associated with Plural AI usage solely for billing, safety, and compliance purposes.
          </p>
        </section>
        <section className="mb-10">
          <h2 className="text-xl font-bold mb-6">7. Privacy and Subprocessors</h2>
          <p className="mb-4">
            To the extent your Customer Data contains Personal Data (or similar term), as defined in any applicable data processing addendum we have with you, you instruct Plural and our subprocessors to process such Personal Data for the purposes of providing Plural AI and Output, subject to our Privacy Policy and any such data processing addendum. You consent to our use of applicable Third-Party AI Providers as subprocessors, as listed at <a href="https://withplural.com/subprocessors" className="text-blue-600">https://withplural.com/subprocessors</a>.
          </p>
        </section>
        <section className="mb-10">
          <h2 className="text-xl font-bold mb-6">8. Suspension and Service Levels</h2>
          <p className="mb-4">
            Notwithstanding anything to the contrary in your Service Terms, Plural may suspend your access to any portion or all of Plural AI if: (a) Plural reasonably believes or determines that your use of Plural AI is in violation of these AI Terms or poses a risk to Plural, the Services, or a third party; (b) a Third-Party AI Provider requires such suspension; (c) a Third-Party AI Provider has suspended or terminated Plural’s access to or use of services or products required to enable your access to Plural AI; or (d) Plural’s provision of Plural AI to you is prohibited by legislation, regulation, or other act of government.
          </p>
          <p className="mb-4">
            Notwithstanding anything to the contrary in your Service Terms, Plural does not make any warranty regarding the availability or uptime of Plural AI, and downtime of Plural AI will not constitute downtime under any applicable service level agreement.
          </p>
        </section>
        <section className="mb-10">
          <h2 className="text-xl font-bold mb-6">9. Beta Features</h2>
          <p className="mb-4">
            Plural may provide you with a version or feature of Plural AI that is not generally available as part of an alpha, a beta, a limited release, a developer preview, or another similar term (“AI Beta”).  Such Plural AI versions or features (collectively, “AI Beta Features”) might contain bugs, errors, or omissions. AI Beta Features are provided to you for testing purposes only, on an “AS IS” basis, without any warranty, liability, indemnity, or performance obligations. AI Beta Features are not subject to any support commitments. AI Beta Features might never be made available for general use or otherwise be provided in a future version of Plural AI or the Services, and we may discontinue AI Beta Features, or revoke your access to AI Beta Features, at any time for any or no reason, in our sole discretion, without any liability to you. Discontinuing AI Beta Features, or making AI Beta Features inaccessible to you, may have the effect of making some or all of your Customer Data inaccessible to you.
          </p>
        </section>
        <section className="mb-10">
          <h2 className="text-xl font-bold mb-6">10. Modifications</h2>
          <p className="mb-4">
            Notwithstanding anything to the contrary in your Service Terms, we may modify or update these AI Terms from time to time, so you should review this page periodically. In such cases, we will update the “Last Updated” date at the top of this page. If the changes are material, we will provide you with reasonable notice prior to the effective date of the changes, either by emailing the email address associated with your account or by notifying you through the Services. For clarity, such notice may be provided to any person designated as an administrator on your account. Your continued use of Plural AI after any change to these AI Terms becomes effective constitutes your acceptance of the new AI Terms. If you do not agree to any part of these AI Terms or any future AI Terms, you must disable Plural AI before they become effective (and not continue to use or access Plural AI). If Plural fails to provide you with notice of material changes to these AI Terms in accordance with this Section, then your use of Plural AI will continue to be governed by the terms and conditions of these AI Terms as they were immediately prior to such changes, unless and until you consent to the new version of the AI Terms, after which the modified AI Terms will govern.  For clarity, renewing your subscription to the Services subject to the new AI Terms or Service Terms that reference the new AI Terms (including by automatic renewal), or otherwise agreeing to the new AI Terms in accordance with this Section, constitutes consent for purposes of the preceding sentence.
          </p>
        </section>
        <p className="mt-6 pt-6 border-t text-sm text-slate-600">
          * Notwithstanding Section 6 above, the data retention policy applicable to generative AI models listed on  <a href="https://withplural.com/subprocessors" className="text-blue-600">https://withplural.com/company/subprocessors</a>.
        </p>
      </main>
    </div>
  )
}
