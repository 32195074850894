import { useEffect, useState } from 'react'
import { isSome } from 'safety'
import { BasicButton } from '../../components/BasicButton'
import { trpc } from '../../utils/trpc'
import GenericNumberInput from '../cashSimulator/GenericNumberOfferInput'
import { useCompanySelectorContext } from './CompanySelectorContextProvider'
import { useCompanySelectorContext as useEnrichedCompanySelectorContext } from './massiveEnrichedCompany/MassiveEnrichedCompanySelectorContextProvider'

/*

*/

export default function V2CalculatorCompanyProfileEditor() {
  let { selectedCompany } = useCompanySelectorContext()
  const { selectedBaseCompany } = useEnrichedCompanySelectorContext()
  if (selectedBaseCompany.value) {
    selectedCompany = selectedBaseCompany
  }
  const existingProfileQuery = trpc.cashSimulator.companyProfile.useQuery(selectedCompany.value?.id ?? 0, {
    enabled: isSome(selectedCompany.value),
  })

  const mutateQuery = trpc.cashSimulator.createOrUpdateCompanyProfile.useMutation({
    onSuccess: () => {
      setDidUpdate(true)
      setTimeout(() => {
        setDidUpdate(false)
      }, 1000)
    },
  })
  const canEdit = trpc.cashSimulator.canEditDefaultBaseSharePrice.useQuery(selectedCompany.value?.id ?? 0, {
    enabled: isSome(selectedCompany.value),
  }).data

  const [inputBaseSharePrice, setInputBaseSharePrice] = useState<string>('')
  const [didUpdate, setDidUpdate] = useState(false)

  useEffect(() => {
    if (existingProfileQuery.data) {
      setInputBaseSharePrice(existingProfileQuery.data.latestMarkPrice.toString())
    }
  }, [existingProfileQuery.data])

  return (
    <div>
      <div className="">
        {canEdit ? (
          <div>
            <div className="text-sm">Default Secondary / Base Share Price</div>
            <div className="flex w-full max-w-md items-center space-x-2">
              <GenericNumberInput
                value={inputBaseSharePrice}
                onNewNumber={(num) => setInputBaseSharePrice(num.toString())}
                isDollars={true}
              />
              <BasicButton
                className=" text-sm"
                variant="secondary"
                onClick={() => {
                  if (!selectedCompany.value) return
                  mutateQuery.mutate({
                    companyId: selectedCompany.value!.id,
                    defaultBaseSharePrice: parseFloat(inputBaseSharePrice),
                  })
                }}>
                Update
              </BasicButton>
            </div>
          </div>
        ) : (
          <div className="text-sm">
            Add a priced financing event to adjust the base share price (doesn't refresh automatically rn)
          </div>
        )}
        <div className="h-4 text-sm">{didUpdate ? 'Updated' : ''}</div>
      </div>
    </div>
  )
}
