import { DEFAULT_FUND_MIN_CAGR } from "..";
import { cashEquivalentLookbackMultipleOverSecondary } from "../functions/cashAdjustedLookbackSharePriceMultiple";
import { companyCagrRequiredForTargetTxMultiple } from "../functions/companyCagrRequiredForTargetTxMultiple";

export interface TargetMultipleGeneratorInputs {
  altInvestmentIrr: number;
  secondaryBrokerTakeRate: number;
  totalTaxRate: number;
  reserveRatio: number;
  yearsToOutcome: number;
}

// This lets you pass in a target price multiple and get back the company CAGR required to hit that multiple
export class TargetMultipleOutcomeGenerator {
  private inputs: TargetMultipleGeneratorInputs;
  private fundMinCagr: number;
  private cashEquivalentLookbackMultipleOverSecondary: number;

  constructor(inputs: TargetMultipleGeneratorInputs) {
    this.inputs = inputs;
    this.fundMinCagr = DEFAULT_FUND_MIN_CAGR;
    this.cashEquivalentLookbackMultipleOverSecondary =
      this._computeCashEquivalentLookbackMultipleOverSecondary();
  }

  minTxMultiple(): number {
    return 1 / this.inputs.reserveRatio;
  }

  companyCagrRequiredForTargetTxMultiple(targetTxMultiple: number): number {
    if (targetTxMultiple < this.minTxMultiple()) {
      throw new Error(
        `targetTxMultiple ${targetTxMultiple} is below minTxMultiple ${this.minTxMultiple()}. Any company cagr will beat it.`
      );
    }
    return companyCagrRequiredForTargetTxMultiple(targetTxMultiple);
  }

  companyCagrRequiredForTargetCashAdjustedTxMultiple(
    targetCashAdjustedTxMultiple: number
  ): number {
    let rawTxMultipleForTargetCashAdjustedTxMultiple =
      targetCashAdjustedTxMultiple /
      this.cashEquivalentLookbackMultipleOverSecondary;
    if (rawTxMultipleForTargetCashAdjustedTxMultiple < 1) {
      let belowMin =
        Math.pow(
          rawTxMultipleForTargetCashAdjustedTxMultiple,
          1 / this.inputs.yearsToOutcome
        ) *
          (1 + this.fundMinCagr) -
        1;
      return belowMin;
    }
    return this.companyCagrRequiredForTargetTxMultiple(
      rawTxMultipleForTargetCashAdjustedTxMultiple
    );
  }

  companyMultipleRequiredForTargetCashAdjustedTxMultiple(
    targetCashAdjustedTxMultiple: number
  ): number {
    let companyCagrRequired =
      this.companyCagrRequiredForTargetCashAdjustedTxMultiple(
        targetCashAdjustedTxMultiple
      );
    return Math.pow(1 + companyCagrRequired, this.inputs.yearsToOutcome);
  }

  private _computeCashEquivalentLookbackMultipleOverSecondary(): number {
    return cashEquivalentLookbackMultipleOverSecondary({
      altInvestmentIrr: this.inputs.altInvestmentIrr,
      yearsToOutcome: this.inputs.yearsToOutcome,
      secondaryBrokerTakeRate: this.inputs.secondaryBrokerTakeRate,
      totalTaxRate: this.inputs.totalTaxRate,
    });
  }
}
