import { useEffect, useState } from 'react'
import { trpc } from 'utils/trpc'
import { InfoItem } from './InfoItem'

export default function DealSensitivityMetadata({
  yearsToLiquidity,
  warehousedAssetCAGR,
  reserveRatio,
}: {
  yearsToLiquidity: number
  warehousedAssetCAGR: number
  reserveRatio: number
}) {
  const { data } = trpc.dealSensitivity.dealMetadata.useQuery({
    yearsToLiquidity,
    warehousedAssetCAGRFromBase: warehousedAssetCAGR,
    warehousedAssetReserveRatio: reserveRatio,
  })

  const [fundInfoItems, setFundInfoItems] = useState<InfoItem[]>([])

  useEffect(() => {
    if (data) {
      let irrColor = 'text-func-black-0'
      if (data.dealIRR.startsWith('-')) {
        irrColor = 'text-tickers-red'
      } else {
        irrColor = 'text-tickers-green'
      }
      const fundItems: InfoItem[] = [
        {
          label: 'Deal Size',
          value: data.dealSizeAtInception,
        },
        {
          label: 'Realized Deal Value',
          value: data.realizedDealValue,
        },
        {
          label: 'Deal MOIC',
          value: data.dealMOIC,
        },
        {
          label: 'Deal IRR',
          value: data.dealIRR,
          valueClass: irrColor,
        },
      ]
      setFundInfoItems(fundItems)
    }
  }, [data])

  return (
    <div className="flex-auto">
      <div className="flex flex-auto flex-row flex-wrap lg:space-x-12">
        {fundInfoItems.map((item, index) => {
          return (
            <div key={`info-${index}`} className="w-full lg:w-auto">
              <div className="text-sm opacity-60">{item.label}</div>
              <div className={`text-xl font-medium   ${item.valueClass ?? ''}`}>{item.value}</div>
            </div>
          )
        })}
      </div>
    </div>
  )
}
