import { IonSpinner } from '@ionic/react'
import { CSSProperties, forwardRef } from 'react'
type ButtonVariant = 'primary' | 'secondary' | 'gray'

interface ButtonStyle {
  pressable: string
  notPressable: string
}

const buttonStyles: Record<ButtonVariant, ButtonStyle> = {
  primary: {
    pressable: 'bg-[rgba(0,0,0,0.82)] text-white hover:bg-func-black-hover-bg',
    notPressable: 'bg-func-black-inactive-button-bg text-func-white-inactive',
  },
  secondary: {
    pressable: 'bg-white text-literal-black-5 hover:bg-func-gray-hover-fill border',
    notPressable: 'bg-white text-literal-black-5 hover:bg-func-gray-hover-fill border opacity-70',
  },
  gray: {
    pressable: 'bg-[#F6F8FA] text-literal-black-5 border border-[#D6D8DB] hover:bg-[#F3F4F6]',
    notPressable: 'bg-[#F6F8FA] text-literal-black-5 opacity-80',
  },
}

export const attioStyleString =
  'flex flex-row  items-center gap-2 rounded-lg border px-[6px] py-[4px] text-sm font-medium text-black shadow-sm hover:bg-gray-100 cursor-pointer'

export const BasicButton = forwardRef(function BasicButton(
  {
    children,
    onClick,
    variant = 'primary',
    className = '',
    isPressable = true,
    isLoading = false,
    textSize = 'text-base',
    textHeight = 24,
    disabled = false,
    attioStyle = false,
    additionalStyle = {},
  }: {
    children: React.ReactNode
    onClick?: () => void
    variant?: ButtonVariant
    className?: string
    isPressable?: boolean
    isLoading?: boolean
    textSize?: string
    textHeight?: number
    disabled?: boolean
    attioStyle?: boolean
    additionalStyle?: CSSProperties
  },
  ref: React.Ref<HTMLButtonElement>,
) {
  const style = buttonStyles[variant]

  let border = ''
  if (variant === 'secondary') {
    border = 'shadow-secondary-button'
  }

  const spinner = () => {
    return (
      <div className="flex w-full flex-col items-center justify-center" style={{ height: textHeight }}>
        <IonSpinner name="lines-sharp" className="h-5 w-5" />
      </div>
    )
  }

  let usableAttioStyleString =
    attioStyleString + ` ${variant === 'gray' ? 'bg-white' : 'bg-gray-900 hover:bg-gray-900 text-white'}`

  return (
    <button
      ref={ref}
      disabled={disabled}
      style={{ opacity: disabled ? 0.5 : 1, cursor: disabled ? 'not-allowed' : 'pointer', ...additionalStyle }}
      className={`${
        isPressable ? `${style.pressable}` : `${style.notPressable} pointer-events-none`
      } ${textSize} ${attioStyle ? usableAttioStyleString : 'rounded-md pb-2 pl-4 pr-4 pt-2 font-medium'} ${border} ${className}`}
      onClick={onClick}>
      {isLoading ? spinner() : children}
    </button>
  )
})
