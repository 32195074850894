import NavigationIsland from '../components/NavigationIsland'

export default function NavigationLayout({ children }: { children: React.ReactNode }) {
  return (
    <div>
      <div className="relative z-50">
        <div className="fixed left-0 right-0 top-0 flex flex-col items-center justify-center">
          <div className="w-full">
            <NavigationIsland />
          </div>
        </div>
      </div>
      <div className="h-screen">
        <div className="h-12"></div>
        {children}
      </div>
    </div>
  )
}
