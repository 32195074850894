export function numberToAbbreviatedDollarString(
  num: number,
  numMillionsDecimals: number = 2,
  numBillionsDecimals: number = 2
) {
  const absNum = Math.abs(num);
  const sign = num < 0 ? "-" : "";

  if (absNum < 1e3) {
    return `${sign}$${absNum.toFixed(2)}`;
  } else if (absNum >= 1e3 && absNum < 1e6) {
    return `${sign}$${(absNum / 1e3).toFixed(0)}k`; // Thousands
  } else if (absNum >= 1e6 && absNum < 1e9) {
    return `${sign}$${Number(
      (absNum / 1e6).toFixed(numMillionsDecimals)
    ).toLocaleString(undefined, {
      minimumFractionDigits: numMillionsDecimals,
      maximumFractionDigits: numMillionsDecimals,
    })}m`; // Millions
  } else if (absNum >= 1e9 && absNum < 1e12) {
    return `${sign}$${Number(
      (absNum / 1e9).toFixed(numBillionsDecimals)
    ).toLocaleString(undefined, {
      minimumFractionDigits: numBillionsDecimals,
      maximumFractionDigits: numBillionsDecimals,
    })}B`; // Billions
  } else if (absNum >= 1e12 && absNum < 1e15) {
    return `${sign}$${Number((absNum / 1e12).toFixed(2)).toLocaleString()}T`; // Trillions
  } else {
    return `${sign}$${absNum.toFixed(2).toLocaleString()}`; // If the number exceeds billions or is negative, return it as a string.
  }
}
