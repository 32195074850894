import ConfirmationModalButton, { ConfirmationModalProps } from 'components/ConfirmationModalButton'
import { useState } from 'react'
import { isNone, isSome, isTrue, Optional } from 'safety'
import { BasicButton } from '../BasicButton'
import { AutoCompleteCell } from './AutoCompleteCell'
import { BooleanCell } from './BooleanCell'
import {
  AnyColumnProps,
  AnyEditableTextColumnProps,
  AnyTextColumnProps,
  ColumnInputType,
  columnPropsInputType,
  CreateColumnProps,
  EditableAutoCompleteColumnProps,
  EditableBooleanColumnProps,
  EditableMultiSelectColumnProps,
  isColumnCreateable,
} from './ColumnProps'
import EditableTextCell from './EditableTextCell'
import { MultiSelectCell } from './MultiSelectCell'

export const defaultTableCellPadding = 'pl-4'

export interface TableProps<T, N extends object> {
  columns: AnyColumnProps<T, N>[]
  rowData: T[]
  onRowCreate?: (createdObject: N) => void
  stickyHeader?: boolean
  stickyColumn?: boolean
  bgColor?: string
  maxHeight?: string
  deleteProps?: {
    onDelete: (rowIndex: number) => void
    confirmationModalProps?: Omit<ConfirmationModalProps, 'onConfirm' | 'button'>
  }
}

type CellIndex = {
  row: number
  column: number
}

export function EditableTable<T, N extends object>(props: TableProps<T, N>) {
  const [isAddingRow, setIsAddingRow] = useState(false)
  const rowDataRenderable: T[] = props.rowData
  const [creatingObject, setCreatingObject] = useState<any>({})

  const bgColor = 'bg-white'

  function renderCreateableCell(columnIndex: number) {
    let column = props.columns[columnIndex]
    if (isColumnCreateable(column)) {
      let columnInputType = columnPropsInputType(column)
      let createProps = column.createProps as CreateColumnProps<string, N>
      if (columnInputType === ColumnInputType.EditableMultiSelect) {
        let _column = column as EditableMultiSelectColumnProps<T, N>

        let options = typeof _column.options === 'function' ? _column.options(creatingObject) : _column.options
        return (
          <MultiSelectCell
            options={options}
            initiallySelected={createProps.defaultOption ? createProps.defaultOption : ''}
            onSelect={(newValue: string) => {
              setCreatingObject({ ...creatingObject, [createProps.createdObjectKey]: newValue })
            }}
          />
        )
      } else if (columnInputType === ColumnInputType.EditableAutoComplete) {
        if (columnInputType === ColumnInputType.EditableAutoComplete) {
          let _column = column as EditableAutoCompleteColumnProps<T, N>
          return (
            <AutoCompleteCell
              options={_column.autoCompleteOptions}
              placeholder={_column.placeholder}
              onSelect={(newValue) => {
                setCreatingObject({ ...creatingObject, [createProps.createdObjectKey]: newValue })
              }}
            />
          )
        }
      } else if (columnInputType === ColumnInputType.EditableBoolean) {
        let _column = column as EditableBooleanColumnProps<T, N>
        let isSelected = _column.createProps?.defaultOption === true
        return (
          <BooleanCell
            isSelected={isSelected}
            onSelect={(value) => {
              setCreatingObject({ ...creatingObject, [createProps.createdObjectKey]: value })
            }}
          />
        )
      } else if (
        columnInputType === ColumnInputType.EditableInput ||
        columnInputType === ColumnInputType.NonEditableInput
      ) {
        let _column = column as AnyTextColumnProps<T, N>
        let shouldFocus = columnIndex === 0
        return (
          <EditableTextCell
            cellFormat={_column.textCellFormat as any}
            placeholder={_column.placeholder ? _column.placeholder : ''}
            initialValue={_column?.createProps?.defaultOption}
            shouldFocus={shouldFocus}
            isCreating={true}
            onEditsFinished={(newValue: any) => {
              setCreatingObject({ ...creatingObject, [createProps.createdObjectKey]: newValue })
            }}
          />
        )
      }
    } else {
      return <div className="pl-2 opacity-60">auto</div>
    }
  }

  // UNSAFE: right now we don't guarantee that the content pulled off of the object matches the intended type from the column
  // fine for now
  function renderEditableCell(cellIndex: CellIndex) {
    let column = props.columns[cellIndex.column]
    const rowDatum = props.rowData[cellIndex.row]
    const content = column.accessor(rowDatum) as any
    let columnInputType = columnPropsInputType(column)
    if (columnInputType === ColumnInputType.EditableMultiSelect) {
      let _column = column as EditableMultiSelectColumnProps<T, N>
      let options = typeof _column.options === 'function' ? _column.options(rowDatum) : _column.options

      return (
        <MultiSelectCell
          options={options}
          initiallySelected={content}
          onSelect={(selected) => {
            _column.updateRemote(rowDatum, selected)
          }}
        />
      )
    } else if (columnInputType === ColumnInputType.EditableAutoComplete) {
      let _column = column as EditableAutoCompleteColumnProps<T, N>

      return (
        <AutoCompleteCell
          options={_column.autoCompleteOptions}
          initiallySelected={content}
          createModal={
            _column.createModal
              ? _column.createModal({
                  onCreate: (newObject) => {
                    _column.updateRemote(rowDatum, (_column as any).onCreate(newObject)) // TODO: fix
                  },
                })
              : undefined
          }
          onSelect={(selected) => {
            _column.updateRemote(rowDatum, selected)
          }}
          placeholder={_column.placeholder}
        />
      )
    } else if (columnInputType === ColumnInputType.EditableBoolean) {
      let _column = column as EditableBooleanColumnProps<T, N>
      return (
        <BooleanCell
          isSelected={content === true}
          onSelect={(value) => {
            _column.updateRemote(rowDatum, value)
          }}
        />
      )
    } else if (columnInputType === ColumnInputType.EditableInput) {
      let _column = column as AnyEditableTextColumnProps<T, N>
      return (
        <EditableTextCell<string>
          cellFormat={_column.textCellFormat as any}
          initialValue={content}
          isCreating={false}
          onEditsFinished={(newValue: Optional<string>) => {
            if (newValue === '') {
              newValue = null
            }

            _column.updateRemote(rowDatum, newValue as never)
          }}
        />
      )
    } else if (columnInputType === ColumnInputType.NonEditableInput) {
      let _column = column as AnyTextColumnProps<T, N>
      return isSome(content) ? (
        <div className={`${defaultTableCellPadding} opacity-100`}>
          {_column.textCellFormat.viewModeString(content as never)}
        </div>
      ) : (
        <div className={`${defaultTableCellPadding} opacity-60`}></div>
      )
    }
  }

  function AddRowCell() {
    return (
      <div
        className="cursor-pointer text-sm opacity-70"
        onClick={() => {
          let newObject: any = {}
          for (let column of props.columns) {
            if (isColumnCreateable(column)) {
              let createProps = column.createProps!
              let defaultOption = createProps.defaultOption
              if (isSome(defaultOption)) {
                newObject[createProps.createdObjectKey] = defaultOption
              }
            }
          }
          setCreatingObject(newObject)
          setIsAddingRow(true)
        }}>
        + Add
      </div>
    )
  }

  function renderColumnHeader(header: string, index: number) {
    const borderRightClass = index !== props.columns.length ? `border-r` : ''
    const minWidthPixels = props.columns[index]?.minWidthPixels
    return (
      <th
        className={`w-25vw border-b ${borderRightClass} ${props.stickyHeader ? 'sticky top-0' : ''}  ${index === 0 && props.stickyColumn ? ' left-0 border-r' : ''} p-4 text-left ${index === 0 && (props.stickyColumn || props.stickyHeader) ? 'sticky z-20' : 'z-10'} bg-table-background`}
        key={`column-header-${index}`}
        style={{ minWidth: minWidthPixels ? `${minWidthPixels}px` : 'auto' }}>
        <div className={`text-sm font-medium text-table-text `}>{header}</div>
      </th>
    )
  }
  const rowHeightValue = 56
  const rowHeight = `${rowHeightValue}px`

  return (
    <div className="w-full">
      <div className={`relative w-full overflow-auto rounded-md border ${props.maxHeight}`}>
        <table className="m-0 min-w-full table-fixed border-separate border-spacing-0 whitespace-nowrap border-none">
          {props.rowData && (
            <thead>
              <tr className="column-headers">
                {props.columns.map((column, index) => {
                  return renderColumnHeader(column.header, index)
                })}
                {isSome(props.deleteProps) && renderColumnHeader('del', props.columns.length)}
              </tr>
            </thead>
          )}
          <tbody>
            {rowDataRenderable.map((rowDatum, rowIndex) => {
              const borderBottom =
                isNone(props.onRowCreate) && rowIndex === rowDataRenderable.length - 1 ? '' : 'border-b'
              return (
                <tr className={`text-sm`} key={`row-${rowIndex}`} style={{ height: rowHeight }}>
                  {props.columns.map((column, columnIndex) => {
                    return columnIndex === 0 && props.stickyColumn ? (
                      <th
                        className={`sticky left-0 z-10 border-r ${bgColor} text-left ${borderBottom} focus-within:border-light-blue-500 font-normal `}
                        key={`cell-${rowIndex}-${columnIndex}`}>
                        <div
                          className={`focus-within:border-light-blue-500 z-20 m-1 flex h-[${rowHeightValue - 6}px] items-center justify-center bg-white p-1 p-1 focus-within:bg-white focus-within:ring-2`}>
                          {renderEditableCell({
                            row: rowIndex,
                            column: columnIndex,
                          })}
                        </div>
                      </th>
                    ) : (
                      <td
                        style={{ height: `${rowHeightValue - 2}px` }}
                        className={`text-left ${borderBottom} relative border-r bg-white p-0`}
                        key={`cell-${rowIndex}-${columnIndex}`}>
                        <div
                          className={`focus-within:border-light-blue-500 z-20 m-1 flex h-[${rowHeightValue - 6}px] items-center justify-center bg-white p-1 p-1 focus-within:bg-white focus-within:ring-2`}>
                          {renderEditableCell({ row: rowIndex, column: columnIndex })}
                        </div>
                      </td>
                    )
                  })}
                  {isSome(props.deleteProps) && (
                    <td
                      className={`${defaultTableCellPadding} text-left ${borderBottom} pr-4`}
                      key={`cell-${rowIndex}-${props.columns.length}`}>
                      {props.deleteProps.confirmationModalProps != null ? (
                        <ConfirmationModalButton
                          {...props.deleteProps.confirmationModalProps}
                          onConfirm={() => {
                            props.deleteProps?.onDelete(rowIndex)
                          }}
                          button={
                            <BasicButton className="text-xs" variant="secondary" onClick={() => {}}>
                              Delete
                            </BasicButton>
                          }
                        />
                      ) : (
                        <BasicButton
                          className="text-xs"
                          variant="secondary"
                          onClick={() => {
                            props.deleteProps?.onDelete(rowIndex)
                          }}>
                          Delete
                        </BasicButton>
                      )}
                    </td>
                  )}
                </tr>
              )
            })}
            {props.onRowCreate && !isAddingRow && (
              <tr className={` text-sm`} key={`create`} style={{ height: rowHeight }}>
                {isTrue(props.stickyColumn) ? (
                  <th
                    className={`sticky left-0 z-10 border-r ${bgColor} ${defaultTableCellPadding} text-left font-normal`}
                    key={`add-row`}>
                    <AddRowCell />
                  </th>
                ) : (
                  <td className={`border-r ${defaultTableCellPadding} text-left`} key={`add-row`}>
                    <AddRowCell />
                  </td>
                )}
                {[...Array(props.columns.length)].map((_, index) => {
                  const borderRightClass = index !== props.columns.length - 1 ? `border-r` : ''
                  return (
                    <td className={`${borderRightClass} ${defaultTableCellPadding} text-left`} key={`create-${index}`}>
                      <div />
                    </td>
                  )
                })}
              </tr>
            )}
            {isAddingRow && (
              <tr className={`text-sm`} key={`row-isAdding`} style={{ height: rowHeight }}>
                {props.columns.map((column, columnIndex) => {
                  return columnIndex === 0 && props.stickyColumn ? (
                    <th
                      className={`sticky left-0 z-10 border-r ${bgColor} ${defaultTableCellPadding} border-b text-left font-normal`}
                      key={`cell-adding-${columnIndex}`}>
                      <div
                        className={`focus-within:border-light-blue-500 z-20  m-1 flex p-1 h-[${rowHeightValue - 6}px] items-center justify-center bg-white  focus-within:bg-white focus-within:ring-2`}>
                        {renderCreateableCell(columnIndex)}
                      </div>
                    </th>
                  ) : (
                    <td className={`border-b border-r text-left`} key={`cell-adding-${columnIndex}`}>
                      <div
                        className={`focus-within:border-light-blue-500 z-20  m-1 flex p-1 h-[${rowHeightValue - 6}px] items-center justify-center bg-white  focus-within:bg-white focus-within:ring-2`}>
                        {renderCreateableCell(columnIndex)}
                      </div>
                    </td>
                  )
                })}
                <td className={`border-b ${defaultTableCellPadding} text-left`} key={`cell-adding-del`}>
                  <div />
                </td>
              </tr>
            )}
            {isAddingRow && (
              <tr className="cursor-pointer">
                <td className="p-2">
                  <BasicButton
                    variant="gray"
                    className="text-sm"
                    onClick={() => {
                      if (props.onRowCreate) {
                        for (let column of props.columns) {
                          if (isColumnCreateable(column)) {
                            let createProps = column.createProps!
                            if (createProps.isRequired && isNone(creatingObject[createProps.createdObjectKey])) {
                              return
                            }
                          }
                        }
                        props.onRowCreate(creatingObject)
                      }
                      setIsAddingRow(false)
                      setCreatingObject({})
                    }}>
                    Submit row
                  </BasicButton>
                  <BasicButton
                    className={`ml-2  border-red-500 bg-red-500 text-sm`}
                    onClick={() => {
                      setIsAddingRow(false)
                      setCreatingObject({})
                    }}>
                    Cancel
                  </BasicButton>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  )
}
