import { FundingRound, SampleData } from 'pages/valuation-calculator/types'

export const companySampleDataMap: Map<string, SampleData> = new Map([])

// Uber
const uberFundingRounds: FundingRound[] = [
  { date: new Date(2012, 1, 1), valuation: 5_800_000 },
  { date: new Date(2013, 1, 1), valuation: 60_000_000 },
  { date: new Date(2014, 1, 1), valuation: 346_500_000 },
  { date: new Date(2015, 1, 1), valuation: 1_820_000_000 },
  { date: new Date(2016, 1, 1), valuation: 3_700_000_000 },
  { date: new Date(2017, 1, 1), valuation: 41_000_000_000 },
  { date: new Date(2018, 1, 1), valuation: 42_000_000_000 },
  { date: new Date(2019, 1, 1), valuation: 66_000_000_000 },
]
const uberTargetExitDate = new Date(2020, 1, 1)
const uberTargetExitValuation = 80_000_000_000
const uberSampleData: SampleData = {
  fundingRounds: uberFundingRounds,
  incorporationDate: new Date(2011, 1, 1),
  exitScenarios: [{ date: uberTargetExitDate, valuation: uberTargetExitValuation }],
}
companySampleDataMap.set('Uber', uberSampleData)

// Coinbase
const coinbaseFundingRounds: FundingRound[] = [
  { date: new Date(2012, 1, 1), valuation: 200_000 },
  { date: new Date(2013, 1, 1), valuation: 1_000_000 },
  { date: new Date(2015, 1, 1), valuation: 2_760_000 },
  { date: new Date(2017, 1, 1), valuation: 8_250_000 },
  { date: new Date(2018, 1, 1), valuation: 36_190_000 },
  { date: new Date(2020, 1, 1), valuation: 28_830_000 },
  { date: new Date(2021, 1, 1), valuation: 343_580_000 },
]
const coinbaseTargetExitDate = new Date(2022, 1, 1)
const coinbaseTargetExitValuation = 381_000_000
const coinbaseSampleData: SampleData = {
  fundingRounds: coinbaseFundingRounds,
  incorporationDate: new Date(2012, 1, 1),
  exitScenarios: [{ date: coinbaseTargetExitDate, valuation: coinbaseTargetExitValuation }],
}
companySampleDataMap.set('Coinbase', coinbaseSampleData)

// Figma
const figmaFundingRounds: FundingRound[] = [
  { date: new Date(2013, 1, 1), valuation: 11_000_000 },
  { date: new Date(2015, 1, 1), valuation: 34_000_000 },
  { date: new Date(2018, 1, 1), valuation: 90_000_000 },
  { date: new Date(2019, 1, 1), valuation: 400_000_000 },
  { date: new Date(2020, 1, 1), valuation: 1_900_000_000 },
  { date: new Date(2021, 1, 1), valuation: 9_800_000_000 },
]
const figmaTargetExitDate = new Date(2022, 1, 1)
const figmaTargetExitValuation = 20_000_000_000
const figmaSampleData: SampleData = {
  fundingRounds: figmaFundingRounds,
  incorporationDate: new Date(2013, 1, 1),
  exitScenarios: [{ date: figmaTargetExitDate, valuation: figmaTargetExitValuation }],
}
companySampleDataMap.set('Figma', figmaSampleData)

// Fake Figma
const fakeFigmaFundingRounds: FundingRound[] = [
  { date: new Date(2013, 1, 1), valuation: 11_000_000 },
  { date: new Date(2015, 1, 1), valuation: 34_000_000 },
  { date: new Date(2018, 1, 1), valuation: 90_000_000 },
  { date: new Date(2019, 1, 1), valuation: 400_000_000 },
  { date: new Date(2020, 1, 1), valuation: 1_900_000_000 },
  { date: new Date(2021, 1, 1), valuation: 4_000_000_000 },
]
const fakeFigmaTargetExitDate = new Date(2022, 1, 1)
const fakeFigmaTargetExitValuation = 20_000_000_000
const fakeFigmaSampleData: SampleData = {
  fundingRounds: fakeFigmaFundingRounds,
  incorporationDate: new Date(2013, 1, 1),
  exitScenarios: [{ date: fakeFigmaTargetExitDate, valuation: fakeFigmaTargetExitValuation }],
}
companySampleDataMap.set('Fake Figma', fakeFigmaSampleData)

// Komodo
const komodoFundingRounds: FundingRound[] = [
  { date: new Date(2014, 1, 1), valuation: 10_000_000 },
  { date: new Date(2015, 1, 1), valuation: 15_000_000 },
  { date: new Date(2016, 1, 1), valuation: 50_000_000 },
  { date: new Date(2018, 1, 1), valuation: 240_000_000 },
  { date: new Date(2020, 1, 1), valuation: 1_200_000_000 },
  { date: new Date(2021, 1, 1), valuation: 3_300_000_000 },
]
const komodoTargetExitDate = new Date(2026, 1, 1)
const komodoTargetExitValuation = 5_500_000_000
const komodoSampleData: SampleData = {
  fundingRounds: komodoFundingRounds,
  incorporationDate: new Date(2014, 1, 1),
  exitScenarios: [{ date: komodoTargetExitDate, valuation: komodoTargetExitValuation }],
}
companySampleDataMap.set('Komodo', komodoSampleData)

// Overvalued before IPO
const overvaluedFundingRounds: FundingRound[] = [
  { date: new Date(2013, 1, 1), valuation: 11_000_000 },
  { date: new Date(2015, 1, 1), valuation: 34_000_000 },
  { date: new Date(2018, 1, 1), valuation: 90_000_000 },
  { date: new Date(2019, 1, 1), valuation: 400_000_000 },
  { date: new Date(2020, 1, 1), valuation: 1_900_000_000 },
  { date: new Date(2021, 1, 1), valuation: 9_800_000_000 },
  { date: new Date(2022, 1, 1), valuation: 10_000_000_000 },
  { date: new Date(2023, 1, 1), valuation: 9_800_000_000 },
  { date: new Date(2024, 1, 1), valuation: 9_600_000_000 },
  { date: new Date(2025, 1, 1), valuation: 9_900_000_000 },
  { date: new Date(2026, 1, 1), valuation: 9_850_000_000 },
  { date: new Date(2027, 1, 1), valuation: 13_000_000_000 },
]
const overvaluedTargetExitDate = new Date(2028, 1, 1)
const overvaluedTargetExitValuation = 10_000_000_000
const overvaluedSampleData: SampleData = {
  fundingRounds: overvaluedFundingRounds,
  incorporationDate: new Date(2013, 1, 1),
  exitScenarios: [{ date: overvaluedTargetExitDate, valuation: overvaluedTargetExitValuation }],
}
companySampleDataMap.set('Overvalued before IPO', overvaluedSampleData)

// Rocket then burnout
const rocketFundingRounds: FundingRound[] = [
  { date: new Date(2000, 1, 1), valuation: 11_000_000 },
  { date: new Date(2001, 1, 1), valuation: 34_000_000 },
  { date: new Date(2002, 1, 1), valuation: 90_000_000 },
  { date: new Date(2003, 1, 1), valuation: 400_000_000 },
]
const rocketTargetExitDate = new Date(2011, 1, 1)
const rocketTargetExitValuation = 100_000_000
const rocketSampleData: SampleData = {
  fundingRounds: rocketFundingRounds,
  incorporationDate: new Date(2000, 1, 1),
  exitScenarios: [{ date: rocketTargetExitDate, valuation: rocketTargetExitValuation }],
}
companySampleDataMap.set('Rocket then burnout', rocketSampleData)

// Slow Steady
const slowSteadyFundingRounds: FundingRound[] = [
  { date: new Date(2000, 1, 1), valuation: 11_000_000 },
  { date: new Date(2001, 1, 1), valuation: 11_660_000 },
  { date: new Date(2002, 1, 1), valuation: 12_360_000 },
  { date: new Date(2003, 1, 1), valuation: 13_100_000 },
  { date: new Date(2004, 1, 1), valuation: 13_890_000 },
  { date: new Date(2005, 1, 1), valuation: 14_720_000 },
  { date: new Date(2006, 1, 1), valuation: 15_600_000 },
  { date: new Date(2007, 1, 1), valuation: 16_540_000 },
  { date: new Date(2008, 1, 1), valuation: 17_530_000 },
  { date: new Date(2009, 1, 1), valuation: 18_580_000 },
  { date: new Date(2010, 1, 1), valuation: 19_700_000 },
  { date: new Date(2011, 1, 1), valuation: 20_880_000 },
  { date: new Date(2012, 1, 1), valuation: 22_130_000 },
  { date: new Date(2013, 1, 1), valuation: 23_460_000 },
  { date: new Date(2014, 1, 1), valuation: 24_870_000 },
  { date: new Date(2015, 1, 1), valuation: 26_360_000 },
  { date: new Date(2016, 1, 1), valuation: 27_940_000 },
  { date: new Date(2017, 1, 1), valuation: 29_620_000 },
  { date: new Date(2018, 1, 1), valuation: 31_400_000 },
  { date: new Date(2019, 1, 1), valuation: 33_280_000 },
]
const slowSteadyTargetExitDate = new Date(2020, 1, 1)
const slowSteadyTargetExitValuation = 35_280_000
const slowSteadySampleData: SampleData = {
  fundingRounds: slowSteadyFundingRounds,
  incorporationDate: new Date(2000, 1, 1),
  exitScenarios: [{ date: slowSteadyTargetExitDate, valuation: slowSteadyTargetExitValuation }],
}
companySampleDataMap.set('Slow Steady', slowSteadySampleData)

// Slow Steady then Rocket (Early, mid, late)
const slowSteadyRocketFundingRounds: FundingRound[] = [
  { date: new Date(2000, 1, 1), valuation: 11_000_000 },
  { date: new Date(2001, 1, 1), valuation: 11_660_000 },
  { date: new Date(2002, 1, 1), valuation: 12_360_000 },
  { date: new Date(2003, 1, 1), valuation: 13_100_000 },
  { date: new Date(2004, 1, 1), valuation: 13_890_000 },
  { date: new Date(2005, 1, 1), valuation: 14_720_000 },
  { date: new Date(2006, 1, 1), valuation: 15_600_000 },
  { date: new Date(2007, 1, 1), valuation: 16_540_000 },
  { date: new Date(2008, 1, 1), valuation: 26_460_000 },
  { date: new Date(2009, 1, 1), valuation: 42_340_000 },
  { date: new Date(2010, 1, 1), valuation: 67_750_000 },
  { date: new Date(2011, 1, 1), valuation: 108_400_000 },
  { date: new Date(2012, 1, 1), valuation: 173_430_000 },
  { date: new Date(2013, 1, 1), valuation: 277_490_000 },
  { date: new Date(2014, 1, 1), valuation: 443_990_000 },
  { date: new Date(2015, 1, 1), valuation: 710_380_000 },
  { date: new Date(2016, 1, 1), valuation: 1_136_620_000 },
  { date: new Date(2017, 1, 1), valuation: 1_818_580_000 },
  { date: new Date(2018, 1, 1), valuation: 2_909_740_000 },
  { date: new Date(2019, 1, 1), valuation: 4_655_580_000 },
]
const slowSteadyRocketTargetExitDate = new Date(2020, 1, 1)
const slowSteadyRocketTargetExitValuation = 7_448_920_000
const slowSteadyRocketSampleData: SampleData = {
  fundingRounds: slowSteadyRocketFundingRounds,
  incorporationDate: new Date(2000, 1, 1),
  exitScenarios: [{ date: slowSteadyRocketTargetExitDate, valuation: slowSteadyRocketTargetExitValuation }],
}
companySampleDataMap.set('Slow Steady then Rocket (Early, mid, late)', slowSteadyRocketSampleData)

// Camel rocket
const camelRocketFundingRounds: FundingRound[] = [
  { date: new Date(2000, 1, 1), valuation: 11_000_000 },
  { date: new Date(2001, 1, 1), valuation: 20_000_000 },
  { date: new Date(2002, 1, 1), valuation: 35_000_000 },
  { date: new Date(2003, 1, 1), valuation: 34_000_000 },
  { date: new Date(2004, 1, 1), valuation: 15_000_000 },
  { date: new Date(2005, 1, 1), valuation: 14_000_000 },
  { date: new Date(2006, 1, 1), valuation: 10_000_000 },
  { date: new Date(2007, 1, 1), valuation: 17_000_000 },
  { date: new Date(2008, 1, 1), valuation: 27_200_000 },
  { date: new Date(2009, 1, 1), valuation: 43_520_000 },
  { date: new Date(2010, 1, 1), valuation: 69_630_000 },
  { date: new Date(2011, 1, 1), valuation: 111_410_000 },
  { date: new Date(2012, 1, 1), valuation: 178_260_000 },
  { date: new Date(2013, 1, 1), valuation: 285_210_000 },
  { date: new Date(2014, 1, 1), valuation: 456_340_000 },
  { date: new Date(2015, 1, 1), valuation: 730_140_000 },
  { date: new Date(2016, 1, 1), valuation: 1_168_230_000 },
  { date: new Date(2017, 1, 1), valuation: 1_869_170_000 },
  { date: new Date(2018, 1, 1), valuation: 2_990_670_000 },
  { date: new Date(2019, 1, 1), valuation: 4_785_070_000 },
]
const camelRocketTargetExitDate = new Date(2020, 1, 1)
const camelRocketTargetExitValuation = 7_656_120_000
const camelRocketSampleData: SampleData = {
  fundingRounds: camelRocketFundingRounds,
  incorporationDate: new Date(2000, 1, 1),
  exitScenarios: [{ date: camelRocketTargetExitDate, valuation: camelRocketTargetExitValuation }],
}
companySampleDataMap.set('Camel rocket', camelRocketSampleData)

// Camel Rocket Burnout
const camelRocketBurnoutFundingRounds: FundingRound[] = [
  { date: new Date(2000, 1, 1), valuation: 11_000_000 },
  { date: new Date(2001, 1, 1), valuation: 20_000_000 },
  { date: new Date(2002, 1, 1), valuation: 35_000_000 },
  { date: new Date(2003, 1, 1), valuation: 34_000_000 },
  { date: new Date(2004, 1, 1), valuation: 15_000_000 },
  { date: new Date(2005, 1, 1), valuation: 14_000_000 },
  { date: new Date(2006, 1, 1), valuation: 10_000_000 },
  { date: new Date(2007, 1, 1), valuation: 17_000_000 },
  { date: new Date(2008, 1, 1), valuation: 27_200_000 },
  { date: new Date(2009, 1, 1), valuation: 43_520_000 },
  { date: new Date(2010, 1, 1), valuation: 69_630_000 },
  { date: new Date(2011, 1, 1), valuation: 111_410_000 },
  { date: new Date(2012, 1, 1), valuation: 178_260_000 },
  { date: new Date(2013, 1, 1), valuation: 285_210_000 },
  { date: new Date(2014, 1, 1), valuation: 456_340_000 },
  { date: new Date(2019, 1, 1), valuation: 100_000_000 },
]
const camelRocketBurnoutTargetExitDate = new Date(2020, 1, 1)
const camelRocketBurnoutTargetExitValuation = 120_000_000
const camelRocketBurnoutSampleData: SampleData = {
  fundingRounds: camelRocketBurnoutFundingRounds,
  incorporationDate: new Date(2000, 1, 1),
  exitScenarios: [{ date: camelRocketBurnoutTargetExitDate, valuation: camelRocketBurnoutTargetExitValuation }],
}
companySampleDataMap.set('Camel Rocket Burnout', camelRocketBurnoutSampleData)

export const defaultCompanySampleData: SampleData = {
  fundingRounds: [
    { date: new Date(2014, 1, 1), valuation: 10_000_000 },
    { date: new Date(2015, 1, 1), valuation: 15_000_000 },
    { date: new Date(2016, 1, 1), valuation: 50_000_000 },
    { date: new Date(2018, 1, 1), valuation: 240_000_000 },
    { date: new Date(2020, 1, 1), valuation: 1_200_000_000 },
    { date: new Date(2021, 1, 1), valuation: 3_300_000_000 },
  ],
  incorporationDate: new Date(2014, 1, 1),
  exitScenarios: [{ date: new Date(2025, 1, 1), valuation: 500_000_000 }],
}
