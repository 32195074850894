import { IrrDistributionGraphData } from 'plural-shared/lookbackV2/sensitivity/IrrDistributionGenerator'
import { numberToPercentString } from 'plural-shared/utils'
import { trpc } from 'utils/trpc'
import { BasicButton } from '../../components/BasicButton'
import IRRDistributionGraph, { IRRDistributionGraphProps } from '../../components/IRRDistributionGraph'
import SmallStat from '../../components/SmallStat'
import Disclaimer from '../portfolio/Disclaimer'

enum GraphType {
  GrossFundIrr = 'grossFundIrr',
  NetFundIrr = 'netFundIrr',
  EquityDealIrr = 'equityDealIrr',
  PluralDealIrr = 'pluralDealIrr',
}

export default function FundSensitivity() {
  const indivDealSimulationQuery = trpc.fundSensitivity.newIndivDealSimulation.useQuery(undefined, {})
  const indivDealSimulationData = indivDealSimulationQuery.data
  const fundSimulationQuery = trpc.fundSensitivity.newFundSimulation.useQuery(undefined, {})
  const fundSimulationData = fundSimulationQuery.data

  function graphForData(data: IrrDistributionGraphData | undefined, graphType: GraphType) {
    if (!data || !indivDealSimulationData) {
      return null
    }
    let props: IRRDistributionGraphProps = {
      subheading: '',
      title: '',
      height: 300,
    }
    switch (graphType) {
      case GraphType.GrossFundIrr:
        props.subheading = `Percent frequency of Fund IRRs across ${data.numOutcomesSimulated} simulated funds. Performance of holdings randomly generated along the same distribution as above`
        props.title = 'Plural Gross Fund Returns'
        props.height = 350
        break
      case GraphType.NetFundIrr:
        break
      case GraphType.EquityDealIrr:
        props.subheading = `Percent frequency of investor returns across ${data.numOutcomesSimulated} simulated late-stage equity investments`
        props.title = 'Traditional Late-Stage Equity Returns'
        break
      case GraphType.PluralDealIrr:
        props.subheading = `Percent frequency of Plural returns on the same ${data.numOutcomesSimulated} late-stage equities`
        props.title = 'Plural Returns on Same Late-Stage Equities'
        break
    }
    return <IRRDistributionGraph data={data} props={props} />
  }

  return (
    <div className="mt-8 flex w-full flex-col items-center pb-36 lg:mb-12">
      <div className="mt-20 flex w-full flex-col items-center pl-4 pr-4 lg:max-w-5xl">
        <div className="w-full">
          <div className="text-4xl font-semibold">Plural Fund Simulations</div>
          <div className="mt-2 text-sm opacity-50">
            Simulate Plural Fund outcomes for differing performances in underlying late-stage equities.
          </div>
          <div className="mt-16">
            <div className="mt-8 flex items-center justify-between">
              <div className="pr-8 text-2xl font-medium">Individual Deals</div>
              <div>
                <BasicButton
                  onClick={() => indivDealSimulationQuery.refetch()}
                  variant="secondary"
                  textSize="text-base">
                  Simulate
                </BasicButton>
              </div>
            </div>
            <div className="mt-8 block space-y-8 lg:flex lg:space-x-12 lg:space-y-0">
              {/* {graphForData(simulationData?.netFundIrrGraphData)} */}
              {graphForData(indivDealSimulationData?.equityDealIrrGraphData, GraphType.EquityDealIrr)}
              {graphForData(indivDealSimulationData?.pluralDealIrrGraphData, GraphType.PluralDealIrr)}
            </div>
          </div>
          <div>
            <div className="mt-20 flex items-center justify-between">
              <div className="pr-8 text-2xl font-medium">Plural Funds</div>
              <div>
                <BasicButton onClick={() => fundSimulationQuery.refetch()} variant="secondary" textSize="text-base">
                  Simulate
                </BasicButton>
              </div>
            </div>
            <div className="mt-8">
              {graphForData(fundSimulationData?.grossFundIrrGraphData, GraphType.GrossFundIrr)}
            </div>
            {fundSimulationData?.grossFundIrrMetrics && (
              <div className="flex flex-col space-x-0 space-y-4 pt-6 lg:flex-row lg:space-x-12 lg:space-y-0">
                <SmallStat
                  label="Median Gross Fund IRR"
                  value={numberToPercentString(fundSimulationData?.grossFundIrrMetrics.median, 1) || ''}
                />
                {/* <SmallStat
                  label="Median Net Fund IRR"
                  value={numberToPercentString(fundSimulationData?.medianNetFundIrr, 1) || ''}
                /> */}
                <SmallStat
                  label="Lower Quartile Gross Fund IRR"
                  value={numberToPercentString(fundSimulationData?.grossFundIrrMetrics.lowerQuartile, 1) || ''}
                />
                <SmallStat
                  label="Upper Quartile Gross Fund IRR"
                  value={numberToPercentString(fundSimulationData?.grossFundIrrMetrics.upperQuartile, 1) || ''}
                />
              </div>
            )}
          </div>
          <Disclaimer />
        </div>
      </div>
    </div>
  )
}
