import { useQuery } from '@tanstack/react-query'
import {
  autoDetermineBaseSharePriceWithYears,
  DEFAULT_FUND_MIN_CAGR,
  TargetMultipleGeneratorInputs,
  TargetMultipleOutcomeGenerator,
  ValueShareCashAdjustedTxPriceLineGenerator,
  ValueShareSecondaryComparableTxGeneratorInputs,
  ValueShareTxWithSecondaryComparable,
} from 'plural-shared/lookbackV2'
import { calculateCAGR, makeStateObject, possiblyNegativeYearsBetweenDates, StateObject } from 'plural-shared/utils'
import { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { anyAreNone, ArrayElement, isNone, isSome } from 'safety'
import { useVanillaTRPC } from '../../providers/TRPCProvider'
import { RouterOutputs, trpc } from '../../utils/trpc'
import { useQueryParam } from '../../utils/useQueryParam'
import { NetCashGraphProps } from './NetCashGraph'
import { ValuationGraphData } from './ValuationGraph'
type CompanyCashSimulatorProfile = RouterOutputs['cashSimulator']['companyProfile']
export type CompanyOption = ArrayElement<RouterOutputs['cashSimulator']['companyProfileOptions']>
export type CompanyOptions = CompanyOption[]

const defaults = {
  companyId: 22,
  yearsInFutureSelected: 3,
  secondarySharePrice: 'auto',
  selectedMultipleIndex: 2,
}

export const constantTxGeneratorInputs = {
  altInvestmentIrr: 0.1,
  secondaryBrokerTakeRate: 0.05,
  totalTaxRate: 0.35,
  principal: 1_000_000,
}

export const defaultPossibleOutcomeMultiples = [...generateArray(0.2, 0.02, 40), ...generateArray(1.0, 0.02, 250)]

interface TxInputs {
  principal: StateObject<number | undefined>
  reserveRatio: StateObject<number | undefined>
  totalTaxRate: StateObject<number>
  altInvestmentIrr: StateObject<number>
  secondaryBrokerTakeRate: StateObject<number>
  yearsToOutcome: StateObject<number>
  secondarySharePrice: StateObject<number | undefined>
  mostRecentFinancingEventIssuePrice: StateObject<number | undefined>
  mostRecentFinancingEventDate: StateObject<Date | undefined>
  nextFinancingEventIssuePrice: StateObject<number | undefined>
  yearsUntilNextFinancingEvent: StateObject<number>
  shouldUseAutoBaseSharePrice: StateObject<boolean>
}

type SimulatorContext = {
  selectedCompanyProps: SelectedCompanyProps
  companyOptions: CompanyOptions | undefined
  isLoadingCompanyProfile: boolean
  multiplesSlider: {
    selectableMultiples: number[]
    activeMultipleIndex: number
    setActiveMultipleIndex: (multipleIndex: number) => void
    activeCompanyValuation: number | undefined
  }
  pluralCashTx: ValueShareTxWithSecondaryComparable | undefined
  didSelectCompanyName: (name: string) => void
  netCashGraphProps: NetCashGraphProps | undefined
  txPriceValuationGraphData: ValuationGraphData | undefined
  breakenvenMultipleIndex: number | undefined
  closestDefaultMultiplesSelectedIndex: number | undefined
  txInputs: TxInputs
  setActiveCompanyValuation: (valuation: number) => void
  selectedCompanyMultiple: number
}
const simulatorContext = createContext<SimulatorContext | undefined>(undefined)

interface SelectedCompanyProps {
  profile: CompanyCashSimulatorProfile | undefined
  baseValuation: number | undefined
}

function findClosestDefaultMultipleIndex(multiple: number): number {
  let closestIndex = 0
  let closestDistance = 10000
  defaultPossibleOutcomeMultiples.forEach((possibleMultiple, index) => {
    const distance = Math.abs(possibleMultiple - multiple)
    if (distance < closestDistance) {
      closestDistance = distance
      closestIndex = index
    }
  })
  return closestIndex
}

function generateArray(start: number, increment: number, numIncrements: number): number[] {
  let arr = []
  for (let i = 0; i < numIncrements; i++) {
    arr.push(parseFloat((start + i * increment).toFixed(2)))
  }
  return arr
}

export function safeCastToNumber(str: string | undefined | number): number | undefined {
  if (isNone(str) || str === '') {
    return undefined
  }
  if (typeof str === 'number') return isNaN(str) ? undefined : str
  const num = Number(str)
  return isNaN(num) ? undefined : num
}

export function CashSimulatorContextProvider({ children }: { children: ReactNode }) {
  const [companyIdQueryParam, _, setCompanyIdQueryParamWithSiblings] = useQueryParam('company', defaults.companyId)
  const [reserveRatioQueryParam, setReserveRatioQueryParam] = useQueryParam<string>('rr', 'auto')
  const [secondarySharePriceQueryParam, setSecondarySharePriceQueryParam] = useQueryParam<string>('bsp', 'auto')
  const [principalQueryParam, setPrincipalQueryParam] = useQueryParam<number>('p', constantTxGeneratorInputs.principal)
  const [activeMultipleIndex, setActiveMultipleIndex] = useState(defaults.selectedMultipleIndex)
  const [breakevenMultipleIndex, setBreakevenMultipleIndex] = useState<number | undefined>(undefined)
  const [selectedCompanyMultiple, setSelectedCompanyMultiple] = useState<number>(
    defaultPossibleOutcomeMultiples[activeMultipleIndex],
  )
  const vanillaClient = useVanillaTRPC()

  const companyOptionsQuery = trpc.cashSimulator.companyProfileOptions.useQuery(undefined, {
    refetchOnWindowFocus: false,
  })

  const selectedProfileQuery = useQuery(
    ['domain', companyIdQueryParam],
    async () => {
      const id = Number(companyIdQueryParam)
      if (isNone(companyIdQueryParam) || isNaN(id)) {
        return undefined
      }
      const profile = await vanillaClient.cashSimulator.companyProfile.query(id)
      const bsp =
        secondarySharePriceQueryParam === 'auto'
          ? profile.latestMarkPrice
          : safeCastToNumber(secondarySharePriceQueryParam)
      const rr =
        reserveRatioQueryParam === 'auto' ? profile.defaultReserveRatio : safeCastToNumber(reserveRatioQueryParam)
      if (isNone(bsp) || isNone(reserveRatioQueryParam) || isNone(rr)) {
        console.log('bsp is none after prof, cant set mult')
      } else {
        let generatorInputs: TargetMultipleGeneratorInputs = {
          secondaryBrokerTakeRate: txInputs.secondaryBrokerTakeRate.value,
          altInvestmentIrr: txInputs.altInvestmentIrr.value,
          totalTaxRate: txInputs.totalTaxRate.value,
          reserveRatio: rr,
          yearsToOutcome: txInputs.yearsToOutcome.value,
        }
        let generator = new TargetMultipleOutcomeGenerator(generatorInputs)
        let multipleRequiredForBoost = generator.companyMultipleRequiredForTargetCashAdjustedTxMultiple(1.2)
        let closestMultipleIndex = findClosestMultipleIndex(multipleRequiredForBoost)
        setActiveMultipleIndex(closestMultipleIndex)
        if (isSome(profile.latestPricedFinancingEvent)) {
          txInputs.mostRecentFinancingEventIssuePrice.setValue(profile.latestPricedFinancingEvent?.issuePrice)
          txInputs.mostRecentFinancingEventDate.setValue(new Date(profile.latestPricedFinancingEvent?.date))
          if (txInputs.nextFinancingEventIssuePrice.value === undefined) {
            txInputs.nextFinancingEventIssuePrice.setValue(
              Number((profile.latestPricedFinancingEvent?.issuePrice * 1.75).toFixed(2)),
            )
          }
        }
      }
      return profile
    },
    { enabled: isSome(companyIdQueryParam), refetchOnWindowFocus: false },
  )

  const selectedCompanyProfile = selectedProfileQuery.data

  const txInputs: TxInputs = {
    principal: {
      value: principalQueryParam,
      setValue: (p: number | undefined) => {
        if (isNone(p)) {
          return
        }
        if (isNaN(p) || p <= 0) {
          return
        }
        setPrincipalQueryParam(p)
      },
    },
    reserveRatio: {
      value:
        reserveRatioQueryParam === 'auto'
          ? selectedCompanyProfile?.defaultReserveRatio
          : safeCastToNumber(reserveRatioQueryParam),
      setValue: (rr: number | undefined) => {
        if (isNone(rr)) {
          return
        }
        if (isNaN(rr) || rr <= 0) {
          return
        }
        setReserveRatioQueryParam(rr.toString())
      },
    },
    totalTaxRate: makeStateObject(useState<number>(constantTxGeneratorInputs.totalTaxRate)),
    altInvestmentIrr: makeStateObject(useState<number>(constantTxGeneratorInputs.altInvestmentIrr)),
    secondaryBrokerTakeRate: makeStateObject(useState<number>(constantTxGeneratorInputs.secondaryBrokerTakeRate)),
    yearsToOutcome: makeStateObject(useState<number>(defaults.yearsInFutureSelected)),
    secondarySharePrice: {
      value:
        secondarySharePriceQueryParam === 'auto'
          ? selectedCompanyProfile?.latestMarkPrice
          : safeCastToNumber(secondarySharePriceQueryParam),
      setValue: (bsp: number | undefined) => {
        if (isNone(bsp)) {
          return
        }
        if (isNaN(bsp) || bsp <= 0) {
          return
        }
        setSecondarySharePriceQueryParam(bsp.toString())
      },
    },
    mostRecentFinancingEventIssuePrice: makeStateObject(useState<number | undefined>(undefined)),
    mostRecentFinancingEventDate: makeStateObject(useState<Date | undefined>(undefined)),
    nextFinancingEventIssuePrice: makeStateObject(useState<number | undefined>(undefined)),
    yearsUntilNextFinancingEvent: makeStateObject(useState<number>(1.5)),
    shouldUseAutoBaseSharePrice: makeStateObject(useState<boolean>(false)),
  }

  useEffect(() => {
    txInputs.mostRecentFinancingEventDate.setValue(undefined)
    txInputs.mostRecentFinancingEventIssuePrice.setValue(undefined)
    txInputs.nextFinancingEventIssuePrice.setValue(undefined)
    txInputs.yearsUntilNextFinancingEvent.setValue(1.5)
  }, [companyIdQueryParam]) // eslint-disable-line react-hooks/exhaustive-deps

  const selectedCompanyValuation =
    txInputs.secondarySharePrice.value &&
    selectedCompanyProfile &&
    txInputs.secondarySharePrice.value * selectedCompanyProfile?.sharesOutstanding
  const sliderActiveCompanyValuation = selectedCompanyValuation && selectedCompanyValuation * selectedCompanyMultiple

  function didSelectCompanyName(companyName: string) {
    const option = companyOptionsQuery.data?.find((option) => option.name === companyName)
    isSome(option) && setCompanyIdQueryParamWithSiblings(option.id, { bsp: 'auto' })
  }

  const txGeneratorInputs = useCallback((): ValueShareSecondaryComparableTxGeneratorInputs | undefined => {
    function companyCagrFromBase(baseSharePrice: number): number | undefined {
      // need base and end valuation
      const endSharePrice =
        txInputs.secondarySharePrice.value && txInputs.secondarySharePrice.value * selectedCompanyMultiple
      const multiple = endSharePrice && endSharePrice / baseSharePrice
      return multiple && calculateCAGR({ returnMultiple: multiple, years: txInputs.yearsToOutcome.value })
    }

    let { sharesOutstanding } = selectedCompanyProfile ?? {}
    let {
      shouldUseAutoBaseSharePrice,
      yearsUntilNextFinancingEvent,
      nextFinancingEventIssuePrice,
      mostRecentFinancingEventDate,
      mostRecentFinancingEventIssuePrice,
      reserveRatio,
      principal,
    } = txInputs
    let baseSharePrice: number
    let canUseAutoBaseSharePrice =
      isSome(mostRecentFinancingEventDate.value) &&
      isSome(mostRecentFinancingEventIssuePrice.value) &&
      isSome(nextFinancingEventIssuePrice.value) &&
      isSome(yearsUntilNextFinancingEvent.value)
    if (shouldUseAutoBaseSharePrice.value && canUseAutoBaseSharePrice) {
      let yearsSinceMostRecentFinancingEvent = possiblyNegativeYearsBetweenDates(
        mostRecentFinancingEventDate.value!,
        new Date(),
      )
      yearsSinceMostRecentFinancingEvent =
        yearsSinceMostRecentFinancingEvent > 0 ? yearsSinceMostRecentFinancingEvent : 0
      baseSharePrice = autoDetermineBaseSharePriceWithYears(
        yearsSinceMostRecentFinancingEvent,
        mostRecentFinancingEventIssuePrice.value!,
        yearsUntilNextFinancingEvent.value,
        nextFinancingEventIssuePrice.value!,
        DEFAULT_FUND_MIN_CAGR,
      )
    } else {
      if (isNone(txInputs.secondarySharePrice.value)) {
        return undefined
      }
      baseSharePrice = txInputs.secondarySharePrice.value
    }

    if (anyAreNone(sharesOutstanding, companyCagrFromBase(baseSharePrice), reserveRatio.value, principal.value)) {
      return undefined
    } else {
      let generatorInputs: ValueShareSecondaryComparableTxGeneratorInputs = {
        secondaryBrokerTakeRate: txInputs.secondaryBrokerTakeRate.value,
        altInvestmentIrr: txInputs.altInvestmentIrr.value,
        totalTaxRate: txInputs.totalTaxRate.value,
        principal: txInputs.principal.value!,
        reserveRatio: txInputs.reserveRatio.value!,
        companySharesOutstanding: sharesOutstanding!,
        baseSharePrice: baseSharePrice,
        yearsToOutcome: txInputs.yearsToOutcome.value,
        companyCagrFromBase: companyCagrFromBase(baseSharePrice)!,
      }
      return generatorInputs
    }
  }, [
    selectedCompanyProfile,
    txInputs.secondarySharePrice.value,
    txInputs.reserveRatio.value,
    txInputs.yearsToOutcome.value,
    txInputs.secondaryBrokerTakeRate.value,
    txInputs.altInvestmentIrr.value,
    txInputs.totalTaxRate.value,
    txInputs.principal.value,
    txInputs.shouldUseAutoBaseSharePrice.value,
    txInputs.mostRecentFinancingEventIssuePrice.value,
    txInputs.mostRecentFinancingEventDate.value,
    txInputs.nextFinancingEventIssuePrice.value,
    txInputs.yearsUntilNextFinancingEvent.value,
    selectedProfileQuery.isLoading,
    selectedProfileQuery.isRefetching,
    selectedCompanyMultiple,
  ])

  const sliderMultiples = useMemo((): number[] => {
    let inputs = txGeneratorInputs()
    if (isNone(inputs)) {
      return defaultPossibleOutcomeMultiples
    }
    let breakevenMultiple = new TargetMultipleOutcomeGenerator(
      inputs,
    ).companyMultipleRequiredForTargetCashAdjustedTxMultiple(1)
    let multiples = [...defaultPossibleOutcomeMultiples, breakevenMultiple]
    return multiples.sort((a, b) => a - b)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedCompanyProfile,
    txInputs.secondarySharePrice.value,
    txInputs.reserveRatio.value,
    txInputs.yearsToOutcome.value,
    txInputs.secondaryBrokerTakeRate.value,
    txInputs.altInvestmentIrr.value,
    txInputs.totalTaxRate.value,
    txInputs.principal.value,
    selectedProfileQuery.isLoading,
    selectedProfileQuery.isRefetching,
  ])

  useEffect(() => {
    setSelectedCompanyMultiple(sliderMultiples[activeMultipleIndex])
  }, [activeMultipleIndex, sliderMultiples])

  const findClosestMultipleIndex = useCallback(
    (multiple: number): number => {
      let closestIndex = 0
      let closestDistance = 10000
      sliderMultiples.forEach((possibleMultiple, index) => {
        const distance = Math.abs(possibleMultiple - multiple)
        if (distance < closestDistance) {
          closestDistance = distance
          closestIndex = index
        }
      })
      return closestIndex
    },
    [sliderMultiples],
  )

  useEffect(() => {
    let inputs = txGeneratorInputs()
    if (isNone(inputs)) {
      return
    }
    let generator = new TargetMultipleOutcomeGenerator(inputs)
    let multipleRequiredForBreakeven = generator.companyMultipleRequiredForTargetCashAdjustedTxMultiple(1)
    let closestIndex = findClosestMultipleIndex(multipleRequiredForBreakeven)
    setBreakevenMultipleIndex(closestIndex)
  }, [txGeneratorInputs, findClosestMultipleIndex])

  function pluralCashTx(): ValueShareTxWithSecondaryComparable | undefined {
    let inputs = txGeneratorInputs()
    if (isNone(inputs)) {
      return undefined
    }
    return new ValueShareTxWithSecondaryComparable(inputs)
  }

  const cashTx = pluralCashTx()

  const netCashGraphProps = (): NetCashGraphProps | undefined => {
    if (isNone(cashTx) || isNone(txInputs.secondarySharePrice.value)) {
      return undefined
    }
    const pluralGrossTxValue = cashTx.grossTxValue()
    const secondaryGrossTxValue = txInputs.secondarySharePrice.value * cashTx.sharesOwed()
    const netRateComparison = cashTx.netRateComparison()
    const data: NetCashGraphProps = {
      pluralBar: {
        transactionType: 'Plural',
        netTaxValue: pluralGrossTxValue * netRateComparison.plural.netTaxRate,
        netCashValue: pluralGrossTxValue * netRateComparison.plural.netCashRate,
        netFeesValue: pluralGrossTxValue * netRateComparison.plural.netFeeRate,
        grossTransactionValue: pluralGrossTxValue,
      },
      secondaryBar: {
        transactionType: 'Secondary',
        netTaxValue: secondaryGrossTxValue * netRateComparison.secondary.netTaxRate,
        netCashValue: secondaryGrossTxValue * netRateComparison.secondary.netCashRate,
        netFeesValue: secondaryGrossTxValue * netRateComparison.secondary.netFeeRate,
        grossTransactionValue: secondaryGrossTxValue,
      },
    }
    return data
  }

  const txPriceValuationGraphData = (): ValuationGraphData | undefined => {
    let inputs = txGeneratorInputs()
    if (isNone(inputs) || isNone(cashTx)) {
      return undefined
    }
    let datapoints = new ValueShareCashAdjustedTxPriceLineGenerator(inputs).generateDatapoints(
      defaultPossibleOutcomeMultiples,
    )
    return {
      line: datapoints,
      activeSliderDatapoint: {
        companyOutcomeValuation: cashTx.companyOutcomeValuation(),
        cashAdjustedSharePrice: cashTx.cashAdjustedTxSharePrice(),
      },
    }
  }

  return (
    <simulatorContext.Provider
      value={{
        selectedCompanyProps: {
          profile: selectedProfileQuery.data,
          baseValuation: selectedCompanyValuation,
        },
        isLoadingCompanyProfile: selectedProfileQuery.isLoading || selectedProfileQuery.isRefetching,
        multiplesSlider: {
          selectableMultiples: sliderMultiples,
          activeMultipleIndex: activeMultipleIndex,
          setActiveMultipleIndex: setActiveMultipleIndex,
          activeCompanyValuation: sliderActiveCompanyValuation,
        },
        companyOptions: companyOptionsQuery.data,
        didSelectCompanyName,
        pluralCashTx: cashTx,
        netCashGraphProps: netCashGraphProps(),
        txPriceValuationGraphData: txPriceValuationGraphData(),
        breakenvenMultipleIndex: breakevenMultipleIndex,
        closestDefaultMultiplesSelectedIndex: findClosestDefaultMultipleIndex(selectedCompanyMultiple),
        setActiveCompanyValuation: (valuation: number) => {
          setSelectedCompanyMultiple(valuation / (selectedCompanyValuation ?? 1))
        },
        txInputs,
        selectedCompanyMultiple,
      }}>
      {children}
    </simulatorContext.Provider>
  )
}

export function useSimulatorContext() {
  return useContext(simulatorContext)
}
