import { IonItem, IonSpinner } from '@ionic/react'
import { AnyColumnProps } from 'components/editable-table/ColumnProps'
import { DateCellFormat, StringCellFormat } from 'components/editable-table/EditableTextCellFormat'
import { CompanyMetric } from 'plural-trpc/routers/companiesRouter'
import { usePluralAuth } from 'providers/PluralAuthProvider'
import { ArrayElement, Optional, isNone, isSome } from 'safety'
import { hackyEditorIds } from 'several/hooks/FundingRound'
import { AttributedMetricCellFormat } from '../../components/editable-table/AttributedMetricEditableCellType'
import { EditableTable } from '../../components/editable-table/EditableTable'
import { RouterOutputs, trpc } from '../../utils/trpc'
import { useCompanySelectorContext } from './CompanySelectorContextProvider'
import { useCompanySelectorContext as useEnrichedCompanySelectorContext } from './massiveEnrichedCompany/MassiveEnrichedCompanySelectorContextProvider'
import { operatorToString, stringToOperator } from './operatorTranslation'

type AttributedMetricType = ArrayElement<RouterOutputs['attributedCompanyMetrics']['getMiscMetricTypes']>

enum ColumnHeaders {
  Metric = 'Metric',
  Value = 'Value',
  Date = 'Date',
  Source = 'Source',
  Operator = 'Operator',
  IsPublic = 'IsPublic',
  IsEstimated = 'IsEstimated',
  UpdatedBy = 'Updated By',
  UpdatedAt = 'Updated At',
}

type CreatedObject = {
  [ColumnHeaders.Metric]: string
  [ColumnHeaders.Value]: CompanyMetric
  [ColumnHeaders.Date]: Date
  [ColumnHeaders.IsEstimated]: boolean
  [ColumnHeaders.Source]: string
  [ColumnHeaders.Operator]: string
  [ColumnHeaders.IsPublic]: boolean
}

export function AttributedCompanyMetricsTable() {
  let { selectedCompany, companyId } = useCompanySelectorContext()
  const { selectedBaseCompany, pluralCompanyId, selectedEnrichedCompanyData, refetch, isLoadingCompanies } =
    useEnrichedCompanySelectorContext()

  if (pluralCompanyId.value) {
    companyId = pluralCompanyId
    selectedCompany = selectedBaseCompany
  }
  const metricTypesQuery = trpc.attributedCompanyMetrics.getMiscMetricTypes.useQuery(undefined, {})
  const metricTypes: AttributedMetricType[] = metricTypesQuery.data ?? []

  const updateMassiveCompanyMetricMutation = trpc.company.updateMassiveCompanyMetric.useMutation()
  const companyMetrics: CompanyMetric[] = selectedEnrichedCompanyData.value?.companyMetrics ?? []

  const columns: AnyColumnProps<CompanyMetric, CreatedObject>[] = [
    {
      header: ColumnHeaders.Metric,
      options: metricTypes.map((type: AttributedMetricType) => type.name),
      inputType: 'multiSelect',
      accessor: (row) => row.metricType,
      updateRemote: async (row: CompanyMetric, newValue: Optional<string>) => {
        const metricType = metricTypes.find((type) => type.name === newValue)
        if (isSome(metricType)) {
          if (metricType.name !== row.metricType) {
            await updateMassiveCompanyMetricMutation.mutateAsync({
              companyMetricId: row.id,
              companyId: selectedEnrichedCompanyData.value?.companyId!,
              metricType: metricType.name,
              metricFormat: metricType.format,
            })
            refetch()
          }
        }
      },
      minWidthPixels: 110,
      createProps: {
        defaultOption: metricTypes.length > 0 ? metricTypes[0]?.name : undefined,
        isRequired: true,
        createdObjectKey: ColumnHeaders.Metric,
      },
    },
    {
      header: ColumnHeaders.Value,
      textCellFormat: AttributedMetricCellFormat,
      inputType: 'input',
      accessor: (row) => row,
      updateRemote: async (row: CompanyMetric, newValue: Optional<CompanyMetric>) => {
        if (newValue?.value === row.value) return
        await updateMassiveCompanyMetricMutation.mutateAsync({
          companyMetricId: row.id,
          companyId: selectedEnrichedCompanyData.value?.companyId!,
          value: newValue?.value ?? undefined,
        })
        refetch()
      },
      minWidthPixels: 130,
      placeholder: 'value',
      createProps: {
        defaultOption: {
          value: '',
          metricType: {
            format: 'GENERIC',
          },
        } as any,
        isRequired: true,
        createdObjectKey: ColumnHeaders.Value,
      },
    },
    {
      header: ColumnHeaders.Date,
      textCellFormat: DateCellFormat,
      inputType: 'input',
      updateRemote: async (row: CompanyMetric, newValue: Optional<Date>) => {
        if (newValue?.toISOString() === row.reportedAt) return
        await updateMassiveCompanyMetricMutation.mutateAsync({
          companyMetricId: row.id,
          companyId: selectedEnrichedCompanyData.value?.companyId!,
          reportedAt: newValue ? newValue.toISOString() : undefined,
        })
        refetch()
      },
      minWidthPixels: 120,
      accessor: (row) => (isSome(row.reportedAt) ? new Date(row.reportedAt) : undefined),
      placeholder: '00/00/0000',
      createProps: {
        isRequired: true,
        createdObjectKey: ColumnHeaders.Date,
        defaultOption: new Date(),
      },
    },
    {
      header: ColumnHeaders.Source,
      textCellFormat: StringCellFormat,
      inputType: 'input',
      updateRemote: async (row: CompanyMetric, newValue: Optional<string>) => {
        if (newValue === row.source) return
        await updateMassiveCompanyMetricMutation.mutateAsync({
          companyMetricId: row.id,
          companyId: selectedEnrichedCompanyData.value?.companyId!,
          source: newValue ?? '',
        })
        refetch()
      },
      minWidthPixels: 150,
      accessor: (row) => row.source,
      placeholder: 'source',
      createProps: {
        isRequired: false,
        createdObjectKey: ColumnHeaders.Source,
      },
    },
    {
      header: ColumnHeaders.Operator,
      inputType: 'multiSelect',
      options: ['=', '>', '<', '~'],
      minWidthPixels: 100,
      accessor: (row) => operatorToString(row.operator),
      updateRemote: async (row: CompanyMetric, newValue: Optional<string>) => {
        if (newValue === operatorToString(row.operator)) return
        await updateMassiveCompanyMetricMutation.mutateAsync({
          companyMetricId: row.id,
          companyId: selectedEnrichedCompanyData.value?.companyId!,
          operator: newValue ? stringToOperator(newValue) : undefined,
        })
        refetch()
      },
      createProps: {
        defaultOption: '=',
        isRequired: false,
        createdObjectKey: ColumnHeaders.Operator,
      },
    },
    {
      header: ColumnHeaders.IsEstimated,
      inputType: 'boolean',
      accessor: (row) => row.isEstimated,
      updateRemote: async (row: CompanyMetric, newValue: Optional<boolean>) => {
        if (newValue === row.isEstimated) return
        await updateMassiveCompanyMetricMutation.mutateAsync({
          companyMetricId: row.id,
          companyId: selectedEnrichedCompanyData.value?.companyId!,
          isEstimated: newValue ?? undefined,
        })
        refetch()
      },
      createProps: {
        defaultOption: false,
        isRequired: true,
        createdObjectKey: ColumnHeaders.IsEstimated,
      },
    },
    {
      header: ColumnHeaders.IsPublic,
      inputType: 'boolean',
      accessor: (row) => row.isPublic,
      updateRemote: async (row: CompanyMetric, newValue: Optional<boolean>) => {
        if (newValue === row.isPublic) return
        await updateMassiveCompanyMetricMutation.mutateAsync({
          companyMetricId: row.id,
          companyId: selectedEnrichedCompanyData.value?.companyId!,
          isPublic: newValue ?? undefined,
        })
        refetch()
      },
      createProps: {
        defaultOption: true,
        isRequired: true,
        createdObjectKey: ColumnHeaders.IsPublic,
      },
    },
    {
      header: ColumnHeaders.UpdatedBy,
      inputType: 'input',
      textCellFormat: StringCellFormat,
      accessor: (row) => row.source,
    },
    {
      header: ColumnHeaders.UpdatedAt,
      inputType: 'input',
      textCellFormat: DateCellFormat,
      accessor: (row) => new Date(row.updatedAt),
    },
  ]
  let { user } = usePluralAuth()
  let isEditor = hackyEditorIds.includes(user?.id ?? 0)

  return (
    <div className="w-full">
      <div className=" w-full">
        {isLoadingCompanies ? (
          <div className="loader mt-6 flex w-full flex-col items-center">
            <IonItem>
              <IonSpinner name="lines-sharp"></IonSpinner>
            </IonItem>
          </div>
        ) : (
          <div className="w-full">
            <EditableTable<CompanyMetric, CreatedObject>
              columns={columns}
              rowData={companyMetrics.filter((metric) => (isEditor ? true : metric.isPublic))}
              deleteProps={{
                onDelete: async (rowIndex) => {
                  const metric = companyMetrics[rowIndex]
                  await updateMassiveCompanyMetricMutation.mutateAsync({
                    companyMetricId: metric.id,
                    companyId: selectedEnrichedCompanyData.value?.companyId!,
                    deletedAt: new Date().toISOString(),
                  })
                },
                confirmationModalProps: {
                  title: 'Are you sure you want to delete this metric?',
                  body: 'This action cannot be undone',
                  confirmText: 'Delete',
                },
              }}
              stickyColumn={true}
              onRowCreate={async (createdObject: CreatedObject) => {
                // here, we know it comes back with the required fields in the object
                if (isNone(selectedEnrichedCompanyData.value?.companyId)) return
                let newMetricTypeName = createdObject[ColumnHeaders.Metric]
                let newMetricType = metricTypes.find((type) => type.name === newMetricTypeName)
                if (!newMetricType) return
                await updateMassiveCompanyMetricMutation.mutateAsync({
                  companyId: selectedEnrichedCompanyData.value?.companyId!,
                  metricType: newMetricTypeName,
                  metricFormat: newMetricType.format,
                  source: createdObject[ColumnHeaders.Source],
                  value: createdObject[ColumnHeaders.Value].value ?? undefined,
                  operator: stringToOperator(createdObject[ColumnHeaders.Operator]),
                  reportedAt: createdObject[ColumnHeaders.Date].toISOString(),
                  isPublic: createdObject[ColumnHeaders.IsPublic],
                  isEstimated: createdObject[ColumnHeaders.IsEstimated],
                })
                refetch()
              }}
            />
          </div>
        )}
      </div>
    </div>
  )
}
