import { useState } from 'react'
import { ArrayElement, isNone, isSome } from 'safety'
import { AnyColumnProps } from '../../components/editable-table/ColumnProps'
import { EditableTable } from '../../components/editable-table/EditableTable'
import { StringCellFormat } from '../../components/editable-table/EditableTextCellFormat'
import { useVanillaTRPC } from '../../providers/TRPCProvider'
import { RouterOutputs, trpc } from '../../utils/trpc'
import { useCompanySelectorContext } from './CompanySelectorContextProvider'
import CreateCompanyModal from './CreateCompanyModal'
import TextInputSelect from './TextInputSelect'
import { useCompanySelectorContext as useEnrichedCompanySelectorContext } from './massiveEnrichedCompany/MassiveEnrichedCompanySelectorContextProvider'

type Company = ArrayElement<RouterOutputs['companies']['allForSelector']>
type ComparisonInitiated = ArrayElement<RouterOutputs['company']['comparisonsInitiated']>

type SimpleCompany = {
  id: number
  name: string
  domain: string
}

export default function ComparableCompaniesEditor() {
  let { companies, companyId } = useCompanySelectorContext()
  const { companies: pluralCompanies, pluralCompanyId } = useEnrichedCompanySelectorContext()
  if (pluralCompanyId.value) {
    companies = pluralCompanies
    companyId = pluralCompanyId
  }
  const [clearInputIncrementor, setClearInputIncrementor] = useState(0)
  const vanillaTrpc = useVanillaTRPC()

  const comparisonsInitiatedQuery = trpc.company.comparisonsInitiated.useQuery(
    {
      companyId: companyId.value ?? 0,
    },
    {
      enabled: isSome(companyId.value),
    },
  )
  const comparisonsInitiated = comparisonsInitiatedQuery.data ?? []

  const comparisonInitiatedColumns: AnyColumnProps<SimpleCompany, {}>[] = [
    {
      header: 'Company Name',
      inputType: 'input',
      accessor: (comparableCompany: SimpleCompany) => comparableCompany.name,
      textCellFormat: StringCellFormat,
    },
    {
      header: 'Domain',
      inputType: 'input',
      accessor: (comparableCompany: SimpleCompany) => comparableCompany.domain,
      textCellFormat: StringCellFormat,
    },
  ]

  function didAddCompany(company: SimpleCompany) {
    if (isNone(companyId.value)) {
      return
    }
    vanillaTrpc.company.initiateComparison
      .mutate({
        companyId: companyId.value,
        comparableCompanyId: company.id,
      })
      .then(() => {
        comparisonsInitiatedQuery.refetch()
      })
  }

  return (
    <div className="w-full">
      {comparisonsInitiated.length > 0 ? (
        <div className="w-full pb-2">
          <EditableTable<SimpleCompany, {}>
            rowData={comparisonsInitiated.map((c) => c.comparableCompany)}
            columns={comparisonInitiatedColumns}
            deleteProps={{
              onDelete: async (rowIndex: number) => {
                const company = comparisonsInitiated[rowIndex]
                if (isNone(companyId.value)) {
                  return
                }
                await vanillaTrpc.company.deleteComparison
                  .mutate({
                    comparisonId: company.id,
                  })
                  .then(() => {
                    comparisonsInitiatedQuery.refetch()
                  })
              },
            }}
          />
        </div>
      ) : (
        <div />
      )}
      <div className="max-w-page-skinny flex-row pt-2">
        <TextInputSelect
          options={companies.map((company: Company, index: number) => ({ label: company.name, index }))}
          initialOption={undefined}
          clearInputIncrementor={clearInputIncrementor}
          onOptionSelect={(option) => {
            didAddCompany(companies[option.index])
            setClearInputIncrementor(clearInputIncrementor + 1)
          }}
          placeholder="Add comparable..."
          createModal={
            <CreateCompanyModal
              onCreate={(newCompany: SimpleCompany) => {
                didAddCompany(newCompany)
                setClearInputIncrementor(clearInputIncrementor + 1)
              }}
            />
          }
        />
      </div>
    </div>
  )
}
