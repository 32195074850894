import { numberToAbbreviatedDollarString, numberToReadableDollarString } from 'plural-shared/utils'
import { useEffect } from 'react'
import { isSome } from 'safety'
import { trpc } from '../../utils/trpc'
import { useCompanySelectorContext } from './CompanySelectorContextProvider'
import { useCompanySelectorContext as useEnrichedCompanySelectorContext } from './massiveEnrichedCompany/MassiveEnrichedCompanySelectorContextProvider'

export default function ProjectionResult({ variant = 'default' }: { variant?: 'default' | 'companyVariant' }) {
  let { companyId, projectionIncrement } = useCompanySelectorContext()
  const { pluralCompanyId, projectionIncrement: projectionIncrementV2 } = useEnrichedCompanySelectorContext()
  // Use custom values if provided, otherwise use defaults from context
  if (pluralCompanyId.value) {
    companyId = pluralCompanyId
    projectionIncrement = projectionIncrementV2
  }

  const projectionQuery = trpc.projectionResults.projectionToday.useQuery(
    {
      companyId: companyId.value ?? 0,
    },
    {
      enabled: isSome(companyId.value),
    },
  )

  useEffect(() => {
    projectionQuery.refetch()
  }, [projectionIncrement.value, companyId.value]) // including query causes infinite refetch

  const projection = projectionQuery.data

  const smallMetricWidthClass = 'min-w-36 text-sm'

  function smallMetricLabel(label: string) {
    return <div className="pb-[2px] pt-2 text-xs  opacity-70">{label}</div>
  }

  return (
    <div className="mt-2">
      {projection && (
        <div>
          <div className="text-sm">
            <div className="text-2xl font-semibold">
              {numberToReadableDollarString(projection.sharePrice)}
              <span className="pl-1 text-sm font-normal">PPS</span>
            </div>
            <div className=" flex space-x-8">
              <div className={`${smallMetricWidthClass}`}>
                {smallMetricLabel('Valuation')}
                {numberToAbbreviatedDollarString(projection.valuation)}
              </div>
              <div className={`${smallMetricWidthClass}`}>
                {smallMetricLabel('Revenue')}
                {numberToAbbreviatedDollarString(projection.revenue)}
              </div>
              <div className={`${smallMetricWidthClass}`}>
                {smallMetricLabel('Cash')}
                {projection.cash ? numberToAbbreviatedDollarString(projection.cash) : 'unknown'}
              </div>
            </div>
            <div className="flex space-x-8">
              <div className={`${smallMetricWidthClass}`}>
                {smallMetricLabel('Enterprise Value')}
                {numberToAbbreviatedDollarString(projection.enterpriseValue)}
              </div>
              <div className={`${smallMetricWidthClass}`}>
                {smallMetricLabel('Total Funding')}
                {numberToAbbreviatedDollarString(projection.totalFundingRaised)}
              </div>
              <div className={`${smallMetricWidthClass}`}>
                {smallMetricLabel('Est Common PPS')}
                {projection.cash ? numberToReadableDollarString(projection.commonSharePrice) : 'unknown'}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
