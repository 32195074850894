import { numberToAbbreviatedDollarString, numberToReadableDollarString } from 'plural-shared/utils'
import { useNavigate } from 'react-router-dom'
import { ArrayElement } from 'safety'
import { Table, TableColumnProps } from '../../components/Table'
import { RouterOutputs, trpc } from '../../utils/trpc'
import { withCompanySelectorContext } from './CompanySelectorContextProvider'
import CompanySelectorForEditor from './CompanySelectorForEditor'

export default withCompanySelectorContext(AllCompanies)

type CompanyForTable = ArrayElement<RouterOutputs['companies']['allForTable']>

function AllCompanies() {
  const navigate = useNavigate()
  const query = trpc.companies.allForTable.useQuery()
  const companies = query.data ?? []
  const columns: TableColumnProps<CompanyForTable>[] = [
    {
      header: 'Name',
      render: (row: CompanyForTable) => {
        const companyName = row.name
        return (
          <div className="align-center flex flex-row  gap-2 pr-9">
            {row.logoImgUrl ? (
              <img
                src={row.logoImgUrl}
                alt="logo"
                className="border-gray-80 h-8 w-8 rounded-full border border-solid"
              />
            ) : (
              <div className="border-gray-80 flex h-8 w-8 items-center justify-center rounded-full border border-solid">
                {companyName[0]}
              </div>
            )}
            <div className="flex flex-row items-center font-normal">{companyName}</div>
          </div>
        )
      },
    },
    {
      header: 'Website',
      render: (row: CompanyForTable) => {
        return (
          <div
            className="hover:underline"
            onClick={(e) => {
              e.stopPropagation()
              window.open(`https://${row.domain}`, '_blank')
            }}>
            {row.domain}
          </div>
        )
      },
    },
    {
      header: 'Total Funding',
      render: (row: CompanyForTable) => {
        return numberToAbbreviatedDollarString(row.totalFunding)
      },
    },
    {
      header: 'Mark Price',
      render: (row: CompanyForTable) => {
        return row.latestMarkPrice ? numberToReadableDollarString(row.latestMarkPrice, true) : ''
      },
    },
  ]

  return (
    <div className="ml-20 mr-20 flex flex-col items-center">
      <div className="w-full max-w-page-supermax pb-64">
        <div className="w-86">
          <div className="mt-8"></div>
        </div>
        <div className="mt-4">
          <div className="flex justify-between">
            <div className="pb-4 text-xl font-semibold">All companies</div>
            <div className="w-64">
              <CompanySelectorForEditor />
            </div>
          </div>
          <Table
            columns={columns}
            rowData={companies}
            onRowClick={(row) => {
              navigate(`/company-editor/${row.id}`)
            }}
            defaultRowCount={50}
          />
        </div>
      </div>
    </div>
  )
}
