import React, { useEffect } from 'react'
import { Optional, isSome } from 'safety'
import { trpc } from './utils/trpc'

export interface CaptureInterestData {
  email: Optional<string>
  equityValue: Optional<string>
  goal: Optional<string>
  company: Optional<string>
  name: Optional<string>
}

interface CaptureInterestContext {
  data: CaptureInterestData
  resetData: () => void
  setEmail: (email: Optional<string>) => void
  setEquityValue: (positionSize: Optional<string>) => void
  setGoal: (goal: Optional<string>) => void
  setCompany: (company: Optional<string>) => void
  setName: (name: Optional<string>) => void
}

const defaultCaptureInterestContext: CaptureInterestContext = {
  data: {
    email: null,
    equityValue: null,
    goal: null,
    company: null,
    name: null,
  },
  resetData: () => {},
  setEmail: () => {},
  setEquityValue: () => {},
  setGoal: () => {},
  setCompany: () => {},
  setName: () => {},
}

const captureInterestContext = React.createContext<CaptureInterestContext>(defaultCaptureInterestContext)

export function useCaptureInterestContext() {
  return React.useContext(captureInterestContext)
}

export function CaptureInterestContextProvider({ children }: { children: React.ReactNode }) {
  const [data, setData] = React.useState<CaptureInterestData>(defaultCaptureInterestContext.data)
  const updateMutation = trpc.interestGathering.updateQualificationAnswerData.useMutation()

  function updateCaptureInterestData(newData: CaptureInterestData) {
    setData(newData)
  }

  useEffect(() => {
    if (isSome(data.email)) {
      updateMutation.mutate({
        userEmail: data.email,
        equityValue: data.equityValue ?? undefined,
        goal: data.goal ?? undefined,
        companyName: data.company ?? undefined,
        userName: data.name ?? undefined,
      })
    }
  }, [data]) // eslint-disable-line react-hooks/exhaustive-deps

  function resetData() {
    updateCaptureInterestData(defaultCaptureInterestContext.data)
  }

  return (
    <captureInterestContext.Provider
      value={{
        data,
        resetData,
        setEmail: (email: Optional<string>) => updateCaptureInterestData({ ...data, email: email }),
        setEquityValue: (positionSize: Optional<string>) =>
          updateCaptureInterestData({ ...data, equityValue: positionSize }),
        setGoal: (goal: Optional<string>) => updateCaptureInterestData({ ...data, goal: goal }),
        setCompany: (company: Optional<string>) => updateCaptureInterestData({ ...data, company: company }),
        setName: (name: Optional<string>) => updateCaptureInterestData({ ...data, name: name }),
      }}>
      {children}
    </captureInterestContext.Provider>
  )
}
