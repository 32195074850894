import { getCalApi } from '@calcom/embed-react'
import { CheckIcon } from '@radix-ui/react-icons'
import { TextField } from '@radix-ui/themes'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { BasicButton } from '../components/BasicButton'
import { trpc } from '../utils/trpc'

export default function BookDemo() {
  const [email, setEmail] = useState('')
  const submitMutation = trpc.interestGathering.logEmailInterestSubmission.useMutation()
  const canPressComplete = email.length > 0 && email.includes('@')
  const navigate = useNavigate()

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const emailParam = urlParams.get('email')
    if (emailParam) {
      setEmail(emailParam)
    }
  }, [])

  const onButtonClick = useCallback(() => {
    function commitInterestData() {
      submitMutation.mutate({
        userEmail: email,
        elementName: 'get-started',
        pathName: window.location.pathname,
      })
    }
    if (!canPressComplete) {
      return
    }
    commitInterestData()
    navigate('/get-started?email=' + email)
  }, [canPressComplete, submitMutation, email, navigate])

  useEffect(() => {
    const fetchCalApi = async () => {
      const cal = await getCalApi()
      cal('ui', {
        theme: 'light',
        styles: { branding: { brandColor: '#000000' } },
        hideEventTypeDetails: false,
        layout: 'month_view',
      })
    }
    fetchCalApi()
  }, [])

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        onButtonClick()
      }
    }
    document.addEventListener('keydown', handleKeyDown)
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [onButtonClick])

  const valueProps = [
    'Learn how much your equity is worth',
    'Receive a guided tour of our financing structure',
    "Understand Plural's tax savings",
  ]

  return (
    <div className="ml-6 mr-6 mt-6 flex flex-col items-center">
      <div className="w-full max-w-page-max text-base">
        <div className="flex w-full flex-col items-center">
          <div className="w-full max-w-form-width">
            <div className="mt-16 text-3xl font-semibold">Book a Demo</div>
            <div className="mt-8">
              <div className="mt-6">What to expect:</div>
              <div>
                {valueProps.map((valueProp, index) => {
                  return (
                    <div key={index} className="flex items-center pt-2">
                      <div className="mr-2 rounded-full bg-func-bg-black" style={{ padding: '1px' }}>
                        <CheckIcon className="h-4 w-4  text-white" />
                      </div>
                      <div className="text-base">{valueProp}</div>
                    </div>
                  )
                })}
              </div>
            </div>
            <div className="mt-9 w-full text-base">
              <div className="mb-2 mt-4 text-sm font-medium">Email</div>
              <TextField.Input
                placeholder="name@example.com"
                size={'3'}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="text-base"
              />
            </div>
            <div className="h-5" />
            <BasicButton
              onClick={onButtonClick}
              textSize="text-base"
              textHeight={20}
              className="w-full"
              isPressable={canPressComplete}>
              Get Started
            </BasicButton>
          </div>
        </div>
      </div>
    </div>
  )
}
