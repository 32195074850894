import { CrossCircledIcon } from '@radix-ui/react-icons'
import * as Popover from '@radix-ui/react-popover'
import { ChipContainer } from 'pages/company/TextInputSelectMulti'
import { useSnackbar } from 'providers/SnackbarProvider'
import { CSSProperties, ReactNode, useEffect, useRef, useState } from 'react'

export type DropdownSearchOption = {
  label: string
  value: string
  additionalSearchString?: string
  startNode?: ReactNode
  style?: CSSProperties
  endNode?: ReactNode
}

type DropdownSearchProps = {
  trigger: React.ReactNode
  placeholder?: string
  options: DropdownSearchOption[]
  onSelectOption: (option: DropdownSearchOption, unfocus: () => void) => void
  onFreeForm?: (value: string) => void
  initialSearchValue?: string
  defaultBlank?: boolean
  autoSelectFirst?: boolean
  width?: number
  manualSearchString?: string
  manualHoveredIndex?: number
  onBlur?: () => void
  onPaste?: (e: React.ClipboardEvent) => void
  maxWidth?: number
  alignOffset?: number
  maxHeight?: number
}
export default function DropdownSearch(props: DropdownSearchProps) {
  let [searchString, setSearchString] = useState(props.initialSearchValue ?? '')
  let [open, setOpen] = useState(false)
  const popoverContentRef = useRef<HTMLDivElement>(null)
  function unfocus() {
    setOpen(false)
  }
  useEffect(() => {
    setSearchString(props.initialSearchValue ?? '')
  }, [props.initialSearchValue])

  const filteredOptions = props.options.filter((option, i) => {
    let text = (props.manualSearchString ?? searchString).toLowerCase()
    if (text === '') return i < 40
    let label = option.label.toLowerCase()
    let additionalSearchString = option.additionalSearchString?.toLowerCase() ?? ''
    return label.includes(text) || additionalSearchString.includes(text)
  })

  let [hoveredIndex, setHoveredIndex] = useState(0)

  useEffect(() => {
    if (hoveredIndex !== -1) {
      scrollToItem(props.manualHoveredIndex ?? hoveredIndex)
    }
  }, [hoveredIndex, props.manualHoveredIndex])

  function handleKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'ArrowDown') {
      event.preventDefault()
      setHoveredIndex((prev) => (prev + 1) % filteredOptions.length)
    }
    if (event.key === 'ArrowUp') {
      event.preventDefault()
      setHoveredIndex((prev) => (prev - 1 + filteredOptions.length) % filteredOptions.length)
    }
    if (event.key === 'Enter') {
      const option = filteredOptions[hoveredIndex]
      if (option) {
        props.onSelectOption(option, unfocus)
      } else if (props.onFreeForm) {
        props.onFreeForm(searchString)
      }
    }
  }

  function scrollToItem(index: number) {
    const itemElement = document.querySelector(`#item-${index}`)
    itemElement?.scrollIntoView({
      behavior: 'smooth', // Optional: defines the transition animation
      block: 'nearest', // Optional: defines vertical alignment
      inline: 'start', // Optional: defines horizontal alignment
    })
  }

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (popoverContentRef.current && !popoverContentRef.current.contains(event.target as Node)) {
        props.onBlur && props.onBlur()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [popoverContentRef, props.onBlur])

  return (
    <Popover.Root
      open={props.manualSearchString == null ? open : !!props.manualSearchString}
      onOpenChange={(open) => {
        setOpen(open)
      }}>
      <Popover.Trigger asChild>{props.trigger}</Popover.Trigger>

      <Popover.Portal>
        <Popover.Content
          alignOffset={props.alignOffset}
          ref={popoverContentRef}
          // onBlur={(e) => {
          //   props.onBlur && props.onBlur()
          // }}
          onOpenAutoFocus={(e) => {
            e.preventDefault()
          }}
          onKeyDown={handleKeyDown}
          style={{ width: props.width ?? 200, zIndex: 1000 }}
          className={`rounded bg-white p-2 shadow-lg`}
          sideOffset={props.alignOffset}
          align="start">
          {props.manualSearchString == null ? (
            <>
              <div>
                <input
                  onPaste={props.onPaste}
                  autoFocus
                  className="mb-1 w-full border-none outline-none"
                  placeholder={props.placeholder ?? 'Search…'}
                  value={searchString}
                  onChange={(e) => {
                    setSearchString(e.target.value)
                    setHoveredIndex(0)
                    if (
                      props.options.find((option) => option.value === e.target.value) &&
                      e.target.value !== '' &&
                      e.target.value !== props.initialSearchValue &&
                      props.autoSelectFirst
                    ) {
                      props.onSelectOption(props.options.find((option) => option.value === e.target.value)!, unfocus)
                    }
                  }}
                />
              </div>
              <div className={'h-[1px] w-full rounded bg-gray-300'} />
            </>
          ) : undefined}
          <div
            className={`overflow-auto`}
            style={{
              maxHeight: `${props.maxHeight ?? 350}px`,
            }}>
            {filteredOptions.map((option, i) => {
              return (
                <div
                  style={option.style}
                  key={`item-${option.value}-${i}`}
                  id={`item-${i}`}
                  className={`flex cursor-pointer flex-row items-center gap-2 px-2 py-1 text-sm hover:bg-gray-100 ${i === (props.manualHoveredIndex ?? hoveredIndex) ? 'bg-gray-100' : ''}`}
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()

                    props.onSelectOption(option, unfocus)
                  }}>
                  {option.startNode}
                  {option.label}
                  {option.endNode && <div className="ml-auto"> {option.endNode}</div>}
                </div>
              )
            })}
            {filteredOptions.length === 0 && (searchString !== '' || props.manualSearchString != null) && (
              <div
                style={props.options[0]?.style ?? { padding: 10 }}
                key={`no-options-found`}
                className={`font-md flex flex-row items-center gap-2 px-2 py-1 text-sm`}
                onClick={(e) => {}}>
                No options found
              </div>
            )}
          </div>
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  )
}

export function DropdownSearchCell(
  props: Omit<DropdownSearchProps, 'initialStringValue' | 'trigger'> & { value: string; noPadding?: boolean },
) {
  return (
    <DropdownSearch
      initialSearchValue={props.value}
      {...props}
      trigger={
        <div className={`flex  w-full cursor-pointer items-center ${props.noPadding ? 'h-[20px]' : 'h-[30px] pl-3'}`}>
          {props.options.find((option) => option.value === props.value)?.startNode}
          {props.options.find((option) => option.value === props.value)?.label ?? props.value}
          {props.options.find((option) => option.value === props.value)?.endNode}
        </div>
      }
    />
  )
}

export function DropdownSearchMultiSelect(
  props: Omit<DropdownSearchProps, 'trigger'> & {
    selectedOptions: string[]
    onRemoveOption: (option: DropdownSearchOption) => void
    onSelectOptions: (options: DropdownSearchOption[]) => void
    fullWidth?: boolean
    placeholder: string
  },
) {
  let { selectedOptions, fullWidth, maxWidth, onRemoveOption, onSelectOptions, placeholder, ...rest } = props
  let { setSnackbarText } = useSnackbar()

  return (
    <DropdownSearch
      alignOffset={4}
      maxHeight={250}
      {...rest}
      options={props.options.filter((option) => !selectedOptions.includes(option.value))}
      onPaste={(e) => {
        let clipboardText = e.clipboardData.getData('text')
        let clipboardArray = clipboardText.split('\n')
        let unselected: string[] = []
        let selected: DropdownSearchOption[] = []

        let filteredClipboardArr = Array.from(new Set(clipboardArray.filter((item) => item.trim() !== '')))

        if (filteredClipboardArr.length > 1) {
          e.preventDefault()
        }

        filteredClipboardArr.forEach((text) => {
          let option = props.options.find((o) => o.label.toLowerCase() === text.toLowerCase())
          let option2 = props.options.find(
            (o) => o.label.toLowerCase().replace(/\s+/g, '') === text.toLowerCase().replace(/\s+/g, ''),
          )
          let option3 = props.options.find((o) => o.label.toLowerCase().includes(text.toLowerCase()))
          let option4 = props.options.find((o) => text.toLowerCase().includes(o.label.toLowerCase()))
          if (option) {
            selected.push(option)
          } else if (option2) {
            selected.push(option2)
          } else if (option3) {
            selected.push(option3)
          } else if (option4) {
            selected.push(option4)
          } else {
            unselected.push(text)
          }
        })

        if (unselected.length > 0 && filteredClipboardArr.length > 1) {
          setSnackbarText(`The following items were not found: ${unselected.join(', ')}`, 7000)
        }
        if (selected.length > 0) {
          props.onSelectOptions(selected)
        }
      }}
      maxWidth={maxWidth ? maxWidth - 50 : 303}
      width={maxWidth ? maxWidth - 50 : 303}
      trigger={
        <ChipContainer fullWidth={!!fullWidth} maxWidth={maxWidth ?? 303} onClick={() => {}}>
          {selectedOptions?.map((value, i) => {
            const option = props.options.find((o) => o.value === value)
            if (!option) return null
            const optionIdx = selectedOptions.findIndex((o) => o === value)
            if (optionIdx !== i) return null
            return (
              <div
                className="mr-1 flex flex-row items-center gap-1 rounded-full bg-gray-100 p-2 text-sm"
                key={`${option.value}-${option.label}`}>
                {option.startNode}
                {option.label}
                <CrossCircledIcon
                  onClick={(e) => {
                    e.stopPropagation()
                    onRemoveOption(option)
                  }}
                  style={{
                    width: '15px',
                    height: '15px',
                    cursor: 'pointer',
                  }}
                />
              </div>
            )
          })}
          {(selectedOptions?.length ?? 0) <= 1 && (
            <div className="flex items-center pl-[6px] text-sm text-gray-400">{placeholder}</div>
          )}
        </ChipContainer>
      }
    />
  )
}
