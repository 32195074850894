import { PlusIcon } from '@radix-ui/react-icons'
import { IconButton } from '@radix-ui/themes'
import { ColumnDef } from '@tanstack/react-table'
import AppModal from 'components/AppModal'
import AppTextField, { AppSelectFormfield } from 'components/AppTextfield'
import { attioStyleString } from 'components/BasicButton'
import { DatePicker } from 'components/DatePicker'
import TextFieldMulti from 'components/TextFieldMulti'
import { Button } from 'components/ui/button'
import { FilterIcon, TrashIcon } from 'lucide-react'
import { SpecialFilterKey, SpecialIndicationFilterKey, TableFilterModel } from 'models/table'
import TextInputSelect, { TextInputSelectOption } from 'pages/company/TextInputSelect'
import { useState } from 'react'
import { DropdownSearchMultiSelect } from 'several/components/DropdownSearch'
import { trpc } from 'utils/trpc'
import { v4 as uuid } from 'uuid'

export default function FiltersModalButton<T>(props: {
  filters: TableFilterModel[][]
  setFilters: (filters: TableFilterModel[][]) => void
  columns: ColumnDef<T, any>[]
  allowAdditionalLists?: 'company' | 'fundingRound'
  addToFiltersExtension?: (filters: TableFilterModel[][], id: string) => void
}) {
  let [open, setOpen] = useState(false)
  const width = 900
  let numberOfFilters = props.filters.filter(
    (filterGroup) => filterGroup.filter((filter) => filter.field).length > 0,
  ).length
  return (
    <>
      <AppModal maxWidth={width} onClose={() => setOpen(false)} open={open} noPortal>
        <AppModal.Header>Filters</AppModal.Header>
        <AppModal.Body>
          <div className={`flex min-w-[${width}px] flex-col gap-5`}>
            {props.filters.map((filters, index) => {
              return (
                <div className="flex flex-row gap-5" key={index}>
                  <div style={{ width: '65px', marginTop: '4px', flexShrink: 0 }}>{index === 0 ? 'Where' : 'Or'}</div>
                  <ConditionGroup
                    addToFiltersExtension={props.addToFiltersExtension}
                    allowAdditionalLists={props.allowAdditionalLists}
                    dontDelete={index === 0 && props.filters.length === 1}
                    onDelete={() => {
                      let newFilters = [...props.filters]
                      newFilters.splice(index, 1)
                      props.setFilters(newFilters)
                    }}
                    key={index}
                    columns={props.columns}
                    filters={filters}
                    setFilters={(newFilters) => {
                      let newFilterGroups = [...props.filters]
                      newFilterGroups[index] = newFilters
                      props.setFilters(newFilterGroups)
                    }}
                  />
                </div>
              )
            })}
            <div className="flex flex-row gap-5">
              <div style={{ width: '75px' }}></div>
              <Button
                variant="outline"
                className="flex w-full flex-row gap-3"
                onClick={() => {
                  let newFilters = [...props.filters]
                  newFilters.push([{ field: '', value: [], id: uuid(), operatorType: 'includesString' }])
                  props.setFilters(newFilters)
                }}>
                <PlusIcon />
                Add Condition Group
              </Button>
            </div>
          </div>
        </AppModal.Body>
        <div className="justify-right flex w-full flex-row border-t p-2">
          <Button onClick={() => setOpen(false)} className="ml-auto mr-2" variant={'secondary'}>
            Done
          </Button>
        </div>
      </AppModal>
      <div onClick={() => setOpen(true)} className={attioStyleString}>
        <FilterIcon className="h-[15px] w-[15px]" />
        Filters
        {numberOfFilters > 0 ? (
          <div className="text-md flex h-[20px] w-[20px] items-center justify-center rounded-full border border-solid bg-white font-medium text-black">
            {numberOfFilters}
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  )
}

function ConditionGroup(props: {
  filters: TableFilterModel[]
  setFilters: (filters: TableFilterModel[]) => void
  columns: ColumnDef<any, any>[]
  onDelete: () => void
  dontDelete: boolean
  allowAdditionalLists?: 'company' | 'fundingRound'
  addToFiltersExtension?: (filters: TableFilterModel[][], id: string) => void
}) {
  return (
    <div className="flex w-full min-w-[942px] flex-col gap-2 rounded border bg-gray-200 p-2">
      <div className="flex w-full flex-row items-center justify-between">
        <div className="text-gray text-sm">All of these are true</div>
      </div>
      {props.filters.map((filter, index) => {
        return (
          <Condition
            addToFiltersExtension={props.addToFiltersExtension}
            allowAdditionalLists={props.allowAdditionalLists}
            key={filter.id}
            columns={props.columns.filter((column) => {
              let existingFilter = props.filters.find((filter) => filter.field === column.id)
              if (existingFilter && existingFilter.id !== filter.id) {
                return false
              }
              return true
            })}
            onDelete={() => {
              let newFilters = [...props.filters]
              if (newFilters.length === 1 && !props.dontDelete) {
                props.onDelete()
              } else {
                newFilters.splice(index, 1)
                props.setFilters(newFilters)
              }
            }}
            filter={filter}
            setFilter={(newFilter) => {
              let newFilters = [...props.filters]
              newFilters[index] = newFilter
              props.setFilters(newFilters)
            }}
          />
        )
      })}
      <Button
        variant="ghost"
        className="w-26"
        onClick={() => {
          let newFilters = [...props.filters]
          newFilters.push({ field: '', value: [], id: uuid(), operatorType: 'includesString' })
          props.setFilters(newFilters)
        }}>
        Add Filter
      </Button>
    </div>
  )
}

function Condition(props: {
  filter: TableFilterModel
  setFilter: (filter: TableFilterModel) => void
  columns: ColumnDef<any, any>[]
  onDelete: () => void
  addToFiltersExtension?: (filters: TableFilterModel[][], id: string) => void
  allowAdditionalLists?: 'company' | 'fundingRound'
}) {
  let filterableListsQuery = trpc.sheetRouter.getAllSheetsByEntity.useQuery(
    props.allowAdditionalLists === 'company' ? 'COMPANY' : 'FUNDING_ROUND',
    {
      enabled: props.allowAdditionalLists !== undefined,
    },
  )
  let filterableLists: {
    id: number
    name: string
  }[] = (filterableListsQuery.data ?? []) as unknown as { id: number; name: string }[]
  let column = props.columns.find((column) => column.id === props.filter.field)
  let isColumnDate = (column?.filterFn?.toString().toLowerCase() ?? 'no').includes('date')
  let isColumnNumber = (column?.filterFn?.toString().toLowerCase() ?? 'no').includes('number')
  let isColumnBoolean = (column?.filterFn?.toString().toLowerCase() ?? 'no').includes('boolean')
  let isColumnSingleSelect =
    (column?.filterFn?.toString().toLowerCase() ?? 'no').includes('singleselect') &&
    (column?.meta?.singleSelectOptions?.length ?? -1) > 0

  let isColumnExclusiveSingleSelect = (column?.filterFn?.toString().toLowerCase() ?? 'no').includes('exclusiveselect')

  let isColumnString = column?.filterFn?.toString().toLowerCase() === 'stringfn'
  let isFilterListExtension = props.filter.field === SpecialFilterKey
  let isFilterIndicationExtension = props.filter.field === SpecialIndicationFilterKey
  let textInputSelectOptions: TextInputSelectOption[] = props.columns
    .filter((column) => column.id)
    .sort((a, b) => (a.header ?? 1).toString().localeCompare((b.header ?? 1).toString()))
    .map((column, i) => {
      return {
        index: i,
        label: column.header?.toString() ?? '',
        id: column.id ?? '',
      }
    })

  let getSheetFiltersMutation = trpc.sheetRouter.getSheetFilters.useMutation()
  return (
    <div className="flex flex-row items-center gap-2">
      <div className={'min-w-[303px]'}>
        <TextInputSelect
          onOptionSelect={(option) => {
            props.setFilter({ ...props.filter, field: option.id as string, value: [''] })
          }}
          placeholder="Select Field"
          initialOption={(() => {
            switch (props.filter.field) {
              case SpecialFilterKey:
                return { index: textInputSelectOptions.length, label: 'List', id: SpecialFilterKey }
              case SpecialIndicationFilterKey:
                return { index: textInputSelectOptions.length, label: 'Indications', id: SpecialIndicationFilterKey }
              default:
                return textInputSelectOptions.find((option) => option.id === props.filter.field) ?? undefined
            }
          })()}
          options={[
            ...textInputSelectOptions,
            ...(props.allowAdditionalLists
              ? [
                  { index: textInputSelectOptions.length, label: 'List', id: SpecialFilterKey },
                  { index: textInputSelectOptions.length + 1, label: 'Indications', id: SpecialIndicationFilterKey },
                ]
              : []),
          ]}
        />
      </div>

      <div className="text-gray flex min-w-[215px] flex-row items-center justify-center text-sm">
        {isColumnString ? (
          <AppSelectFormfield
            value={props.filter.value[0] || 'containsAllOf'}
            onValueChange={(value) => {
              if (value === 'isEmpty' || value === 'isSome') {
                props.setFilter({ ...props.filter, value: [value] })
              } else {
                let oldFilterValues = [...props.filter.value]
                oldFilterValues.shift()
                props.setFilter({ ...props.filter, value: [value, ...oldFilterValues] })
              }
            }}
            options={[
              {
                label: (props.filter.value.length ?? 0) > 2 ? 'contains all of' : 'contains',
                value: 'containsAllOf',
              },
              ...((props.filter.value.length ?? 0) > 2
                ? [
                    {
                      label: 'contains some of',
                      value: 'containsSomeOf',
                    },
                  ]
                : []),
              {
                label: 'is empty',
                value: 'isEmpty',
              },
              {
                label: 'is some',
                value: 'isSome',
              },
            ]}></AppSelectFormfield>
        ) : isColumnSingleSelect ? (
          props.filter.value.length > 2 ? (
            'is any of'
          ) : (
            'is'
          )
        ) : isColumnBoolean ? (
          'is'
        ) : isColumnExclusiveSingleSelect ? (
          <AppSelectFormfield
            value={props.filter.value[0] || 'is'}
            options={[
              {
                label: 'is',
                value: 'is',
              },
              {
                label: 'is not',
                value: 'isNot',
              },
            ]}
            onValueChange={(value) => {
              let oldFilterValues = [...props.filter.value]
              oldFilterValues[0] = value
              props.setFilter({ ...props.filter, value: oldFilterValues })
            }}
          />
        ) : isColumnDate ? (
          <AppSelectFormfield
            value={props.filter.value[0] || 'on'}
            options={[
              {
                label: 'is on',
                value: 'on',
              },
              {
                label: 'is before',
                value: 'before',
              },
              {
                label: 'is after',
                value: 'after',
              },
              {
                label: 'is between',
                value: 'between',
              },
              {
                label: 'is within the last',
                value: 'daysAgo',
              },
              {
                label: 'is between X and X days ago',
                value: 'betweenDaysAgo',
              },
              {
                label: 'is empty',
                value: 'isEmpty',
              },
              {
                label: 'is some',
                value: 'isSome',
              },
            ]}
            onValueChange={(value) => {
              props.setFilter({ ...props.filter, value: [value] })
            }}
          />
        ) : isColumnNumber ? (
          <AppSelectFormfield
            value={props.filter.value[0] ?? 'equals'}
            options={[
              {
                label: '==',
                value: 'equals',
              },
              {
                label: '<',
                value: 'lessThan',
              },
              {
                label: '>',
                value: 'greaterThan',
              },
              {
                label: '<=',
                value: 'lessThanOrEqual',
              },
              {
                label: '>=',
                value: 'greaterThanOrEqual',
              },
              {
                label: 'is between',
                value: 'between',
              },
            ]}
            onValueChange={(value) => {
              let oldFilterValues = [...props.filter.value]
              oldFilterValues[0] = value
              props.setFilter({ ...props.filter, value: oldFilterValues })
            }}
          />
        ) : isFilterListExtension ? (
          <AppSelectFormfield
            value={props.filter.value[0] && props.filter.value.length > 2 ? props.filter.value[0] : 'or'}
            options={[
              {
                label: props.filter.value.length > 2 ? 'is on any of' : 'is on',
                value: 'or',
              },
              ...(props.filter.value.length > 2
                ? [
                    {
                      label: 'is on all of',
                      value: 'and',
                    },
                  ]
                : []),
            ]}
            onValueChange={(value) => {
              let oldFilterValues = [...props.filter.value]
              oldFilterValues[0] = value
              props.setFilter({ ...props.filter, value: oldFilterValues })
            }}
          />
        ) : isFilterIndicationExtension ? (
          'are'
        ) : (
          'contains'
        )}
      </div>
      {isColumnString ? (
        <TextFieldMulti
          onPaste={(e) => {
            e.preventDefault()
            e.stopPropagation()
            let clipboardText = e.clipboardData.getData('text')
            let clipboardArray = clipboardText.split('\n')
            props.setFilter({ ...props.filter, value: [...props.filter.value, ...clipboardArray] })
          }}
          selectedOptions={props.filter.value.slice(1)}
          onSelectOption={(option) => {
            props.setFilter({ ...props.filter, value: [...props.filter.value, option] })
          }}
          onOptionRemove={(option) => {
            props.setFilter({
              ...props.filter,
              value: props.filter.value.filter((value) => value !== option),
            })
          }}
          maxWidth={414}
          fullWidth
          placeholder={props.filter.value[0] === 'isEmpty' || props.filter.value[0] === 'isSome' ? '' : 'add string...'}
          disabled={props.filter.value[0] === 'isEmpty' || props.filter.value[0] === 'isSome'}
        />
      ) : isColumnSingleSelect ? (
        <DropdownSearchMultiSelect
          placeholder={column?.meta?.singleSelectPlaceholder ?? 'Select ...'}
          fullWidth
          maxWidth={414}
          options={column?.meta?.singleSelectOptions ?? []}
          onSelectOption={(option) => {
            props.setFilter({ ...props.filter, value: [...props.filter.value, option.value] })
          }}
          selectedOptions={props.filter.value.slice(1)}
          onRemoveOption={(option) => {
            props.setFilter({
              ...props.filter,
              value: props.filter.value.filter((value) => value !== option.value),
            })
          }}
          onSelectOptions={(options) => {
            props.setFilter({
              ...props.filter,
              value: [
                ...props.filter.value,
                ...options
                  .filter((option) => !props.filter.value.includes((option.value ?? '') as string))
                  .map((option) => option.value),
              ],
            })
          }}
        />
      ) : isColumnBoolean ? (
        <DropdownSearchMultiSelect
          placeholder={column?.meta?.singleSelectPlaceholder ?? 'Select ...'}
          fullWidth
          maxWidth={414}
          options={[
            { label: 'True', value: 'true' },
            { label: 'False', value: 'false' },
          ]}
          onSelectOption={(option) => {
            props.setFilter({ ...props.filter, value: [...props.filter.value, option.value] })
          }}
          selectedOptions={props.filter.value.slice(1)}
          onRemoveOption={(option) => {
            props.setFilter({
              ...props.filter,
              value: props.filter.value.filter((value) => value !== option.value),
            })
          }}
          onSelectOptions={(options) => {
            props.setFilter({
              ...props.filter,
              value: [
                ...props.filter.value,
                ...options
                  .filter((option) => !props.filter.value.includes((option.value ?? '') as string))
                  .map((option) => option.value),
              ],
            })
          }}
        />
      ) : isFilterListExtension ? (
        <DropdownSearchMultiSelect
          maxWidth={414}
          options={filterableLists.map((list) => {
            return {
              label: list.name,
              value: list.id.toString(),
            }
          })}
          placeholder="Select List"
          selectedOptions={props.filter.value.slice(1)}
          onSelectOption={async (option) => {
            let additionalFilters = (await getSheetFiltersMutation.mutateAsync(parseInt(option.value))).map(
              (fg: any) => fg.filters,
            )
            if (props.addToFiltersExtension) {
              props.addToFiltersExtension(additionalFilters as unknown as TableFilterModel[][], option.value)
            }
            props.setFilter({ ...props.filter, value: [...props.filter.value, option.value] })
          }}
          onSelectOptions={(options) => {
            props.setFilter({
              ...props.filter,
              value: [
                ...props.filter.value,
                ...options
                  .filter((option) => !props.filter.value.includes((option.value ?? '') as string))
                  .map((option) => option.value),
              ],
            })
          }}
          onRemoveOption={(option) => {
            props.setFilter({
              ...props.filter,
              value: props.filter.value.filter((value) => value !== option.value),
            })
          }}
          fullWidth
        />
      ) : isFilterIndicationExtension ? (
        <DropdownSearchMultiSelect
          maxWidth={414}
          options={[
            {
              label: 'Active',
              value: 'active',
            },
            {
              label: 'Inactive',
              value: 'inactive',
            },
          ]}
          placeholder="Active or Inactive"
          selectedOptions={props.filter.value.slice(1)}
          onSelectOption={(option) => {
            props.setFilter({ ...props.filter, value: [...props.filter.value, option.value] })
          }}
          onSelectOptions={(options) => {
            props.setFilter({
              ...props.filter,
              value: [
                ...props.filter.value,
                ...options
                  .filter((option) => !props.filter.value.includes((option.value ?? '') as string))
                  .map((option) => option.value),
              ],
            })
          }}
          onRemoveOption={(option) => {
            props.setFilter({
              ...props.filter,
              value: props.filter.value.filter((value) => value !== option.value),
            })
          }}
          fullWidth
        />
      ) : isColumnExclusiveSingleSelect ? (
        <AppSelectFormfield
          value={props.filter.value[1]}
          options={(column?.meta?.singleSelectOptions ?? []).map((option) => {
            return {
              label: option.label,
              value: option.value,
            }
          })}
          onValueChange={(value) => {
            let oldFilterValues = [...props.filter.value]
            oldFilterValues[1] = value
            props.setFilter({ ...props.filter, value: oldFilterValues })
          }}
        />
      ) : isColumnDate ? (
        props.filter.value[0] === 'daysAgo' ? (
          <AppTextField
            fullWidth
            value={props.filter.value[1] ?? ''}
            type="number"
            endAdornment={<div className="mr-4">days</div>}
            style={{ minWidth: '260px' }}
            onChange={(e) => {
              props.setFilter({
                ...props.filter,
                value: [props.filter.value[0], e.target.value],
              })
            }}></AppTextField>
        ) : props.filter.value[0].includes('between') ? (
          <div className="flex flex-1 flex-row items-center gap-1 rounded border">
            {props.filter.value[0] === 'betweenDaysAgo' ? (
              <>
                <AppTextField
                  value={props.filter.value[1] ?? ''}
                  type="number"
                  onChange={(e) => {
                    props.setFilter({
                      ...props.filter,
                      value: [props.filter.value[0], e.target.value, props.filter.value[2]],
                    })
                  }}
                  style={{
                    width: '188px',
                  }}></AppTextField>
                <div className="text-sm">and</div>
                <AppTextField
                  value={props.filter.value[2] ?? ''}
                  onChange={(e) => {
                    props.setFilter({
                      ...props.filter,
                      value: [props.filter.value[0], props.filter.value[1], e.target.value],
                    })
                  }}
                  type="number"
                  style={{
                    width: '188px',
                  }}></AppTextField>
              </>
            ) : (
              <>
                <DatePicker
                  miniTextField
                  maxDate={props.filter.value[2] ? new Date(props.filter.value[2]) : undefined}
                  asTextField
                  date={props.filter.value[1] ? new Date(props.filter.value[1]) : undefined}
                  setDate={(date) => {
                    props.setFilter({
                      ...props.filter,
                      value: [props.filter.value[0], date ? date.toISOString() : '', props.filter.value[2]],
                    })
                  }}
                />
                <div className="text-sm">and</div>
                <DatePicker
                  minDate={props.filter.value[1] ? new Date(props.filter.value[1]) : undefined}
                  miniTextField
                  asTextField
                  date={props.filter.value[2] ? new Date(props.filter.value[2]) : undefined}
                  setDate={(date) => {
                    props.setFilter({
                      ...props.filter,
                      value: [props.filter.value[0], props.filter.value[1], date ? date.toISOString() : ''],
                    })
                  }}
                />
              </>
            )}
          </div>
        ) : (
          <div className="flex-1 rounded border">
            <DatePicker
              disabled={props.filter.value[0] === 'isEmpty' || props.filter.value[0] === 'isSome'}
              asTextField
              date={props.filter.value[1] ? new Date(props.filter.value[1]) : undefined}
              setDate={(date) => {
                props.setFilter({ ...props.filter, value: [props.filter.value[0], date ? date.toISOString() : ''] })
              }}
            />
          </div>
        )
      ) : isColumnNumber ? (
        props.filter.value[0] === 'between' ? (
          <div className="flex flex-1 flex-row items-center gap-1 rounded border">
            <AppTextField
              fullWidth
              type="number"
              style={{ flex: 1 }}
              endAdornment={column?.meta?.filterEndAdornment}
              value={props.filter.value[1]}
              onChange={(e) => {
                props.setFilter({
                  ...props.filter,
                  value: [props.filter.value[0], e.target.value, props.filter.value[2]],
                })
              }}
            />
            <div className="text-sm">and</div>
            <AppTextField
              fullWidth
              type="number"
              style={{ flex: 1 }}
              endAdornment={column?.meta?.filterEndAdornment}
              value={props.filter.value[2]}
              onChange={(e) => {
                props.setFilter({
                  ...props.filter,
                  value: [props.filter.value[0], props.filter.value[1], e.target.value],
                })
              }}
            />
          </div>
        ) : (
          <AppTextField
            fullWidth
            type="number"
            style={{ minWidth: '260px' }}
            endAdornment={column?.meta?.filterEndAdornment}
            value={props.filter.value[1]}
            onChange={(e) => {
              props.setFilter({ ...props.filter, value: [props.filter.value[0], e.target.value] })
            }}
          />
        )
      ) : (
        <AppTextField
          fullWidth
          style={{ minWidth: '260px' }}
          value={props.filter.value[0]}
          onChange={(e) => {
            props.setFilter({ ...props.filter, value: [e.target.value] })
          }}
        />
      )}

      <IconButton onClick={props.onDelete} variant="ghost">
        <TrashIcon color="gray" size={'15px'} />
      </IconButton>
    </div>
  )
}
