import AppTextField from 'components/AppTextfield'
import { safeCastToNumber } from 'pages/cashSimulator/CashSimulatorContext'
import { useState } from 'react'
import { ArrayElement, Optional, isSome } from 'safety'
import { BasicButton } from '../../components/BasicButton'
import { AnyColumnProps } from '../../components/editable-table/ColumnProps'
import { EditableTable } from '../../components/editable-table/EditableTable'
import { DateCellFormat, DollarsCellFormat } from '../../components/editable-table/EditableTextCellFormat'
import { useVanillaTRPC } from '../../providers/TRPCProvider'
import { RouterOutputs, trpc } from '../../utils/trpc'
import { useCompanySelectorContext } from './CompanySelectorContextProvider'
import { useCompanySelectorContext as useEnrichedCompanySelectorContext } from './massiveEnrichedCompany/MassiveEnrichedCompanySelectorContextProvider'

export type PositionMark = ArrayElement<RouterOutputs['company']['marks']>

export default function CompanyMarks() {
  let { selectedCompany } = useCompanySelectorContext()
  const { selectedBaseCompany } = useEnrichedCompanySelectorContext()
  if (selectedBaseCompany.value) {
    selectedCompany = selectedBaseCompany
  }
  const financingDataQuery = trpc.company.marks.useQuery(
    { companyId: selectedCompany.value?.id ?? 0 },
    {
      enabled: isSome(selectedCompany.value),
    },
  )
  const vanillaClient = useVanillaTRPC()
  const [isRecalculating, setIsRecalculating] = useState(false)

  const positionMarks: PositionMark[] = financingDataQuery.data ?? []

  const columns: AnyColumnProps<PositionMark, {}>[] = [
    {
      header: 'Date',
      accessor: (mark: PositionMark) => new Date(mark.markDate),
      inputType: 'input',
      textCellFormat: DateCellFormat,
    },
    {
      header: 'Share price',
      inputType: 'input',
      textCellFormat: DollarsCellFormat,
      accessor: (row: PositionMark) => row.sharePrice,
      minWidthPixels: 150,
      updateRemote: async (row: PositionMark, newValue: Optional<number>) => {
        if (!newValue) return
        await vanillaClient.marks.update.mutate({
          markId: row.id,
          sharePrice: newValue,
        })
        financingDataQuery.refetch()
      },
    },
  ]

  return (
    <div>
      <div className="font-medium">Marks</div>
      <div className="mt-4">
        {positionMarks.length === 0 ? (
          <CreateMarksButton companyId={selectedCompany.value?.id ?? 0} refetch={financingDataQuery.refetch} />
        ) : (
          <div className="mt-6">
            <div className="">
              <EditableTable columns={columns} rowData={positionMarks} />
            </div>
          </div>
        )}
        <div className="mt-2 flex items-center text-sm">
          <BasicButton
            className="text-sm"
            variant="gray"
            onClick={() => {
              vanillaClient.fundSnapshot.recalculate.query().then(() => {
                setIsRecalculating(true)
              })
            }}>
            Recalculate Portfolio Page
          </BasicButton>
          {isRecalculating && <div className="ml-4">Recalculating...will take a min</div>}
        </div>
      </div>
    </div>
  )
}

function CreateMarksButton(props: { companyId: number; refetch: () => void }) {
  let createMarksMutation = trpc.adminCompanyRouter.createMarksForCompany.useMutation()
  let [price, setPrice] = useState<number | null>(null)

  return (
    <div className="flex h-[50px] w-[300px] flex-row items-center gap-2">
      <AppTextField
        value={price ?? undefined}
        type="number"
        placeholder="Default PPS"
        startAdornment={'$'}
        onChange={(e) => setPrice(safeCastToNumber(e.target.value) ?? null)}
      />
      <BasicButton
        className="w-[250px]"
        onClick={async () => {
          if (price) {
            await createMarksMutation.mutateAsync({ companyId: props.companyId, sharePrice: price })
            props.refetch()
          }
        }}>
        Create Marks
      </BasicButton>
    </div>
  )
}
