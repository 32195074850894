
export default function Subprocessors() {
  return (
    <div className="mb-24 mt-24 flex pl-2 pr-2 lg:pl-16 lg:pr-16 items-start flex-wrap flex-col lg:flex-row">
      <aside className="lg:w-1/4 w-full p-6 lg:sticky lg:top-[20vh]">
        <nav className="lg:border-r pr-4">
          <ul>
            <li className="mb-2">
              <a href="/terms" className="block py-2 px-4 rounded-lg hover:bg-slate-100 font-medium">Terms of Use</a>
            </li>
            <li className="mb-2">
              <a href="/privacy" className="block py-2 px-4 rounded-lg hover:bg-slate-100 font-medium">Privacy Policy</a>
            </li>
            <li className="mb-2">
              <a href="/subprocessors" className="block py-2 px-4 rounded-lg hover:bg-slate-100 font-medium">Sub-processors</a>
            </li>
            <li className="mb-2">
              <a href="/terms/ai" className="block py-2 px-4 rounded-lg hover:bg-slate-100 font-medium">AI Terms</a>
            </li>
            <li className="mb-2">
              <a href="/aup" className="block py-2 px-4 rounded-lg hover:bg-slate-100 font-medium">Acceptable Use Policy</a>
            </li>
          </ul>
        </nav>
      </aside>

      <main className="lg:w-3/4 w-full p-6">
        <h1 className="text-3xl font-bold mb-8">Plural Subprocessors</h1>
        <section className="mb-10">
          <p className="mb-4">
            Third-Party AI Provider Applicable Terms and Policies
          </p>
          <ul>
            <li className="pb-4">
              <p className="mb-2"><strong>Amazon Web Services, Inc. (AWS)</strong></p>
              <ul className="list-disc ml-6">
                <li>
                  <p>AWS Acceptable Use Policy available at: <a href="https://aws.amazon.com/aup/" className="text-blue-600" target="_blank">https://aws.amazon.com/aup/</a></p>
                </li>
                <li>
                  <p>AWS Responsible AI Policy available at: <a href="https://aws.amazon.com/machine-learning/responsible-ai/policy/" className="text-blue-600" target="_blank">https://aws.amazon.com/machine-learning/responsible-ai/policy/</a></p>
                </li>
              </ul>
            </li>
            <li className="pb-4">
              <p className="mb-2"><strong>Anthropic PBC:</strong> Acceptable Use Policy available at: <a href="https://console.anthropic.com/legal/aup" className="text-blue-600" target="_blank">https://console.anthropic.com/legal/aup</a></p>
            </li>
            <li className="pb-4">
              <p className="mb-2"><strong>OpenAI, LLC:</strong> OpenAI policies available at:</p>
              <ul className="list-disc ml-6">
                <li>
                  <p><a href="https://openai.com/policies/usage-policies" className="text-blue-600" target="_blank">https://openai.com/policies/usage-policies</a></p>
                </li>
                <li>
                  <p><a href="https://openai.com/policies/sharing-publication-policy/" className="text-blue-600" target="_blank">https://openai.com/policies/sharing-publication-policy/</a></p>
                </li>
                <li>
                  <p><a href="https://openai.com/policies/service-terms/" className="text-blue-600" target="_blank">https://openai.com/policies/service-terms/</a></p>
                </li>
              </ul>
            </li>
          </ul>
        </section>
      </main>
    </div>
  )
}
