/*

type CreatedObject = {
  name?: string
  title?: string
  startDate?: Date
  endDate?: Date
  linkedInUrl?: string
  seniority?: string
}

const seniorityTypes: string[] = ['BOARD_MEMBER', 'EXECUTIVE', 'FOUNDER']

type Person = ArrayElement<RouterOutputs['company']['people']>

export default function PeopleForCompanyTable() {
  const { companyId } = useCompanySelectorContext()
  const vanillaClient = useVanillaTRPC()

  const peopleQuery = trpc.company.people.useQuery(
    {
      companyId: companyId.value ? companyId.value : 0,
    },
    {
      enabled: isSome(companyId.value),
    },
  )
  const people: Person[] = peopleQuery.data ?? []

  const columns: AnyColumnProps<Person, CreatedObject>[] = [
    {
      header: 'Name',
      inputType: 'input',
      textCellFormat: StringCellFormat,
      accessor: (row) => row.name,
      updateRemote: async (row: Person, newValue: string) => {
        await vanillaClient.person.update.mutate({
          id: row.id,
          name: newValue,
        })
      },
      placeholder: 'Name',
      createProps: {
        isRequired: false,
        createdObjectKey: 'name',
      },
    },
    {
      header: 'LinkedIn URL',
      inputType: 'input',
      textCellFormat: StringCellFormat,
      accessor: (row) => row.linkedInUrl,
      updateRemote: async (row: Person, newValue: string) => {
        await vanillaClient.person.update.mutate({
          id: row.id,
          linkedInUrl: newValue,
        })
      },
      placeholder: 'LinkedIn URL',
      createProps: {
        isRequired: false,
        createdObjectKey: 'linkedInUrl',
      },
    },
    {
      header: 'Title',
      inputType: 'input',
      textCellFormat: StringCellFormat,
      accessor: (row) => row.title,
      updateRemote: async (row: Person, newValue: string) => {
        await vanillaClient.person.update.mutate({
          id: row.id,
          title: newValue,
        })
      },
      placeholder: 'Title',
      createProps: {
        isRequired: false,
        createdObjectKey: 'title',
      },
    },
    {
      header: 'Start Date',
      inputType: 'input',
      textCellFormat: DateCellFormat,
      accessor: (row) => (isSome(row.startDate) ? new Date(row.startDate) : null),
      updateRemote: async (row: Person, newValue: Date) => {
        await vanillaClient.person.update.mutate({
          id: row.id,
          startDate: newValue.toISOString(),
        })
      },
      placeholder: '00/00/0000',
      createProps: {
        isRequired: false,
        createdObjectKey: 'startDate',
      },
    },
    {
      header: 'End Date',
      inputType: 'input',
      textCellFormat: DateCellFormat,
      accessor: (row) => (isSome(row.endDate) ? new Date(row.endDate) : null),
      updateRemote: async (row: Person, newValue: Date) => {
        await vanillaClient.person.update.mutate({
          id: row.id,
          endDate: newValue.toISOString(),
        })
      },
      placeholder: '00/00/0000',
      createProps: {
        isRequired: false,
        createdObjectKey: 'endDate',
      },
    },
    {
      header: 'Seniority',
      inputType: 'multiSelect',
      options: seniorityTypes.map((type) => type.toLowerCase()),
      accessor: (row) => row.seniority?.toLowerCase(),
      updateRemote: async (row: Person, newValue: string) => {
        await vanillaClient.person.update.mutate({
          id: row.id,
          seniority: newValue.toUpperCase(),
        })
      },
      createProps: {
        isRequired: false,
        createdObjectKey: 'seniority',
      },
    },
  ]

  return (
    <div className=" w-full">
      {peopleQuery.isLoading ? (
        <div />
      ) : (
        <div className="w-full">
          <EditableTable<Person, CreatedObject>
            columns={columns}
            rowData={people}
            stickyColumn={true}
            deleteProps={{
              onDelete: async (rowIndex) => {
                const metric = people[rowIndex]
                await vanillaClient.person.delete.mutate({ id: metric.id })
                peopleQuery.refetch()
              },
              confirmationModalProps: {
                title: 'Delete person',
                body: 'Are you sure you want to delete this person?',
                confirmText: 'Delete',
              },
            }}
            onRowCreate={async (createdObject: CreatedObject) => {
              if (!companyId.value) return
              await vanillaClient.person.create.mutate({
                companyId: companyId.value,
                name: createdObject.name,
                title: createdObject.title,
                seniority: createdObject.seniority?.toUpperCase(),
                startDate: createdObject.startDate?.toISOString(),
                endDate: createdObject.endDate?.toISOString(),
                linkedInUrl: createdObject.linkedInUrl,
              })
              peopleQuery.refetch()
            }}
          />
        </div>
      )}
    </div>
  )
}

*/

import { IonItem, IonSpinner } from '@ionic/react'
import { AnyColumnProps } from 'components/editable-table/ColumnProps'
import { useState } from 'react'
import { ArrayElement, isNone, isSome } from 'safety'
import { BasicButton } from '../../components/BasicButton'
import { EditableTable } from '../../components/editable-table/EditableTable'
import { StringCellFormat } from '../../components/editable-table/EditableTextCellFormat'
import { RouterOutputs, trpc } from '../../utils/trpc'
import { useCompanySelectorContext } from './CompanySelectorContextProvider'
import { useCompanySelectorContext as useEnrichedCompanySelectorContext } from './massiveEnrichedCompany/MassiveEnrichedCompanySelectorContextProvider'

type SubscriptionWithUser = ArrayElement<RouterOutputs['companySubscription']['subscribersForCompany']>
type CreatedObject = {
  email: string
}

export default function CompanySubscribers() {
  let { companyId } = useCompanySelectorContext()
  const { pluralCompanyId } = useEnrichedCompanySelectorContext()
  if (pluralCompanyId.value) {
    companyId = pluralCompanyId
  }
  const [sentEmailsSummary, setSentEmailsSummary] = useState('')

  const subscribersQuery = trpc.companySubscription.subscribersForCompany.useQuery(
    {
      companyId: companyId.value ? companyId.value : 0,
    },
    {
      enabled: isSome(companyId.value),
    },
  )

  const subscriptions: SubscriptionWithUser[] = subscribersQuery.data ?? []

  const removeMutation = trpc.companySubscription.removeSubscriptionById.useMutation({
    onSuccess: () => {
      subscribersQuery.refetch()
    },
  })
  const subscribeMutation = trpc.companySubscription.subscribeUserWithEmail.useMutation({
    onSuccess: () => {
      subscribersQuery.refetch()
    },
  })
  const triggerEmailsMutation = trpc.companySubscription.manuallyTriggerUpdatesToSubscribers.useMutation({
    onSuccess: (summary: string) => {
      setSentEmailsSummary(summary)
    },
  })

  const columns: AnyColumnProps<SubscriptionWithUser, CreatedObject>[] = [
    {
      header: 'Email',
      inputType: 'input',
      textCellFormat: StringCellFormat,
      accessor: (row: SubscriptionWithUser) => row.user.email,
      createProps: {
        isRequired: true,
        createdObjectKey: 'email',
      },
    },
  ]

  return (
    <div className=" w-full">
      {subscribersQuery.isLoading ? (
        <div className="loader mt-6 flex w-full flex-col items-center">
          <IonItem>
            <IonSpinner name="lines-sharp"></IonSpinner>
          </IonItem>
        </div>
      ) : (
        <div className="w-full">
          <EditableTable<SubscriptionWithUser, CreatedObject>
            onRowCreate={async (createdObject: CreatedObject) => {
              if (isNone(companyId.value)) return
              subscribeMutation.mutate({
                companyId: companyId.value,
                userEmail: createdObject.email,
              })
            }}
            deleteProps={{
              onDelete: async (rowIndex: number) => {
                let sub = subscriptions[rowIndex]
                removeMutation.mutate({
                  subscriptionId: sub.id,
                })
              },
            }}
            columns={columns}
            rowData={subscriptions}
          />
          <div className="mt-2 flex items-center">
            <BasicButton
              className=" text-sm"
              variant="gray"
              onClick={() => {
                if (isNone(companyId.value)) return
                triggerEmailsMutation.mutate({
                  companyId: companyId.value,
                })
              }}>
              Manually trigger emails
            </BasicButton>
            {sentEmailsSummary.length > 0 && <div className="ml-3 text-sm">{sentEmailsSummary}</div>}
          </div>
        </div>
      )}
    </div>
  )
}
