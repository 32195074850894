export { absorbPromise } from "./absorbPromise";
export type None = null | undefined;
export type Optional<T> = T | None;

export function none<T>(): None {
  return null;
}

export function isNone<T>(value: Optional<T>): value is None {
  return value === null || value === undefined;
}

export function areNone<T>(values: Optional<T>[]): values is None[] {
  return values.every((value) => isNone(value));
}

export function areSome<T>(values: Optional<T>[]): values is T[] {
  return values.every((value) => isSome(value));
}

export function isSome<T>(value: Optional<T>): value is T {
  return !isNone(value);
}

export function isTrue(value: Optional<boolean>): value is true {
  return value === true;
}

export function anyAreNone(...args: any): boolean {
  return args.some((arg: any) => isNone(arg));
}

export function unwrap<T>(value: Optional<T>): T {
  if (isNone(value)) {
    throw new Error(
      "Unexpectedly found none while unwrapping an Optional value"
    );
  }
  return value;
}

export function expect<T>(value: Optional<T>, message?: string): T {
  const _message = message || "Expected a value but found none";
  if (value === null || value === undefined) {
    throw new Error(_message);
  }
  return value;
}
export type ArrayElement<ArrayType extends readonly unknown[]> =
  ArrayType[number];
