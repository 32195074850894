// import { DateTime } from "luxon";

// export function dateWithMarketOpenTime(date: Date): Date {
//   // Create a Luxon DateTime object from the JavaScript Date
//   let dt = DateTime.fromJSDate(date).setZone("America/New_York");

//   // Set the time to 9:30 AM
//   dt = dt.set({
//     hour: 9,
//     minute: 30,
//     second: 0,
//     millisecond: 0,
//   });

//   // Convert the Luxon DateTime back to a JavaScript Date
//   return new Date(dt.toJSDate());
// }

import moment from "moment-timezone";

export function dateWithMarketOpenTime(date: Date): Date {
  // Create a Moment object from the JavaScript Date
  let dt = moment(date).tz("America/New_York");

  // Set the time to 9:30 AM
  dt = dt.set({
    hour: 9,
    minute: 30,
    second: 0,
    millisecond: 0,
  });

  // Convert the Moment object back to a JavaScript Date
  return dt.toDate();
}
