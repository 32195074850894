import { Line, LineChart, XAxis, YAxis } from 'recharts'
import { useIsSmallScreen } from 'several/hooks/navigation'

export default function SmallChart({
  scatterData,
  color = 'black',
  untilToday = false,
  linear = true,
  continueLine = false,
}: {
  scatterData: {
    data: {
      x: Date
      y: number
    }[]
    label: string
    stroke?: string
    fill?: string
  }[]
  color?: 'red' | 'green' | 'black'
  untilToday?: boolean
  linear?: boolean
  continueLine?: boolean
}) {
  let colorString = color === 'green' ? '#228B22' : color
  const dates = scatterData.flatMap((ds) => ds.data.map((point) => point.x.getTime())) // Collect all date timestamps
  const minDate = Math.min(...dates)
  const maxDate = Math.max(...dates)
  let maxValue = Math.max(...scatterData.flatMap((ds) => ds.data.map((point) => point.y)))
  let lengthOfXAxis = maxDate - minDate
  let yAxisBoundaries = [0, Math.ceil((maxValue * 1.1) / 10) * 10]
  let xAxisBoundaries = [minDate, untilToday ? new Date().getTime() : maxDate + lengthOfXAxis * 0.05]
  let isSmallScreen = useIsSmallScreen('md')

  return (
    <div>
      <LineChart width={isSmallScreen ? 100 : 150} height={60}>
        {/* <XAxis
          dataKey="x"
          type="number"
          domain={[minDate, maxDate]}
          tickFormatter={(date) => new Date(date).toLocaleDateString()}
        /> */}
        {/* <YAxis /> */}
        <XAxis
          dataKey="x"
          type="number"
          domain={xAxisBoundaries}
          axisLine={false}
          display="none"
          width={0}
          height={0}
        />
        <YAxis dataKey="y" domain={yAxisBoundaries} axisLine={false} display="none" width={0} />

        {scatterData
          .filter((ds) => ds.data.length)
          .map((ds, index) => {
            const lastPoint = ds.data.sort((a, b) => a.x.getTime() - b.x.getTime())[ds.data.length - 1]
            const lastX = lastPoint.x.getTime()
            const lastY = lastPoint.y
            return (
              <>
                <Line
                  key={ds.label}
                  type={linear ? 'linear' : 'monotone'}
                  width={20}
                  data={ds.data}
                  dataKey="y"
                  name={ds.label}
                  stroke={colorString}
                  dot={false}
                />
                {continueLine && (
                  <Line
                    isAnimationActive={false}
                    key={`${ds.label}-dotted`}
                    tooltipType="none"
                    dataKey="y"
                    type="linear"
                    data={[
                      { x: lastX, y: lastY },
                      { x: xAxisBoundaries[1] - 10, y: lastY },
                    ]}
                    stroke={colorString}
                    strokeDasharray="3 3"
                    dot={false}></Line>
                )}
              </>
            )
          })}
      </LineChart>
    </div>
  )
}
