import { size, useFloating } from '@floating-ui/react'
import { CrossCircledIcon } from '@radix-ui/react-icons'
import { TextField } from '@radix-ui/themes'
import { useSnackbar } from 'providers/SnackbarProvider'
import React, { forwardRef, useEffect, useRef, useState } from 'react'
import { isSome } from 'safety'
import { TextInputSelectOption } from './TextInputSelect'

export const ChipContainer = forwardRef(function ChipContainer(
  {
    children,
    fullWidth,
    maxWidth,
    onClick,
    disabled,
  }: {
    children: React.ReactNode
    fullWidth: boolean
    maxWidth: number
    onClick: () => void
    disabled?: boolean
  },
  ref: React.Ref<HTMLDivElement>,
) {
  return (
    <div
      ref={ref}
      className={`focus-within:border-primary-500 focus-within:ring-primary-500 z-40 flex w-full  flex-wrap items-stretch focus:ring-2
      focus:ring-opacity-50 
      opacity-${disabled ? 50 : 100}`}
      onClick={onClick}
      style={{
        cursor: disabled ? 'not-allowed' : 'pointer',
        background: 'white',
        maxWidth: maxWidth,
        borderRadius: '4px',
        padding: '2px',
        display: 'flex',

        overflowY: 'auto',
        maxHeight: '120px',
        minHeight: '40px',
        height: 'auto',
        textOverflow: 'ellipsis',
        width: fullWidth ? '100%' : '260px',
        boxShadow: '0 0 0 1px var(--gray-a7)',
        boxSizing: 'border-box',
        position: 'relative',
        columnGap: '4px',
        rowGap: '4px',
      }}>
      {children}
    </div>
  )
})

export default function TextInputSelectMulti({
  options,
  onOptionSelect,
  onOptionRemove,
  placeholder,
  selectedOptions,
  createModal,
  noOutline,
  fullWidth,
  maxWidth,
  onOptionsSelect,
}: {
  options: TextInputSelectOption[]
  onOptionSelect: (option: TextInputSelectOption) => void
  onOptionRemove: (option: TextInputSelectOption) => void
  onOptionsSelect: (options: TextInputSelectOption[]) => void
  placeholder: string
  selectedOptions?: string[]
  createModal?: React.ReactNode
  noOutline?: boolean
  renderSelectedOptions?: (options: TextInputSelectOption[]) => React.ReactNode
  fullWidth?: boolean
  maxWidth: number
}) {
  const { refs, floatingStyles } = useFloating({
    placement: 'bottom',
    middleware: [
      size({
        apply({ rects, elements }) {
          Object.assign(elements.floating.style, {
            width: `${rects.reference.width}px`,
          })
        },
      }),
    ],
  })

  const [isTextFieldFocused, setIsTextFieldFocused] = useState(false)

  function unfocus() {
    setIsTextFieldFocused(false)
    setIsEditing(false)
  }
  const [isListHovered, setIsListHovered] = useState(false)

  const [blurCount, setBlurCount] = useState(0)

  const [inputText, setInputText] = useState('')
  const [filteredOptionsHighlightIndex, setFilteredOptionsHighlightIndex] = useState(0)
  const inputRef = useRef<HTMLInputElement>(null)

  const filteredOptions = options.filter((option) => {
    let alreadyExists = selectedOptions?.includes(option.id?.toString() ?? '')
    let text = inputText.toLowerCase()
    let label = option.label.toLowerCase()
    let additionalSearchString = option.additionalSearchString?.toLowerCase() ?? ''
    return (label.includes(text) || additionalSearchString.includes(text)) && !alreadyExists
  })

  console.log(filteredOptions, 'fo')
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        unfocus()
      }
    }

    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [])

  function handleKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'ArrowDown') {
      event.preventDefault()
      setFilteredOptionsHighlightIndex((prev) => (prev + 1) % filteredOptions.length)
    }
    if (event.key === 'ArrowUp') {
      event.preventDefault()
      setFilteredOptionsHighlightIndex((prev) => (prev - 1 + filteredOptions.length) % filteredOptions.length)
    }
    if (event.key === 'Enter') {
      const inputText = filteredOptions[filteredOptionsHighlightIndex].label
      checkShouldSetSelection(inputText)
      setInputText(inputText)
      removeFocus()
    }
  }
  function removeFocus() {
    unfocus()
    inputRef.current?.blur()
  }

  function checkShouldSetSelection(_inputText: string) {
    const index = options.map((o) => o.label.toLowerCase()).indexOf(_inputText.toLowerCase())
    if (index !== -1) {
      const option = options[index]
      onOptionSelect(option)
    }
  }

  useEffect(() => {
    if (filteredOptionsHighlightIndex !== -1) {
      scrollToItem(filteredOptionsHighlightIndex)
    }
  }, [filteredOptionsHighlightIndex])

  function scrollToItem(index: number) {
    const itemElement = document.querySelector(`#item-${index}`)
    itemElement?.scrollIntoView({
      behavior: 'smooth', // Optional: defines the transition animation
      block: 'nearest', // Optional: defines vertical alignment
      inline: 'start', // Optional: defines horizontal alignment
    })
  }

  const [isEditing, setIsEditing] = useState(false)
  let { setSnackbarText } = useSnackbar()
  return (
    <div className={`${noOutline ? 'no-border-outline-in' : ''} relative w-full max-w-[${maxWidth}px] flex-grow-0`}>
      {true && (
        <ChipContainer
          fullWidth={!!fullWidth}
          maxWidth={maxWidth}
          onClick={() => {
            setIsEditing(true)
            inputRef.current?.focus()
          }}>
          {selectedOptions?.map((value, i) => {
            const option = options.find((o) => o.id === value)
            if (!option) return null
            const optionIdx = selectedOptions.findIndex((o) => o === value)
            if (optionIdx !== i) return null
            return (
              <div
                className="mr-1 flex flex-row items-center gap-1 rounded-full bg-gray-100 p-2 text-sm"
                key={`${option.id ? option.id : ''}-${option.label}`}>
                {option.label}
                <CrossCircledIcon
                  onClick={(e) => {
                    e.stopPropagation()
                    onOptionRemove(option)
                  }}
                  style={{
                    width: '15px',
                    height: '15px',
                    cursor: 'pointer',
                  }}
                />
              </div>
            )
          })}
          {(selectedOptions?.length ?? 0) <= 1 && !isEditing && (
            <div className="flex h-[40px] w-full items-center pl-[6px] text-sm text-gray-400">{placeholder}</div>
          )}
        </ChipContainer>
      )}
      <TextField.Root
        style={{
          width: fullWidth ? '100%' : '260px',
          height: isEditing ? '40px' : '0px',
          opacity: isEditing ? 1 : 0,
          position: 'absolute',
          bottom: '-45px',
          outline: 'none',
          border: 'none',
          zIndex: 100000,
        }}
        ref={refs.setReference}
        onBlur={() => {
          if (!isListHovered) {
            unfocus()
            setBlurCount(blurCount + 1)
          }
        }}
        onFocus={() => {
          setInputText('')
          setIsTextFieldFocused(true)
        }}>
        <TextField.Input
          onPaste={(e) => {
            let clipboardText = e.clipboardData.getData('text')
            let clipboardArray = clipboardText.split('\n')
            let unselected: string[] = []
            let selected: TextInputSelectOption[] = []

            let filteredClipboardArr = Array.from(new Set(clipboardArray.filter((item) => item.trim() !== '')))

            if (filteredClipboardArr.length > 1) {
              e.preventDefault()
            }

            filteredClipboardArr.forEach((text) => {
              let option = options.find((o) => o.label.toLowerCase() === text.toLowerCase())
              let option2 = options.find(
                (o) => o.label.toLowerCase().replace(/\s+/g, '') === text.toLowerCase().replace(/\s+/g, ''),
              )
              let option3 = options.find((o) => o.label.toLowerCase().includes(text.toLowerCase()))
              let option4 = options.find((o) => text.toLowerCase().includes(o.label.toLowerCase()))
              if (option) {
                selected.push(option)
              } else if (option2) {
                selected.push(option2)
              } else if (option3) {
                selected.push(option3)
              } else if (option4) {
                selected.push(option4)
              } else {
                unselected.push(text)
              }
            })

            if (unselected.length > 0 && filteredClipboardArr.length > 1) {
              setSnackbarText(`The following items were not found: ${unselected.join(', ')}`, 7000)
            }
            if (selected.length > 0) {
              onOptionsSelect(selected)
              unfocus()
            }
          }}
          style={{
            width: fullWidth ? '100%' : '260px',
            height: isEditing ? '40px' : '0px',
            opacity: isEditing ? 1 : 0,
            outline: 'none',
            border: 'none',
          }}
          ref={inputRef}
          placeholder={placeholder}
          value={inputText}
          onChange={(e) => {
            checkShouldSetSelection(e.target.value)
            setInputText(e.target.value)
          }}
          onKeyDown={handleKeyDown}
          size={'2'}
        />
      </TextField.Root>
      {isTextFieldFocused && (
        <div
          ref={refs.setFloating}
          style={floatingStyles}
          className={`relative z-50 border bg-white text-sm`}
          onMouseEnter={() => setIsListHovered(true)}
          onMouseLeave={() => setIsListHovered(false)}>
          <div className="max-h-40 overflow-y-auto">
            {filteredOptions.map((option, index) => {
              const isSelected = filteredOptionsHighlightIndex === index
              return (
                <div
                  key={`item-${index}`}
                  id={`item-${index}`}
                  onClick={() => {
                    const _inputText = option.label
                    checkShouldSetSelection(_inputText)
                    setInputText(_inputText)
                    removeFocus()
                  }}
                  className={`cursor-pointer ${isSelected ? 'bg-gray-100' : 'bg-white'} flex flex-row items-center gap-2 pb-1 pl-2 pt-1 hover:bg-gray-100`}>
                  {option.startNode}
                  {option.label}
                  {option.endNode && <div className="ml-auto pr-2">{option.endNode}</div>}
                </div>
              )
            })}
          </div>
          {isSome(createModal) && (
            <div className="flex h-8 w-full flex-col items-start justify-center border-t bg-white pl-2">
              {createModal}
            </div>
          )}
        </div>
      )}
    </div>
  )
}
