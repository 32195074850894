import {
  numberToAbbreviatedDollarString,
  numberToAbbreviatedString,
  numberToReadableDollarString,
  numberToReadableString,
} from 'plural-shared/utils'
import { isSome, Optional } from 'safety'
import { titleCase } from 'several/hooks/FundingRound'
import { safeCastToNumber } from '../../pages/cashSimulator/CashSimulatorContext'

export type EditableTextCellFormat<V> = {
  id: string
  viewModeString: (value: V) => string
  editModeString: (value: V) => string
  valueFromEditModeString: (editModeString: string, initialValue: Optional<V>) => Optional<V>
  onInputChange: (value: string) => Optional<string>
  floatingModeString?: (value: V) => string
}
export const StringCellFormat: EditableTextCellFormat<string> = {
  id: 'string',
  viewModeString: (value) => value,
  editModeString: (value) => value,
  valueFromEditModeString: (editModeString: string) => editModeString,
  onInputChange: (value) => value,
}

export const TitleStringCellFormat: EditableTextCellFormat<string> = {
  id: 'titleString',
  viewModeString: (value) => titleCase(value),
  editModeString: (value) => value,
  valueFromEditModeString: (editModeString: string) => editModeString,
  onInputChange: (value) => value,
}
export const BigIntDollarsCellFormat: EditableTextCellFormat<number> = {
  id: 'bigIntDollars',
  viewModeString: (value) => numberToAbbreviatedDollarString(value, 2, 2),
  editModeString: (value) => String(value),
  floatingModeString: (value) => numberToReadableDollarString(value),
  valueFromEditModeString: (editModeString: string) => {
    let cast = safeCastToNumber(editModeString)
    return isSome(cast) ? cast : null
  },
  onInputChange: (input) => {
    let newValue = input.replace(/[^0-9.-]/g, '')
    return newValue
  },
}

export const BigIntCellFormat: EditableTextCellFormat<number> = {
  id: 'bigInt',
  viewModeString: (value) => numberToAbbreviatedString(value),
  editModeString: (value) => String(value),
  floatingModeString: (value) => numberToReadableString(value),
  valueFromEditModeString: (editModeString: string) => {
    let cast = safeCastToNumber(editModeString)
    return isSome(cast) ? cast : null
  },
  onInputChange: (input) => {
    let newValue = input.replace(/[^0-9.-]/g, '')
    return newValue
  },
}
export const CSVCellFormat: EditableTextCellFormat<string[]> = {
  id: 'csv',
  viewModeString: (value) => value.join(', '),
  editModeString: (value) => value.join(', '),
  valueFromEditModeString: (editModeString: string) => {
    let split = editModeString.split(',').map((s) => s.trim())
    return split.length ? split : null
  },
  onInputChange: (value) => value,
}

export const PercentCellFormat: EditableTextCellFormat<number> = {
  id: 'percent',
  viewModeString: (value) => `${(value * 100).toFixed(2)}%`,
  editModeString: (value) => String(value),
  valueFromEditModeString: (editModeString: string) => {
    let cast = safeCastToNumber(editModeString)
    return isSome(cast) ? cast : null
  },
  onInputChange: (input) => {
    let newValue = input.replace(/[^0-9.-]/g, '')
    return newValue
  },
}

export const FullPercentCellFormat: EditableTextCellFormat<number> = {
  id: 'percent',
  viewModeString: (value) => `${value.toFixed(2)}%`,
  editModeString: (value) => String(value.toFixed(2)),
  valueFromEditModeString: (editModeString: string) => {
    let cast = safeCastToNumber(editModeString)
    return isSome(cast) ? cast : null
  },
  onInputChange: (input) => {
    let newValue = input.replace(/[^0-9.-]/g, '')
    return newValue
  },
}

export const NumberCellFormat: EditableTextCellFormat<number> = {
  id: 'number',
  viewModeString: (value) => String(value),
  editModeString: (value) => String(value),
  valueFromEditModeString: (editModeString: string) => {
    let cast = safeCastToNumber(editModeString)
    return isSome(cast) ? cast : null
  },
  onInputChange: (input) => {
    let newValue = input.replace(/[^0-9.-]/g, '')
    return newValue
  },
}

export const AbbreviatedDollarsCellFormat: EditableTextCellFormat<number> = {
  id: 'dollars',
  viewModeString: (value) => numberToAbbreviatedDollarString(value, 2, 2),
  editModeString: (value) => String(value),
  floatingModeString: (value) => numberToReadableDollarString(value),
  valueFromEditModeString: (editModeString: string) => {
    let cast = safeCastToNumber(editModeString)
    return isSome(cast) ? cast : null
  },
  onInputChange: (input) => {
    let newValue = input.replace(/[^0-9.-]/g, '')
    return newValue
  },
}

export const DollarsCellFormat: EditableTextCellFormat<number> = {
  id: 'dollars',
  viewModeString: (value) => numberToReadableDollarString(value),
  editModeString: (value) => String(value),
  floatingModeString: (value) => numberToReadableDollarString(value),
  valueFromEditModeString: (editModeString: string) => {
    let cast = safeCastToNumber(editModeString)
    return isSome(cast) ? cast : null
  },
  onInputChange: (input) => {
    let newValue = input.replace(/[^0-9.-]/g, '')
    return newValue
  },
}
export const DateCellFormat: EditableTextCellFormat<Date> = {
  id: 'date',
  viewModeString: (value) => value.toLocaleDateString('en-US', { month: 'short', year: 'numeric', day: 'numeric' }),
  editModeString: (value) => value.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' }),
  valueFromEditModeString: (editModeString) => {
    let cast = new Date(editModeString)
    let isValid = !isNaN(cast.getTime())
    return isValid ? cast : null
  },
  onInputChange: (input) => {
    return input
  },
}

export const DateStringCellFormat: EditableTextCellFormat<string> = {
  id: 'dateString',
  viewModeString: (value) =>
    value
      ? new Date(value).toLocaleDateString('en-US', {
          month: 'short',
          year: 'numeric',
          day: 'numeric',
          timeZone: 'UTC',
        })
      : '',
  editModeString: (value) =>
    value
      ? new Date(value).toLocaleDateString('en-US', {
          month: '2-digit',
          day: '2-digit',
          year: 'numeric',
          timeZone: 'UTC',
        })
      : '',
  valueFromEditModeString: (editModeString) => {
    let cast = new Date(editModeString)
    let isValid = !isNaN(cast.getTime())
    return isValid
      ? cast.toLocaleDateString('en-US', { month: 'short', year: 'numeric', day: 'numeric', timeZone: 'UTC' })
      : null
  },
  onInputChange: (input) => {
    return input
  },
}
