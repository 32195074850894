import { ArrayElement } from 'safety'
import { RouterOutputs } from '../../utils/trpc'
import { useCompanySelectorContext } from './CompanySelectorContextProvider'
import CreateCompanyModal from './CreateCompanyModal'
import TextInputSelect from './TextInputSelect'

type Company = ArrayElement<RouterOutputs['companies']['allForSelector']>

export default function CompanySelectorForEditor() {
  const { companies, didSelectCompany, companyId } = useCompanySelectorContext()

  const selectedIndex = companies.findIndex((company) => company.id === companyId.value)
  const initialOption =
    selectedIndex === -1
      ? undefined
      : {
          label: companies[selectedIndex].name,
          index: selectedIndex,
        }

  return (
    <div className="w-full">
      <TextInputSelect
        options={companies.map((company: Company, index: number) => ({ label: company.name, index }))}
        initialOption={initialOption}
        onOptionSelect={(option) => {
          didSelectCompany(companies[option.index])
        }}
        placeholder="Find a company..."
        createModal={
          <CreateCompanyModal
            onCreate={(newCompany) => {
              didSelectCompany(newCompany)
            }}
          />
        }
      />
    </div>
  )
}
