import { Select, Switch, TextField } from '@radix-ui/themes'
import { NumberFormattedInput } from 'components/FormattedInput'
import { numberToReadableDollarString } from 'plural-shared/utils'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { isSome } from 'safety'
import { CompanyOptions, safeCastToNumber, useSimulatorContext } from './CashSimulatorContext'
import GenericNumberInput from './GenericNumberOfferInput'
const inputLabelColor = '#5A5A5A'

function InputLabel({ label }: { label: string }) {
  return (
    <div className="pb-2 pt-4 text-sm" style={{ color: inputLabelColor }}>
      {label}
    </div>
  )
}

export default function OfferInputs() {
  const sectionHeaderFont = '#585858'
  const context = useSimulatorContext()
  const { txInputs } = context ?? {}
  const [displayedReserveRatio, setDisplayedReserveRatio] = useState<string>('')
  const [displayedTotalTaxRate, setDisplayedTotalTaxRate] = useState<string>('')
  const [displayedAltInvestmentIrr, setDisplayedAltInvestmentIrr] = useState<string>('')
  const [displayedSecondaryBrokerTakeRate, setDisplayedSecondaryBrokerTakeRate] = useState<string>('')
  const [displayedBsp, setDisplayedBsp] = useState<string>('')
  const [displayedLatestFundingDate, setDisplayedLatestFundingDate] = useState<string>('')
  const [displayedLatestFundingPps, setDisplayedLatestFundingPps] = useState<string>('')
  const [displayedYearsToNextFunding, setDisplayedYearsToNextFunding] = useState<string>('')
  const [displayedNextFundingPps, setDisplayedNextFundingPps] = useState<string>('')
  const [isEditingNextPps, setIsEditingNextPps] = useState(false)

  useEffect(() => {
    setDisplayedReserveRatio(String(txInputs?.reserveRatio.value ?? ''))
  }, [txInputs?.reserveRatio.value])

  useEffect(() => {
    setDisplayedBsp(String(txInputs?.secondarySharePrice.value ?? ''))
  }, [txInputs?.secondarySharePrice.value])

  useEffect(() => {
    if (!txInputs?.totalTaxRate.value) {
      return
    }
    setDisplayedTotalTaxRate(String(txInputs.totalTaxRate.value * 100 ?? ''))
  }, [txInputs?.totalTaxRate.value])

  useEffect(() => {
    if (!txInputs?.altInvestmentIrr.value) {
      return
    }
    setDisplayedAltInvestmentIrr(String(txInputs.altInvestmentIrr.value * 100))
  }, [txInputs?.altInvestmentIrr.value])

  useEffect(() => {
    if (!txInputs?.secondaryBrokerTakeRate.value) {
      return
    }
    setDisplayedSecondaryBrokerTakeRate(String(txInputs.secondaryBrokerTakeRate.value * 100))
  }, [txInputs?.secondaryBrokerTakeRate.value])

  useEffect(() => {
    if (!txInputs?.mostRecentFinancingEventDate.value) {
      return
    }
    setDisplayedLatestFundingDate(
      txInputs.mostRecentFinancingEventDate.value.toLocaleDateString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      }),
    )
  }, [txInputs?.mostRecentFinancingEventDate.value])

  useEffect(() => {
    setDisplayedLatestFundingPps(String(txInputs?.mostRecentFinancingEventIssuePrice.value ?? ''))
  }, [txInputs?.mostRecentFinancingEventIssuePrice.value])

  useEffect(() => {
    setDisplayedYearsToNextFunding(String(txInputs?.yearsUntilNextFinancingEvent.value ?? ''))
  }, [txInputs?.yearsUntilNextFinancingEvent.value])

  useEffect(() => {
    console.log('isEditingNextPps', isEditingNextPps)
    if (isEditingNextPps) {
      console.log('bailing')
      return
    }
    setDisplayedNextFundingPps(String(txInputs?.nextFinancingEventIssuePrice.value ?? ''))
  }, [txInputs?.nextFinancingEventIssuePrice.value, isEditingNextPps])

  if (!context || !txInputs) {
    return null
  }

  const companyOptions: CompanyOptions = context?.companyOptions ?? []
  function CompanySelect() {
    if (!context) {
      return null
    }
    return (
      <Select.Root
        value={context.selectedCompanyProps.profile?.name}
        onValueChange={(newValue) => {
          context.didSelectCompanyName(newValue)
        }}
        size="2">
        <Select.Trigger radius="large" />
        <Select.Content>
          {companyOptions.map((option) => (
            <Select.Item value={option.name} key={option.domain} className="pr-2">
              {option.name}
            </Select.Item>
          ))}
        </Select.Content>
      </Select.Root>
    )
  }

  return (
    <div className="w-full bg-[#F7F7F7] lg:w-[329px] lg:overflow-y-auto">
      {isSome(context) && (
        <div className="ml-7 mr-7 mt-16 flex flex-col justify-center pb-12">
          <div
            className={`text-xl font-medium`}
            style={{
              color: sectionHeaderFont,
            }}>
            Offer
          </div>
          <div className="w-full">
            <InputLabel label="Company" />
          </div>
          <CompanySelect />
          <div className="">
            <InputLabel label="Cash today" />
            <div className="relative w-full">
              <NumberFormattedInput
                placeholder=""
                size={'2'}
                value={String(txInputs.principal.value)}
                onChange={(val) => {
                  let num = safeCastToNumber(val)
                  if (isSome(num) && !val.endsWith('.') && val !== '') {
                    txInputs.principal.setValue(num)
                  }
                }}
                className="ml-3 text-base"
              />
              <div className="absolute left-2 top-[6px] text-sm">$</div>
            </div>
          </div>
          <div className="">
            <InputLabel label="Secondary PPS" />
            <GenericNumberInput
              value={displayedBsp}
              isDollars={true}
              onNewNumber={(num) => {
                txInputs.secondarySharePrice.setValue(num)
              }}
            />
          </div>
          <div className="mt-9">
            <div
              className={`text-xl font-medium`}
              style={{
                color: sectionHeaderFont,
                borderRadius: '4px',
              }}>
              Assumptions
            </div>
            <div className="">
              <InputLabel label="Reserve multiple" />
              <GenericNumberInput
                value={displayedReserveRatio}
                onNewNumber={(num) => {
                  txInputs.reserveRatio.setValue(num)
                }}
              />
            </div>
            <div className="">
              <InputLabel label="Tax rate" />
              <GenericNumberInput
                value={displayedTotalTaxRate}
                isPercentage={true}
                onNewNumber={(num) => {
                  txInputs.totalTaxRate.setValue(num)
                }}
              />
            </div>
            <div>
              <InputLabel label="Alternative IRR" />
              <GenericNumberInput
                value={displayedAltInvestmentIrr}
                isPercentage={true}
                onNewNumber={(num) => {
                  txInputs.altInvestmentIrr.setValue(num)
                }}
              />
            </div>
            <div>
              <InputLabel label="Secondary broker fee" />
              <GenericNumberInput
                value={displayedSecondaryBrokerTakeRate}
                isPercentage={true}
                onNewNumber={(num) => {
                  txInputs.secondaryBrokerTakeRate.setValue(num)
                }}
              />
            </div>
            <div>
              <div className="mt-4 flex items-center">
                <Switch
                  checked={txInputs.shouldUseAutoBaseSharePrice.value}
                  size="1"
                  color="gray"
                  onCheckedChange={(checked) => {
                    txInputs.shouldUseAutoBaseSharePrice.setValue(checked)
                  }}
                />
                <div
                  className="pl-2 text-sm"
                  style={{
                    color: inputLabelColor,
                  }}>
                  Auto base PPS
                </div>
              </div>
              {txInputs.shouldUseAutoBaseSharePrice.value === true && (
                <div>
                  <div
                    className={`mt-5 text-sm font-medium`}
                    style={{
                      color: sectionHeaderFont,
                    }}>
                    Base Share Price Inputs
                  </div>
                  <div>
                    <InputLabel label="Latest funding date" />
                    <TextField.Input
                      placeholder="MM/DD/YYYY"
                      size={'2'}
                      value={displayedLatestFundingDate}
                      onChange={(e) => {
                        setDisplayedLatestFundingDate(e.target.value)
                        let date = new Date(e.target.value)
                        const isValidDate = /^\d{2}\/\d{2}\/\d{4}$/.test(e.target.value)
                        if (isValidDate && !isNaN(date.getTime())) {
                          txInputs.mostRecentFinancingEventDate.setValue(date)
                        }
                      }}
                    />
                  </div>
                  <div className="">
                    <InputLabel label="Latest funding PPS" />
                    <GenericNumberInput
                      value={displayedLatestFundingPps}
                      placeholder=""
                      isDollars={true}
                      onNewNumber={(num) => {
                        txInputs.mostRecentFinancingEventIssuePrice.setValue(num)
                      }}
                    />
                  </div>
                  <div>
                    <InputLabel label="Years from now to next funding" />
                    <GenericNumberInput
                      value={displayedYearsToNextFunding}
                      placeholder="0"
                      onNewNumber={(num) => {
                        txInputs.yearsUntilNextFinancingEvent.setValue(num)
                      }}
                    />
                  </div>
                  <div>
                    <InputLabel label="Next funding PPS" />
                    <GenericNumberInput
                      value={displayedNextFundingPps}
                      isDollars={true}
                      onNewNumber={(num) => {
                        txInputs.nextFinancingEventIssuePrice.setValue(num)
                      }}
                    />
                  </div>
                  <div className="mt-3 opacity-60">
                    {context.pluralCashTx?.inputs().baseSharePrice && (
                      <div className="text-sm">
                        Base PPS: {numberToReadableDollarString(context.pluralCashTx?.inputs().baseSharePrice)}
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
            <div className="mt-8 text-sm opacity-60">
              Looking for the{' '}
              <span className="underline">
                <Link to="/calculator">v1 calculator</Link>
              </span>
              ?
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
