export default function Disclaimers() {
  return (
    <div className="ml-6 mr-6 flex flex-col items-center">
      <div className="mb-20 mt-20 w-full max-w-page-max text-sm">
        Disclaimer: Any documents or communications by Plural Capital, LLC or its affiliates (together referred to
        hereinafter as “Plural Capital”) are intended to be confidential unless specifically indicated otherwise and
        shall not constitute an offer to sell or the solicitation of any offer to buy which may only be made at the time
        a qualified offeree receives a subscription agreement which contains important information (including investment
        objective, policies, risk factors, fees, tax implications and relevant qualifications), and only in those
        jurisdictions where permitted by law. While all the information prepared in this document is believed to be
        accurate, Plural Capital makes no express warranty as to the completeness or accuracy, nor can it accept
        responsibility for errors, appearing in the document. In particular, certain factual information contained
        herein has been obtained from third parties, and the investment manager has not independently verified such
        information. <br />
        <div style={{ height: '15px' }}></div>
        An investment in any fund is speculative and involves a high degree of risk. Opportunities for redemptions and
        transferability of interests are restricted, so investors may not have access to capital when it is needed.
        There is no secondary market for the interests and none is expected to develop. Plural Capital’s portfolio,
        which is under the sole trading authority of the investment manager, is primarily concentrated in the
        technology, media and telecommunications sector and this lack of diversification may result in a higher risk. A
        portion of the trades executed may take place on non-U.S. Exchanges. Leverage may be employed in the portfolio,
        which can make investment performance volatile. An investor should not make an investment unless it is prepared
        to lose all or a substantial portion of its investment. The fees and expenses charged in connection with this
        investment may be higher than the fees and expenses of other investment alternatives and may offset profits.
        <br /> <div style={{ height: '15px' }}></div>
        There is no guarantee that any investment objectives described by Plural Capital will be achieved. Moreover, the
        past performance (if any) of the investment team should not be construed as an indicator of future performance.
        Any projections, market outlooks, or estimates presented by Plural Capital may be forward-looking statements
        and/or based upon certain assumptions. Other events which were not taken into account may occur and may
        significantly affect the returns or performance of the fund. Any projections, outlooks, or assumptions should
        not be construed to be indicative of the actual events which will occur. Further, any statements of opinion
        contained herein constitute only current opinions of the investment manager, which are subject to change and
        which the investment manager does not undertake to update.
        <br /> <div style={{ height: '15px' }}></div>
        Any fund portfolio report is for illustrative purposes only. Generally, the portfolio views referenced by Plural
        Capital may not yet closed and may be subject to material changes relating to its holdings and allocations. We
        cannot guarantee the accuracy or completeness of information relating to the fund at this time, and we assume no
        liability for damages resulting from or arising out of the use of such information. Any information contained in
        this report relating to pricing has been gathered from sources we believe to be reliable, but we do not
        guarantee the accuracy or completeness of such information, and we assume no liability for damages resulting
        from or arising out of the use of such information. The performance numbers displayed in any materials conveyed
        by Plural Capital may have been adversely or favorably impacted by events and economic conditions that will not
        prevail in the future. Certain information contained herein is based upon hypothetical performance. Hypothetical
        performance results may have inherent limitations, some of which are described below. No representation is being
        made that any account will or is likely to achieve profits similar to those shown. In fact, there are frequently
        significant differences between hypothetical performance results subsequently achieved by following a particular
        strategy. One of the limitations of hypothetical performance results is that they are prepared with the benefit
        of hindsight. Past performance is not indicative of future results. In addition, hypothetical trading does not
        involve financial risk, and no hypothetical trading record can completely account for the impact of financial
        risk associated with actual trading, including changing objectives and constraints on the management of the
        account. There are numerous other factors related to the markets in general or to the implementation of any
        specific trading strategy that cannot be fully accounted for in the preparation of hypothetical performance
        results, all of which can adversely affect actual results.
      </div>
    </div>
  )
}
