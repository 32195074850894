import * as SwitchPrimitive from '@radix-ui/react-switch'
// import './switch.css'

export function Switch({
  leftLabel,
  rightLabel,
  isRight,
  setIsRight,
}: {
  leftLabel: string
  rightLabel: string
  isRight: boolean
  setIsRight: (value: boolean) => void
}) {
  return (
    <form>
      <div className="flex items-center">
        <label className="text-plural-sand-600 text-xs leading-none pr-[8px]">{leftLabel}</label>
        <SwitchPrimitive.Root
          checked={isRight}
          onCheckedChange={setIsRight}
          className={`w-[36px] h-[22px] bg-plural-sand-600 rounded-full outline-none cursor-default`}
          id="airplane-mode"
          style={{ '-webkit-tap-highlight-color': 'rgba(0, 0, 0, 0)' } as React.CSSProperties}>
          <SwitchPrimitive.Thumb className="block w-[17px] h-[17px] bg-white rounded-full shadow-[0_2px_2px] shadow-plural-sand-600 transition-transform duration-100 translate-x-0.5 will-change-transform data-[state=checked]:translate-x-[17px]" />
        </SwitchPrimitive.Root>
        <label className="text-plural-sand-600 text-xs pl-[8px]">{rightLabel}</label>
      </div>
    </form>
  )
}
