import AppModal, { AppModalProps } from 'components/AppModal'
import AppTextField from 'components/AppTextfield'
import { useSimulatorContext } from 'pages/cashSimulator/CashSimulatorContext'
import { numberToPercentChangeString, numberToReadableDollarString } from 'plural-shared/utils'
import { useEffect, useState } from 'react'

const color = '#5A5A5A'
export default function CashSimDetailModal(props: AppModalProps) {
  let context = useSimulatorContext()
  if (!context?.pluralCashTx) {
    return null
  }
  const dollarsSaved =
    context.netCashGraphProps?.pluralBar.netCashValue! - context.netCashGraphProps?.secondaryBar.netCashValue!
  let infoLabels = [
    { label: 'Shares Exchanged', value: Math.floor(context.pluralCashTx.sharesOwed()).toLocaleString() },
    { label: 'Shares Reserved', value: Math.floor(context.pluralCashTx.sharesReserved()).toLocaleString() },
    {
      label: 'Dollars Saved',
      value: `${dollarsSaved >= 0 ? '+' : ''}${numberToReadableDollarString(dollarsSaved)}`,
    },
    {
      label: 'Company Growth From Base',
      value: `${numberToPercentChangeString(context.pluralCashTx.inputs().companyCagrFromBase)}`,
    },
    { label: 'Plural Return', value: `${numberToPercentChangeString(context.pluralCashTx.pluralCagr())}` },
    {
      label: 'Settlement Share Price',
      value: numberToReadableDollarString(context.pluralCashTx.txSharePrice()),
    },
  ]

  return (
    <AppModal {...props}>
      <AppModal.Header>Cash Simulator Details</AppModal.Header>
      <AppModal.Body>
        <div className="w-[700px]">
          <CashSimValuationInput />
          <div className="mt-6 flex flex-col gap-4">
            {infoLabels.map((label) => {
              return <InfoLabel key={label.label} label={label.label} value={label.value} />
            })}
          </div>
        </div>
      </AppModal.Body>
    </AppModal>
  )
}

function CashSimValuationInput() {
  let context = useSimulatorContext()
  const [currentCompanyValuation, setCurrentCompanyValuation] = useState(
    context?.multiplesSlider.activeCompanyValuation?.toFixed(2) ?? '0',
  )

  useEffect(() => {
    setCurrentCompanyValuation(context?.multiplesSlider.activeCompanyValuation?.toFixed(2) ?? '0')
  }, [context?.multiplesSlider.activeCompanyValuation])

  return (
    <div className="flex flex-col gap-2">
      <AppTextField
        startAdornment="$"
        label={`Company Valuation`}
        endAdornment={`in ${context?.txInputs.yearsToOutcome.value} years`}
        placeholder=""
        size={'2'}
        value={currentCompanyValuation}
        onChange={(e) => {
          setCurrentCompanyValuation(e.target.value)
        }}
        onBlur={() => {
          if (currentCompanyValuation) {
            context?.setActiveCompanyValuation(parseFloat(currentCompanyValuation))
          }
        }}
        className="ml-3 text-base"
      />
      <div className="text-xs" style={{ color: color }}>
        {numberToReadableDollarString(context?.multiplesSlider.activeCompanyValuation ?? 0)}
      </div>
    </div>
  )
}

function InfoLabel(props: { label: string; value: string | number }) {
  return (
    <div className="flex w-full flex-row items-center justify-between">
      <div className="text-sm" style={{ color: color }}>
        {props.label}
      </div>
      <div>{props.value}</div>
    </div>
  )
}
