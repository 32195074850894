import { Month } from "../dataStructures/month";
import { dateWithMarketOpenTime } from "./dateWithMarketOpenTime";

export function createMarketOpenDate(
  month: Month,
  day: number,
  year: number
): Date {
  return dateWithMarketOpenTime(new Date(year, month, day, 12));
}
