import * as React from 'react'

import { cn } from 'lib/utils'

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {}

const Input = React.forwardRef<HTMLInputElement, InputProps>(({ className, type, ...props }, ref) => {
  return (
    <input
      type={type}
      className={cn(
        'flex px-3 h-6 rounded-lg text-xs w-full border border-plural-sand-300 bg-plural-sand-100 hover:bg-plural-sand-200 ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus:border-black disabled:cursor-not-allowed disabled:opacity-50 mt-0 font-medium',
        className
      )}
      ref={ref}
      {...props}
    />
  )
})
Input.displayName = 'Input'

export { Input }
