import { CardStackPlusIcon, CrossCircledIcon } from '@radix-ui/react-icons'
import { Button, TextField } from '@radix-ui/themes'
import { useQueryClient } from '@tanstack/react-query'
import { getQueryKey } from '@trpc/react-query'
import AppTextField, { InputLabel } from 'components/AppTextfield'
import { BasicButton } from 'components/BasicButton'
import { DatePicker } from 'components/DatePicker'
import { ScrollText } from 'lucide-react'
import { useClosedSideNav } from 'providers/NavigationUIProvider'
import { useEffect, useRef, useState } from 'react'
import { ArrayElement, isNone, isSome } from 'safety'
import CompanyFundingRoundsTable from 'several/components/CompanyFundingRoundsTable'
import DropdownSearch from 'several/components/DropdownSearch'
import { PageLoader } from '../../PageLoader'
import { usePluralAuth } from '../../providers/PluralAuthProvider'
import { AuthStatus } from '../../utils/authStatus'
import { RouterOutputs, trpc } from '../../utils/trpc'
import { AttributedCompanyMetricsTable } from './AttributedCompanyMetricsTable'
import { CompanyDebtEventsTable } from './CompanyDebtEventsTable'
import { CompanyFinancingDataTable } from './CompanyFinancingDataTable'
import CompanyMarks from './CompanyMarks'
import { CompanyRevenueTable } from './CompanyRevenueTable'
import CompanySubscribers from './CompanySubscribers'
import ComparableCompaniesEditor from './ComparableCompaniesEditor'
import { MarksChart } from './MarksChart'
import PeopleForCompanyTable from './PeopleForCompanyTable'
import ProjectedCompanyMetricsTable from './ProjectedCompanyMetricsTable'
import ProjectionResult from './ProjectionResult'
import SubscribeToCompany from './SubscribeToCompany'
import V2CalculatorCompanyProfileEditor from './V2CalculatorCompanyProfileEditor'
import CompanyHeadcountAndOpenRoleData from './massiveEnrichedCompany/HeadcountAndOpenRolesData'
import {
  useCompanySelectorContext,
  withCompanySelectorContext,
} from './massiveEnrichedCompany/MassiveEnrichedCompanySelectorContextProvider'

export default withCompanySelectorContext(CompanyProfile, '/company-profile')

function CompanyProfile() {
  const { isLoading: isAuthLoading, authStatus, user: authUser } = usePluralAuth()
  const isCompanyEditorQuery = trpc.attributedCompanyMetrics.isCompanyEditor.useQuery(undefined, {
    enabled: !isAuthLoading,
  })
  const { isLoadingCompanies, refetch, didSelectCompany, projectionIncrement } = useCompanySelectorContext()

  const updateCompanyNameMutation = trpc.company.updateName.useMutation()
  const updateCompanyLegalNameMutation = trpc.company.updateLegalName.useMutation()
  const updateJurisdictionMutation = trpc.company.updateJurisdiction.useMutation()
  const updateCompanyDateMutation = trpc.company.updateIncorporationDate.useMutation()
  const updateMassiveCompanyDataMutation = trpc.company.updateMassiveCompanyData.useMutation()

  useEffect(() => {
    if (isAuthLoading) return
    isCompanyEditorQuery.refetch()
  }, [isAuthLoading, isCompanyEditorQuery])

  const isCompanyEditor = isCompanyEditorQuery.data ?? undefined
  const {
    pluralCompanyId,
    selectedBaseCompany,
    selectedEnrichedCompanyMetadata,
    selectedEnrichedCompanyData,
    companyId,
  } = useCompanySelectorContext()
  const isAllowedUser = authStatus === AuthStatus.Allowed

  const [isEditingCompanyName, setIsEditingCompanyName] = useState(false)
  const [editedCompanyName, setEditedCompanyName] = useState<string | null>(
    selectedEnrichedCompanyData.value?.companyName ?? null,
  )
  useEffect(() => {
    setEditedCompanyName(selectedEnrichedCompanyData?.value?.companyName ?? null)
  }, [selectedEnrichedCompanyData?.value?.companyName])
  const inputRef = useRef<HTMLInputElement>(null)
  useEffect(() => {
    if (isEditingCompanyName && inputRef.current) {
      inputRef.current.focus()
    }
  }, [isEditingCompanyName])

  const [editedLegalName, setEditedLegalName] = useState<string | null>(
    selectedEnrichedCompanyData.value?.legalName ?? null,
  )

  useEffect(() => {
    setEditedLegalName(selectedEnrichedCompanyData?.value?.legalName ?? null)
  }, [selectedEnrichedCompanyData?.value?.legalName])

  const [editedJurisdiction, setEditedJurisdiction] = useState<string | null>(
    selectedBaseCompany.value?.jurisdictionOfIncorporation ?? null,
  )

  const [editedHeadquarters, setEditedHeadquarters] = useState<string | null>(
    selectedEnrichedCompanyData.value?.location ?? null,
  )
  useEffect(() => {
    setEditedHeadquarters(selectedEnrichedCompanyData?.value?.location ?? null)
  }, [selectedEnrichedCompanyData?.value?.location])

  const [editedDate, setEditedDate] = useState<Date | null>(
    selectedEnrichedCompanyData.value?.foundingDate ? new Date(selectedEnrichedCompanyData?.value.foundingDate) : null,
    // TODO: founding date is often just the year, or the year and month, so we need to handle that
  )

  useEffect(() => {
    setEditedDate(
      selectedEnrichedCompanyData.value?.foundingDate
        ? new Date(selectedEnrichedCompanyData?.value.foundingDate)
        : null,
    )
  }, [selectedEnrichedCompanyData.value?.foundingDate])

  useEffect(() => {
    setEditedLegalName(selectedEnrichedCompanyData.value?.legalName ?? null)
  }, [selectedEnrichedCompanyData.value?.legalName])
  useEffect(() => {
    setEditedJurisdiction(selectedBaseCompany.value?.jurisdictionOfIncorporation ?? null)
  }, [selectedBaseCompany.value?.jurisdictionOfIncorporation])

  // useActionButtons([
  //   {
  //     icon: <PlusIcon className="w-[15px]" />,
  //     text: 'I want to buy or sell',
  //   },
  //   {
  //     icon: <PlusIcon className="w-[15px]" />,
  //     text: 'Report Trade',
  //   },
  //   {
  //     icon: <Bookmark size={15} />,
  //     text: 'Watchlist',
  //   },
  // ])
  useClosedSideNav()
  let addToListMutation = trpc.sheetRouter.addCompaniesToSheet.useMutation()

  let companySheetsToAdd: any[] =
    authUser?.userToSheets
      .filter((utr: any) => utr.sheet.entityType === 'COMPANY' && (utr.role === 'OWNER' || utr.role === 'EDITOR'))
      .map((utr: any) => utr.sheet) ?? []
  let queryClient = useQueryClient()
  return (
    <div className="ml-16 mr-16 flex max-w-page-supermax flex-col items-center">
      {isCompanyEditorQuery.isLoading || isAuthLoading || isLoadingCompanies || isNone(isCompanyEditor) ? (
        <PageLoader />
      ) : !isAllowedUser ? (
        <div className="opacity-60">You are not logged in as an allowed user.</div>
      ) : (
        <div className="ml-6 mr-6 flex w-full flex-col items-center">
          <div className="w-full ">
            {isSome(selectedEnrichedCompanyData.value) && (
              <div className="w-full ">
                <div className="mt-8 flex max-w-page-skinny items-center justify-between">
                  <div className="flex items-center">
                    {' '}
                    {/* Flex container for logo and company name */}
                    <img
                      src={selectedEnrichedCompanyData.value?.companyS3LogoUrl || undefined} // Assuming logo URL is stored here
                      alt={`${selectedEnrichedCompanyData.value.companyName} Logo`}
                      className="mr-4 h-24 w-24" // Adjust size and margins as needed
                    />
                    <div>
                      <div className="text-sm opacity-60">Company Editor</div>
                      <div
                        className={`mt-[2px] text-3xl font-medium ${isCompanyEditor ? 'cursor-pointer' : 'pointer-events-none'}`}
                        onClick={() => setIsEditingCompanyName(true)}>
                        {!isEditingCompanyName ? (
                          `${selectedEnrichedCompanyData.value.companyName}`
                        ) : (
                          <input
                            ref={inputRef}
                            onChange={(e) => setEditedCompanyName(e.target.value)}
                            value={editedCompanyName ?? ''}
                            onBlur={async () => {
                              if (
                                authUser?.email &&
                                editedCompanyName &&
                                selectedEnrichedCompanyData.value!.companyId
                              ) {
                                const companyMetadata = await updateMassiveCompanyDataMutation.mutateAsync({
                                  companyId: selectedEnrichedCompanyData.value!.companyId,
                                  companyName: editedCompanyName,
                                  adminEmail: authUser?.email,
                                  pluralCompanyId: pluralCompanyId.value,
                                })
                                refetch()
                              }
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <SubscribeToCompany />
                  {/* This will stay on the right */}

                  <DropdownSearch
                    options={companySheetsToAdd.map((sheet: any) => {
                      return {
                        label: sheet.name,
                        value: sheet.id.toString(),
                        startNode: <ScrollText size={15} key={sheet.id} />,
                      }
                    })}
                    onSelectOption={async (option, unfocus) => {
                      await addToListMutation.mutateAsync({
                        sheetId: parseInt(option.value),
                        companyValues: [selectedEnrichedCompanyData.value?.companyName ?? ''],
                      })

                      let sheetQueryKey = getQueryKey(trpc.sheetRouter.getSheet, parseInt(option.value), 'query')

                      queryClient.invalidateQueries(sheetQueryKey)
                      unfocus()
                    }}
                    trigger={
                      <BasicButton variant={'gray'} attioStyle className="flex items-center gap-1" onClick={() => {}}>
                        <CardStackPlusIcon />
                        Add to List
                      </BasicButton>
                    }
                  />
                </div>
                <div>
                  {pluralCompanyId.value != null && (
                    <div className="mt-6">
                      <ProjectionResult />
                    </div>
                  )}
                  {pluralCompanyId.value != null && (
                    <div className="mt-6 max-w-page-skinny">
                      <MarksChart />
                    </div>
                  )}
                  <div>
                    <div className="mt-2 text-xs opacity-60">
                      ingredients used / req'd
                      <li>1 financing event with issue price, date, and valuation</li>
                      <li>1 of each of the projection metrics (dilution, operating income, expense optional)</li>
                      <li>misc metrics for historical revenue and (optionally) cash</li>
                      <li>note: expense is subtracted on top of operating income if both exist </li>
                    </div>
                  </div>
                  <div className={`w-full ${isCompanyEditor ? '' : 'pointer-events-none'}`}>
                    <div className="mt-12 flex flex-col gap-2">
                      {selectedEnrichedCompanyData.value?.cbCompanyDescription != null && (
                        <div className="pb-2 font-medium">
                          <div className="font-medium">Description</div>
                          <span className="text-sm opacity-60">
                            {selectedEnrichedCompanyData.value?.cbCompanyDescription}
                          </span>
                        </div>
                      )}

                      <div className="font-medium">Name</div>
                      <div
                        className="flex flex-col gap-2"
                        style={{
                          minWidth: '175px',
                          maxWidth: '250px',
                        }}>
                        <AppTextField
                          label="Legal Name"
                          placeholder={`Legal Name`}
                          value={editedLegalName ?? ''}
                          onChange={(e) => {
                            setEditedLegalName(e.target.value)
                          }}
                          onBlur={async () => {
                            if (authUser?.email && editedLegalName && selectedEnrichedCompanyData.value?.companyId) {
                              const companyMetadata = await updateMassiveCompanyDataMutation.mutateAsync({
                                companyId: selectedEnrichedCompanyData.value!.companyId,
                                legalName: editedLegalName,
                                adminEmail: authUser?.email,
                                pluralCompanyId: pluralCompanyId.value,
                              })
                              refetch()
                            }
                          }}
                        />
                        {selectedBaseCompany.value != null && (
                          <AppTextField
                            label="Jurisdiction of Incorporaion"
                            placeholder={`Jurisdiction of Incorporaion`}
                            value={editedJurisdiction ?? ''}
                            onChange={(e) => {
                              setEditedJurisdiction(e.target.value)
                            }}
                            onBlur={() => {
                              if (selectedBaseCompany.value?.id && editedJurisdiction) {
                                updateJurisdictionMutation.mutateAsync({
                                  companyId: selectedBaseCompany.value!.id,
                                  jurisdictionOfIncorporation: editedJurisdiction ?? '',
                                })
                              }
                            }}
                          />
                        )}
                        <AppTextField
                          label="Headquarters"
                          placeholder={`Headquarters`}
                          value={editedHeadquarters ?? ''}
                          onChange={(e) => {
                            setEditedHeadquarters(e.target.value)
                          }}
                          onBlur={async () => {
                            if (authUser?.email && editedHeadquarters && selectedEnrichedCompanyData.value?.companyId) {
                              const companyMetadata = await updateMassiveCompanyDataMutation.mutateAsync({
                                companyId: selectedEnrichedCompanyData.value!.companyId,
                                headquarters: editedHeadquarters,
                                adminEmail: authUser?.email,
                                pluralCompanyId: pluralCompanyId.value,
                              })
                              refetch()
                            }
                          }}
                        />
                        <div className="flex flex-col gap-2">
                          <InputLabel label="Incorporation Date" />
                          <DatePicker
                            date={editedDate ?? undefined}
                            setDate={(newDate) => {
                              setEditedDate(newDate ?? null)
                              if (authUser?.email && editedDate && selectedEnrichedCompanyData.value?.companyId) {
                                const formattedDate = editedDate.toLocaleDateString('en-US', {
                                  year: 'numeric',
                                  month: 'short',
                                  day: '2-digit',
                                })
                                updateMassiveCompanyDataMutation.mutateAsync({
                                  companyId: selectedEnrichedCompanyData.value!.companyId,
                                  foundingDate: formattedDate,
                                  adminEmail: authUser?.email,
                                  pluralCompanyId: pluralCompanyId.value,
                                })
                                refetch()
                              }
                            }}
                          />
                        </div>
                      </div>
                      {selectedBaseCompany.value != null && (
                        <div className=" flex flex-col gap-[60px]  md:flex-row">
                          <div className="flex flex-col gap-2">
                            <InputLabel label="Legal Name Aliases" />
                            <AliasTable aliasType="LEGAL_NAME" />
                          </div>
                          <div className="flex flex-col gap-2">
                            <InputLabel label="DBA Name Aliases" />
                            <AliasTable aliasType="DBA_NAME" />
                          </div>
                        </div>
                      )}
                    </div>

                    <div className="mt-12">
                      <CompanyHeadcountAndOpenRoleData />
                    </div>

                    <div className="mt-12">
                      <CompanyFundingRoundsTable
                        companyName={selectedEnrichedCompanyData.value?.companyName ?? ''}
                        tableNameNode={<div className="flex items-center font-medium">Funding Rounds</div>}
                        companyId={companyId?.value ?? ''}
                      />
                    </div>
                    {pluralCompanyId.value != null && (
                      <>
                        <div className="mt-12">
                          <div className="mb-4 font-medium">Revenue</div>
                          <CompanyRevenueTable />
                        </div>
                        <div className="mt-12">
                          <div className="mb-4 font-medium">Share Price Projection Data</div>
                          <ProjectedCompanyMetricsTable />
                        </div>
                        <div className="mt-12">
                          <div className="mb-4 font-medium">Misc Metrics</div>
                          <AttributedCompanyMetricsTable />
                        </div>
                        <div className="mt-12">
                          <div className="mb-4 font-medium">Financing Events</div>
                          <CompanyFinancingDataTable />
                        </div>
                        <div className="mt-12">
                          <CompanyMarks />
                        </div>
                        <div className="mt-12">
                          <div className="mb-4 font-medium">Value Share Profile</div>
                          <V2CalculatorCompanyProfileEditor />
                        </div>
                        <div className="mt-12">
                          <div className="mb-4 font-medium">Debt Events</div>
                          <CompanyDebtEventsTable />
                        </div>
                        <div className="mt-12">
                          <div className="mb-4 font-medium">Comparable Companies</div>
                          <ComparableCompaniesEditor />
                        </div>
                        <div className="mt-12">
                          <div className="mb-4 font-medium">People</div>
                          <PeopleForCompanyTable />
                        </div>
                        <div className="mt-12">
                          <div className="mb-4 font-medium">Subscribers</div>
                          <CompanySubscribers />
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            )}
            <div className="footer h-36" />
          </div>
        </div>
      )}
    </div>
  )
}

type AliasType = 'LEGAL_NAME' | 'DBA_NAME'

type RouterAlias = ArrayElement<RouterOutputs['company']['getCompanyAliases']>

function AliasTable(props: { aliasType: AliasType }) {
  const { aliasType } = props
  const { selectedBaseCompany } = useCompanySelectorContext()

  const companyAliasesQuery = trpc.company.getCompanyAliases.useQuery(
    { companyId: selectedBaseCompany.value!.id },
    { enabled: isSome(selectedBaseCompany.value) },
  )

  const companyAliases = (companyAliasesQuery.data ?? []).filter((alias: RouterAlias) => alias.aliasType === aliasType)

  const deleteAliasMutation = trpc.company.deleteCompanyAlias.useMutation()
  const addAliasMutation = trpc.company.addCompanyAlias.useMutation()
  const [newAlias, setNewAlias] = useState('')

  return (
    <div className="flex flex-col gap-3">
      {companyAliases.map((alias) => {
        return (
          <div key={alias.id} className="flex flex-row items-center gap-2 text-sm">
            - {alias.alias}{' '}
            <CrossCircledIcon
              color="gray"
              style={{ color: 'gray', cursor: 'pointer' }}
              onClick={async () => {
                await deleteAliasMutation.mutateAsync({ aliasId: alias.id })
                companyAliasesQuery.refetch()
              }}
            />
          </div>
        )
      })}
      {companyAliases.length === 0 ? (
        <div className="ml-2 text-sm">No {aliasType === 'LEGAL_NAME' ? 'legal' : 'DBA'} name aliases</div>
      ) : undefined}
      <div className="flex flex-row items-center gap-2">
        <TextField.Input
          style={{
            minWidth: '175px',
          }}
          placeholder={`Add new ${aliasType === 'LEGAL_NAME' ? 'legal' : 'DBA'} name alias`}
          value={newAlias}
          onChange={(e) => {
            setNewAlias(e.target.value)
          }}
        />
        <Button
          color="gray"
          variant="outline"
          onClick={async () => {
            if (!selectedBaseCompany.value || newAlias) return
            await addAliasMutation.mutateAsync({
              alias: newAlias,
              aliasType,
              companyId: selectedBaseCompany.value!.id, // UPDATE to massive api
            })
            setNewAlias('')
            companyAliasesQuery.refetch()
          }}>
          Add
        </Button>
      </div>
    </div>
  )
}
