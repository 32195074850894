import { FloatingPortal, size, useFloating } from '@floating-ui/react'
import React, { useEffect, useState } from 'react'
import { isSome, Optional } from 'safety'
import { defaultTableCellPadding } from './EditableTable'
import { EditableTextCellFormat } from './EditableTextCellFormat'

function EditableTextCell<V>({
  cellFormat,
  onEditsFinished,
  initialValue,
  placeholder,
  shouldFocus,
  isCreating = false,
  bgColor,
  noPadding = false,
}: {
  cellFormat: EditableTextCellFormat<V>
  onEditsFinished: (newValue: Optional<V>) => void
  initialValue?: V
  placeholder?: string
  shouldFocus?: boolean
  isCreating: boolean
  bgColor?: string
  noPadding?: boolean
}) {
  const [isEditing, setIsEditing] = useState(false)
  const [editModeString, setEditModeString] = useState<string>(
    isSome(initialValue) ? cellFormat.editModeString(initialValue) : '',
  )
  const [viewModeString, setViewModeString] = useState<string>(
    isSome(initialValue) ? cellFormat.viewModeString(initialValue) : '',
  )
  const { refs, floatingStyles } = useFloating({
    placement: 'bottom',
    middleware: [
      size({
        apply({ rects, elements }) {
          Object.assign(elements.floating.style, {
            width: `${rects.reference.width}px`,
          })
        },
      }),
    ],
  })

  useEffect(() => {
    if (isSome(initialValue)) {
      setViewModeString(cellFormat.viewModeString(initialValue))
      setEditModeString(cellFormat.editModeString(initialValue))
    }
  }, [initialValue, cellFormat])

  function _onEditsFinished() {
    let newValue = cellFormat.valueFromEditModeString(editModeString, initialValue)
    if (newValue !== initialValue) {
      onEditsFinished(newValue)
      setViewModeString(newValue ? cellFormat.viewModeString(newValue) : '')
    }
  }

  useEffect(() => {
    if (shouldFocus) {
      let ref = refs.reference?.current as HTMLInputElement
      if (ref) {
        ref.focus()
      }
    }
  }, [shouldFocus, refs])

  function handleKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key === 'Enter') {
      let ref = refs.reference.current as HTMLInputElement
      if (ref) {
        ref.blur()
      }
    }
  }

  let valueFromEditModeString = cellFormat.valueFromEditModeString(editModeString, initialValue)

  return (
    <div className={`${noPadding ? '' : ` w-full`}  ${bgColor}`}>
      <input
        className={`w-full ${bgColor} border-none  ${noPadding ? '' : `${defaultTableCellPadding} pb-1 pr-2 pt-1`}   outline-none ${!isEditing ? 'cursor-pointer' : ''}`}
        placeholder={placeholder ? placeholder : ''}
        value={isEditing ? editModeString : viewModeString}
        ref={refs.setReference}
        onKeyDown={handleKeyDown}
        onChange={(e) => {
          let newValue = cellFormat.onInputChange(e.target.value)
          if (isSome(newValue)) {
            setEditModeString(newValue)
          }
        }}
        onFocus={() => {
          setIsEditing(true)
        }}
        onBlur={() => {
          setIsEditing(false)
          _onEditsFinished()
        }}
      />
      {isEditing &&
        isSome(cellFormat.floatingModeString) &&
        isSome(valueFromEditModeString) &&
        editModeString.length > 0 && (
          <FloatingPortal>
            <div
              ref={refs.setFloating}
              style={floatingStyles}
              className="z-50 mt-[6px] bg-slate-100 p-1 text-sm shadow-sm">
              <div className="opacity-60">{cellFormat.floatingModeString(valueFromEditModeString)}</div>
            </div>
          </FloatingPortal>
        )}
    </div>
  )
}

export default React.memo(EditableTextCell) as typeof EditableTextCell
