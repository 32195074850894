import { TextField } from '@radix-ui/themes'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { BasicButton } from '../components/BasicButton'
import { usePluralAuth } from '../providers/PluralAuthProvider'
import { AuthStatus } from '../utils/authStatus'
import { trpc } from '../utils/trpc'

export default function Onboarding() {
  const { user, authStatus } = usePluralAuth()
  const location = useLocation()
  const navigate = useNavigate()
  const nameMutation = trpc.user.updateName.useMutation({
    onSuccess: () => {
      navigate(returnTo)
    },
  })

  const [hasPrefilledName, setHasPrefilledName] = useState(false)

  const fullNameMutation = trpc.user.fullNameForAllowedUser.useMutation({
    onSuccess: (data) => {
      if (hasPrefilledName) {
        return
      }
      if (data?.firstName && firstName.length === 0) {
        setFirstName(data.firstName)
      }
      if (data?.lastName && lastName.length === 0) {
        setLastName(data.lastName)
      }
      setHasPrefilledName(true)
    },
  })

  useEffect(() => {
    fullNameMutation.mutate({
      userEmail: user!.email,
    })
  }, [user]) // eslint-disable-line react-hooks/exhaustive-deps

  const returnTo = location.state?.returnTo || '/'
  const onComplete = () => {
    nameMutation.mutate({
      firstName: firstName,
      lastName: lastName,
    })
  }
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')

  useEffect(() => {
    if (authStatus !== AuthStatus.Allowed) {
      navigate(returnTo)
    }
    if (user?.firstName && user?.lastName) {
      navigate(returnTo)
    }
  }, [user, navigate, returnTo, authStatus])

  const canPressComplete = firstName.length > 0 && lastName.length > 0

  return (
    <div className="ml-6 mr-6 flex flex-col items-center">
      <div className="w-full max-w-page-skinny text-base">
        <div className="mt-16 text-3xl font-semibold">LP Sign Up</div>
        <div className="h-12" />
        <div>
          <div>Email</div>
          <div className="opacity-70">{user?.email}</div>
        </div>
        <div className="mt-4 w-full">
          <div>Name</div>
          <div className="mt-2 flex w-full flex-row space-x-2">
            <div className="flex-1">
              <TextField.Input
                placeholder="First Name"
                size={'3'}
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </div>
            <div className="flex-1">
              <TextField.Input
                placeholder="Last Name"
                size={'3'}
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="h-9" />
        <BasicButton
          onClick={onComplete}
          className="w-full"
          isPressable={canPressComplete}
          isLoading={nameMutation.isLoading}>
          Sign Up
        </BasicButton>
      </div>
    </div>
  )
}
