import { fundMultiple, lookbackV2TxMultiple } from "plural-shared/lookbackV2";
import { calculateCAGR, numberToPercentString } from "plural-shared/utils";
import { ValueShareFundSettings } from "./valueShareFundSettings";

export class FundSimulationOutcome {
  private assetMultiples: number[];
  private fundDealMultiples: number[];
  yearsToOutcome: number;
  fundLifetime: number;
  managerFee: number;
  grossFundInterest: number;
  performanceFee: number = 0.2;
  reserveRatio: number;

  constructor(assetMultiples: number[], fundSettings: ValueShareFundSettings) {
    this.assetMultiples = assetMultiples;
    let fundDealMultiples = [];
    let { yearsToLiquidity, reserveRatio, fundMinCagr, grossFundInterest } =
      fundSettings;
    for (let assetMultiple of assetMultiples) {
      const assetCagrFromBase = calculateCAGR({
        returnMultiple: assetMultiple,
        years: yearsToLiquidity,
      });
      const txMult = lookbackV2TxMultiple(
        yearsToLiquidity,
        assetCagrFromBase,
        fundMinCagr,
        reserveRatio
      );
      const fundMult = fundMultiple(txMult, assetMultiple);
      fundDealMultiples.push(fundMult);
    }
    this.fundDealMultiples = fundDealMultiples;
    this.yearsToOutcome = yearsToLiquidity;
    this.reserveRatio = reserveRatio;
    this.grossFundInterest = grossFundInterest;
    this.fundLifetime = fundSettings.fundLifetime;
    this.managerFee = fundSettings.managerFee;
  }

  get numCompanies() {
    return this.assetMultiples.length;
  }

  fundGrossMultiple() {
    let totalMultiple = this.fundDealMultiples.reduce(
      (acc, multiple) => acc + multiple,
      0
    );
    return totalMultiple / this.numCompanies;
  }

  fundNetMultiple() {
    return this.netEndPortfolioValue() / this.grossDollarsIn();
  }

  totalManagerFeePercent() {
    return this.managerFee * this.fundLifetime;
  }

  grossDollarsIn() {
    return this.grossFundInterest * this.numCompanies;
  }

  investedCapital() {
    return this.grossDollarsIn() * (1 - this.totalManagerFeePercent());
  }

  totalManagerFeeDollars() {
    return this.grossDollarsIn() * this.totalManagerFeePercent();
  }

  endPortfolioValue() {
    return this.investedCapital() * this.fundGrossMultiple();
  }

  // todo, make sure this is supposed to be based on total dollars in not invested capial
  totalPerformanceFeeDollars() {
    let feeBasis = this.endPortfolioValue() - this.grossDollarsIn();
    return feeBasis * this.performanceFee;
  }

  netEndPortfolioValue() {
    return this.endPortfolioValue() - this.totalPerformanceFeeDollars();
  }

  fundGrossIRR() {
    return calculateCAGR({
      returnMultiple: this.fundGrossMultiple(),
      years: this.yearsToOutcome,
    });
  }

  fundNetIRR() {
    return calculateCAGR({
      returnMultiple: this.fundNetMultiple(),
      years: this.yearsToOutcome,
    });
  }

  averageAssetMultiple() {
    return (
      this.assetMultiples.reduce((acc, mult) => acc + mult, 0) /
      this.numCompanies
    );
  }

  medianAssetMultiple() {
    let sortedMultiples = this.assetMultiples.sort((a, b) => a - b);
    let middleIndex = Math.floor(sortedMultiples.length / 2);
    return sortedMultiples[middleIndex];
  }

  assumptions() {
    return {
      yearsActive: this.yearsToOutcome,
      managementFee: this.managerFee,
      performanceFee: this.performanceFee,
      reserveRatio: this.reserveRatio,
    };
  }

  picture() {
    return {
      fundGrossIRR: numberToPercentString(this.fundGrossIRR()),
      fundNetIRR: numberToPercentString(this.fundNetIRR()),
      averageCompanyMultiple: this.averageAssetMultiple(),
      medianCompanyMultiple: this.medianAssetMultiple(),
    };
  }

  printPicture() {
    console.log(JSON.stringify(this.picture(), null, 4));
  }
}
