import { ValueShareCashAdjustedTxPriceLineDatapoint } from 'plural-shared/lookbackV2'
import { numberToAbbreviatedDollarString, numberToReadableDollarString } from 'plural-shared/utils'
import {
  Label,
  Line,
  LineChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from 'recharts'
import { isNone, isSome } from 'safety'
import { useSimulatorContext } from './CashSimulatorContext'

export interface ValuationGraphData {
  line: ValueShareCashAdjustedTxPriceLineDatapoint[]
  activeSliderDatapoint: ValueShareCashAdjustedTxPriceLineDatapoint
}

export default function ValuationGraph() {
  const context = useSimulatorContext()
  const graphData = context?.txPriceValuationGraphData
  let line = graphData?.line
  if (isNone(context) || isNone(graphData) || isNone(line)) {
    return null
  }
  const numTicks = 4
  const xTickInterval = Math.floor(line.length / numTicks)
  const xTickValues = line
    .filter((_, index) => index % xTickInterval === 0)
    .map((datapoint, index) => (index === 0 ? '' : datapoint.companyOutcomeValuation))
  const yMin = Math.min(...line.map((datapoint) => datapoint.cashAdjustedSharePrice))
  const yMax = Math.max(...line.map((datapoint) => datapoint.cashAdjustedSharePrice))
  const yTickValues = Array.from({ length: numTicks }, (_, i) => yMin + (i * (yMax - yMin)) / (numTicks - 1))
  const secondarySharePrice = context.txInputs.secondarySharePrice.value
  const secondaryValuation = context.selectedCompanyProps.baseValuation
  const yearsToLiquidity = context.txInputs.yearsToOutcome.value
  const brown = '#59564F'
  const minDomain = Math.min(...line.map((datapoint) => datapoint.cashAdjustedSharePrice)) * 0.9
  const maxDomain = Math.max(...line.map((datapoint) => datapoint.cashAdjustedSharePrice)) * 1.05

  return (
    <div className="w-full">
      <ResponsiveContainer width="100%" height={300}>
        <LineChart
          margin={{
            top: 16,
            right: 24,
            left: 0,
            bottom: 4,
          }}
          data={line}>
          <XAxis
            dataKey="companyOutcomeValuation"
            ticks={xTickValues}
            tickLine={false}
            tickFormatter={(value) => numberToAbbreviatedDollarString(value)}
            className="text-xs"
            height={20}></XAxis>
          <YAxis
            className="text-xs"
            tickLine={false}
            domain={[minDomain, maxDomain]}
            width={42}
            ticks={yTickValues}
            tickFormatter={(value) => (value === 0 ? '' : `${numberToReadableDollarString(value, false)}`)}
          />
          <Tooltip
            isAnimationActive={false}
            content={(tooltipProps: TooltipProps<any, any>) => {
              if (!tooltipProps.active || !tooltipProps.payload || !tooltipProps.label) {
                return null
              }
              let data: ValueShareCashAdjustedTxPriceLineDatapoint = tooltipProps.payload?.[0].payload
              return (
                <div
                  className="custom-tooltip flex flex-col items-start rounded-md pb-4 pl-3 pr-5 pt-3 text-white"
                  style={{ backgroundColor: '#1C2227' }}>
                  <div>
                    <div className="text-lg font-semibold">
                      {numberToReadableDollarString(data.cashAdjustedSharePrice, true)}
                    </div>
                    <div className="-mt-1 text-sm opacity-70">
                      Plural PPS <span className="text-xs">(Cash-adjusted)</span>
                    </div>
                  </div>
                  <div className="mt-2">
                    <div className="text-lg font-semibold">
                      {numberToAbbreviatedDollarString(data.companyOutcomeValuation)}
                    </div>
                    <div className="-mt-1 text-sm opacity-70">
                      Company valuation
                      <span className="text-xs"> (in {yearsToLiquidity} yrs)</span>
                    </div>
                  </div>
                </div>
              )
            }}
          />
          <Line
            dataKey="cashAdjustedSharePrice"
            stroke={brown}
            type={'monotone'}
            dot={({ cx, cy, payload, index }) => {
              if (index === context.closestDefaultMultiplesSelectedIndex) {
                return (
                  <g>
                    <circle cx={cx} cy={cy} r={7} fill={brown} key={cx + ', ' + cy} />
                    <text x={cx} y={cy} dy={-12} textAnchor="middle" fill={brown} className="text-xs">
                      Plural Tx
                    </text>
                  </g>
                )
              } else {
                return <circle cx={cx} cy={cy} r={0} key={cx + ', ' + cy} />
              }
            }}
            isAnimationActive={false}
          />
          <ReferenceLine y={secondarySharePrice} stroke="hsl(0, 0%, 70%)">
            <Label
              value={
                isSome(secondarySharePrice) && isSome(secondaryValuation)
                  ? `${numberToReadableDollarString(
                      secondarySharePrice,
                    )} Secondary PPS (${numberToAbbreviatedDollarString(secondaryValuation, 0, 1)} implied val.)`
                  : ''
              }
              position={'insideBottomRight'}
              className="text-xs"
            />
          </ReferenceLine>
        </LineChart>
      </ResponsiveContainer>
    </div>
  )
}
