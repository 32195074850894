import moment from "moment";

export function dateForYearsInFuture({
  yearsInFuture,
  startDate,
}: {
  yearsInFuture: number;
  startDate: Date;
}) {
  return moment(startDate).add(yearsInFuture, "years").toDate();
}
