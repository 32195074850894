import { ColumnDef, createColumnHelper, FilterFnOption, SortingFnOption } from '@tanstack/react-table'
import { columnType, renderEditableCell } from 'components/editable-tanstack-table/EditableTanstackTable'
import { format } from 'date-fns'
import { PluralLink } from 'providers/NavigationProvider'
import { usePluralAuth } from 'providers/PluralAuthProvider'
import { useMemo } from 'react'
import { ArrayElement } from 'safety'
import { RouterOutputs } from 'utils/trpc'
import { hackyEditorIds } from './FundingRound'

export type Fund = ArrayElement<RouterOutputs['fundFileRouter']['getFundsByUser']>

export function titleCase(str: string | undefined): string {
  if (!str) return ''
  return str
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}

export function useFundsColumns(inputs: { cellClassName?: string; bgColor?: string }) {
  let { user } = usePluralAuth()

  let columnHelper = createColumnHelper<Fund>()

  let isPersonEditor = hackyEditorIds.indexOf(user?.id ?? 0) !== -1
  let cellClassName = inputs.cellClassName ?? `max-w-full truncate p-2 ${isPersonEditor ? 'cursor-not-allowed' : ''}`
  let isCellParent = (cell: any) => (cell.row.original.subRows?.length ?? 0) > 0 || !isPersonEditor

  let columnsMemo = useMemo(() => {
    let defaultCellFn = (cell: any) => {
      const initialValue = cell.getValue()
      let _column = cell.column.columnDef.meta!
      return renderEditableCell(
        initialValue,
        _column as unknown as columnType<Fund, any>,
        cell.row.original,
        (row) => row.id,
        inputs.bgColor,
      )
    }

    let columns: ColumnDef<Fund, any>[] = [
      columnHelper.accessor('name', {
        header: 'Name',
        size: 300,
        id: 'name',
        cell: (cell) => {
          return (
            <div className={`flex max-w-full items-center gap-3 truncate p-2`}>
              <PluralLink href={`/fund-snapshots/${cell.row.original.id}`}>{cell.getValue() as string}</PluralLink>
            </div>
          )
        },
        sortingFn: 'stringFn' as SortingFnOption<Fund>,
        filterFn: 'stringFn' as FilterFnOption<Fund>,
        meta: {
          className: 'z-30 sticky left-0 bg-white',
          headerClassName: 'z-40 sticky left-0 bg-white',
          isImmovable: true,
          isUnhideable: true,
          noPadding: true,
        },
      }),
      columnHelper.accessor('markDate', {
        header: 'Mark Date',
        size: 150,
        id: 'markDate',
        cell: (cell) => {
          if (!isCellParent(cell)) {
            return defaultCellFn(cell)
          }
          return <div className={cellClassName}>{format(new Date(cell.getValue()), 'MMM dd yyyy')}</div>
        },
        sortingFn: 'dateFn' as SortingFnOption<Fund>,
        filterFn: 'dateFn' as FilterFnOption<Fund>,
      }),
      columnHelper.accessor('companiesCount', {
        header: 'Number of Investments',
        size: 150,
        id: 'companiesCount',
        cell: (cell) => {
          if (!isCellParent(cell)) {
            return defaultCellFn(cell)
          }
          return <div className={cellClassName}>{cell.getValue()}</div>
        },
        sortingFn: 'numberFn' as SortingFnOption<Fund>,
        filterFn: 'numberFn' as FilterFnOption<Fund>,
      }),
      columnHelper.accessor((row) => `${row.createdByUser?.firstName} ${row.createdByUser?.lastName}`, {
        header: 'Created By',
        id: '8',
        sortingFn: 'stringFn' as SortingFnOption<Fund>,
        filterFn: 'stringFn' as FilterFnOption<Fund>,
        cell: (cell) => {
          return (
            <div className="ml-2">
              {cell.row.original.createdByUser?.firstName} {cell.row.original.createdByUser?.lastName}
            </div>
          )
        },
      }) as ColumnDef<Fund>,
    ]

    return columns
  }, [cellClassName, columnHelper, user?.email])
  return columnsMemo
}
