import { size, useFloating } from '@floating-ui/react'
import { TextField } from '@radix-ui/themes'
import { ReactNode, useEffect, useRef, useState } from 'react'
import { isSome } from 'safety'

export type TextInputSelectOption = {
  label: string
  index: number
  additionalSearchString?: string
  id?: number | string
  startNode?: ReactNode
  endNode?: ReactNode
}

// type createProps = {

export default function TextInputSelect({
  options,
  onOptionSelect,
  placeholder,
  initialOption,
  createModal,
  clearInputIncrementor,
  noOutline,
  endAdornment,
  placement,
}: {
  options: TextInputSelectOption[]
  onOptionSelect: (option: TextInputSelectOption) => void
  placeholder: string
  initialOption?: TextInputSelectOption
  createModal?: React.ReactNode
  clearInputIncrementor?: number
  noOutline?: boolean
  endAdornment?: React.ReactNode
  placement?: 'top' | 'bottom'
}) {
  const { refs, floatingStyles } = useFloating({
    placement: placement ?? 'bottom',
    middleware: [
      size({
        apply({ rects, elements }) {
          Object.assign(elements.floating.style, {
            width: `${rects.reference.width}px`,
          })
        },
      }),
    ],
  })

  const [isTextFieldFocused, setIsTextFieldFocused] = useState(false)
  const [isListHovered, setIsListHovered] = useState(false)
  const [selectedOption, setSelectedOption] = useState<TextInputSelectOption | null>(
    initialOption ? initialOption : null,
  )
  useEffect(() => {
    setSelectedOption(initialOption ? initialOption : null)
  }, [initialOption])
  const [blurCount, setBlurCount] = useState(0)

  const [inputText, setInputText] = useState(initialOption?.label ?? '')
  const [filteredOptionsHighlightIndex, setFilteredOptionsHighlightIndex] = useState(0)
  const inputRef = useRef<HTMLInputElement>(null)

  const filteredOptions = options.filter((option) => {
    let text = inputText.toLowerCase()
    let label = option.label.toLowerCase()
    let additionalSearchString = option.additionalSearchString?.toLowerCase() ?? ''
    return label.includes(text) || additionalSearchString.includes(text)
  })

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        setIsTextFieldFocused(false)
      }
    }

    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [])

  function handleKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'ArrowDown') {
      event.preventDefault()
      setFilteredOptionsHighlightIndex((prev) => (prev + 1) % filteredOptions.length)
    }
    if (event.key === 'ArrowUp') {
      event.preventDefault()
      setFilteredOptionsHighlightIndex((prev) => (prev - 1 + filteredOptions.length) % filteredOptions.length)
    }
    if (event.key === 'Enter') {
      const inputText = filteredOptions[filteredOptionsHighlightIndex].label
      checkShouldSetSelection(inputText)
      setInputText(inputText)
      removeFocus()
    }
  }
  function removeFocus() {
    setIsTextFieldFocused(false)
    inputRef.current?.blur()
  }

  function checkShouldSetSelection(_inputText: string) {
    const index = options.map((o) => o.label.toLowerCase()).indexOf(_inputText.toLowerCase())
    if (index !== -1) {
      const option = options[index]
      setSelectedOption(option)
      onOptionSelect(option)
    }
  }

  useEffect(() => {
    if (inputText !== selectedOption?.label) {
      setInputText(selectedOption?.label ?? '')
    }
  }, [blurCount])

  useEffect(() => {
    if (filteredOptionsHighlightIndex !== -1) {
      scrollToItem(filteredOptionsHighlightIndex)
    }
  }, [filteredOptionsHighlightIndex])

  function scrollToItem(index: number) {
    const itemElement = document.querySelector(`#item-${index}`)
    itemElement?.scrollIntoView({
      behavior: 'smooth', // Optional: defines the transition animation
      block: 'nearest', // Optional: defines vertical alignment
      inline: 'start', // Optional: defines horizontal alignment
    })
  }

  return (
    <div className={`${noOutline ? 'no-border-outline-in' : ''} w-full`}>
      <TextField.Root
        ref={refs.setReference}
        onBlur={() => {
          if (!isListHovered) {
            setIsTextFieldFocused(false)
            setBlurCount(blurCount + 1)
          }
        }}
        onFocus={() => {
          setInputText('')
          setIsTextFieldFocused(true)
        }}>
        <TextField.Input
          ref={inputRef}
          placeholder={placeholder}
          value={inputText}
          onChange={(e) => {
            checkShouldSetSelection(e.target.value)
            setInputText(e.target.value)
          }}
          onKeyDown={handleKeyDown}
          size={'2'}
        />
        {endAdornment}
      </TextField.Root>
      {isTextFieldFocused && (
        <div
          ref={refs.setFloating}
          style={floatingStyles}
          className={`relative z-50 border bg-white text-sm`}
          onMouseEnter={() => setIsListHovered(true)}
          onMouseLeave={() => setIsListHovered(false)}>
          <div className="max-h-40 overflow-y-auto">
            {filteredOptions.map((option, index) => {
              const isSelected = filteredOptionsHighlightIndex === index
              return (
                <div
                  key={`item-${index}`}
                  id={`item-${index}`}
                  onClick={() => {
                    const _inputText = option.label
                    checkShouldSetSelection(_inputText)
                    setInputText(_inputText)
                    removeFocus()
                  }}
                  className={`cursor-pointer ${isSelected ? 'bg-gray-100' : 'bg-white'} flex flex-row items-center gap-2 pb-1 pl-2 pt-1 hover:bg-gray-100`}>
                  {option.startNode}
                  {option.label}
                  {option.endNode && <div className="ml-auto pr-2">{option.endNode}</div>}
                </div>
              )
            })}
          </div>
          {isSome(createModal) && (
            <div className="flex h-8 w-full flex-col items-start justify-center border-t bg-white pl-2">
              {createModal}
            </div>
          )}
        </div>
      )}
    </div>
  )
}
