import { useEffect, useState } from 'react'
import { BasicButton } from '../../components/BasicButton'
import SmallStat from '../../components/SmallStat'
import Disclaimer from '../portfolio/Disclaimer'
import {
  LPInterestFundSimulationResult,
  LPInterestSimulationResult,
  makeIndivLPInterestSimulation,
  makeLPInterestFundSimulation,
} from 'plural-shared/lookbackV2'
import { IrrDistributionGraphData } from 'plural-shared/lookbackV2/sensitivity/IrrDistributionGenerator'
import IRRDistributionGraph, { IRRDistributionGraphProps } from '../../components/IRRDistributionGraph'
import { numberToPercentString } from 'plural-shared/utils'

enum GraphType {
  GrossFundIrr = 'grossFundIrr',
  LPDealIrr = 'equityDealIrr',
  PluralDealIrr = 'pluralDealIrr',
}

export default function LPInterestSensitivity() {
  const [indivDealSimulationData, setIndivDealSimulationData] = useState<LPInterestSimulationResult>(
    makeIndivLPInterestSimulation(),
  )

  function simulateIndivDeal() {
    setIndivDealSimulationData(makeIndivLPInterestSimulation())
  }

  const [fundSimulationData, setFundSimulationData] =
    useState<LPInterestFundSimulationResult>(makeLPInterestFundSimulation())

  function simulateFund() {
    setFundSimulationData(makeLPInterestFundSimulation())
  }

  function graphForData(data: IrrDistributionGraphData | undefined, graphType: GraphType) {
    if (!data || !indivDealSimulationData) {
      return null
    }
    let props: IRRDistributionGraphProps = {
      subheading: '',
      title: '',
      height: 300,
    }
    switch (graphType) {
      case GraphType.GrossFundIrr:
        props.subheading = `Percent frequency of Fund IRRs across ${data.numOutcomesSimulated} simulated funds. Performance of holdings randomly generated along the same distribution as above`
        props.title = 'Plural Gross Fund Returns'
        props.height = 350
        break
      case GraphType.LPDealIrr:
        props.subheading = `Percent frequency of LP returns across ${data.numOutcomesSimulated} simulated late-stage venture funds`
        props.title = 'LP Returns'
        break
      case GraphType.PluralDealIrr:
        props.subheading = `Percent frequency of Plural returns on the same ${data.numOutcomesSimulated} LP interests`
        props.title = 'Plural Returns on Same LP Interests'
        break
    }
    return <IRRDistributionGraph data={data} props={props} />
  }

  return (
    <div className="mt-8 flex w-full flex-col items-center pb-36 lg:mb-12">
      <div className="mt-20 flex w-full flex-col items-center pl-4 pr-4 lg:max-w-5xl">
        <div className="w-full">
          <div className="text-4xl font-semibold">LP Interest Simulations</div>
          <div className="mt-2 text-sm opacity-50">
            Simulating Plural outcomes for differing performances in underlying venture fund LP interests.
          </div>
          <div className="mt-16">
            <div className="mt-8 flex items-center justify-between">
              <div className="pr-8 text-2xl font-medium">LP Interests</div>
              <div>
                <BasicButton onClick={() => simulateIndivDeal()} variant="secondary" textSize="text-base">
                  Simulate
                </BasicButton>
              </div>
            </div>
            <div className="mt-8 block space-y-8 lg:flex lg:space-x-12 lg:space-y-0">
              {graphForData(indivDealSimulationData?.equityDealIrrGraphData, GraphType.LPDealIrr)}
              {graphForData(indivDealSimulationData?.pluralDealIrrGraphData, GraphType.PluralDealIrr)}
            </div>
          </div>
          <div>
            <div className="mt-20 flex items-center justify-between">
              <div className="pr-8 text-2xl font-medium">Plural Funds of LP Interests</div>
              <div>
                <BasicButton onClick={() => simulateFund()} variant="secondary" textSize="text-base">
                  Simulate
                </BasicButton>
              </div>
            </div>
            <div className="mt-8">
              {graphForData(fundSimulationData?.grossFundIrrGraphData, GraphType.GrossFundIrr)}
            </div>
            <div className="flex flex-col space-x-0 space-y-4 pt-6 lg:flex-row lg:space-x-12 lg:space-y-0">
              <SmallStat
                label="Median Gross Fund IRR"
                value={numberToPercentString(fundSimulationData.grossFundIrrMetrics.median, 1)}
              />
              <SmallStat
                label="Lower Quartile Gross Fund IRR"
                value={numberToPercentString(fundSimulationData.grossFundIrrMetrics.lowerQuartile, 1)}
              />
              <SmallStat
                label="Upper Quartile Gross Fund IRR"
                value={numberToPercentString(fundSimulationData.grossFundIrrMetrics.upperQuartile, 1)}
              />
            </div>
          </div>
          <Disclaimer />
        </div>
      </div>
    </div>
  )
}
