import { useEffect } from 'react'
import LogoutButton from './LogoutButton'
import LoginButton from './LoginButton'
import { useAuth0 } from '@auth0/auth0-react'
import { usePluralAuth } from '../providers/PluralAuthProvider'

export default function AuthStatusPage() {
  const { isAuthenticated } = useAuth0()
  const { user, authStatus, isLoading, possiblyRejectedUserEmail } = usePluralAuth()

  return (
    <div className="flex flex-col items-center justify-center ml-6 mr-6">
      <div className="max-w-page-skinny w-full text-base mt-16">
        Auth
        <div className="mt-4">{isAuthenticated ? <LogoutButton /> : <LoginButton />}</div>
      </div>
    </div>
  )
}
