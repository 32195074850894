import { CompanyMetric } from 'plural-trpc/routers/companiesRouter'
import { usePluralAuth } from 'providers/PluralAuthProvider'
import { ArrayElement, isNone, isSome, Optional } from 'safety'
import { hackyEditorIds } from 'several/hooks/FundingRound'
import { AttributedMetricCellFormat } from '../../components/editable-table/AttributedMetricEditableCellType'
import { AnyColumnProps } from '../../components/editable-table/ColumnProps'
import { EditableTable } from '../../components/editable-table/EditableTable'
import { DateCellFormat } from '../../components/editable-table/EditableTextCellFormat'
import { RouterOutputs, trpc } from '../../utils/trpc'
import { useCompanySelectorContext } from './CompanySelectorContextProvider'
import { useCompanySelectorContext as useEnrichedCompanySelectorContext } from './massiveEnrichedCompany/MassiveEnrichedCompanySelectorContextProvider'

export type ProjectedCompanyMetric = ArrayElement<RouterOutputs['projectedCompanyMetrics']['metricsForCompany']>
type AttributedMetricType = ArrayElement<RouterOutputs['projectedCompanyMetrics']['metricTypes']>

type CreatedObject = {
  metricTypeName: string
  metricValue: CompanyMetric
}

export default function ProjectedCompanyMetricsTable() {
  let { selectedCompany, companyId, projectionIncrement } = useCompanySelectorContext()
  const {
    selectedBaseCompany,
    pluralCompanyId,
    projectionIncrement: projectionIncrementV2,
    selectedEnrichedCompanyData,
    isLoadingCompanies,
    refetch: refreshEnrichedCompanyData,
  } = useEnrichedCompanySelectorContext()

  if (pluralCompanyId.value) {
    companyId = pluralCompanyId
    projectionIncrement = projectionIncrementV2
    selectedCompany = selectedBaseCompany
  }

  const updateMassiveCompanyMetricMutation = trpc.company.updateMassiveCompanyMetric.useMutation()

  const companyMetrics: CompanyMetric[] =
    selectedEnrichedCompanyData?.value?.companyMetrics?.filter((metric) => metric.isProjected) ?? []
  const metricTypes = trpc.projectedCompanyMetrics.metricTypes.useQuery(undefined, {}).data ?? []

  function refresh() {
    refreshEnrichedCompanyData()
    projectionIncrement.setValue(projectionIncrement.value + 1)
  }

  const columns: AnyColumnProps<CompanyMetric, CreatedObject>[] = [
    {
      header: 'Metric',
      options: metricTypes.map((type: AttributedMetricType) => type.name),
      inputType: 'multiSelect',
      accessor: (row) => row.metricType,
      updateRemote: async (row: CompanyMetric, newValue: Optional<String>) => {
        const metricType = metricTypes.find((type: AttributedMetricType) => type.name === newValue)
        if (isSome(metricType)) {
          await updateMassiveCompanyMetricMutation.mutateAsync({
            companyMetricId: row.id,
            companyId: selectedEnrichedCompanyData.value?.companyId!,
            metricType: metricType.name,
            metricFormat: metricType.format,
          })
        }
        refresh()
      },
      createProps: {
        createdObjectKey: 'metricTypeName',
        isRequired: true,
      },
    },
    {
      header: 'Value',
      inputType: 'input',
      textCellFormat: AttributedMetricCellFormat,
      accessor: (row: CompanyMetric) => row,
      updateRemote: async (row: CompanyMetric, newValue: Optional<CompanyMetric>) => {
        if (newValue != null) {
          await updateMassiveCompanyMetricMutation.mutateAsync({
            companyMetricId: row.id,
            companyId: selectedEnrichedCompanyData.value?.companyId!,
            value: newValue.value ?? undefined,
          })
        }
        refresh()
      },
      minWidthPixels: 150,
      placeholder: 'value...',
      createProps: {
        defaultOption: {
          value: '',
          metricType: {
            format: 'GENERIC',
          },
        } as any,
        createdObjectKey: 'metricValue',
        isRequired: true,
      },
    },
    {
      header: 'Updated At',
      inputType: 'input',
      textCellFormat: DateCellFormat,
      accessor: (row) => new Date(row.updatedAt),
      minWidthPixels: 100,
    },
  ]

  let { user } = usePluralAuth()
  let isEditor = hackyEditorIds.includes(user?.id ?? -1)

  return (
    <div>
      <EditableTable<CompanyMetric, CreatedObject>
        columns={columns}
        rowData={companyMetrics.filter((metric) => (isEditor ? true : metric.isPublic))}
        deleteProps={{
          onDelete: async (rowIndex) => {
            const metric = companyMetrics[rowIndex]
            await updateMassiveCompanyMetricMutation.mutateAsync({
              companyMetricId: metric.id,
              companyId: selectedEnrichedCompanyData.value?.companyId!,
              deletedAt: new Date().toISOString(),
            })
            refresh()
          },
          confirmationModalProps: {
            title: 'Are you sure you want to delete this metric?',
            body: 'This action cannot be undone',
            confirmText: 'Delete',
          },
        }}
        onRowCreate={async (createdObject: CreatedObject) => {
          if (isNone(companyId.value)) return
          let newMetricTypeName = createdObject.metricTypeName
          let newMetricType = metricTypes.find((type: AttributedMetricType) => type.name === newMetricTypeName)
          if (isNone(newMetricType) || isNone(createdObject.metricValue.value)) return
          const data = await updateMassiveCompanyMetricMutation.mutateAsync({
            isProjected: true,
            companyId: selectedEnrichedCompanyData.value?.companyId!,
            metricType: newMetricType.name,
            metricFormat: newMetricType.format,
            value: createdObject.metricValue.value,
          })
          refresh()
        }}
        stickyColumn={true}
      />
    </div>
  )
}
