import { numberToAbbreviatedDollarString, numberToReadableDollarString } from 'plural-shared/utils'
import { isNone } from 'safety'
import { useSimulatorContext } from './CashSimulatorContext'

export default function NetCashGraphHeader() {
  const { multiplesSlider, netCashGraphProps, pluralCashTx } = useSimulatorContext() ?? {}
  const { pluralBar, secondaryBar } = netCashGraphProps ?? {}
  if (isNone(multiplesSlider) || isNone(netCashGraphProps) || isNone(pluralCashTx)) {
    return null
  }
  if (isNone(pluralBar) || isNone(secondaryBar)) {
    return null
  }
  const dollarsSaved = pluralBar.netCashValue - secondaryBar.netCashValue
  return (
    <div>
      <div className="flex items-end text-2xl">
        <div className="font-semibold">{numberToAbbreviatedDollarString(pluralBar.netCashValue)}</div>
        <div className="mb-[2px] ml-1 text-base font-normal opacity-60">Net cash</div>
      </div>
      <div
        className={`text-sm font-medium ${
          Math.abs(dollarsSaved - 0) < 0.001
            ? 'text-func-black-0'
            : dollarsSaved < 0
              ? 'text-tickers-red'
              : 'text-tickers-green'
        }`}>
        {dollarsSaved > 0 ? '+' : ''}
        {numberToAbbreviatedDollarString(dollarsSaved)}{' '}
        <span className="text-sm font-normal text-black opacity-60">
          from {numberToAbbreviatedDollarString(pluralCashTx.inputs().principal, 1)} secondary at{' '}
          {numberToReadableDollarString(pluralCashTx.inputs().baseSharePrice)} / sh
        </span>
      </div>
    </div>
  )
}
