import alex from '../images/headshots/alex.jpeg'
import andres from '../images/headshots/andres.jpeg'
import ben from '../images/headshots/ben.jpeg'
import bryan from '../images/headshots/bryan.jpeg'
import dave from '../images/headshots/dave.jpeg'
import emil from '../images/headshots/emil.jpeg'
import erik from '../images/headshots/erik.jpeg'
import hari from '../images/headshots/hari.jpeg'
import nate from '../images/headshots/nate.jpeg'
import randy from '../images/headshots/randy.jpeg'
import ravi from '../images/headshots/ravi.jpeg'
import reed from '../images/headshots/reed.jpeg'
import rick from '../images/headshots/rick.png'
import sam from '../images/headshots/sam.jpeg'

interface Person {
  name: string
  title: React.ReactNode
  link: string
  image: string
}
const advisors: Person[] = [
  {
    name: 'Emil Michael',
    title: 'CBO, Uber',
    link: 'https://www.linkedin.com/in/emilmichael/',
    image: emil,
  },
  {
    name: 'Dave McClure',
    title: 'Founder, 500 Startups',
    link: 'https://www.linkedin.com/in/davemcclure/',
    image: dave,
  },
  {
    name: 'Ben Braverman',
    title: 'CRO, Flexport',
    link: 'https://www.linkedin.com/in/ben-braverman-a1120314/',
    image: ben,
  },
  {
    name: 'Reed McBride',
    title: 'VP of Business Development, Carta',
    link: 'https://www.linkedin.com/in/reedmcbride/',
    image: reed,
  },
  {
    name: 'Hari Raghavan',
    title: (
      <div>
        COO, Forge
        <br />
        CEO, AbstractOps
      </div>
    ),
    link: 'https://www.linkedin.com/in/hraghavan/',
    image: hari,
  },
  {
    name: 'Ravi Goyal',
    title: 'Cofounder, Komodo Health',
    link: 'https://www.linkedin.com/in/rkgoyal/',
    image: ravi,
  },
  {
    name: 'Alex Song',
    title: 'VP of Finance, Ramp',
    link: 'https://www.linkedin.com/in/alexsong2009/',
    image: alex,
  },
  {
    name: 'Rick Ling',
    title: (
      <div>
        Head of Communities, Discord <div className="hidden lg:block">Cofounder, Rascal Games</div>
      </div>
    ),
    link: 'https://www.linkedin.com/in/rickling/',
    image: rick,
  },
  {
    name: 'Sam Kirschner',
    title: 'Director, On Deck Accelerator',
    link: 'https://www.linkedin.com/in/samlkirschner/',
    image: sam,
  },
  {
    name: 'Erik Brue',
    title: 'Cofounder, Themis',
    link: 'https://www.linkedin.com/in/erikbrue/',
    image: erik,
  },
  {
    name: 'Andrés Pérez Soderi',
    title: 'Cofounder, Sanas',
    link: 'https://www.linkedin.com/in/andresperezsoderi/',
    image: andres,
  },
  {
    name: 'Randy Yang',
    title: "Chief Growth Officer, Harry's",
    link: 'https://www.linkedin.com/in/rsyang/',
    image: randy,
  },
]
export function PersonGrid({ people, title }: { people: Person[]; title: string }) {
  const personComponent = (advisor: Person, key: string) => {
    return (
      <div className="flex w-full flex-col lg:w-1/2">
        <a
          className="flex w-full cursor-pointer items-start pb-6 pr-4 pt-6 text-left"
          href={advisor.link}
          target="_blank"
          key={key}
          rel="noreferrer">
          <img
            src={advisor.image}
            className="lg:w-22 lg:h-22 mr-3 h-[68px] w-[68px] rounded-lg shadow-light"
            alt={advisor.name}
          />
          <div className="flex flex-col text-left">
            <div className="text-sm font-medium">{advisor.name}</div>
            <div className="text-sm opacity-50">{advisor.title}</div>
          </div>
        </a>
        <div className="h-[1px] bg-black opacity-10" style={{ width: 'calc(100% - 24px)' }} />
      </div>
    )
  }

  return (
    <div>
      <div className="mb-4 w-full text-left text-3xl font-semibold">{title}</div>
      <div className="mt-2 flex w-full flex-wrap lg:w-[48rem] ">
        {people.map((advisor) => {
          return personComponent(advisor, advisor.name)
        })}
      </div>
    </div>
  )
}

export default function Team() {
  interface Partner {
    name: string
    title: string
    link: string
    image: string
    bio?: string
  }
  const partnerProps: Partner[] = [
    {
      name: 'Bryan Casey',
      title: 'Partner',
      link: 'https://www.linkedin.com/in/bryan-casey-aaa112148/',
      image: bryan,
      bio: 'Bryan previously founded Harmonic ($200m+ valuation) and taught at Stanford Law.',
    },
    // {
    //   name: 'Jason Pate',
    //   title: 'Partner',
    //   link: 'https://www.linkedin.com/in/jason-pate-50735310/',
    //   image: jason,
    //   bio: 'Jason served as the Head of Finance, Business, and Corporate Development at Plaid.',
    // },
    {
      name: 'Nate Shirley',
      title: 'Engineering',
      link: 'https://www.linkedin.com/in/nate-shirley-93b738126/',
      image: nate,
      bio: 'Nate most recently worked as a software engineer at Harmonic.',
    },
  ]

  function makeParter(partner: Partner) {
    return (
      <div className="w-full pr-8 pt-8 lg:w-1/2">
        <a
          className="flex w-full cursor-pointer items-start pb-4 text-left"
          href={partner.link}
          target="_blank"
          key={partner.name}
          rel="noreferrer">
          <img
            src={partner.image}
            className="lg:w-22 lg:h-22 mr-3 h-20 w-20 rounded-lg shadow-light"
            alt={partner.name}
          />
          <div className="flex flex-col text-left">
            <div className="text-base font-medium">{partner.name}</div>
            <div className="text-base opacity-50">{partner.title}</div>
          </div>
        </a>
        {partner.bio && <div className="mr-2 text-sm"> {partner.bio} </div>}
      </div>
    )
  }

  return (
    <div className="flex w-full flex-col items-center pl-6 pr-6">
      <div className="w-full max-w-[48rem] text-left">
        <div className="mb-4 text-5xl font-semibold">Our Advisors</div>
        <div className="text-lg ">
          Built by founders, operators, angels, institutional investors, corporate attorneys, private wealth experts,
          and more.
        </div>
        {/* <div className="mt-2 flex flex-wrap ">
          {partnerProps.map((partner) => {
            return makeParter(partner)
          })}
        </div> */}
      </div>
      <div className="mt-16 flex w-full flex-col items-center">
        <PersonGrid people={advisors} title="Advisors" />
      </div>
    </div>
  )
}
