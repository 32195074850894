import {
  MultipleRange,
  MultipleRangeDistribution,
} from "../multipleRangeDistribution";

let multipleRanges = [
  new MultipleRange([1, 1.1, 1.3, 1.5, 1.7], 0.25),
  new MultipleRange([2.3, 2.5, 2.7], 0.29),
  new MultipleRange([3.3, 3.5, 3.7], 0.3),
  new MultipleRange([4.5], 0.01),
  new MultipleRange([5.5], 0.06),
  new MultipleRange([6.5], 0.03),
  new MultipleRange([7.5], 0.03),
  new MultipleRange([10], 0.01),
  new MultipleRange([12], 0.01),
  new MultipleRange([14], 0.01),
];

export const defaultLPInterestMultipleRangeDistribution =
  new MultipleRangeDistribution(multipleRanges);
