import { ColumnDef, createColumnHelper, SortingFnOption } from '@tanstack/react-table'
import { AdminSheetPageContainer } from 'admin/AdminSideNav'
import { DollarsCellFormat } from 'components/editable-table/EditableTextCellFormat'
import EditableTanstackTable from 'components/editable-tanstack-table/EditableTanstackTable'
import { FundMarkDate, FundMarkDateType } from 'plural-shared/constants'
import { dateToShorthandMonthYearString } from 'plural-shared/utils'
import { usePluralAuth } from 'providers/PluralAuthProvider'
import { useMemo } from 'react'
import { ArrayElement, Optional } from 'safety'
import { RouterOutputs, trpc } from 'utils/trpc'

type RouterMarkRow = ArrayElement<RouterOutputs['marks']['adminGetAllMarks']>
export default function AdminMarksPage() {
  let { isLoading: isAuthLoading } = usePluralAuth()
  let allMarksQuery = trpc.marks.adminGetAllMarks.useQuery(undefined, {
    enabled: !isAuthLoading,
  })

  let columnHelper = createColumnHelper<RouterMarkRow>()
  let createMarkMutation = trpc.marks.create.useMutation()
  let updateMarkMutation = trpc.marks.update.useMutation()
  let allMonths = useMemo(() => {
    return Object.keys(allMarksQuery.data ? allMarksQuery.data[0].marks : {}).sort(
      (a, b) => FundMarkDate[a as FundMarkDateType].getTime() - FundMarkDate[b as FundMarkDateType].getTime(),
    )
  }, [allMarksQuery.data])
  let columns: ColumnDef<RouterMarkRow>[] = [
    columnHelper.accessor('company', {
      header: 'Company',
      id: 'company',
      sortingFn: 'companySort' as SortingFnOption<RouterMarkRow>,
      size: 200,
      meta: {
        className: 'z-30 sticky left-0 bg-white',
        headerClassName: 'z-40 sticky left-0 bg-white',
      },
      cell: (cell) => (
        <div className="align-center z-30 flex  flex-row gap-2 bg-white pl-1">
          {cell.row.original.companyLogo ? (
            <img
              src={cell.row.original.companyLogo}
              alt="logo"
              className="border-gray-80 h-6  w-6 rounded-full border border-solid"
            />
          ) : (
            <div className="border-gray-80 flex h-6  w-6 items-center justify-center rounded-full border border-solid">
              {cell.getValue()[0]}
            </div>
          )}
          <div className="flex flex-row items-center font-normal">{cell.getValue()}</div>
        </div>
      ),
    }) as ColumnDef<RouterMarkRow>,
    ...allMonths.map(
      (month) =>
        columnHelper.accessor((row: RouterMarkRow) => row.marks[month]?.sharePrice, {
          header: dateToShorthandMonthYearString(FundMarkDate[month as FundMarkDateType]),
          sortingFn: 'markSort' as SortingFnOption<RouterMarkRow>,
          id: month,
          meta: {
            inputType: 'input',
            textCellFormat: DollarsCellFormat,
            placeholder: 'Share Price',
            // @ts-ignore
            updateRemote: async (row: RouterMarkRow, newValue: Optional<number>) => {
              if (!newValue) return
              if (row.marks[month]?.id === undefined) {
                await createMarkMutation.mutateAsync({
                  companyId: row.companyId,
                  markDate: FundMarkDate[month as FundMarkDateType].toISOString(),
                  sharePrice: newValue,
                })
              } else {
                await updateMarkMutation.mutateAsync({
                  markId: row.marks[month]?.id ?? 0,
                  sharePrice: newValue,
                })
              }

              await allMarksQuery.refetch()
            },
          },
        }) as ColumnDef<RouterMarkRow>,
    ),
  ]
  return (
    <AdminSheetPageContainer title="Portfolio Markings">
      <EditableTanstackTable
        defaultSorting={{ id: 'company', desc: true }}
        stickyHeader
        maxHeight="max-h-160"
        noFilter
        data={allMarksQuery.data ?? []}
        sortingFns={{
          companySort: (a, b) => b.company.localeCompare(a.company),
          markSort: (a, b, field) => (a.marks[field]?.sharePrice ?? 0) - (b.marks[field]?.sharePrice ?? 0),
        }}
        columns={columns}
      />
    </AdminSheetPageContainer>
  )
}
