import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { getQueryKey } from '@trpc/react-query'
import { BasicButton } from 'components/BasicButton'
import EditableTanstackTable from 'components/editable-tanstack-table/EditableTanstackTable'
import { MergeIcon, RefreshCcw, SplitIcon, Trash } from 'lucide-react'
import { usePluralAuth } from 'providers/PluralAuthProvider'
import { useSnackbar } from 'providers/SnackbarProvider'
import React, { useEffect, useState } from 'react'
import {
  associateFundingRounds,
  getFundingRounds,
  mergeFundingRounds,
  patchFundingRound,
  postFundingRound,
  unmergeFundingRound,
} from 'several/actions/FundingRounds'
import { getFundingRoundSortingFns, hackyEditorIds, useFundingRoundColumns } from 'several/hooks/FundingRound'
import { useIsSmallScreen } from 'several/hooks/navigation'
import { MasterFundingRound, UpdateMassiveFundingRoundData } from 'several/models/FundingRounds'
import { trpc } from 'utils/trpc'

function CompanyFundingRoundsTable(props: { companyId: string; tableNameNode?: React.ReactNode; companyName: string }) {
  let [skippingCache, setSkippingCache] = useState(false)
  let [hasSkippedCache, setHasSkippedCache] = useState(false)
  let queryClient = useQueryClient()
  let { user } = usePluralAuth()
  let fundingRoundsQuery = useQuery({
    enabled: !!props.companyId,
    queryKey: ['fundingRounds', props.companyId, hasSkippedCache],
    queryFn: getFundingRounds(props.companyId, hasSkippedCache, true),
    onSuccess: () => {
      if (skippingCache) {
        setSkippingCache(false)
      }
    },
  })
  let sortingFns = getFundingRoundSortingFns()

  let { setSnackbarText } = useSnackbar()
  const mergeFundingRoundsMutation = useMutation({
    mutationFn: (variables: { fundingRoundAId: string; fundingRoundBId: string }) =>
      mergeFundingRounds(variables.fundingRoundAId, variables.fundingRoundBId)(),
    mutationKey: ['mergeFundingRounds'],
    onSuccess: (newRound, variables) => {
      queryClient.setQueryData(
        ['fundingRounds', props.companyId, hasSkippedCache],
        (oldData: MasterFundingRound[] | undefined) => {
          function replaceItemById(arr: MasterFundingRound[], newItem: MasterFundingRound): MasterFundingRound[] {
            return arr.map((item) => {
              if (item.id === newItem.id) {
                return newItem
              } else if (item.subRows && item.subRows.length > 0) {
                return {
                  ...item,
                  subRows: replaceItemById(item.subRows, newItem) as MasterFundingRound[],
                }
              } else {
                return item
              }
            })
          }
          return replaceItemById(oldData ?? [], newRound)
        },
      )
    },
    onError: (error) => {
      alert(error)
      // setSnackbarText('Error merging funding rounds', 5000)
    },
  })

  const associateFundingRoundsMutation = useMutation({
    mutationFn: (variables: { parentFundingRoundId: string; childrenFundingRoundIds: string[] }) =>
      associateFundingRounds(
        variables.childrenFundingRoundIds[0],
        variables.parentFundingRoundId.split(',').filter((id) => id !== variables.childrenFundingRoundIds[0]),
      )(),
    mutationKey: ['associateFundingRounds'],
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries(['fundingRounds', props.companyId])
      queryClient.invalidateQueries(['fundingRounds', props.companyId, hasSkippedCache])
    },
    onError: (error) => {
      console.log(error)
      setSnackbarText('Error merging funding rounds', 5000)
    },
  })

  const unmergeFundingRoundMutation = useMutation({
    mutationFn: (variables: { fundingRoundId: string }) => unmergeFundingRound(variables.fundingRoundId)(),
    mutationKey: ['unmergeFundingRounds'],
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries(['fundingRounds', props.companyId])
      queryClient.invalidateQueries(['fundingRounds', props.companyId, hasSkippedCache])
    },
    onError: (error) => {
      console.log(error)
      setSnackbarText('Error merging funding rounds', 5000)
    },
  })

  function onSuccessFn(newRound: MasterFundingRound, Id: string) {
    queryClient.setQueryData(['fundingRounds', props.companyId], (oldData: MasterFundingRound[] | undefined) => {
      function replaceItemById(arr: MasterFundingRound[], newItem: MasterFundingRound): MasterFundingRound[] {
        return arr.map((item) => {
          if (item.id === newItem.id) {
            return newItem
          } else if (item.subRows && item.subRows.length > 0) {
            return {
              ...item,
              subRows: replaceItemById(item.subRows, newItem) as MasterFundingRound[],
            }
          } else {
            return item
          }
        })
      }
      return replaceItemById(oldData ?? [], newRound)
    })
  }
  let columns = useFundingRoundColumns({
    onSuccess: onSuccessFn,
  })

  let patchFundingRoundMutation = useMutation({
    mutationFn: (variables: { fundingRound: UpdateMassiveFundingRoundData }) => patchFundingRound(variables)(),
    mutationKey: ['patchFundingRound'],
    onSuccess: (newRound, oldVars) => {
      return onSuccessFn(newRound, oldVars.fundingRound.id)
    },
  })

  let fundingRounds: MasterFundingRound[] = fundingRoundsQuery.data ?? []
  let isEditor = hackyEditorIds.includes(user?.id ?? -1)

  let createFundingRoundMutation = useMutation({
    mutationFn: (variables: { email: string; companyId: string; companyName: string }) =>
      postFundingRound(variables.companyId, variables.email, variables.companyName),
    onSuccess: (newRound) => {
      queryClient.setQueryData(['fundingRounds', props.companyId], (oldData: MasterFundingRound[] | undefined) => {
        return [...(oldData ?? []), newRound]
      })
    },
  })

  useEffect(() => {
    let timer = setTimeout(() => {
      setSkippingCache(false)
    }, 180000)
    return () => {
      clearTimeout(timer)
    }
  }, [skippingCache])

  let isSmallScreen = useIsSmallScreen()
  let height = isSmallScreen ? 'h-[80vh] min-h-[80vh]' : 'h-[500px] min-h-[500px]'
  return (
    <div className={`${isSmallScreen ? '' : 'mb-[85px]'} ${height} `}>
      <EditableTanstackTable
        fullPage={isSmallScreen}
        onRowCreate={(row) => {
          createFundingRoundMutation.mutate({
            email: user?.email ?? '',
            companyId: props.companyId,
            companyName: props.companyName,
          })
        }}
        stickyColumn
        bgColor="bg-white"
        defaultCellBg="bg-white"
        sortingFns={sortingFns}
        enableMultiRowSelection={(row) => true}
        defaultColumnSize={112}
        isLoading={fundingRoundsQuery.isLoading}
        tableNameNode={
          <div className="flex flex-row items-center gap-3">
            {props.tableNameNode}
            <BasicButton
              attioStyle
              disabled={skippingCache}
              variant="gray"
              onClick={async () => {
                setSkippingCache(true)
                setHasSkippedCache(true)
                await queryClient.invalidateQueries(['fundingRounds', props.companyId])
              }}>
              <RefreshCcw size={12} />
            </BasicButton>
          </div>
        }
        maxHeight={height}
        stickyHeader
        getRowId={(row) => row.id}
        defaultSorting={{
          id: 'date',
          desc: true,
        }}
        columns={columns}
        data={fundingRounds
          .filter((round) => !round.hidden)
          .map((fundingRound) => {
            return {
              ...fundingRound,
              unifiedDate: fundingRound.announcedDate ?? fundingRound.closedDate,
              subRows: fundingRound.subRows?.filter((round) => !round.hidden),
            }
          })}
        filterFns={{}}
        createFloatingFooterButtons={(selectedRows, deselect) => {
          let parents = selectedRows.filter((row) => (row.subRows?.length ?? 0) > 0)
          let children = selectedRows.filter((row) => (row.subRows?.length ?? 0) === 0)
          return (
            <div className="flex flex-row items-center gap-2">
              <BasicButton
                attioStyle
                disabled={parents.length > 1 || !isEditor}
                variant="gray"
                className="flex items-center gap-2"
                onClick={async () => {
                  if (parents.length === 1) {
                    await associateFundingRoundsMutation.mutateAsync({
                      parentFundingRoundId: parents[0].id,
                      childrenFundingRoundIds: children.map((child) => child.id),
                    })
                  } else {
                    await mergeFundingRoundsMutation.mutateAsync({
                      fundingRoundAId: selectedRows[0].id,
                      fundingRoundBId: selectedRows[1].id,
                    })
                  }

                  deselect()
                }}>
                <MergeIcon size={20} />
                Merge
              </BasicButton>
              <BasicButton
                attioStyle
                disabled={
                  parents.length > 0 ||
                  !isEditor ||
                  children.length !== 1 ||
                  (children[0].original?.sources?.length ?? 0) <= 1
                }
                variant="gray"
                className="flex items-center gap-2"
                onClick={async () => {
                  await unmergeFundingRoundMutation.mutateAsync({
                    fundingRoundId: children[0].id,
                  })
                  deselect()
                }}>
                <SplitIcon size={20} />
                Unmerge
              </BasicButton>
              <BasicButton
                variant="gray"
                attioStyle
                disabled={selectedRows.length === 0 || parents.length > 0 || !isEditor}
                onClick={async () => {
                  for (let row of selectedRows) {
                    await patchFundingRoundMutation.mutateAsync({
                      fundingRound: {
                        id: row.id,
                        hidden: true,
                        adminEmail: user?.email ?? '',
                        companyId: props.companyId,
                      },
                    })
                  }
                  let companyQueryKey = getQueryKey(trpc.companies.getEnrichedCompanyData, props.companyId)
                  queryClient.invalidateQueries(companyQueryKey)
                  fundingRoundsQuery.refetch()
                  deselect()
                }}>
                <Trash size={20} />
                Delete
              </BasicButton>
            </div>
          )
        }}
      />
    </div>
  )
}

export default React.memo(CompanyFundingRoundsTable)
