import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from 'components/ui/tooltip'

export default function QuestionTooltip({ children }: { children?: React.ReactNode }) {
  return (
    <TooltipProvider>
      <Tooltip delayDuration={100}>
        <TooltipTrigger asChild>
          <div className="flex w-6 h-6 bg-plural-mustard-500 items-center justify-center rounded-full">
            <span className="text-plural-mustard-800 text-[12px] font-bold">?</span>
          </div>
        </TooltipTrigger>

        <TooltipContent className="bg-plural-mustard-500 px-4 py-2">{children}</TooltipContent>
      </Tooltip>
    </TooltipProvider>
  )
}

export function QuestionTooltipContent({ children }: { children?: React.ReactNode }) {
  return <div className="text-plural-mustard-800 tex-sm max-w-xs">{children}</div>
}
