import { ChevronDownIcon, LockClosedIcon, LockOpen1Icon } from '@radix-ui/react-icons'
import * as Select from '@radix-ui/react-select'
import { TextFieldInput, TextFieldRoot } from '@radix-ui/themes'
import AppModal from 'components/AppModal'
import { BasicButton } from 'components/BasicButton'
import { LinkIcon, ShareIcon } from 'lucide-react'
import { usePluralAuth } from 'providers/PluralAuthProvider'
import { useEffect, useMemo, useState } from 'react'
import { RouterOutputs, trpc } from 'utils/trpc'
import DropdownSearch, { DropdownSearchOption } from './DropdownSearch'

export function ShareSheetModal(props: { sheetId: number; open: boolean; onClose: () => void }) {
  let sheetQuery = trpc.sheetRouter.getSheet.useQuery(props.sheetId)

  let sheet = sheetQuery.data

  let { user } = usePluralAuth()
  let utrs = ((sheet as unknown as any)?.userToSheets ?? []) as {
    user: { id: number; email: string; firstName?: string; lastName?: string }
    role: 'OWNER' | 'EDITOR' | 'VIEWER' | 'FOLLOWER'
  }[]

  let patchSheetMutation = trpc.sheetRouter.patchSheet.useMutation()
  const [restrictedValue, setRestrictedValue] = useState((!!sheet?.restricted).toString())
  useEffect(() => {
    setRestrictedValue((!!sheet?.restricted).toString())
  }, [sheet?.restricted])

  let [copied, setCopied] = useState(false)
  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false)
      }, 2000)
    }
  }, [copied])

  return (
    <>
      <AppModal open={props.open} onClose={props.onClose}>
        <div style={{ width: '600px' }} className={'flex flex-col px-4 py-5'}>
          <div className="pb-5">
            <h1 className="text-xl font-normal">Share "{sheet?.name}"</h1>
          </div>
          <div>
            <AddEditorSearchBar
              existingUserIds={utrs.map((utr: any) => utr.user.id)}
              sheetId={sheet?.id ?? 0}
              onChange={() => {
                sheetQuery.refetch()
              }}
            />
          </div>
          <div className="pt-3 text-sm font-semibold">People with access</div>
          <div className="max-h-[400px] overflow-auto">
            {utrs.map((utr) => {
              return (
                <UserWithAccess
                  key={utr.user.id}
                  user={utr.user}
                  role={utr.role}
                  sheetId={sheet?.id ?? 0}
                  onChange={() => {
                    sheetQuery.refetch()
                  }}
                  isSelf={utr.user.id === user?.id}
                />
              )
            })}
          </div>
          <div className="py-3 text-sm font-semibold">General access</div>
          <div className="flex flex-row items-center gap-2">
            <div className="flex h-[30px] w-[30px] items-center justify-center rounded-full bg-gray-300">
              {restrictedValue === 'true' ? (
                <LockClosedIcon className="h-[15px] w-[15px]" />
              ) : (
                <LockOpen1Icon className="h-[15px] w-[15px]" />
              )}
            </div>
            <div className="flex flex-col">
              <Select.Root
                value={restrictedValue}
                onValueChange={async (value) => {
                  setRestrictedValue(value)
                  await patchSheetMutation.mutateAsync({
                    id: props.sheetId,
                    data: { restricted: value === 'true' },
                  })
                }}>
                <Select.Trigger className="flex flex-row items-center font-semibold">
                  {restrictedValue === 'true' ? 'Restricted' : 'Public'}
                  <ChevronDownIcon className="ml-2 h-4 w-4" />
                </Select.Trigger>
                <Select.Content className="rounded border bg-white shadow-lg" position="popper">
                  <Select.Item value="true" className="cursor-pointer p-3 hover:bg-gray-100">
                    Restricted
                  </Select.Item>
                  <Select.Item value="false" className="cursor-pointer p-3 hover:bg-gray-100">
                    Public
                  </Select.Item>
                </Select.Content>
              </Select.Root>
              <div className="text-sm">
                {restrictedValue === 'true'
                  ? 'Only people with access can open the link'
                  : 'Anyone with the link can view'}
              </div>
            </div>
          </div>
          <div className="flex flex-row items-center justify-between pt-5">
            <BasicButton
              variant="gray"
              onClick={() => {
                let location = window.location.href
                navigator.clipboard.writeText(location)
                setCopied(true)
              }}
              disabled={copied}
              attioStyle>
              <LinkIcon className="w-[15px]" />
              {copied ? 'Copied' : 'Copy Link'}
            </BasicButton>
            <BasicButton
              variant="primary"
              onClick={props.onClose}
              attioStyle
              additionalStyle={{
                padding: '6px 12px',
              }}>
              Done
            </BasicButton>
          </div>
        </div>
      </AppModal>
    </>
  )
}
type RouterUser = RouterOutputs['user']['getSelfWithSheets']
type UserT = { id: number; email: string; firstName?: string; lastName?: string }
function AddEditorSearchBar(props: { sheetId: number; onChange: () => void; existingUserIds: number[] }) {
  let [searchString, setSearchString] = useState('')
  const { isLoading: isAuthLoading } = usePluralAuth()
  let userQuery = trpc.user.getSelfWithSheets
  let user: RouterUser | undefined = userQuery.useQuery(undefined, {
    enabled: !isAuthLoading,
  })?.data

  let dropDownOptions: DropdownSearchOption[] = useMemo(() => {
    let users = (user as unknown as any)?.userToSheets.flatMap((utr: any) => {
      return utr.sheet.userToSheets.map(
        (utr: any) =>
          utr.user as {
            id: number
            email: string
            firstName?: string
            lastName?: string
          },
      )
    })

    return users
      .filter((nUser: UserT) => nUser.id !== user?.id && !props.existingUserIds.includes(nUser.id))
      .reduce((acc: UserT[], curr: UserT) => {
        return acc.find((nUser) => nUser.id === curr.id) ? acc : [...acc, curr]
      }, [])
      .map((user: UserT) => {
        return {
          label: user.email,
          value: user.id,
          style: { padding: '10px' },
          startNode: (
            <div className="flex h-[20px] w-[20px] items-center justify-center rounded-full bg-blue-500 font-bold text-white">
              {user.firstName?.charAt(0).toUpperCase() ?? user.email.charAt(0).toUpperCase()}
            </div>
          ),
        }
      })
  }, [user, props.existingUserIds])
  let putEditorMutation = trpc.sheetRouter.setUserPermission.useMutation()
  let addEditorByEmail = trpc.sheetRouter.addEditorByEmail.useMutation()

  const innerWidth = 550
  return (
    <DropdownSearch
      manualHoveredIndex={-1}
      manualSearchString={searchString}
      options={[{ label: searchString, value: 'new', style: { padding: '10px' } }, ...dropDownOptions]}
      onSelectOption={async (option) => {
        if (option.value !== 'new') {
          await putEditorMutation.mutateAsync({
            sheetId: props.sheetId,
            userId: parseInt(option.value),
            role: 'EDITOR',
          })
        } else {
          await addEditorByEmail.mutateAsync({
            sheetId: props.sheetId,
            userEmail: searchString,
            role: 'EDITOR',
          })
        }
        setSearchString('')
        props.onChange()
      }}
      onBlur={() => {
        setSearchString('')
      }}
      width={innerWidth + 20}
      trigger={
        <TextFieldRoot className="w-full" size={'3'} radius="small">
          <TextFieldInput
            style={{ width: innerWidth }}
            placeholder="Add people by email"
            value={searchString}
            onChange={(e) => {
              setSearchString(e.target.value)
            }}
          />
        </TextFieldRoot>
      }></DropdownSearch>
  )
}

function UserWithAccess(props: {
  user: UserT
  role: 'OWNER' | 'EDITOR' | 'VIEWER' | 'FOLLOWER'
  sheetId: number
  onChange: () => void
  isSelf: boolean
}) {
  let [role, setRole] = useState<string>(props.role)

  let putEditorMutation = trpc.sheetRouter.setUserPermission.useMutation()
  let deleteEditorMutation = trpc.sheetRouter.removeUserPermission.useMutation()
  useEffect(() => {
    setRole(props.role)
  }, [props.role, setRole])
  return (
    <div className="flex flex-row items-center gap-2 py-3">
      <div className="flex h-[30px] w-[30px] items-center justify-center rounded-full bg-blue-500 font-bold text-white">
        {props.user.firstName?.charAt(0).toUpperCase() ?? props.user.email.charAt(0).toUpperCase()}
      </div>
      <div className="flex flex-col">
        <div>{parseUserName(props.user, props.isSelf)}</div>
        <div className="text-xs text-gray-500">{props.user.email}</div>
      </div>
      <div className="ml-auto">
        {role === 'OWNER' ? (
          <div className="text-gray-400">Owner</div>
        ) : (
          <Select.Root
            value={role}
            onValueChange={async (value) => {
              setRole(value as 'EDITOR' | 'VIEWER' | 'false')
              if (value === 'false') {
                await deleteEditorMutation.mutateAsync({ sheetId: props.sheetId, userId: props.user.id })
              } else {
                await putEditorMutation.mutateAsync({ sheetId: props.sheetId, userId: props.user.id, role: value })
              }
              props.onChange()
            }}>
            <Select.Trigger className="font-md flex flex-row items-center">
              {role.toLowerCase() !== 'false' ? role.charAt(0).toUpperCase() + role.toLowerCase().slice(1) : ''}
              <ChevronDownIcon className="ml-2 h-3 w-3" />
            </Select.Trigger>
            <Select.Content className="rounded border bg-white shadow-lg">
              <Select.Item value="EDITOR" className="cursor-pointer p-3 hover:bg-gray-100">
                Editor
              </Select.Item>
              <Select.Item value="VIEWER" className="cursor-pointer p-3 hover:bg-gray-100">
                Viewer
              </Select.Item>
              <Select.Item value="FOLLOWER" className="cursor-pointer p-3 hover:bg-gray-100">
                Follower
              </Select.Item>
              <Select.Item value="false" className="cursor-pointer p-3 hover:bg-gray-100">
                None
              </Select.Item>
            </Select.Content>
          </Select.Root>
        )}
      </div>
    </div>
  )
}

function parseUserName(user: UserT, isSelf?: boolean) {
  return (
    (user.firstName || user.lastName ? `${user.firstName + ' '}${user.lastName}` : user.email) +
    (isSelf ? ' (You)' : '')
  )
}

export default function ShareSheetModalButton(props: { sheetId: number }) {
  let [open, setOpen] = useState(false)
  return (
    <>
      <ShareSheetModal
        {...props}
        open={open}
        onClose={() => {
          setOpen(false)
        }}
      />
      <BasicButton
        variant="gray"
        attioStyle
        onClick={() => {
          setOpen(true)
        }}>
        Share
        <ShareIcon className="h-[15px] w-[15px]" />
      </BasicButton>
    </>
  )
}
