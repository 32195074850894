import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import React, { PropsWithChildren, ReactNode, useLayoutEffect, useState } from 'react'

const WithPortal = (props: PropsWithChildren<{ portal?: boolean }>) => {
  return props.portal ? (
    <DropdownMenu.DropdownMenuPortal>{props.children}</DropdownMenu.DropdownMenuPortal>
  ) : (
    <>{props.children}</>
  )
}
type DropdownProps = PropsWithChildren<{
  button: ReactNode
  position?: 'top' | 'bottom' | 'right' | 'left'
  align?: 'start' | 'center' | 'end'
  portal?: boolean
}>

export function Dropdown(props: DropdownProps) {
  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild style={{ outline: 'none' }}>
        {props.button}
      </DropdownMenu.Trigger>
      <WithPortal portal={props.portal}>
        <DropdownMenu.Content
          className="z-50 min-w-48 gap-1 rounded-md border border-gray-200 bg-white p-1 shadow-sm"
          style={{
            maxHeight: 'var(--radix-dropdown-menu-content-available-height)',
            maxWidth: 'var(--radix-dropdown-menu-content-available-width)',
          }}
          collisionPadding={4}
          side={props.position ?? 'bottom'}
          sideOffset={4}
          align={props.align ?? 'start'}>
          {props.children}
        </DropdownMenu.Content>
      </WithPortal>
    </DropdownMenu.Root>
  )
}

export function TableCellWithDropdown(
  props: PropsWithChildren<{
    button: ReactNode
    open?: boolean
    onOpenChange?: (open: boolean) => void
    disabled?: boolean
  }>,
) {
  const triggerRef = React.useRef<HTMLDivElement>(null)
  const [triggerRefHeight, setTriggerRefHeight] = useState(0)

  useLayoutEffect(() => {
    if (triggerRef.current) {
      setTriggerRefHeight(triggerRef.current.getBoundingClientRect().height)
    }
  }, [])

  return (
    <DropdownMenu.Root open={props.open ?? (props.disabled ? false : undefined)} onOpenChange={props.onOpenChange}>
      <DropdownMenu.Trigger asChild>
        <div
          style={{
            display: 'flex',
            position: 'relative',
            alignItems: 'center',
            cursor: props.disabled ? 'not-allowed' : 'pointer',
          }}
          ref={triggerRef}>
          {props.button}
        </div>
      </DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <DropdownMenu.Content
          align="start"
          className="z-50 min-w-48 gap-1 rounded-md border border-gray-200 bg-white p-1 shadow-sm"
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            zIndex: 10,
            width: '100%',
            height: '100%',
            backgroundColor: 'white',
            maxHeight: 'var(--radix-dropdown-menu-content-available-height)',
            maxWidth: 'var(--radix-dropdown-menu-content-available-width)',
            boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
            transform: `translate(-2%, -${(triggerRefHeight + 5).toString()}px)`,
            minHeight: '150px',
          }}>
          {props.children}
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  )
}
