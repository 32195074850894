function getDecimals(num: number, divisor: number, decimals: number) {
  const trimmedNum = num / divisor;
  return Number.isInteger(Number(trimmedNum.toFixed(decimals))) ? 0 : decimals;
}
export function numberToAbbreviatedString(num: number, decimals: number = 2) {
  if (num < 1e3) {
    return `${num.toFixed(Number.isInteger(num) ? 0 : decimals)}`;
  } else if (num >= 1e3 && num < 1e6) {
    return `${(num / 1e3).toFixed(getDecimals(num, 1e3, decimals))}k`; // Thousands
  } else if (num >= 1e6 && num < 1e9) {
    return `${Number(
      (num / 1e6).toFixed(getDecimals(num, 1e6, decimals))
    ).toLocaleString()}m`; // Millions
  } else if (num >= 1e9 && num < 1e12) {
    return `${Number(
      (num / 1e9).toFixed(getDecimals(num, 1e9, decimals))
    ).toLocaleString()}B`; // Billions
  } else if (num >= 1e12 && num < 1e15) {
    return `${Number(
      (num / 1e12).toFixed(getDecimals(num, 1e12, decimals))
    ).toLocaleString()}T`; // Trillions
  } else {
    return num.toFixed(0).toLocaleString(); // If the number exceeds billions or is negative, return it as a string.
  }
}
