import CashSimDetailModal from 'components/cash-sim/CashSimDetailsModal'
import OutcomesTable from 'components/cash-sim/OutcomesTable'
import { useState } from 'react'
import SharpSpinner from '../../SharpSpinner'
import Disclaimer from '../portfolio/Disclaimer'
import { CashSimulatorContextProvider, useSimulatorContext } from './CashSimulatorContext'
import NetCashGraph from './NetCashGraph'
import NetCashGraphHeader from './NetCashGraphHeader'
import NetCashTransactionData from './NetCashTransactionData'
import OfferInputs from './OfferInputs'
import SliderIslandContainer from './SliderIslandContainer'
import ValuationGraph from './ValuationGraph'
import ValuationGraphHeader from './ValuationGraphHeader'
import ValuationGraphTransactionData from './ValuationGraphTransactionData'

function CashOutcomeSimulatorInner() {
  const context = useSimulatorContext()
  let [cashSimDetailModalOpen, setCashSimDetailModalOpen] = useState(false)

  if (!context) {
    return <div className="flex w-full flex-col items-center lg:h-nav-remainder lg:overflow-y-auto"></div>
  }
  const { pluralCashTx, selectedCompanyProps } = context
  if (!pluralCashTx || !selectedCompanyProps) {
    return <div className="flex w-full flex-col items-center lg:h-nav-remainder lg:overflow-y-auto"></div>
  }

  return (
    <div className="flex w-full flex-col items-center lg:h-nav-remainder lg:overflow-y-auto">
      <CashSimDetailModal
        open={cashSimDetailModalOpen}
        onClose={() => {
          setCashSimDetailModalOpen(false)
        }}
      />
      <div className="mb-28 mt-12 flex w-full flex-col items-center pl-4 pr-4 lg:max-w-3xl">
        <div className="flex w-full justify-between">
          <div className="flex items-center gap-2">
            {selectedCompanyProps.profile?.logoImgUrl && (
              <img
                src={selectedCompanyProps.profile.logoImgUrl}
                alt="logo"
                className="border-gray-80 h-8 w-8 rounded-full border border-solid"
              />
            )}
            <div className="text-lg font-medium">{selectedCompanyProps.profile?.name}</div>
          </div>
        </div>
        {context.isLoadingCompanyProfile ? (
          <div className="mt-16 flex w-full flex-col items-center">
            <SharpSpinner />
          </div>
        ) : (
          <div className="flex w-full flex-col justify-start">
            <div className="max-w-2xl">
              <div>
                <ValuationGraphHeader />
              </div>
              <div className="mt-1">
                <ValuationGraph />
              </div>
              <div className="mt-3">
                <ValuationGraphTransactionData />
              </div>
            </div>
            <div className="mt-12">
              <div className="text-base font-medium">Plural Net Cash vs. Secondary</div>
              <div className="mt-2" />
              <NetCashGraphHeader />
              <div className="mt-5" />
              <NetCashGraph props={context.netCashGraphProps} />
              <div className="mt-3">
                <NetCashTransactionData />
              </div>
              <div className="mt-20">
                <OutcomesTable />
              </div>
            </div>
            <div className="mb-12 mt-56 flex flex-col gap-4">
              <div className="mt-8 text-sm opacity-60">
                View{' '}
                <span
                  className="cursor-pointer underline"
                  onClick={() => {
                    setCashSimDetailModalOpen(true)
                  }}>
                  detailed tx values
                </span>
              </div>
              <Disclaimer shouldApplyMarginTop={false} />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default function CashRecipientOutcomeSimulator() {
  return (
    <CashSimulatorContextProvider>
      <SliderIslandContainer>
        <div className="flex h-full w-full flex-col lg:flex-row">
          <OfferInputs />
          <CashOutcomeSimulatorInner />
        </div>
      </SliderIslandContainer>
    </CashSimulatorContextProvider>
  )
}
