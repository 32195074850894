import {
  Chart as ChartJS,
  ChartData,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  ChartOptions,
  TimeScale,
  LineController,
  ScatterController,
  Plugin,
  ChartDataset,
} from 'chart.js'
import { Chart } from 'react-chartjs-2'
import 'chartjs-adapter-date-fns'
import { CalculatedExitScenario, FundingRound } from 'pages/valuation-calculator/types'
import { numberToAbbreviatedDollarString } from 'plural-shared/utils'
import { format } from 'date-fns'

ChartJS.register(TimeScale, LinearScale, PointElement, LineElement, LineController, ScatterController, Tooltip)

export const options: ChartOptions = {
  maintainAspectRatio: false, // Needed to correctly resize on page grow.
  onResize(chart, size) {
    // Hack to maintain 3/4 aspect ratio.
    chart.canvas.style.height = `${size.width * 0.85}px`
  },
  scales: {
    x: {
      type: 'time',
      time: {
        unit: 'year',
      },
    },
    y: {
      ticks: {
        // Format ticks with dollar shorthand
        callback: function (value, index, values) {
          return numberToAbbreviatedDollarString(Number(value))
        },
      },
    },
  },
  interaction: {
    intersect: true,
  },
  plugins: {
    tooltip: {
      callbacks: {
        title: (items) => {
          const first = items[0]
          if (!first) {
            return ''
          }
          let date = (first.raw as any).x as Date
          if (first.dataset.label === 'Funding Round') {
            const monthDateLabel = date ? format(date, 'MM/yyyy') : ''
            return 'Funding Round ' + monthDateLabel
            //using if 'rect' to find the 'today' points
          } else if (first.dataset.pointStyle === 'rect') {
            return `When you joined Plural`
            // would be faster to use the point size, but this makes more sense. Could go either way.
          } else if (first.dataset.label?.startsWith('Exit')) {
            return `${first.dataset.label} ` + format(date, 'MM/yyyy')
            // the default line scenario
          } else {
            return format(date, 'MM/yyyy')
          }
        },
        label: (item) => {
          return `Valuation: ${numberToAbbreviatedDollarString(Number(item.parsed.y))}`
        },
      },
    },
  },
}

// Ref. https://devsheet.com/code-snippet/show-vertical-line-on-data-point-hover-chartjs/
const plugins: Plugin[] = [
  {
    id: 'line-graph-vertical-line',
    afterDraw: (chart: ChartJS) => {
      if (chart.tooltip?.getActiveElements()?.length) {
        const x = chart.tooltip.getActiveElements()[0].element.x
        const yAxis = chart.scales.y
        const ctx = chart.ctx
        ctx.save()
        ctx.beginPath()
        ctx.moveTo(x, yAxis.top)
        ctx.lineTo(x, yAxis.bottom)
        ctx.lineWidth = 1
        ctx.strokeStyle = '#D5D2CE'
        ctx.stroke()
        ctx.restore()
      }
    },
  },
]

export default function LineGraph({
  scenarios,
  fundingRounds,
}: {
  scenarios: CalculatedExitScenario[]
  fundingRounds: FundingRound[]
}) {
  const lineDataSets: ChartDataset[] = scenarios.map((es, index) => ({
    type: 'line',
    label: `${index + 1}`,
    // `as any` cast needed since the type definitions from the date-fns adapter are missing.
    data: es.data.map((lp) => ({ x: lp.date, y: Math.ceil(lp.value) })) as any,
    borderColor: `rgb(${es.color.r}, ${es.color.g}, ${es.color.b})`,
    backgroundColor: `rgb(${es.color.r}, ${es.color.g}, ${es.color.b}, 0.5)`,
    tension: 0.1,
    pointRadius: 0,
    hitRadius: 1,
  }))

  const scatterData = fundingRounds.map((fr) => ({ x: fr.date, y: Math.ceil(fr.valuation) })) as any

  const todayValues = scenarios.map((es) => {
    return { x: new Date(), y: es.todayValuation } as any
  })

  const todayDatasets = todayValues
    .map((tv, index) => {
      const color = scenarios[index].color
      return {
        type: 'scatter',
        label: `${index + 1}`,
        data: [tv],
        backgroundColor: `rgb(${color.r}, ${color.g}, ${color.b})`,
        pointStyle: 'rect',
        pointRadius: 6.5,
        pointHoverRadius: 6.5,
        hitRadius: 20,
      }
    })
    .filter((ds) => ds.data[0].y !== null)

  const exitValues = scenarios.map((es: CalculatedExitScenario) => {
    return { x: es.exitScenario.date, y: es.exitScenario.valuation }
  })

  const exitDatasets = exitValues.map((ev, index) => {
    const color = scenarios[index].color
    return {
      type: 'scatter',
      label: `Exit`,
      data: [ev],
      backgroundColor: `rgb(${color.r}, ${color.g}, ${color.b})`,
      pointStyle: 'circle',
      pointRadius: 5,
      pointHoverRadius: 5,
      hitRadius: 20,
    } as any
  })

  const data: ChartData = {
    datasets: [
      ...lineDataSets,
      ...todayDatasets,
      ...exitDatasets,
      {
        type: 'scatter',
        label: 'Funding Round',
        data: scatterData,
        backgroundColor: 'rgb(0, 0, 0, 0.70)',
        pointRadius: 3.5,
        pointHoverRadius: 3.5,
        hitRadius: 5,
      },
    ],
  }

  return <Chart type="line" data={data} options={options} plugins={plugins} datasetIdKey="label" />
}
