import { Select, Slider } from '@radix-ui/themes'
import DealSensitivityMetadata from 'pages/dealSensitivity/DealSensitivityMetadata'
import SensivityFundValueGraph from 'pages/dealSensitivity/DealSensitivityValueGraph'
import Disclaimer from 'pages/portfolio/Disclaimer'
import { DEAL_SENSITIVITY_INPUT_OPTIONS } from 'plural-shared/constants'
import { breakevenCAGRPercent, numberToPercentChangeString } from 'plural-shared/utils'
import { ArrayElement, isNone, isSome } from 'safety'
import { RouterOutputs, trpc } from 'utils/trpc'
import { useQueryParam } from 'utils/useQueryParam'
import { Table, TableColumnProps } from '../../components/Table'
import SensitivityEquityMetadata from './DealSensitivityEquityMetadata'
type SensitivityProfile = ArrayElement<RouterOutputs['dealSensitivity']['tableRows']>
const possibleCompanyCAGRs = DEAL_SENSITIVITY_INPUT_OPTIONS.CAGRs
const possibleSliderYears = DEAL_SENSITIVITY_INPUT_OPTIONS.yearsToLiquidity
const possibleReserveRatios = DEAL_SENSITIVITY_INPUT_OPTIONS.reserveRatios
const possibleReserveRatioSelectionInputs = possibleReserveRatios.map((reserveRatio) => `${reserveRatio}x`)

export default function DealSensitivity() {
  const tablePadding = 'pl-3 lg:pl-6'
  const columns: TableColumnProps<SensitivityProfile>[] = [
    {
      header: 'Company CAGR',
      render: (profile: SensitivityProfile) => {
        const value = profile.companyCAGR
        return (
          <div
            className={`${value < 0 ? 'text-tickers-red' : value > 0 ? 'text-tickers-green' : 'text-tickers-black'} 
            `}>
            {numberToPercentChangeString(value, 0)}
          </div>
        )
      },
    },
    {
      header: 'Company Total Growth',
      render: (profile: SensitivityProfile) => {
        const value = numberToPercentChangeString(profile.companyTotalGrowthPercent, 0)
        return <div>{value}</div>
      },
    },
    {
      header: 'Plural IRR',
      render: (profile: SensitivityProfile) => {
        const value = profile.dealIRR
        return (
          <div className={`${value < 0 ? 'text-tickers-red' : 'text-func-bg-black'} `}>
            {numberToPercentChangeString(value, 0)}
          </div>
        )
      },
    },
    {
      header: 'Plural MOIC',
      render: (profile: SensitivityProfile) => {
        return <div>{profile.dealMOIC.toFixed(2)}</div>
      },
    },
    {
      header: 'Years to Liquidity',
      render: (profile: SensitivityProfile) => {
        return <div>{yearsToLiquiditySelected}</div>
      },
    },
  ]
  const columnHeaderStyle = 'text-sm font-normal'

  const defaultCompanyCAGR = 0.1 as number
  const defaultCompanyCAGRIndex = possibleCompanyCAGRs.indexOf(defaultCompanyCAGR) ?? 10
  const defaultYearsToLiquidity = 7 as number
  const defaultYearsToLiquidityIndex = possibleSliderYears.indexOf(defaultYearsToLiquidity) ?? 3
  const defaultReserveRatioIndex = 1
  const defaultReserveRatio = possibleReserveRatios[defaultReserveRatioIndex] as number

  const [yearsToLiquiditySelected, setYearsToLiquiditySelected] = useQueryParam('years', defaultYearsToLiquidity)
  const [companyCAGR, setCompanyCAGR] = useQueryParam('cagr', defaultCompanyCAGR)
  const [reserveRatioSelected, setReserveRatioSelected] = useQueryParam<number>('rr', defaultReserveRatio)

  const sensitivityTableQuery = trpc.dealSensitivity.tableRows.useQuery(
    {
      yearsToLiquidity: yearsToLiquiditySelected ?? defaultYearsToLiquidity,
      warehousedAssetReserveRatio: reserveRatioSelected ?? defaultReserveRatio,
    },
    { enabled: isSome(yearsToLiquiditySelected) && isSome(reserveRatioSelected) },
  )

  function onSliderPercentChangeChange(newValueArr: number[]) {
    if (newValueArr.length !== 1) {
      console.log('error: more than one value in slider')
      return
    }
    const newValue = newValueArr[0]
    setCompanyCAGR(possibleCompanyCAGRs[newValue])
  }

  function onSliderYearChange(newValueArr: number[]) {
    if (newValueArr.length !== 1) {
      console.log('custom error: more than one value in slider')
      return
    }
    const newValue = newValueArr[0]
    setYearsToLiquiditySelected(possibleSliderYears[newValue])
  }

  function onReserveRatioChange(newValue: string) {
    const index = possibleReserveRatioSelectionInputs.indexOf(newValue)
    setReserveRatioSelected(possibleReserveRatios[index])
  }
  function reserveRatioToSelectionInput(reserveRatio: number): string {
    const index = possibleReserveRatios.indexOf(reserveRatio)
    return possibleReserveRatioSelectionInputs[index]
  }

  const titleClass = 'text-base font-medium'

  if (isNone(companyCAGR) || isNone(yearsToLiquiditySelected) || isNone(reserveRatioSelected)) {
    return null
  } else {
    return (
      <div className="bg-white pt-6">
        <div className="mb-24 flex w-full flex-col items-center justify-start pl-4 pr-4">
          <div className={`items-left mt-8 flex w-full max-w-page-max flex-col justify-center `}>
            <div className="w-full text-3xl font-semibold">Deal Sensitivity</div>
            <div className="mb-5 mt-9 text-xl font-medium">Settings</div>
            <div className="flex w-4/5 flex-col flex-wrap space-y-6 lg:w-4/5 lg:flex-row lg:flex-nowrap lg:space-y-0">
              <div className="flex-1 lg:mr-12">
                <div className="mb-3 flex w-full justify-between">
                  <div className="text-base">Company CAGR</div>
                  <div
                    className={`text-base font-medium ${
                      companyCAGR === 0
                        ? 'text-func-black-0'
                        : companyCAGR < 0
                          ? 'text-tickers-red'
                          : 'text-tickers-green'
                    }`}>
                    {numberToPercentChangeString(companyCAGR, 0)}
                  </div>
                </div>
                <div className="z-10">
                  <Slider
                    defaultValue={[defaultCompanyCAGRIndex]}
                    onValueChange={(newValue) => onSliderPercentChangeChange(newValue)}
                    min={0}
                    max={possibleCompanyCAGRs.length - 1}
                    step={1}
                    size={'3'}
                    color="gray"
                    highContrast
                    className="z-20"
                  />
                </div>
                <div className="relative z-10">
                  <div
                    className="border-rounded absolute h-5 rounded-full border border-radix-outline"
                    style={{ left: `${breakevenCAGRPercent()}%`, marginLeft: '-1px', top: '-17px' }}></div>
                </div>
              </div>
              <div className="flex-1 lg:mr-12">
                <div className="mb-3 flex w-full justify-between text-base">
                  <div>Fund duration</div>
                  <div className="font-medium">{yearsToLiquiditySelected} years</div>
                </div>
                <Slider
                  defaultValue={[defaultYearsToLiquidityIndex]}
                  onValueChange={(newValue) => onSliderYearChange(newValue)}
                  min={0}
                  max={possibleSliderYears.length - 1}
                  step={1}
                  size={'3'}
                  color="gray"
                  highContrast
                />
              </div>
              <div className="flex w-full flex-col lg:w-28 lg:items-end">
                <div className="mb-2 text-base">Reserve ratio</div>
                <Select.Root
                  defaultValue={reserveRatioToSelectionInput(reserveRatioSelected)}
                  onValueChange={(newValue) => onReserveRatioChange(newValue)}
                  size="2">
                  <Select.Trigger radius="full" />
                  <Select.Content>
                    {possibleReserveRatioSelectionInputs.map((option) => (
                      <Select.Item value={String(option)} key={option}>
                        {option}
                      </Select.Item>
                    ))}
                  </Select.Content>
                </Select.Root>
              </div>
            </div>
            <div className="mt-9 text-xl font-medium">Plural Performance</div>
            <div className="mt-5">
              <DealSensitivityMetadata
                yearsToLiquidity={yearsToLiquiditySelected}
                warehousedAssetCAGR={companyCAGR}
                reserveRatio={reserveRatioSelected}
              />
            </div>
            <div className="mt-8">
              <div className={`${titleClass} mb-6`}>Plural Investment Value vs. Underlying Equity Value</div>
              <SensivityFundValueGraph
                warehousedAssetCAGR={companyCAGR}
                yearsToLiquidity={yearsToLiquiditySelected}
                reserveRatio={reserveRatioSelected}
              />
            </div>
            <div>
              <div className={`${titleClass} mb-3 mt-8`}>Company Equity Performance</div>
              <SensitivityEquityMetadata
                yearsToLiquidity={yearsToLiquiditySelected}
                warehousedAssetCAGR={companyCAGR}
                reserveRatio={reserveRatioSelected}
              />
            </div>
            <div className={`mb-6 mt-14 text-2xl font-medium`}>Sensitivity Table</div>
            <div className="flex w-full flex-col justify-start">
              <Table<SensitivityProfile>
                rowData={sensitivityTableQuery?.data ?? []}
                defaultRowCount={50}
                columns={columns}
                onRowClick={() => {}}
              />
            </div>
            <Disclaimer />
          </div>
        </div>
      </div>
    )
  }
}
