import { useEffect, useState } from 'react'
import CheckBox from './CheckBox'

export function BooleanCell({ isSelected, onSelect }: { isSelected: boolean; onSelect: (selected: boolean) => void }) {
  const [selected, setSelected] = useState(isSelected)

  useEffect(() => {
    setSelected(isSelected)
  }, [isSelected])

  return (
    <div className="p-2 pl-3">
      <CheckBox
        checked={selected}
        onCheckChange={(checked) => {
          setSelected(checked)
          onSelect(checked)
        }}
      />
    </div>
  )
}
