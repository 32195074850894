import { differenceInDays } from "date-fns";

export function dateToReadableTimeFromNow(date: Date): string {
  const now = new Date();
  const diffInDays = differenceInDays(date, now);
  if (diffInDays >= 365) {
    const yearsWithDecimal = (diffInDays / 365).toFixed(1);
    return `${yearsWithDecimal}y`;
  } else if (diffInDays >= 30) {
    const monthsWithDecimal = (diffInDays / 30).toFixed(0);
    return `${monthsWithDecimal}m`;
  } else {
    return `${diffInDays}d`;
  }
}
