import { AlertDialog, Button, Flex } from '@radix-ui/themes'

export type ConfirmationModalProps = {
  title: string
  body: string
  confirmText?: string
  onConfirm: () => void
  button?: React.ReactNode
  open?: boolean
  onOpenChange?: (open: boolean) => void
}
export default function ConfirmationModalButton(props: ConfirmationModalProps) {
  return (
    <AlertDialog.Root open={props.open} onOpenChange={props.onOpenChange}>
      {props.button && <AlertDialog.Trigger>{props.button}</AlertDialog.Trigger>}
      <AlertDialog.Content style={{ maxWidth: 450 }}>
        <AlertDialog.Title>{props.title}</AlertDialog.Title>
        <AlertDialog.Description size="2">{props.body}</AlertDialog.Description>
        <Flex gap="3" mt="4" justify="end">
          <AlertDialog.Cancel>
            <Button variant="soft" color="gray">
              Cancel
            </Button>
          </AlertDialog.Cancel>
          <AlertDialog.Action>
            <Button variant="soft" color="red" onClick={props.onConfirm} className="cursor-pointer">
              Confirm
            </Button>
          </AlertDialog.Action>
        </Flex>
      </AlertDialog.Content>
    </AlertDialog.Root>
  )
}
