export default function GraphStat({
  label,
  value,
  className,
}: {
  label: React.ReactNode
  value: React.ReactNode
  className?: string
}) {
  return (
    <div className={className ?? ''}>
      <div className={`text-base font-medium`}>{value}</div>
      <div className="text-xs text-black opacity-60 lg:text-sm">{label}</div>
    </div>
  )
}
