import {
  DEFAULT_FUND_MIN_CAGR,
  lookbackV2TxMultiple,
} from "../core/lookbackV2Contract";
import {
  SecondaryNetRateComparison,
  TxComparatorInputs,
  cashEquivalentLookbackMultipleOverSecondary,
  compareNetRates,
} from "../functions/cashAdjustedLookbackSharePriceMultiple";

export interface ValueShareSecondaryComparableTxGeneratorInputs {
  altInvestmentIrr: number;
  secondaryBrokerTakeRate: number;
  totalTaxRate: number;
  principal: number;
  reserveRatio: number;
  companySharesOutstanding: number;
  baseSharePrice: number;
  yearsToOutcome: number;
  companyCagrFromBase: number;
}

// really should split this into multiple generator underneath and tx generator here
export class ValueShareTxWithSecondaryComparable {
  private _inputs: ValueShareSecondaryComparableTxGeneratorInputs;
  private fundMinCagr: number;

  constructor(inputs: ValueShareSecondaryComparableTxGeneratorInputs) {
    this._inputs = inputs;
    this.fundMinCagr = DEFAULT_FUND_MIN_CAGR;
  }

  /*
  something is going on here
  the
  */

  pluralCagr(): number {
    return (
      Math.pow(
        // (this.companyMultiple() * this._inputs.baseSharePrice) /
        //   this.txSharePrice(),
        (this.sharesOwed() * this.endSharePrice()) / this.grossTxValue(),
        1 / this._inputs.yearsToOutcome
      ) - 1
    );
  }

  endSharePrice(): number {
    return this._inputs.baseSharePrice * this.companyMultiple();
  }

  inputs(): ValueShareSecondaryComparableTxGeneratorInputs {
    return this._inputs;
  }

  baseSharesOwed(): number {
    return this._inputs.principal / this._inputs.baseSharePrice;
  }

  maxSharesOwed(): number {
    return this.baseSharesOwed() * this._inputs.reserveRatio;
  }

  txSharePrice(): number {
    return this.txMultiple() * this._inputs.baseSharePrice;
  }

  sharesOwed(): number {
    return Math.min(
      this._inputs.principal / this.txSharePrice(),
      this.maxSharesOwed()
    );
  }

  sharesReserved(): number {
    return this.baseSharesOwed() * this._inputs.reserveRatio;
  }

  txCompanyValuation(): number {
    return this.txSharePrice() * this._inputs.companySharesOutstanding;
  }

  cashAdjustedTxCompanyValuation(): number {
    return (
      this.cashAdjustedTxSharePrice() * this._inputs.companySharesOutstanding
    );
  }

  cashAdjustedTxSharePrice(): number {
    return (
      this.txSharePrice() * this.cashEquivalentLookbackMultipleOverSecondary()
    );
  }

  minTxMultiplePaid(): number {
    return 1 / this._inputs.reserveRatio;
  }

  txMultiple(): number {
    return lookbackV2TxMultiple(
      this._inputs.yearsToOutcome,
      this._inputs.companyCagrFromBase,
      this.fundMinCagr,
      this._inputs.reserveRatio
    );
  }

  grossTxValue(): number {
    return this.txSharePrice() * this.sharesOwed();
  }

  txCashAdjustedSharePricePercentDeltaFromBase(): number {
    return (
      (this.cashAdjustedTxSharePrice() - this._inputs.baseSharePrice) /
      this._inputs.baseSharePrice
    );
  }

  txComparatorInputs(): TxComparatorInputs {
    return {
      altInvestmentIrr: this._inputs.altInvestmentIrr,
      yearsToOutcome: this._inputs.yearsToOutcome,
      secondaryBrokerTakeRate: this._inputs.secondaryBrokerTakeRate,
      totalTaxRate: this._inputs.totalTaxRate,
    };
  }

  netRateComparison(): SecondaryNetRateComparison {
    return compareNetRates(this.txComparatorInputs());
  }

  cashEquivalentLookbackMultipleOverSecondary(): number {
    return cashEquivalentLookbackMultipleOverSecondary(
      this.txComparatorInputs()
    );
  }

  companyOutcomeValuation(): number {
    return (
      this._inputs.baseSharePrice *
      this._inputs.companySharesOutstanding *
      this.companyMultiple()
    );
  }

  companyMultiple(): number {
    return Math.pow(
      1 + this._inputs.companyCagrFromBase,
      this._inputs.yearsToOutcome
    );
  }
}
