import { useState, useCallback } from 'react'
import { trpc } from '../utils/trpc'
import { ChangeEvent } from 'react'
import { BasicButton } from '../components/BasicButton'
import PrivateEquityView from './PrivateEquityView'

export default function PrivateEquityFundFileUploadPage() {
  const [fileEncodedString, setFileEncodedString] = useState('')
  const [filename, setFilename] = useState('')
  const [submitMessage, setSubmitMessage] = useState('')

  const submitMutation = trpc.fundFileRouter.handlePrivateEquityFileUpload.useMutation()

  const handleFileChange = useCallback((encodedString: string, name: string) => {
    setFileEncodedString(encodedString)
    setFilename(name)
  }, [])

  const onSubmit = useCallback(() => {
    setSubmitMessage('')
    if (fileEncodedString && filename) {
      submitMutation.mutate(
        { fileEncodedString, filename },
        {
          onError: (err) => {
            setSubmitMessage(
              err.message.includes('duplicate')
                ? 'This file has already been uploaded before.'
                : "We couldn't process that file, but we're looking into it. Please try another file."
            )
          },
        }
      )
    }
  }, [fileEncodedString, filename, submitMutation])

  return (
    <div className="relative">
      <div className="ml-6 mr-6 mt-6 flex flex-col items-center">
        <div className="w-full max-w-page-max text-base flex justify-center">
          <div className="w-full max-w-form-width">
            <FileUploadForm
              onFileChange={handleFileChange}
              onSubmit={onSubmit}
              isSubmitDisabled={!fileEncodedString || submitMutation.isLoading}
            />
            {submitMessage && <div className="mt-4 text-red-500">{submitMessage}</div>}
          </div>
        </div>

        {submitMutation.isLoading && <div>Loading...</div>}

        {submitMutation.isSuccess && submitMutation.data && (
          <>
            {submitMutation.data && (
              <PrivateEquityView data={submitMutation.data} isLoading={submitMutation.isLoading} />
            )}
          </>
        )}
      </div>
    </div>
  )
}

interface FileUploadFormProps {
  onFileChange: (encodedString: string, filename: string) => void
  onSubmit: () => void
  isSubmitDisabled: boolean
}

function FileUploadForm({ onFileChange, onSubmit, isSubmitDisabled }: FileUploadFormProps) {
  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files?.[0]
    if (selectedFile) {
      const reader = new FileReader()
      reader.onloadend = () => {
        const base64String = reader.result as string
        onFileChange(base64String, selectedFile.name)
      }
      reader.readAsDataURL(selectedFile)
    }
  }


  return (
    <div className="w-full max-w-form-width">
      <div className="text-3xl font-semibold">Extract financial metrics</div>
      <div className="mt-4 w-full text-base">
        <div className="mb-2 mt-4 text-sm font-medium">File</div>
        <input type="file" accept=".pdf" required onChange={handleFileChange} />
      </div>
      <div className="h-5" />
      <BasicButton
        onClick={onSubmit}
        textSize="text-base"
        textHeight={20}
        className="w-full"
        isPressable={!isSubmitDisabled}
      >
        Submit
      </BasicButton>
    </div>
  )
}