import { Button, Dialog, Flex, TextField } from '@radix-ui/themes'
import { useState } from 'react'
import { isSome } from 'safety'
import { useVanillaTRPC } from '../../providers/TRPCProvider'
import { RouterOutputs } from '../../utils/trpc'

/*
domain
name
*/

enum InputFormat {
  NUMBER = 'NUMBER',
  TEXT = 'TEXT',
}

function InputLabel({ title }: { title: string }) {
  return <div className="mb-1 text-sm">{title}</div>
}

function TextInput({
  label,
  placeholder,
  value,
  setValue,
  inputFormat,
}: {
  label: string
  placeholder?: string
  value: string
  setValue: (value: string) => void
  inputFormat: InputFormat
}) {
  return (
    <div className="mt-2 w-full">
      <InputLabel title={label} />
      <TextField.Root>
        <TextField.Input
          placeholder={placeholder ?? ''}
          value={value}
          onChange={(e) => {
            if (inputFormat === InputFormat.NUMBER) {
              setValue(e.target.value.replace(/[^0-9.]/g, ''))
            } else {
              setValue(e.target.value)
            }
          }}
        />
      </TextField.Root>
    </div>
  )
}

type Company = RouterOutputs['company']['create']

export type CreateableModalProps = {
  onCreate: (newValue: any) => void
}

export default function CreateCompanyModal({ onCreate }: { onCreate: (newCompany: Company) => void }) {
  const [domain, setDomain] = useState('')
  const [name, setName] = useState('')
  const [foundingDate, setFoundingDate] = useState('')
  const vanillaClient = useVanillaTRPC()
  const [isOpen, setIsOpen] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)

  function foundingDateFromText() {
    let cast = new Date(foundingDate)
    let isValid = !isNaN(cast.getTime())
    if (isValid) {
      return cast
    }
  }

  function submitMetric() {
    if (domain.length === 0) {
      return
    }
    let date = foundingDateFromText()
    vanillaClient.company.create
      .mutate({
        domain,
        name,
        foundingDate: isSome(date) ? date?.toISOString() : undefined,
      })
      .then((newCompany) => {
        setIsOpen(false)
        onCreate(newCompany)
      })
    setIsSubmitting(true)
  }

  return (
    <Dialog.Root open={isOpen}>
      <Dialog.Trigger>
        <button className="text-sm font-medium" onClick={() => setIsOpen(true)}>
          + Create New
        </button>
      </Dialog.Trigger>
      <Dialog.Content style={{ maxWidth: 450 }}>
        <Dialog.Title>Create Company</Dialog.Title>
        {isSubmitting ? (
          <div>Submitting...</div>
        ) : (
          <div className="w-full">
            <div className="flex w-full flex-col space-y-4">
              <TextInput
                label="Name"
                placeholder="Company"
                value={name}
                setValue={setName}
                inputFormat={InputFormat.TEXT}
              />
              <TextInput
                label="Domain"
                placeholder="example.com"
                value={domain}
                setValue={setDomain}
                inputFormat={InputFormat.TEXT}
              />
              <TextInput
                label="Founding date (optional)"
                placeholder="00/00/0000"
                value={foundingDate}
                setValue={setFoundingDate}
                inputFormat={InputFormat.TEXT}
              />
            </div>
            <Flex gap="3" mt="4" justify="end">
              <Button variant="soft" color="gray" onClick={() => setIsOpen(false)}>
                Cancel
              </Button>
              <div className="cursor-pointer">
                <Button variant="soft" color="blue" onClick={submitMetric} className="cursor-pointer">
                  Confirm
                </Button>
              </div>
            </Flex>
          </div>
        )}
      </Dialog.Content>
    </Dialog.Root>
  )
}
