import { ColumnDef, createColumnHelper, FilterFnOption, SortingFnOption } from '@tanstack/react-table'
import EditableTanstackTable from 'components/editable-tanstack-table/EditableTanstackTable'
import { PluralLink } from 'providers/NavigationProvider'
import { usePluralAuth } from 'providers/PluralAuthProvider'
import Breadcrumbs from 'several/components/Breadcrumbs'
import { trpc } from 'utils/trpc'

type ProcessedList = {
  id: number
  name: string
  entityType: string
  followers: number
  createdBy: string
}
export default function ListsPage() {
  let { user } = usePluralAuth()
  let allSheetsQuery = trpc.sheetRouter.getAllSheetsForList.useQuery()
  let allSheets = allSheetsQuery.data ?? []
  let columnHelper = createColumnHelper<ProcessedList>()

  let cellClassName = 'max-w-full truncate p-2'
  function processSheets(sheets: any[]) {
    return sheets.map((sheet) => {
      return {
        id: sheet.id,
        name: sheet.name,
        entityType: sheet.entityType,
        followers: sheet.userToSheets.filter((userToSheet: any) => userToSheet.role === 'FOLLOWER').length,
        createdBy: sheet.userToSheets.find((userToSheet: any) => userToSheet.role === 'OWNER')?.user.email ?? '',
      }
    })
  }
  let columns: ColumnDef<ProcessedList>[] = [
    columnHelper.accessor('name', {
      id: 'name',
      header: 'Name',
      enableGlobalFilter: true,
      cell: (cell) => {
        return (
          <div className={cellClassName}>
            <PluralLink
              href={`/dashboard/sheets/${cell.row.original.entityType.toLowerCase() === 'company' ? 'company' : 'funding'}/${cell.row.original.id}`}>
              {cell.getValue()}
            </PluralLink>
          </div>
        )
      },
      filterFn: 'stringFn' as FilterFnOption<ProcessedList>,
      sortingFn: 'stringFn' as SortingFnOption<ProcessedList>,
      meta: {
        className: 'z-30 sticky left-0 bg-white',
        headerClassName: 'z-40 sticky left-0 bg-white',
      },
    }) as ColumnDef<ProcessedList>,
    columnHelper.accessor('entityType', {
      id: 'entityType',
      header: 'Entity Type',
      cell: (cell) => {
        return <div className={cellClassName}>{cell.getValue() === 'COMPANY' ? 'Company' : 'Funding'}</div>
      },
      filterFn: 'singleSelectFn' as FilterFnOption<ProcessedList>,
      sortingFn: 'stringFn' as SortingFnOption<ProcessedList>,
      meta: {
        singleSelectOptions: [
          { label: 'Company', value: 'COMPANY' },
          { label: 'Funding', value: 'FUNDING_ROUND' },
        ],
        singleSelectPlaceholder: 'Select Entity Type',
      },
    }) as ColumnDef<ProcessedList>,
    columnHelper.accessor('followers', {
      id: 'followers',
      header: 'Followers',
      cell: (cell) => {
        return <div className={cellClassName}>{cell.getValue()}</div>
      },
      filterFn: 'numberFn' as FilterFnOption<ProcessedList>,
      sortingFn: 'numberFn' as SortingFnOption<ProcessedList>,
    }) as ColumnDef<ProcessedList>,
    columnHelper.accessor('createdBy', {
      id: 'createdBy',
      enableGlobalFilter: true,
      header: 'Created By',
      cell: (cell) => {
        return (
          <div className={cellClassName}>
            {cell.getValue() === user?.email && cell.getValue() ? 'you' : cell.getValue()}
          </div>
        )
      },
      filterFn: 'stringFn' as FilterFnOption<ProcessedList>,
      sortingFn: 'stringFn' as SortingFnOption<ProcessedList>,
    }) as ColumnDef<ProcessedList>,
  ]

  return (
    <div>
      <EditableTanstackTable
        tableNameNode={
          <Breadcrumbs
            crumbs={[
              {
                title: 'Public Lists',
              },
            ]}
          />
        }
        defaultSorting={{ id: 'name', desc: true }}
        columns={columns}
        stickyHeader
        data={processSheets(allSheets)}
        sortingFns={{}}
        fullPage
        borders="top"
        maxHeightStyle="calc(100vh - 190px)"
      />
    </div>
  )
}
