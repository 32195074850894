import { Auth0Provider as ImportedAuth0Provider } from '@auth0/auth0-react'
import { useNavigate } from 'react-router-dom'
import { AUTH0_AUDIENCE, AUTH0_CLIENT_ID, AUTH0_CUSTOM_DOMAIN } from 'plural-shared/constants'

// using local storage for now, can switch to refresh tokens later
// useRefreshTokens={true}
export const Auth0Provider = ({ children }: any) => {
  const navigate = useNavigate()

  const onRedirectCallback = (appState: any) => {
    if (appState?.returnTo === '/') {
      appState.returnTo = '/'
    }
    navigate(appState?.returnTo || window.location.pathname)
  }

  return (
    <ImportedAuth0Provider
      domain={AUTH0_CUSTOM_DOMAIN}
      clientId={AUTH0_CLIENT_ID}
      authorizationParams={{
        redirect_uri: window.location.origin,
        connection: 'email',
        audience: AUTH0_AUDIENCE,
      }}
      cacheLocation="localstorage"
      onRedirectCallback={onRedirectCallback}>
      {children}
    </ImportedAuth0Provider>
  )
}

export default Auth0Provider
