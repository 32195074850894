import { CreateableModalProps } from 'pages/company/CreateCompanyModal'
import { TextInputSelectOption } from 'pages/company/TextInputSelect'
import { isSome, Optional } from 'safety'
import { EditableTextCellFormat } from './EditableTextCellFormat'
import { CompanyMetric } from 'plural-trpc/routers/companiesRouter'

// T is rowtype, V is cell value type, N is created object type

export type CreateColumnProps<V, N> = {
  defaultOption?: V
  createdObjectKey: keyof N
  isRequired: boolean
}

export type ColumnInputTypeStr = 'input' | 'multiSelect' | 'boolean' | 'autoComplete'

export enum ColumnInputType {
  NonEditableInput = 'NonEditableInput',
  EditableInput = 'EditableInput',
  EditableMultiSelect = 'EditableMultiSelect',
  EditableBoolean = 'EditableBoolean',
  EditableAutoComplete = 'EditableAutoComplete',
}

export type ColumnProps<T, V, N> = {
  inputType: ColumnInputTypeStr
  header: string
  accessor: (row: T) => Optional<V>
  minWidthPixels?: number
  createProps?: CreateColumnProps<V, N>
  updateRemote?: (row: T, newValue: Optional<V>) => Promise<void>
}

export type EditableMultiSelectColumnProps<T, N> = ColumnProps<T, string, N> & {
  updateRemote: (row: T, newValue: string) => Promise<void>
  options: string[] | ((row: T) => string[])
}

export type EditableAutoCompleteColumnProps<T, N> = ColumnProps<T, TextInputSelectOption, N> & {
  updateRemote: (row: T, newValue: TextInputSelectOption) => Promise<void>
  autoCompleteOptions: TextInputSelectOption[]
  placeholder: string
} & (
    | {
        createModal: (props: CreateableModalProps) => React.ReactNode
        onCreate: (newObject: any) => TextInputSelectOption
      }
    | { createModal?: never; onCreate?: never }
  )

export type TextColumnProps<T, V, N> = ColumnProps<T, V, N> & {
  placeholder?: string
  textCellFormat: EditableTextCellFormat<V>
}

export type EditableBooleanColumnProps<T, N> = ColumnProps<T, boolean, N> & {
  createProps?: CreateColumnProps<boolean, N>
  updateRemote: (row: T, newValue: boolean) => Promise<void>
}

export type EditableTextColumnProps<T, V, N> = TextColumnProps<T, V, N> & {
  updateRemote: (row: T, newValue: Optional<V>) => Promise<void>
}

export function isColumnEditableMultiSelect<T, N>(
  columnProps: AnyColumnProps<T, N>,
): columnProps is EditableMultiSelectColumnProps<T, N> {
  return (columnProps as EditableMultiSelectColumnProps<T, N>).options !== undefined
}

export function isColumnEditableAutocomplete<T, N>(
  columnProps: AnyColumnProps<T, N>,
): columnProps is EditableAutoCompleteColumnProps<T, N> {
  return (columnProps as EditableAutoCompleteColumnProps<T, N>).autoCompleteOptions !== undefined
}

export function isColumnEditableText<T, N>(
  columnProps: AnyColumnProps<T, N>,
): columnProps is TextColumnProps<T, any, N> {
  return (
    columnProps.inputType === 'input' &&
    isSome((columnProps as TextColumnProps<T, any, N>).updateRemote) &&
    isSome((columnProps as TextColumnProps<T, any, N>).textCellFormat)
  )
}

export function isColumnEditableBoolean<T, N>(
  columnProps: AnyColumnProps<T, N>,
): columnProps is EditableBooleanColumnProps<T, N> {
  return columnProps.inputType === 'boolean' && isSome((columnProps as ColumnProps<T, boolean, N>).updateRemote)
}

export function isColumnEditable<T, N>(
  columnProps: AnyColumnProps<T, N>,
): columnProps is EditableBooleanColumnProps<T, N> {
  return columnProps.inputType === 'boolean' && isSome((columnProps as ColumnProps<T, boolean, N>).updateRemote)
}

export function isColumnCreateable<T, N>(column: AnyColumnProps<T, N>): boolean {
  return isSome((column as AnyColumnProps<T, N>).createProps)
}

export type AnyTextColumnProps<T, N> =
  | TextColumnProps<T, string, N>
  | TextColumnProps<T, number, N>
  | TextColumnProps<T, Date, N>
  | TextColumnProps<T, CompanyMetric, N>
  | TextColumnProps<T, string[], N>

export type AnyEditableTextColumnProps<T, N> =
  | EditableTextColumnProps<T, string, N>
  | EditableTextColumnProps<T, number, N>
  | EditableTextColumnProps<T, Date, N>
  | EditableTextColumnProps<T, CompanyMetric, N>
  | EditableTextColumnProps<T, string[], N>

export type AnyColumnProps<T, N> =
  | EditableMultiSelectColumnProps<T, N>
  | AnyTextColumnProps<T, N>
  | AnyEditableTextColumnProps<T, N>
  | EditableBooleanColumnProps<T, N>
  | EditableAutoCompleteColumnProps<T, N>

export function columnPropsInputType<T, N>(columnProps: AnyColumnProps<T, N>): ColumnInputType {
  if (isColumnEditableMultiSelect(columnProps)) {
    return ColumnInputType.EditableMultiSelect
  } else if (isColumnEditableBoolean(columnProps)) {
    return ColumnInputType.EditableBoolean
  } else if (isColumnEditableText(columnProps)) {
    return ColumnInputType.EditableInput
  } else if (isColumnEditableAutocomplete(columnProps)) {
    return ColumnInputType.EditableAutoComplete
  }
  return ColumnInputType.NonEditableInput
}
