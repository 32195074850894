'use client'

import { Button } from 'components/ui/button'
import { Calendar } from 'components/ui/calendar'
import { Input } from 'components/ui/input'
import { Popover, PopoverContent, PopoverTrigger } from 'components/ui/popover'
import { format, isValid, parse } from 'date-fns'
import { cn } from 'lib/utils'
import { CalendarDays } from 'lucide-react'
import * as React from 'react'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { isMatch, Matcher } from 'react-day-picker'

export function DatePicker({
  date,
  setDate,
  maxDate,
  minDate,
  asTextField,
  miniTextField,
  fullWidth = true,
  disabled = false,
}: {
  date?: Date
  setDate: (date?: Date) => void
  maxDate?: Date
  minDate?: Date
  asTextField?: boolean
  miniTextField?: boolean
  fullWidth?: boolean
  disabled?: boolean
}) {
  const matchers: Matcher[] = useMemo(
    () => [...(minDate ? [{ before: minDate }] : []), ...(maxDate ? [{ after: maxDate }] : [])],
    [maxDate, minDate],
  )

  const dateIsEnabled = useCallback(
    (d: Date) => {
      return !matchers.length || !isMatch(d, matchers)
    },
    [matchers],
  )

  useEffect(() => {
    // Validate the date against the matchers, clear if invalid
    if (date && !dateIsEnabled(date)) {
      setDate(undefined)
    }
  }, [date, dateIsEnabled, setDate])

  const [open, setOpen] = useState(false)
  const [inputValue, setInputValue] = useState<string>('')
  const inputRef = React.useRef<HTMLInputElement>(null)

  const onCalendarSelect = (date?: Date) => {
    setDate(date)
    setInputValue(date ? format(date, 'MM/dd/yyyy') : '')
    setOpen(false)
  }

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.currentTarget.value)
  }

  const onFinishInput = () => {
    if (!inputValue) {
      setDate(undefined)
      return
    }

    const inputDate = parse(inputValue, 'MM/dd/yyyy', new Date())
    if (isValid(inputDate) && dateIsEnabled(inputDate)) {
      setDate(inputDate)
    } else {
      const dateCopy = date ? new Date(date) : undefined
      setDate(dateCopy)
    }
  }

  const onInputKeydown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      inputRef.current?.blur()
    }
  }

  useEffect(() => {
    setInputValue(date ? format(date, 'MM/dd/yyyy') : '')
  }, [date])

  const inputIsValid = useMemo(() => {
    const parsed = parse(inputValue, 'MM/dd/yyyy', new Date())
    return inputValue && isValid(parsed) && dateIsEnabled(parsed)
  }, [dateIsEnabled, inputValue])

  return (
    <div
      className={
        asTextField
          ? `focus-within:border-primary-500 focus-within:ring-primary-500 flex shrink-0 flex-row  items-stretch focus:ring-2 focus:ring-opacity-50`
          : 'flex flex-row items-stretch'
      }
      style={
        asTextField
          ? {
              background: 'white',
              borderRadius: '4px',
              boxShadow: '0 0 0 1px var(--gray-a7)',
              boxSizing: 'border-box',
              cursor: disabled ? 'not-allowed' : 'text',
              pointerEvents: disabled ? 'none' : 'auto',
              opacity: disabled ? 0.5 : 1,
              width: miniTextField ? '190px' : fullWidth ? '100%' : '303px',
            }
          : {}
      }>
      <Input
        ref={inputRef}
        type="text"
        value={inputValue}
        style={asTextField ? { border: 'none' } : {}}
        onChange={onInputChange}
        onKeyDown={onInputKeydown}
        onBlur={onFinishInput}
        className={cn(
          asTextField
            ? `relative h-[32px]  min-w-[${miniTextField ? '125px' : '303px'}] flex-1 bg-white p-2 text-sm text-sm font-normal hover:bg-white`
            : 'block max-w-[130px] bg-plural-sand-100 px-2 font-medium hover:bg-plural-sand-200',
          !inputIsValid && 'text-muted-foreground',
        )}
      />
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button size="icon" variant="ghost" className="h-auto text-plural-sand-600">
            <CalendarDays size="14px" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0">
          <Calendar
            mode="single"
            selected={date}
            defaultMonth={date}
            onSelect={onCalendarSelect}
            initialFocus
            captionLayout="dropdown-buttons"
            fromYear={1990}
            toYear={2053}
            disabled={matchers}
          />
        </PopoverContent>
      </Popover>
    </div>
  )
}
