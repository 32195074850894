import { autoDetermineBaseSharePriceWithDates } from "../core/lookbackV2Contract";

export function autoDetermineBaseSharePriceWithYears(
  yearsSinceMostRecentFinancingEvent: number,
  mostRecentFinancingEventIssuePrice: number,
  yearsUntilNextFinancingEvent: number,
  nextFinancingEventIssuePrice: number,
  minimumCagr: number
) {
  let today = new Date();
  let mostRecentFinancingEventDate = new Date(
    today.getTime() -
      yearsSinceMostRecentFinancingEvent * 1000 * 60 * 60 * 24 * 365.25
  );
  let nextFinancingEventDate = new Date(
    today.getTime() +
      yearsUntilNextFinancingEvent * 1000 * 60 * 60 * 24 * 365.25
  );
  return autoDetermineBaseSharePriceWithDates(
    today,
    mostRecentFinancingEventDate,
    mostRecentFinancingEventIssuePrice,
    nextFinancingEventDate,
    nextFinancingEventIssuePrice,
    minimumCagr
  );
}
