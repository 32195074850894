import { ValueShareFundSettings } from "../valueShareFundSettings";

const yearsToLiquidity = 6;

export const defaultLPInterestFundSettings: ValueShareFundSettings = {
  grossFundInterest: 1_000_000,
  fundLifetime: 10,
  managerFee: 0.02,
  yearsToLiquidity: yearsToLiquidity,
  reserveRatio: 3,
  fundMinCagr: 0.125,
};
