import {
  closestCenter,
  DndContext,
  DraggableAttributes,
  DragOverlay,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities'
import { restrictToParentElement, restrictToVerticalAxis } from '@dnd-kit/modifiers'
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { DragHandleDots2Icon, MagnifyingGlassIcon } from '@radix-ui/react-icons'
import { DropdownMenu } from '@radix-ui/themes'
import { ColumnDef } from '@tanstack/react-table'
import AppTextField from 'components/AppTextfield'
import { attioStyleString } from 'components/BasicButton'
import { Dropdown } from 'components/Dropdown'
import CheckBox from 'components/editable-table/CheckBox'
import { Settings } from 'lucide-react'
import React, { PropsWithChildren, useMemo, useState } from 'react'
import { createPortal } from 'react-dom'
import { useIsSmallScreen } from 'several/hooks/navigation'

export default function ColumnsDropdown<T>(props: {
  columns: ColumnDef<T>[]
  columnVisibility: { [field: string]: boolean }
  setColumnVisibility: React.Dispatch<React.SetStateAction<{ [field: string]: boolean }>>
  columnOrder: string[]
  setColumnOrder: (columnOrder: string[]) => void
}) {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  )
  let isSmallScreen = useIsSmallScreen()
  let sortedColumns = useMemo(() => {
    return props.columns.sort((a, b) => props.columnOrder.indexOf(a.id!) - props.columnOrder.indexOf(b.id!))
  }, [props.columns, props.columnOrder])

  let sortedColumnsIds = useMemo(() => {
    return sortedColumns.map((column) => column.id ?? '')
  }, [sortedColumns])
  let [activeId, setActiveId] = useState<string | null>(null)
  let [hideUnchecked, setHideUnchecked] = useState(false)
  let [search, setSearch] = useState('')

  return (
    <Dropdown
      portal
      align="end"
      button={
        <div className={attioStyleString}>
          {isSmallScreen ? '' : 'Columns'}
          <Settings className="h-[15px]" />
        </div>
      }>
      <DropdownMenu.Item>
        <div className="flex min-w-[300px] max-w-[300px] flex-col gap-1">
          <div className="flex flex-row items-center justify-between">
            <div className="pb-[-5px] pl-2 pt-2 text-sm font-medium text-gray-500">Columns</div>
            <div className="flex items-center gap-1 text-sm text-gray-400">
              only visible columns?
              <CheckBox checked={hideUnchecked} onCheckChange={setHideUnchecked}></CheckBox>
            </div>
          </div>
          <AppTextField
            variant={'underlined'}
            placeholder="Search"
            fullWidth
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            startAdornment={
              <div className="flex h-[20px] items-center">
                <MagnifyingGlassIcon />
              </div>
            }
          />
          <DndContext
            modifiers={[restrictToVerticalAxis]}
            collisionDetection={closestCenter}
            sensors={sensors}
            onDragStart={(event) => {
              setActiveId(event.active.id as string)
            }}
            onDragEnd={(event) => {
              setActiveId(null)
              const { active, over } = event
              if (active.id !== over?.id && over?.id) {
                const oldIndex = sortedColumnsIds.indexOf(active.id as string)
                const newIndex = sortedColumnsIds.indexOf(over.id as string)
                let newArray = arrayMove(sortedColumnsIds, oldIndex, newIndex)
                props.setColumnOrder(newArray)
                return newArray
              }
            }}>
            <SortableContext
              strategy={verticalListSortingStrategy}
              items={sortedColumns.map((column) => column.id ?? '')}>
              <div className="flex max-h-[300px] flex-col gap-[1px] overflow-y-auto">
                {sortedColumns
                  .filter(
                    (column) =>
                      !(column?.meta?.isImmovable ?? false) &&
                      (!hideUnchecked || props.columnVisibility[column.id ?? ''] !== false) &&
                      (column?.header?.toString() ?? '').toLowerCase().includes(search.toLowerCase()),
                  )
                  .map((column, index) => {
                    const isVisible = props.columnVisibility[column.id ?? ''] !== false
                    return (
                      <SortableColumn
                        pauseSorting={search.length > 0}
                        isActive={activeId === column.id}
                        columnHeader={column.header?.toString() ?? ''}
                        isUnhideable={column.meta?.isUnhideable ?? false}
                        key={column.id ?? `index-${index}`}
                        id={column.id ?? `index-${index}`}
                        checked={isVisible}
                        onCheckChange={() => {
                          props.setColumnVisibility((prev) => ({
                            ...prev,
                            [column.id ?? '']: !isVisible,
                          }))
                        }}>
                        <div
                          key={column.id ?? `index-${index}`}
                          className="flex flex-row items-center justify-between gap-3 p-2">
                          <div className="flex flex-row items-center gap-2 text-sm">
                            <div>
                              <DragHandleDots2Icon />
                            </div>
                            {column.header?.toString()}
                          </div>
                          {!column.meta?.isUnhideable && (
                            <CheckBox
                              checked={isVisible}
                              onCheckChange={(newValue) => {
                                props.setColumnVisibility((prev) => ({
                                  ...prev,
                                  [column.id ?? '']: newValue,
                                }))
                              }}
                            />
                          )}
                        </div>
                      </SortableColumn>
                    )
                  })}
              </div>
            </SortableContext>
            {createPortal(
              <DragOverlay modifiers={[restrictToParentElement]}>
                {activeId ? (
                  <div>
                    <Column
                      id={activeId}
                      checked={props.columnVisibility[activeId] !== false}
                      onCheckChange={(newValue) => {
                        props.setColumnVisibility((prev) => ({
                          ...prev,
                          [activeId as string]: newValue,
                        }))
                      }}
                      isUnhideable={sortedColumns.find((column) => column.id === activeId)?.meta?.isUnhideable ?? false}
                      columnHeader={sortedColumns.find((column) => column.id === activeId)?.header?.toString() ?? ''}
                    />
                  </div>
                ) : null}
              </DragOverlay>,
              document.body,
            )}
          </DndContext>
        </div>
      </DropdownMenu.Item>
    </Dropdown>
  )
}

function SortableColumn(
  props: PropsWithChildren<{
    id: string
    isUnhideable: boolean
    checked: boolean
    onCheckChange: (newValue: boolean) => void
    columnHeader: string
    isActive?: boolean
    pauseSorting?: boolean
  }>,
) {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: props.id })

  const style: React.CSSProperties = {
    transform: CSS.Transform.toString(transform),
    transition,
    visibility: props.isActive ? 'hidden' : 'visible',
  }

  return (
    <div ref={setNodeRef} style={style}>
      <Column {...props} listeners={listeners} attributes={attributes} />
    </div>
  )
}

function Column(
  props: PropsWithChildren<{
    listeners?: SyntheticListenerMap
    attributes?: DraggableAttributes
    id: string
    isUnhideable: boolean
    checked: boolean
    onCheckChange: (newValue: boolean) => void
    columnHeader: string
    pauseSorting?: boolean
  }>,
) {
  return (
    <div>
      <div key={props.id} className="flex flex-row items-center justify-between gap-3 p-2">
        <div className="flex flex-row items-center gap-2 text-sm">
          <div
            {...(props.listeners && !props.pauseSorting ? props.listeners : {})}
            {...(props.attributes && !props.pauseSorting ? props.attributes : {})}>
            {!props.pauseSorting && <DragHandleDots2Icon />}
          </div>
          {props.columnHeader}
        </div>
        {!props.isUnhideable && <CheckBox checked={props.checked} onCheckChange={props.onCheckChange} />}
      </div>
    </div>
  )
}
