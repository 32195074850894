import { ColumnDef, createColumnHelper, FilterFnOption, SortingFnOption } from '@tanstack/react-table'
import { ChevronDown, ChevronRight } from 'lucide-react'
import { MassiveCompany, processRawMassiveCompany, RawMassiveCompany } from 'pages/admin/AdminCompanySheetPage'
import SheetPage from 'pages/admin/AdminSheetPage'
import {
  numberToAbbreviatedDollarString,
  numberToReadableDollarString,
  numberToReadableString,
} from 'plural-shared/utils'
import { PluralLink } from 'providers/NavigationProvider'
import { usePluralAuth } from 'providers/PluralAuthProvider'
import { useMemo } from 'react'
import { ArrayElement, isSome, Optional } from 'safety'
import { RouterOutputs, trpc } from 'utils/trpc'
import { cellClassName, CompanyAvatar } from './FundingRoundSheetPage'

type RouterCompanyRow = ArrayElement<RouterOutputs['fundFileRouter']['getAllFundCompanyDetails']['companies']> & {
  companyData?: RawMassiveCompany[]
}
type ProcessedCompanyRow = ArrayElement<RouterOutputs['fundFileRouter']['getAllFundCompanyDetails']['companies']> &
  Optional<MassiveCompany>
// & {
//   timeFromMarkToLastFundingRound: string
//   headcountGrowthSinceMark: number
// }

// function getTimeFromMarkToLastFundingRound(markDate: Date, lastFundingRoundDate: Date | undefined) {
//   if (lastFundingRoundDate) {
//     const suffix = markDate > lastFundingRoundDate ? ' before' : ' after'
//     return (
//       formatDuration(intervalToDuration({ start: markDate, end: lastFundingRoundDate }), {
//         format: ['years', 'months'],
//       }) + suffix
//     )
//   }
//   return ''
// }

export default function HoldingsSheetPage(props: { isLoading?: boolean }) {
  const { isLoading: isAuthLoading } = usePluralAuth()
  let getAllFundCompanyDetails = trpc.fundFileRouter.getAllFundCompanyDetails.useQuery(undefined, {
    enabled: !isAuthLoading,
  })
  let indicationQuery = trpc.indications.allIndicationsQuery.useQuery(undefined, {
    enabled: !isAuthLoading,
  })

  function calculateHeadcountGrowthSinceMark(data: any[], markDate: string): number {
    const markDateObj = new Date(markDate)

    const sortedData = data.sort((a, b) => new Date(a.statsDate).getTime() - new Date(b.statsDate).getTime())
    const headCountAtMark = sortedData.find(
      (point) => new Date(point.statsDate).getDate() === markDateObj.getDate(),
    )?.count

    if (headCountAtMark === undefined) {
      return 0
    }
    const latestHeadCount = sortedData[sortedData.length - 1]?.count ?? 0
    const growthRate = (100 * (latestHeadCount - headCountAtMark)) / headCountAtMark
    return Math.round(growthRate * 100) / 100
  }

  // function processCompanyRows(data: RouterCompanyRow[], date_of_mark: string): ProcessedCompanyRow[] {
  function processCompanyRows(data: RouterCompanyRow[]): ProcessedCompanyRow[] {
    return data
      .map((row: RouterCompanyRow) => {
        let rawMassiveCompany = (row.companyData ?? [])[0]
        let company = {} as MassiveCompany
        // let timeFromMarkToLastFundingRound = ''
        // let headcountGrowthSinceMark = 0
        if (rawMassiveCompany && rawMassiveCompany.companyId) {
          company = processRawMassiveCompany(rawMassiveCompany)
          // timeFromMarkToLastFundingRound = getTimeFromMarkToLastFundingRound(
          //   new Date(date_of_mark),
          //   company.lastFundingRoundClosedDate,
          // )
          // headcountGrowthSinceMark = calculateHeadcountGrowthSinceMark(company.allHeadcountStats, date_of_mark)
        }

        const entry_pps =
          row.entry_low_pps === row.entry_high_pps
            ? numberToReadableDollarString(row.entry_low_pps)
            : `${numberToReadableDollarString(row.entry_low_pps)} - ${numberToReadableDollarString(row.entry_high_pps)}`
        const marked_pps =
          row.marked_low_pps === row.marked_high_pps
            ? numberToReadableDollarString(row.marked_low_pps)
            : `${numberToReadableDollarString(row.marked_low_pps)} - ${numberToReadableDollarString(row.marked_high_pps)}`
        return {
          ...row,
          entry_pps: entry_pps,
          marked_pps: marked_pps,
          // timeFromMarkToLastFundingRound: timeFromMarkToLastFundingRound,
          // headcountGrowthSinceMark: headcountGrowthSinceMark,
          calculated_percent_of_total: isNaN(row.calculated_percent_of_total)
            ? row.calculated_percent_of_total
            : row.calculated_percent_of_total,
          shares_held: isNaN(parseInt(row.shares_held as unknown as string))
            ? row.shares_held
            : parseFloat(row.shares_held as unknown as string),
          ...company,
        } as unknown as ProcessedCompanyRow
      })
      .sort((company1, company2) => company2.value - company1.value)
  }

  const columnHelper = createColumnHelper<ProcessedCompanyRow>()

  let processedResponseData = useMemo(
    () => processCompanyRows(getAllFundCompanyDetails?.data?.companies ?? []),
    [getAllFundCompanyDetails],
  )

  let companiesDeduped = useMemo(() => {
    function deduplicateByKey<T>(array: T[], key: keyof T, fallbackKey: keyof T): T[] {
      return Array.from(
        array
          .reduce((map, item) => {
            const keyValue = item[key]
            const fallbackValue = item[fallbackKey]
            if (!map.has(keyValue ?? fallbackValue)) {
              map.set(keyValue ?? fallbackValue, item)
            }
            return map
          }, new Map<T[keyof T], T>())
          .values(),
      )
    }
    return deduplicateByKey(processedResponseData, 'companyName', 'company_legal_name')
  }, [processedResponseData])

  const columns: ColumnDef<ProcessedCompanyRow, any>[] = useMemo(
    () => [
      columnHelper.accessor('companyId', {
        id: 'companyId',
        header: 'Company',
        enableGlobalFilter: true,
        minSize: 200,
        meta: {
          className: 'z-30 sticky left-0 bg-white',
          headerClassName: 'z-40 sticky left-0 bg-white',
          isImmovable: true,
          isUnhideable: true,
          singleSelectPlaceholder: 'Search for a company',
          singleSelectOptions: companiesDeduped.filter((company) => company.companyName).map((company, index) => ({
            label: company.companyName,
            // CHANGE TO ID WHEN WE CAN
            value: company.companyId,
            index,
            style: {
              padding: '10px',
            },
            startNode: (
              <CompanyAvatar
                logo={company.companyS3LogoUrl}
                alt={company.companyName}
                altLetter={company.companyName?.[0].toUpperCase()}
                size={20}
              />
            ),
            // endNode: <div className="font-gray-500 text-xs font-light">Headcount: {company.latestHeadcount}</div>,
          })),
        },
        filterFn: 'singleSelectFn' as FilterFnOption<ProcessedCompanyRow>,
        size: 200,
        sortingFn: 'stringFn' as SortingFnOption<ProcessedCompanyRow>,
        // sortingFn: 'companySort' as SortingFnOption<ProcessedCompanyRow>,
        cell: (cell) => (
          <div
            className={`flex max-w-full items-center gap-3 truncate p-2`}
            style={{
              paddingLeft: cell.row.depth * 18 + 8,
            }}>
            <div className="w-20px item-center flex h-full shrink-0 justify-center">
              {cell.row.getCanExpand() ? (
                <button
                  {...{
                    onClick: cell.row.getToggleExpandedHandler(),
                    style: { cursor: 'pointer' },
                  }}>
                  {cell.row.getIsExpanded() ? (
                    <ChevronDown className="h-[20px] w-[20px]" />
                  ) : (
                    <ChevronRight className="h-[20px] w-[20px]" />
                  )}
                </button>
              ) : (
                <div className="w-[20px]"></div>
              )}
            </div>
            {/* only show avatar and name on parent row, and we know parent row if there's no sub rows */}
            {cell.row.original.companyName ? (
              <CompanyAvatar
                logo={cell.row.original.companyS3LogoUrl}
                alt={cell.row.original.company_legal_name}
                altLetter={cell.row.original.company_legal_name[0].toUpperCase()}
              />
            ) : null}
            {cell.row.original.companyId ? (
              <PluralLink href={`/company/${cell.row.original.companyId}`}>{cell.row.original.companyName}</PluralLink>
            ) : (
              <>
                <div>{cell.row.original.companyName}</div>
              </>
            )}
          </div>
        ),
      }),
      columnHelper.accessor('company_legal_name', {
        id: 'company_legal_name',
        header: 'Legal Name',
        enableGlobalFilter: true,
        minSize: 200,
        filterFn: 'stringFn' as FilterFnOption<ProcessedCompanyRow>,
        size: 200,
        sortingFn: 'stringFn' as SortingFnOption<ProcessedCompanyRow>,
        // sortingFn: 'companySort' as SortingFnOption<ProcessedCompanyRow>,
        cell: (cell) => <div className="flex flex-row truncate pl-1">{cell.getValue()}</div>,
      }),
      columnHelper.accessor('fund_name', {
        header: 'Fund Name',
        id: 'fundName',
        enableGlobalFilter: true,
        sortingFn: 'stringFn' as SortingFnOption<ProcessedCompanyRow>,
        filterFn: 'singleSelectFn' as FilterFnOption<ProcessedCompanyRow>,
        size: 200,
        meta: {
          singleSelectPlaceholder: 'Search for a fund',
          singleSelectOptions: getAllFundCompanyDetails.data?.fundNames.map((fundName: any, index: any) => ({
            label: fundName,
            // CHANGE TO ID WHEN WE CAN
            value: fundName,
            index,
            style: {
              padding: '10px',
            },
          })),
        },
        cell: (cell) => <div className="flex flex-row truncate pl-1">{cell.getValue()}</div>,
      }),
      columnHelper.accessor('share_class', {
        header: 'Share Class',
        id: 'share_class',
        size: 200,
        cell: (cell) => <div className="flex flex-row truncate pl-1">{cell.getValue()}</div>,
      }),
      columnHelper.accessor((row: ProcessedCompanyRow) => parseInt(row.shares_held as unknown as string), {
        header: 'Shares Held',
        id: 'shares_held',
        sortingFn: 'numberFn' as SortingFnOption<ProcessedCompanyRow>,
        filterFn: 'numberFn' as FilterFnOption<ProcessedCompanyRow>,
        size: 150,
        cell: (cell) => (
          <div className="flex flex-row pl-1">
            {cell.getValue() ? numberToReadableString(cell.getValue()) : undefined}
          </div>
        ),
      }),
      columnHelper.accessor((row: ProcessedCompanyRow) => row.marked_pps, {
        header: 'Marked PPS',
        id: 'marked_pps',
        sortingFn: 'numberFn' as SortingFnOption<ProcessedCompanyRow>,
        filterFn: 'numberFn' as FilterFnOption<ProcessedCompanyRow>,
        size: 150,
        cell: (cell) => (
          <div className="flex flex-row pl-1">
            {typeof cell.getValue() === 'string' ? cell.getValue() : numberToReadableDollarString(cell.getValue())}
          </div>
        ),
      }),
      // columnHelper.accessor('company_legal_name', {
      //   header: 'Company',
      //   id: 'company_legal_name',
      //   size: 200,
      //   cell: (cell) => <div className="flex flex-row truncate pl-1">{cell.getValue()}</div>,
      // }),
      columnHelper.accessor('value', {
        header: 'Value',
        id: 'value',
        sortingFn: 'numberFn' as SortingFnOption<ProcessedCompanyRow>,
        filterFn: 'numberFn' as FilterFnOption<ProcessedCompanyRow>,
        size: 150,
        cell: (cell) => <div className="flex flex-row pl-1">{numberToReadableDollarString(cell.getValue())}</div>,
      }),
      columnHelper.accessor('calculated_percent_of_total', {
        header: 'Percent of Total',
        id: 'calculated_percent_of_total',
        sortingFn: 'numberFn' as SortingFnOption<ProcessedCompanyRow>,
        size: 150,
        cell: (cell) => <div className="flex flex-row pl-1">{cell.getValue()}%</div>,
      }),
      columnHelper.accessor('latestHeadcount', {
        id: 'latestHeadcount',
        header: 'Latest Headcount',
        size: 150,
        filterFn: 'numberFn' as FilterFnOption<ProcessedCompanyRow>,
        sortingFn: 'numberFn' as SortingFnOption<ProcessedCompanyRow>,
        cell: (cell) => (
          <div className={cellClassName}>
            {cell.getValue() ? numberToReadableString(cell.getValue() as number) : undefined}
          </div>
        ),
      }) as ColumnDef<ProcessedCompanyRow>,
      columnHelper.accessor('oneYearOverallHeadcountGrowthRate', {
        header: '1 Year Headcount Growth',
        id: 'oneYearOverallHeadcountGrowthRate',
        size: 180,
        sortingFn: 'numberFn' as SortingFnOption<ProcessedCompanyRow>,
        filterFn: 'numberFn' as FilterFnOption<ProcessedCompanyRow>,

        cell: (cell) => (
          <div
            className={`max-w-full truncate p-2 ${((cell.getValue() as number | undefined) ?? 0) > 0 ? 'text-green-500' : 'text-red-500'}`}>
            {cell.getValue() as number} {cell.getValue() ? '%' : undefined}
          </div>
        ),
        meta: {
          filterEndAdornment: <div className="mr-4">%</div>,
        },
      }) as ColumnDef<ProcessedCompanyRow>,
      // columnHelper.accessor('headcountGrowthSinceMark', {
      //   header: 'Headcount Growth Since Mark',
      //   id: 'headcountGrowthSinceMark',
      //   size: 210,
      //   sortingFn: 'numberFn' as SortingFnOption<ProcessedCompanyRow>,
      //   filterFn: 'numberFn' as FilterFnOption<ProcessedCompanyRow>,

      //   cell: (cell) => (
      //     <div
      //       className={`max-w-full truncate p-2 ${((cell.getValue() as number | undefined) ?? 0) > 0 ? 'text-green-500' : 'text-red-500'}`}>
      //       {cell.getValue() !== 0 && (cell.getValue() as number)}{' '}
      //       {cell.getValue() !== 0 && cell.getValue() ? '%' : undefined}
      //     </div>
      //   ),
      //   meta: {
      //     filterEndAdornment: <div className="mr-4">%</div>,
      //   },
      // }) as ColumnDef<ProcessedCompanyRow>,
      columnHelper.accessor('lastFundingRoundClosedDate', {
        header: 'Latest Funding Round',
        id: 'lastFundingRoundClosedDate',
        size: 170,
        sortingFn: 'dateFn' as SortingFnOption<ProcessedCompanyRow>,
        filterFn: 'dateFn' as FilterFnOption<ProcessedCompanyRow>,
        cell: (cell) => (
          <div className={cellClassName}>
            {isSome(cell.getValue()) ? new Date(cell.getValue() as Date).toLocaleDateString() : ''}
          </div>
        ),
      }) as ColumnDef<ProcessedCompanyRow>,
      // columnHelper.accessor('timeFromMarkToLastFundingRound', {
      //   header: 'Last Raised Since Mark',
      //   id: 'timeFromMarkToLastFundingRound',
      //   // todo: this should be dateFn, but we need to pass in a date and not string
      //   // sortingFn: 'numberFn' as SortingFnOption<ProcessedCompanyRow>,
      //   // filterFn: 'numberFn' as FilterFnOption<ProcessedCompanyRow>,
      //   size: 180,
      //   cell: (cell) => <div className="flex flex-row pl-1">{cell.getValue()}</div>,
      // }),
      columnHelper.accessor('lastFundingRoundAmount', {
        header: 'Latest Funding Round Amount',
        id: 'lastFundingRoundAmount',
        size: 220,
        sortingFn: 'numberFn' as SortingFnOption<ProcessedCompanyRow>,
        filterFn: 'numberFn' as FilterFnOption<ProcessedCompanyRow>,
        cell: (cell) => (
          <div className={cellClassName}>
            {cell.getValue() ? numberToAbbreviatedDollarString(cell.getValue() as number) : ''}
          </div>
        ),
      }) as ColumnDef<ProcessedCompanyRow>,
      // columnHelper.accessor('sector', {
      //   header: 'Sector',
      //   id: 'sector',
      //   size: 200,
      //   cell: (cell) => <div className="flex flex-row pl-1">{cell.getValue()}</div>,
      // }),
      columnHelper.accessor((row: ProcessedCompanyRow) => row.gain_loss, {
        header: 'Gain/Loss',
        id: 'gain_loss',
        sortingFn: 'numberFn' as SortingFnOption<ProcessedCompanyRow>,
        filterFn: 'numberFn' as FilterFnOption<ProcessedCompanyRow>,
        size: 150,
        cell: (cell) => (
          <div
            className={`flex flex-row pl-1 ${cell.getValue() > 0 ? 'text-green-500' : cell.getValue() === 0 ? '' : 'text-red-500'}`}>
            {cell.getValue() === 0
              ? ''
              : cell.getValue() > 0
                ? numberToReadableDollarString(cell.getValue())
                : `(${numberToReadableDollarString(Math.abs(cell.getValue()))})`}
          </div>
        ),
      }),
      columnHelper.accessor((row: ProcessedCompanyRow) => row.percent_gain_loss, {
        header: '% Gain/Loss',
        id: 'percent_gain_loss',
        sortingFn: 'numberFn' as SortingFnOption<ProcessedCompanyRow>,
        filterFn: 'numberFn' as FilterFnOption<ProcessedCompanyRow>,
        size: 150,
        cell: (cell) => (
          <div
            className={`flex flex-row pl-1 ${cell.getValue() > 0 ? 'text-green-500' : cell.getValue() === 0 ? '' : 'text-red-500'}`}>
            {cell.getValue() === null || cell.getValue() === undefined || cell.getValue() === 0
              ? ''
              : `${numberToReadableString(cell.getValue())}%`}
          </div>
        ),
      }),
      columnHelper.accessor((row: ProcessedCompanyRow) => row.moic, {
        header: 'MOIC',
        id: 'moic',
        sortingFn: 'numberFn' as SortingFnOption<ProcessedCompanyRow>,
        filterFn: 'numberFn' as FilterFnOption<ProcessedCompanyRow>,
        size: 150,
        cell: (cell) => <div className="flex flex-row truncate pl-1">{cell.getValue()}</div>,
      }),
      columnHelper.accessor('cost', {
        header: 'Cost',
        id: 'cost',
        sortingFn: 'numberFn' as SortingFnOption<ProcessedCompanyRow>,
        filterFn: 'numberFn' as FilterFnOption<ProcessedCompanyRow>,
        size: 150,
        cell: (cell) => <div className="flex flex-row pl-1">{numberToReadableDollarString(cell.getValue())}</div>,
      }),
      columnHelper.accessor((row: ProcessedCompanyRow) => row.entry_pps, {
        header: 'Entry PPS',
        id: 'entry_pps',
        sortingFn: 'numberFn' as SortingFnOption<ProcessedCompanyRow>,
        filterFn: 'numberFn' as FilterFnOption<ProcessedCompanyRow>,
        size: 150,
        cell: (cell) => (
          <div className="flex flex-row pl-1">
            {typeof cell.getValue() === 'string' ? cell.getValue() : numberToReadableDollarString(cell.getValue())}
          </div>
        ),
      }),
    ],
    [columnHelper, getAllFundCompanyDetails.data?.fundNames, processedResponseData],
  )

  return (
    <>
      <SheetPage
        paginate
        defaultSheetId={10}
        backBreadcrumb={{ href: '/dashboard/sheets/holdings', title: 'Holdings' }}
        getRowId={(row: any) => row.id}
        isLoading={getAllFundCompanyDetails.isLoading || props.isLoading}
        columns={columns as ColumnDef<unknown>[]}
        data={processedResponseData}
        filterFns={{}}
        sortingFns={{}}
        indicationData={{
          activeIndicationCompanyIds: indicationQuery.data?.active ?? [],
          inactiveIndicationCompanyIds: indicationQuery.data?.inactive ?? [],
        }}
      // onNavigate={(newId) => {
      //     navigate(`/fund-upload`)
      // }}
      // onNavigatePush={(newId) => {
      //     pushNavigate(`/dashboard/sheets/funds/${newId}`)
      // }}
      />
    </>
  )
}
