import { numberToPercentString } from "plural-shared/utils";

export interface IrrFrequencyCount {
  irr: number;
  frequency: number;
}

export interface GroupedIrrFrequency {
  frequency: number;
  frequencyPercent: number;
  irrRangeString: string;
  irrRangeStart: number;
  irrRangeEnd: number;
}

interface GraphBar {
  irrRangeString: string;
  frequencyPercent: number;
}

export interface IrrDistributionGraphData {
  bars: GraphBar[];
  groupedIrrFrequencies: GroupedIrrFrequency[];
  maxYDomain: number;
  numOutcomesSimulated: number;
}

export class IrrDistributionGraph {
  groupedIrrFrequencies: GroupedIrrFrequency[];
  maxY: number;
  numOutcomesSimulated: number;
  constructor(groupedIrrFrequencies: GroupedIrrFrequency[], maxY: number) {
    this.groupedIrrFrequencies = groupedIrrFrequencies;
    this.maxY = maxY;
    this.numOutcomesSimulated = groupedIrrFrequencies.reduce(
      (acc: number, group: GroupedIrrFrequency) => {
        return acc + group.frequency;
      },
      0
    );
  }

  bars(): GraphBar[] {
    return this.groupedIrrFrequencies.map((group) => {
      return {
        irrRangeString: group.irrRangeString,
        frequencyPercent: group.frequencyPercent,
      };
    });
  }

  graphData(): IrrDistributionGraphData {
    return {
      bars: this.bars(),
      groupedIrrFrequencies: this.groupedIrrFrequencies,
      maxYDomain: this.maxY,
      numOutcomesSimulated: this.numOutcomesSimulated,
    };
  }
}

export class IrrDistributionGenerator<T> {
  irrs: number[] = [];
  irrFrequencies: { [irr: number]: IrrFrequencyCount } = {};
  groupedIrrFrequencies: GroupedIrrFrequency[] = [];
  materials: T[];

  constructor(
    irrMaterials: T[],
    irrGenerator: (list: T[], index: number) => number
  ) {
    // this.distribution = distribution;
    this.materials = irrMaterials;

    this.irrs = [];
    irrMaterials.forEach((material, index) => {
      this.irrs.push(irrGenerator(irrMaterials, index));
    });
    this.irrs.sort((a, b) => a - b);
    this.irrs.forEach((irr) => {
      let irrFrequency = this.irrFrequencies[irr]?.frequency || 0;
      this.irrFrequencies[irr] = {
        irr: irr,
        frequency: irrFrequency + 1,
      };
    });
  }

  irrPercentile(percentile: number): number {
    let index = Math.floor((this.irrs.length - 1) * percentile);
    return this.irrs[index];
  }

  meanIrr(): number {
    return (
      this.irrs.reduce((acc, irr) => {
        return acc + irr;
      }, 0) / this.irrs.length
    );
  }

  medianIrr(): number {
    return this.irrPercentile(0.5);
  }

  firstQuartileIrr(): number {
    return this.irrPercentile(0.25);
  }

  thirdQuartileIrr(): number {
    return this.irrPercentile(0.75);
  }

  irrRange(): number {
    return this.irrs[this.irrs.length - 1] - this.irrs[0];
  }

  makeGroupedFrequenciesWithCustomRanges(
    ranges: number[]
  ): GroupedIrrFrequency[] {
    let irrFrequencies = Object.values(this.irrFrequencies).sort(
      (a, b) => a.irr - b.irr
    );
    let groups: { [key: string]: GroupedIrrFrequency } = {};

    // Create a group for irr < ranges[0]
    groups[`< ${numberToPercentString(ranges[0], 0)}`] = {
      irrRangeString: `< ${numberToPercentString(ranges[0], 0)}`,
      irrRangeStart: -Infinity,
      irrRangeEnd: ranges[0],
      frequency: 0,
      frequencyPercent: 0,
    };

    // Create groups for irr within the ranges
    for (let i = 0; i < ranges.length - 1; i++) {
      let lowerBound = ranges[i];
      let upperBound = ranges[i + 1];
      let irrRangeString = `${numberToPercentString(
        lowerBound,
        0
      )} to ${numberToPercentString(upperBound, 0)}`;
      groups[irrRangeString] = {
        irrRangeString: irrRangeString,
        irrRangeStart: lowerBound,
        irrRangeEnd: upperBound,
        frequency: 0,
        frequencyPercent: 0,
      };
    }

    // Create a group for irr > ranges[ranges.length - 1]
    groups[`> ${numberToPercentString(ranges[ranges.length - 1], 0)}`] = {
      irrRangeString: `> ${numberToPercentString(
        ranges[ranges.length - 1],
        0
      )}`,
      irrRangeStart: ranges[ranges.length - 1],
      irrRangeEnd: Infinity,
      frequency: 0,
      frequencyPercent: 0,
    };

    for (let irrFrequency of irrFrequencies) {
      let groupKey = Object.keys(groups).find(
        (key) =>
          irrFrequency.irr >= groups[key].irrRangeStart &&
          irrFrequency.irr < groups[key].irrRangeEnd
      );
      if (groupKey) {
        groups[groupKey].frequency += irrFrequency.frequency;
      }
    }

    let groupedIrrFrequencies = Object.values(groups).sort((a, b) => {
      return a.irrRangeStart - b.irrRangeStart;
    });

    for (let groupedIrrFrequency of groupedIrrFrequencies) {
      groupedIrrFrequency.frequencyPercent =
        groupedIrrFrequency.frequency / this.irrs.length;
    }

    this.groupedIrrFrequencies = groupedIrrFrequencies;
    return groupedIrrFrequencies;
  }

  printGroupedIrrFrequencies(message: string) {
    console.log(`\n${message}`);
    let obj: { [rangeStr: string]: number } = {};
    this.groupedIrrFrequencies.forEach((group) => {
      obj[group.irrRangeString] = group.frequency;
    });
    console.log(JSON.stringify(obj, null, 4));
  }
}
