import TextInputSelect, { TextInputSelectOption } from 'pages/company/TextInputSelect'
import { ReactNode, useEffect, useState } from 'react'

type AutoCompleteCellProps = {
  initiallySelected?: TextInputSelectOption
  onSelect: (value: TextInputSelectOption) => void
  options: TextInputSelectOption[]
  placeholder: string
  createModal?: ReactNode
}

export function AutoCompleteCell(props: AutoCompleteCellProps) {
  const [selected, setSelected] = useState(props.initiallySelected)

  useEffect(() => {
    setSelected(props.initiallySelected)
  }, [props.initiallySelected])
  return (
    <TextInputSelect
      noOutline
      options={props.options}
      placeholder={props.placeholder}
      createModal={props.createModal}
      initialOption={selected}
      onOptionSelect={(value) => {
        setSelected(value)
        props.onSelect(value)
      }}
    />
  )
}
