import { IonItem, IonSpinner } from '@ionic/react'
import { CrossCircledIcon, EyeNoneIcon, EyeOpenIcon } from '@radix-ui/react-icons'
import { Button } from '@radix-ui/themes'
import AppModal from 'components/AppModal'
import AppTextField, { AppCheckboxFormfield, AppSelectFormfield, InputLabel } from 'components/AppTextfield'
import { Input } from 'components/ui/input'
import { ArrowDown, ArrowUp } from 'lucide-react'
import TextInputSelect, { TextInputSelectOption } from 'pages/company/TextInputSelect'
import { numberToReadableDollarString } from 'plural-shared/utils'
import { usePluralAuth } from 'providers/PluralAuthProvider'
import { useEffect, useMemo, useState } from 'react'
import { ArrayElement, isSome } from 'safety'
import { AuthStatus } from 'utils/authStatus'
import { RouterOutputs, trpc } from 'utils/trpc'

type RouterEntity = ArrayElement<RouterOutputs['adminLegalEntityRouter']['getAllLegalEntities']>

enum EntityType {
  NATURAL_PERSON = 'NATURAL_PERSON',
  IRA = 'IRA',
  LP = 'LP',
  LLC = 'LLC',
  CORP = 'CORP',
  TRUST_OWNER = 'TRUST_OWNER',
  TRUST_NON_OWNER = 'TRUST_NON_OWNER',
  OTHER = 'OTHER',
}

const EntityTypeToLabel: Record<EntityType, string> = {
  [EntityType.NATURAL_PERSON]: 'Natural Person',
  [EntityType.IRA]: 'Individual Retirement Account',
  [EntityType.LP]: 'Limited Partnership',
  [EntityType.LLC]: 'Limited Liability Company',
  [EntityType.CORP]: 'Corporation',
  [EntityType.TRUST_OWNER]: 'Trust Owner',
  [EntityType.TRUST_NON_OWNER]: 'Trust Non-Owner',
  [EntityType.OTHER]: 'Other',
}
export function EntityAdminSheet() {
  const [searchString, setSearchString] = useState<string>('')
  const { isLoading: isAuthLoading, authStatus } = usePluralAuth()

  const isCompanyEditorQuery = trpc.attributedCompanyMetrics.isCompanyEditor.useQuery(undefined, {
    enabled: !isAuthLoading,
  })

  useEffect(() => {
    if (isAuthLoading) return
    isCompanyEditorQuery.refetch()
  }, [isAuthLoading, isCompanyEditorQuery])

  const isCompanyEditor = isCompanyEditorQuery.data ?? undefined
  const isAllowedUser = authStatus === AuthStatus.Allowed

  const allEntitiesQuery = trpc.adminLegalEntityRouter.getAllLegalEntities.useQuery(undefined, {
    enabled: !isAuthLoading,
  })

  if ((!isAllowedUser || !isCompanyEditor) && !isAuthLoading) {
    return (
      <div className="mb-24 flex w-full flex-col items-center justify-start pl-4 pr-4 pt-4">
        <div className="opacity-60">You are not logged in as an allowed user.</div>
      </div>
    )
  }
  return (
    <div className="mb-24 flex w-full flex-col items-center justify-start pb-10 pl-4 pr-4">
      <div className={`mt-8 flex w-full max-w-page-max flex-col items-center justify-center `}>
        <div className=" w-full">
          {allEntitiesQuery.isLoading || isAuthLoading ? (
            <div className="loader mt-6 flex w-full flex-col items-center">
              <IonItem>
                <IonSpinner name="lines-sharp"></IonSpinner>
              </IonItem>
            </div>
          ) : (
            <div className="flex w-full flex-col gap-8">
              <div className="flex w-full flex-row items-center justify-between">
                <div className="flex flex-row items-center text-2xl"> Legal Entities</div>
                <CreateEntityModalButton
                  onSubmit={() => {
                    allEntitiesQuery.refetch()
                  }}
                />
              </div>

              <div style={{}}>
                <Input
                  value={searchString}
                  placeholder="Search for an entity..."
                  onChange={(e) => {
                    setSearchString(e.target.value)
                  }}
                />
              </div>
              <div className="flex w-full flex-col gap-4">
                {(allEntitiesQuery.data ?? [])
                  .filter((entity: RouterEntity) => entity.name.toLowerCase().includes(searchString.toLowerCase()))
                  .sort((a: RouterEntity, b: RouterEntity) => {
                    return a.name.localeCompare(b.name)
                  })
                  .map((entity) => (
                    <EntityCard
                      key={entity.id}
                      entity={entity}
                      onSubmit={() => {
                        allEntitiesQuery.refetch()
                      }}
                    />
                  ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

function EntityCard({ entity, onSubmit }: { entity: RouterEntity; onSubmit: () => void }) {
  const [isExpanded, setIsExpanded] = useState(false)
  return (
    <div className="flex w-full flex-col  rounded border">
      <div className="flex w-full items-center justify-between p-4">
        <div className="text-xl font-normal">{entity.name}</div>
        {isExpanded ? (
          <ArrowUp
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setIsExpanded(false)
            }}
          />
        ) : (
          <ArrowDown
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setIsExpanded(true)
            }}
          />
        )}
      </div>
      {isExpanded && (
        <LegalEntityForm
          onCancel={() => {
            setIsExpanded(false)
          }}
          entity={entity}
          onSubmit={() => {
            setIsExpanded(false)
            onSubmit()
          }}
        />
      )}
    </div>
  )
}

function LegalEntityForm(props: { entity?: RouterEntity; onSubmit: () => void; onCancel: () => void }) {
  type FormEntity = Omit<
    RouterEntity,
    'id' | 'sensitiveLegalEntityInformation' | 'journalEntries' | 'fundCommits' | 'entityToUsers'
  > & {
    taxId?: string
  }

  const [entityForm, setEntityForm] = useState<FormEntity>(
    props.entity
      ? {
          name: props.entity.name,
          type: props.entity.type,
          address: props.entity.address,
          isAccreditationApproved: props.entity.isAccreditationApproved,
          isAccreditedInvestor: props.entity.isAccreditedInvestor,
          isQualifiedPurchaser: props.entity.isQualifiedPurchaser,
          taxId: props.entity.sensitiveLegalEntityInformation?.taxId,
          isEligibleContractParticipant: props.entity.isEligibleContractParticipant,
        }
      : {
          name: '',
          type: EntityType.OTHER,
          address: '',
          isAccreditationApproved: false,
          isAccreditedInvestor: false,
          isQualifiedPurchaser: false,
          isEligibleContractParticipant: false,
          taxId: '',
        },
  )

  function setEntityFormValue<K extends keyof FormEntity>(key: K, value: FormEntity[K]) {
    setEntityForm((prev: FormEntity) => ({ ...prev, [key]: value }))
  }

  let updateEntityMutation = trpc.adminLegalEntityRouter.updateLegalEntity.useMutation()
  let createEntityMutation = trpc.adminLegalEntityRouter.createLegalEntity.useMutation()
  let createUserToEntityMutation = trpc.adminLegalEntityRouter.createLegalEntityToUser.useMutation()
  let deleteUserToEntityMutation = trpc.adminLegalEntityRouter.deleteLegalEntityToUser.useMutation()

  let [usersToCreate, setUsersToCreate] = useState<{ id: number; email: string }[]>([])
  let [usersToDelete, setUsersToDelete] = useState<{ id: number; email: string }[]>([])
  async function submitForm() {
    if (props.entity === undefined) {
      let createdEntity = await createEntityMutation.mutateAsync({
        data: {
          address: entityForm.address,
          name: entityForm.name,
          type: entityForm.type,
          isAccreditationApproved: entityForm.isAccreditationApproved,
          isAccreditedInvestor: entityForm.isAccreditedInvestor,
          isQualifiedPurchaser: entityForm.isQualifiedPurchaser,
          isEligibleContractParticipant: entityForm.isEligibleContractParticipant,
          taxId: entityForm.taxId ?? undefined,
        },
      })
      for (let user of usersToCreate) {
        if (!isSome(usersToDelete.find((u) => u.id === user.id))) {
          await createUserToEntityMutation.mutateAsync({
            userId: user.id,
            legalEntityId: createdEntity.id,
          })
        }
      }
    } else {
      await updateEntityMutation.mutateAsync({
        id: props.entity.id,
        data: {
          address: entityForm.address,
          name: entityForm.name,
          type: entityForm.type,
          isAccreditationApproved: entityForm.isAccreditationApproved,
          isAccreditedInvestor: entityForm.isAccreditedInvestor,
          isQualifiedPurchaser: entityForm.isQualifiedPurchaser,
          isEligibleContractParticipant: entityForm.isEligibleContractParticipant,
          taxId: entityForm.taxId ?? null,
        },
      })
      for (let user of usersToCreate) {
        if (!isSome(usersToDelete.find((u) => u.id === user.id))) {
          await createUserToEntityMutation.mutateAsync({
            userId: user.id,
            legalEntityId: props.entity.id,
          })
        }
      }
      for (let user of usersToDelete) {
        if (!isSome(usersToCreate.find((u) => u.id === user.id))) {
          await deleteUserToEntityMutation.mutateAsync({
            userId: user.id,
            legalEntityId: props.entity.id,
          })
        }
      }
    }
    props.onSubmit()
  }
  let associatedUsers = [
    ...(props.entity
      ? props.entity.entityToUsers.map((utr: ArrayElement<RouterEntity['entityToUsers']>) => {
          return utr.user.email
        })
      : []),
    ...usersToCreate.map((utc) => {
      return utc.email
    }),
  ]
  return (
    <div className="flex w-full flex-col p-4">
      {props.entity || usersToCreate.length > 0 ? (
        <div className="flex flex-col gap-1">
          <InputLabel label="Associated Users" />
          <div className="flex flex-row">
            {associatedUsers
              .filter((email) => usersToDelete.find((utd) => utd.email === email) == null)
              .map((email: string, i: number) => {
                return (
                  <div key={email} className="ml-2 flex flex-row items-center">
                    {email}
                    <CrossCircledIcon
                      style={{ cursor: 'pointer', color: 'gray', marginLeft: '3px' }}
                      onClick={() => {
                        setUsersToDelete((prev) => [
                          ...prev,
                          {
                            id:
                              props.entity?.entityToUsers.find((etu: ArrayElement<RouterEntity['entityToUsers']>) => {
                                return etu.user.email === email
                              })?.userId ??
                              usersToCreate.find((utr) => {
                                return utr.email === email
                              })?.id ??
                              -1,
                            email: email,
                          },
                        ])
                        setUsersToCreate((prev) => prev.filter((u) => u.email !== email))
                      }}
                    />
                    {i !== associatedUsers.length - 1 && ','}
                  </div>
                )
              })}
          </div>
        </div>
      ) : null}
      <AddUserSelector
        onSubmit={(userId, userEmail) => {
          if (!associatedUsers.includes(userEmail)) {
            setUsersToCreate((prev) => [...prev, { id: userId, email: userEmail }])
          }
          if (usersToDelete.find((u) => u.id === userId)) {
            setUsersToDelete((prev) => prev.filter((u) => u.id !== userId))
          }
        }}
      />
      {props.entity && (
        <div className="mt-3 flex flex-row justify-between  gap-3">
          <InputLabel label="Total Commits" />
          <div className="text-small flex flex-row gap-6">
            {props.entity.fundCommits
              .sort((a: ArrayElement<RouterEntity['fundCommits']>, b: ArrayElement<RouterEntity['fundCommits']>) => {
                return a.fundId - b.fundId
              })
              .map((commit: ArrayElement<RouterEntity['fundCommits']>) => {
                return (
                  <div key={commit.id}>
                    <div>Fund {commit.fundId}</div>
                    <div>{numberToReadableDollarString(commit.cashDelta)}</div>
                  </div>
                )
              })}
          </div>
        </div>
      )}
      {props.entity && (
        <div className="mt-3 flex flex-row justify-between">
          <InputLabel label="Journal Entries" />
          <div className="flex w-30 flex-row">{`${props.entity.journalEntries.length} Total Entries`} </div>
        </div>
      )}
      <AppTextField
        label="Name"
        value={entityForm.name}
        onChange={(e) => {
          setEntityFormValue('name', e.target.value)
        }}
      />
      <AppSelectFormfield
        label="Entity Type"
        value={entityForm.type}
        onValueChange={(value) => {
          setEntityFormValue('type', value as keyof typeof EntityType)
        }}
        options={(Object.keys(EntityType) as Array<keyof typeof EntityType>).map((key) => {
          return {
            value: EntityType[key],
            label: EntityTypeToLabel[EntityType[key]],
          }
        })}
      />
      <SensitiveAppTextField
        label="Tax Id Number"
        type="password"
        placeholder="XXX-XX-XXXX"
        value={entityForm?.taxId ?? ''}
        onChange={(e) => {
          setEntityFormValue('taxId', e.target.value)
        }}
        defaultShown={false}
      />
      <AppTextField
        label="Address"
        placeholder="1234 Plural St"
        value={entityForm.address}
        onChange={(e) => {
          setEntityFormValue('address', e.target.value)
        }}
      />
      <div className="mt-3">Accreditation Status:</div>
      <AppCheckboxFormfield
        label="Is Accreditation Approved?"
        checked={entityForm.isAccreditationApproved}
        onCheckChange={(value) => setEntityFormValue('isAccreditationApproved', value)}
      />
      <AppCheckboxFormfield
        label="Is Accredited Investor?"
        checked={entityForm.isAccreditedInvestor}
        onCheckChange={(value) => setEntityFormValue('isAccreditedInvestor', value)}
      />
      <AppCheckboxFormfield
        label="Is Qualified Purchaser?"
        checked={entityForm.isQualifiedPurchaser}
        onCheckChange={(value) => setEntityFormValue('isQualifiedPurchaser', value)}
      />
      <AppCheckboxFormfield
        label="Is Eligible Contract Participant?"
        checked={entityForm.isEligibleContractParticipant}
        onCheckChange={(value) => setEntityFormValue('isEligibleContractParticipant', value)}
      />

      <div className="items-right flex w-full">
        <div className="ml-auto mt-2 flex flex-row items-center gap-2">
          <Button onClick={props.onCancel} variant="outline" color="gray">
            Cancel
          </Button>
          <Button onClick={submitForm} variant="solid" color="gray">
            Save
          </Button>
        </div>
      </div>
    </div>
  )
}

export function SensitiveAppTextField(
  props: {
    defaultShown: boolean
  } & React.ComponentProps<typeof AppTextField>,
) {
  let { defaultShown, ...rest } = props
  const [isShown, setIsShown] = useState(defaultShown)
  return (
    <div className="flex w-full flex-row gap-2">
      <AppTextField
        {...rest}
        type={isShown ? 'text' : 'password'}
        endAdornment={
          isShown ? (
            <EyeNoneIcon
              style={{
                cursor: 'pointer',
              }}
              onClick={() => {
                setIsShown(false)
              }}
            />
          ) : (
            <EyeOpenIcon
              style={{
                cursor: 'pointer',
              }}
              onClick={() => {
                setIsShown(true)
              }}
            />
          )
        }
      />
    </div>
  )
}

function CreateEntityModalButton(props: { onSubmit: () => void }) {
  const [isModalOpen, setIsModalOpen] = useState(false)
  return (
    <div className="">
      <Button
        color="gray"
        highContrast
        variant="outline"
        onClick={() => {
          setIsModalOpen(true)
        }}>
        Create New Entity
      </Button>
      <AppModal
        open={isModalOpen}
        onClose={() => {
          setIsModalOpen(false)
        }}>
        <div className="w-[700px]">
          <LegalEntityForm
            onSubmit={() => {
              setIsModalOpen(false)
              props.onSubmit()
            }}
            onCancel={() => {
              setIsModalOpen(false)
            }}
          />
        </div>
      </AppModal>
    </div>
  )
}

type RouterUser = ArrayElement<RouterOutputs['user']['adminGetAllUsers']['users']>
function AddUserSelector(props: { onSubmit: (userId: number, userEmail: string) => void }) {
  const { isLoading: isAuthLoading } = usePluralAuth()

  const [selectedOption, setUserOptionSelected] = useState<TextInputSelectOption | undefined>(undefined)
  const usersQuery = trpc.user.adminGetAllUsers.useQuery(undefined, {
    enabled: !isAuthLoading,
  })

  const users = useMemo(() => {
    return usersQuery.data?.users.sort((a: RouterUser, b: RouterUser) => a.email.localeCompare(b.email)) ?? []
  }, [usersQuery.data])

  return (
    <div className="my-4 flex flex-row items-center gap-2">
      <TextInputSelect
        options={users.map((user: RouterUser, i: number) => {
          return {
            label: user.email,
            index: i,
            additionalSearchString: `${user.firstName} ${user.lastName} ${user.email}`,
            id: user.id,
          }
        })}
        onOptionSelect={setUserOptionSelected}
        initialOption={selectedOption}
        placeholder="Add a user..."
      />
      <Button
        variant="soft"
        color="gray"
        onClick={() => {
          if (selectedOption) {
            props.onSubmit(selectedOption.id as number, selectedOption.label)
            setUserOptionSelected(undefined)
          }
        }}>
        Submit
      </Button>
    </div>
  )
}
