import { ArrowBottomRightIcon, ArrowTopRightIcon } from '@radix-ui/react-icons'

export function SharePriceFromLastRoundCell(props: { sharePriceFromLastRound?: number; sharePriceToday: number }) {
  const tickerGreen = 'rgba(59, 131, 86, 1)'
  const tickerRed = 'rgba(190, 52, 55, 1)'
  if (!props.sharePriceFromLastRound) return <div></div>
  const cell = `$${props.sharePriceToday.toLocaleString(undefined, { maximumFractionDigits: 2 })}`
  let exitPremium = (
    Math.abs(props.sharePriceToday - props.sharePriceFromLastRound) / props.sharePriceFromLastRound
  ).toLocaleString(undefined, {
    style: 'percent',
    minimumFractionDigits: 0,
  })
  const icon =
    props.sharePriceToday < props.sharePriceFromLastRound ? (
      <ArrowBottomRightIcon color={`${tickerRed}`} />
    ) : (
      <ArrowTopRightIcon color={`${tickerGreen}`} />
    )
  return (
    <div className="flex items-center">
      <div>{cell}</div>
      <div className="ml-2 flex items-center opacity-90">
        {icon}
        <span
          className={`text-xs ${
            props.sharePriceToday < props.sharePriceFromLastRound ? 'text-tickers-red' : 'text-tickers-green'
          }`}>
          {exitPremium}
        </span>
      </div>
    </div>
  )
}
