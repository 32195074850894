import { numberToAbbreviatedDollarString, numberToAbbreviatedString } from 'plural-shared/utils'
import { isNone } from 'safety'
import { useSimulatorContext } from './CashSimulatorContext'
import GraphStat from './GraphStat'

export default function ValuationGraphTransactionData() {
  const { multiplesSlider, pluralCashTx, netCashGraphProps, txInputs } = useSimulatorContext() ?? {}
  if (isNone(multiplesSlider) || isNone(pluralCashTx) || isNone(netCashGraphProps) || isNone(txInputs)) {
    return null
  }
  const { pluralBar, secondaryBar } = netCashGraphProps ?? {}
  if (isNone(pluralBar) || isNone(secondaryBar)) {
    return null
  }
  const yearsToOutcome = txInputs.yearsToOutcome.value
  const dollarsSaved = pluralBar.netCashValue - secondaryBar.netCashValue
  return (
    <div className="flex space-x-12">
      <GraphStat
        label={`Company valuation`}
        value={
          <div>
            {numberToAbbreviatedDollarString(multiplesSlider?.activeCompanyValuation ?? 0, 2, 1)}{' '}
            <span className="text-sm font-normal opacity-60">in {yearsToOutcome} yrs</span>{' '}
          </div>
        }
      />
      <GraphStat
        label="Shares exchanged"
        value={
          <div>
            {numberToAbbreviatedString(pluralCashTx.sharesOwed(), 1)}{' '}
            <span className="text-sm font-normal opacity-60">
              of {numberToAbbreviatedString(pluralCashTx.sharesReserved(), 1)}
            </span>{' '}
          </div>
        }
      />
      <GraphStat
        label="Dollars saved"
        value={`${dollarsSaved >= 0 ? '+' : ''}${numberToAbbreviatedDollarString(dollarsSaved)}`}
      />
    </div>
  )
}
