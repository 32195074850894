import {
  ArrowLeftIcon,
  ArrowRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  DotsVerticalIcon,
  EyeNoneIcon,
  MagnifyingGlassIcon,
  TextAlignBottomIcon,
  TextAlignTopIcon,
  TriangleDownIcon,
  TriangleUpIcon,
} from '@radix-ui/react-icons'
import { IconButton, Skeleton } from '@radix-ui/themes'
import '@tanstack/react-table' //or vue, svelte, solid, qwik, etc.
import {
  ColumnDef,
  FilterFn,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getPaginationRowModel,
  Header,
  Row,
  useReactTable,
} from '@tanstack/react-table'
import AppTextField from 'components/AppTextfield'
import { attioStyleString, BasicButton } from 'components/BasicButton'
import ConfirmationModalButton, { ConfirmationModalProps } from 'components/ConfirmationModalButton'
import { Dropdown } from 'components/Dropdown'
import { AutoCompleteCell } from 'components/editable-table/AutoCompleteCell'
import { BooleanCell } from 'components/editable-table/BooleanCell'
import { InputCheckbox } from 'components/editable-table/CheckBox'
import {
  AnyColumnProps,
  AnyEditableTextColumnProps,
  AnyTextColumnProps,
  ColumnInputType,
  columnPropsInputType,
  CreateColumnProps,
  EditableAutoCompleteColumnProps,
  EditableBooleanColumnProps,
  EditableMultiSelectColumnProps,
  isColumnCreateable,
} from 'components/editable-table/ColumnProps'
import { defaultTableCellPadding } from 'components/editable-table/EditableTable'
import EditableTextCell from 'components/editable-table/EditableTextCell'
import { MultiSelectCell } from 'components/editable-table/MultiSelectCell'
import _ from 'lodash'
import { BadgePlus, SaveIcon } from 'lucide-react'
import {
  getTableFilteredRows,
  IndicationDataModel,
  SpecialFilterKey,
  SpecialIndicationFilterKey,
  TableFilterModel,
} from 'models/table'
import { FloatingFooter, getNumber } from 'pages/admin/AdminCompanySheetPage'
import { useNavigationBlocker, useNavigationBlockerText } from 'providers/NavigationProvider'
import { useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router'
import { isNone, isSome, isTrue, Optional } from 'safety'
import { DropdownSearchCell, DropdownSearchOption } from 'several/components/DropdownSearch'
import { useIsSmallScreen } from 'several/hooks/navigation'
import { v4 as uuid } from 'uuid'
import ColumnsDropdown from './ColumnsDropdown'
import CreateNewSheetModal from './CreateNewSheetModal'
import FiltersModalButton from './FiltersModal'

type SortingState = {
  id: string
  desc: boolean
}

export const BlueCellColor = 'bg-[#FAF9FF]'

export function renderEditableCell<T, N>(
  initialValue: any,
  column: Omit<AnyColumnProps<T, N>, 'header' | 'accessor'> | undefined,
  rowDatum: T,
  getRowId?: (originalRow: T, index: number, parent?: Row<T> | undefined) => string,
  bgColor?: string,
  noPadding?: boolean,
) {
  let bg = bgColor
  const content = initialValue
  if (!column) {
    return <div className={`${noPadding ? '' : defaultTableCellPadding} opacity-60`}>{content}</div>
  }

  let columnInputType = columnPropsInputType(column as unknown as AnyColumnProps<T, N>)
  // @ts-ignore
  let rowDatumId = getRowId ? getRowId(rowDatum, 0) : (rowDatum['id' as keyof rowDatum] as number)
  if (columnInputType === ColumnInputType.EditableMultiSelect) {
    let _column = column as EditableMultiSelectColumnProps<T, N>

    let options = typeof _column.options === 'function' ? _column.options(rowDatum) : _column.options

    return (
      <MultiSelectCell
        bgColor={bg ?? ''}
        key={`content-${content}-${rowDatumId}`}
        options={options}
        initiallySelected={content}
        onSelect={(selected) => {
          _column.updateRemote(rowDatum, selected)
        }}
      />
    )
  } else if (columnInputType === ColumnInputType.EditableAutoComplete) {
    let _column = column as EditableAutoCompleteColumnProps<T, N>
    // @ts-ignore
    let noPaddingX = _column.noPadding ?? false

    let _options = _column.autoCompleteOptions as unknown as DropdownSearchOption[]
    let selectedOption = _options.find((option) => option.value === content)
    return (
      <DropdownSearchCell
        key={`content-${content}-${rowDatumId}`}
        noPadding={noPaddingX || noPadding}
        options={_options}
        value={content}
        initialSearchValue={selectedOption?.label}
        onFreeForm={(newValue) => {
          _column.updateRemote(rowDatum, newValue as unknown as any)
        }}
        onSelectOption={(option) => {
          _column.updateRemote(rowDatum, option.value as unknown as any)
        }}
      />
    )
  } else if (columnInputType === ColumnInputType.EditableBoolean) {
    let _column = column as EditableBooleanColumnProps<T, N>
    return (
      <BooleanCell
        key={`content-${content}-${rowDatumId}`}
        isSelected={content === true}
        onSelect={(value) => {
          _column.updateRemote(rowDatum, value)
        }}
      />
    )
  } else if (columnInputType === ColumnInputType.EditableInput) {
    let _column = column as AnyEditableTextColumnProps<T, N>
    return (
      <EditableTextCell<string>
        noPadding={noPadding}
        bgColor={bg}
        key={`content-${content}-${rowDatumId}`}
        cellFormat={_column.textCellFormat as any}
        initialValue={content}
        isCreating={false}
        onEditsFinished={(newValue: Optional<string>) => {
          _column.updateRemote(rowDatum, newValue as never)
        }}
      />
    )
  } else if (columnInputType === ColumnInputType.NonEditableInput) {
    let _column = column as AnyTextColumnProps<T, N>
    return isSome(content) && isSome(_column.textCellFormat) ? (
      <div className={`${defaultTableCellPadding} opacity-100`} key={`content-${content}-${rowDatumId}`}>
        {_column.textCellFormat.viewModeString(content as never)}
      </div>
    ) : (
      <div className={`${defaultTableCellPadding} opacity-60`} key={`content-${content}-${rowDatumId}`}></div>
    )
  }
}

export type columnType<T, N> = Omit<AnyColumnProps<T, N>, 'header' | 'accessor'>

function EditableTanstackTable<T, N extends object>(props: {
  fullPage?: boolean
  getRowId?: (originalRow: T, index: number, parent?: Row<T> | undefined) => string
  noFilter?: boolean
  columns: ColumnDef<T>[]
  tableKey?: string
  confirmOnLeave?: boolean
  maxHeightStyle?: string
  data: T[]
  stickyHeader?: boolean
  stickyColumn?: boolean
  onRowCreate?: (createdObject: N) => void
  bgColor?: string
  filterFns?: Record<string, FilterFn<any>> | undefined
  sortingFns: Record<string, (a: T, b: T, field: string) => number>
  maxHeight?: string
  defaultSorting?: SortingState
  defaultFilters?: TableFilterModel[][]
  defaultFiltersExtensionMap?: { [key: string]: TableFilterModel[][] }
  allowFilterByListEntity?: 'company' | 'fundingRound'
  defaultColumnVisibility?: {
    [field: string]: boolean
  }
  disabledSave?: boolean
  defaultCellBg?: string
  defaultColumnOrder?: string[]
  defaultSortingKey?: keyof T & string
  defaultGlobalSearchString?: string
  defaultColumnSize?: number
  onSave?: (
    columnVisibility: { [field: string]: boolean },
    columnOrder: string[],
    sortingState: SortingState,
    filters: TableFilterModel[][],
    globalSearchString: string,
    columnSizes: { [field: string]: number },
  ) => void
  onCreate?: (
    columnVisibility: { [field: string]: boolean },
    columnOrder: string[],
    sortingState: SortingState,
    filters: TableFilterModel[][],
    tableName: string,
    globalSearchString: string,
    columnSizes: { [field: string]: number },
  ) => void
  isSaving?: boolean
  canSave?: boolean
  isSavingFollow?: boolean
  tableNameNode?: React.ReactNode
  borders?: 'all' | 'top' | 'none'
  isLoading?: boolean
  createFloatingFooterButtons?: (selectedRows: Row<T>[], deselectAll: () => void) => React.ReactNode
  paginate?: boolean
  enableMultiRowSelection?: boolean | ((row: Row<T>) => boolean) | undefined
  expandedByDefault?: boolean
  indicationData?: IndicationDataModel
  createChart?: (selectedRows: Row<T>[]) => React.ReactNode
}) {
  const defaultFilterFns: typeof props.filterFns = {
    dateFn: (row: Row<T>, columnId: string, filterValue: string[]) => {
      let [operator, filterDate, secondaryFilterDate] = filterValue
      if (!isSome(filterDate) && operator !== 'isSome' && operator !== 'isEmpty') return true
      else if (!row.getValue(columnId)) return false
      let rowDate = new Date(row.getValue(columnId)! as unknown as string)
      let secondDate = new Date(filterDate)
      if (operator === 'before') {
        return rowDate.getTime() < secondDate.getTime()
      } else if (operator === 'after') {
        return rowDate.getTime() > secondDate.getTime()
      } else if (operator === 'between') {
        if (!secondaryFilterDate) return false
        let thirdDate = new Date(secondaryFilterDate)
        return rowDate.getTime() > secondDate.getTime() && rowDate.getTime() < thirdDate.getTime()
      } else if (operator === 'daysAgo') {
        let today = new Date()
        return rowDate.getTime() > today.getTime() - parseInt(filterDate) * 24 * 60 * 60 * 1000
      } else if (operator === 'betweenDaysAgo') {
        if (!secondaryFilterDate || !filterDate) return false
        let today = new Date()
        let higher =
          parseInt(filterDate) > parseInt(secondaryFilterDate) ? parseInt(filterDate) : parseInt(secondaryFilterDate)
        let lower =
          parseInt(filterDate) < parseInt(secondaryFilterDate) ? parseInt(filterDate) : parseInt(secondaryFilterDate)
        let daysAgo = (today.getTime() - rowDate.getTime()) / (24 * 60 * 60 * 1000)
        return daysAgo > lower && daysAgo < higher
      } else if (operator === 'isEmpty') {
        return row.getValue(columnId) == null
      } else if (operator === 'isSome') {
        return isSome(row.getValue(columnId))
      } else if (operator === 'on') {
        return (
          rowDate.getFullYear() === secondDate.getFullYear() &&
          rowDate.getMonth() === secondDate.getMonth() &&
          rowDate.getDate() === secondDate.getDate()
        )
      }

      return false
    },
    exclusiveSelectFn: (row: Row<T>, columnId: string, filterValue: string[]) => {
      let [operator, fValue] = filterValue
      if (!isSome(fValue)) return true
      let value = `${row.getValue(columnId) as unknown as string}`
      if (operator === 'isNot') {
        return value !== fValue
      }
      return value === fValue
    },
    numberFn: (row: Row<T>, columnId: string, filterValue: string[]) => {
      let [operator, filterNumber, secondaryFilterNumber] = filterValue
      if (!isSome(filterNumber) || filterNumber === '') return true
      if (!row.getValue(columnId) && !filterNumber) return true
      else if (!row.getValue(columnId)) return false
      if (operator === 'greaterThan') {
        return (row.getValue(columnId) as unknown as number) > parseFloat(filterNumber)
      } else if (operator === 'lessThan') {
        return (row.getValue(columnId) as unknown as number) < parseFloat(filterNumber)
      } else if (operator === 'greaterThanOrEqual') {
        return (row.getValue(columnId) as unknown as number) >= parseFloat(filterNumber)
      } else if (operator === 'lessThanOrEqual') {
        return (row.getValue(columnId) as unknown as number) <= parseFloat(filterNumber)
      } else if (operator === 'between') {
        if (!secondaryFilterNumber) return false
        return (
          (row.getValue(columnId) as unknown as number) >= parseFloat(filterNumber) &&
          (row.getValue(columnId) as unknown as number) <= parseFloat(secondaryFilterNumber)
        )
      }

      return (row.getValue(columnId) as unknown as number) === parseFloat(filterNumber)
    },
    singleSelectFn: (row: Row<T>, columnId: string, filterValue: string[]) => {
      if (!isSome(filterValue) || filterValue.filter((f) => f).length === 0) return true
      let includes = false
      for (let value of filterValue) {
        if (value == (row.getValue(columnId) as unknown as string)) {
          includes = true
        }
      }
      return includes
    },
    booleanFn: (row: Row<T>, columnId: string, filterValue: string[]) => {
      if (!isSome(filterValue) || filterValue.filter((f) => f).length === 0) return true
      let includes = false
      for (let value of filterValue) {
        if (value === `${row.getValue(columnId) as unknown as string}`) {
          includes = true
        }
      }
      return includes
    },
    stringFn: (row: Row<T>, columnId: string, filterValue: string[]) => {
      const operators = ['containsAllOf', 'containsSomeOf', 'isEmpty', 'isSome']
      let [operator, ...values] = filterValue

      if (!operators.includes(operator) && operator) {
        return (row.getValue(columnId) as unknown as string).includes(operator)
      }
      if (operator === 'isEmpty') {
        return row.getValue(columnId) == null
      }
      if (operator === 'isSome') {
        return row.getValue(columnId) != null
      }
      if (!isSome(values) || values.filter((f) => f).length === 0) return true
      if (operator === 'containsAllOf' || !operator) {
        return values
          .filter((value) => value)
          .every((value) => ((row.getValue(columnId) ?? '') as string).includes(value))
      }
      if (operator === 'containsSomeOf') {
        return values
          .filter((value) => value)
          .some((value) => ((row.getValue(columnId) ?? '') as string).includes(value))
      }
      return false
    },
  }

  const initialSortState = useMemo(() => props.defaultSorting ?? { id: '', desc: false }, [props.defaultSorting])
  const [sortState, setSortState] = useState<{
    id: string
    desc: boolean
  }>(initialSortState)

  function isImmovableColumn(columnName: string) {
    return props.columns.find((column) => column.id === columnName)?.meta?.isImmovable ?? false
  }
  const initialGlobalSearchString = useMemo(
    () => props.defaultGlobalSearchString ?? '',
    [props.defaultGlobalSearchString],
  )
  const [globalSearchString, setGlobalSearchString] = useState<string>(initialGlobalSearchString)
  useEffect(() => {
    setGlobalSearchString(initialGlobalSearchString)
  }, [initialGlobalSearchString])

  function getToggleSorting(id: string, desc?: boolean) {
    return () => {
      if (desc !== undefined) {
        setSortState({ id, desc })
      } else if (sortState.id === id && sortState.desc) {
        setSortState({ id, desc: false })
      } else if (sortState.id === id) {
        setSortState({
          id: props.defaultSortingKey ?? '',
          desc: true,
        })
      } else {
        setSortState({ id, desc: true })
      }
    }
  }

  const [creatingObject, setCreatingObject] = useState<any>({})
  const [isAddingRow, setIsAddingRow] = useState(false)

  let initialColumnVisibility = useMemo(
    () =>
      props.columns.reduce(
        (acc, column) => {
          let existingKeys = Object.keys(props.defaultColumnVisibility ?? {})
          if (column.id && !existingKeys.includes(column.id)) {
            acc[column.id] = true
          }
          return acc
        },
        (props.defaultColumnVisibility ?? {}) as Record<string, boolean>,
      ),
    [props.columns, props.defaultColumnVisibility],
  )
  const [columnVisibility, setColumnVisibility] = useState<{ [field: string]: boolean }>(initialColumnVisibility)

  let initialColumnOrder = useMemo(() => {
    let defaultColumnArr = props.defaultColumnOrder ?? []
    return props.columns
      .sort((a, b) => {
        return (
          (defaultColumnArr.indexOf(a.id ?? 'unknown') === -1 ? 100_000 : defaultColumnArr.indexOf(a.id ?? 'unknown')) -
          (defaultColumnArr.indexOf(b.id ?? 'unknown') === -1 ? 100_000 : defaultColumnArr.indexOf(b.id ?? 'unknown'))
        )
      })
      .map((column) => column.id ?? '')
  }, [props.columns, props.defaultColumnOrder])

  const [columnOrder, setColumnOrder] = useState<string[]>(initialColumnOrder)

  let defaultCellBg = props.defaultCellBg ?? BlueCellColor

  function renderCreateableCell<T, N>(column: columnType<T, N> | undefined, columnIndex: number) {
    if (!column) {
      return <div className="pl-2 opacity-60"></div>
    }
    if (isColumnCreateable(column as unknown as AnyColumnProps<T, N>)) {
      let columnInputType = columnPropsInputType(column as unknown as AnyColumnProps<T, N>)
      let createProps = column.createProps as CreateColumnProps<string, N>
      if (columnInputType === ColumnInputType.EditableMultiSelect) {
        let _column = column as EditableMultiSelectColumnProps<T, N>
        let options = typeof _column.options === 'function' ? _column.options(creatingObject) : _column.options

        return (
          <MultiSelectCell
            key={`new-content-${_column.header}`}
            options={options}
            initiallySelected={createProps.defaultOption ? createProps.defaultOption : ''}
            onSelect={(newValue: string) => {
              setCreatingObject({ ...creatingObject, [createProps.createdObjectKey]: newValue })
            }}
          />
        )
      } else if (columnInputType === ColumnInputType.EditableAutoComplete) {
        if (columnInputType === ColumnInputType.EditableAutoComplete) {
          let _column = column as EditableAutoCompleteColumnProps<T, N>
          return (
            <AutoCompleteCell
              key={`new-content-${_column.header}`}
              options={_column.autoCompleteOptions}
              placeholder={_column.placeholder}
              onSelect={(newValue) => {
                setCreatingObject({ ...creatingObject, [createProps.createdObjectKey]: newValue })
              }}
            />
          )
        }
      } else if (columnInputType === ColumnInputType.EditableBoolean) {
        let _column = column as EditableBooleanColumnProps<T, N>
        let isSelected = _column.createProps?.defaultOption === true
        return (
          <BooleanCell
            key={`new-content-${_column.header}`}
            isSelected={isSelected}
            onSelect={(value) => {
              setCreatingObject({ ...creatingObject, [createProps.createdObjectKey]: value })
            }}
          />
        )
      } else if (
        columnInputType === ColumnInputType.EditableInput ||
        columnInputType === ColumnInputType.NonEditableInput
      ) {
        let _column = column as AnyTextColumnProps<T, N>
        let shouldFocus = columnIndex === 0

        return (
          <EditableTextCell
            cellFormat={_column.textCellFormat as any}
            placeholder={_column.placeholder ? _column.placeholder : ''}
            initialValue={_column?.createProps?.defaultOption}
            shouldFocus={shouldFocus}
            isCreating={true}
            onEditsFinished={(newValue: any) => {
              setCreatingObject({ ...creatingObject, [createProps.createdObjectKey]: newValue })
            }}
          />
        )
      }
    } else {
      return <div className="pl-2 opacity-60">auto</div>
    }
  }
  function AddRowCell() {
    return (
      <div
        className="cursor-pointer text-sm opacity-70"
        onClick={() => {
          let newObject: any = {}
          for (let column of props.columns) {
            if (!column.meta) {
              break
            }
            if (isColumnCreateable(column.meta as unknown as AnyColumnProps<T, N>)) {
              let createProps = column.meta?.createProps as CreateColumnProps<any, N>
              let defaultOption = createProps.defaultOption
              if (isSome(defaultOption)) {
                newObject[createProps.createdObjectKey] = defaultOption
              }
            }
          }
          setCreatingObject(newObject)
          setIsAddingRow(true)
        }}>
        + Add
      </div>
    )
  }

  const defaultColumn: Partial<ColumnDef<T>> = {
    cell: (cell) => {
      const initialValue = cell.getValue()
      let _column = cell.column.columnDef.meta!
      return renderEditableCell(
        initialValue,
        _column as unknown as columnType<T, N>,
        cell.row.original,
        props.getRowId,
        defaultCellBg,
      )
    },
    size: props.defaultColumnSize ?? 150,
    minSize: 80,
    maxSize: 500,
    enableGlobalFilter: false,
  }

  const initialFilters: TableFilterModel[][] = useMemo(
    () => props.defaultFilters ?? [[{ field: '', value: [''], id: uuid(), operatorType: 'includesString' }]],
    [props.defaultFilters],
  )

  const [filters, setFilters] = useState<TableFilterModel[][]>(initialFilters)

  const initialExtendedFiltersMap = useMemo(
    () => props.defaultFiltersExtensionMap ?? {},
    [props.defaultFiltersExtensionMap],
  )
  const [extendedFiltersMap, setExtendedFiltersMap] = useState(initialExtendedFiltersMap)

  const tableMeta = useMemo(
    () => ({
      columnFilters: filters
        .map((filter) =>
          filter.filter(
            (f) =>
              (props.columns.find((column) => column.id === f.field) ||
                f.field === SpecialFilterKey ||
                f.field === SpecialIndicationFilterKey) &&
              f.value.length > 0,
          ),
        )
        .filter((filter) => filter.length > 0),
      // HERE maybe adjust above/ look into above, take props of columnFilterKeys process and pass into the meta
      // process this like the above filters
      extendedFiltersMap: extendedFiltersMap,
      indicationData: props.indicationData,
    }),
    [filters, props.columns, extendedFiltersMap, props.indicationData],
  )

  const customSortedRowModel = useMemo(() => {
    const defaultSortingFns = {
      dateFn: (a: T, b: T, accessor: string | ((obj: T) => any)) => {
        let aVal = typeof accessor === 'function' ? accessor(a) : a[accessor as keyof T]
        let bVal = typeof accessor === 'function' ? accessor(b) : b[accessor as keyof T]
        if (!aVal && !b[accessor as keyof T]) {
          return 0
        }
        if (!aVal) {
          return -1
        }
        if (!bVal) {
          return 1
        }
        let firstDate = new Date(aVal as unknown as string)
        let secondDate = new Date(bVal as unknown as string)
        return firstDate.getTime() - secondDate.getTime()
      },
      numberFn: (a: T, b: T, accessor: string | ((obj: T) => any)) => {
        let aVal = typeof accessor === 'function' ? accessor(a) : a[accessor as keyof T]
        let bVal = typeof accessor === 'function' ? accessor(b) : b[accessor as keyof T]
        return (getNumber(aVal) ?? 0) - (getNumber(bVal) ?? 0)
      },
      booleanFn: (a: T, b: T, accessor: string | ((obj: T) => any)) => {
        let aVal = typeof accessor === 'function' ? accessor(a) : a[accessor as keyof T]
        let bVal = typeof accessor === 'function' ? accessor(b) : b[accessor as keyof T]
        return (aVal ? 1 : 0) - (bVal ? 1 : 0)
      },
      stringFn: (a: T, b: T, accessor: string | ((obj: T) => any)) => {
        let aVal = typeof accessor === 'function' ? accessor(a) : a[accessor as keyof T]
        let bVal = typeof accessor === 'function' ? accessor(b) : b[accessor as keyof T]
        return ((bVal || 'ZZZ') as string).localeCompare((aVal || 'ZZZ') as string)
      },
    }

    const baseSortFunction = (a: T, b: T) => {
      let descMultiplier = sortState.desc ? -1 : 1
      if (!sortState.id) {
        return 0
      } else {
        let column = props.columns.find((column) => column.id === sortState.id)
        // @ts-ignore
        let accessorFn = column?.accessorFn
        // @ts-ignore
        let accessorKey = column?.accessorKey
        let accessor: (obj: T) => any = accessorFn ?? accessorKey

        if (column && sortingFns[(column.sortingFn?.toString() ?? '') as keyof typeof sortingFns]) {
          let sortingFn = sortingFns![(column.sortingFn?.toString() ?? '') as keyof typeof sortingFns]
          if (sortingFn) {
            return sortingFn(a, b, accessor ?? sortState.id) * descMultiplier
          } else if (props.defaultSortingKey) {
            if (typeof a[props.defaultSortingKey!] === 'string' && typeof b[props.defaultSortingKey!] === 'string') {
              return ((b[props.defaultSortingKey!] || '') as string).localeCompare(
                (a[props.defaultSortingKey!] || '') as string,
              )
            }
            return (
              (a[props.defaultSortingKey!] as unknown as number) - (b[props.defaultSortingKey!] as unknown as number)
            )
          } else {
            return 0
          }
        }
      }
      return 0
    }

    const sortingFns = { ...defaultSortingFns, ...props.sortingFns }

    return [...props.data].sort(baseSortFunction).map((row) => {
      return {
        ...row,
        subRows: row['subRows' as keyof T]
          ? (row['subRows' as keyof T] as unknown as T[]).sort(baseSortFunction)
          : row['subRows' as keyof T],
      }
    })
  }, [props.data, sortState, props.columns, props.sortingFns, props.defaultSortingKey])
  let { pathname } = useLocation()

  // useEffect(() => {
  //   setIsJustLoaded(true)
  //   const timer = setTimeout(() => {
  //     setIsJustLoaded(false)
  //   }, 2000)
  //   // Cleanup the timer
  //   return () => clearTimeout(timer)
  // }, [pathname])
  const initialColumnSizes = useMemo(
    () =>
      props.columns.reduce(
        (acc, column) => {
          if (column.size && column.id) acc[column.id] = column.size
          return acc
        },
        {} as { [key: string]: number },
      ),
    [props.columns],
  )
  const [columnSizes, setColumnSizes] = useState(initialColumnSizes)
  const tableColumns = useMemo(() => props.columns, [props.columns])
  const table = useReactTable<T>({
    enableSubRowSelection: false,
    enableMultiRowSelection: props.enableMultiRowSelection,
    columns: tableColumns,
    data: customSortedRowModel,
    getRowId: props.getRowId,
    defaultColumn,
    globalFilterFn: 'includesString',
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getTableFilteredRows,
    getExpandedRowModel: getExpandedRowModel(),
    getPaginationRowModel: props.paginate ? getPaginationRowModel() : undefined,
    getSubRows: (row) => row['subRows' as keyof T] as unknown as T[],
    initialState: props.paginate
      ? {
          pagination: {
            pageSize: 50,
          },
        }
      : {},
    state: {
      columnOrder,
      columnVisibility,
      globalFilter: globalSearchString,
      columnSizing: columnSizes,
    },
    onColumnSizingChange: setColumnSizes,
    filterFns: { ...defaultFilterFns, ...props.filterFns },
    meta: tableMeta,
    columnResizeMode: 'onChange',
  })

  useEffect(() => {
    if (props.expandedByDefault !== false) table.toggleAllRowsExpanded()
  }, [table])

  // ------------------ reinitialize state when we change path //hacky this needs work

  useEffect(() => {
    setSortState(initialSortState)
  }, [pathname, props.tableKey])
  useEffect(() => {
    setFilters(initialFilters)
  }, [pathname, props.tableKey])
  useEffect(() => {
    setFilters(initialFilters)
  }, [JSON.stringify(props.defaultFilters)])
  useEffect(() => {
    setColumnVisibility(initialColumnVisibility)
  }, [pathname, props.tableKey])

  useEffect(() => {
    setColumnOrder(initialColumnOrder)
  }, [pathname, props.tableKey])

  useEffect(() => {
    setColumnSizes(initialColumnSizes)
  }, [pathname, props.tableKey])
  useEffect(() => {
    setExtendedFiltersMap(initialExtendedFiltersMap)
  })
  // ------------------

  let isUnsaved = useMemo(() => {
    let isFiltersSaved = _.isEqual(filters, initialFilters)
    let isSortSaved = _.isEqual(sortState, initialSortState)
    let isColumnVisibilitySaved = _.isEqual(columnVisibility, initialColumnVisibility)
    let isColumnOrderSaved = _.isEqual(columnOrder, initialColumnOrder)
    let isGlobalSearchSaved = globalSearchString === initialGlobalSearchString
    let isColumnSizesSaved = _.isEqual(initialColumnSizes, columnSizes)
    return (
      !isFiltersSaved ||
      !isSortSaved ||
      !isColumnVisibilitySaved ||
      !isColumnOrderSaved ||
      !isGlobalSearchSaved ||
      !isColumnSizesSaved
    )
  }, [
    filters,
    initialFilters,
    sortState,
    initialSortState,
    columnVisibility,
    initialColumnVisibility,
    columnOrder,
    initialColumnOrder,
    globalSearchString,
    initialGlobalSearchString,
    initialColumnSizes,
    columnSizes,
  ])
  useNavigationBlocker(isUnsaved && !!props.confirmOnLeave && !!props.canSave && !props.isLoading)
  useNavigationBlockerText('You have unsaved changes. Are you sure you want to leave?')
  let isSmallScreen = useIsSmallScreen('md')
  const rowHeightValue = 40

  const rowHeight = `${rowHeightValue}px`

  function isSortableColumn(column: ColumnDef<T>) {
    return column.id && column.header && column.sortingFn
  }
  let orderableColumns = props.columns.filter((column) => isSortableColumn(column))

  let columns = useMemo(() => props.columns, [props.columns])

  let [createNewTableModalOpen, setCreateNewTableModalOpen] = useState(false)
  function renderColumnHeader(
    header: Header<T, unknown>,
    borderRightClass: string,
    first: boolean,
    columnIndex: number,
    last: boolean,
  ) {
    const widthPixels = columnSizes[header.id]
    return (
      <th
        colSpan={header.colSpan}
        className={`w-25vw border-b ${borderRightClass} ${props.stickyHeader ? 'sticky top-0' : ''}  bg-white ${isSortableColumn(header.column.columnDef) ? 'cursor-pointer hover:bg-gray-100' : ''}  ${first && (props.stickyColumn || props.stickyHeader) ? 'sticky z-20' : 'z-10'} ${header.column.columnDef.meta?.headerClassName ? header.column.columnDef.meta.headerClassName : ''}  text-left font-medium text-table-text`}
        key={`column-header-${header.id}`}
        style={{
          minWidth: widthPixels ? `${widthPixels}px` : 'auto',
          height: rowHeight,
          maxWidth: widthPixels ? `${widthPixels}px` : 'auto',
        }}>
        {isSortableColumn(header.column.columnDef) ? (
          <div className="row flex flex items-center justify-between">
            {header.column.columnDef.meta?.selectAllOptions && (
              <div className="pl-2">
                <InputCheckbox
                  checked={table.getIsAllRowsSelected()}
                  onChange={table.getToggleAllRowsSelectedHandler()}
                />
              </div>
            )}
            <Dropdown
              portal
              button={
                <div className="flex w-full flex-row items-center justify-between p-2">
                  <div
                    className={`text-left text-sm font-medium text-table-text`}
                    onClick={(e) => {
                      e.stopPropagation()
                    }}>
                    {flexRender(header.column.columnDef.header, header.getContext())}
                  </div>
                  {sortState.desc && header.column.id === sortState.id ? (
                    <TriangleDownIcon className={'shrink-0'} />
                  ) : header.column.id === sortState.id ? (
                    <TriangleUpIcon className={'shrink-0'} />
                  ) : (
                    <></>
                  )}
                </div>
              }>
              <div
                className="flex cursor-pointer flex-row items-center justify-between gap-2 p-2 hover:bg-gray-100"
                onClick={getToggleSorting(header.id, false)}>
                <div className="text-sm">Sort Ascending</div>
                <TriangleUpIcon />
              </div>
              <div
                className="flex cursor-pointer flex-row items-center justify-between gap-2 p-2 hover:bg-gray-100"
                onClick={getToggleSorting(header.id, true)}>
                <div className="text-sm">Sort Descending</div> <TriangleDownIcon />
              </div>
              {columnOrder.filter((column, i) => isImmovableColumn(column) && i < columnIndex).length !== columnIndex &&
                !isImmovableColumn(header.column.id) && (
                  <div
                    className="flex cursor-pointer flex-row items-center justify-between gap-2 p-2 hover:bg-gray-100"
                    onClick={() => {
                      let newColumnOrder = [...columnOrder]
                      let index = newColumnOrder.indexOf(header.id)
                      let nextIndexLeft = index - 1
                      let immovableColumnsLeft = columnOrder.filter(
                        (column, i) => isImmovableColumn(column) && i < index,
                      ).length
                      while (nextIndexLeft > immovableColumnsLeft && !columnVisibility[newColumnOrder[nextIndexLeft]]) {
                        nextIndexLeft--
                      }
                      newColumnOrder[index] = newColumnOrder[nextIndexLeft]
                      newColumnOrder[nextIndexLeft] = header.id
                      setColumnOrder(newColumnOrder)
                    }}>
                    <div className="text-sm">Move Left</div>
                    <ArrowLeftIcon />
                  </div>
                )}
              {columnOrder.filter((column, i) => isImmovableColumn(column) && i > columnIndex).length !==
                columnOrder.length - columnIndex - 1 &&
                !isImmovableColumn(header.column.id) && (
                  <div
                    className="flex cursor-pointer flex-row items-center justify-between gap-2 p-2 hover:bg-gray-100"
                    onClick={() => {
                      let newColumnOrder = [...columnOrder]
                      let index = newColumnOrder.indexOf(header.id)
                      let nextIndexRight = index + 1
                      while (
                        nextIndexRight < newColumnOrder.length &&
                        !columnVisibility[newColumnOrder[nextIndexRight]]
                      ) {
                        nextIndexRight++
                      }
                      newColumnOrder[index] = newColumnOrder[nextIndexRight]
                      newColumnOrder[nextIndexRight] = header.id
                      setColumnOrder(newColumnOrder)
                    }}>
                    <div className="text-sm">Move Right</div>
                    <ArrowRightIcon />
                  </div>
                )}
              {!header.column.columnDef.meta?.isUnhideable && (
                <div
                  className="flex cursor-pointer flex-row items-center justify-between gap-2 p-2 hover:bg-gray-100"
                  onClick={() => {
                    setColumnVisibility((prev) => {
                      return {
                        ...prev,
                        [header.id]: false,
                      }
                    })
                  }}>
                  <div className="text-sm">Hide Column</div>
                  <EyeNoneIcon />
                </div>
              )}
            </Dropdown>
            {header.column.getCanResize() && (
              <div
                {...{
                  onDoubleClick: () => header.column.resetSize(),
                  onMouseDown: header.getResizeHandler(),
                  onTouchStart: header.getResizeHandler(),
                  style: { cursor: 'col-resize', width: '10px', height: rowHeight, marginRight: '-2px' },
                  className: `resizer ${header.column.getIsResizing() ? 'isResizing' : ''}`,
                }}></div>
            )}
          </div>
        ) : (
          <div className="flex w-full flex-row items-center justify-between p-4">
            <div className={` pl-2 text-left text-sm font-medium text-table-text`}>
              {flexRender(header.column.columnDef.header, header.getContext())}
            </div>
            {header.column.getCanResize() && (
              <div
                {...{
                  onDoubleClick: () => header.column.resetSize(),
                  onMouseDown: header.getResizeHandler(),
                  onTouchStart: header.getResizeHandler(),
                  style: { cursor: 'col-resize', width: '10px', height: rowHeight, marginRight: '-2px' },
                  className: `resizer ${header.column.getIsResizing() ? 'isResizing' : ''}`,
                }}></div>
            )}
          </div>
        )}
      </th>
    )
  }

  let memoizedRows = useMemo(() => table.getFilteredRowModel().rows, [table.getFilteredRowModel()])

  return (
    <div className="flex flex-col">
      {props.createChart ? props.createChart(memoizedRows) : <></>}
      {props.createFloatingFooterButtons && (
        <FloatingFooter numberSelected={table.getSelectedRowModel().flatRows.length}>
          {props.createFloatingFooterButtons(table.getSelectedRowModel().flatRows, () =>
            table.toggleAllPageRowsSelected(false),
          )}
        </FloatingFooter>
      )}
      {!props.noFilter && (
        <div className="flex flex-col">
          {props.tableNameNode && (
            <div className={`flex w-full flex-row items-center gap-2 border-b ${props.fullPage ? 'px-6' : ''} py-3`}>
              {props.tableNameNode}
            </div>
          )}

          <div className="flex w-full flex-row items-center">
            <div className={`mr-auto flex flex-row items-center gap-1  py-3 ${props.fullPage ? 'px-6' : ''}`}>
              {props.columns.find((column) => column.id === sortState.id)?.header?.toString() && (
                <SortedByDiv
                  sortedBy={props.columns.find((column) => column.id === sortState.id)?.header?.toString() ?? ''}
                  desc={sortState.desc}></SortedByDiv>
              )}
              <VerticalBar />

              <FiltersModalButton
                addToFiltersExtension={(value, key) => {
                  setExtendedFiltersMap((prev) => ({
                    ...prev,
                    [key]: value,
                  }))
                }}
                allowAdditionalLists={props.allowFilterByListEntity}
                columns={columns.filter((column) => column.filterFn)}
                filters={filters}
                setFilters={setFilters}
              />
            </div>

            <div className={`ml-auto flex flex-row items-center justify-center gap-1 ${props.fullPage ? 'px-6' : ''}`}>
              <Dropdown
                position="left"
                portal
                button={
                  <IconButton variant="ghost" color="gray" size={'4'} style={{ margin: '0px 5px' }}>
                    <MagnifyingGlassIcon />
                  </IconButton>
                }>
                <AppTextField
                  placeholder="Search"
                  value={globalSearchString}
                  onChange={(e) => setGlobalSearchString(e.target.value)}
                  startAdornment={<MagnifyingGlassIcon />}
                  style={{ width: '250px' }}
                />
              </Dropdown>
              <div className="ml-auto flex items-center">
                <ColumnsDropdown
                  columnOrder={columnOrder}
                  setColumnOrder={setColumnOrder}
                  columns={orderableColumns}
                  columnVisibility={columnVisibility}
                  setColumnVisibility={setColumnVisibility}
                />
              </div>
              {props.onCreate && (
                <CreateNewSheetModal
                  setIsOpen={isSmallScreen ? setCreateNewTableModalOpen : undefined}
                  open={isSmallScreen ? createNewTableModalOpen : undefined}
                  onCreateSheet={(newSheetName) => {
                    props.onCreate!(
                      columnVisibility,
                      columnOrder,
                      sortState,
                      filters,
                      newSheetName,
                      globalSearchString,
                      columnSizes,
                    )
                  }}
                />
              )}
              {isSmallScreen ? (
                <>
                  <Dropdown
                    align="end"
                    button={
                      <div className={attioStyleString}>
                        <DotsVerticalIcon className="h-[15px] w-[15px]" />
                      </div>
                    }>
                    {props.onCreate && (
                      <div
                        onClick={() => {
                          setCreateNewTableModalOpen(true)
                        }}
                        className="flex cursor-pointer items-center gap-2 p-1 text-sm hover:bg-gray-100">
                        <BadgePlus className="h-[15px] w-[15px]" />
                        Save as New List
                      </div>
                    )}
                    <div
                      className={`flex items-center gap-2 p-1 text-sm  ${
                        !isUnsaved || props.disabledSave
                          ? 'cursor-not-allowed opacity-50'
                          : 'cursor-pointer hover:bg-gray-100'
                      }`}
                      onClick={() => {
                        if (props.onSave && isUnsaved && !props.disabledSave) {
                          props.onSave(
                            columnVisibility,
                            columnOrder,
                            sortState,
                            filters,
                            globalSearchString,
                            columnSizes,
                          )
                        }
                      }}>
                      <SaveIcon className="h-[15px] w-[15px]" />
                      Save
                    </div>
                  </Dropdown>
                </>
              ) : (
                <>
                  {props.onSave &&
                    (!props.canSave ? (
                      <></>
                    ) : (
                      <BasicButton
                        attioStyle
                        disabled={!isUnsaved || props.disabledSave}
                        variant="gray"
                        isLoading={props.isSaving}
                        onClick={() => {
                          if (props.onSave) {
                            props.onSave(
                              columnVisibility,
                              columnOrder,
                              sortState,
                              filters,
                              globalSearchString,
                              columnSizes,
                            )
                          }
                        }}>
                        <SaveIcon className="h-[15px] w-[15px]" />
                        Save
                      </BasicButton>
                    ))}
                </>
              )}
            </div>
          </div>
        </div>
      )}
      <div className="w-full">
        <div
          style={props.maxHeightStyle ? { maxHeight: props.maxHeightStyle } : {}}
          className={`relative w-full overflow-auto ${props.borders === 'all' || props.borders === undefined ? 'rounded-md border' : props.borders === 'top' ? 'border-t' : ''} ${props.maxHeight}`}>
          <table className="m-0 min-w-full table-fixed border-separate border-spacing-0 whitespace-nowrap border-none">
            <thead>
              {table.getHeaderGroups().map((headerGroup, i) => {
                return (
                  <tr key={`${headerGroup.id}-${i}`} className="column-headers">
                    {headerGroup.headers.map((header, i) => {
                      let length = headerGroup.headers.length
                      return renderColumnHeader(
                        header,
                        i === length - 1 ? '' : 'border-r',
                        i === 0,
                        i,
                        i === length - 1,
                      )
                    })}
                  </tr>
                )
              })}
            </thead>
            <tbody>
              {props.isLoading
                ? Array.from({ length: 2 }).map(() => (
                    <tr style={{ height: rowHeight }}>
                      {Array.from({ length: table.getAllLeafColumns().length }).map(() => (
                        <td className={`px-2 py-4`}>
                          <Skeleton className="min-h-4" />
                        </td>
                      ))}
                    </tr>
                  ))
                : table.getRowModel().rows.map((row, i) => {
                    return (
                      <tr key={`${row.id}-${i}`} className={`text-sm`} style={{ height: rowHeight }}>
                        {row.getVisibleCells().map((cell, i) => {
                          let length = row.getVisibleCells().length
                          let size = columnSizes[cell.column.id]
                          return (
                            <td
                              style={{ minWidth: size ? `${size}px` : 'auto', maxWidth: size ? `${size}px` : 'auto' }}
                              key={cell.id}
                              className={`border-b ${i !== length - 1 ? 'border-r' : ''} ${cell.column.columnDef.meta?.className ?? defaultCellBg}`}>
                              {flexRender(cell.column.columnDef.cell, cell.getContext())}
                            </td>
                          )
                        })}
                      </tr>
                    )
                  })}
              {props.onRowCreate && !isAddingRow && (
                <tr className={` text-sm`} key={`create`} style={{ height: rowHeight }}>
                  {isTrue(props.stickyColumn) ? (
                    <th
                      className={`sticky left-0 z-10 border-r  ${defaultTableCellPadding} text-left font-normal ${props.columns[0]?.meta?.headerClassName ?? ''}`}
                      key={`add-row`}>
                      <AddRowCell />
                    </th>
                  ) : (
                    <td className={`border-r ${defaultTableCellPadding} text-left`} key={`add-row`}>
                      <AddRowCell />
                    </td>
                  )}
                  {[...Array(props.columns.length)].map((_, index) => {
                    const borderRightClass = index !== props.columns.length - 1 ? `border-r` : ''
                    return (
                      <td
                        className={`${borderRightClass} ${defaultTableCellPadding} text-left`}
                        key={`create-${index}`}>
                        <div />
                      </td>
                    )
                  })}
                </tr>
              )}
              {isAddingRow && (
                <tr className={`text-sm`} key={`row-isAdding`} style={{ height: rowHeight }}>
                  {table.getHeaderGroups()[0].headers.map((header, i) => {
                    let size = header.getSize()
                    let column = header.column.columnDef
                    return i === 0 && props.stickyColumn ? (
                      <th
                        style={{ minWidth: size ? `${size}px` : 'auto' }}
                        className={`sticky left-0 z-10 border-b border-r text-left font-normal`}
                        key={`cell-adding-${i}-${column.id}`}>
                        <div
                          className={`focus-within:border-light-blue-500 z-20  flex h-[${rowHeightValue - 6}px] items-center justify-center bg-white  focus-within:bg-white focus-within:ring-2`}>
                          {renderCreateableCell(column.meta as columnType<T, N>, i)}
                        </div>
                      </th>
                    ) : (
                      <td
                        className={`border-b border-r text-left`}
                        key={`cell-adding-${i}-${column.id}`}
                        style={{ minWidth: size ? `${size}px` : 'auto' }}>
                        <div
                          className={`focus-within:border-light-blue-500 z-20 flex h-[${rowHeightValue - 6}px] items-center justify-center bg-white  focus-within:bg-white focus-within:ring-2`}>
                          {renderCreateableCell(column.meta as columnType<T, N>, i)}
                        </div>
                      </td>
                    )
                  })}
                </tr>
              )}
              {isAddingRow && (
                <tr className="cursor-pointer">
                  <td className="p-2">
                    <BasicButton
                      variant="gray"
                      className="text-sm"
                      onClick={() => {
                        if (props.onRowCreate) {
                          for (let column of props.columns) {
                            if (!column.meta) {
                              break
                            } else if (isColumnCreateable(column.meta as unknown as AnyColumnProps<T, N>)) {
                              let createProps = column.meta?.createProps
                              if (createProps?.isRequired && isNone(creatingObject[createProps.createdObjectKey])) {
                                return
                              }
                            }
                          }
                          props.onRowCreate(creatingObject)
                        }
                        setIsAddingRow(false)
                        setCreatingObject({})
                      }}>
                      Submit row
                    </BasicButton>
                    <BasicButton
                      className={`ml-2  border-red-500 bg-red-500 text-sm`}
                      onClick={() => {
                        setIsAddingRow(false)
                        setCreatingObject({})
                      }}>
                      Cancel
                    </BasicButton>
                  </td>
                </tr>
              )}
              {props.paginate && props.fullPage && <tr className="h-[40px]"></tr>}
            </tbody>
          </table>
          {props.paginate && (
            <TableFooter
              absolute={props.fullPage}
              rowCount={table.getFilteredRowModel().rows.length}
              currentPage={table.getState().pagination.pageIndex + 1}
              pageCount={table.getPageCount()}
              pageSize={table.getState().pagination.pageSize}
              onNextPage={table.getCanNextPage() ? table.nextPage : undefined}
              onPreviousPage={table.getCanPreviousPage() ? table.previousPage : undefined}
              isLoading={props.isLoading}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export function DeleteCell(props: {
  onDelete: () => void
  confirmationModalProps?: Omit<ConfirmationModalProps, 'onConfirm' | 'button'>
}) {
  return (
    <td className={`${defaultTableCellPadding} pr-4 text-left`}>
      {props.confirmationModalProps != null ? (
        <ConfirmationModalButton
          {...props.confirmationModalProps}
          onConfirm={props.onDelete}
          button={
            <BasicButton className="text-xs" variant="secondary" onClick={() => {}}>
              Delete
            </BasicButton>
          }
        />
      ) : (
        <BasicButton
          className="text-xs"
          variant="secondary"
          onClick={() => {
            props.onDelete()
          }}>
          Delete
        </BasicButton>
      )}
    </td>
  )
}

function VerticalBar() {
  return <div className="mx-1 h-[25px] w-[1px] rounded-full bg-gray-200"></div>
}

type TableFooterProps = {
  rowCount: number
  pageSize: number
  pageCount: number
  currentPage: number
  onNextPage?: () => void
  onPreviousPage?: () => void
  absolute?: boolean
  isLoading?: boolean
}
export function TableFooter(props: TableFooterProps) {
  let startRowI = (props.currentPage - 1) * props.pageSize + 1
  let endRowI = startRowI + props.pageSize - 1
  if (endRowI > props.rowCount) {
    endRowI = props.rowCount
  }
  return (
    <div
      className={`flex min-h-[40px] flex-row items-center justify-between gap-4 border-t ${props.absolute ? 'fixed bottom-[0px] left-[220px] z-[45]  ml-auto w-[calc(100%-220px)] bg-white px-6 py-2 pb-[10px]' : 'w-full'}`}>
      <div className="flex flex-row items-center gap-2">
        {props.isLoading ? (
          <></>
        ) : props.pageCount === 0 ? (
          <div className="text-base">No results found</div>
        ) : (
          <>
            <div className="text-base">
              {startRowI}
              &nbsp;-&nbsp;
              {endRowI}
            </div>

            <div className="text-sm">&nbsp;of&nbsp;</div>
            <div className="text-base">{props.rowCount}</div>
          </>
        )}
      </div>

      <div className="flex flex-row items-center gap-2">
        <BasicButton attioStyle variant="gray" disabled={props.onPreviousPage == null} onClick={props.onPreviousPage}>
          <ChevronLeftIcon />
        </BasicButton>
        <div className="flex flex-row items-center gap-2">
          <div className="text-base">{props.currentPage}</div>
          &nbsp;<div className="text-sm">of</div>&nbsp;
          <div className="text-base">{props.pageCount}</div>
        </div>
        <BasicButton attioStyle variant="gray" disabled={props.onNextPage == null} onClick={props.onNextPage}>
          <ChevronRightIcon />
        </BasicButton>
      </div>
    </div>
  )
}
export default EditableTanstackTable as typeof EditableTanstackTable

function SortedByDiv(props: { sortedBy: string; desc: boolean }) {
  let [expanded, setExpanded] = useState(false)
  return (
    <div
      onMouseLeave={() => {
        setExpanded(false)
      }}
      className="flex h-[30px] cursor-pointer items-center gap-2 rounded-full bg-gray-200 px-2 py-1 text-xs font-medium shadow-sm"
      onClick={() => {
        setExpanded(true)
      }}>
      <div className="flex flex-row items-center gap-1 text-xs text-gray-600">
        <div className="mt-[-5px] flex items-center">{props.desc ? <TextAlignBottomIcon /> : <TextAlignTopIcon />}</div>
        {expanded && <div>Sorted by</div>}
      </div>
      <div className="text-nowrap text-xs font-normal">
        {props.sortedBy?.length > 7 && !expanded ? props.sortedBy.slice(0, 5) + '...' : props.sortedBy}
      </div>
    </div>
  )
}
