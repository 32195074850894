import { IonItem, IonSpinner } from '@ionic/react'

export const PageLoader = () => {
  return (
    <div className="loader flex flex-col items-center w-full">
      <IonItem style={{ position: 'fixed', top: '18vh' }}>
        <IonSpinner name="lines-sharp"></IonSpinner>
      </IonItem>
    </div>
  )
}
