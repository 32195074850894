export function calculateSharedUpsidePercentage(
  fundingAmount: number,
  baseSharePrice: number,
  exitSharePrice: number,
  yearsInContract: number,
  initialReferenceSecurities: number,
  minimumCagr: number
): number {
  const reserveMultiple =
    (initialReferenceSecurities * baseSharePrice) / fundingAmount;
  const lookbackPPS = lookbackV2SharePrice(
    baseSharePrice,
    exitSharePrice,
    yearsInContract,
    reserveMultiple,
    minimumCagr
  );
  return fundingAmount / lookbackPPS / initialReferenceSecurities;
}

export function lookbackV2SharePrice(
  baseSharePrice: number,
  exitSharePrice: number,
  yearsInContract: number,
  reserveMultiple: number,
  minimumCagr: number
) {
  if (reserveMultiple < 1) {
    throw new Error("reserveMultiple must be >= 1");
  }
  let cagrFromBasePrice =
    Math.pow(exitSharePrice / baseSharePrice, 1 / yearsInContract) - 1;
  //no value share value past 10,000% cagr
  cagrFromBasePrice = Math.min(cagrFromBasePrice, 100);
  let lookbackMultiple = lookbackV2TxMultiple(
    yearsInContract,
    cagrFromBasePrice,
    minimumCagr,
    reserveMultiple
  );
  return baseSharePrice * lookbackMultiple;
}

export function lookbackV2TxMultiple(
  yearsInContract: number,
  cagrFromBasePrice: number,
  fundMinCagr: number,
  reserveMultiple: number
) {
  let companyMultiple = Math.pow(1 + cagrFromBasePrice, yearsInContract);
  let minLookbackMultiple = 1 / reserveMultiple;
  let curveTxMultiple = txMultipleCurve(cagrFromBasePrice, fundMinCagr);
  let targetLookbackMultiple = curveTxMultiple;
  if (yearsInContract < 1) {
    targetLookbackMultiple = Math.pow(targetLookbackMultiple, yearsInContract);
  }
  let fundCagr =
    (companyMultiple / targetLookbackMultiple) ** (1 / yearsInContract) - 1;
  if (fundCagr < fundMinCagr || curveTxMultiple < 1) {
    let fundMult = Math.pow(1 + fundMinCagr, yearsInContract);
    targetLookbackMultiple = companyMultiple / fundMult;
  }
  return Math.max(targetLookbackMultiple, minLookbackMultiple);
}

export function txMultipleCurve(
  cagrFromBasePrice: number,
  fundMinCagr: number = DEFAULT_FUND_MIN_CAGR
): number {
  const curveParams = {
    x: cagrFromBasePrice,
    h: fundMinCagr,
    m: 0.238083099889254,
    a: 4.994673809696096,
    b: 0.761916900110746,
    c: 0.0009,
  };
  const { m, a, b, c, x, h } = curveParams;
  return 1 / (m * Math.exp(-a * (x - h)) + b - c * x);
}

export function autoDetermineBaseSharePriceWithDates(
  contractStartDate: Date,
  mostRecentFinancingEventDate: Date,
  mostRecentFinancingEventIssuePrice: number,
  nextFinancingEventDate: Date,
  nextFinancingEventIssuePrice: number,
  minimumCagr: number
) {
  // calculate years between dates
  let yearsBetweenFinancingEvents =
    (nextFinancingEventDate.getTime() -
      mostRecentFinancingEventDate.getTime()) /
    (1000 * 60 * 60 * 24 * 365.25);
  let yearsBetweenContractStartAndNextFinancingEvent =
    (nextFinancingEventDate.getTime() - contractStartDate.getTime()) /
    (1000 * 60 * 60 * 24 * 365.25);

  let cagrBetweenEvents =
    Math.pow(
      nextFinancingEventIssuePrice / mostRecentFinancingEventIssuePrice,
      1 / yearsBetweenFinancingEvents
    ) - 1;
  const priceUsingActualCagr =
    nextFinancingEventIssuePrice /
    Math.pow(
      1 + cagrBetweenEvents,
      yearsBetweenContractStartAndNextFinancingEvent
    );
  const priceUsingMinimumCagr =
    nextFinancingEventIssuePrice /
    Math.pow(1 + minimumCagr, yearsBetweenContractStartAndNextFinancingEvent);
  if (cagrBetweenEvents < minimumCagr) {
    return priceUsingMinimumCagr;
  } else {
    return priceUsingActualCagr;
  }
}

// -------------------------include down to here in shared upside agreement-----------------------------------

export const DEFAULT_FUND_MIN_CAGR = 0.2;
