import { AlertDialog, Button, Flex } from '@radix-ui/themes'

export type ErrorAlertModalProps = {
  title: string
  body: string
  onClose: () => void
  open: boolean
}
export default function ErrorAlertModal(props: ErrorAlertModalProps) {
  return (
    <AlertDialog.Root open={props.open}>
      <AlertDialog.Content style={{ maxWidth: 450 }}>
        <AlertDialog.Title>{props.title}</AlertDialog.Title>
        <AlertDialog.Description size="2">{props.body}</AlertDialog.Description>
        <Flex gap="3" mt="4" justify="end">
          <AlertDialog.Action>
            <Button variant="soft" color="gray" onClick={props.onClose}>
              Confirm
            </Button>
          </AlertDialog.Action>
        </Flex>
      </AlertDialog.Content>
    </AlertDialog.Root>
  )
}
