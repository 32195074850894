import { SharePriceFromLastRoundCell } from 'components/SharePriceFromLastRoundCell'
import { Table, TableColumnProps } from 'components/Table'
import { useSimulatorContext } from 'pages/cashSimulator/CashSimulatorContext'
import { ValueShareTxWithSecondaryComparable } from 'plural-shared/lookbackV2'
import { numberToAbbreviatedDollarString } from 'plural-shared/utils'

export default function OutcomesTable() {
  const context = useSimulatorContext()
  let txToday = context?.pluralCashTx
  if (!txToday || !context) {
    return null
  }

  const multiples = [2, 1.5, 1, 0.8, 0.5]
  function createOutcomeScenarios(multiples: number[]) {
    return multiples.map((multiple) => {
      return new ValueShareTxWithSecondaryComparable({
        ...txToday!.inputs(),
        companyCagrFromBase: txToday!.inputs().companyCagrFromBase * multiple,
      })
    })
  }

  let transactions = createOutcomeScenarios(multiples)
  const columnHeaderStyle = 'text-sm font-medium'
  const columns: TableColumnProps<ValueShareTxWithSecondaryComparable>[] = [
    {
      header: 'Exit Scenario',
      render: (row: ValueShareTxWithSecondaryComparable) => {
        return (
          <div
            className="flex flex-row items-center gap-1
          ">
            {numberToAbbreviatedDollarString(row.companyOutcomeValuation(), 1, 1)}
            <div className="text-xs text-gray-500">{`in ${context.txInputs.yearsToOutcome.value} years`}</div>
          </div>
        )
      },
    },
    {
      header: 'Effective PPS Today',
      render: (row: ValueShareTxWithSecondaryComparable) => {
        return (
          <SharePriceFromLastRoundCell
            sharePriceFromLastRound={txToday!.inputs().baseSharePrice}
            sharePriceToday={row.cashAdjustedTxSharePrice()}
          />
        )
      },
    },
    {
      header: 'Valuation Today',
      render: (row: ValueShareTxWithSecondaryComparable) => {
        return <div>{numberToAbbreviatedDollarString(row.txCompanyValuation())}</div>
      },
    },
  ]
  return <Table<ValueShareTxWithSecondaryComparable> rowData={transactions} columns={columns} />
}
