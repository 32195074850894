export function numberToReadableDollarString(
  value: number,
  showCents: boolean = true,
  includeDeltaSign: boolean = false
) {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: showCents ? 2 : 0,
    maximumFractionDigits: showCents ? 2 : 0,
  });
  const formattedValue = formatter.format(value);
  return includeDeltaSign && value >= 0 ? `+${formattedValue}` : formattedValue;
}


export function readableDollarStringToNumber(formattedValue: string): number {

  const cleanedValue = formattedValue.replace(/[$,\s]/g, '');

  const number = parseFloat(cleanedValue);

  if (isNaN(number)) {
    throw new Error('Invalid dollar string format');
  }

  return number;
}