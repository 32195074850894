import { AnyColumnProps } from 'components/editable-table/ColumnProps'
import { ArrayElement, Optional, isSome } from 'safety'
import { EditableTable } from '../../components/editable-table/EditableTable'
import { DateCellFormat, StringCellFormat } from '../../components/editable-table/EditableTextCellFormat'
import { useVanillaTRPC } from '../../providers/TRPCProvider'
import { RouterOutputs, trpc } from '../../utils/trpc'
import { useCompanySelectorContext } from './CompanySelectorContextProvider'
import { useCompanySelectorContext as useEnrichedCompanySelectorContext } from './massiveEnrichedCompany/MassiveEnrichedCompanySelectorContextProvider'

type CreatedObject = {
  name?: string
  title?: string
  startDate?: Date
  endDate?: Date
  linkedInUrl?: string
  seniority?: string
}

const seniorityTypes: string[] = ['BOARD_MEMBER', 'EXECUTIVE', 'FOUNDER']

type Person = ArrayElement<RouterOutputs['company']['people']>

export default function PeopleForCompanyTable() {
  let { companyId } = useCompanySelectorContext()
  const { pluralCompanyId } = useEnrichedCompanySelectorContext()
  if (pluralCompanyId.value) {
    companyId = pluralCompanyId
  }
  const vanillaClient = useVanillaTRPC()

  const peopleQuery = trpc.company.people.useQuery(
    {
      companyId: companyId.value ? companyId.value : 0,
    },
    {
      enabled: isSome(companyId.value),
    },
  )
  const people: Person[] = peopleQuery.data ?? []

  const columns: AnyColumnProps<Person, CreatedObject>[] = [
    {
      header: 'Name',
      inputType: 'input',
      textCellFormat: StringCellFormat,
      accessor: (row) => row.name,
      updateRemote: async (row: Person, newValue: Optional<string>) => {
        await vanillaClient.person.update.mutate({
          id: row.id,
          name: newValue ? newValue : undefined,
        })
      },
      placeholder: 'Name',
      createProps: {
        isRequired: false,
        createdObjectKey: 'name',
      },
    },
    {
      header: 'LinkedIn URL',
      inputType: 'input',
      textCellFormat: StringCellFormat,
      accessor: (row) => row.linkedInUrl,
      updateRemote: async (row: Person, newValue: Optional<string>) => {
        await vanillaClient.person.update.mutate({
          id: row.id,
          linkedInUrl: newValue ? newValue : undefined,
        })
      },
      placeholder: 'LinkedIn URL',
      createProps: {
        isRequired: false,
        createdObjectKey: 'linkedInUrl',
      },
    },
    {
      header: 'Title',
      inputType: 'input',
      textCellFormat: StringCellFormat,
      accessor: (row) => row.title,
      updateRemote: async (row: Person, newValue: Optional<string>) => {
        await vanillaClient.person.update.mutate({
          id: row.id,
          title: newValue ? newValue : undefined,
        })
      },
      placeholder: 'Title',
      createProps: {
        isRequired: false,
        createdObjectKey: 'title',
      },
    },
    {
      header: 'Start Date',
      inputType: 'input',
      textCellFormat: DateCellFormat,
      accessor: (row) => (isSome(row.startDate) ? new Date(row.startDate) : null),
      updateRemote: async (row: Person, newValue: Optional<Date>) => {
        await vanillaClient.person.update.mutate({
          id: row.id,
          startDate: newValue ? newValue.toISOString() : undefined,
        })
      },
      placeholder: '00/00/0000',
      createProps: {
        isRequired: false,
        createdObjectKey: 'startDate',
      },
    },
    {
      header: 'End Date',
      inputType: 'input',
      textCellFormat: DateCellFormat,
      accessor: (row) => (isSome(row.endDate) ? new Date(row.endDate) : null),
      updateRemote: async (row: Person, newValue: Optional<Date>) => {
        await vanillaClient.person.update.mutate({
          id: row.id,
          endDate: newValue ? newValue.toISOString() : undefined,
        })
      },
      placeholder: '00/00/0000',
      createProps: {
        isRequired: false,
        createdObjectKey: 'endDate',
      },
    },
    {
      header: 'Seniority',
      inputType: 'multiSelect',
      options: seniorityTypes.map((type) => type.toLowerCase()),
      accessor: (row) => row.seniority?.toLowerCase(),
      updateRemote: async (row: Person, newValue: Optional<string>) => {
        await vanillaClient.person.update.mutate({
          id: row.id,
          seniority: newValue!.toUpperCase(),
        })
      },
      createProps: {
        isRequired: false,
        createdObjectKey: 'seniority',
      },
    },
  ]

  return (
    <div className=" w-full">
      {peopleQuery.isLoading ? (
        <div />
      ) : (
        <div className="w-full">
          <EditableTable<Person, CreatedObject>
            columns={columns}
            rowData={people}
            stickyColumn={true}
            deleteProps={{
              onDelete: async (rowIndex) => {
                const metric = people[rowIndex]
                await vanillaClient.person.delete.mutate({ id: metric.id })
                peopleQuery.refetch()
              },
              confirmationModalProps: {
                title: 'Delete person',
                body: 'Are you sure you want to delete this person?',
                confirmText: 'Delete',
              },
            }}
            onRowCreate={async (createdObject: CreatedObject) => {
              if (!companyId.value) return
              await vanillaClient.person.create.mutate({
                companyId: companyId.value,
                name: createdObject.name,
                title: createdObject.title,
                seniority: createdObject.seniority?.toUpperCase(),
                startDate: createdObject.startDate?.toISOString(),
                endDate: createdObject.endDate?.toISOString(),
                linkedInUrl: createdObject.linkedInUrl,
              })
              peopleQuery.refetch()
            }}
          />
        </div>
      )}
    </div>
  )
}
