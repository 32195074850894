import { Button } from 'components/ui/button'
import { createContext, PropsWithChildren, useContext, useState } from 'react'

const SnackbarContext = createContext({
  setSnackbarText: (newText: string, length?: number) => {},
})
export function useSnackbar() {
  return useContext(SnackbarContext)
}
export function SnackbarProvider(props: PropsWithChildren<{}>) {
  const [snackbarString, setSnackbarString] = useState('')
  function setSnackbarText(newText: string, length: number = 3000) {
    setSnackbarString(newText)
    setTimeout(() => {
      setSnackbarString('')
    }, length)
  }

  return (
    <SnackbarContext.Provider value={{ setSnackbarText }}>
      {props.children}
      {snackbarString && (
        <div
          style={{ transform: 'translateX(-50%)' }}
          className="text-medium font-small items center absolute bottom-[50px] left-[50%] z-[60] w-[275px] rounded border-gray-400 border-red-500 bg-red-200 bg-white p-5">
          {snackbarString}
          <Button
            onClick={() => {
              navigator.clipboard.writeText(snackbarString)
            }}>
            Copy
          </Button>
        </div>
      )}
    </SnackbarContext.Provider>
  )
}
