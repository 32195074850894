import { CheckIcon } from '@radix-ui/react-icons'
import { ChangeEvent } from 'react'

export default function CheckBox({
  checked,
  onCheckChange,
  disabled = false,
}: {
  checked: boolean
  onCheckChange: (checked: boolean) => void
  disabled?: boolean
}) {
  return (
    <div
      className={
        'flex h-5 w-5 shrink-0 flex-col items-center justify-center rounded-md border' +
        (disabled ? ' cursor-not-allowed' : ' cursor-pointer hover:bg-gray-50')
      }
      onClick={() => {
        if (!disabled) {
          onCheckChange(!checked)
        }
      }}>
      {checked && (
        <CheckIcon
          className={disabled ? 'cursor-not-allowed' : 'cursor-pointer'}
          style={{
            height: '18px',
            width: '18px',
          }}
        />
      )}
    </div>
  )
}

type CheckboxChangeEventHandler = (event: ChangeEvent<HTMLInputElement>) => void
export function InputCheckbox(props: { onChange: CheckboxChangeEventHandler; checked: boolean; disabled?: boolean }) {
  return (
    <input
      type="checkbox"
      disabled={props.disabled}
      onChange={props.onChange}
      checked={props.checked}
      className={`shrink-0 rounded border-gray-300 ${props.disabled ? 'cursor-not-allowed' : 'cursor-pointer'}`}></input>
  )
}
