import { CrossCircledIcon } from '@radix-ui/react-icons'
import { Button, TextField } from '@radix-ui/themes'
import { useEffect, useRef, useState } from 'react'
import { ArrayElement, isNone, isSome } from 'safety'

import AppTextField, { InputLabel } from 'components/AppTextfield'
import { DatePicker } from 'components/DatePicker'
import { PageLoader } from '../../PageLoader'
import { usePluralAuth } from '../../providers/PluralAuthProvider'
import { AuthStatus } from '../../utils/authStatus'
import { RouterOutputs, trpc } from '../../utils/trpc'
import { AttributedCompanyMetricsTable } from './AttributedCompanyMetricsTable'
import { CompanyDebtEventsTable } from './CompanyDebtEventsTable'
import { CompanyFinancingDataTable } from './CompanyFinancingDataTable'
import CompanyMarks from './CompanyMarks'
import { CompanyRevenueTable } from './CompanyRevenueTable'
import { useCompanySelectorContext, withCompanySelectorContext } from './CompanySelectorContextProvider'
import CompanySelectorForEditor from './CompanySelectorForEditor'
import CompanySubscribers from './CompanySubscribers'
import ComparableCompaniesEditor from './ComparableCompaniesEditor'
import { MarksChart } from './MarksChart'
import PeopleForCompanyTable from './PeopleForCompanyTable'
import ProjectedCompanyMetricsTable from './ProjectedCompanyMetricsTable'
import ProjectionResult from './ProjectionResult'
import SubscribeToCompany from './SubscribeToCompany'
import V2CalculatorCompanyProfileEditor from './V2CalculatorCompanyProfileEditor'

export default withCompanySelectorContext(CompanyEditor)

function CompanyEditor() {
  const { isLoading: isAuthLoading, authStatus } = usePluralAuth()
  const isCompanyEditorQuery = trpc.attributedCompanyMetrics.isCompanyEditor.useQuery(undefined, {
    enabled: !isAuthLoading,
  })
  const { isLoadingCompanies, refetch, didSelectCompany } = useCompanySelectorContext()

  const updateCompanyNameMutation = trpc.company.updateName.useMutation()
  const updateCompanyLegalNameMutation = trpc.company.updateLegalName.useMutation()
  const updateJurisdictionMutation = trpc.company.updateJurisdiction.useMutation()
  const updateCompanyDateMutation = trpc.company.updateIncorporationDate.useMutation()

  useEffect(() => {
    if (isAuthLoading) return
    isCompanyEditorQuery.refetch()
  }, [isAuthLoading, isCompanyEditorQuery])

  const isCompanyEditor = isCompanyEditorQuery.data ?? undefined
  const { selectedCompany } = useCompanySelectorContext()
  const isAllowedUser = authStatus === AuthStatus.Allowed

  const [isEditingCompanyName, setIsEditingCompanyName] = useState(false)
  const [editedCompanyName, setEditedCompanyName] = useState<string | null>(selectedCompany.value?.name ?? null)
  useEffect(() => {
    setEditedCompanyName(selectedCompany?.value?.name ?? null)
  }, [selectedCompany?.value?.name])
  const inputRef = useRef<HTMLInputElement>(null)
  useEffect(() => {
    if (isEditingCompanyName && inputRef.current) {
      inputRef.current.focus()
    }
  }, [isEditingCompanyName])

  const [editedLegalName, setEditedLegalName] = useState<string | null>(selectedCompany.value?.legalName ?? null)

  const [editedJurisdiction, setEditedJurisdiction] = useState<string | null>(
    selectedCompany.value?.jurisdictionOfIncorporation ?? null,
  )

  const [editedDate, setEditedDate] = useState<Date | null>(
    selectedCompany.value?.incorporated ? new Date(selectedCompany?.value.incorporated) : null,
  )

  useEffect(() => {
    setEditedDate(selectedCompany.value?.incorporated ? new Date(selectedCompany?.value.incorporated) : null)
  }, [selectedCompany.value?.incorporated])

  useEffect(() => {
    setEditedLegalName(selectedCompany.value?.legalName ?? null)
  }, [selectedCompany.value?.legalName])
  useEffect(() => {
    setEditedJurisdiction(selectedCompany.value?.jurisdictionOfIncorporation ?? null)
  }, [selectedCompany.value?.jurisdictionOfIncorporation])

  return (
    <div className="ml-16 mr-16 flex max-w-page-supermax flex-col items-center">
      {isCompanyEditorQuery.isLoading || isAuthLoading || isLoadingCompanies || isNone(isCompanyEditor) ? (
        <PageLoader />
      ) : !isAllowedUser ? (
        <div className="opacity-60">You are not logged in as an allowed user.</div>
      ) : (
        <div className="ml-6 mr-6 flex w-full flex-col items-center">
          <div className="w-full ">
            <div className="w-86">
              <div className="mt-8">
                <CompanySelectorForEditor />
              </div>
            </div>
            {isSome(selectedCompany.value) && (
              <div className="w-full ">
                <div className="mt-8 flex max-w-page-skinny items-center justify-between">
                  <div>
                    <div className="text-sm opacity-60">Company Editor</div>
                    <div
                      className={`mt-[2px] text-3xl font-medium ${isCompanyEditor ? 'cursor-pointer' : 'pointer-events-none'}`}
                      onClick={() => {
                        setIsEditingCompanyName(true)
                      }}>
                      {!isEditingCompanyName ? (
                        `${selectedCompany.value.name}`
                      ) : (
                        <input
                          ref={inputRef}
                          onChange={(e) => {
                            setEditedCompanyName(e.target.value)
                          }}
                          value={editedCompanyName ?? ''}
                          onBlur={async () => {
                            if (editedCompanyName && selectedCompany.value!.id) {
                              let company = await updateCompanyNameMutation.mutateAsync({
                                companyId: selectedCompany.value!.id,
                                name: editedCompanyName ?? '',
                              })
                              refetch()
                              didSelectCompany(company)
                            }
                            setIsEditingCompanyName(false)
                          }}></input>
                      )}
                    </div>
                  </div>
                  <SubscribeToCompany />
                </div>
                <div>
                  <div className="mt-6">
                    <ProjectionResult />
                  </div>
                  <div className="mt-6 max-w-page-skinny">
                    <MarksChart />
                  </div>
                  <div>
                    <div className="mt-2 text-xs opacity-60">
                      ingredients used / req'd
                      <li>1 financing event with issue price, date, and valuation</li>
                      <li>1 of each of the projection metrics (dilution, operating income, expense optional)</li>
                      <li>misc metrics for historical revenue and (optionally) cash</li>
                      <li>note: expense is subtracted on top of operating income if both exist </li>
                    </div>
                  </div>
                  <div className={`w-full ${isCompanyEditor ? '' : 'pointer-events-none'}`}>
                    <div className="mt-12 flex flex-col gap-2">
                      <div className="font-medium">Name</div>
                      <div
                        className="flex flex-col gap-2"
                        style={{
                          minWidth: '175px',
                          maxWidth: '250px',
                        }}>
                        <AppTextField
                          label="Legal Name"
                          placeholder={`Legal Name`}
                          value={editedLegalName ?? ''}
                          onChange={(e) => {
                            setEditedLegalName(e.target.value)
                          }}
                          onBlur={() => {
                            if (selectedCompany.value?.id) {
                              updateCompanyLegalNameMutation.mutateAsync({
                                companyId: selectedCompany.value!.id,
                                legalName: editedLegalName ?? '',
                              })
                            }
                          }}
                        />
                        <AppTextField
                          label="Jurisdiction of Incorporaion"
                          placeholder={`Jurisdiction of Incorporaion`}
                          value={editedJurisdiction ?? ''}
                          onChange={(e) => {
                            setEditedJurisdiction(e.target.value)
                          }}
                          onBlur={() => {
                            if (selectedCompany.value?.id) {
                              updateJurisdictionMutation.mutateAsync({
                                companyId: selectedCompany.value!.id,
                                jurisdictionOfIncorporation: editedJurisdiction ?? '',
                              })
                            }
                          }}
                        />
                        <div className="flex flex-col gap-2">
                          <InputLabel label="Incorporation Date" />
                          <DatePicker
                            date={editedDate ?? undefined}
                            setDate={(newDate) => {
                              setEditedDate(newDate ?? null)
                              if (selectedCompany.value?.id) {
                                updateCompanyDateMutation.mutateAsync({
                                  companyId: selectedCompany.value!.id,
                                  incorporationDate: newDate?.toISOString() ?? null,
                                })
                              }
                            }}
                          />
                        </div>
                      </div>

                      <div className=" flex flex-col gap-[60px]  md:flex-row">
                        <div className="flex flex-col gap-2">
                          <InputLabel label="Legal Name Aliases" />
                          <AliasTable aliasType="LEGAL_NAME" />
                        </div>
                        <div className="flex flex-col gap-2">
                          <InputLabel label="DBA Name Aliases" />
                          <AliasTable aliasType="DBA_NAME" />
                        </div>
                      </div>
                    </div>
                    <div className="mt-12">
                      <div className="mb-4 font-medium">Revenue</div>
                      <CompanyRevenueTable />
                    </div>
                    <div className="mt-12">
                      <div className="mb-4 font-medium">Share Price Projection Data</div>
                      <ProjectedCompanyMetricsTable />
                    </div>
                    <div className="mt-12">
                      <div className="mb-4 font-medium">Misc Metrics</div>
                      <AttributedCompanyMetricsTable />
                    </div>

                    <div className="mt-12">
                      <div className="mb-4 font-medium">Financing Events</div>
                      <CompanyFinancingDataTable />
                    </div>
                    <div className="mt-12">
                      <CompanyMarks />
                    </div>
                    <div className="mt-12">
                      <div className="mb-4 font-medium">Value Share Profile</div>
                      <V2CalculatorCompanyProfileEditor />
                    </div>
                    <div className="mt-12">
                      <div className="mb-4 font-medium">Debt Events</div>
                      <CompanyDebtEventsTable />
                    </div>
                    <div className="mt-12">
                      <div className="mb-4 font-medium">Comparable Companies</div>
                      <ComparableCompaniesEditor />
                    </div>
                    <div className="mt-12">
                      <div className="mb-4 font-medium">People</div>
                      <PeopleForCompanyTable />
                    </div>
                    <div className="mt-12">
                      <div className="mb-4 font-medium">Subscribers</div>
                      <CompanySubscribers />
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="footer h-36" />
          </div>
        </div>
      )}
    </div>
  )
}

type AliasType = 'LEGAL_NAME' | 'DBA_NAME'

type RouterAlias = ArrayElement<RouterOutputs['company']['getCompanyAliases']>

function AliasTable(props: { aliasType: AliasType }) {
  const { aliasType } = props
  const { selectedCompany } = useCompanySelectorContext()

  const companyAliasesQuery = trpc.company.getCompanyAliases.useQuery(
    { companyId: selectedCompany.value!.id },
    { enabled: isSome(selectedCompany.value) },
  )

  const companyAliases = (companyAliasesQuery.data ?? []).filter((alias: RouterAlias) => alias.aliasType === aliasType)

  const deleteAliasMutation = trpc.company.deleteCompanyAlias.useMutation()
  const addAliasMutation = trpc.company.addCompanyAlias.useMutation()
  const [newAlias, setNewAlias] = useState('')

  return (
    <div className="flex flex-col gap-3">
      {companyAliases.map((alias) => {
        return (
          <div key={alias.id} className="flex flex-row items-center gap-2 text-sm">
            - {alias.alias}{' '}
            <CrossCircledIcon
              color="gray"
              style={{ color: 'gray', cursor: 'pointer' }}
              onClick={async () => {
                await deleteAliasMutation.mutateAsync({ aliasId: alias.id })
                companyAliasesQuery.refetch()
              }}
            />
          </div>
        )
      })}
      {companyAliases.length === 0 ? (
        <div className="ml-2 text-sm">No {aliasType === 'LEGAL_NAME' ? 'legal' : 'DBA'} name aliases</div>
      ) : undefined}
      <div className="flex flex-row items-center gap-2">
        <TextField.Input
          style={{
            minWidth: '175px',
          }}
          placeholder={`Add new ${aliasType === 'LEGAL_NAME' ? 'legal' : 'DBA'} name alias`}
          value={newAlias}
          onChange={(e) => {
            setNewAlias(e.target.value)
          }}
        />
        <Button
          color="gray"
          variant="outline"
          onClick={async () => {
            if (!selectedCompany.value || newAlias) return
            await addAliasMutation.mutateAsync({
              alias: newAlias,
              aliasType,
              companyId: selectedCompany.value!.id,
            })
            setNewAlias('')
            companyAliasesQuery.refetch()
          }}>
          Add
        </Button>
      </div>
    </div>
  )
}
