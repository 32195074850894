import React from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { Button } from '@radix-ui/themes'
import { setHasViewedAllowlistNotice } from '../utils/setHasViewedAllowlistNotice'

const LogoutButton = () => {
  const { logout } = useAuth0()
  const handleLogout = () => {
    setHasViewedAllowlistNotice(false)
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    })
  }

  return (
    <Button variant="soft" onClick={handleLogout}>
      Log Out
    </Button>
  )
}

export default LogoutButton
