import * as RadixTabs from '@radix-ui/react-tabs'
import React from 'react'
import { attioStyleString } from './BasicButton'

function Tabs(props: React.ComponentProps<typeof RadixTabs.Root> & { children: React.ReactNode }) {
  let { children, ...rest } = props
  return <RadixTabs.Root {...rest}>{children}</RadixTabs.Root>
}

function Tab(
  props: React.ComponentProps<typeof RadixTabs.Trigger> & {
    label: string
    isActive?: boolean
    startIcon?: React.ReactNode
    endIcon?: React.ReactNode
    basic?: boolean
  },
) {
  return (
    <RadixTabs.Trigger {...props}>
      <div
        className={`${props.isActive ? 'border-b border-black' : 'border-b border-transparent'} flex h-[${props.basic ? '35' : '50'}px] items-center`}>
        <div
          className={`${props.isActive && !props.basic ? attioStyleString : 'flex cursor-pointer  flex-row items-center gap-2 rounded-lg border border-transparent  px-[6px]  py-[4px] text-sm font-medium text-black hover:border-gray-200 hover:bg-gray-100 hover:shadow-sm'}  `}>
          <div className="flex w-full shrink-0 flex-row items-center gap-2 text-nowrap">
            {props.startIcon} {props.label} {props.endIcon}
          </div>
        </div>
      </div>
    </RadixTabs.Trigger>
  )
}

Tabs.Content = function Content(props: React.ComponentProps<typeof RadixTabs.Content>) {
  return <RadixTabs.Content {...props} className={`${props.className} p-3`} />
}

Tabs.List = function List(props: React.ComponentProps<typeof RadixTabs.List>) {
  return (
    <RadixTabs.List
      {...props}
      className={`${props.className} flex max-w-full flex-row items-center gap-3 overflow-auto px-3`}
    />
  )
}
Tabs.Tab = Tab

export default Tabs
