import { ChevronDownIcon, ChevronRightIcon, DotsVerticalIcon } from '@radix-ui/react-icons'
import { Dropdown } from 'components/Dropdown'
import { DropdownItem } from 'components/DropdownItem'
import { usePluralNavigation } from 'providers/NavigationProvider'
import { ReactNode, useState } from 'react'
import { isSome } from 'safety'
import './SidenavV2.css'

type SideNavItemAction = {
  onClick: () => void
  title: string
  icon: ReactNode
  customComponent?: ReactNode
}
export type IndividualSidenavItem = {
  title: string
  icon: ReactNode
  href: string
  active?: boolean
  actions?: SideNavItemAction[]
}

type GroupSidenavItem = {
  title: string
  children: IndividualSidenavItem[]
}

export type SidenavItemV2Type = IndividualSidenavItem | GroupSidenavItem

export default function SidenavV2(props: { items: SidenavItemV2Type[]; width?: number }) {
  return (
    <div className="flex flex-col gap-3 pl-3 pr-2 pt-3" style={{}}>
      {props.items.map((item, i) =>
        isSome((item as GroupSidenavItem).children) ? (
          <SidenavAccordion
            title={item.title}
            childrenItems={(item as GroupSidenavItem).children}
            key={`${item.title}-i`}
          />
        ) : (
          <SidenavItem item={item as IndividualSidenavItem} key={`${(item as IndividualSidenavItem).href}-${i}`} />
        ),
      )}
    </div>
  )
}

function SidenavItem({ item }: { item: IndividualSidenavItem }) {
  let { navigate } = usePluralNavigation()

  return (
    <div
      className={`flex w-full cursor-pointer flex-row items-center rounded hover:bg-gray-200 ${item.active ? 'bg-gray-200' : ''}`}>
      <a
        href={item.href}
        className="flex max-w-full flex-1 items-center"
        onClick={(e) => {
          e.preventDefault()
          navigate(item.href)
        }}>
        <div className="flex w-full items-center gap-2 py-1 pl-1">
          <div>{item.icon}</div>
          <div className="font-gray-500 font-lg w-full max-w-[144px] flex-1 truncate text-xs font-medium">
            {item.title}
          </div>
        </div>
      </a>

      {item.actions ? (
        <div className="ml-auto flex shrink-0 items-center">
          <Dropdown
            button={
              <div className="rounded p-1 hover:bg-gray-300">
                <DotsVerticalIcon className="h-[20px]" />
              </div>
            }>
            {item.actions.map((action, i) =>
              action.customComponent ? (
                action.customComponent
              ) : (
                <DropdownItem
                  key={i}
                  text={action.title}
                  startIcon={action.icon}
                  onClick={(e) => {
                    e.stopPropagation()
                    action.onClick()
                  }}
                  className="flex w-full flex-row items-center gap-2 rounded p-2 hover:bg-gray-100"
                />
              ),
            )}
          </Dropdown>
        </div>
      ) : null}
    </div>
  )
}

// function SidenavAccordion(props: { title: string; childrenItems: IndividualSidenavItem[]; defaultClosed?: boolean }) {
//   const [isExpanded, setIsExpanded] = useState(!props.defaultClosed)
//   return (
//     <div>
//       <div
//         className={`flex h-[20px] w-full cursor-pointer flex-row items-center rounded px-1 hover:bg-gray-200`}
//         onClick={() => setIsExpanded(!isExpanded)}>
//         <div className="font-gray-400 font-md mr-2 text-xs">
//           {isExpanded ? <ChevronDownIcon className={'h-[10px]'} /> : <ChevronRightIcon className={'h-[10px]'} />}
//         </div>
//         <div className="font-md text-xs text-gray-500">{props.title}</div>
//       </div>
//       {isExpanded && (
//         <div className="flex max-h-[100px] flex-col overflow-auto">
//           {props.childrenItems.map((item, i) => {
//             return <SidenavItem item={item} key={item.href + `-${i}`} />
//           })}
//         </div>
//       )}
//     </div>
//   )
// }

function SidenavAccordion(props: { title: string; childrenItems: IndividualSidenavItem[]; defaultClosed?: boolean }) {
  const [isExpanded, setIsExpanded] = useState(!props.defaultClosed)
  return (
    <div className="flex flex-col">
      <div
        className={`flex h-[20px] w-full cursor-pointer flex-row items-center rounded px-1 hover:bg-gray-200`}
        onClick={() => setIsExpanded(!isExpanded)}>
        <div className="font-gray-400 font-md mr-2 text-xs">
          {isExpanded ? <ChevronDownIcon className={'h-[10px]'} /> : <ChevronRightIcon className={'h-[10px]'} />}
        </div>
        <div className="font-md text-xs text-gray-500">{props.title}</div>
      </div>
      <div
        className={`transition-max-height duration-300 ease-in-out ${isExpanded ? 'max-h-[300px]' : 'max-h-0'} hiddenScroll overflow-y-auto`}>
        {props.childrenItems.map((item, i) => {
          return <SidenavItem item={item} key={item.href + `-${i}`} />
        })}
      </div>
    </div>
  )
}
