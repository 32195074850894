import { Table, TableColumnProps } from 'components/Table'
import PortfolioGraphFooter, { SelfValueGraphFooter } from 'pages/portfolio/Info'
import { numberToPercentChangeString, numberToReadableDollarString, yearsBetweenDates } from 'plural-shared/utils'
import { usePluralAuth } from 'providers/PluralAuthProvider'
import { useEffect, useState } from 'react'
import { Line, LineChart, ResponsiveContainer, Tooltip, TooltipProps, XAxis, YAxis } from 'recharts'
import { ArrayElement } from 'safety'
import { RouterOutputs, trpc } from 'utils/trpc'
import { FundSwitch } from './Portfolio'

type GraphPoint = ArrayElement<RouterOutputs['portfolio']['graphData']>

type RouterFundCommit = ArrayElement<RouterEntity['fundCommits']>
type RouterCompanyPosition = ArrayElement<RouterOutputs['portfolio']['getCompanyPositionsByJournalEntries']>

export default function PortfolioGraph(props: { fundId: number }) {
  const graphDataQuery = trpc.portfolio.graphData.useQuery({
    fundId: props.fundId,
  })
  const [graphData, setGraphData] = useState<GraphPoint[]>(graphDataQuery.data ?? [])

  useEffect(() => {
    if (graphDataQuery.data) {
      setGraphData(graphDataQuery.data)
    } else {
      setGraphData([])
    }
  }, [graphDataQuery.data])

  function yDomainMin() {
    let min = Math.min(...graphData.map(({ totalMarkValue }) => totalMarkValue))
    return min * 0.99
  }
  function yDomainMax() {
    let max = Math.max(...graphData.map(({ totalMarkValue }) => totalMarkValue))
    return max * 1.01
  }

  const CustomTooltip: React.FC<TooltipProps<any, any>> = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const formattedPayloadValue = formatTooltipValue(payload[0].value)
      const date = new Date(label)
      const formattedLabel = date.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })
      return (
        <div
          className="custom-tooltip flex flex-col items-center rounded-md pb-3 pl-4 pr-4 pt-3 text-white"
          style={{ backgroundColor: '#1C2227' }}>
          <div className="label text-sm opacity-70">{formattedLabel}</div>
          <div className="value mb-1 text-xl font-semibold">{formattedPayloadValue}</div>
          <div className="text-sm opacity-70">Marked Portfolio Value</div>
          {/* <div className="value mb-1 text-xl font-semibold">{formattedIrr}</div>
          <div className="text-sm opacity-70">IRR</div> */}
        </div>
      )
    }
    return null
  }

  const formatTooltipValue = (value: number) => {
    return value.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 0,
    })
  }

  return (
    <div className="w-full text-left">
      <div className="w-full">
        <ResponsiveContainer width="100%" height={400}>
          <LineChart data={graphData} margin={{ top: 2, right: 22, left: 16, bottom: 4 }}>
            <XAxis
              dataKey="snapshotDate"
              tick={true}
              tickLine={false}
              height={32}
              axisLine={false}
              tickFormatter={(value) => {
                return new Date(value).toLocaleDateString('en-US', { year: '2-digit', month: 'short' })
              }}
              className="hidden text-xs opacity-70 lg:block"
            />
            <YAxis domain={[yDomainMin(), yDomainMax()]} tick={false} tickLine={false} width={8} axisLine={false} />
            {/* <YAxis domain={[-0.5, 0.5]} tick={false} tickLine={false} width={8} axisLine={false} yAxisId="irr" /> */}
            {/* <Line
              type="monotone"
              dataKey="irr"
              stroke="#02CCFE"
              strokeWidth={2}
              name="IRR"
              activeDot={{ r: 5 }}
              yAxisId={'irr'}
              isAnimationActive={false}
            /> */}
            <Tooltip content={<CustomTooltip />} isAnimationActive={false} />
            <Line
              type="monotone"
              dataKey="totalMarkValue"
              stroke="#06B823"
              strokeWidth={2}
              name="Marked Portfolio Value"
              activeDot={{ r: 5 }}
              isAnimationActive={false}
            />
          </LineChart>
        </ResponsiveContainer>
        <div className="ml-2 lg:mt-2">
          <PortfolioGraphFooter fundId={props.fundId} />
        </div>
        <div className="h-4" />
        <div className="h-[1px] w-full bg-black opacity-10" />
      </div>
    </div>
  )
}

type SelfGraphPoint = { snapshotDate: string; totalMarkValue: number }

type RouterEntityToUser = ArrayElement<RouterOutputs['user']['getSelfWithFundCommits']['legalEntitiesToUser']>
type RouterEntity = RouterEntityToUser['legalEntity']

export function SelfValueGraph(props: { fundId: number; snapshotDate: string; fundName: string; grossIrr: number }) {
  const graphDataQuery = trpc.portfolio.graphData.useQuery({
    fundId: props.fundId,
  })

  const { isLoading: isAuthLoading } = usePluralAuth()
  const userQuery = trpc.user.getSelfWithFundCommits.useQuery(undefined, {
    enabled: !isAuthLoading,
  })

  const journalEntryIds = userQuery.data?.legalEntitiesToUser.flatMap((lte: any) =>
    lte.legalEntity.journalEntries
      .filter((je: any) => je.fundId === props.fundId)
      .map((je: any) => je.id)
  ) ?? []

  const companyPositionsQuery = trpc.portfolio.getCompanyPositionsByJournalEntries.useQuery({
    fundId: props.fundId,
    journalEntryIds: journalEntryIds
  }, {
    enabled: journalEntryIds.length > 0
  })

  let userCommits =
    (userQuery.data?.legalEntitiesToUser ?? []).reduce((prev: RouterFundCommit[], curr: RouterEntityToUser) => {
      prev.push(
        ...(curr.legalEntity.fundCommits ?? []).filter((commit: RouterFundCommit) => commit.fundId === props.fundId),
      )
      return prev
    }, [] as RouterFundCommit[]) ?? []

  function findLegalEntityName(routerFundCommit: RouterFundCommit): string {
    return (
      userQuery.data?.legalEntitiesToUser.find(
        (userToEntity: RouterEntityToUser) => userToEntity.legalEntity.id === routerFundCommit.legalEntityId,
      )?.legalEntity.name ?? ''
    )
  }
  function graphQueryDataToSelfGraphPoint(data: GraphPoint[]): SelfGraphPoint[] {
    let earliestDate = new Date()
    let earliestInvestmentAmount = 0
    userCommits
      .filter((commit: RouterFundCommit) => {
        return new Date(commit.wireDate).getTime() <= new Date(props.snapshotDate).getTime()
      })
      .forEach((investment: RouterFundCommit) => {
        if (new Date(investment.wireDate).getTime() === earliestDate.getTime()) {
          earliestInvestmentAmount += investment.cashDelta
        }
        if (new Date(investment.wireDate).getTime() < earliestDate.getTime()) {
          earliestInvestmentAmount = investment.cashDelta
          earliestDate = new Date(investment.wireDate)
        }
      })
    let points = [...data.map((pt) => structuredClone(pt))]
      .map((point) => {
        let totalValueOfInvestments = 0

        userCommits
          .filter((investment: RouterFundCommit) => {
            return new Date(investment.wireDate).getTime() <= new Date(point.snapshotDate).getTime()
          })
          .forEach((investment: RouterFundCommit) => {
            totalValueOfInvestments += calculateEndingValue(
              investment.cashDelta,
              point.irr || 0,
              yearsBetweenDates({ start: new Date(investment.wireDate), end: new Date(point.snapshotDate) }),
            )
          })

        return {
          snapshotDate: point.snapshotDate,
          totalMarkValue: totalValueOfInvestments,
        }
      })
      .filter((point) => point.totalMarkValue > 0)

    return points
  }

  const myCommitsColumns: TableColumnProps<RouterFundCommit>[] = [
    {
      header: 'Entity Name',
      render: (row: RouterFundCommit) => findLegalEntityName(row),
    },
    {
      header: 'Wire Date',
      render: (row: RouterFundCommit) => new Date(row.wireDate).toLocaleDateString(),
    },
    {
      header: 'Fund Interest',
      render: (row: RouterFundCommit) => numberToReadableDollarString(row.cashDelta),
    },
    {
      header: 'Marked Value of Fund Interest',
      render: (row: RouterFundCommit) =>
        numberToReadableDollarString(
          calculateEndingValue(
            row.cashDelta,
            lastIrr,
            yearsBetweenDates({
              start: new Date(row.wireDate),
              end: new Date(props.snapshotDate),
            }),
          ),
        ),
    },
  ]
  const [graphData, setGraphData] = useState<SelfGraphPoint[]>(
    graphQueryDataToSelfGraphPoint(graphDataQuery.data ?? []),
  )

  useEffect(() => {
    if (graphDataQuery.data) {
      setGraphData(graphQueryDataToSelfGraphPoint(graphDataQuery.data))
    } else {
      setGraphData([])
    }
  }, [graphDataQuery.data, userQuery.data])

  function yDomainMin() {
    let min = Math.min(...graphData.map(({ totalMarkValue }) => totalMarkValue))
    return min * 0.99
  }
  function yDomainMax() {
    let max = Math.max(...graphData.map(({ totalMarkValue }) => totalMarkValue))
    return max * 1.01
  }

  const CustomTooltip: React.FC<TooltipProps<any, any>> = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const formattedPayloadValue = formatTooltipValue(payload[0].value)
      const date = new Date(label)
      const formattedLabel = date.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })

      return (
        <div
          className="custom-tooltip flex flex-col items-center rounded-md pb-3 pl-4 pr-4 pt-3 text-white"
          style={{ backgroundColor: '#1C2227' }}>
          <div className="label text-sm opacity-70">{formattedLabel}</div>
          <div className="value mb-1 text-xl font-semibold">{formattedPayloadValue}</div>
          <div className="text-sm opacity-70">Marked Value of Fund Interest</div>
        </div>
      )
    }
    return null
  }

  const formatTooltipValue = (value: number) => {
    return value.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 0,
    })
  }

  const activeCommits = userCommits.filter((investment: RouterFundCommit) => {
    return new Date(investment.wireDate).getTime() <= new Date(props.snapshotDate).getTime()
  })
  const totalInvestmentsMade = activeCommits.reduce((prev: number, curr: RouterFundCommit) => prev + curr.cashDelta, 0)

  const lastMarkedValue = graphData[graphData.length - 1]?.totalMarkValue ?? 0
  const lastIrr = graphDataQuery.data?.[graphDataQuery.data.length - 1]?.irr ?? 0

  const grossTotalPercentGrowth = (lastMarkedValue - totalInvestmentsMade) / totalInvestmentsMade
  if (totalInvestmentsMade === 0) {
    return null
  }

  const companyPositionsColumns: TableColumnProps<RouterCompanyPosition>[] = [
    {
      header: 'Fund Name',
      render: () => props.fundName,
    },
    {
      header: 'Lookback Date',
      render: (row: RouterCompanyPosition) => new Date(row.rawPosition.entryDate).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: '2-digit',
      }),
    },
    {
      header: 'Amount Sold',
      render: (row: RouterCompanyPosition) => numberToReadableDollarString(row.rawPosition.cashDelta),
    },
    {
      header: 'Shares Owed',
      render: (row: RouterCompanyPosition) => Number(row.rawPosition.sharesDelta).toFixed(2),
    },
    {
      header: 'PPS',
      render: (row: RouterCompanyPosition) => row.detailedRow.entryPricePerShare,
    },
    {
      header: 'Company',
      render: (row: RouterCompanyPosition) => row.detailedRow.companyName ?? 'N/A',
    },
  ]

  return (
    <div className="mb-10 flex w-full flex-col gap-2 py-4">
      <div className="flex w-full justify-between">
        <div className="">
          <div className="text-xl font-normal">My Holdings in {props.fundName}</div>
        </div>
        <FundSwitch selectedFundId={props.fundId} />
      </div>
      <div className="items-left flex w-full py-3">
        <div className="flex w-full space-x-6 pt-2">
          <div>
            <div className="text-sm opacity-80">Marked gross IRR</div>
            <div className="text-lg font-medium">{numberToPercentChangeString(props.grossIrr)}</div>
          </div>
          <div className="text-sm">
            <div className="opacity-80">Total growth</div>
            <span
              className={`text-lg font-medium ${Math.abs(grossTotalPercentGrowth - 0) < 0.001
                ? 'text-func-black-0'
                : grossTotalPercentGrowth < 0
                  ? 'text-tickers-red'
                  : 'text-tickers-green'
                }`}>
              {numberToPercentChangeString(grossTotalPercentGrowth)}
            </span>{' '}
          </div>
        </div>
      </div>
      <div className="w-full text-left">
        <div className="w-full">
          <ResponsiveContainer width="100%" height={400}>
            <LineChart data={graphData} margin={{ top: 2, right: 22, left: 16, bottom: 4 }}>
              <XAxis
                dataKey="snapshotDate"
                tick={true}
                tickLine={false}
                height={32}
                axisLine={false}
                tickFormatter={(value) => {
                  return new Date(value).toLocaleDateString('en-US', { year: '2-digit', month: 'short' })
                }}
                className="hidden text-xs opacity-70 lg:block"
              />
              <YAxis
                domain={[yDomainMin() / 2, yDomainMax() * 1.5]}
                tick={false}
                tickLine={false}
                width={8}
                axisLine={false}
              />

              <Tooltip content={<CustomTooltip />} isAnimationActive={false} />
              <Line
                type="monotone"
                dataKey="totalMarkValue"
                stroke="#02CCFE"
                strokeWidth={2}
                name="Marked Fund Interest Value"
                activeDot={{ r: 5 }}
                isAnimationActive={false}
              />
            </LineChart>
          </ResponsiveContainer>
          <div className="ml-2 lg:mt-2">
            <SelfValueGraphFooter
              snapshotDate={props.snapshotDate}
              totalInvestmentsMade={totalInvestmentsMade}
              totalMarkValue={lastMarkedValue}
            />
          </div>
          <div className="h-4" />
          <div className="h-[1px] w-full bg-black opacity-10" />
        </div>
      </div>
      <div className="mt-4">
        <h2 className="text-xl font-normal mb-4">Invested in Fund</h2>
        <Table<RouterFundCommit>
          maxHeight="max-h-160"
          stickyHeader
          rowData={activeCommits}
          defaultRowCount={70}
          columns={myCommitsColumns}
        />
      </div>

      {companyPositionsQuery.data && companyPositionsQuery.data.length > 0 && (
        <div className="mt-4">
          <h2 className="text-xl font-normal mb-4">Sold to Fund</h2>
          <Table<RouterCompanyPosition>
            maxHeight="max-h-160"
            stickyHeader
            rowData={companyPositionsQuery.data}
            defaultRowCount={70}
            columns={companyPositionsColumns}
          />
        </div>
      )}
    </div>
  )
}

export function calculateEndingValue(beginningValue: number, cagr: number, years: number): number {
  if (beginningValue <= 0 || years <= 0) {
    throw new Error('Beginning value and number of years must be greater than 0')
  }
  if (cagr < -1) {
    throw new Error('CAGR must be greater than -100%')
  }

  const endingValue = beginningValue * Math.pow(1 + cagr, years)
  return endingValue
}
