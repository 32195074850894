import {
  numberToAbbreviatedDollarString,
  numberToAbbreviatedString,
  numberToPercentChangeString,
} from 'plural-shared/utils'
import { isNone } from 'safety'
import { useSimulatorContext } from './CashSimulatorContext'
import GraphStat from './GraphStat'

export default function NetCashTransactionData() {
  const { multiplesSlider, pluralCashTx, netCashGraphProps, txInputs } = useSimulatorContext() ?? {}
  if (isNone(multiplesSlider) || isNone(pluralCashTx) || isNone(netCashGraphProps) || isNone(txInputs)) {
    return null
  }
  const { pluralBar, secondaryBar } = netCashGraphProps ?? {}
  if (isNone(pluralBar) || isNone(secondaryBar)) {
    return null
  }
  const cashAdjustedSharePricePercentDeltaFromBase = pluralCashTx.txCashAdjustedSharePricePercentDeltaFromBase()
  const baseStatClass = ''
  return (
    <div className="">
      <div className="flex space-x-12">
        <GraphStat label="Cash today" value={numberToAbbreviatedDollarString(pluralCashTx.inputs().principal)} />
        <GraphStat
          label="Shares exchanged"
          value={
            <div>
              {numberToAbbreviatedString(pluralCashTx.sharesOwed(), 1)}{' '}
              <span className="text-sm font-normal opacity-60">
                of {numberToAbbreviatedString(pluralCashTx.sharesReserved(), 1)}
              </span>{' '}
            </div>
          }
          className={baseStatClass}
        />
        <GraphStat
          label="Net cash Δ%"
          value={numberToPercentChangeString(cashAdjustedSharePricePercentDeltaFromBase, 1)}
        />
      </div>
    </div>
  )
}
