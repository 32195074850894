import { useQuery, useQueryClient } from '@tanstack/react-query'
import { ColumnDef } from '@tanstack/react-table'
import { BlueCellColor } from 'components/editable-tanstack-table/EditableTanstackTable'
import { RouterUser, getSearchableCompanies } from 'pages/admin/AdminCompanySheetPage'
import SheetPage from 'pages/admin/AdminSheetPage'
import { usePluralNavigation } from 'providers/NavigationProvider'
import { usePluralAuth } from 'providers/PluralAuthProvider'
import { PropsWithChildren } from 'react'
import { getFundingRounds } from 'several/actions/FundingRounds'
import { useFundingRoundColumns } from 'several/hooks/FundingRound'
import { MasterFundingRound } from 'several/models/FundingRounds'
import { trpc } from 'utils/trpc'

export function getNumber(value: any): number | undefined {
  if (value === undefined) return undefined
  if (typeof value === 'number') return value
  if (typeof value === 'string') {
    const parsed = parseInt(value)
    return isNaN(parsed) ? undefined : parsed
  }
  return undefined
}

type RawFundingRound = {
  id: string
  companyName: string
  announcementDate: string
  fundingAmount: number
  fundingRoundClosedDate: string | null
}

export const cellClassName = 'max-w-full truncate p-2'

export default function FundingRoundSheetPage(props: { isLoading?: boolean }) {
  let companiesQuery = useQuery({
    queryKey: ['searchableCompanies'],
    queryFn: getSearchableCompanies,
  })

  let fundingRoundsQuery = useQuery({
    queryKey: ['fundingRounds'],
    queryFn: getFundingRounds(''),
  })
  const fundingRounds: MasterFundingRound[] = fundingRoundsQuery.data ?? []
  let queryClient = useQueryClient()
  let columns: ColumnDef<MasterFundingRound, any>[] = useFundingRoundColumns({
    includeCompanyName: true,
    onSuccess: (newRound, Id) => {
      queryClient.setQueryData(['fundingRounds'], (oldData: MasterFundingRound[] | undefined) => {
        function replaceItemById(arr: MasterFundingRound[], newItem: MasterFundingRound): MasterFundingRound[] {
          return arr.map((item) => {
            if (item.id === newItem.id) {
              return newItem
            } else if (item.subRows && item.subRows.length > 0) {
              return {
                ...item,
                subRows: replaceItemById(item.subRows, newItem) as MasterFundingRound[],
              }
            } else {
              return item
            }
          })
        }
        return replaceItemById(oldData ?? [], newRound)
      })
    },
    bgColor: BlueCellColor,
  })

  const { navigate, pushNavigate } = usePluralNavigation()
  let userQuery = trpc.user.getSelfWithSheets
  const { isLoading: isAuthLoading } = usePluralAuth()

  let user: RouterUser | undefined = userQuery.useQuery(undefined, {
    enabled: !isAuthLoading,
  })?.data

  let fundingRoundSheetsToAdd: any[] =
    user?.userToSheets
      .filter((utr: any) => utr.sheet.entityType === 'FUNDING_ROUND' && (utr.role === 'OWNER' || utr.role === 'EDITOR'))
      .map((utr: any) => utr.sheet) ?? []
  return (
    <>
      <SheetPage
        paginate
        defaultSheetId={10}
        backBreadcrumb={{ href: '/dashboard/sheets/funding', title: 'Financings' }}
        getRowId={(row: any) => row.id}
        isLoading={companiesQuery.isLoading || fundingRoundsQuery.isLoading || props.isLoading}
        columns={columns as ColumnDef<unknown>[]}
        data={fundingRounds
          .filter((round) => !round.hidden)
          .map((fundingRound) => {
            return {
              ...fundingRound,
              subRows: fundingRound.subRows?.filter((round) => !round.hidden),
            }
          })}
        filterFns={{}}
        sortingFns={{}}
        onNavigate={(newId) => {
          navigate(`/dashboard/sheets/funding/${newId}`)
        }}
        onNavigatePush={(newId) => {
          pushNavigate(`/dashboard/sheets/funding/${newId}`)
        }}
      />
    </>
  )
}

function parsePercentage(value: string): number | undefined {
  if (value.endsWith('%')) {
    const numberPart = parseFloat(value.slice(0, -1))
    return isNaN(numberPart) ? undefined : numberPart
  }
  return undefined
}

export function FloatingFooter(
  props: PropsWithChildren<{
    numberSelected: number
  }>,
) {
  return (
    <>
      {props.numberSelected > 0 ? (
        <div className="fixed bottom-[40px] right-[50%] z-50 flex translate-x-[50%] transform flex-row items-center gap-3 rounded-lg bg-white px-4 py-2  shadow-md">
          <div className="flex flex-row items-center gap-1 ">
            <div className="rounded bg-blue-300 px-2 text-white">{props.numberSelected}</div>
            <div className="text-sm text-gray-500">selected</div>
          </div>
          <div className="flex items-center gap-3">{props.children}</div>
        </div>
      ) : (
        <></>
      )}
    </>
  )
}

export function CompanyAvatar(props: { logo: string | undefined; alt: string; altLetter: string; size?: number }) {
  const size = props.size ?? 22
  return props.logo ? (
    <img src={props.logo} alt={props.alt} className={`h-[${size}px] w-[${size}px] shrink-0  rounded object-contain`} />
  ) : (
    <div
      style={{
        minWidth: size,
        minHeight: size,
      }}
      className={`text-md h-[${size}px] w-[${size}px] flex shrink-0 items-center justify-center rounded bg-gray-200 object-contain font-bold text-gray-500`}>
      {props.altLetter}
    </div>
  )
}
