import { TextField } from '@radix-ui/themes'
import React, { useEffect, useState } from 'react'
import { isSome } from 'safety'
import { safeCastToNumber } from './CashSimulatorContext'
// Import the TextField.Input from your UI library instead
// This is a placeholder for demonstration
// const TextField = { Input: (props: React.InputHTMLAttributes<HTMLInputElement>) => <input {...props} /> };

interface GenericNumberInputProps {
  value: number | string // Can be a number or a string to accommodate initial values like '.5'
  onNewNumber: (value: number) => void // Callback with the new value as a string
  isDollars?: boolean // Whether to format the input as dollars
  isPercentage?: boolean // Whether to format the input as a percentage
  placeholder?: string
}

const GenericNumberInput: React.FC<GenericNumberInputProps> = ({
  value,
  onNewNumber,
  isDollars,
  placeholder,
  isPercentage,
}) => {
  const [displayValue, setDisplayValue] = useState<string>('')
  const [isEditing, setIsEditing] = useState(false)

  // Convert the external value to a string for display
  useEffect(() => {
    if (!isEditing) {
      setDisplayValue(value.toString())
    }
  }, [value, isEditing])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // Update the display value directly with the input
    setDisplayValue(e.target.value.replace(/[^0-9.]/g, ''))
    // Call the external onChange handler
    let num = safeCastToNumber(e.target.value)
    if (isSome(num) && !e.target.value.endsWith('.') && e.target.value !== '') {
      if (isPercentage === true) {
        num = num / 100
      }
      onNewNumber(num)
    }
  }

  return (
    <div className="relative w-full">
      <TextField.Input
        type="text" // Use text type to allow input like '.5'
        size="2"
        placeholder={placeholder ?? ''}
        value={displayValue}
        onChange={handleChange}
        onFocus={() => setIsEditing(true)}
        onBlur={() => setIsEditing(false)}
        className={`text-base ${isDollars === true ? 'ml-3' : ''}`}
      />
      {isDollars === true && <div className="absolute left-2 top-[6px] text-sm">$</div>}
      {isPercentage === true && <div className="absolute right-2 top-[6px] text-sm">%</div>}
    </div>
  )
}

export default GenericNumberInput
