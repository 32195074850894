import { TextField } from '@radix-ui/themes'
import { safeCastToNumber } from 'pages/cashSimulator/CashSimulatorContext'
import { useEffect, useRef, useState } from 'react'

export function NumberFormattedInput(
  props: Omit<React.ComponentProps<typeof TextField.Input>, 'onChange' | 'ref'> & { onChange: (val: string) => void },
) {
  function parseInner(value: string): string {
    let num = safeCastToNumber(value)
    return num ? num.toLocaleString() : value
  }
  return <FormattedInput {...props} onlyNumbers parseInner={parseInner} />
}

export function FormattedInput(
  props: Omit<React.ComponentProps<typeof TextField.Input>, 'onChange' | 'ref'> & {
    onChange: (val: string) => void
    parseInner: (value: string) => string
    onlyNumbers?: boolean
  },
) {
  const [innerValue, setInnerValue] = useState(props.value ?? '')
  let inputRef = useRef<HTMLInputElement>(null)
  useEffect(() => {
    props.value && setInnerValue(props.value)
  }, [props.value])

  const [focused, setFocused] = useState(false)

  return (
    <TextField.Input
      {...props}
      onFocus={(e) => {
        setFocused(true)
        props.onFocus?.(e)
      }}
      onChange={(e) => {
        if (props.onlyNumbers && safeCastToNumber(e.target.value) === undefined) {
          return
        }
        props.onChange(e.target.value)
        setInnerValue(e.target.value)
      }}
      onBlur={(e) => {
        setFocused(false)
        props.onBlur?.(e)
      }}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          inputRef.current?.blur()
        }
        props.onKeyDown?.(e)
      }}
      ref={inputRef}
      value={focused ? innerValue : props.parseInner(innerValue as string)}
    />
  )
}
