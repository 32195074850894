export interface TxComparatorInputs {
  altInvestmentIrr: number;
  yearsToOutcome: number;
  secondaryBrokerTakeRate: number;
  totalTaxRate: number;
}

export interface TxComparatorNetRates {
  netCashRate: number;
  netTaxRate: number;
  netFeeRate: number;
}

export interface SecondaryNetRateComparison {
  plural: TxComparatorNetRates;
  secondary: TxComparatorNetRates;
}

export function compareNetRates(
  inputs: TxComparatorInputs
): SecondaryNetRateComparison {
  return {
    plural: {
      netCashRate: lookbackNetCashRate(inputs),
      netTaxRate: lookbackNetTaxRate(inputs),
      netFeeRate: 0,
    },
    secondary: {
      netCashRate: secondaryNetCashRate(inputs),
      netTaxRate: secondaryNetTaxRate(inputs),
      netFeeRate: inputs.secondaryBrokerTakeRate,
    },
  };
}

function lookbackNetTaxRate(inputs: TxComparatorInputs): number {
  return (
    inputs.totalTaxRate /
    Math.pow(1 + inputs.altInvestmentIrr, inputs.yearsToOutcome)
  );
}

export function lookbackNetCashRate(inputs: TxComparatorInputs): number {
  return 1 - lookbackNetTaxRate(inputs);
}

function secondaryNetTaxRate(inputs: TxComparatorInputs): number {
  return inputs.totalTaxRate;
}

export function secondaryNetCashRate(inputs: TxComparatorInputs): number {
  return 1 - secondaryNetTaxRate(inputs) - inputs.secondaryBrokerTakeRate;
}

// convert a lookback share price to a secondary share price
export function cashEquivalentLookbackMultipleOverSecondary(
  inputs: TxComparatorInputs
): number {
  return lookbackNetCashRate(inputs) / secondaryNetCashRate(inputs);
}

// convert a secondary share price to a lookback share price
function cashAdjustedSecondarySharePriceMultiple(
  inputs: TxComparatorInputs
): number {
  return secondaryNetCashRate(inputs) / lookbackNetCashRate(inputs);
}
