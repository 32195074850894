
export default function PrivacyPolicy() {
  return (
    <div className="mb-24 mt-24 flex pl-2 pr-2 lg:pl-16 lg:pr-16 items-start flex-wrap flex-col lg:flex-row">
      <aside className="lg:w-1/4 w-full p-6 lg:sticky lg:top-[20vh]">
        <nav className="lg:border-r pr-4">
          <ul>
            <li className="mb-2">
              <a href="/terms" className="block py-2 px-4 rounded-lg hover:bg-slate-100 font-medium">Terms of Use</a>
            </li>
            <li className="mb-2">
              <a href="/privacy" className="block py-2 px-4 rounded-lg hover:bg-slate-100 font-medium">Privacy Policy</a>
            </li>
            <li className="mb-2">
              <a href="/subprocessors" className="block py-2 px-4 rounded-lg hover:bg-slate-100 font-medium">Sub-processors</a>
            </li>
            <li className="mb-2">
              <a href="/terms/ai" className="block py-2 px-4 rounded-lg hover:bg-slate-100 font-medium">AI Terms</a>
            </li>
            <li className="mb-2">
              <a href="/aup" className="block py-2 px-4 rounded-lg hover:bg-slate-100 font-medium">Acceptable Use Policy</a>
            </li>
          </ul>
        </nav>
      </aside>

      <main className="lg:w-3/4 w-full p-6">
        <h1 className="text-3xl font-bold mb-8">Privacy Policy</h1>
        <section className="mb-10">
          <p className="mb-4">
            Massive Tech, Inc., doing business as Plural ("we," "our," "us," or “Plural”), prepared this Privacy Policy to help you understand our practices with respect to the collection, use, and disclosure of information we collect from you through: (i) our website, its subdomains, and any other website where our Terms of Service are posted; (ii) our online hosted services; and (iii) our “Software,” meaning, collectively, our browser extensions, mobile applications, other downloadable apps, application programming interfaces (“APIs”), and tools and documentation ((i) through (iii) collectively, our or the “Services”).
          </p>
          <h2 className="text-xl font-bold mb-6">1. What Information We Collect and How</h2>
          <p className="mb-4">
            We may collect information from you when you:
          </p>
          <ul className="list-disc ml-6">
            <li><p className="mb-2">Create or register an account, or administer your account</p></li>
            <li><p className="mb-2">Input, post, or upload information, data, or other content through the Services</p></li>
            <li><p className="mb-2">Submit questions, requests, or other communications to us via various communication channels</p></li>
            <li><p className="mb-2">Contact us for customer support or technical support</p></li>
            <li><p className="mb-2">Visit any of our websites or download any of our applications</p></li>
            <li><p className="mb-2">Participate in promotions, user conferences, webinars, demonstrations, contests, sweepstakes, surveys, or other marketing events</p></li>
            <li><p className="mb-2">Participate in research activities</p></li>
            <li><p className="mb-2">Interact with other users of the Services and guests on our community forums</p></li>
            <li><p className="mb-2">Integrate third-party products and services with your account on our Services (“Plural Account”)</p></li>
          </ul>
          <p className="mb-4">
            We also collect information about you from our business partners, including referral partners and resellers, and third parties that help us with sales and marketing efforts, such as prospecting. We may also obtain publicly-available business contact information.
            Below is additional information about what we collect, depending on how you interact with the Services:

          </p>
        </section>
        <section className="mb-10">
          <h3 className="text-lg font-bold mb-4">a. Your Content</h3>
          <p className="mb-4">
            “Content” includes all information, data, and other content, in any form or medium, that is collected, downloaded, or otherwise received, directly or indirectly, from you (or on your behalf) by or through the Services. It also includes information you provide when connecting a third-party account, product, or service to the Services. You (and anyone who can interact with your use of the Services) own and control the nature of any Content, subject to any applicable terms and conditions including our Terms of Service and this Privacy Policy. Our collection, use, and disclosure practices with respect to Content are distinct from those with respect to other sorts of information, as explained in Section 2.a. below.
          </p>
          <p className="mb-4">
            We may upload Content automatically with your authorization from third-party services (such as from your Google Drive). See Section 1.c. below.
          </p>
        </section>
        <section className="mb-10">
          <h3 className="text-lg font-bold mb-4">b. Customer Information</h3>
          <p className="mb-4">
            “Customer Information” consists of information related to your access or use of our Services, the creation of accounts, or that otherwise identifies you as a customer or end user of the Services. Customer Information includes:
          </p>
          <ul className="list-disc ml-6">
            <li><p className="mb-2"><em>Identifiers</em>. This includes your name, mailing address, email address, postal code, telephone number, and other similar identifiers.</p></li>
            <li><p className="mb-2"><em>Customer Records</em>. This includes username and password, payment information, company name, job title, business email address, and department.</p></li>
            <li><p className="mb-2"><em>Protected Classification Characteristics</em>. This includes age and gender.</p></li>
            <li><p className="mb-2"><em>Commercial Information</em>. This includes information about products or services purchased, obtained or considered.</p></li>
            <li><p className="mb-2"><em>Internet/Network Activity Information</em>. This includes your browsing history, log and analytics data, information about the device(s) used to access the Services, domain server, search history and information regarding your interaction with our Services, and other usage data.</p></li>
            <li><p className="mb-2"><em>Audio/Visual Information</em>. This includes pictures you provide or upload in connection with our Services, and the content and audio or video recordings of phone or video calls between you and us that we record where permitted by law.</p></li>
            <li><p className="mb-2"><em>Profession/Employment Information</em>. This includes your current employer, title, and location.</p></li>
            <li><p className="mb-2"><em>Other Personal Information</em>. This includes personal information you provide to us in relation to a survey, comment, question, request, or inquiry, and information you provide when you connect a third-party account, product or service to the Services.</p></li>
            <li><p className="mb-2"><em>Inferences</em>. This includes inferring your location using your IP address, or using data from your use of our Services to make predictions about your preferences, characteristics, psychological trends, predispositions, behavior, attitudes, intelligence, abilities, and aptitudes.</p></li>
          </ul>
          <p className="mb-4">
            If you are an end user using the Services through an account created on your behalf by another of our customers (such as an employer, an organization of which you are a member, or another individual that uses our Services), we may collect and process Customer Information about you on behalf of the customer with whom your use of the Services is associated.
          </p>
        </section>
        <section className="mb-10">
          <h3 className="text-lg font-bold mb-4">c. Third-Party Integrations</h3>
          <p className="mb-4">
            If you create your account using a service provided by a third party such as Google or Apple, or a single-sign-on service provided by a third party such as Okta, we may collect Customer Information about you from the third-party service (such as your username or user ID associated with that third-party service). If you create your account using such a third-party service, or if you give us permission by changing the settings on your Plural Account, we may also collect, and you authorize us to collect, information about your personal contacts as may be stored within that third-party service, which we may use to facilitate your invitation of collaborators to our Services. By choosing to create an account using a third-party service, you also authorize us to collect Customer Information necessary to authenticate your account with the third party.
          </p>
          <p className="mb-4">
            Certain aspects of the Services allow you to link or integrate third-party products and services to enable certain features and functionalities with the Services. For example, you can connect your Google Drive account to generate Content in the Services from information and data contained in your Google Drive or integrate with third-party services using our applications or extensions. If you choose to use these features or functionalities, you may be asked to create an account with a third party that provides such features or functionalities or link your existing third-party service account with the Services (and, by doing so, agree to the privacy policy and/or terms and conditions of that third party). You may also be asked to authorize the Services to collect information from the third party on your behalf. We will then collect information (such as your username or user ID associated with that third-party service) from you and/or that third party as necessary to enable the Services to access your data and content stored with that third-party service. Once the authentication is complete, we have the ability to access information you provided to us or was otherwise collected by the third-party service in accordance with the privacy practices of that third party. We will store the information and data we collect and associate it with your Plural Account, and we will use that information and data to enable the integration of the Services with the third-party service and to perform actions requested or initiated by you, or that are reasonably necessary to carry out instructions provided by you.
          </p>
        </section>
        <section className="mb-10">
          <h3 className="text-lg font-bold mb-4">d. Automatically-Collected Information</h3>
          <p className="mb-4">
            We collect information about how you use the Services and your actions on the Services, including your IP addresses, browser types, operating systems, ISPs, platform types, device types, and mobile device identifiers such as make and model, and mobile carrier. We may also use tools, including third-party tools, to collect analytics data. Some of this information is collected through the use of cookies and other tracking technologies, such as web beacons, pixels, session replay scripts, and similar technologies. Please see our Cookie Policy for more information about our use of cookies and similar tracking technologies.
          </p>
        </section>
        <section className="mb-10">
          <h2 className="text-xl font-bold mb-6">2. How We Use the Information We Collect</h2>
          <h3 className="text-lg font-bold mb-4">a. Use of Content</h3>
          <p className="mb-4">
            Notwithstanding anything to the contrary in this Privacy Policy, we will not use or access your Content except: to provide, maintain, improve, or optimize use of the Services; where you (or the organization through which you have an account) explicitly approve access (e.g., during a customer support inquiry or when participating in beta testing); in response to lawful requests by law enforcement or government authorities; in response to subpoenas, court orders, or other legal process or requests, as required by applicable law as determined in our discretion; where necessary (in our sole discretion) to ensure the stability and security of the Services and our systems (e.g., where we have reason to believe specific Content is degrading server stability); and where necessary (in our sole discretion) to protect the rights, privacy, safety, or property of you, us, or others. Notwithstanding the foregoing, you acknowledge and agree that we may retain, take possession of, delete, or deny you access to your Content if we believe, in our sole discretion, that some or all of your Content, or your use of the Services, violates our Terms of Service. We also may analyze metadata related to Content (such as total number of records, file size, API volume, access logs, etc.).
          </p>
        </section>
        <section className="mb-10">
          <h3 className="text-lg font-bold mb-4">b. Use of Other Information</h3>
          <p className="mb-4">
            We use the information we collect for a variety of purposes, and how we use it depends on what we collect and which Services (or features of the Services) you use. These purposes may include:
          </p>
          <ul className="list-disc ml-6">
            <li><p className="mb-2"><em>Fulfilling our Contract with You and Providing the Services to You</em>. This includes use for the purpose of: (i) responding to requests or inquiries from you; (ii) providing customer support or technical assistance; (iii) contacting you to provide product updates or information about products you have requested or purchased; (iv) creating, managing, or administering your information and account, including identifying you with your Plural Account or the account of another Plural customer; and (v) auditing relating to interactions, transactions, and other compliance activities.</p></li>
            <li><p className="mb-2"><em>Analyzing and Improving our Services Pursuant to our Legitimate Interest</em>. This includes use for the purpose of: (i) deriving market insights, ways to improve the Services, and performing other business analysis or research; (ii) customizing existing and future product offerings and other aspects of the Services to you and other users; (iii) securing the Services and our systems, and protecting your information and data; (iv) detecting security incidents and protecting against malicious, deceptive, fraudulent, or illegal activity, and prosecuting those responsible for that activity; and (v) measuring interest in and engagement with our Services and short-term, transient use, such as contextual customization of ads.</p></li>
            <li><p className="mb-2"><em>Marketing Our Products and Services</em>. This includes use for the purpose of: (i) tailoring and providing you with information about Plural products, services, or events that you may be interested in, including by email or phone, (ii) monitoring the performance of our advertisements and marketing efforts, and (iii) communicating with you about your engagement with our Services.</p></li>
            <li><p className="mb-2"><em>Legal Obligations and Rights</em>. This includes use for the purpose of: (i) establishing, exercising, investigating, prosecuting, or defending against legal claims; (ii) complying with laws or responding to lawful requests and legal process; (iii) protecting our rights and property, and the rights and property of our agents, customers, and others, including to enforce our agreements, policies, or Terms of Service; (iv) detecting, suppressing, or preventing fraud; (v) reducing credit risk and collecting debts owed to us; (vi) protecting our health and safety, or the health and safety of our Services, our customers, or any person; or (vii) fulfilling other requirements under applicable law.</p></li>
            <li><p className="mb-2"><em>Participation in any Plural partner program</em>. This includes use for the purpose of enabling or supporting participation in any such program, which may involve the provision by Plural partners of implementation, training, app-building, base-building, or other similar services.</p></li>
            <li><p className="mb-2"><em>Consent</em>. This includes use for other purposes that are clearly disclosed to you at the time you provide personal information, or with your consent.</p></li>
            <li><p className="mb-2"><em>Aggregated or De-Identified Data</em>. This includes use of aggregated or de-identified information for a wide variety of statistical, analytical, and service improvement purposes. The aggregation or de-identification process prevents the information from being reassociated or identified with any one customer account, user, or individual.</p></li>
            <li><p className="mb-2"><em>Other Purposes</em>. This includes use for our other, legitimate business purposes, or as permitted by applicable law.</p></li>
          </ul>
        </section>
        <section className="mb-10">
          <h2 className="text-xl font-bold mb-6">3. How We Disclose Information</h2>
          <p className="mb-4">
            We disclose information we collect for specific purposes, including:
          </p>
          <ul className="list-disc ml-6">
            <li><p className="mb-2">When you ask us to, or otherwise give your specific consent (for example, by posting Content using the Universe feature), you consent to our making that Content visible to users of our Services or website guests;</p></li>
            <li><p className="mb-2">With affiliates and subsidiaries to provide you with aspects of the Services, such as customer support;</p></li>
            <li><p className="mb-2">With vendors that help us provide you with aspects of the Services, such as data storage, hosting, customer support, and payment processing, or that help us with marketing and email campaigns, to advertise, gain insights, and perform analytics into how the Services are used and how they might be improved;</p></li>
            <li><p className="mb-2">With third parties who enable certain features or functionalities of the Services that you’ve requested (for example, by installing extensions in the Services);</p></li>
            <li><p className="mb-2">With an employer or other organization (or employees or other users of the Services associated with such an employer or other organization) on whose behalf you use the Services, that created an Plural Account on your behalf, or that owns, manages, or is associated with the email domain for an email address on your account;</p></li>
            <li><p className="mb-2">With the owner or collaborator of a workspace on the Services to which you have access or on which you are a collaborator as necessary to identify you to such owner or collaborator or enable you to collaborate as intended;</p></li>
            <li><p className="mb-2">With partners in any Plural partner program, when you elect to work with one of our partners for implementation, training, app-building, base-building, or other similar services.</p></li>
            <li><p className="mb-2">As necessary to comply with applicable law, including governmental requests and law enforcement requests, and otherwise to protect the rights, privacy, safety, or property of you, us, or others;</p></li>
            <li><p className="mb-2">As necessary in the event of a proposed or actual reorganization, merger, sale, joint venture, assignment, transfer, financing, or other disposition of all or any portion of our business, assets, or stock;</p></li>
            <li><p className="mb-2">With others for any legitimate business purpose that does not conflict with the statements made in this Privacy Policy.</p></li>
          </ul>
        </section>
        <section className="mb-10">
          <h2 className="text-xl font-bold mb-6">4. Transfers of Information</h2>
          <p className="mb-4">
            We may transfer to and process your personal information in countries outside of the jurisdiction where you are located for the various purposes described above. When required by law, we will ensure that we rely on an appropriate legal mechanism for the transfer, such as your consent, standard contractual clauses (or their equivalent), or adequacy decisions. You may ask us, using the contact information in Section 9 of this policy, for more information about the specific basis we use for transferring your data.
          </p>
        </section>
        <section className="mb-10">
          <h2 className="text-xl font-bold mb-6">5. Retention of Information</h2>
          <p className="mb-4">
            We store your personal information for no longer than necessary for the purposes for which it was collected, including for the purposes of satisfying any legal or reporting requirements, and in accordance with our legal obligations and legitimate business interests. To determine the appropriate retention period for personal data, we consider the amount, nature, and sensitivity of the personal data; the potential risk of harm from unauthorized use or disclosure of your personal data; the purposes for which we process your personal data; and the applicable legal requirements.
          </p>
        </section>
        <section className="mb-10">
          <h2 className="text-xl font-bold mb-6">6. Managing Your Information</h2>
          <h3 className="text-lg font-bold mb-4">a. Content</h3>
          <p className="mb-4">
            You may access, correct, amend, or delete Content within the Services. You own all Content you upload provided you have lawful title thereto. Content you delete (including Content containing personal information) may be retained in archived or backup copies in order to enable you to use certain features like revision history and base snapshots. For instructions on how to permanently delete Content from your Plural Account, please contact us at <a href="mailto:admin@withplural.com" className="text-blue-600">admin@withplural.com</a>. Please note that permanent deletion of Content through this process may impair or disable some features of the Services (such as revision history and base snapshots) with respect to that Content.
          </p>
        </section>
        <section className="mb-10">
          <h3 className="text-lg font-bold mb-4">b. Other Information</h3>
          <p className="mb-4">
            We may use some of the information we collect for marketing purposes, including to send you promotional communications about new Services features, products, events, or other opportunities. If you wish to stop receiving these communications or to opt out of use of your information for these purposes, please follow the opt-out instructions by clicking "Unsubscribe" (or similar opt-out language) in those communications. You may also contact us at <a href="mailto:admin@withplural.com" className="text-blue-600">admin@withplural.com</a> to opt out.
          </p>
          <p className="mb-4">
            You also may have certain rights with respect to your data depending on the jurisdiction in which you live. Please see the jurisdiction-specific sections below for a description of those rights.
          </p>
        </section>
        <section className="mb-10">
          <h2 className="text-xl font-bold mb-6">7. Information from Children</h2>
          <p className="mb-4">
            Our Services are not intended for use by children under the age of 16 (or other age as required by local law) and we do not knowingly collect personal information from children. If we learn that we have collected personal information from a child, we will take reasonable steps to delete such information from our files as soon as is practicable. If you learn that your child has provided us with personal information without your consent, you may contact us at <a href="mailto:admin@withplural.com" className="text-blue-600">admin@withplural.com</a>.
          </p>
        </section>
        <section className="mb-10">
          <h2 className="text-xl font-bold mb-6">8. Changes to Privacy Policy</h2>
          <p className="mb-4">
            Any information that we collect is subject to the Privacy Policy in effect at the time such information is collected. We may, however, revise the Privacy Policy from time to time. If a revision is material, as determined solely by us, we will notify you via email. The current version will always be posted to our Privacy Policy page.
          </p>
        </section>
        <section className="mb-10">
          <h2 className="text-xl font-bold mb-6">9. Contact Us</h2>
          <p className="mb-4">
            If you have any questions about our privacy practices, including this policy, you may contact us by email at <a href="mailto:admin@withplural.com" className="text-blue-600">admin@withplural.com</a> or by mail.
          </p>
        </section>
        <section className="mb-10">
          <h2 className="text-xl font-bold mb-6">10. EEA, UK, Swiss-Specific Disclosures</h2>
          <p className="mb-4">
            The disclosures in this section apply solely to residents of the European Economic Area ("EEA"), Switzerland, and the United Kingdom ("UK"), and describe how we collect, use, disclose, and otherwise process personal data about you. Unless otherwise expressly stated, all terms in this section have the same meaning as defined in the General Data Protection Regulation ("GDPR"). We are the controller of the personal data we hold about you in connection with your use of the Services.
          </p>
        </section>
        <section className="mb-10">
          <h3 className="text-lg font-bold mb-4">a. Lawful Basis of Processing</h3>
          <p className="mb-4">
            We collect and process your personal data for purposes set forth in this Privacy Policy. Where required by law, we obtain your consent to use and process your personal data for these purposes. Otherwise, we rely on another authorized legal basis (including but not limited to the performance of a contract or legitimate interest) to collect and process your Personal Data.
          </p>
        </section>
        <section className="mb-10">
          <h3 className="text-lg font-bold mb-4">b. Marketing and Advertising</h3>
          <p className="mb-4">
            From time to time we may contact you with information about our products and services, including sending you marketing or advertising messages and asking for your feedback on our products and services. For some marketing or advertising messages, we may use personal data we collect about you to help us determine the most relevant marketing or advertising information to share with you. You can unsubscribe at any time from our marketing or advertising emails by clicking on the unsubscribe link (or similar opt-out language) at the bottom of the email or by contacting us at <a href="mailto:admin@withplural.com" className="text-blue-600">admin@withplural.com</a>.
          </p>
          <p className="mb-4">
            In addition, when you give us your consent, we may provide your information to third parties for targeted advertising or we may allow our advertising partners to collect data about you for that purpose using cookies or similar technologies. For more information, please see our Cookie Policy.
          </p>
        </section>
        <section className="mb-10">
          <h3 className="text-lg font-bold mb-4">c. Your Privacy Rights</h3>
          <p className="mb-4">
            You have the following rights in respect of your personal data that we hold: (i) right of access; (ii) right of portability; (iii) right to rectification; (iv) right to erasure; (v) right to restriction; (vi) right to withdraw consent; and (vii) right to object.
          </p>
          <p className="mb-4">
            You also have the right to lodge a complaint to your local data protection authority. Information about how to contact your local data protection authority is available <a href="https://ec.europa.eu/newsroom/article29/items/612080" className="text-blue-600" target="_blank">here</a>.
          </p>
          <p className="mb-4">
            If you wish to exercise one of these rights, please submit a request by:
          </p>
          <ul className="list-disc ml-6">
            <li><p className="mb-2">Emailing <a href="mailto:admin@withplural.com" className="text-blue-600">admin@withplural.com</a> with the subject line "European Rights Request", or</p></li>
            <li><p className="mb-2">Filling out a Data Subject Request by emailing <a href="mailto:admin@withplural.com" className="text-blue-600">admin@withplural.com</a></p></li>
          </ul>
          <p className="mb-4">
            Due to the confidential nature of data processing we may ask you to provide proof of identity when exercising the above rights.
          </p>
        </section>
        <section className="mb-10">
          <h2 className="text-xl font-bold mb-6">11. California-Specific Disclosures</h2>
          <p className="mb-4">
            The disclosures in this section apply solely to individual residents of the State of California and provide additional information about how we collect, use, disclose, and otherwise process personal information within the scope of the California Consumer Privacy Act of 2018, as amended, including its implementing regulations ("CCPA"). Unless otherwise expressly stated, all terms in this section have the same meaning as defined in the CCPA.
          </p>
        </section>
        <section className="mb-10">
          <h3 className="text-lg font-bold mb-4">a. Sensitive Personal Information</h3>
          <p className="mb-4">
            Certain data elements we collect and use to provide the Services may be deemed "sensitive personal information" under CCPA. These include your username and password to access your account and contents of any messages you send through our email integration feature. We do not use or disclose such sensitive personal information to “infer” characteristics as defined under the CCPA, or for any purpose other than that which is necessary to provide the Services as specified in the CCPA.
          </p>
        </section>
        <section className="mb-10">
          <h3 className="text-lg font-bold mb-4">b. Sales and Sharing of Personal Information (Targeted Advertising)</h3>
          <p className="mb-4">
            We use cookies and similar tracking technologies that enable certain advertising networks, social media companies, analytics services, and other third-party businesses to collect and disclose your personal information directly from your browser or device when you visit or interact with our Services or otherwise engage with us online. In some cases, we may upload personal information to certain of these partners for advertising or analytics purposes.
          </p>
          <p className="mb-4">
            To opt out of these “sales” or “sharing” of personal information (as these terms are defined under the CCPA or other applicable US state privacy laws), you must:
          </p>
          <ul className="list-disc ml-6">
            <li><p className="mb-2">Toggle cookies off in our or enable Global Privacy Control (“GPC”) on your browser; and</p></li>
            <li><p className="mb-2">Submit a request using email to <a href="mailto:admin@withplural.com" className="text-blue-600">admin@withplural.com</a>.</p></li>
          </ul>
          <p className="mb-4">
            Note that the above opt-out right does not apply where we have appropriately limited our partners to be our “service providers” or “processors” (as these terms are defined under the CCPA or other applicable US state privacy laws).
          </p>
          <p className="mb-4">
            To learn more about GPC, please visit <a href="http://globalprivacycontrol.org" className="text-blue-600" target="_blank">http://globalprivacycontrol.org</a>.
          </p>
        </section>
        <section className="mb-10">
          <h3 className="text-lg font-bold mb-4">c. California Privacy Rights</h3>
          <p className="mb-4">
            As a California resident, you may be able to exercise the following rights in relation to the personal information about you that we have collected (subject to certain limitations at law):
          </p>
          <ul className="list-disc ml-6">
            <li><p className="mb-2">The Right to Know any or all of the following information relating to your personal information we have collected and disclosed in the last 12 months, upon verification of your identity:</p></li>
            <li><p className="mb-2">The specific pieces of personal information we have collected about you;</p></li>
            <li><p className="mb-2">The categories of personal information we have collected about you;</p></li>
            <li><p className="mb-2">The categories of sources of the personal information;</p></li>
            <li><p className="mb-2">The categories of personal information that we have disclosed to third parties for a business purpose, and the categories of recipients to whom this information was disclosed;</p></li>
            <li><p className="mb-2">The categories of personal information we have sold or shared and the categories of third parties to whom the information was sold or shared; and</p></li>
            <li><p className="mb-2">The business or commercial purposes for collecting, selling, or sharing the personal information.</p></li>
            <li><p className="mb-2">The Right to Request Deletion of personal information we have collected from you, subject to certain exceptions.</p></li>
            <li><p className="mb-2">The Right to Request Correction of inaccurate personal information.</p></li>
            <li><p className="mb-2">The Right to Opt Out of Personal Information Sales or Sharing to third parties now or in the future.</p></li>
            <li><p className="mb-2">You also have the right to be free of discrimination for exercising these rights. However, please note that if the exercise of these rights limits our ability to process personal information (such as in the case of a deletion request), we may no longer be able to provide you our Services or engage with you in the same manner.</p></li>
          </ul>
        </section>
        <section className="mb-10">
          <h3 className="text-lg font-bold mb-4">d. How to Exercise Your California Privacy Rights</h3>
          <ul className="list-disc ml-6">
            <li><p className="mb-2">Please see Section 11.b. above to exercise your right to opt out of personal information sales or sharing.</p></li>
            <li><p className="mb-2">To exercise your rights to know, correct, or delete, please submit a request by:</p></li>
            <li><p className="mb-2">Emailing <a href="mailto:admin@withplural.com" className="text-blue-600">admin@withplural.com</a> with the subject line "California Rights Request" or</p></li>
            <li><p className="mb-2">Filling out a Data Subject Request by emailing <a href="mailto:admin@withplural.com" className="text-blue-600">admin@withplural.com</a></p></li>
          </ul>
          <p className="mb-4">
            We will need to verify your identity before processing your request. In order to verify your identity, we will generally require either the successful login to your account (if applicable) and/or the matching of sufficient information you provide us to the information we maintain about you in our systems. Although we try to limit the personal information collected in connection with a request to know, correct, or delete, certain requests may require us to obtain additional personal information from you. In certain circumstances, we may decline a request to exercise the right to know, correct, or delete, particularly where we are unable to verify your identity or locate your information in our systems, or as permitted by law.
          </p>
        </section>
        <section className="mb-10">
          <h3 className="text-lg font-bold mb-4">e. Minors Under Age 16</h3>
          <p className="mb-4">
            We do not sell or share the personal information of consumers we know to be less than 16 years of age. Please contact us at <a href="mailto:admin@withplural.com" className="text-blue-600">admin@withplural.com</a> to inform us if you, or your minor child, are under the age of 16.
          </p>
        </section>
        <section className="mb-10">
          <h3 className="text-lg font-bold mb-4">f. California’s "Shine the Light" Law</h3>
          <p className="mb-4">
            In addition to the rights described above, California's "Shine the Light" law (Civil Code Section §1798.83) permits California residents that have an established business relationship with us to request certain information regarding our disclosure of certain types of personal information to third parties for their direct marketing purposes during the immediately preceding calendar year.
          </p>
          <p className="mb-4">
            To make such a request, please send an email to <a href="mailto:admin@withplural.com" className="text-blue-600">admin@withplural.com</a>.
          </p>
        </section>
        <section className="mb-10">
          <h2 className="text-xl font-bold mb-6">12. Disclosures to Residents of Colorado, Connecticut, Montana, Oregon, Texas, Utah, and Virginia</h2>
          <p className="mb-4">
            The disclosures in this section apply solely to individual residents of the States of Colorado, Connecticut, Montana, Oregon, Texas, Utah, and Virginia. Privacy laws in Colorado, Connecticut, Utah, and Virginia give residents certain rights with respect to their personal data, and privacy laws in Montana, Oregon, and Texas will give residents certain rights with respect to their personal data when they take effect over the course of 2024. Those rights include:
          </p>
          <ul className="list-disc ml-6">
            <li><p className="mb-2">Right to Access Information. You have the right to access and obtain a copy of your personal data.</p></li>
            <li><p className="mb-2">Right to Request Deletion. You have the right to request that we delete personal data provided by or obtained about you.</p></li>
            <li><p className="mb-2">Right to Correct. You have the right to correct inaccuracies in your personal data.</p></li>
            <li><p className="mb-2">Right to Opt-Out of Targeted Advertising. You may ask us not to use or disclose your information for the purposes of targeting advertising to you based on your personal data obtained from your activity across different businesses, services, websites, etc.</p></li>
            <li><p className="mb-2">Right to Opt Out of Personal Information Sales to third parties.</p></li>
          </ul>
          <p className="mb-4">
            To submit a request to exercise your access, deletion, or correction privacy rights, please email us at <a href="mailto:admin@withplural.com" className="text-blue-600">admin@withplural.com</a> with the subject line “Privacy Rights Request” and let us know in which state you live. Please see Section 11.b. for a description of how to exercise your right to opt-out of targeted advertising or sales.
          </p>
          <p className="mb-4">
            Residents of Colorado, Connecticut, Montana, Oregon, Texas, and Virginia may appeal a refusal to take action on a request by contacting us by email at <a href="mailto:admin@withplural.com" className="text-blue-600">admin@withplural.com</a>.
          </p>
          <p className="mb-4">
            Residents of Oregon may request that we provide a list of third parties to which we have disclosed personal data. To make such a request, please follow the instructions above for submitting an access, deletion, or correction request.
          </p>
        </section>
        <section className="mb-10">
          <h2 className="text-xl font-bold mb-6">13. Nevada-Specific Disclosures</h2>
          <p className="mb-4">
            For residents of the State of Nevada, Chapter 603A of the Nevada Revised Statutes permits a Nevada resident to opt out of future sales of certain covered information that a website operator has collected or will collect about the resident. Although we do not currently sell covered information, please contact us at <a href="mailto:admin@withplural.com" className="text-blue-600">admin@withplural.com</a> to submit such a request.
          </p>
        </section>
        <section className="mb-10">
          <h2 className="text-xl font-bold mb-6">14. Canada-Specific Disclosures</h2>
          <p className="mb-4">
            If you live in Canada, you have the following rights:
          </p>
          <ul className="list-disc ml-6">
            <li><p className="mb-2">Right to Access. You can ask us to: (i) confirm that we have personal information about you, and (ii) provide you a copy of that information.</p></li>
            <li><p className="mb-2">Right to Correct. You can ask us to correct any inaccurate or incomplete personal information that we have about you.</p></li>
            <li><p className="mb-2">You may submit a request by contacting us at <a href="mailto:admin@withplural.com" className="text-blue-600">admin@withplural.com</a> with the subject line “Canadian Privacy Rights Request”. Before we honor your request, we will need to verify your identity.</p></li>
          </ul>
        </section>
      </main>
    </div>
  )
}
