import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import { ComponentProps, ReactNode } from 'react'

type DropdownProps = ComponentProps<typeof DropdownMenu.Item> & {
  text: ReactNode
  startIcon?: ReactNode
  endIcon?: ReactNode
}

const className = 'dropdown-item'

export function DropdownItem(props: DropdownProps) {
  let { text, startIcon, endIcon, ...otherProps } = props
  return (
    <DropdownMenu.Item
      {...otherProps}
      className={`${className} ${props.className} hoved:round flex cursor-pointer flex-row items-center gap-4 p-3 hover:bg-[#EDEDED] hover:outline-none`}>
      {startIcon && startIcon}
      <div className="flex-1 text-sm">{text}</div>
      {endIcon && <div className="ml-auto">{endIcon}</div>}
    </DropdownMenu.Item>
  )
}
DropdownItem.toString = () => `.${className}`
