import { PageLoader } from '../../PageLoader'
import { usePluralAuth } from '../../providers/PluralAuthProvider'
import { format } from 'date-fns'
import { Link } from 'react-router-dom'
import { Building2, Coins, Wallet } from 'lucide-react'
import { useQuery } from '@tanstack/react-query'
import { MASSIVE_DEV_URL } from 'plural-shared/constants'

interface NewsItem {
    id: number
    companyId: string
    createdAt: string
    type: 'funding_round' | 'acquisition' | 'new_fund'
    tag: string
    snippet: string
    investors: string[]
}

const fetchNewsData = async (): Promise<NewsItem[]> => {
    const response = await fetch(`${MASSIVE_DEV_URL}/api/get-funding-rounds`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer 2bfcde2c-1b72-40c1-a45b-6ee66c885e8d',
        },
    })
    if (!response.ok) {
        throw new Error('Network response was not ok')
    }
    return response.json()
}

export default function NewsPage() {
    const { isLoading: isAuthLoading, authStatus } = usePluralAuth()

    const { data: newsData, isLoading, error } = useQuery<NewsItem[], Error>({
        queryKey: ['newsData'],
        queryFn: fetchNewsData
    })

    if (isAuthLoading || isLoading) {
        return <PageLoader />
    }

    if (error) {
        console.error('Error fetching news data:', error)
    }

    return (
        <div className="ml-16 mr-16 flex max-w-page-supermax flex-col items-center">
            <div className="ml-6 mr-6 flex w-full flex-col items-center">
                <div className="w-full">
                    <h1 className="mt-8 text-3xl font-medium">Tech News</h1>
                    <NewsList news={newsData || []} />
                </div>
            </div>
        </div>
    )
}


function NewsList(props: { news: NewsItem[] }) {
    return (
        <div className="mt-6 flex flex-col gap-4">
            {/* only grab the top 35 news articles */}
            {props.news.slice(0, 35).map((article, index) => (
                <NewsArticle key={index} article={article} />
            ))}
        </div>
    )
}

function NewsArticle({ article }: { article: NewsItem }) {
    const { companyId, createdAt, type, tag, snippet, investors } = article

    const getIcon = () => {
        switch (type) {
            case 'funding_round':
                return <Coins size={16} className="text-blue-600" />
            case 'acquisition':
                return <Building2 size={16} className="text-green-600" />
            case 'new_fund':
                return <Wallet size={16} className="text-purple-600" />
            default:
                return null
        }
    }

    return (
        <Link to={`/company/${companyId}`} className="block rounded-lg border p-4 hover:bg-gray-50">
            <div className="flex items-center gap-2">
                {getIcon()}
                <span className="text-sm font-medium text-gray-600">
                    {format(new Date(createdAt), 'MMM d, yyyy')}
                </span>
                <span className="rounded bg-gray-200 px-2 py-1 text-xs font-medium text-gray-700">
                    {tag}
                </span>
            </div>
            <h2 className="mt-2 text-lg font-medium">{snippet}</h2>
            {investors.length > 0 && (
                <div className="mt-2 text-sm text-gray-600">
                    {/* hacky way to ensure no duplicate investors appear */}
                    Investors: {[...new Set(investors)].join(', ')}
                </div>
            )}
        </Link>
    )
}