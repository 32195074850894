import { useEffect, useState } from 'react'
import { trpc } from 'utils/trpc'
import { InfoItem } from './InfoItem'

export default function DealSensitivityEquityMetadata({
  yearsToLiquidity,
  warehousedAssetCAGR,
  reserveRatio,
}: {
  yearsToLiquidity: number
  warehousedAssetCAGR: number
  reserveRatio: number
}) {
  const { data } = trpc.dealSensitivity.equityMetadata.useQuery({
    yearsToLiquidity,
    warehousedAssetCAGRFromBase: warehousedAssetCAGR,
    warehousedAssetReserveRatio: reserveRatio,
  })

  const [companyInfoItems, setCompanyInfoItems] = useState<InfoItem[]>([])

  useEffect(() => {
    if (data) {
      const companyItems: InfoItem[] = [
        {
          label: 'Initial Value',
          value: data.companyInitialValue,
        },
        {
          label: 'Realized Value',
          value: data.companyEndValue,
        },
        {
          label: 'Total Growth',
          value: data.companyTotalReturnPercentage,
        },
        {
          label: 'IRR',
          value: data.companyIRR,
        },
      ]
      setCompanyInfoItems(companyItems)
    }
  }, [data])

  return (
    <div className="flex-auto">
      <div className="flex flex-auto flex-row flex-wrap lg:space-x-12">
        {companyInfoItems.map((item, index) => {
          return (
            <div key={`info-${index}`} className="w-full lg:w-auto">
              <div className="text-sm opacity-60">{item.label}</div>
              <div className={`mt-1 text-base font-medium ${item.valueClass ?? ''}`}>{item.value}</div>
            </div>
          )
        })}
      </div>
    </div>
  )
}
