import { addSeconds } from 'date-fns'
import { Coefficients, FundingRound, GraphDataPoint, GraphMethodology, CalculatedExitScenario } from './types'
import {
  calculateFixedCAGRCompanyValuationAtDate,
  numberToAbbreviatedString,
  yearsBetweenDates,
} from 'plural-shared/utils'

type DataPoint = [number, number]

const MINIMUM_COMPANY_CAGR = 0.2

const predict = (x: number, coefficients: Coefficients) => {
  const { a, b, c } = coefficients
  return (a * Math.exp(b * x) + c) * 1_000_000
}

const getYearsSinceDate = (date: Date, sinceDate: Date) => {
  const yearsSinceDate = (date.getTime() - sinceDate.getTime()) / (1000 * 60 * 60 * 24 * 365.25)
  return Number(yearsSinceDate)
}

/*
 * Transform the funding rounds into data points
 *
 * @param {FundingRound[]} fundingRounds - The funding rounds to transform into data points
 * @returns {DataPoint[]}
 *
 */
export const transformFundingRoundsToDataPoints = (
  fundingRounds: FundingRound[],
  incorporationDate: Date,
): DataPoint[] => {
  return fundingRounds.map((round) => [getYearsSinceDate(round.date, incorporationDate), round.valuation])
}

export const finalFormulaToString = (coefficients: Coefficients, scalingFactor: number, adjustmentFactor: number) => {
  const { a, b, c } = coefficients
  return `${a * scalingFactor} * e^(${b} * x) + ${c + adjustmentFactor}`
}

export const generateGraphData = (
  fundingRounds: FundingRound[],
  incorporationDate: Date,
  targetExitDate: Date,
  targetExitValuation: number,
  coefficients: Coefficients,
): { results: GraphDataPoint[]; todaysValuation: number; graphMethodology: GraphMethodology } => {
  // console.log(`starting valuation: ${predict(0, coefficients)}`)
  // Calculate the valuation for today's date
  const todaysDate = new Date()
  const todaysDateIncorporationDateDelta = getYearsSinceDate(todaysDate, incorporationDate)
  const todaysValuation = predict(todaysDateIncorporationDateDelta, coefficients)

  // We enforce a minimum annual growth rate of 20%, ideally from the date the company joins.
  // We estimate that as today's date, unless the target exit date is before today's date, in which case we use the
  // incorporation date as the starting date.
  const isTodaysDateAfterTargetExitDate = todaysDate.getTime() > targetExitDate.getTime()
  let startingDate = todaysDate
  let startingValuation = todaysValuation
  if (isTodaysDateAfterTargetExitDate) {
    startingDate = incorporationDate
    startingValuation = predict(0, coefficients)
  }

  const yearsDiff = yearsBetweenDates({ start: startingDate, end: targetExitDate })
  const totalReturn = targetExitValuation / startingValuation
  const annualizedReturn = Math.pow(totalReturn, 1 / yearsDiff) - 1

  // console.log(`annualized return: ${annualizedReturn}`)
  if (annualizedReturn < MINIMUM_COMPANY_CAGR) {
    // console.log(`Using minimum growth rate line`)
    return {
      ...generateMinimumGrowthRateLine(incorporationDate, targetExitDate, targetExitValuation, coefficients),
      graphMethodology: 'MINIMUM_GROWTH_RATE',
    }
  } else {
    return {
      ...generateBestFitExponentialCurveData(
        fundingRounds,
        incorporationDate,
        targetExitDate,
        targetExitValuation,
        coefficients,
      ),
      graphMethodology: 'BEST_FIT_EXPONENTIAL_CURVE',
    }
  }
}

const generateMinimumGrowthRateLine = (
  incorporationDate: Date,
  targetExitDate: Date,
  targetExitValuation: number,
  coefficients: Coefficients,
): { results: GraphDataPoint[]; todaysValuation: number } => {
  const dataPoints = Math.max(
    5000,
    Math.floor((targetExitDate.getTime() - incorporationDate.getTime()) / (24 * 60 * 60 * 1000)),
  ) // At least 5000 data points
  const step = Math.floor((targetExitDate.getTime() - incorporationDate.getTime()) / dataPoints)

  const results: GraphDataPoint[] = []

  // Calculate annualized growth rate over sample
  const growthRate = 0.2 // You can adjust this value to fit your specific scenario

  for (let i = dataPoints - 1; i >= 0; i--) {
    const currentDate = new Date(targetExitDate.getTime() - i * step)
    // I believe this is the wrong way to calcuate the valuation
    // const timeDiff = targetExitDate.getTime() - currentDate.getTime()
    // const yearsDiff = timeDiff / (365.25 * 24 * 60 * 60 * 1000) // Convert milliseconds to years
    // const valuation = targetExitValuation * Math.pow(1 - growthRate, yearsDiff)
    // fixed here
    const valuation = calculateFixedCAGRCompanyValuationAtDate({
      cagr: growthRate,
      exitValuation: targetExitValuation,
      valuationDate: currentDate,
      exitDate: targetExitDate,
    })
    results.unshift({ date: currentDate, value: valuation })
  }

  const todaysDate = new Date()
  // same here, should use traditional cagr
  // const todaysDateIncorporationDateDelta =
  //   (targetExitDate.getTime() - todaysDate.getTime()) / (365.25 * 24 * 60 * 60 * 1000) // Convert milliseconds to years
  // const todaysValuation = targetExitValuation * Math.pow(1 - growthRate, todaysDateIncorporationDateDelta)
  const todaysValuation = calculateFixedCAGRCompanyValuationAtDate({
    cagr: growthRate,
    exitValuation: targetExitValuation,
    valuationDate: todaysDate,
    exitDate: targetExitDate,
  })

  return { results, todaysValuation }
}

/*
 * Generate the data points for the adjusted exponential best fit curve
 *
 * @param {FundingRound[]} fundingRounds - The funding rounds to use for the curve of best fit
 * @param {Date} incorporationDate - The incorporation date of the company
 * @param {Date} targetExitDate - The target exit date
 * @param {number} targetExitValuation - The target exit valuation
 * @param {Coefficients} coefficients - The coefficients (a, b, c) of the computed exponential regression equation
 * @returns {GraphData} - The graph data points for the exponential curve of best fit for the funding rounds
 */
const generateBestFitExponentialCurveData = (
  fundingRounds: FundingRound[],
  incorporationDate: Date,
  targetExitDate: Date,
  targetExitValuation: number,
  coefficients: Coefficients,
): { results: GraphDataPoint[]; todaysValuation: number } => {
  const fundingRoundsWithTargetRound = [
    ...fundingRounds,
    {
      date: targetExitDate,
      valuation: targetExitValuation,
    },
  ]

  // [x, y] = [years since incorporation, valuation]
  // for each round, x=years since incorporation date, y=valuation
  const fundingRoundDataPoints = transformFundingRoundsToDataPoints(fundingRoundsWithTargetRound, incorporationDate)

  const fundingRoundDateSet = new Set<number>(fundingRoundDataPoints.map((point) => point[0]))

  const additionalPoints: DataPoint[] = []

  const targetExitYear = getYearsSinceDate(
    fundingRoundsWithTargetRound[fundingRoundsWithTargetRound.length - 1].date,
    incorporationDate,
  )
  // console.log('targetExitYear', targetExitYear)

  const dataPointDelta = targetExitYear / 5001
  // Generate 5000 points for each year between year 0 and the target exit year
  for (let i = 0; i < 5000; i++) {
    const year = i * dataPointDelta

    if (fundingRoundDateSet.has(year)) {
      continue
    }

    const valuation = predict(year, coefficients)
    additionalPoints.push([year, valuation])
  }

  const graphData: GraphDataPoint[] = [
    ...fundingRoundDataPoints.map((data) => ({
      date: addSeconds(incorporationDate, data[0] * (365.25 * 24 * 60 * 60)),
      value: Math.abs(predict(data[0], coefficients)),
    })),
    ...additionalPoints.map((point) => ({
      date: addSeconds(incorporationDate, point[0] * (365.25 * 24 * 60 * 60)),
      value: point[1],
    })),
  ]

  const results = graphData.sort((a, b) => a.date.getTime() - b.date.getTime())

  // Calculate the valuation for today's date
  const todaysDate = new Date()
  const todaysDateIncorporationDateDelta = getYearsSinceDate(todaysDate, incorporationDate)
  const todaysValuation = predict(todaysDateIncorporationDateDelta, coefficients)

  return { results, todaysValuation }
}
