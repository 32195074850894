import AppModal from 'components/AppModal'
import AppTextField from 'components/AppTextfield'
import { BasicButton } from 'components/BasicButton'
import { Button } from 'components/ui/button'
import { BadgePlus } from 'lucide-react'
import React, { useState } from 'react'

export default function CreateNewSheetModal(props: {
  onCreateSheet: (sheetName: string) => void
  defaultSheetName?: string
  asDropdownButton?: boolean
  open?: boolean
  setIsOpen?: (isOpen: boolean) => void
}) {
  let [_isOpen, _setIsOpen] = useState(false)
  let isOpen = props.open ?? _isOpen
  let setIsOpen = props.setIsOpen ?? _setIsOpen
  let ref = React.createRef<HTMLButtonElement>()

  let [sheetName, setSheetName] = useState(props.defaultSheetName ?? '')
  return (
    <>
      {props.open != null ? (
        <></>
      ) : props.asDropdownButton ? (
        <div
          onClick={() => {
            setIsOpen(true)
          }}
          className="flex cursor-pointer items-center gap-2 text-sm">
          Save as New List
          <BadgePlus className="h-[15px] w-[15px]" />
        </div>
      ) : (
        <BasicButton
          attioStyle
          variant="gray"
          className="flex items-center gap-2"
          onClick={() => {
            setIsOpen(true)
          }}>
          <BadgePlus className="h-[15px] w-[15px]" />
          Save as New List
        </BasicButton>
      )}
      <AppModal
        noPortal
        open={isOpen}
        onClose={() => {
          setIsOpen(false)
          setSheetName('')
        }}>
        <div className="flex min-w-[750px] flex-col gap-4 p-4">
          <div className="font-sm text-lg">Create New Sheet</div>
          <AppTextField
            autoFocus
            value={sheetName}
            onChange={(e) => setSheetName(e.target.value)}
            placeholder="Sheet Name"
            fullWidth
          />
          <Button
            className="ml-auto w-[125px]"
            ref={ref}
            onClick={() => {
              props.onCreateSheet(sheetName)
              setIsOpen(false)
              setSheetName('')
            }}>
            Create List
          </Button>
        </div>
      </AppModal>
    </>
  )
}
