import { ColumnDef, createColumnHelper, FilterFnOption, SortingFnOption } from '@tanstack/react-table'
import EditableTanstackTable from 'components/editable-tanstack-table/EditableTanstackTable'
import { formatDuration, intervalToDuration } from 'date-fns'
import { ChevronDown, ChevronRight } from 'lucide-react'
import {
  numberToReadableDollarString,
} from 'plural-shared/utils'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { ArrayElement } from 'safety'
import { RouterOutputs, trpc } from '../utils/trpc'
import TwoLayerPieChart, { CustomTooltipProps } from './TwoLayerPieChart'
import Tabs from 'components/Tabs'

type RouterCompanyRow = ArrayElement<RouterOutputs['fundFileRouter']['handlePrivateEquityFileUpload']['portfolio_summary']>;
type RouterAssetMetricsRow = ArrayElement<RouterOutputs['fundFileRouter']['handlePrivateEquityFileUpload']['asset_metrics']>;
type RouterFundMetricsRow = ArrayElement<RouterOutputs['fundFileRouter']['handlePrivateEquityFileUpload']['fund_metrics']>;

function getTimeFromMarkToLastFundingRound(markDate: Date, lastFundingRoundDate: Date | undefined) {
  if (lastFundingRoundDate) {
    const suffix = markDate > lastFundingRoundDate ? ' before' : ' after'
    return (
      formatDuration(intervalToDuration({ start: markDate, end: lastFundingRoundDate }), {
        format: ['years', 'months'],
      }) + suffix
    )
  }
  return ''
}

const AssetMetricsTable = ({ data }: { data: RouterAssetMetricsRow[] }) => {
  const columnHelper = createColumnHelper<RouterAssetMetricsRow>()
  const columns = useMemo<ColumnDef<RouterAssetMetricsRow, any>[]>(() => [
    columnHelper.accessor('asset', {
      header: 'Asset',
      cell: (info) => <div className="h-full max-w-full truncate pl-1">{info.getValue()}</div>,
      minSize: 200,
      meta: {
        className: 'z-30 sticky left-0 bg-white',
        headerClassName: 'z-40 sticky left-0 bg-white',
        isImmovable: true,
        isUnhideable: true,
      },
      filterFn: 'stringFn' as FilterFnOption<RouterAssetMetricsRow>,
      sortingFn: 'stringFn' as SortingFnOption<RouterAssetMetricsRow>,
    }),
    columnHelper.accessor('metric', {
      header: 'Metric',
      cell: (info) => <div className="h-full max-w-full truncate pl-1">{info.getValue()}</div>,
      size: 200,
      filterFn: 'stringFn' as FilterFnOption<RouterAssetMetricsRow>,
      sortingFn: 'stringFn' as SortingFnOption<RouterAssetMetricsRow>,
    }),
    columnHelper.accessor('value', {
      header: 'Value',
      cell: (info) => <div className="h-full max-w-full truncate pl-1">{info.getValue()}</div>,
      size: 150,
      filterFn: 'stringFn' as FilterFnOption<RouterAssetMetricsRow>,
      sortingFn: 'stringFn' as SortingFnOption<RouterAssetMetricsRow>,
    }),
    columnHelper.accessor('multiplier', {
      header: 'Multiplier',
      cell: (info) => <div className="h-full max-w-full truncate pl-1">{info.getValue()}</div>,
      size: 150,
      filterFn: 'stringFn' as FilterFnOption<RouterAssetMetricsRow>,
      sortingFn: 'stringFn' as SortingFnOption<RouterAssetMetricsRow>,
    }),
    columnHelper.accessor('time_period', {
      header: 'Time Period',
      cell: (info) => <div className="h-full max-w-full truncate pl-1">{info.getValue()}</div>,
      size: 150,
      filterFn: 'stringFn' as FilterFnOption<RouterAssetMetricsRow>,
      sortingFn: 'stringFn' as SortingFnOption<RouterAssetMetricsRow>,
    }),
    columnHelper.accessor('reported_date', {
      header: 'Reported Date',
      cell: (info) => <div className="h-full max-w-full truncate pl-1">{info.getValue()}</div>,
      size: 150,
      filterFn: 'dateFn' as FilterFnOption<RouterAssetMetricsRow>,
      sortingFn: 'dateFn' as SortingFnOption<RouterAssetMetricsRow>,
    }),
  ], [columnHelper])

  return (
    <EditableTanstackTable
      borders="top"
      stickyHeader
      stickyColumn
      fullPage
      data={data}
      columns={columns}
      sortingFns={{}}
      filterFns={{}}
      defaultSorting={{ id: 'asset', desc: true }}
    />
  )
}

const FundMetricsTable = ({ data }: { data: RouterFundMetricsRow[] }) => {
  const columnHelper = createColumnHelper<RouterFundMetricsRow>()
  const columns = useMemo<ColumnDef<RouterFundMetricsRow, any>[]>(() => [
    columnHelper.accessor('metric', {
      header: 'Metric',
      cell: (info) => <div className="h-full max-w-full truncate pl-1">{info.getValue()}</div>,
      size: 200,
      filterFn: 'stringFn' as FilterFnOption<RouterFundMetricsRow>,
      sortingFn: 'stringFn' as SortingFnOption<RouterFundMetricsRow>,
    }),
    columnHelper.accessor('value', {
      header: 'Value',
      cell: (info) => <div className="h-full max-w-full truncate pl-1">{info.getValue()}</div>,
      size: 150,
      filterFn: 'stringFn' as FilterFnOption<RouterFundMetricsRow>,
      sortingFn: 'stringFn' as SortingFnOption<RouterFundMetricsRow>,
    })
  ], [columnHelper])

  return (
    <div className="w-[95vw]">
      <EditableTanstackTable
        borders="top"
        stickyHeader
        stickyColumn
        fullPage
        data={data}
        columns={columns}
        sortingFns={{}}
        filterFns={{}}
        defaultSorting={{ id: 'metric', desc: true }}
      />
    </div>
  )
}

export default function PrivateEquityView({ data, isLoading }: { data: RouterOutputs['fundFileRouter']['handlePrivateEquityFileUpload'], isLoading: boolean }) {
  const [totalMismatch, setTotalMismatch] = useState<{ cost: number; value: number } | null>(null)

  const [chartData, setChartData] = useState<{
    innerData: { name: string; value: number; color: string }[]
    outerData: { name: string; value: number; color: string; share_class?: string }[]
  }>({ innerData: [], outerData: [] })
  const processChartData = (data: RouterCompanyRow[]) => {
    const colorMap: { [key: string]: [number, number, number] } = {}
    const generateColor = (baseColor: [number, number, number], totalChildren: number, level: number) => {
      const shadeIncrement = 1 / totalChildren
      const shadeFactor = 1 - shadeIncrement * level * 0.2
      return `rgba(${Math.round(baseColor[0] * shadeFactor)}, ${Math.round(baseColor[1] * shadeFactor)}, ${Math.round(baseColor[2] * shadeFactor)}, 1)`
    }

    const baseColors: [number, number, number][] = [
      [255, 99, 132], // red
      [54, 162, 235], // blue
      [255, 206, 86], // yellow
      [75, 192, 192], // green
      [153, 102, 255], // purple
      [255, 159, 64], // orange
    ]

    const groupedData = data.reduce((acc: any, item: RouterCompanyRow) => {
      if (!acc[item.company_name]) {
        const baseColor = baseColors[Object.keys(acc).length % baseColors.length]
        colorMap[item.company_name] = baseColor

        acc[item.company_name] = {
          name: item.company_name,
          value: Number(item.total_value),
          cost: Number(item.invested_cost),
          children: []
        }
      }
      return acc
    }, {})

    // inner circle is a solid color
    const innerData = Object.values(groupedData).map((company: any) => ({
      name: company.name,
      cost: company.cost,
      value: company.value,
      color: `rgba(${colorMap[company.name][0]}, ${colorMap[company.name][1]}, ${colorMap[company.name][2]}, 1)`,
    }))

    // outer circle is a solid color but darkened by the position (so 2nd slice is darker than first slice)
    const outerData = Object.values(groupedData).flatMap((company: any) => {
      return company.children.map((child: any, index: number) => ({
        ...child,
        color: generateColor(colorMap[company.name], company.children.length, index + 1),
      }))
    })

    return { innerData, outerData }
  }
  const cellClassName = 'max-w-full truncate p-2'
  const CustomTooltip: React.FC<CustomTooltipProps> = ({ payload, active }) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload
      return (
        <div
          style={{
            backgroundColor: 'white',
            border: '1px solid #ccc',
            padding: '10px',
            borderRadius: '4px',
            fontFamily: 'Arial, sans-serif',
            boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
          }}>
          <p
            style={{
              fontSize: '16px',
              fontWeight: 'bold',
              marginBottom: '5px',
            }}>
            {data.name}
          </p>

          <p
            style={{
              fontSize: '14px',
            }}>
            <span style={{ color: '#777' }}>{data.share_class ? 'Value: ' : 'Total Value at latest mark: '}</span>
            {numberToReadableDollarString(data.value)}
          </p>
          <p
            style={{
              fontSize: '14px',
            }}>
            <span style={{ color: '#777' }}>{data.share_class ? 'Cost: ' : 'Total Cost: '}</span>
            {numberToReadableDollarString(data.cost)}
          </p>
        </div>
      )
    }
    return null
  }

  const columnHelper = createColumnHelper<RouterCompanyRow>()

  useEffect(() => {
    if (data) {
      const chartData = processChartData(data.portfolio_summary)
      setChartData(chartData)
    }
  }, [data])

  const calculateTotals = useCallback(() => {
    if (data) {
      const totalCost = data.portfolio_summary.reduce((sum: number, row: RouterCompanyRow) => sum + row.invested_cost, 0)
      const totalValue = data.portfolio_summary.reduce((sum: number, row: RouterCompanyRow) => sum + row.total_value, 0)

      const costDifference = Math.abs(totalCost - data.table_of_contents.total_invested)
      const valueDifference = Math.abs(totalValue - data.table_of_contents.total_invested_value)

      if (costDifference > 0.01 || valueDifference > 0.01) {
        setTotalMismatch({ cost: costDifference, value: valueDifference })
      } else {
        setTotalMismatch(null)
      }
    }
  }, [data])

  useEffect(() => {
    if (data) {
      calculateTotals()
    }
  }, [data, calculateTotals])

  const columns: ColumnDef<RouterCompanyRow, any>[] = useMemo(
    () => [
      columnHelper.accessor('company_name', {
        id: 'company_name',
        header: 'Name',
        enableGlobalFilter: true,
        minSize: 200,
        meta: {
          className: 'z-30 sticky left-0 bg-white',
          headerClassName: 'z-40 sticky left-0 bg-white',
          isImmovable: true,
          isUnhideable: true,
        },
        filterFn: 'stringFn' as FilterFnOption<RouterCompanyRow>,
        size: 300,
        sortingFn: 'stringFn' as SortingFnOption<RouterCompanyRow>,
        cell: (cell) => (
          <div
            className={`flex max-w-full items-center gap-3 truncate p-2`}
            style={{
              paddingLeft: cell.row.depth * 18 + 8,
            }}>
            <div className="w-20px item-center flex h-full shrink-0 justify-center">
              {cell.row.getCanExpand() ? (
                <button
                  {...{
                    onClick: cell.row.getToggleExpandedHandler(),
                    style: { cursor: 'pointer' },
                  }}>
                  {cell.row.getIsExpanded() ? (
                    <ChevronDown className="h-[20px] w-[20px]" />
                  ) : (
                    <ChevronRight className="h-[20px] w-[20px]" />
                  )}
                </button>
              ) : (
                <div className="w-[20px]"></div>
              )}
            </div>
            <div>{cell.getValue() as string}</div>
          </div>
        ),
      }),
      columnHelper.accessor('status', {
        header: 'Status',
        id: 'status',
        size: 200,
        cell: (cell) => <div className="h-full max-w-full truncate pl-1">{cell.getValue()}</div>,
      }),
      columnHelper.accessor('investment_date', {
        header: 'Investment Date',
        id: 'investment_date',
        sortingFn: 'numberFn' as SortingFnOption<RouterCompanyRow>,
        filterFn: 'numberFn' as FilterFnOption<RouterCompanyRow>,
        size: 150,
        cell: (cell) => (
          <div className="flex flex-row pl-1">
            {cell.getValue() ? new Intl.DateTimeFormat('en-US', { dateStyle: 'long' }).format(new Date(cell.getValue())) : undefined}
          </div>
        ),
      }),
      columnHelper.accessor('invested_cost', {
        header: 'Cost',
        id: 'invested_cost',
        sortingFn: 'numberFn' as SortingFnOption<RouterCompanyRow>,
        filterFn: 'numberFn' as FilterFnOption<RouterCompanyRow>,
        size: 150,
        cell: (cell) => (
          <div className="h-full max-w-full truncate pl-1">
            {numberToReadableDollarString(cell.getValue())}
          </div>
        ),
      }),
      columnHelper.accessor('realized_value', {
        header: 'Realized',
        id: 'realized_value',
        sortingFn: 'numberFn' as SortingFnOption<RouterCompanyRow>,
        filterFn: 'numberFn' as FilterFnOption<RouterCompanyRow>,
        size: 150,
        cell: (cell) => <div className="flex flex-row pl-1">{numberToReadableDollarString(cell.getValue())}</div>,
      }),
      columnHelper.accessor('unrealized_value', {
        header: 'Unrealized',
        id: 'unrealized_value',
        sortingFn: 'numberFn' as SortingFnOption<RouterCompanyRow>,
        filterFn: 'numberFn' as FilterFnOption<RouterCompanyRow>,
        size: 150,
        cell: (cell) => <div className="flex flex-row pl-1">{numberToReadableDollarString(cell.getValue())}</div>,
      }),
      columnHelper.accessor('equity_ownership_percent', {
        header: '% Ownership',
        id: 'equity_ownership_percent',
        sortingFn: 'numberFn' as SortingFnOption<RouterCompanyRow>,
        size: 150,
        cell: (cell) => <div className="flex flex-row pl-1">{cell.getValue() ? `${cell.getValue()}%` : undefined}</div>,
      }),
      columnHelper.accessor('industry', {
        header: 'Industry',
        id: 'industry',
        size: 200,
        cell: (cell) => <div className="flex flex-row pl-1">{cell.getValue()}</div>,
      }),
      columnHelper.accessor('geography', {
        header: 'Geography',
        id: 'geography',
        size: 200,
        cell: (cell) => <div className="flex flex-row pl-1">{cell.getValue()}</div>,
      }),
    ],
    [columnHelper],
  )

  const [selectedTab, setSelectedTab] = useState<string>('')

  useEffect(() => {
    if (data) {
      if (data.table_of_contents.portfolio_table_pages.length > 0) {
        setSelectedTab('portfolio_summary')
      } else if (data.asset_metrics.length > 0) {
        setSelectedTab('asset_metrics')
      } else if (data.fund_metrics.length > 0) {
        setSelectedTab('fund_metrics')
      }
    }
  }, [data])

  return (
    <div className="relative">
      <div className="ml-6 mr-6 mt-6 flex flex-col items-center">
        {data && chartData.innerData.length > 0 ? (
          <>
            <TwoLayerPieChart chartData={chartData} customTooltip={CustomTooltip} />
          </>
        ) : null}
        {data && data.table_of_contents.total_invested_value > 0 ? (
          <div className="absolute mr-[2%] flex flex-col self-end">
            <div className="self-end pt-2 text-2xl font-semibold">
              {data && numberToReadableDollarString(data.table_of_contents.total_invested_value)}
            </div>
            <div className="self-end text-xs font-semibold">{data && 'Total portfolio value'}</div>
            <div className="self-end pt-2 text-2xl font-semibold">
              {data && numberToReadableDollarString(data.table_of_contents.total_invested)}
            </div>
            <div className="self-end text-xs font-semibold">{data && 'Total invested'}</div>
            <div className="self-end text-xs font-semibold">
              {data &&
                new Intl.DateTimeFormat('en-US', { dateStyle: 'long' }).format(
                  new Date(data.table_of_contents.date_of_portfolio_breakdown),
                )}
            </div>
            <div className="mr-[10%] self-end pt-2"></div>
          </div>
        ) : null}
        {/* {totalMismatch && (
          <div className={cn(
            "mt-4 p-4 rounded-md",
            "bg-yellow-100 border border-yellow-400 text-yellow-800"
          )}>
            <p className="font-semibold">Warning: Amount Mismatch</p>
            {totalMismatch.cost > 0 && <p>Total Investment Cost is off by: {numberToReadableDollarString(totalMismatch.cost)}</p>}
            {totalMismatch.value > 0 && <p>Total Investment Value is off by: {numberToReadableDollarString(totalMismatch.value)}</p>}
            <p>We are looking into it.</p>
          </div>
        )} */}
        <div className="w-full max-w-full">
          <Tabs
            value={selectedTab}
            onValueChange={(newVal) => setSelectedTab(newVal)}
          >
            <Tabs.List>
              {data && data.table_of_contents.portfolio_table_pages.length > 0 && (
                <Tabs.Tab
                  basic
                  label="Portfolio Summary"
                  value="portfolio_summary"
                  isActive={selectedTab === 'portfolio_summary'}
                />
              )}
              {data && data.asset_metrics.length > 0 && (
                <Tabs.Tab
                  basic
                  label="Asset Metrics"
                  value="asset_metrics"
                  isActive={selectedTab === 'asset_metrics'}
                />
              )}
              {data && data.fund_metrics.length > 0 && (
                <Tabs.Tab
                  basic
                  label="Fund Metrics"
                  value="fund_metrics"
                  isActive={selectedTab === 'fund_metrics'}
                />
              )}
            </Tabs.List>
          </Tabs>

          {selectedTab === 'portfolio_summary' && (
            <EditableTanstackTable
              borders="top"
              defaultSorting={{ id: 'company', desc: true }}
              stickyHeader
              stickyColumn
              fullPage
              data={data?.portfolio_summary ?? []}
              sortingFns={{}}
              filterFns={{}}
              columns={columns}
              expandedByDefault={false}
              isLoading={isLoading}
            />
          )}
          {selectedTab === 'asset_metrics' && (
            <AssetMetricsTable data={data?.asset_metrics ?? []} />
          )}
          {selectedTab === 'fund_metrics' && (
            <FundMetricsTable data={data?.fund_metrics ?? []} />
          )}
        </div>
      </div>
    </div>
  )
}
