export function numberToPercentChangeString(
  totalPercentChange: number,
  numDecimals: number = 2
): string {
  const sign = totalPercentChange > 0 ? "+" : "";
  const value = (totalPercentChange * 100).toLocaleString("en-US", {
    minimumFractionDigits: numDecimals,
    maximumFractionDigits: numDecimals,
  });
  return `${sign}${value}%`;
}
