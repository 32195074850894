import Cal, { getCalApi } from '@calcom/embed-react'
import { CheckIcon } from '@radix-ui/react-icons'
import { useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { isNone } from 'safety'
import { trpc } from 'utils/trpc'
import { useQueryParam } from 'utils/useQueryParam'
import { CaptureInterestContextProvider, useCaptureInterestContext } from './CaptureInterestContextProvider'
import { ReactComponent as ReturnIcon } from './images/icons/return-icon.svg'

interface MultipleChoiceQuestionProps {
  question: string
  choices: string[]
  onSelect: (choice: string) => void
}

function AskMultipleChoiceQuestion(props: MultipleChoiceQuestionProps) {
  return (
    <div className="flex flex-col items-center">
      <div className="mb-10 max-w-md text-center text-3xl font-medium">{props.question}</div>
      {props.choices.map((choice, index) => {
        return (
          <div key={index} className="mb-3 w-full text-base">
            <button
              className="w-full border pb-4 pl-5 pt-4 text-left hover:bg-[rgba(0,0,0,0.03)]"
              onClick={() => props.onSelect(choice)}>
              {choice}
            </button>
          </div>
        )
      })}
    </div>
  )
}

interface TextFieldQuestionProps {
  question: string
  placeholder: string
  onSubmit: (answer: string) => void
  subheader?: string
  buttonLabel?: string
  requireTextContains?: string
}

function AskTextFieldQuestion(props: TextFieldQuestionProps) {
  const [answer, setAnswer] = useState('')
  const buttonIsPressable =
    answer.length > 0 && (!props.requireTextContains || answer.includes(props.requireTextContains))
  const buttonTextOpacity = buttonIsPressable ? 'opacity-100' : 'opacity-50'

  const onSubmit = useCallback(() => {
    if (!buttonIsPressable) {
      return
    }
    props.onSubmit(answer)
  }, [buttonIsPressable, answer, props])

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        onSubmit()
      }
    }
    document.addEventListener('keydown', handleKeyPress)
    return () => {
      document.removeEventListener('keydown', handleKeyPress)
    }
  }, [onSubmit])

  return (
    <div className="flex w-full max-w-md flex-col items-center">
      <div className="text-center text-3xl font-medium">{props.question}</div>
      {props.subheader ? <div className="mt-4 text-base opacity-80">{props.subheader}</div> : null}
      <div className="h-10" />
      <div className="relative mt-8 w-full">
        <input
          placeholder={props.placeholder}
          value={answer}
          onChange={(e) => setAnswer(e.target.value)}
          className="h-11 w-full rounded-lg border pl-4 pr-9 placeholder-[rgba(0,0,0,0.5)] outline-none"
          autoFocus
        />
        <ReturnIcon className="absolute right-3 top-3 h-5 w-5 opacity-40" />
      </div>
      <button
        className={`mt-3 h-11 w-full rounded-lg bg-[rgba(0,0,0,0.07)] font-semibold text-black ${
          buttonIsPressable ? '' : 'pointer-events-none'
        }`}
        onClick={onSubmit}>
        <div className={`${buttonTextOpacity}`}>{props.buttonLabel ?? 'Continue'}</div>
      </button>
    </div>
  )
}

function AskFirstLastName({ onSubmit }: { onSubmit: (name: string) => void }) {
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const buttonIsPressable = firstName.length > 0 && lastName.length > 0
  const buttonTextOpacity = buttonIsPressable ? 'opacity-100' : 'opacity-50'

  const onClickSubmit = useCallback(() => {
    if (!buttonIsPressable) {
      return
    }
    onSubmit(firstName + ' ' + lastName)
  }, [buttonIsPressable, firstName, lastName, onSubmit])

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        onClickSubmit()
      }
    }
    document.addEventListener('keydown', handleKeyPress)
    return () => {
      document.removeEventListener('keydown', handleKeyPress)
    }
  }, [onClickSubmit])

  return (
    <div className="flex w-full max-w-md flex-col items-center">
      <div className="mb-10 text-center text-3xl font-medium">What's your name?</div>
      <div className="mt-8 flex w-full flex-col space-y-2 lg:flex-row lg:space-x-2 lg:space-y-0">
        <input
          placeholder="First"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          className="h-11 flex-grow rounded-lg border pl-4 placeholder-[rgba(0,0,0,0.5)] outline-none"
          autoFocus
        />
        <input
          placeholder="Last"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          className="h-11 flex-grow rounded-lg border pl-4 placeholder-[rgba(0,0,0,0.5)] outline-none"
        />
      </div>
      <button
        className={`mt-3 h-11 w-full rounded-lg bg-[rgba(0,0,0,0.07)] font-semibold text-black ${
          buttonIsPressable ? '' : 'pointer-events-none'
        }`}
        onClick={onClickSubmit}>
        <div className={`${buttonTextOpacity}`}>Submit</div>
      </button>
    </div>
  )
}

export default function CaptureInterestWithData() {
  return (
    <CaptureInterestContextProvider>
      <CaptureInterest />
    </CaptureInterestContextProvider>
  )
}

function CaptureInterest() {
  const context = useCaptureInterestContext()
  const [shouldGetEmail, setShouldGetEmail] = useState<boolean | undefined>(undefined)
  const markCalendarSignUpMutation = trpc.interestGathering.markCalendarEventSignUp.useMutation()

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const emailParam = urlParams.get('email')
    if (isNone(shouldGetEmail)) {
      // pre-render of q's
      if (emailParam) {
        setShouldGetEmail(false)
        setCurrentStepIndex(1)
      } else {
        setShouldGetEmail(true)
      }
      context.setEmail(emailParam)
    }
  }, [context, shouldGetEmail])

  const steps = [
    <AskTextFieldQuestion
      question="What's your email?"
      placeholder="name@mail.com"
      requireTextContains="@"
      onSubmit={(email) => {
        context.setEmail(email)
        incrementStep()
      }}
    />,
    <AskMultipleChoiceQuestion
      question="What's the approximate value of your equity?"
      choices={['$0-$50k', '$50-$500k', '$500k-$5m', '$5m+']}
      onSelect={(choice) => {
        context.setEquityValue(choice)
        incrementStep()
      }}
    />,
    <AskMultipleChoiceQuestion
      question="What do you hope to do with your equity?"
      choices={['Use my equity to get cash', 'Use my equity to diversify into other top startups', 'Both']}
      onSelect={(choice) => {
        context.setGoal(choice)
        incrementStep()
      }}
    />,
    <AskTextFieldQuestion
      question="What company do you own equity in?"
      placeholder="Company name"
      onSubmit={(answer) => {
        context.setCompany(answer)
        incrementStep()
      }}
    />,
    <AskFirstLastName
      onSubmit={(answer) => {
        context.setName(answer)
        incrementStep()
      }}
    />,
  ]
  const numSteps = shouldGetEmail ? steps.length : steps.length - 1
  let [skip] = useQueryParam('s', 'false' as string)

  useEffect(() => {
    if (skip === 'true') {
      setCurrentStepIndex(5)
    }
  }, [skip])
  const [currentStepIndex, setCurrentStepIndex] = useState(skip === 'true' ? 0 : numSteps - 1)
  function incrementStep() {
    setCurrentStepIndex(currentStepIndex + 1)
  }

  useEffect(() => {
    const fetchCalApi = async () => {
      const cal = await getCalApi()
      cal('on', {
        action: 'bookingSuccessful',
        callback: (e) => {
          context.data.email &&
            markCalendarSignUpMutation.mutate({
              email: context.data.email,
            })
        },
      })
      cal('ui', {
        theme: 'light',
        styles: { branding: { brandColor: '#000000' } },
        hideEventTypeDetails: false,
        layout: 'month_view',
      })
    }
    fetchCalApi()
  }, [])

  function bookDemo() {
    const valueProps = [
      'Learn how much your equity is worth',
      'Receive a guided tour of our financing structure',
      "Understand Plural's tax savings",
    ]
    return (
      <div className="w-full max-w-4xl pl-4 pr-4">
        <div className="mt-2 text-3xl font-semibold">Thanks!</div>
        <div>
          <div className="mt-4 text-base">Book time with us to learn more—here's what to expect:</div>
          <div>
            <div>
              {valueProps.map((valueProp, index) => {
                return (
                  <div key={index} className="flex items-center pt-2">
                    <div className="mr-2 rounded-full bg-func-bg-black" style={{ padding: '1px' }}>
                      <CheckIcon className="h-4 w-4  text-white" />
                    </div>
                    <div className="text-base">{valueProp}</div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
        <div className="mt-8">
          <Cal
            calLink="bryan-casey/qualified"
            style={{ width: '100%', height: '100%', overflow: 'scroll' }}
            config={{
              layout: 'month_view',
              name: context.data.name ?? '',
              email: context.data.email ?? '',
            }}
          />
          <div className="mb-12 mt-12">
            <Link to="/">
              <div className="text-base underline">Return to home</div>
            </Link>
          </div>
        </div>
      </div>
    )
  }

  const shouldShowSteps = currentStepIndex < steps.length
  const currentStepForPercentComplete = shouldGetEmail ? currentStepIndex : currentStepIndex - 1
  const percentComplete = `${(((currentStepForPercentComplete + 1) / numSteps) * 100).toFixed(0)}%`
  if (skip === undefined) {
    return <></>
  }
  return (
    <div className="flex w-full flex-col items-center ">
      {shouldShowSteps && (
        <div className="flex w-full flex-col items-center ">
          <div className="w-full">
            <div className={`h-1 bg-[rgba(0,0,0,0.1)]`} style={{ width: percentComplete }} />
          </div>
          <div className="mb-2 mt-16 text-xs opacity-50">Let's understand your finances</div>
          <div className="flex w-full max-w-xl flex-col items-center pl-6 pr-6">
            {isNone(shouldGetEmail) ? null : steps[currentStepIndex]}
          </div>
        </div>
      )}
      {!shouldShowSteps && bookDemo()}
    </div>
  )
}
