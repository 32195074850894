import { Select, TextField } from '@radix-ui/themes'
import React, { ReactNode } from 'react'
import CheckBox from './editable-table/CheckBox'

export function InputLabel({ label }: { label: string }) {
  const color = '#5A5A5A'
  return (
    <div className="w-full pb-2 pt-4 text-sm" style={{ color: color }}>
      {label}
    </div>
  )
}

export default function AppTextField(
  props: Omit<React.ComponentProps<typeof TextField.Input>, 'variant'> & {
    label?: string
    endAdornment?: ReactNode
    startAdornment?: ReactNode
    fullWidth?: boolean
    variant?: 'outlined' | 'underlined' | 'ghost'
  },
) {
  let { style, variant, ...rest } = props
  return (
    <div className={`${props.fullWidth ? 'w-full' : 'w-60'} `} style={style}>
      {props.label && <InputLabel label={props.label} />}
      <div className="relative w-full">
        <TextField.Input
          onClick={(e) => {
            e.stopPropagation()
          }}
          className={`text-base ${!props.label && props.startAdornment ? 'ml-6' : ''}`}
          {...rest}
          style={
            props.variant === 'ghost'
              ? { outline: 'none !important', border: 'none !important', boxShadow: 'none !important' }
              : {}
          }
        />
        {props.endAdornment ? (
          <div className="absolute right-0 top-[6px] pr-3 text-sm">{props.endAdornment}</div>
        ) : null}
        {props.startAdornment ? (
          <div className="absolute left-2 top-[4px] pr-3 text-sm">{props.startAdornment}</div>
        ) : null}
      </div>
    </div>
  )
}

export function AppCheckboxFormfield(
  props: React.ComponentProps<typeof CheckBox> & {
    label: string
  },
) {
  return (
    <div className="flex w-full flex-row items-center justify-between">
      <InputLabel label={props.label} />
      <div className="relative pr-4">
        <CheckBox {...props} />
      </div>
    </div>
  )
}

export function AppSelectFormfield(props: {
  label?: string
  options: {
    value: string
    label: string
  }[]
  value: string
  onValueChange: (value: string) => void
  className?: string
}) {
  return (
    <div
      className={`flex w-full flex-col gap-2 ${props.className ? props.className : ''}`}
      onClick={(e) => {
        e.stopPropagation()
      }}>
      {props.label && <InputLabel label={props.label} />}
      <Select.Root value={props.value} onValueChange={props.onValueChange}>
        <Select.Trigger
          style={{
            width: '100%',
            cursor: 'pointer',
          }}
        />
        <Select.Content>
          {props.options.map((option) => (
            <Select.Item
              value={option.value}
              key={option.value}
              className="pr-2"
              onClick={(e) => {
                e.stopPropagation()
                e.preventDefault()
              }}>
              {option.label}
            </Select.Item>
          ))}
        </Select.Content>
      </Select.Root>
    </div>
  )
}
