import { ResolvedColumnFilter, Row, RowData, RowModel, Table } from '@tanstack/react-table'
import { ColumnInputTypeStr, CreateColumnProps } from 'components/editable-table/ColumnProps'
import { EditableTextCellFormat } from 'components/editable-table/EditableTextCellFormat'
import { DropdownSearchOption } from 'several/components/DropdownSearch'

export type FilterOperatorType = 'arrIncludesAll' | 'includesString'

export type TableFilterModel = {
  field: string
  value: string[]
  operatorType: FilterOperatorType
  id: string
}

export type ColumnFilterModel = {
  field: string
  value: string[]
  id: string
}

export type IndicationDataModel = {
  fundsToCompany?: {
    [fundId: string]: string[]
  }
  activeIndicationCompanyIds: string[]
  inactiveIndicationCompanyIds: string[]
}

declare module '@tanstack/react-table' {
  interface TableMeta<TData extends RowData> {
    columnFilters: TableFilterModel[][]
    extendedFiltersMap: { [key: string]: TableFilterModel[][] }
    indicationData?: IndicationDataModel
  }
}

declare module '@tanstack/react-table' {
  interface ColumnMeta<TData extends RowData, TValue> {
    // come back and fix this typing
    createProps?: CreateColumnProps<TValue, Partial<TData>>
    updateRemote?: (row: RowData, newValue: TValue) => void | Promise<void>
    inputType?: ColumnInputTypeStr
    placeholder?: string
    textCellFormat?: EditableTextCellFormat<TValue>
    options?: string[] | ((row: RowData) => string[])
    autoCompleteOptions?: DropdownSearchOption[]
    noPadding?: boolean
    className?: string
    headerClassName?: string
    isImmovable?: boolean
    isUnhideable?: boolean
    singleSelectOptions?: DropdownSearchOption[]
    singleSelectPlaceholder?: string
    filterEndAdornment?: React.ReactNode
    selectAllOptions?: boolean
  }
}

export const tanStackFilterRowsFn = (
  table: Table<any>,
  rowModel: RowModel<any>,
  columnFilters: any,
  globalFilter: any,
) => {
  if (!rowModel.rows.length || (!columnFilters?.length && !globalFilter)) {
    for (let i = 0; i < rowModel.flatRows.length; i++) {
      rowModel.flatRows[i]!.columnFilters = {}
      rowModel.flatRows[i]!.columnFiltersMeta = {}
    }
    return rowModel
  }

  const resolvedColumnFilters: ResolvedColumnFilter<any>[] = []
  const resolvedGlobalFilters: ResolvedColumnFilter<any>[] = []

  ;(columnFilters ?? []).forEach((d: any) => {
    const column = table.getColumn(d.id)
    if (!column) {
      return
    }

    const filterFn = column.getFilterFn()

    if (!filterFn) {
      if (process.env.NODE_ENV !== 'production') {
        console.warn(`Could not find a valid 'column.filterFn' for column with the ID: ${column.id}.`)
      }
      return
    }

    resolvedColumnFilters.push({
      id: d.id,
      filterFn,
      resolvedValue: filterFn.resolveFilterValue?.(d.value) ?? d.value,
    })
  })

  const filterableIds = columnFilters.map((d: any) => d.id)

  const globalFilterFn = table.getGlobalFilterFn()

  const globallyFilterableColumns = table.getAllLeafColumns().filter((column) => column.getCanGlobalFilter())

  if (globalFilter && globalFilterFn && globallyFilterableColumns.length) {
    filterableIds.push('__global__')

    globallyFilterableColumns.forEach((column) => {
      resolvedGlobalFilters.push({
        id: column.id,
        filterFn: globalFilterFn,
        resolvedValue: globalFilterFn.resolveFilterValue?.(globalFilter) ?? globalFilter,
      })
    })
  }

  let currentColumnFilter
  let currentGlobalFilter

  // Flag the prefiltered row model with each filter state
  for (let j = 0; j < rowModel.flatRows.length; j++) {
    const row = rowModel.flatRows[j]!

    row.columnFilters = {}

    if (resolvedColumnFilters.length) {
      for (let i = 0; i < resolvedColumnFilters.length; i++) {
        currentColumnFilter = resolvedColumnFilters[i]!
        const id = currentColumnFilter.id
        // Tag the row with the column filter state
        row.columnFilters[id] = currentColumnFilter.filterFn(
          row,
          id,
          currentColumnFilter.resolvedValue,
          (filterMeta) => {
            row.columnFiltersMeta[id] = filterMeta
          },
        )
      }
    }

    if (resolvedGlobalFilters.length) {
      for (let i = 0; i < resolvedGlobalFilters.length; i++) {
        currentGlobalFilter = resolvedGlobalFilters[i]!
        const id = currentGlobalFilter.id
        // Tag the row with the first truthy global filter state
        if (
          currentGlobalFilter.filterFn(row, id, currentGlobalFilter.resolvedValue, (filterMeta) => {
            row.columnFiltersMeta[id] = filterMeta
          })
        ) {
          row.columnFilters.__global__ = true
          break
        }
      }

      if (row.columnFilters.__global__ !== true) {
        row.columnFilters.__global__ = false
      }
    }
  }

  const filterRowsImpl = (row: Row<any>) => {
    // Horizontally filter rows through each column
    for (let i = 0; i < filterableIds.length; i++) {
      if (row.columnFilters[filterableIds[i]!] === false) {
        return false
      }
    }
    return true
  }

  const newFilteredFlatRows: Row<any>[] = []
  const newFilteredRowsById: Record<string, Row<any>> = {}
  const rows: Row<any>[] = []

  rowModel.rows.forEach((row) => {
    if (filterRowsImpl(row)) {
      rows.push(row)
      newFilteredFlatRows.push(row)
      newFilteredRowsById[row.id] = row
    }
  })

  // Filter final rows using all of the active filters
  return {
    rows: rows,
    flatRows: newFilteredFlatRows,
    rowsById: newFilteredRowsById,
  }
}
export const SpecialFilterKey = '#tableMetaListExtension'
export const SpecialIndicationFilterKey = '#tableMetaIndicationExtension'

export function getTableFilteredRows(table: Table<any>) {
  return () => {
    const rowModel = table.getPreFilteredRowModel()
    let masterSetRows: Set<Row<any>> = new Set()
    let masterSetFlatRows: Set<Row<any>> = new Set()
    let masterRowsById: { [id: number]: Row<any> } = {}

    if (!table.options.meta?.columnFilters || table.options.meta.columnFilters.length === 0) {
      return tanStackFilterRowsFn(table, rowModel, [], table.getState().globalFilter)
    }

    let allColumns = table.getAllColumns()

    function addRowModel(model: { rows: Row<any>[]; flatRows: Row<any>[]; rowsById: { [id: number]: Row<any> } }) {
      model.rows.forEach((row) => masterSetRows.add(row))
      model.flatRows.forEach((row) => masterSetFlatRows.add(row))
      masterRowsById = { ...masterRowsById, ...model.rowsById }
    }

    function handleGroupFilters(filterGroups: TableFilterModel[][]): {
      rows: Row<any>[]
      flatRows: Row<any>[]
      rowsById: { [id: number]: Row<any> }
    } {
      let miniMasterSetRows: Set<Row<any>> = new Set()
      let miniMasterSetFlatRows: Set<Row<any>> = new Set()
      let miniMasterRowsById: { [id: number]: Row<any> } = {}
      filterGroups.forEach((groupFilters) => {
        let rowModelA: RowModel<any> | undefined = undefined
        let rowModelB: RowModel<any> | undefined = undefined
        let rowModelC: RowModel<any> | undefined = undefined

        let specialListFilter = groupFilters.find((filter: any) => filter.field === SpecialFilterKey)

        let specialFundFilter = groupFilters.find((filter: any) => filter.field === SpecialIndicationFilterKey)
        if (specialListFilter && specialListFilter.value.length > 1) {
          for (const id of (specialListFilter.value ?? ['']).slice(1)) {
            let columnFilters = table.options.meta!.extendedFiltersMap[id] ?? []
            if (columnFilters.length > 0) {
              rowModelA =
                specialListFilter.value[0] === 'and'
                  ? IntersectionOfRowModels(handleGroupFilters(columnFilters), rowModelA)
                  : UnionOfRowModels(handleGroupFilters(columnFilters), rowModelA)
            }
          }
        }
        if (specialFundFilter) {
          let rows: RowModel<any>['rows'] = []

          if (specialFundFilter.value.includes('active')) {
            rows = table.getPreFilteredRowModel().rows.filter((row) => {
              return (
                !!table.options.meta?.indicationData?.fundsToCompany?.[row.original['id']]?.some((companyId) =>
                  table.options.meta?.indicationData?.activeIndicationCompanyIds?.includes(companyId),
                ) ||
                // sometimes the company id is already in the row
                !!table.options.meta?.indicationData?.activeIndicationCompanyIds?.includes(row.original['companyId'])
              )
            })
          }
          if (specialFundFilter.value.includes('inactive')) {
            rows = rows.concat(
              table.getPreFilteredRowModel().rows.filter((row) => {
                return !!table.options.meta?.indicationData?.fundsToCompany?.[row.original['id']]?.some(
                  (companyId) =>
                    table.options.meta?.indicationData?.inactiveIndicationCompanyIds?.includes(companyId) ||
                    // sometimes the company id is already in the row
                    !!table.options.meta?.indicationData?.inactiveIndicationCompanyIds?.includes(
                      row.original['companyId'],
                    ),
                )
              }),
            )
          }

          rowModelC = {
            rows: rows,
            flatRows: rows,
            rowsById: rows.reduce(
              (acc, row) => {
                acc[row.id] = row
                return acc
              },
              {} as { [id: string]: Row<any> },
            ),
          }
        }

        if (groupFilters.filter((f: any) => table.getAllFlatColumns().find((col) => col.id === f.field)).length !== 0) {
          rowModelB = tanStackFilterRowsFn(
            table,
            rowModel,
            groupFilters
              .filter((f: any) => table.getAllFlatColumns().find((col) => col.id === f.field))
              .map((filter: TableFilterModel) => {
                let column = allColumns.find((column) => column.id === filter.field)

                return {
                  id: filter.field,
                  value: column?.columnDef.filterFn?.toString() === 'includesString' ? filter.value[0] : filter.value,
                }
              }),
            table.getState().globalFilter,
          )
        }

        let filteredRowModel = IntersectionOfRowModels(IntersectionOfRowModels(rowModelA, rowModelB), rowModelC)
        if (!filteredRowModel) {
          return {
            rows: [],
            flatRows: [],
            rowsById: {},
          }
        }
        filteredRowModel.rows.forEach((row) => miniMasterSetRows.add(row))
        filteredRowModel.flatRows.forEach((row) => miniMasterSetFlatRows.add(row))
        miniMasterRowsById = { ...miniMasterRowsById, ...filteredRowModel.rowsById }
      })
      return {
        rows: Array.from(miniMasterSetRows),
        flatRows: Array.from(miniMasterSetFlatRows),
        rowsById: miniMasterRowsById,
      }
    }

    addRowModel(handleGroupFilters(table.options.meta.columnFilters))
    let newRowModel = {
      rows: Array.from(masterSetRows),
      flatRows: Array.from(masterSetFlatRows),
      rowsById: masterRowsById,
    }

    return {
      ...newRowModel,
      rows: newRowModel.rows.sort((a, b) => {
        let ax = table.getPreFilteredRowModel().rows.findIndex((r) => r.id === a.id)
        let bx = table.getPreFilteredRowModel().rows.findIndex((r) => r.id === b.id)
        return ax - bx
      }),
    }
  }
}

function UnionOfRowModels(rowModelA: RowModel<any> | undefined, rowModelB: RowModel<any> | undefined): RowModel<any> {
  let masterSetRows: Set<Row<any>> = new Set()
  let masterSetFlatRows: Set<Row<any>> = new Set()
  let masterRowsById: { [id: number]: Row<any> } = {}
  if (!rowModelA || !rowModelB) {
    return rowModelA ?? rowModelB ?? { rows: [], flatRows: [], rowsById: {} }
  }
  rowModelA.rows.forEach((row) => masterSetRows.add(row))
  rowModelA.flatRows.forEach((row) => masterSetFlatRows.add(row))
  masterRowsById = { ...masterRowsById, ...rowModelA.rowsById }

  rowModelB.rows.forEach((row) => masterSetRows.add(row))
  rowModelB.flatRows.forEach((row) => masterSetFlatRows.add(row))
  masterRowsById = { ...masterRowsById, ...rowModelB.rowsById }

  return {
    rows: Array.from(masterSetRows),
    flatRows: Array.from(masterSetFlatRows),
    rowsById: masterRowsById,
  }
}

function IntersectionOfRowModels(
  rowModelA: RowModel<any> | undefined,
  rowModelB: RowModel<any> | undefined,
): RowModel<any> | undefined {
  let masterSetRows: Set<Row<any>> = new Set()
  let masterSetFlatRows: Set<Row<any>> = new Set()
  let masterRowsById: { [id: string]: Row<any> } = {}

  if (!rowModelA || !rowModelB) {
    return rowModelA ?? rowModelB ?? undefined
  }

  rowModelA.rows.forEach((row) => {
    if (rowModelB.rowsById[row.id]) {
      masterSetRows.add(row)
      masterRowsById[row.id] = row
    }
  })

  rowModelA.flatRows.forEach((row) => {
    if (rowModelB.rowsById[row.id]) {
      masterSetFlatRows.add(row)
    }
  })
  return {
    rows: Array.from(masterSetRows),
    flatRows: Array.from(masterSetFlatRows),
    rowsById: masterRowsById,
  }
}
