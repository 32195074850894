import { CrossCircledIcon } from '@radix-ui/react-icons'
import { TextField } from '@radix-ui/themes'
import { ChipContainer } from 'pages/company/TextInputSelectMulti'
import { useRef, useState } from 'react'

export default function TextFieldMulti(props: {
  maxWidth: number
  fullWidth?: boolean
  onSelectOption: (option: string) => void
  placeholder: string
  onOptionRemove: (option: string) => void
  selectedOptions?: string[]
  disabled?: boolean
  onPaste?: (e: React.ClipboardEvent<HTMLInputElement>) => void
}) {
  const [isEditing, setIsEditing] = useState(false)
  const [inputText, setInputText] = useState('')
  const inputRef = useRef<HTMLInputElement>(null)

  function unfocus() {
    setIsEditing(false)
  }
  return (
    <div className={`relative w-full max-w-[${props.maxWidth}px] flex-grow-0 opacity-${props.disabled ? 50 : 100}`}>
      <ChipContainer
        onClick={() => {
          setIsEditing(true)
          inputRef.current?.focus()
        }}
        disabled={props.disabled}
        fullWidth={!!props.fullWidth}
        maxWidth={props.maxWidth}>
        {props.selectedOptions?.map((option, index) => {
          if (!option) return null
          return (
            <div
              className="mr-1 flex flex-row items-center gap-1 rounded-full bg-gray-100 p-2 text-sm"
              key={`${option}-${index}`}>
              {option}
              <CrossCircledIcon
                onClick={(e) => {
                  e.stopPropagation()
                  props.onOptionRemove(option)
                }}
                style={{
                  width: '15px',
                  height: '15px',
                  cursor: 'pointer',
                }}
              />
            </div>
          )
        })}
        {(props.selectedOptions?.length ?? 0) < 1 && !isEditing && (
          <div className="flex h-[40px] w-full items-center pl-[6px] text-sm text-gray-400">{props.placeholder}</div>
        )}
      </ChipContainer>
      <TextField.Root
        className="z-40"
        style={{
          width: props.fullWidth ? '100%' : '260px',
          height: isEditing ? '40px' : '0px',
          opacity: isEditing ? 1 : 0,
          position: 'absolute',
          bottom: '-45px',
        }}
        onPaste={(e) => {
          if (props.onPaste) {
            props.onPaste(e as React.ClipboardEvent<HTMLInputElement>)
            unfocus()
          }
        }}
        onBlur={() => {
          if (inputText) {
            props.onSelectOption(inputText)
          }
          unfocus()
        }}
        onFocus={() => {
          setInputText('')
        }}>
        <TextField.Input
          disabled={props.disabled}
          style={{
            width: props.fullWidth ? '100%' : '260px',
            height: isEditing ? '40px' : '0px',
            opacity: isEditing ? 1 : 0,
          }}
          ref={inputRef}
          placeholder={props.placeholder}
          value={inputText}
          onChange={(e) => {
            setInputText(e.target.value)
          }}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              if (inputText) {
                props.onSelectOption(inputText)
                setInputText('')
              }
              unfocus()
            } else if (event.key === 'Escape') {
              setInputText('')
              unfocus()
            }
          }}
          size={'2'}
        />
      </TextField.Root>
    </div>
  )
}
