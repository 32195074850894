import { Checkbox, Select } from '@radix-ui/themes'
import { TableColumnProps } from 'components/Table'
import Disclaimer from 'pages/portfolio/Disclaimer'
import PortfolioGraph, { SelfValueGraph } from 'pages/portfolio/PortfolioGraph'
import { LATEST_MARK_DATE } from 'plural-shared/constants'
import { createMarketOpenDate, dateToShorthandMonthYearString, numberToPercentChangeString } from 'plural-shared/utils'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ArrayElement } from 'safety'
import { RouterOutputs, trpc } from 'utils/trpc'
import { Table } from '../../components/Table'
import { usePluralAuth } from '../../providers/PluralAuthProvider'
import { AuthStatus } from '../../utils/authStatus'

type CompanyPositionRowBasic = ArrayElement<RouterOutputs['portfolio']['companyPositionTableRowsBasic']>
type CompanyPositionRowDetailed = ArrayElement<RouterOutputs['portfolio']['companyPositionTableRowsDetailed']>
type FundSwapPositionRowBasic = ArrayElement<RouterOutputs['portfolio']['fundSwapPositionTableRowsBasic']>

export default function Portfolio() {
  const { authStatus, loginWithRedirect } = usePluralAuth()
  let params = useParams()
  let fundId = params.fundId ? parseInt(params.fundId) : 1
  const [shouldShowFundAssetsAsIndividualInvestments, setShouldShowFundAssetsAsIndividualInvestments] = useState(true)
  const portfolioMetadataQuery = trpc.portfolio.snapshotMetadata.useQuery({ fundId: fundId })
  let snapshotDate = portfolioMetadataQuery.data?.snapshotDate
  const now = new Date()

  function onFundAssetCheckboxClick() {
    setShouldShowFundAssetsAsIndividualInvestments((current) => !current)
  }
  let fund = trpc.fund.fundDetails.useQuery(fundId).data

  const fundSwapColumns: TableColumnProps<FundSwapPositionRowBasic>[] = [
    {
      header: 'Fund',
      render: (row: FundSwapPositionRowBasic) => {
        return <div className="font-normal">{row.fundName}</div>
      },
    },
    {
      header: `Percent of ${fund?.name}`,
      render: (row: FundSwapPositionRowBasic) => `${row.percentOfFund}`,
    },
    {
      header: `${dateToShorthandMonthYearString(createMarketOpenDate(now.getMonth(), now.getDay(), now.getFullYear()))} Value`,
      render: (row: FundSwapPositionRowBasic) => `${row.latestPositionValue}`,
    },
    {
      header: 'Total Value Change',
      render: (row: FundSwapPositionRowBasic) => {
        return (
          <div className={`${row.totalPercentChange.startsWith('-') ? 'text-tickers-red' : 'text-tickers-green'}`}>
            {row.positionValueChange}
          </div>
        )
      },
    },
    {
      header: 'Total % Change',
      render: (row: FundSwapPositionRowBasic) => {
        return (
          <div className={`${row.totalPercentChange.startsWith('-') ? 'text-tickers-red' : 'text-tickers-green'}`}>
            {row.totalPercentChange}
          </div>
        )
      },
    },
  ]

  const columnsBasic: TableColumnProps<CompanyPositionRowBasic>[] = [
    {
      header: 'Company',
      render: (row: CompanyPositionRowBasic) => {
        const companyName = row.companyName
        return (
          <div className="align-center flex flex-row  gap-2 pr-9">
            {row.companyLogoUrl ? (
              <img
                src={row.companyLogoUrl}
                alt="logo"
                className="border-gray-80 h-8 w-8 rounded-full border border-solid"
              />
            ) : (
              <div className="border-gray-80 flex h-8 w-8 items-center justify-center rounded-full border border-solid">
                {companyName[0]}
              </div>
            )}
            <div className="flex flex-row items-center font-normal">{companyName}</div>
          </div>
        )
      },
    },
    {
      header: 'Percent of Fund',
      render: (row: CompanyPositionRowBasic) => `${row.percentOfFund}`,
    },
    {
      header: `${dateToShorthandMonthYearString(LATEST_MARK_DATE)} Value`,
      render: (row: CompanyPositionRowBasic) => `${row.latestPositionValue}`,
    },
    {
      header: 'Total Value Change',
      render: (row: CompanyPositionRowBasic) => {
        return (
          <div className={`${row.totalPercentChange.startsWith('-') ? 'text-tickers-red' : 'text-tickers-green'}`}>
            {row.positionValueChange}
          </div>
        )
      },
    },
    {
      header: 'Total % Change',
      render: (row: CompanyPositionRowBasic) => {
        return (
          <div className={`${row.totalPercentChange.startsWith('-') ? 'text-tickers-red' : 'text-tickers-green'}`}>
            {row.totalPercentChange}
          </div>
        )
      },
    },
  ]
  const columnsDetailed: TableColumnProps<CompanyPositionRowDetailed>[] = [
    ...columnsBasic,
    {
      header: 'Entry PPS',
      render: (row: CompanyPositionRowDetailed) => `${row.entryPricePerShare}`,
    },
    {
      header: 'Mark PPS',
      render: (row: CompanyPositionRowDetailed) => `${row.markPricePerShare}`,
    },
    {
      header: 'Latest Round PPS',
      render: (row: CompanyPositionRowDetailed) => `${row.companyMostRecentRoundSharePrice}`,
    },
    {
      header: 'Entry Valuation',
      render: (row: CompanyPositionRowDetailed) => `${row.companyEntryValuation}`,
    },
    {
      header: 'Mark Valuation',
      render: (row: CompanyPositionRowDetailed) => `${row.companyMarkValuation}`,
    },
    {
      header: 'Latest Round Valuation',
      render: (row: CompanyPositionRowDetailed) => `${row.companyMostRecentRoundValuation}`,
    },
    {
      header: 'Position Type',
      render: (row: CompanyPositionRowDetailed) => row.positionType[0] + row.positionType.substring(1).toLowerCase(),
    },
    {
      header: 'Mark Date',
      render: (row: CompanyPositionRowDetailed) => LATEST_MARK_DATE.toLocaleDateString(),
    },
  ]

  const rowQueryBasic = trpc.portfolio.companyPositionTableRowsBasic.useQuery(
    {
      fundId: fundId,
      showIndividualizedFundAssets: shouldShowFundAssetsAsIndividualInvestments,
    },
    {
      enabled: authStatus !== AuthStatus.Allowed,
    },
  )
  const rowQueryDetailed = trpc.portfolio.companyPositionTableRowsDetailed.useQuery(
    {
      fundId: fundId,
      showIndividualizedFundAssets: shouldShowFundAssetsAsIndividualInvestments,
    },
    {
      enabled: authStatus === AuthStatus.Allowed,
    },
  )
  const fundSwapQuery = trpc.portfolio.fundSwapPositionTableRowsBasic.useQuery({
    fundId: fundId,
  })

  async function onLoginClick() {
    loginWithRedirect({
      appState: {
        returnTo: `/portfolio/${fundId}`,
      },
    }).then(() => {
      console.log('login complete')
    })
  }

  return (
    <div className="mb-24 flex w-full flex-col items-center justify-start pl-4 pr-4">
      <div className={`mt-12 flex w-full max-w-page-max flex-col items-center justify-center `}>
        {portfolioMetadataQuery.data && (
          <SelfValueGraph
            fundId={fundId}
            snapshotDate={snapshotDate ?? ''}
            fundName={fund?.name ?? ''}
            grossIrr={portfolioMetadataQuery.data.grossIrr}
          />
        )}

        <div className="justify-apart flex w-full items-center">
          <div className="items-left flex w-full py-2 pt-4 text-xl font-normal">{fund?.name}</div>
          <div>
            <FundSwitch selectedFundId={fundId} />
          </div>
        </div>
        <div className="items-left flex w-full py-3">
          {portfolioMetadataQuery.data && (
            <div className="flex w-full space-x-6 pt-2">
              <div>
                <div className="text-sm opacity-80">Marked gross IRR</div>
                <div className="text-lg font-medium">
                  {numberToPercentChangeString(portfolioMetadataQuery.data.grossIrr)}
                </div>
              </div>
              <div className="text-sm">
                <div className="opacity-80">Total growth</div>
                <span
                  className={`text-lg font-medium ${Math.abs(portfolioMetadataQuery.data.grossTotalPercentGrowth - 0) < 0.001
                    ? 'text-func-black-0'
                    : portfolioMetadataQuery.data.grossTotalPercentGrowth < 0
                      ? 'text-tickers-red'
                      : 'text-tickers-green'
                    }`}>
                  {numberToPercentChangeString(portfolioMetadataQuery.data.grossTotalPercentGrowth)}
                </span>{' '}
              </div>
            </div>
          )}
        </div>
        <PortfolioGraph fundId={fundId} />
        <div className="mt-20 w-full">
          <div className="text-xl">Portfolio</div>
        </div>
        <div className="mt-7 max-w-full">
          <div className="flex w-full flex-col justify-start">
            {authStatus === AuthStatus.Allowed ? (
              <Table<CompanyPositionRowDetailed>
                maxHeight="max-h-160"
                stickyHeader
                stickyColumn
                rowData={rowQueryDetailed.data ?? []}
                defaultRowCount={70}
                columns={columnsDetailed}
              />
            ) : (
              <Table<CompanyPositionRowBasic>
                stickyColumn
                maxHeight="max-h-160"
                stickyHeader
                rowData={rowQueryBasic.data ?? []}
                defaultRowCount={70}
                columns={columnsBasic}
              />
            )}
          </div>
        </div>
      </div>
      <div className="flex w-full max-w-page-max flex-col justify-start">
        {!shouldShowFundAssetsAsIndividualInvestments && (fundSwapQuery.data ?? []).length > 0 && (
          <div className="mt-8 w-full">
            <Table<FundSwapPositionRowBasic>
              stickyHeader
              stickyColumn
              rowData={fundSwapQuery.data ?? []}
              defaultRowCount={25}
              columns={fundSwapColumns}
            />
          </div>
        )}
        {(fundSwapQuery?.data ?? []).length > 0 && (
          <div className="pt-6">
            <div className="flex cursor-pointer items-center" onClick={onFundAssetCheckboxClick}>
              <Checkbox
                size="2"
                className="pointer-events-none"
                checked={shouldShowFundAssetsAsIndividualInvestments}
              />
              <div className="pl-2 text-sm">Show fund assets as individual investments</div>
            </div>
          </div>
        )}
        <div className="mt-72 flex flex-col gap-2 pb-16">
          <div className="w-full text-sm opacity-50">* Assets in transit</div>
          <Disclaimer shouldApplyMarginTop={false} />
        </div>
      </div>
    </div>
  )
}

export function FundSwitch(props: { selectedFundId: number }) {
  let navigate = useNavigate()
  return (
    <Select.Root
      value={props.selectedFundId.toString()}
      onValueChange={(newValue) => {
        navigate(`/portfolio/${newValue}`)
      }}>
      <Select.Trigger></Select.Trigger>
      <Select.Content>
        <Select.Item value={'1'}>Fund I</Select.Item>
        <Select.Item value={'2'}>Fund II</Select.Item>
        <Select.Item value={'3'}>Fund III</Select.Item>
      </Select.Content>
    </Select.Root>
  )
}
