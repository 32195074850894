import {
  numberToAbbreviatedDollarString,
  numberToAbbreviatedString,
  numberToReadableDollarString,
} from 'plural-shared/utils'
import { Bar, BarChart, ResponsiveContainer, XAxis, YAxis } from 'recharts'
import { isNone } from 'safety'
import { useSimulatorContext } from './CashSimulatorContext'

export type CashGraphTransactionType = 'Secondary' | 'Plural'

export interface NetCashGraphBar {
  netCashValue: number
  netTaxValue: number
  netFeesValue: number
  grossTransactionValue: number
  transactionType: CashGraphTransactionType
}

export interface NetCashGraphProps {
  pluralBar: NetCashGraphBar
  secondaryBar: NetCashGraphBar
}

export default function NetCashGraph({ props }: { props: NetCashGraphProps | undefined }) {
  const context = useSimulatorContext()
  const cashTx = context?.pluralCashTx
  if (isNone(cashTx) || isNone(props)) {
    return null
  }

  const brown = '#59564F'
  const gray = 'hsl(0, 0%, 90%)'
  const darkGray = 'hsl(0, 0%, 83%)'
  const { pluralBar, secondaryBar } = props

  const xMax = Math.max(pluralBar.grossTransactionValue, secondaryBar.grossTransactionValue)

  function NetCashBar(props: any) {
    const { x, y, width, height } = props
    const transactionType: CashGraphTransactionType = props.transactionType
    const bar = transactionType === 'Secondary' ? secondaryBar : pluralBar
    return (
      <g>
        <rect x={x} y={y} width={width} height={height} fill={brown} />
        <foreignObject x={x} y={y} width={width} height={height}>
          <div className="pl-3 pt-3 text-white">
            <div className="text-base">{bar.transactionType}</div>
            <div className="pt-2">
              <div className="text-base font-medium">{numberToAbbreviatedDollarString(bar.netCashValue)}</div>
              <div className="-mt-1 text-sm">net cash</div>
            </div>
          </div>
        </foreignObject>
      </g>
    )
  }

  function legendMark(color: string, label: string) {
    return (
      <div className="flex items-center">
        <div className="mr-[6px] h-4 w-4" style={{ backgroundColor: color }} />
        <div className="text-sm">{label}</div>
      </div>
    )
  }

  function barChart(bars: NetCashGraphBar[]) {
    return (
      <ResponsiveContainer width="100%" height={130}>
        <BarChart
          data={bars}
          margin={{
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
          }}
          barCategoryGap={0}
          layout="vertical">
          <XAxis
            interval={0}
            className="text-[7px] lg:text-xs"
            type="number"
            domain={[0, xMax]}
            tick={false}
            tickLine={false}
            axisLine={false}
            height={0}
          />
          <YAxis type="category" dataKey="transactionType" tick={false} tickLine={false} axisLine={false} width={0} />
          <Bar dataKey="netCashValue" stackId={'a'} fill={brown} isAnimationActive={false} shape={NetCashBar} />
          <Bar dataKey="netTaxValue" stackId={'a'} fill={darkGray} isAnimationActive={false} />
          <Bar dataKey="netFeesValue" stackId={'a'} fill={gray} isAnimationActive={false} />
        </BarChart>
      </ResponsiveContainer>
    )
  }

  const netRateComparison = cashTx.netRateComparison()
  const pluralTaxes = netRateComparison.plural.netTaxRate * pluralBar.grossTransactionValue
  const secondaryTaxes = netRateComparison.secondary.netTaxRate * secondaryBar.grossTransactionValue
  const pluralFees = netRateComparison.plural.netFeeRate * pluralBar.grossTransactionValue
  const secondaryFees = netRateComparison.secondary.netFeeRate * secondaryBar.grossTransactionValue
  const sharesOwed = cashTx.sharesOwed()

  return (
    <div className="w-full lg:pr-32">
      <div>
        {barChart([pluralBar])}
        <div className="mt-1 text-xs opacity-50">
          {numberToAbbreviatedString(sharesOwed, 0)} shares at{' '}
          {numberToReadableDollarString(cashTx.inputs().baseSharePrice)} per share—
          {numberToAbbreviatedDollarString(pluralTaxes)} NPV taxes, {numberToAbbreviatedDollarString(pluralFees)} fees
        </div>
      </div>
      <div className="mt-3" />
      <div>
        {barChart([secondaryBar])}
        <div className="mt-1 text-xs opacity-50">
          {numberToAbbreviatedString(sharesOwed, 0)} shares at{' '}
          {numberToReadableDollarString(cashTx.inputs().baseSharePrice)} per share—
          {numberToAbbreviatedDollarString(secondaryTaxes)} NPV taxes, {numberToAbbreviatedDollarString(secondaryFees)}{' '}
          fees
        </div>
      </div>
      <div className="mt-4">
        <div className="flex space-x-4">
          {legendMark(brown, 'Net cash')}
          {legendMark(darkGray, 'Taxes')}
          {legendMark(gray, 'Fees')}
        </div>
      </div>
      <div className="mt-2 w-full border-b" />
    </div>
  )
}
