import { makeStateObject, StateObject } from 'plural-shared/utils'
import { MassiveCompanyProfile, MassiveResolvedCompanyMetadata } from 'plural-trpc/routers/companiesRouter'
import { createContext, useContext, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ArrayElement } from 'safety'
import { RouterOutputs, trpc } from '../../../utils/trpc'

type EnrichedCompaniesMetadata = RouterOutputs['companies']['allForEnrichedSelector']
export type Company = ArrayElement<RouterOutputs['companies']['allForEnrichedSelector']['companies']>

type EnrichedCompanyDataContext = {
  companyId: StateObject<string | undefined>
  pluralCompanyId: StateObject<number | undefined>
  allMassiveCompanies: MassiveResolvedCompanyMetadata[]
  companies: Company[]
  selectedBaseCompany: StateObject<Company | null>
  selectedEnrichedCompanyMetadata: StateObject<MassiveResolvedCompanyMetadata | null>
  selectedEnrichedCompanyData: StateObject<MassiveCompanyProfile | null>
  didSelectCompany: (company: MassiveResolvedCompanyMetadata) => void
  isLoadingCompanies: boolean
  error: string | null
  projectionIncrement: StateObject<number>
  refetch: () => Promise<any>
}

const CompanySelectorContext = createContext<EnrichedCompanyDataContext>({
  companyId: {
    value: undefined,
    setValue: () => {},
  },
  pluralCompanyId: {
    value: undefined,
    setValue: () => {},
  },
  allMassiveCompanies: [],
  companies: [],
  isLoadingCompanies: false,
  error: null,
  didSelectCompany: () => {},
  selectedBaseCompany: {
    value: null,
    setValue: () => {},
  },
  selectedEnrichedCompanyMetadata: {
    value: null,
    setValue: () => {},
  },
  selectedEnrichedCompanyData: {
    value: null,
    setValue: () => {},
  },
  projectionIncrement: {
    value: 0,
    setValue: () => {},
  },
  refetch: () => {
    return Promise.resolve()
  },
})

export function CompanySelectorContextProvider({
  children,
  redirectPath,
}: {
  children: React.ReactNode
  redirectPath: string
}) {
  let params = useParams<{ companyId: string }>()
  let navigate = useNavigate()
  const [projectionIncrement, setProjectionIncrement] = useState(0)

  const companiesQuery = trpc.companies.allForEnrichedSelector.useQuery()

  const allMassiveCompanies = useMemo(() => {
    return (
      companiesQuery.data?.allMassiveCompanies?.sort(
        (a: MassiveResolvedCompanyMetadata, b: MassiveResolvedCompanyMetadata) =>
          a.companyName.localeCompare(b.companyName),
      ) ?? []
    )
  }, [companiesQuery.data?.allMassiveCompanies])

  const companies = useMemo(() => {
    return companiesQuery.data?.companies?.sort((a: Company, b: Company) => a.name.localeCompare(b.name)) ?? []
  }, [companiesQuery.data?.companies])

  const [selectedBaseCompany, setSelectedBaseCompany] = useState<Company | null>(null)
  const [selectedEnrichedCompanyMetadata, setSelectedEnrichedCompanyMetadata] =
    useState<MassiveResolvedCompanyMetadata | null>(null)
  const [enrichedCompanyData, setEnrichedCompanyData] = useState<MassiveCompanyProfile | null>(null)
  const [loading, setLoading] = useState(false)

  const companyId: StateObject<string | undefined> = useMemo(() => {
    const companyIdFromParams = (): string | undefined => {
      const companyIdParam = params.companyId
      if (companyIdParam === 'none') return undefined
      return companyIdParam
    }
    return {
      value: companyIdFromParams(),
      setValue: (id: string | undefined) => {
        if (id !== undefined) {
          navigate(`${redirectPath}/${id}`)
        }
      },
    }
  }, [params.companyId, navigate])

  const pluralCompanyId: StateObject<number | undefined> = useMemo(() => {
    let company = allMassiveCompanies.find(
      (company: MassiveResolvedCompanyMetadata) => company.massiveCompanyId === companyId.value,
    )
    return {
      value: company?.otherCompanyId ? parseInt(company.otherCompanyId) : undefined,
      setValue: (id: number | undefined) => {},
    }
  }, [companyId, allMassiveCompanies])

  useEffect(() => {
    // Reset data when companyId changes
    setEnrichedCompanyData(null)
    if (companyId.value) {
      setLoading(true)
    }
  }, [companyId.value])

  const enrichedDataQuery = trpc.companies.getEnrichedCompanyData.useQuery(companyId.value!!, {
    enabled: !!companyId.value,
    onSuccess: (data: { companyData: MassiveCompanyProfile }) => {
      setEnrichedCompanyData(data.companyData)
      setLoading(false)
    },
    onError: () => {
      // Handle errors, such as resetting data or setting error messages
      setEnrichedCompanyData(null)
      setLoading(false)
    },
    onSettled: () => {
      // This runs after query either succeeds or fails
      setLoading(false)
    },
  })

  function didSelectCompany(company: MassiveResolvedCompanyMetadata) {
    const baseCompany: Company | null =
      company.otherCompanyId != null
        ? companies.find((c: Company) => c.id.toString() === company.otherCompanyId) ?? null
        : null

    setSelectedBaseCompany(baseCompany)
    setSelectedEnrichedCompanyMetadata(company)
    companyId.setValue(company.massiveCompanyId)
  }

  useEffect(() => {
    if (companyId.value) {
      let companyMetadata = allMassiveCompanies.find(
        (company: MassiveResolvedCompanyMetadata) => company.massiveCompanyId === companyId.value,
      )
      if (companyMetadata != null && companyMetadata.otherCompanyId) {
        setSelectedEnrichedCompanyMetadata(companyMetadata)
        setSelectedBaseCompany(
          companies.find(
            (existingCompany: Company) => existingCompany.id.toString() === companyMetadata?.otherCompanyId,
          ) ?? null,
        )
      }
    }
  }, [companyId, companies])

  return (
    <CompanySelectorContext.Provider
      value={{
        companyId: companyId,
        pluralCompanyId: pluralCompanyId,
        allMassiveCompanies: allMassiveCompanies,
        companies: companies,
        selectedBaseCompany: makeStateObject([selectedBaseCompany, setSelectedBaseCompany]),
        selectedEnrichedCompanyMetadata: makeStateObject([
          selectedEnrichedCompanyMetadata,
          setSelectedEnrichedCompanyMetadata,
        ]),
        selectedEnrichedCompanyData: makeStateObject([enrichedCompanyData, setEnrichedCompanyData]),
        isLoadingCompanies: companiesQuery.isLoading || enrichedDataQuery.isLoading,
        error: companiesQuery.error?.message ?? null,
        didSelectCompany,
        projectionIncrement: makeStateObject([projectionIncrement, setProjectionIncrement]),
        refetch: () => {
          return companiesQuery.refetch()
        },
      }}>
      {children}
    </CompanySelectorContext.Provider>
  )
}

export const useCompanySelectorContext = () => {
  return useContext(CompanySelectorContext)
}

export const withCompanySelectorContext =
  (Component: React.ComponentType, redirectPath: string = '/company') =>
  (props: any) => {
    return (
      <CompanySelectorContextProvider redirectPath={redirectPath}>
        <Component {...props} />
      </CompanySelectorContextProvider>
    )
  }
