import { Select } from '@radix-ui/themes'
import AdminOverallGraph from 'admin/AdminOverallGraph'
import { AdminSheetPageContainer } from 'admin/AdminSideNav'
import { useState } from 'react'

export default function AdminDashboardPage() {
  let [selectedGraphFnId, setSelectedGraphFnId] = useState<number>(1)
  return (
    <AdminSheetPageContainer
      title="Plural Admin Dashboard"
      endNode={
        <Select.Root
          value={selectedGraphFnId.toString()}
          onValueChange={(newValue) => {
            setSelectedGraphFnId(parseInt(newValue))
          }}>
          <Select.Trigger></Select.Trigger>
          <Select.Content>
            <Select.Item value={'1'}>Total Value</Select.Item>
            <Select.Item value={'2'}>Change in Value</Select.Item>
          </Select.Content>
        </Select.Root>
      }>
      <AdminOverallGraph selectedFnId={selectedGraphFnId} />
    </AdminSheetPageContainer>
  )
}
