import CompanyStatChart from './CompanyStatChart'
import { useCompanySelectorContext as useEnrichedCompanySelectorContext } from './MassiveEnrichedCompanySelectorContextProvider'

export default function CompanyHeadcountAndOpenRoleData() {
  const { selectedEnrichedCompanyData } = useEnrichedCompanySelectorContext()
  const allOpenRoleStats = selectedEnrichedCompanyData.value?.allOpenRoleStats || []
  const allHeadcountStats = selectedEnrichedCompanyData.value?.allHeadcountStats || []

  const getTotalOpenRolesGraphDataForCompany = () => {
    const statDateToTotalCount = new Map<string, number>()
    for (const stat of allOpenRoleStats) {
      const date = stat.statsDate
      const dateObj = new Date(date)
      if (statDateToTotalCount.has(date)) {
        statDateToTotalCount.set(date, statDateToTotalCount.get(date)! + stat.adjustedCount)
      } else {
        statDateToTotalCount.set(date, stat.count)
      }
    }

    return Array.from(statDateToTotalCount.entries()).map(([date, count]) => ({
      x: new Date(date),
      y: count,
    }))
  }

  const getTotalHeadcountGraphDataForCompany = () => {
    const statDateToTotalCount = new Map<string, number>()
    const statDateToTotalAdjustedCount = new Map<string, number>()
    for (const stat of allHeadcountStats) {
      const date = stat.statsDate
      const dateObj = new Date(date)

      if (statDateToTotalCount.has(date)) {
        statDateToTotalCount.set(date, statDateToTotalCount.get(date)! + stat.count)
        if (stat.adjustedCount != null) {
          statDateToTotalAdjustedCount.set(date, statDateToTotalAdjustedCount.get(date)! + stat.adjustedCount)
        }
      } else {
        statDateToTotalCount.set(date, stat.count)
        if (stat.adjustedCount != null) {
          statDateToTotalAdjustedCount.set(date, stat.adjustedCount)
        }
      }
    }
    const totalHeadcountData = Array.from(statDateToTotalCount.entries()).map(([date, count]) => ({
      x: new Date(date),
      y: count,
    }))
    const totalAdjustedHeadcountData = Array.from(statDateToTotalAdjustedCount.entries()).map(([date, count]) => ({
      x: new Date(date),
      y: count,
    }))
    totalHeadcountData.sort((a, b) => a.x.getTime() - b.x.getTime())
    totalAdjustedHeadcountData.sort((a, b) => a.x.getTime() - b.x.getTime())

    return { totalHeadcountData, totalAdjustedHeadcountData }
  }
  const totalOpenRoleGraphData = getTotalOpenRolesGraphDataForCompany()
  const { totalHeadcountData: totalHeadcountGraphData, totalAdjustedHeadcountData: totalAdjustedHeadcountGraphData } =
    getTotalHeadcountGraphDataForCompany()
  const headcountScatterData = [
    {
      data: totalHeadcountGraphData,
      label: 'Headcount',
    },
    {
      data: totalAdjustedHeadcountGraphData,
      label: 'Adjusted Headcount',
      borderColor: 'rgb(210,76,76)',
      backgroundColor: 'rgb(210,76,76)',
    },
  ]

  return (
    <div className="w-full">
      <div className="flex justify-between">
        <div className="w-1/2">
          <table className="table-auto">
            <thead>
              <tr>
                <th className="px-4 py-2">Headcount</th>
                <th className="px-4 py-2">Value</th>
              </tr>
            </thead>
            <tbody>
              <tr title={selectedEnrichedCompanyData.value?.latestHeadcountAsOf || undefined}>
                <td className="border px-4 py-2">Current Headcount</td>
                <td className="border px-4 py-2">{selectedEnrichedCompanyData.value?.latestHeadcount}</td>
              </tr>
              <tr>
                <td className="border px-4 py-2">1 Week Headcount Growth Rate</td>
                <td className="border px-4 py-2">
                  {selectedEnrichedCompanyData.value?.oneWeekOverallHeadcountGrowthRate}
                </td>
              </tr>
              <tr>
                <td className="border px-4 py-2">1 Month Headcount Growth Rate</td>
                <td className="border px-4 py-2">
                  {selectedEnrichedCompanyData.value?.oneMonthOverallHeadcountGrowthRate}
                </td>
              </tr>
              <tr>
                <td className="border px-4 py-2">3 Months Headcount Growth Rate</td>
                <td className="border px-4 py-2">
                  {selectedEnrichedCompanyData.value?.threeMonthsOverallHeadcountGrowthRate}
                </td>
              </tr>
              <tr>
                <td className="border px-4 py-2">6 Months Headcount Growth Rate</td>
                <td className="border px-4 py-2">
                  {selectedEnrichedCompanyData.value?.sixMonthsOverallHeadcountGrowthRate}
                </td>
              </tr>
              <tr>
                <td className="border px-4 py-2">1 Year Headcount Growth Rate</td>
                <td className="border px-4 py-2">
                  {selectedEnrichedCompanyData.value?.oneYearOverallHeadcountGrowthRate}
                </td>
              </tr>
              <tr>
                <td className="border px-4 py-2">2 Year Headcount Growth Rate</td>
                <td className="border px-4 py-2">
                  {selectedEnrichedCompanyData.value?.twoYearsOverallHeadcountGrowthRate}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="w-1/2">
          <table className="table-auto">
            <thead>
              <tr>
                <th className="px-4 py-2">Open Roles</th>
                <th className="px-4 py-2">Value</th>
              </tr>
            </thead>
            <tbody>
              <tr title={selectedEnrichedCompanyData.value?.latestOpenRoleCountAsOf || undefined}>
                <td className="border px-4 py-2">Current Open Roles</td>
                <td className="border px-4 py-2">{selectedEnrichedCompanyData.value?.latestOpenRoleCount}</td>
              </tr>
              <tr>
                <td className="border px-4 py-2">1 Week Open Roles Growth Rate</td>
                <td className="border px-4 py-2">
                  {selectedEnrichedCompanyData.value?.oneWeekOverallOpenRoleGrowthRate}
                </td>
              </tr>
              <tr>
                <td className="border px-4 py-2">1 Month Open Roles Growth Rate</td>
                <td className="border px-4 py-2">
                  {selectedEnrichedCompanyData.value?.oneMonthOverallOpenRoleGrowthRate}
                </td>
              </tr>
              <tr>
                <td className="border px-4 py-2">3 Months Open Roles Growth Rate</td>
                <td className="border px-4 py-2">
                  {selectedEnrichedCompanyData.value?.threeMonthsOverallOpenRoleGrowthRate}
                </td>
              </tr>
              <tr>
                <td className="border px-4 py-2">6 Months Open Roles Growth Rate</td>
                <td className="border px-4 py-2">
                  {selectedEnrichedCompanyData.value?.sixMonthsOverallOpenRoleGrowthRate}
                </td>
              </tr>
              <tr>
                <td className="border px-4 py-2">1 Year Open Roles Growth Rate</td>
                <td className="border px-4 py-2">
                  {selectedEnrichedCompanyData.value?.oneYearOverallOpenRoleGrowthRate}
                </td>
              </tr>
              <tr>
                <td className="border px-4 py-2">2 Year Open Roles Growth Rate</td>
                <td className="border px-4 py-2">
                  {selectedEnrichedCompanyData.value?.twoYearsOverallOpenRoleGrowthRate}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="mt-8 flex w-full flex-col items-center px-4 py-2">
        <h4 className="text-lg font-semibold">Headcount</h4>

        {headcountScatterData?.[0]?.data?.length > 0 && (
          <CompanyStatChart scatterData={headcountScatterData} chartName="Headcount" />
        )}
      </div>
      <div className="mt-8 flex w-full flex-col items-center px-4 py-2">
        <h4 className="text-lg font-semibold">Open Roles</h4>
        {totalOpenRoleGraphData[0] && (
          <CompanyStatChart
            scatterData={[
              {
                data: totalOpenRoleGraphData,
                label: 'Total # of open roles',
              },
            ]}
            chartName="Total # of open roles"
          />
        )}
      </div>
    </div>
  )
}
