import { CaretRightIcon } from '@radix-ui/react-icons'
import { usePluralNavigation } from 'providers/NavigationProvider'

export default function Breadcrumbs(props: { crumbs: { title: string; href?: string }[] }) {
  let { navigate } = usePluralNavigation()
  return (
    <div className={`flex flex-row items-center text-lg`}>
      {props.crumbs.map((crumb, index) => {
        return (
          <div key={crumb.title} className="flex flex-row items-center">
            <div
              key={crumb.title}
              className={`${crumb.href ? 'cursor-pointer' : ''} flex flex-row items-center`}
              onClick={() => {
                if (crumb.href) {
                  navigate(crumb.href)
                }
              }}>
              {crumb.title}
            </div>
            {index < props.crumbs.length - 1 && <CaretRightIcon key={crumb.title + index} />}
          </div>
        )
      })}
    </div>
  )
}
