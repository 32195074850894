import Tabs from 'components/Tabs'
import { useState } from 'react'

export function useChartTimeFooter(defaultValue?: { years: number; months: number }) {
  let [selectedTime, setSelectedTime] = useState<{ years: number; months: number } | undefined>(defaultValue)
  return [selectedTime, setSelectedTime] as const
}

type TabT = {
  label: string
  value: { years: number; months: number }
}
export default function CompanyStatChartTimeFooter(props: {
  tabs?: TabT[]
  selectedTime?: { years: number; months: number }
  setSelectedTime: (time: { years: number; months: number } | undefined) => void
}) {
  let tabs = props.tabs ?? [
    {
      label: '6M',
      value: { years: 0, months: 6 },
    },
    {
      label: '1Y',
      value: { years: 1, months: 0 },
    },
    {
      label: '2Y',
      value: { years: 2, months: 0 },
    },
    {
      label: '5Y',
      value: { years: 5, months: 0 },
    },
  ]
  let { selectedTime, setSelectedTime } = props
  let tabValue = selectedTime
    ? tabs.find((tab) => tab.value.years === selectedTime!.years && tab.value.months === selectedTime!.months)?.label
    : undefined
  return (
    <div
      className="pl-[90px]"
      style={
        {
          // transform: 'translateY(-65px)',
        }
      }>
      <Tabs
        value={tabValue}
        onValueChange={(newVal) => {
          let newTab = tabs.find((tab) => tab.label === newVal)
          if (newTab) {
            setSelectedTime(newTab.value)
          } else {
            setSelectedTime(undefined)
          }
        }}>
        <Tabs.List>
          {tabs.map((tab) => (
            <Tabs.Tab
              basic
              key={tab.label}
              label={tab.label}
              value={tab.label}
              isActive={selectedTime?.years === tab.value.years && selectedTime?.months === tab.value.months}
            />
          ))}
          <Tabs.Tab key={'all'} label={'All'} value={'all'} isActive={!selectedTime} basic />
        </Tabs.List>
      </Tabs>
    </div>
  )
}
