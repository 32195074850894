import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { createTRPCProxyClient, httpBatchLink, httpLink } from '@trpc/client'
import type { AppRouter } from 'plural-trpc'
import { createContext, useContext, useMemo } from 'react'
import { trpc } from '../utils/trpc'
import { useAccessToken } from './AccessTokenProvider'
import { useSnackbar } from './SnackbarProvider'

enum TRPC_Environment {
  Local = 'http://localhost:2023',
  Dev = 'https://trpc-dev.withplural.com/',
  Prod = 'https://trpc-prod.withplural.com/',
}
let selectedEnvironment = TRPC_Environment.Local
const vercelStr = 'with-plural.vercel.app'
const forceProdTRPC =
  window.location.hostname !== 'localhost' &&
  window.location.hostname !== '127.0.0.1' &&
  !window.location.hostname.includes(vercelStr)

function chooseServerURL(): string {
  if (forceProdTRPC) {
    return TRPC_Environment.Prod
  } else if (window.location.hostname.includes(vercelStr) && selectedEnvironment === TRPC_Environment.Local) {
    return TRPC_Environment.Dev
  } else {
    return selectedEnvironment
  }
}

const TRPCClientContext = createContext(createTRPCProxyClient<AppRouter>({ links: makeLinks(undefined) }))

function makeLinks(_accessToken: string | undefined) {
  const links = [
    httpBatchLink({
      url: chooseServerURL(),
    }),
  ]
  if (_accessToken) {
    links.unshift(
      httpLink({
        url: chooseServerURL(),
        headers: {
          Authorization: `Bearer ${_accessToken}`,
        },
      }),
    )
  } else {
    links.unshift(
      httpLink({
        url: chooseServerURL(),
      }),
    )
  }
  return links
}

export function TRPCProvider({ children }: any) {
  const accessToken = useAccessToken()
  let { setSnackbarText } = useSnackbar()

  const queryClient = useMemo(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
          },
        },
        // queryCache: new QueryCache({
        //   onError: (error: any) => {
        //     alert('error hit here!')
        //   },
        // }),
      }),
    [],
  )
  const vanillaClient = useMemo(() => {
    return createTRPCProxyClient<AppRouter>({
      links: makeLinks(accessToken),
    })
  }, [accessToken])
  const trpcClient = useMemo(() => {
    return trpc.createClient({
      links: makeLinks(accessToken),
    })
  }, [accessToken])

  return (
    <TRPCClientContext.Provider value={vanillaClient}>
      <trpc.Provider client={trpcClient} queryClient={queryClient}>
        <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
      </trpc.Provider>
    </TRPCClientContext.Provider>
  )
}

export function useVanillaTRPC() {
  const trpcClient = useContext(TRPCClientContext)
  if (!trpcClient) {
    throw new Error('useTRPCClient must be used within a TRPCProvider')
  }
  return trpcClient
}
