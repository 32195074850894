export const valuationResultsFooter = (
  <div>
    <hr className="mb-5 mt-8" />

    <div className="text-xs text-neutral-500">
      <h6 className="mb-3 font-medium">Assumptions</h6>
      <p className="mb-3">
        Only includes bonafide funding rounds, meaning raises of greater than 10% of the post-money valuation, unless
        Plural grants an exception.
      </p>
      <p className="mb-3">
        Share value may differ depending on the specific share type exchanged (e.g. common versus preferred shares).
      </p>
      <p>*Outcome triggers Plural's minimum company growth rate.</p>
    </div>
  </div>
)
