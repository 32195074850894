import { IonItem, IonSpinner } from '@ionic/react'
import AdminCompanySheetPage from 'pages/admin/AdminCompanySheetPage'
import { usePluralNavigation } from 'providers/NavigationProvider'
import { usePluralAuth } from 'providers/PluralAuthProvider'
import { useEffect } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { AuthStatus } from 'utils/authStatus'
import FundingRoundSheetPage from './FundingRoundSheetPage'
import FundsSheetPage from './FundsSheetPage'
import IndicationsSheetPage from './IndicationsSheetPage'
import HoldingsSheetPage from './HoldingsSheetPage'

export default function SeveralHomePage(props: {
  defaultEntity: 'company' | 'funding' | 'fund-snapshot' | 'indications' | 'holdings'
}) {
  const { loginWithRedirect, authStatus, isLoading } = usePluralAuth()
  let params = useParams()
  let sheetId = params.sheetId ? parseInt(params.sheetId) : 0
  let { navigate } = usePluralNavigation()
  useEffect(() => {
    if (authStatus === AuthStatus.None && !isLoading) {
      loginWithRedirect({
        appState: {
          returnTo: '/dashboard',
        },
      })
    }
  }, [authStatus, isLoading, loginWithRedirect])
  let location = useLocation()
  if (!sheetId && location.pathname.toLowerCase().endsWith('dashboard')) {
    navigate('/dashboard/sheets/company', { replace: true })
  }

  switch (props.defaultEntity) {
    case 'company':
      return <AdminCompanySheetPage />
    case 'funding':
      return <FundingRoundSheetPage />
    case 'fund-snapshot':
      return <FundsSheetPage />
    case 'indications':
      return <IndicationsSheetPage />
    case 'holdings':
      return <HoldingsSheetPage />
    default:
      throw new Error('Invalid entity')
  }
}

function LoadingPage() {
  return (
    <div className="flex h-screen w-screen items-center justify-center">
      <IonItem>
        <IonSpinner name="lines-sharp"></IonSpinner>
      </IonItem>
    </div>
  )
}
