import moment from 'moment'
import { dateForYearsInFuture, numberToAbbreviatedDollarString } from 'plural-shared/utils'
import { useEffect, useState } from 'react'
import { Line, LineChart, ResponsiveContainer, Tooltip, TooltipProps, XAxis, YAxis } from 'recharts'
import { ArrayElement, Optional, isSome } from 'safety'
import { RouterOutputs, trpc } from 'utils/trpc'
import { sampleEnv } from '../../constants'

console.log('received var: ', sampleEnv)

const fundLineColor = '#262422'
const companyLineColor = '#B2ACA8'
const fundLineColorBg = 'bg-[#262422]'
const companyLineColorBg = 'bg-[#B2ACA8]'

type GraphPoint = ArrayElement<RouterOutputs['dealSensitivity']['dealGraphPoints']>

export default function DealSensitivityValueGraph({
  warehousedAssetCAGR,
  yearsToLiquidity,
  reserveRatio,
}: {
  warehousedAssetCAGR: number
  yearsToLiquidity: number
  reserveRatio: number
}) {
  const graphPointsQuery = trpc.dealSensitivity.dealGraphPoints.useQuery({
    warehousedAssetCAGRFromBase: warehousedAssetCAGR,
    warehousedAssetReserveRatio: reserveRatio,
    yearsToLiquidity,
  })
  const [graphPoints, setGraphPoints] = useState<GraphPoint[]>(graphPointsQuery.data ?? [])

  useEffect(() => {
    if (graphPointsQuery.data) {
      setGraphPoints(graphPointsQuery.data)
    }
    if (graphPointsQuery.error) {
      console.error(graphPointsQuery.error)
    }
  }, [graphPointsQuery.data, graphPointsQuery.error])

  const formatTooltipValue = (value: number) => {
    return value.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 0,
    })
  }
  function dateForYearsSinceStartDate({
    startDate,
    yearsSinceStartDate,
  }: {
    startDate: Date
    yearsSinceStartDate: number
  }): Date {
    return moment(startDate).add(yearsSinceStartDate, 'years').toDate()
  }

  const CustomTooltip: React.FC<TooltipProps<any, any>> = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const fundRealizedValue = numberToAbbreviatedDollarString(payload[1]?.value ?? '')
      const fundRealizedPayload = payload[1]?.payload ?? null
      let index: number = -1
      if (isSome(fundRealizedPayload)) {
        index = fundRealizedPayload.index
      }
      const equivalentWarehousedAssetValue: Optional<number> = payload[0]?.value
      const date = dateForYearsSinceStartDate({
        startDate: new Date(),
        yearsSinceStartDate: Number(label),
      })
      let realizedValueLabel = 'Plural Investment Value'
      return (
        <div
          className="custom-tooltip flex flex-col items-start rounded-md pb-4 pl-4 pr-5 pt-3 text-white"
          style={{ backgroundColor: '#1C2227' }}>
          <div className="label mb-3 text-sm opacity-70">
            {date.toLocaleDateString('en-US', { year: 'numeric', month: 'short' })}
          </div>
          <div className="text-lg font-bold">{fundRealizedValue}</div>
          <div className="-mt-1 text-sm opacity-70">{realizedValueLabel}</div>
          {equivalentWarehousedAssetValue && (
            <div className="mt-2 flex flex-col items-start">
              <div className="text-lg font-bold">{numberToAbbreviatedDollarString(equivalentWarehousedAssetValue)}</div>
              <div className="label -mt-1 text-sm opacity-70">Company Equity Value</div>
            </div>
          )}
        </div>
      )
    }
    return null
  }

  const endDate = dateForYearsInFuture({ startDate: new Date(), yearsInFuture: yearsToLiquidity })
  const rangeStr =
    new Date().toLocaleDateString('en-US', { year: 'numeric', month: 'short' }) +
    ' - ' +
    endDate.toLocaleDateString('en-US', { year: 'numeric', month: 'short' })

  function yAxisDomain(): [number, number] {
    // Find the min and max values in your data
    const minValue = Math.min(...graphPoints.map((point) => point.contractValueLocked))
    const maxValue = Math.max(...graphPoints.map((point) => point.contractValueLocked))
    // Calculate the range
    const range = maxValue - minValue
    // Calculate the padding as a percentage of the range
    const padding = range * 0.1 // Adjust this value to change the percentage
    // Adjust the domain
    return [minValue - padding, maxValue + padding]
  }

  return (
    <div className="border-b">
      <div className="pr-16 lg:w-full">
        <ResponsiveContainer width="100%" height={350}>
          <LineChart data={graphPoints} margin={{ top: 16, right: 32, left: 48, bottom: 16 }}>
            <XAxis
              dataKey="yearsSinceEntry"
              tickSize={0}
              tick={false}
              tickLine={false}
              axisLine={false}
              padding={{ left: 0, right: 0 }}
              height={12}
            />
            <YAxis
              yAxisId="left"
              orientation="left"
              tickFormatter={(tickItem) => {
                if (tickItem <= 0) {
                  return '$0'
                }
                return numberToAbbreviatedDollarString(tickItem, 0)
              }}
              tickSize={12}
              className="text-xs"
              tickLine={false}
              axisLine={false}
              domain={yAxisDomain()}
              padding={{ top: 0, bottom: 0 }}
              width={12}
            />
            <Tooltip content={<CustomTooltip />} isAnimationActive={false} />
            <Line
              yAxisId="left"
              type="monotone"
              stroke={companyLineColor}
              name="Equivalent Company Equity Value"
              dataKey="equivalentWarehousedAssetValue"
              strokeWidth={1.5}
              activeDot={{ r: 4.5 }}
              dot={{ fill: companyLineColor, r: 2.5 }}
              isAnimationActive={false}
            />
            <Line
              yAxisId="left"
              type="monotone"
              stroke={fundLineColor}
              dataKey="contractValueLocked"
              name="Plural Investment Value"
              strokeWidth={1.5}
              dot={{ fill: fundLineColorBg, r: 2.5 }}
              activeDot={{ r: 4.5 }}
              isAnimationActive={false}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
      <div className="mb-2 pt-3">
        <div className="mb-1 text-sm font-medium">{rangeStr}</div>
        <div className="flex items-center">
          <div className={`h-3 w-3 ${fundLineColorBg} mr-1 rounded-sm`} />
          <div className="text-sm">Plural Investment Value</div>
        </div>
        <div className="flex items-center">
          <div className={`h-3 w-3 ${companyLineColorBg} mr-1 rounded-sm`} />
          <div className="text-sm">Underlying Company Equity Value</div>
        </div>
      </div>
    </div>
  )
}
