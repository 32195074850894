import { useFloating } from '@floating-ui/react'
import { HamburgerMenuIcon } from '@radix-ui/react-icons'
import { useEffect, useRef, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { ReactComponent as AppIcon } from '../images/pluralLogos/app-wordmark-light.svg'
import { usePluralAuth } from '../providers/PluralAuthProvider'
import { AuthStatus } from '../utils/authStatus'

const defaultLinkHover = 'hover:opacity-80'
const whiteButtonHover = 'hover:opacity-90'
const darkButtonBg = 'bg-[#1F1D1C]'
const islandBackgroundColor = 'bg-[#FCFCFC]'
const islandBorderColor = 'border-[#E8E8E8]'
const islandCardStyle: React.CSSProperties = { boxShadow: '0px 3px 8px 0px rgba(0, 0, 0, 0.02)' }
const islandSeparatorColor = '#E8E8E8'

interface NavigationLinkProps {
  label: string
  to: string
}

interface NavigationButtonProps {
  label: string
  onClick: () => void
}
type NavigationItemProps = NavigationLinkProps | NavigationButtonProps

function NavigationIslandLink({
  label,
  to,
  additionalClass = '',
  hoverClass = defaultLinkHover,
  style = {},
}: {
  label: string
  to: string
  additionalClass?: string
  hoverClass?: string
  style?: React.CSSProperties
}) {
  return (
    <Link className={`flex h-9 items-center justify-center ${additionalClass} ${hoverClass}`} style={style} to={to}>
      {label}
    </Link>
  )
}

function NavigationIslandButton({
  label,
  onClick,
  hoverClass = defaultLinkHover,
}: {
  label: string
  onClick: () => void
  hoverClass?: string
}) {
  return (
    <button className={`flex h-9 items-center justify-center ${hoverClass}`} onClick={onClick}>
      <div>{label}</div>
    </button>
  )
}

function FloatingNavigationMenu({ navigationItems }: { navigationItems: NavigationItemProps[] }) {
  const popupNavigationItems = navigationItems.map((navigationItem, index) => {
    let itemClassName = `pl-4 h-12 w-full flex items-center ${islandSeparatorColor}`
    let itemStyle: React.CSSProperties = { borderColor: islandSeparatorColor }
    if (index !== navigationItems.length - 1) {
      itemClassName += ' border-b' // Add border to all but last item
    }
    if ('onClick' in navigationItem) {
      return (
        <div key={navigationItem.label}>
          <button onClick={navigationItem.onClick} className={itemClassName} style={itemStyle}>
            {navigationItem.label}
          </button>
        </div>
      )
    } else {
      return (
        <div key={navigationItem.label}>
          <Link className={itemClassName} to={navigationItem.to} style={itemStyle}>
            {navigationItem.label}
          </Link>
        </div>
      )
    }
  })
  return (
    <div
      className={`${islandBackgroundColor}  rounded-lg border text-sm ${islandBorderColor} ml-2 mr-2 mt-2`}
      style={islandCardStyle}>
      <div className="flex flex-col">{popupNavigationItems}</div>
    </div>
  )
}

export default function NavigationIsland({ additionalClass = '' }: { additionalClass?: string }) {
  const { loginWithRedirect, logout, authStatus, isLoading } = usePluralAuth()
  const location = useLocation()
  const [isOpen, setIsOpen] = useState(false)
  const { refs, floatingStyles } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: 'bottom',
  })

  const mobileBurgerNavigationItems: NavigationItemProps[] = [
    // {
    //   label: 'Calculator',
    //   to: '/calculator',
    // },
    ...(authStatus === AuthStatus.Allowed
      ? [
          {
            label: 'LP Portal',
            to: '/my-holdings',
          },
        ]
      : []),
    authStatus === AuthStatus.None
      ? {
          label: 'LP Sign in',
          onClick: () =>
            loginWithRedirect({
              appState: {
                returnTo: '/my-holdings',
              },
            }),
        }
      : {
          label: 'Log out',
          onClick: () =>
            logout({
              logoutParams: { returnTo: window.location.origin },
            }),
        },
  ]

  const desktopNavigationItems: NavigationItemProps[] = [
    // {
    //   label: 'Calculator',
    //   to: '/calculator',
    // },

    { label: 'LP Portal', to: '/my-holdings' },
  ]

  const expandedNavigationItems = desktopNavigationItems.map((navigationItem) => {
    if ('onClick' in navigationItem) {
      return (
        <NavigationIslandButton
          label={navigationItem.label}
          onClick={navigationItem.onClick}
          key={navigationItem.label}
        />
      )
    } else {
      return <NavigationIslandLink label={navigationItem.label} to={navigationItem.to} key={navigationItem.label} />
    }
  })

  const islandRef = useRef(null)
  const [islandWidth, setIslandWidth] = useState(0)
  useEffect(() => {
    const updateWidth = () => {
      if (islandRef.current) {
        const anyIsland = islandRef.current as any
        setIslandWidth(anyIsland.offsetWidth)
      }
    }

    // Call updateWidth once initially to set the width
    updateWidth()

    // Add event listener for window resize
    window.addEventListener('resize', updateWidth)

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', updateWidth)
    }
  }, [])

  // close the popup if click outside island
  useEffect(() => {
    const handleClickOutside = (event: TouchEvent) => {
      const cc: any = islandRef.current
      const clickIsInsideIsland = 'contains' in cc ? cc.contains(event.target as Node) : false
      if (refs.floating.current && !refs.floating.current.contains(event.target as Node) && !clickIsInsideIsland) {
        setIsOpen(false)
      }
    }
    document.addEventListener('touchstart', handleClickOutside)
    return () => {
      document.removeEventListener('touchstart', handleClickOutside)
    }
  }, [refs.floating, setIsOpen, islandRef])

  return (
    <div ref={islandRef} className="w-full">
      <div
        className={`flex flex-row items-center ${islandBackgroundColor} h-12 border-b text-sm font-normal ${islandBorderColor} ${additionalClass} pl-2 pr-2 lg:pl-16 lg:pr-16`}
        style={{ ...islandCardStyle }}
        ref={refs.setReference}>
        <Link to="/">
          <AppIcon style={{ height: '36px', width: '85px' }} />
        </Link>
        <div className="flex-grow lg:hidden" />
        <div className="ml-5 hidden space-x-8 lg:flex">{expandedNavigationItems}</div>
        <div className="lg:flex-grow" />
        {isLoading === false && authStatus === AuthStatus.None && (
          <div className="hidden pr-2 lg:flex">
            <NavigationIslandButton
              label="LP Sign in"
              onClick={() =>
                loginWithRedirect({
                  appState: {
                    returnTo: '/my-holdings',
                  },
                })
              }
            />
          </div>
        )}
        {isLoading === false && authStatus === AuthStatus.Allowed && (
          <div className="mr-5 hidden lg:flex">
            <NavigationIslandButton
              label="Log out"
              onClick={() =>
                logout({
                  logoutParams: { returnTo: window.location.origin },
                })
              }
            />
          </div>
        )}
        {/* {isLoading === false && authStatus === AuthStatus.None && (
          <NavigationIslandLink
            label="Book a demo"
            to="/demo"
            additionalClass={`${darkButtonBg} text-white font-medium pr-3 pl-3 pt-2 pb-2 ml-6`}
            hoverClass={whiteButtonHover}
            style={{ borderRadius: '12px' }}
          />
        )} */}
        <div className="ml-1 lg:hidden">
          <button
            onClick={() => setIsOpen(!isOpen)}
            className={`flex h-9 w-9 items-center justify-center rounded-xl border bg-white ${islandBorderColor} `}>
            <HamburgerMenuIcon color="black" style={{ height: '20px', width: '20px' }} />
          </button>
        </div>
        {isOpen && (
          <div ref={refs.setFloating} style={{ ...floatingStyles, width: islandWidth }}>
            <FloatingNavigationMenu navigationItems={mobileBurgerNavigationItems} />
          </div>
        )}
      </div>
    </div>
  )
}
