import { AnyColumnProps } from 'components/editable-table/ColumnProps'
import {
  AbbreviatedDollarsCellFormat,
  DateCellFormat,
  NumberCellFormat,
  PercentCellFormat,
  StringCellFormat,
} from 'components/editable-table/EditableTextCellFormat'
import { CompanyMetric } from 'plural-trpc/routers/companiesRouter'
import { usePluralAuth } from 'providers/PluralAuthProvider'
import { Optional, isSome } from 'safety'
import { hackyEditorIds } from 'several/hooks/FundingRound'
import { EditableTable } from '../../components/editable-table/EditableTable'
import { trpc } from '../../utils/trpc'
import { useCompanySelectorContext as useEnrichedCompanySelectorContext } from './massiveEnrichedCompany/MassiveEnrichedCompanySelectorContextProvider'
import { operatorToString, stringToOperator } from './operatorTranslation'

enum ColumnHeaders {
  Revenue = 'Revenue',
  Date = 'ReportedDate',
  PercentChange = 'PercentChange',
  Source = 'Source',
  Operator = 'RevOperator',
  IsPublic = 'IsPublic',
  IsRevenueEstimated = 'IsRevenueEstimated',
  IsPercentChangeEstimated = 'IsPercentChangeEstimated',
  UpdatedBy = 'Updated By',
  UpdatedAt = 'Updated At',
}

type CreatedObject = {
  [ColumnHeaders.Revenue]: number
  [ColumnHeaders.Date]: Date
  [ColumnHeaders.Source]: string
  [ColumnHeaders.Operator]: string
  [ColumnHeaders.IsPublic]: boolean
  [ColumnHeaders.IsRevenueEstimated]: boolean
  [ColumnHeaders.PercentChange]: number
  [ColumnHeaders.IsPercentChangeEstimated]: boolean
  timeframeInMonths: number
}

export function CompanyRevenueTable() {
  let { user } = usePluralAuth()
  let isEditor = hackyEditorIds.includes(user?.id ?? 0)
  const { selectedEnrichedCompanyData } = useEnrichedCompanySelectorContext()

  const updateMassiveCompanyMetricMutation = trpc.company.updateMassiveCompanyMetric.useMutation()

  const companyMetrics: CompanyMetric[] =
    selectedEnrichedCompanyData.value?.companyMetrics?.filter(
      (metric) => metric.metricType.toLowerCase() === 'revenue',
    ) ?? []

  function refresh() {}

  const columns: AnyColumnProps<CompanyMetric, CreatedObject>[] = [
    {
      header: ColumnHeaders.Revenue,
      inputType: 'input',
      textCellFormat: AbbreviatedDollarsCellFormat,
      accessor: (row) => row.value,
      updateRemote: async (row: CompanyMetric, newValue: Optional<number>) => {
        if (newValue === undefined || newValue === row.value) return
        await updateMassiveCompanyMetricMutation.mutateAsync({
          companyMetricId: row.id,
          companyId: selectedEnrichedCompanyData.value?.companyId!,
          value: newValue ? newValue : undefined,
        })
        refresh()
      },
      minWidthPixels: 150,
      placeholder: 'Revenue',
      createProps: {
        isRequired: false,
        createdObjectKey: ColumnHeaders.Revenue,
      },
    },
    {
      header: ColumnHeaders.Operator,
      inputType: 'multiSelect',
      options: ['=', '>', '<', '~'],
      minWidthPixels: 100,
      accessor: (row) => operatorToString(row.operator),
      updateRemote: async (row: CompanyMetric, newValue: Optional<string>) => {
        if (newValue === operatorToString(row.operator)) return
        await updateMassiveCompanyMetricMutation.mutateAsync({
          companyMetricId: row.id,
          companyId: selectedEnrichedCompanyData.value?.companyId!,
          operator: newValue ? stringToOperator(newValue) : undefined,
        })
        refresh()
      },
      createProps: {
        defaultOption: '=',
        isRequired: true,
        createdObjectKey: ColumnHeaders.Operator,
      },
    },
    {
      header: ColumnHeaders.PercentChange,
      inputType: 'input',
      textCellFormat: PercentCellFormat,
      accessor: (row: CompanyMetric) => row.percentChange,
      updateRemote: async (row: CompanyMetric, newValue: Optional<number>) => {
        if (newValue === row.percentChange) return
        await updateMassiveCompanyMetricMutation.mutateAsync({
          companyMetricId: row.id,
          companyId: selectedEnrichedCompanyData.value?.companyId!,
          percentChange: newValue ? newValue : undefined,
        })
        refresh()
      },
      placeholder: 'Percent change',
      createProps: {
        isRequired: false,
        createdObjectKey: ColumnHeaders.PercentChange,
      },
    },
    {
      header: 'Last Y Months',
      textCellFormat: NumberCellFormat,
      inputType: 'input',
      accessor: (row) => row.timeframeInMonthsSinceReported,
      updateRemote: async (row: CompanyMetric, newValue: Optional<number>) => {
        if (newValue === row.timeframeInMonthsSinceReported) return
        await updateMassiveCompanyMetricMutation.mutateAsync({
          companyMetricId: row.id,
          companyId: selectedEnrichedCompanyData.value?.companyId!,
          timeframeInMonths: newValue,
        })
        refresh()
      },
      createProps: {
        isRequired: false,
        createdObjectKey: 'timeframeInMonths',
      },
    },
    {
      header: ColumnHeaders.Date,
      textCellFormat: DateCellFormat,
      inputType: 'input',
      minWidthPixels: 100,
      updateRemote: async (row: CompanyMetric, newValue: Optional<Date>) => {
        if (newValue === (isSome(row.reportedAt) ? new Date(row.reportedAt) : undefined)) return
        await updateMassiveCompanyMetricMutation.mutateAsync({
          companyMetricId: row.id,
          companyId: selectedEnrichedCompanyData.value?.companyId!,
          reportedAt: newValue ? newValue.toISOString() : undefined,
        })
        refresh()
      },
      accessor: (row) => (isSome(row.reportedAt) ? new Date(row.reportedAt) : undefined),
      placeholder: new Date().toLocaleDateString(),
      createProps: {
        isRequired: true,
        createdObjectKey: ColumnHeaders.Date,
        defaultOption: new Date(),
      },
    },
    {
      header: ColumnHeaders.Source,
      inputType: 'input',
      textCellFormat: StringCellFormat,
      minWidthPixels: 150,
      updateRemote: async (row: CompanyMetric, newValue: Optional<string>) => {
        if (newValue === row.source) return
        await updateMassiveCompanyMetricMutation.mutateAsync({
          companyMetricId: row.id,
          companyId: selectedEnrichedCompanyData.value?.companyId!,
          source: newValue ? newValue : '',
        })
        refresh()
      },
      accessor: (row) => row.source,
      placeholder: 'Source',
      createProps: {
        isRequired: false,
        createdObjectKey: ColumnHeaders.Source,
      },
    },
    {
      header: ColumnHeaders.IsRevenueEstimated,
      inputType: 'boolean',
      accessor: (row) => row.isEstimated,
      updateRemote: async (row: CompanyMetric, newValue: Optional<boolean>) => {
        if (newValue === row.isEstimated) return
        await updateMassiveCompanyMetricMutation.mutateAsync({
          companyMetricId: row.id,
          companyId: selectedEnrichedCompanyData.value?.companyId!,
          isEstimated: newValue != null ? newValue : undefined,
        })
        refresh()
      },
      createProps: {
        defaultOption: false,
        isRequired: true,
        createdObjectKey: ColumnHeaders.IsRevenueEstimated,
      },
    },
    {
      header: 'Is % Change Estimated',
      inputType: 'boolean',
      accessor: (row) => row.isPercentChangeEstimated,
      updateRemote: async (row: CompanyMetric, newValue: Optional<boolean>) => {
        if (newValue === row.isPercentChangeEstimated) return
        await updateMassiveCompanyMetricMutation.mutateAsync({
          companyMetricId: row.id,
          companyId: selectedEnrichedCompanyData.value?.companyId!,
          isPercentChangeEstimated: newValue != null ? newValue : undefined,
        })
        refresh()
      },
      createProps: {
        defaultOption: false,
        isRequired: true,
        createdObjectKey: ColumnHeaders.IsPercentChangeEstimated,
      },
    },
    {
      header: ColumnHeaders.IsPublic,
      inputType: 'boolean',
      accessor: (row) => row.isPublic,
      updateRemote: async (row: CompanyMetric, newValue: Optional<boolean>) => {
        if (newValue === row.isPublic) return
        await updateMassiveCompanyMetricMutation.mutateAsync({
          companyMetricId: row.id,
          companyId: selectedEnrichedCompanyData.value?.companyId!,
          isPublic: newValue != null ? newValue : undefined,
        })
        refresh()
      },
      createProps: {
        defaultOption: true,
        isRequired: true,
        createdObjectKey: ColumnHeaders.IsPublic,
      },
    },
    {
      header: ColumnHeaders.UpdatedBy,
      inputType: 'input',
      textCellFormat: StringCellFormat,
      accessor: (row: CompanyMetric) => row.updatedBy,
    },
    {
      header: ColumnHeaders.UpdatedAt,
      inputType: 'input',
      textCellFormat: DateCellFormat,
      accessor: (row) => new Date(row.updatedAt),
    },
  ]

  return (
    <div className="w-full">
      <div className=" w-full">
        {
          <div className="w-full">
            <EditableTable<CompanyMetric, CreatedObject>
              columns={columns}
              rowData={companyMetrics.filter((metric) => (isEditor ? true : metric.isPublic))}
              stickyColumn={true}
              deleteProps={{
                onDelete: async (rowIndex) => {
                  const metric = companyMetrics[rowIndex]
                  await updateMassiveCompanyMetricMutation.mutateAsync({
                    companyMetricId: metric.id,
                    companyId: selectedEnrichedCompanyData.value?.companyId!,
                    deletedAt: new Date().toISOString(),
                  })
                  refresh()

                  // Remove the deleted metric from the table
                  const updatedMetrics = companyMetrics.filter((_, index) => index !== rowIndex)
                  selectedEnrichedCompanyData.setValue({
                    ...selectedEnrichedCompanyData.value!,
                    companyMetrics: updatedMetrics,
                  })
                },
                confirmationModalProps: {
                  title: 'Delete metric',
                  body: 'Are you sure you want to delete this metric?',
                  confirmText: 'Delete',
                },
              }}
              onRowCreate={async (createdObject: CreatedObject) => {
                if (!selectedEnrichedCompanyData.value?.companyId) return
                const data = await updateMassiveCompanyMetricMutation.mutateAsync({
                  companyId: selectedEnrichedCompanyData.value?.companyId!,
                  metricType: 'Revenue',
                  metricFormat: 'DOLLAR',
                  source: createdObject.Source,
                  value: createdObject.Revenue ?? undefined,
                  operator: stringToOperator(createdObject.RevOperator),
                  reportedAt: createdObject.ReportedDate.toISOString(),
                  isPublic: createdObject.IsPublic,
                  isEstimated: createdObject.IsRevenueEstimated,
                  isPercentChangeEstimated: createdObject.IsPercentChangeEstimated,
                  percentChange: createdObject.PercentChange ?? undefined,
                  timeframeInMonths: createdObject.timeframeInMonths ?? undefined,
                })
                selectedEnrichedCompanyData.setValue({
                  ...selectedEnrichedCompanyData.value!,
                  companyMetrics: [...(selectedEnrichedCompanyData.value?.companyMetrics ?? []), data],
                })
                refresh()
              }}
            />
          </div>
        }
      </div>
    </div>
  )
}
