import { AdminSheetPageContainer } from 'admin/AdminSideNav'
import { FundCommitsTable } from 'pages/adminSheet'
import { usePluralAuth } from 'providers/PluralAuthProvider'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { trpc } from 'utils/trpc'

export default function AdminFundCommits() {
  let params = useParams()
  let selectedFundId = params.fundId ? parseInt(params.fundId) : 1
  const fundDetailsQuery = trpc.fund.fundDetails.useQuery(selectedFundId)
  let { isLoading } = usePluralAuth()
  const entitiesQuery = trpc.user.adminGetAllEntities.useQuery(undefined, {
    enabled: !isLoading,
  })
  const entities = useMemo(() => {
    return entitiesQuery.data?.entities.sort((a, b) => a.name.localeCompare(b.name)) ?? []
  }, [entitiesQuery.data])

  return (
    <AdminSheetPageContainer title={`Fund Commits for ${fundDetailsQuery.data?.name ?? `Fund ${selectedFundId}`}`}>
      <FundCommitsTable selectedFundId={selectedFundId} entities={entities} />
    </AdminSheetPageContainer>
  )
}
