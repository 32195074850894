import Disclaimer from 'pages/portfolio/Disclaimer'
import { Link } from 'react-router-dom'
import VerticalBreak from '../VerticalBreak'

export default function LandingFooter() {
  return (
    <div
      className="text-white"
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        maxWidth: '628px',
        marginTop: '150px',
        marginBottom: '20px',
        padding: '0 24px',
      }}>
      <Link
        className="footer-contact make-button underline-on-hover"
        style={{ fontSize: '18px', marginTop: '32px', color: 'inherit', fontWeight: '500' }}
        to="/get-started">
        Contact us
      </Link>
      <div
        className="disclaimer font-400"
        style={{
          marginTop: '48px',
          fontSize: '12px',
          opacity: '0.5',
        }}>
        <Disclaimer shouldApplyMarginTop={false} />
      </div>
      <VerticalBreak height={30} />
    </div>
  )
}
