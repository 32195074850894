import { ChevronDownIcon, ChevronUpIcon } from '@radix-ui/react-icons'
import { usePluralNavigation } from 'providers/NavigationProvider'
import { ReactNode, useState } from 'react'

export type SidenavItemType = {
  title: string
  icon: ReactNode
  href: string
  children?: Omit<SidenavItemType, 'icon' | 'children'>[]
  active?: boolean
  loading?: boolean
}

export default function Sidenav(props: { items: SidenavItemType[]; width?: number }) {
  return (
    <div className="flex flex-col pl-3 pt-3" style={{}}>
      {props.items.map((item, i) => (
        <SidenavItem item={item} key={`${item.href}-${i}`} />
      ))}
    </div>
  )
}

function SidenavItem({ item }: { item: SidenavItemType }) {
  let { navigate } = usePluralNavigation()
  const [isExpanded, setIsExpanded] = useState(true)

  return (
    <div className={`flex flex-col gap-2 px-1 `}>
      <div
        className={`flex w-full flex-1 cursor-pointer flex-row items-center gap-2 rounded p-1 py-2  hover:bg-gray-300 ${item.active ? 'bg-gray-400' : ''}`}
        onClick={() => {
          item.children?.length && item.children.length > 0 ? setIsExpanded(!isExpanded) : navigate(item.href)
        }}>
        <div>{item.icon}</div>
        <div className="font-gray-500 w-full text-sm">{item.title}</div>
        {item.loading ? (
          <div className="mr-1">
            <ChevronDownIcon />
            {/* <IonItem>
              <IonSpinner name="lines-sharp" style={{ width: '15px', height: '15px' }} />
            </IonItem> */}
          </div>
        ) : item.children?.length && item.children.length > 0 ? (
          <div className="mr-1">{isExpanded ? <ChevronUpIcon /> : <ChevronDownIcon />}</div>
        ) : (
          <> </>
        )}
      </div>

      {isExpanded && (
        <div className="flex w-full flex-row gap-3">
          <div className="h-[calc(100% - 5px)] ml-2 w-[5px] rounded bg-gray-400"></div>
          <div className="flex flex-col gap-1">
            {item.children?.map((child) => (
              <div
                key={child.href}
                className={`flex w-full flex-1 cursor-pointer flex-row items-center gap-2 rounded p-1 hover:bg-gray-300 ${child.active ? 'bg-gray-300' : ''}`}
                onClick={() => {
                  navigate(child.href)
                }}>
                <div className="font-gray-500 w-full text-sm">{child.title}</div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}
