import LandingFooter from 'landingPage/LandingFooter'
import Team from 'landingPage/Team'

export default function TeamPage() {
  return (
    <div className="mb-24 mt-24">
      <Team />
      <div className="mt-48 flex w-full flex-col items-center bg-black">
        <LandingFooter />
      </div>
    </div>
  )
}
