export type StateObject<T> = {
  value: T;
  setValue: (value: T) => void;
};
export function makeStateObject<T>(
  value: [T, (value: T) => void]
): StateObject<T> {
  return {
    value: value[0],
    setValue: value[1],
  };
}
