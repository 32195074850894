import { z } from 'zod'
export const UpdateMassiveFundingRoundDataSchema = z.object({
  id: z.string().uuid(),
  companyId: z.string().uuid().optional(),
  companyName: z.string().optional(),
  financingType: z.string().optional(),
  financingSubType: z.string().optional(),
  impliedEnterpriseValue: z.string().optional(),
  series: z.string().optional(),
  shareClass: z.string().optional(),
  announcedDate: z.string().optional(),
  announcedShareClass: z.string().optional(),
  announcedTotal: z.string().optional(),
  announcedPostMoneyValuation: z.string().optional(),
  announcedImpliedEnterpriseValue: z.string().optional(),
  estimatedShareCount: z.number().optional(),
  closedDate: z.string().optional(),
  closedTotal: z.string().optional(),
  pricePerShare: z.number().optional(),
  postMoneyValuation: z.string().optional(),
  authorizedShares: z.number().optional(),
  outstandingShares: z.number().optional(),
  ownershipPercent: z.number().optional(),
  seniority: z.string().optional(),
  liquidationOrder: z.number().optional(),
  liquidationPrice: z.number().optional(),
  participatingPreferred: z.boolean().optional(),
  conversionRatio: z.number().optional(),
  conversionPrice: z.number().optional(),
  dividendPercent: z.number().optional(),
  votingCount: z.number().optional(),
  sourcesToAdd: z.array(z.string()).optional(),
  sourcesToRemove: z.array(z.string()).optional(),
  hidden: z.boolean().optional(),
  adminEmail: z.string(), // TODO: not secure, upgrade to JWT, OAuth, etc.
})

export type UpdateMassiveFundingRoundData = z.infer<typeof UpdateMassiveFundingRoundDataSchema>

export const allFundingRoundTypes = [
  'series a',
  'series a extension',
  'series a extension 2',
  'series b',
  'series b extension',
  'series b extension 2',
  'series c',
  'series c extension',
  'series c extension 2',
  'series d',
  'series d extension',
  'series d extension 2',
  'series e',
  'series e extension',
  'series e extension 2',
  'series f',
  'series f extension',
  'series f extension 2',
  'series g',
  'series g extension',
  'series g extension 2',
  'series h',
  'series h extension',
  'series h extension 2',
  'series i',
  'series i extension',
  'series i extension 2',
  'series j',
  'series j extension',
  'series j extension 2',
  'series k',
  'series k extension',
  'series k extension 2',
  'series l',
  'series l extension',
  'series l extension 2',
  'series m',
  'series m extension',
  'series m extension 2',
  'series n',
  'series n extension',
  'series n extension 2',
  'venture - series unknown',
  'series',
  'seed',
  'seed extension',
  'seed extension 2',
  'secondary/tender',
  'debt facility',
  'rights issue',
  'product crowdfunding',
  'private equity',
  'pre-seed',
  'pre-seed extension',
  'pre-seed extension 2',
  'pre-b',
  'post-ipo secondary',
  'post-ipo equity',
  'post-ipo debt',
  'other financing',
  'non-equity assistance',
  'n/a',
  'ipo',
  'initial coin offering',
  'growth',
  'growth 2',
  'growth 3',
  'growth 4',
  'growth 5',
  'growth 6',
  'growth 7',
  'grant',
  'funding round',
  'equity crowdfunding',
  'equity and debt',
  'equity',
  'debt financing - term loan',
  'debt financing',
  'corporate round',
  'convertible note',
  'angel',
  'internal 409a',
]
export type RawMasterFundingRound = {
  id: string
  companyId: string
  companyName?: string
  financingType?: string
  financingSubType?: string
  impliedEnterpriseValue?: string
  sources?: string
  investors: any
  lead?: boolean
  estimatedAmount?: number
  partner?: string
  shareClass?: string
  announcedDate?: string
  series?: string
  announcedShareClass?: string
  announcedTotal?: string
  announcedPostMoneyValuation?: string
  announcedImpliedEnterpriseValue?: string
  closedDate?: string
  closedTotal?: string
  pricePerShare?: number
  postMoneyValuation?: string
  authorizedShares?: number
  outstandingShares?: number
  ownershipPercent?: number
  seniority?: string
  liquidationOrder?: number
  liquidationPrice?: number
  participatingPreferred?: boolean
  conversionRatio?: number
  conversionPrice?: number
  dividendPercent?: number
  references?: any
  subRows?: RawMasterFundingRound[]
  hidden?: boolean
  sourceMap: {
    [key: string]: {
      createdAt: string | null
      updatedBy: string | null
      updatedSource: string | null
    }
  }
}

export type MasterFundingRound = Omit<
  RawMasterFundingRound,
  | 'impliedEnterpriseValue'
  | 'announcedTotal'
  | 'announcedPostMoneyValuation'
  | 'announcedImpliedEnterpriseValue'
  | 'closedTotal'
  | 'postMoneyValuation'
  | 'subRows'
> & {
  impliedEnterpriseValue: number | undefined
  announcedTotal: number | undefined
  announcedPostMoneyValuation: number | undefined
  announcedImpliedEnterpriseValue: number | undefined
  closedTotal: number | undefined
  postMoneyValuation: number | undefined
  subRows?: MasterFundingRound[]
}
