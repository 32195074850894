import { SharePriceFromLastRoundCell } from 'components/SharePriceFromLastRoundCell'
import { CalculatedExitScenario, GraphMethodology } from 'pages/valuation-calculator/types'
import {
  dateToReadableTimeFromNow,
  numberToAbbreviatedDollarString,
  numberToAbbreviatedString,
} from 'plural-shared/utils'

export default function ExitScenarioResultsTable({
  scenarios,
  sharePriceFromLastRound,
  lastRoundValuation,
  desiredFundInterest,
  reserveMultiple,
}: {
  scenarios: CalculatedExitScenario[]
  sharePriceFromLastRound?: number
  lastRoundValuation?: number
  desiredFundInterest?: number
  reserveMultiple?: number
}) {
  scenarios = scenarios.sort((a, b) => {
    return b.todayValuation - a.todayValuation
  })

  let headers: string[]
  if (!sharePriceFromLastRound) {
    headers = ['Exit scenario', 'Valuation today']
  } else if (!desiredFundInterest || !reserveMultiple) {
    headers = ['Exit scenario', 'Valuation today', 'Share price today']
  } else {
    headers = ['Exit scenario', 'Valuation today', 'Share price today', 'Shares owed']
  }

  return (
    <div>
      <div>
        {scenarios.length === 0 ? (
          <div className="mt-6">
            <div className="text-sm text-plural-sand-600">Add funding history to simulate valuations.</div>
          </div>
        ) : (
          <div className="mt-6 w-full rounded-lg border border-plural-sand-500">
            {/* Table header */}
            <div className="flex rounded-tl-lg rounded-tr-lg border-b border-plural-sand-500 bg-plural-sand-400 pb-0.5 pl-1 pt-0.5">
              {headers.map((header, idx) => {
                return (
                  <div key={idx} className={`items-left max-w-xxs flex-1 flex-grow p-2 text-sm opacity-100`}>
                    {header}
                  </div>
                )
              })}
            </div>
            {/* Table rows */}
            {scenarios.map((scenario, rowIndex) => (
              <div>
                <ExitScenarioResultsTableRow
                  key={rowIndex}
                  scenario={scenario}
                  headers={headers}
                  scenarioIndex={rowIndex}
                  numScenarios={scenarios.length}
                  sharePriceFromLastRound={sharePriceFromLastRound}
                  lastRoundValuation={lastRoundValuation}
                  desiredFundInterest={desiredFundInterest}
                  reserveMultiple={reserveMultiple}
                />
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}
function ExitScenarioResultsTableRow({
  scenario,
  headers,
  scenarioIndex,
  numScenarios,
  sharePriceFromLastRound,
  lastRoundValuation,
  desiredFundInterest,
  reserveMultiple,
}: {
  scenario: CalculatedExitScenario
  headers: string[]
  scenarioIndex: number
  numScenarios: number
  sharePriceFromLastRound?: number
  lastRoundValuation?: number
  desiredFundInterest?: number
  reserveMultiple?: number
}) {
  let minGrowthRateMethod: GraphMethodology = 'MINIMUM_GROWTH_RATE'
  let isUsingMin = scenario.graphMethodology === minGrowthRateMethod
  const minGrowthText = isUsingMin ? '*' : ''
  function valuationTodayCell() {
    return (
      <div>
        {numberToAbbreviatedDollarString(scenario.todayValuation)}
        <span className="opacity-50">{minGrowthText}</span>
      </div>
    )
  }

  function exitScenarioCell() {
    const dot = (
      <span className="flex h-6 items-center pr-1.5">
        <span
          className="h-2 w-2 rounded-full"
          style={{ backgroundColor: `rgb(${scenario.color.r},${scenario.color.g},${scenario.color.b})` }}
        />
      </span>
    )
    return (
      <div className="flex items-center justify-start">
        {dot}
        <div>
          <span>{numberToAbbreviatedDollarString(scenario.exitScenario.valuation)}</span>
          <span className="opacity-50"> in </span>
          <span className="opacity-50">{dateToReadableTimeFromNow(scenario.exitScenario.date)}</span>
        </div>
      </div>
    )
  }

  function fractionize(num: number): string {
    // Ensure the input is between 0 and 1
    if (num < 0 || num > 1) {
      throw new Error('Input must be a number between 0 and 1')
    }

    // Multiply the input by 12 and round to the nearest whole number
    const fraction = Math.round(num * 12)

    // Return the fraction as a string
    return `${fraction}/12`
  }

  function sharesOwedCell() {
    if (!sharePriceFromLastRound || !lastRoundValuation || !desiredFundInterest || !reserveMultiple) return <div></div>
    // the number from the reserve that they keep
    const sellPrice = (scenario.todayValuation / lastRoundValuation) * sharePriceFromLastRound
    // the number they have to set aside
    const sharesReserved = (desiredFundInterest / sharePriceFromLastRound) * reserveMultiple
    // the number they send to plural
    const sharesOwed = Math.min(desiredFundInterest / sellPrice, sharesReserved)
    const bgColor = 'bg-plural-sand-500'
    const width = `w-${fractionize(sharesOwed / sharesReserved)}`
    return (
      // <div className="w-28">
      <div className="w-26">
        <span>{numberToAbbreviatedString(sharesOwed, 1)}</span>
        <span className="opacity-50">&nbsp;of&nbsp;{numberToAbbreviatedString(sharesReserved, 1)}</span>
        <div className={`mt-0.5 flex h-1 items-center overflow-hidden rounded ${bgColor} ${width} pl-1`}></div>
      </div>
    )
  }

  const rowCells = [exitScenarioCell(), valuationTodayCell()]
  if (sharePriceFromLastRound && lastRoundValuation && rowCells.length === 2) {
    const minSharePrice = () => (reserveMultiple ? sharePriceFromLastRound / reserveMultiple : 0)
    rowCells.splice(
      2,
      0,
      <SharePriceFromLastRoundCell
        sharePriceToday={Math.max(
          (scenario.todayValuation / lastRoundValuation) * sharePriceFromLastRound,
          minSharePrice(),
        )}
        sharePriceFromLastRound={sharePriceFromLastRound}
      />,
    )
  }
  if (
    desiredFundInterest &&
    reserveMultiple &&
    sharePriceFromLastRound &&
    lastRoundValuation &&
    rowCells.length === 3
  ) {
    rowCells.splice(3, 0, sharesOwedCell())
  }

  const borderB = scenarioIndex === numScenarios - 1 ? '' : 'border-b'
  return (
    <div key={scenarioIndex} className={`flex border-plural-sand-500 pb-0.5 pl-1 pt-0.5 text-sm ${borderB}`}>
      {rowCells.map((cell, cellIndex) => {
        return (
          <div key={cellIndex} className={`flex max-w-xxs flex-1 flex-grow items-center justify-start p-2`}>
            {cell}
          </div>
        )
      })}
    </div>
  )
}

// Not sure if I will use this in near future, leaving for now
// const sharesReserved = (desiredFundInterest / sharePriceFromLastRound) * reserveMultiple
// const sharesOwed = Math.min(desiredFundInterest / sharePriceToday, sharesReserved)
// const sharesKept = Math.max(0, sharesReserved - sharesOwed)
// const sharesKeptPrice = sharesKept * sharePriceToday
// const percentOfTotalReserve = 1 - Math.min(sharesOwed / sharesReserved, 1)
// const percentOfTotalReserveStyled = percentOfTotalReserve.toLocaleString(undefined, {
//   style: 'percent',
//   minimumFractionDigits: 0,
// })
