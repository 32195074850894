import Card from 'components/Card'
import { DatePicker } from 'components/DatePicker'
import QuestionTooltip, { QuestionTooltipContent } from 'components/QuestionTooltip'
import { Button, ButtonProps } from 'components/ui/button'
import { Switch } from 'components/ui/switch'
import { Toaster } from 'components/ui/toaster'
import { Toggle } from 'components/ui/toggle'
import { useToast } from 'components/ui/use-toast'
import { format, isPast, max, min } from 'date-fns'
import { ReactComponent as Moon } from 'images/pluralLogos/moon.svg'
import { cn } from 'lib/utils'
import { MoveRight, Share, Trash2 } from 'lucide-react'
import ExitScenarioResultsTable from 'pages/valuation-calculator/ExitScenarioResultsTable'
import LineGraph from 'pages/valuation-calculator/LineGraph'
import { companySampleDataMap, defaultCompanySampleData } from 'pages/valuation-calculator/constants'
import { generateGraphData } from 'pages/valuation-calculator/formulas'
import { useRecalculateCoefficients } from 'pages/valuation-calculator/hooks'
import SampleCompanies, { SampleCompany } from 'pages/valuation-calculator/sample-companies/SampleCompanies'
import {
  CalculatedExitScenario,
  EditingDateValuation,
  ExitColors,
  ExitScenario,
  FundingRound,
  SampleData,
} from 'pages/valuation-calculator/types'
import { valuationResultsFooter } from 'pages/valuation-calculator/valuationResultsFooter'
import { numberToAbbreviatedDollarString } from 'plural-shared/utils'
import { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { v4 as uuid } from 'uuid'

declare global {
  interface Window {
    devCalc?: () => void
    shareUrl?: () => void
  }
}

const filteredDateValuations = (edv: EditingDateValuation[]) => {
  return edv.filter((d) => d.date && d.valuation) as { date: Date; valuation: number }[]
}

const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
})

function ValuationCalculator() {
  const [incorporationDate, setIncorporationDate] = useState<Date | undefined>()
  const [editingFundingRounds, setEditingFundingRounds] = useState<EditingDateValuation[]>([
    { rowId: uuid(), valuation: 0 },
  ])
  const [editingExitScenarios, setEditingExitScenarios] = useState<EditingDateValuation[]>([
    { rowId: uuid(), valuation: 0 },
  ])

  const filteredFundingRounds: FundingRound[] = useMemo(
    () => filteredDateValuations(editingFundingRounds),
    [editingFundingRounds],
  )

  const filteredExitScenarios: ExitScenario[] = useMemo(
    () => filteredDateValuations(editingExitScenarios),
    [editingExitScenarios],
  )

  const [lastRoundSharePrice, setLastRoundSharePrice] = useState<number>(0)
  const [desiredFundInterest, setDesiredFundInterest] = useState<number>(0)
  const [reserveMultiple, setReserveMultiple] = useState<number>(0)
  const [shouldUseTextView, setShouldUseTextView] = useState<boolean>(false)

  const { coefficients } = useRecalculateCoefficients({
    incorporationDate,
    fundingRounds: filteredFundingRounds,
    exitScenarios: filteredExitScenarios,
  })

  const [graphData, setGraphData] = useState<CalculatedExitScenario[]>([])
  useEffect(() => {
    if (!incorporationDate || !filteredExitScenarios.length || !filteredFundingRounds.length) {
      setGraphData([])
      return
    }

    if (coefficients.length !== filteredExitScenarios.length) {
      // These are debounced and async fetched, so wait until we have results.
      // Keep showing the previous graph
      return
    }

    const graphData: CalculatedExitScenario[] = []

    for (const [index, exitScenario] of filteredExitScenarios.entries()) {
      const {
        results,
        todaysValuation: tv,
        graphMethodology,
      } = generateGraphData(
        filteredFundingRounds,
        incorporationDate,
        exitScenario.date,
        exitScenario.valuation,
        coefficients[index],
      )

      graphData.push({
        exitScenario,
        todayValuation: tv,
        data: results,
        color: ExitColors[index],
        graphMethodology,
      })
    }

    setGraphData(graphData)
  }, [filteredFundingRounds, incorporationDate, coefficients, filteredExitScenarios])

  const setSampleData = useCallback((sampleData: SampleData) => {
    setIncorporationDate(sampleData.incorporationDate)
    setEditingExitScenarios(sampleData.exitScenarios.map((es) => ({ rowId: uuid(), ...es })))
    setEditingFundingRounds(sampleData.fundingRounds.map((fr) => ({ rowId: uuid(), ...fr })))
    setLastRoundSharePrice(sampleData.lastRoundSharePrice ?? 0)
    setDesiredFundInterest(sampleData.desiredFundInterest ?? 0)
    setReserveMultiple(sampleData.reserveMultiple ?? 0)
  }, [])

  useEffect(() => {
    // Add a development function on window we can use to set a preset calulcation state.
    window.devCalc = (companyName?: string) => {
      const companySampleData = companySampleDataMap.get(companyName ?? '') || defaultCompanySampleData
      console.log('Setting sample data', companySampleData)
      setSampleData(companySampleData)
    }
  }, [setSampleData])

  const minExitDate: Date | undefined = useMemo((): Date | undefined => {
    const fundingDates = editingFundingRounds.map((round) => round.date)
    const allDates = [incorporationDate, ...fundingDates].filter((date) => !!date) as Date[]

    if (!allDates.length) {
      return undefined
    }

    return max(allDates)
  }, [editingFundingRounds, incorporationDate])

  const minIncorporationDate: Date | undefined = useMemo((): Date | undefined => {
    const fundingDates = editingFundingRounds.map((round) => round.date)
    const exitDates = editingExitScenarios.map((es) => es.date)
    const allDates = [...exitDates, ...fundingDates].filter((date) => !!date) as Date[]

    if (!allDates.length) {
      return undefined
    }

    return min(allDates)
  }, [editingFundingRounds, editingExitScenarios])

  const [searchParams, setSearchParams] = useSearchParams()
  useEffect(() => {
    const s = searchParams.get('s')
    if (!s) {
      return
    }

    const decoded = atob(s)
    try {
      const jsonDecoded = JSON.parse(decoded)

      const { id, fr, es } = jsonDecoded
      // incorporationDate, funding rounds, exit scenarios
      console.log('id: ', id)
      console.log('fr', fr)
      const incorporationDate = new Date(id)
      const fundingRounds = fr.map((fr: any) => ({ date: new Date(fr.d), valuation: fr.v }))
      const exitScenarios = es.map((es: any) => ({ date: new Date(es.d), valuation: es.v }))
      const lastRoundSharePrice: number = jsonDecoded.lrsp ?? 0
      const desiredFundInterest: number = jsonDecoded.dfi ?? 0
      const reserveMultiple: number = jsonDecoded.rm ?? 0

      // localStorage.setItem('companyFinancings', JSON.stringify({}))
      /*
      const companyName = searchParams.get('name')?.toLowerCase() ?? 'default'
      let companyFinancings: any = JSON.parse(localStorage.getItem('companyFinancings') || '{}')
      companyFinancings[companyName] = {
        companyId: companyName,
        funding: {
          companyId: companyName,
          fundingRounds: fundingRounds,
          mostRecentRoundSharePrice: lastRoundSharePrice,
        },
        incorporationDate: id,
      }
      localStorage.setItem('companyFinancings', JSON.stringify(companyFinancings))
      const fundingStr = `${JSON.stringify(companyFinancings, null, 4)}`
      console.log('funding rounds saved\n\n', fundingStr)
      // console.log('companyFinancings\n\n', companyFinancings)
       */

      setIncorporationDate(incorporationDate)
      setEditingFundingRounds(fundingRounds)
      setEditingExitScenarios(exitScenarios)
      setLastRoundSharePrice(lastRoundSharePrice)
      setDesiredFundInterest(desiredFundInterest)
      setReserveMultiple(reserveMultiple)
    } catch (e) {
      console.error(e)
    }

    setSearchParams({}, { replace: true })
  }, [searchParams, setSearchParams])

  const generateShareUrl = useCallback(() => {
    const data = {
      id: incorporationDate?.toISOString(),
      fr: editingFundingRounds.map((fr) => ({ d: fr.date?.toISOString(), v: fr.valuation })),
      es: editingExitScenarios.map((es) => ({ d: es.date?.toISOString(), v: es.valuation })),
      lrsp: lastRoundSharePrice,
      dfi: desiredFundInterest,
      rm: reserveMultiple,
    }

    const encoded = btoa(JSON.stringify(data))

    const url = new URL(window.location.href)
    url.searchParams.set('s', encoded)

    return url.href
  }, [
    desiredFundInterest,
    editingExitScenarios,
    editingFundingRounds,
    incorporationDate,
    lastRoundSharePrice,
    reserveMultiple,
  ])

  useEffect(() => {
    window.shareUrl = () => {
      return generateShareUrl()
    }
  }, [generateShareUrl])

  const { toast } = useToast()

  const lastFundingRoundValuation: number | undefined = useMemo(() => {
    let sorted = filteredFundingRounds.sort((a, b) => a.date.getTime() - b.date.getTime())
    return sorted[sorted.length - 1]?.valuation
  }, [filteredFundingRounds])

  const onSelectSampleCompany = (company: SampleCompany) => {
    setSampleData(company.sampleData)
  }

  return (
    <div className="select-none pt-4">
      <div className="relative mx-auto mt-12 w-full max-w-6xl px-12 pb-10">
        <div className="mb-6 flex items-center justify-between">
          <div>
            <h1 className="text-2xl font-semibold text-neutral-800">Valuation Calculator</h1>
          </div>

          <a
            className="hidden w-56 items-center rounded-lg bg-func-bg-sand-md p-3 text-xs hover:bg-func-bg-sand-dk md:flex"
            href="https://docs.google.com/document/d/1jhux8jTZ4nP2dwr4evCXHBnCMoopGm1a7yFXuEL1u9Q/view"
            target="_blank"
            rel="noreferrer">
            <Moon className="flex-none" style={{ height: '40px', width: '40px' }} />
            <span className="pl-4 pr-2">See the full formula here</span>
            <MoveRight className="flex-none" size="32px" strokeWidth={1.5} />
          </a>
        </div>

        <h6 className="text-sm font-medium text-neutral-800">Valuing startups is hard. Plural makes it easy.</h6>
        <p className="mb-4 text-sm text-neutral-800">
          Instead of trying to value your startup today, we wait until it *actually* exits. Then, we use a lookback
          formula to determine the valuation when you joined.
        </p>

        <h6 className="text-sm font-medium text-neutral-800">How's the lookback calculated?</h6>
        <p className="mb-4 text-sm text-neutral-800">
          Plural uses your start date and funding history to calculate a best-fit valuation curve,{' '}
          <span className="text-blue-500">
            <em>P = a</em> exp(<em>bt&#8201;</em>){/* Add thin space to visually spread out the paren */}
          </span>
          , based on your exit.
        </p>

        <p className="mb-4 text-sm text-neutral-800">Give it a try below. Or see a few examples first:</p>

        <SampleCompanies onSelect={onSelectSampleCompany} />

        <div className="flex flex-col items-start gap-8 md:flex-row">
          <div className="flex w-full flex-none flex-col gap-6 md:w-96">
            <Card>
              <div className="mb-4 flex justify-between">
                <SectionTitle>Company Start Date</SectionTitle>
                <QuestionTooltip>
                  <QuestionTooltipContent>
                    <h6 className="mb-3 font-semibold">Don't know the start date?</h6>
                    <p className="mt-3">
                      You can look it up{' '}
                      <a
                        href="https://icis.corp.delaware.gov/eCorp/EntitySearch/NameSearch.aspx"
                        target="_blank"
                        rel="noreferrer"
                        className="text-blue-500">
                        here
                      </a>
                    </p>
                  </QuestionTooltipContent>
                </QuestionTooltip>
              </div>

              <DatePicker date={incorporationDate} setDate={setIncorporationDate} maxDate={minIncorporationDate} />
              <label className="mb-2 text-xs font-light uppercase text-plural-sand-600">Date</label>
            </Card>

            <Card>
              <div className="mb-4 flex justify-between">
                <SectionTitle>Funding History</SectionTitle>
                <QuestionTooltip>
                  <QuestionTooltipContent>
                    <h6 className="mb-3 font-semibold">Don't know the date of a round?</h6>
                    <p className="my-3">
                      Try a reasonable estimate. Plural will do the same if it's unable to find the exact date for the
                      final calculation.
                    </p>

                    <h6 className="my-3 font-semibold">Dont know the valuation?</h6>

                    <p className="mt-3">
                      Try multiplying the amount raised multiple by 5. And if it's unknown, try making a reasonable
                      guess. Plural will do the same if it's unable to find the exact date for the final calculation.
                    </p>
                  </QuestionTooltipContent>
                </QuestionTooltip>
              </div>

              <FundingRoundsList fundingRounds={editingFundingRounds} setFundingRounds={setEditingFundingRounds} />
            </Card>

            <Card>
              <div className="mb-4 flex justify-between">
                <SectionTitle>Exit Scenario</SectionTitle>
                <QuestionTooltip>
                  <QuestionTooltipContent>
                    <h6 className="mb-3 font-semibold">What counts as an exit?</h6>

                    <p className="my-3">
                      Plural defines "exit" as the time when shares, or their cash or other equivalent, become
                      transferable or saleable.
                    </p>
                  </QuestionTooltipContent>
                </QuestionTooltip>
              </div>

              <ExitScenariosList
                exitScenarios={editingExitScenarios}
                setExitScenarios={setEditingExitScenarios}
                minExitDate={minExitDate}
              />
            </Card>

            <Card>
              <div className="mb-4 flex justify-between">
                <SectionTitle>Optional fields</SectionTitle>
                <QuestionTooltip>
                  <QuestionTooltipContent>
                    <h6 className="mb-3 font-semibold">Is this the preferred price or 409a?</h6>

                    <p className="my-3">
                      Use the preferred price if possible, but feel free to make a best guess based on your 409a.
                    </p>
                  </QuestionTooltipContent>
                </QuestionTooltip>
              </div>

              <div className="flex flex-col gap-4">
                <NumberInput
                  value={lastRoundSharePrice}
                  onChange={setLastRoundSharePrice}
                  title="Price per share of last round"
                  isDollar
                />

                <NumberInput
                  value={desiredFundInterest}
                  onChange={setDesiredFundInterest}
                  title="Desired value today"
                  isDollar
                />

                <NumberInput
                  value={reserveMultiple}
                  onChange={setReserveMultiple}
                  title={`Reserve multiple (e.g. "3.4" for a 3.4x multiple)`}
                />

                <div>
                  <div className="flex h-6 w-32 flex-row items-center border-b border-plural-sand-300">
                    {!!desiredFundInterest && !!lastRoundSharePrice && !!reserveMultiple && (
                      <span className="mt-[0.5px] pl-2 pr-0.5 text-xs font-semibold">
                        {((desiredFundInterest / lastRoundSharePrice) * reserveMultiple).toLocaleString(undefined, {
                          maximumFractionDigits: 2,
                        })}
                      </span>
                    )}
                  </div>
                  <label className="mb-2 text-xs font-light uppercase text-plural-sand-600">
                    Shares reserved (based on last preferred)
                  </label>
                </div>
              </div>
            </Card>
          </div>

          <div
            className={cn(
              'w-full flex-1 overflow-hidden transition-opacity',
              graphData.length > 0 && !coefficients.length && 'opacity-40',
            )}>
            <Card className="mb-4 flex min-h-[200px] items-center justify-center">
              {graphData.length > 0 && <LineGraph scenarios={graphData} fundingRounds={filteredFundingRounds} />}
              {graphData.length === 0 && (
                <h3 className="text-lg font-light uppercase text-plural-sand-600">Please add funding history</h3>
              )}
            </Card>

            <div className="mt-9">
              <div style={{ color: 'black', fontWeight: 500 }}>Company valuation in exit scenarios</div>
              {shouldUseTextView ? (
                <TodaysValuationsList
                  scenarios={graphData}
                  lastRoundSharePrice={lastRoundSharePrice}
                  lastRoundValuation={lastFundingRoundValuation}
                  desiredFundInterest={desiredFundInterest}
                  reserveMultiple={reserveMultiple}
                />
              ) : (
                <div>
                  <ExitScenarioResultsTable
                    scenarios={graphData}
                    sharePriceFromLastRound={lastRoundSharePrice}
                    lastRoundValuation={lastFundingRoundValuation}
                    desiredFundInterest={desiredFundInterest}
                    reserveMultiple={reserveMultiple}
                  />
                </div>
              )}
            </div>
            <div className="mt-6 flex flex-row items-center justify-center gap-4">
              {graphData.length > 0 && (
                <Button
                  variant="outline"
                  onClick={() => {
                    navigator.clipboard.writeText(generateShareUrl())
                    toast({
                      description: 'Share link copied to clipboard!',
                    })
                  }}>
                  <Share className="mr-2 h-4 w-4" /> Copy Share Link
                </Button>
              )}
            </div>
            {valuationResultsFooter}
            <div className="mt-6">
              <Switch
                leftLabel={'Table mode'}
                rightLabel={'Text mode'}
                isRight={shouldUseTextView}
                setIsRight={(isRight) => setShouldUseTextView(isRight)}
              />
            </div>
          </div>
        </div>
      </div>

      <Toaster />
    </div>
  )
}

function SectionTitle({
  children,
  ...props
}: React.DetailedHTMLProps<React.HTMLAttributes<HTMLHeadingElement>, HTMLHeadingElement>) {
  return (
    <h2 className="text-xs font-semibold uppercase text-plural-sand-600" {...props}>
      {children}
    </h2>
  )
}

function FundingRoundsList({
  fundingRounds,
  setFundingRounds,
}: {
  fundingRounds: EditingDateValuation[]
  setFundingRounds: React.Dispatch<React.SetStateAction<EditingDateValuation[]>>
}) {
  const onInputChange = useCallback(
    (index: number, value: number) => {
      const newFundingRounds = [...fundingRounds]
      newFundingRounds[index].valuation = value
      setFundingRounds(newFundingRounds)
    },
    [fundingRounds, setFundingRounds],
  )

  const onSetDate = useCallback(
    (index: number, date?: Date) => {
      const newFundingRounds = [...fundingRounds]
      newFundingRounds[index].date = date
      setFundingRounds(newFundingRounds)
    },
    [fundingRounds, setFundingRounds],
  )

  const onDelete = useCallback(
    (index: number) => {
      if (fundingRounds.length === 1) {
        setFundingRounds([{ rowId: uuid(), valuation: 0 }])
        return
      }

      const newFundingRounds = [...fundingRounds]
      newFundingRounds.splice(index, 1)
      setFundingRounds(newFundingRounds)
    },
    [fundingRounds, setFundingRounds],
  )

  return (
    <>
      <div className="flex flex-col gap-4">
        {fundingRounds.map((fundingRound, index) => (
          <FundingRoundRow
            key={fundingRound.rowId}
            fundingRound={fundingRound}
            index={index}
            onInputChange={onInputChange}
            onSetDate={onSetDate}
            onDelete={onDelete}
            deleteDisabled={fundingRounds.length === 1}
          />
        ))}
      </div>

      <button
        className="mt-6 text-xs font-semibold uppercase text-black"
        onClick={() => setFundingRounds([...fundingRounds, { rowId: uuid(), valuation: 0 }])}>
        + Add round
      </button>
    </>
  )
}

function FundingRoundRow({
  fundingRound,
  index,
  onInputChange,
  onSetDate,
  deleteDisabled,
  onDelete,
}: {
  fundingRound: EditingDateValuation
  index: number
  onInputChange: (index: number, value: number) => void
  onSetDate: (index: number, date?: Date) => void
  deleteDisabled: boolean
  onDelete: (index: number) => void
}) {
  const setDate = useCallback((d?: Date) => onSetDate(index, d), [index, onSetDate])
  const onValuationInputChange = useCallback((v: number) => onInputChange(index, v), [index, onInputChange])

  return (
    <div className="flex flex-row gap-2">
      <span className="mt-0.5 text-sm font-medium text-plural-sand-700">{index + 1}.</span>

      <div className="w-0 flex-1">
        <DatePicker date={fundingRound.date} setDate={setDate} />
        <label className="font-regular mb-2 text-xs uppercase text-plural-sand-600">Date</label>
      </div>

      <div className="w-0 flex-1">
        <ValuationInput value={fundingRound.valuation} onChange={onValuationInputChange} />
      </div>

      <DeleteButton onClick={() => onDelete(index)} />
    </div>
  )
}

function ExitScenariosList({
  exitScenarios,
  setExitScenarios,
  minExitDate,
}: {
  exitScenarios: EditingDateValuation[]
  setExitScenarios: React.Dispatch<React.SetStateAction<EditingDateValuation[]>>
  minExitDate?: Date
}) {
  const onInputChange = useCallback(
    (index: number, value: number) => {
      const newExitScenarios = [...exitScenarios]
      newExitScenarios[index].valuation = value
      setExitScenarios(newExitScenarios)
    },
    [exitScenarios, setExitScenarios],
  )

  const onSetDate = useCallback(
    (index: number, date?: Date) => {
      const newExitScenarios = [...exitScenarios]
      newExitScenarios[index].date = date
      setExitScenarios(newExitScenarios)
    },
    [exitScenarios, setExitScenarios],
  )

  const onDelete = useCallback(
    (index: number) => {
      if (exitScenarios.length === 1) {
        setExitScenarios([{ rowId: uuid(), valuation: 0 }])
        return
      }

      const newExitScenarios = [...exitScenarios]
      newExitScenarios.splice(index, 1)
      setExitScenarios(newExitScenarios)
    },
    [exitScenarios, setExitScenarios],
  )

  return (
    <>
      <div className="flex flex-col gap-4">
        {exitScenarios.map((es, index) => (
          <ExitScenarioRow
            key={es.rowId}
            exitScenario={es}
            index={index}
            onInputChange={onInputChange}
            onSetDate={onSetDate}
            onDelete={onDelete}
            deleteDisabled={exitScenarios.length === 1}
            minExitDate={minExitDate}
          />
        ))}
      </div>

      <button
        disabled={exitScenarios.length === ExitColors.length}
        className="mt-6 text-xs font-semibold uppercase text-black disabled:opacity-50"
        onClick={() => setExitScenarios([...exitScenarios, { rowId: uuid(), valuation: 0 }])}>
        + Add exit scenario
      </button>
    </>
  )
}

function ExitScenarioRow({
  exitScenario,
  index,
  onInputChange,
  onSetDate,
  deleteDisabled,
  onDelete,
  minExitDate,
}: {
  exitScenario: EditingDateValuation
  index: number
  onInputChange: (index: number, value: number) => void
  onSetDate: (index: number, date?: Date) => void
  deleteDisabled: boolean
  onDelete: (index: number) => void
  minExitDate?: Date
}) {
  const setDate = useCallback((d?: Date) => onSetDate(index, d), [index, onSetDate])
  const onValuationInputChange = useCallback((v: number) => onInputChange(index, v), [index, onInputChange])

  const color = ExitColors[index]

  return (
    <div className="flex flex-row gap-2">
      <div className="flex h-6 flex-none items-center">
        <span className="h-2 w-2 rounded-full" style={{ backgroundColor: `rgb(${color.r},${color.g},${color.b})` }} />
      </div>

      <div className="w-0 flex-1">
        <DatePicker date={exitScenario.date} setDate={setDate} minDate={minExitDate} />
        <label className="mb-2 text-xs font-light uppercase text-plural-sand-600">Date</label>
      </div>

      <div className="w-0 flex-1">
        <ValuationInput value={exitScenario.valuation} onChange={onValuationInputChange} />
      </div>

      <DeleteButton onClick={() => onDelete(index)} />
    </div>
  )
}

enum DollarDenomination {
  Ones = 'O', // Not visible in the UI
  Millions = 'M',
  Billions = 'B',
}

const useNumberInput = (value: number, onChange: (value: number) => void) => {
  const [rawStringValue, setRawStringValue] = useState<string>('')

  const [cursor, setCursor] = useState<number | null>(null)
  const ref = useRef<HTMLInputElement | null>(null)

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setCursor(e.target.selectionStart)

    const stringValue = e.target.value.replace(/[^0-9.,]/g, '')

    const hasDecimal = stringValue.includes('.')
    const wholePart = stringValue.split('.')[0]
    const decimalPart = stringValue.split('.')[1]

    const wholePartInt = parseInt(wholePart.replace(/,/g, ''))
    if (Number.isNaN(wholePartInt)) {
      setRawStringValue('')
      return
    }

    const wholeWithCommas = wholePartInt.toLocaleString()
    const cleanedDecimalPart = decimalPart?.replace(/[^0-9]/g, '')

    const updated = wholeWithCommas + (hasDecimal ? '.' : '') + (hasDecimal && decimalPart ? cleanedDecimalPart : '')

    setRawStringValue(updated)
    if (updated.length > e.target.value.length && e.target.selectionStart) {
      setCursor(e.target.selectionStart + 1)
    }
  }

  useEffect(() => {
    const input = ref.current
    if (input) {
      input.setSelectionRange(cursor, cursor)
    }
  }, [ref, cursor, rawStringValue])

  const onFinishInput = (rawStringValue: string, denomination: DollarDenomination) => {
    if (!rawStringValue) {
      onChange(0)
      return
    }

    const cleanValue = rawStringValue.replace(/[^0-9.]/g, '')
    const floatValue = parseFloat(cleanValue)
    if (Number.isNaN(floatValue)) {
      onChange(0)
      return
    }

    const decimal =
      denomination === DollarDenomination.Ones
        ? 1.0
        : denomination === DollarDenomination.Millions
          ? 1_000_000
          : 1_000_000_000
    onChange(floatValue * decimal)
  }

  const onInputKeydown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      ref.current?.blur()
    }
  }

  return {
    ref,
    rawStringValue,
    setRawStringValue,
    onInputChange,
    onInputKeydown,
    onFinishInput,
  }
}

function ValuationInput({ value, onChange }: { value: number; onChange: (value: number) => void }) {
  const [denomination, setDenomination] = useState<DollarDenomination>(DollarDenomination.Millions)

  const { rawStringValue, setRawStringValue, onInputChange, onInputKeydown, onFinishInput, ref } = useNumberInput(
    value,
    onChange,
  )

  useEffect(() => {
    if (!value) {
      return
    }

    const decimal = denomination === DollarDenomination.Millions ? 1_000_000 : 1_000_000_000
    const raw = (value / decimal).toLocaleString()
    setRawStringValue(raw)
  }, [denomination, setRawStringValue, value])

  const onDenominationChange = (to: DollarDenomination) => {
    if (denomination === to) {
      return
    }

    setDenomination(to)
    onFinishInput(rawStringValue, to)
  }

  return (
    <div>
      <div className="flex h-6 w-full flex-row items-center rounded-lg border border-plural-sand-300 bg-plural-sand-100 ring-offset-background focus-within:border-black hover:bg-plural-sand-200 focus-visible:outline-none">
        <span className="mt-[0.5px] pl-2 pr-0.5 text-xs font-semibold">$</span>

        <input
          className="h-full w-0 flex-1 bg-transparent text-xs font-medium focus:outline-none"
          value={rawStringValue}
          onChange={onInputChange}
          onKeyDown={onInputKeydown}
          onBlur={() => onFinishInput(rawStringValue, denomination)}
          ref={ref}
          prefix="$"
        />

        <div className="flex h-full flex-row items-center py-0.5 pr-1">
          <Toggle
            size="sm"
            variant="text"
            className="h-full w-0 px-3"
            pressed={denomination === DollarDenomination.Millions}
            onPressedChange={() => onDenominationChange(DollarDenomination.Millions)}>
            {DollarDenomination.Millions}
          </Toggle>
          <Toggle
            size="sm"
            variant="text"
            className="h-full w-0 px-3"
            pressed={denomination === DollarDenomination.Billions}
            onPressedChange={() => onDenominationChange(DollarDenomination.Billions)}>
            {DollarDenomination.Billions}
          </Toggle>
        </div>
      </div>
      <label className="mb-2 text-xs font-light uppercase text-plural-sand-600">Valuation</label>
    </div>
  )
}

function NumberInput({
  value,
  onChange,
  title,
  isDollar,
}: {
  value: number
  onChange: (value: number) => void
  title: React.ReactNode
  isDollar?: boolean
}) {
  const { rawStringValue, setRawStringValue, onInputChange, onInputKeydown, onFinishInput, ref } = useNumberInput(
    value,
    onChange,
  )

  useEffect(() => {
    if (!value) {
      setRawStringValue('')
      return
    }

    const format: Intl.NumberFormatOptions = isDollar ? { minimumFractionDigits: 2, maximumFractionDigits: 2 } : {}
    const raw = value.toLocaleString(undefined, format)
    setRawStringValue(raw)
  }, [isDollar, setRawStringValue, value])

  return (
    <div>
      <div className="flex h-6 w-32 flex-row items-center rounded-lg border border-plural-sand-300 bg-plural-sand-100 ring-offset-background focus-within:border-black hover:bg-plural-sand-200 focus-visible:outline-none">
        <span className="mt-[0.5px] pl-2 pr-0.5 text-xs font-medium">{isDollar ? `$` : ' '}</span>

        <input
          className="h-full  w-0 flex-1 bg-transparent text-xs focus:outline-none"
          value={rawStringValue}
          onChange={onInputChange}
          onKeyDown={onInputKeydown}
          onBlur={() => onFinishInput(rawStringValue, DollarDenomination.Ones)}
          ref={ref}
          prefix={isDollar ? '$' : undefined}
        />
      </div>
      <label className="mb-2 text-xs font-light uppercase text-plural-sand-600">{title}</label>
    </div>
  )
}

function TodaysValuationsList({
  scenarios,
  lastRoundSharePrice,
  lastRoundValuation,
  desiredFundInterest,
  reserveMultiple,
}: {
  scenarios: CalculatedExitScenario[]
  lastRoundSharePrice?: number
  lastRoundValuation?: number
  desiredFundInterest?: number
  reserveMultiple?: number
}) {
  if (!scenarios.length) {
    return <p className="mt-7 text-sm"></p>
  }

  return (
    <>
      <div className="mt-6 flex flex-col gap-4">
        {scenarios.map((s, index) => (
          <TodayValuationScenarioText
            key={index}
            scenario={s}
            lastRoundSharePrice={lastRoundSharePrice}
            lastRoundValuation={lastRoundValuation}
            desiredFundInterest={desiredFundInterest}
            reserveMultiple={reserveMultiple}
          />
        ))}
      </div>
    </>
  )
}

const currencyOnesFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
})

function TodayValuationScenarioText({
  scenario,
  lastRoundSharePrice,
  lastRoundValuation,
  desiredFundInterest,
  reserveMultiple,
}: {
  scenario: CalculatedExitScenario
  lastRoundSharePrice?: number
  lastRoundValuation?: number
  desiredFundInterest?: number
  reserveMultiple?: number
}) {
  const rgbColor = `rgb(${scenario.color.r},${scenario.color.g},${scenario.color.b})`

  const fundInterestText = (sellPrice: number) => {
    if (!desiredFundInterest || !reserveMultiple || !lastRoundSharePrice) {
      return null
    }

    const sharesReserved = (desiredFundInterest / lastRoundSharePrice) * reserveMultiple
    const sharesOwed = Math.min(desiredFundInterest / sellPrice, sharesReserved)
    const sharesKept = Math.max(0, sharesReserved - sharesOwed)
    const sharesKeptPrice = sharesKept * sellPrice
    const percentOfTotalReserve = 1 - Math.min(sharesOwed / sharesReserved, 1)
    const percentOfTotalReserveStyled = percentOfTotalReserve.toLocaleString(undefined, {
      style: 'percent',
      minimumFractionDigits: 0,
    })

    return (
      <span>
        , so you'd owe{' '}
        <span style={{ color: rgbColor }}>
          {sharesOwed.toLocaleString(undefined, {
            maximumFractionDigits: 0,
          })}
        </span>{' '}
        shares for the <span style={{ color: rgbColor }}>{currencyFormatter.format(desiredFundInterest)}</span> fund
        interest you received on <span style={{ color: rgbColor }}>{format(new Date(), 'MM/dd/yyyy')}</span> and you'd
        keep{' '}
        <span style={{ color: rgbColor }}>
          {sharesKept.toLocaleString(undefined, {
            maximumFractionDigits: 0,
          })}
        </span>{' '}
        shares from the reserve, worth{' '}
        <span style={{ color: rgbColor }}>{currencyFormatter.format(sharesKeptPrice)}</span> at exit (
        <span style={{ color: rgbColor }}>{percentOfTotalReserveStyled}</span> of the total reserve)
      </span>
    )
  }

  const sharePriceText = () => {
    if (!lastRoundSharePrice || !lastRoundValuation) {
      return null
    }

    const minSharePrice = () => (reserveMultiple ? lastRoundSharePrice / reserveMultiple : 0)
    const sellPrice = Math.max((scenario.todayValuation / lastRoundValuation) * lastRoundSharePrice, minSharePrice())
    const exitPremium = (Math.abs(sellPrice - lastRoundSharePrice) / lastRoundSharePrice).toLocaleString(undefined, {
      style: 'percent',
      minimumFractionDigits: 0,
    })

    return (
      <span>
        This would value your shares at{' '}
        <span style={{ color: rgbColor }}>{currencyOnesFormatter.format(sellPrice)}</span> today (a{' '}
        <span style={{ color: rgbColor }}>{exitPremium}</span>{' '}
        {sellPrice > lastRoundSharePrice ? 'premium' : 'discount'} to the last round price){fundInterestText(sellPrice)}
        .
      </span>
    )
  }

  return (
    <p className="text-sm">
      Your company's valuation would be{' '}
      <span style={{ color: rgbColor }}>{numberToAbbreviatedDollarString(scenario.todayValuation)}</span> today if it
      exited for{' '}
      <span style={{ color: rgbColor }}>{numberToAbbreviatedDollarString(scenario.exitScenario.valuation)}</span> on{' '}
      <span style={{ color: rgbColor }}>{format(scenario.exitScenario.date, 'MM/dd/yyyy')}</span>. {sharePriceText()}
      {isPast(scenario.exitScenario.date) && (
        <span>
          <br /> 🚩Note 🚩 this implies an exit scenario that occurred before the present date.
        </span>
      )}
      {scenario.graphMethodology === 'MINIMUM_GROWTH_RATE' && (
        <span>
          <br /> 🚩Note 🚩 This scenario triggered the 20% minimum annual growth rate line.
        </span>
      )}
    </p>
  )
}

function DeleteButton(props: ButtonProps) {
  return (
    <Button size="icon" variant="outline" className="h-6 w-6 px-1 text-plural-sand-600" {...props}>
      <Trash2 size="14px" />
    </Button>
  )
}

export default ValuationCalculator
