import { Select, Slider } from '@radix-ui/themes'
import { numberToAbbreviatedDollarString } from 'plural-shared/utils'
import { defaultPossibleOutcomeMultiples, useSimulatorContext } from './CashSimulatorContext'
export const possibleSliderYears = [2, 3, 4, 5, 6, 7, 8, 10]

function findClosestMultipleIndex(multiple: number, multiples: number[]) {
  let closestIndex = 0
  let closestDistance = 10000
  multiples.forEach((possibleMultiple, index) => {
    const distance = Math.abs(possibleMultiple - multiple)
    if (distance < closestDistance) {
      closestDistance = distance
      closestIndex = index
    }
  })
  return closestIndex
}
function SliderIsland() {
  const context = useSimulatorContext()
  const { txInputs } = context ?? {}
  if (!context) {
    return null
  }

  function YearsSelect() {
    if (!txInputs) {
      return null
    }
    return (
      <div className="flex w-full flex-col items-end">
        <div className="mb-1 text-sm opacity-60">Years in future</div>
        <Select.Root
          value={String(txInputs.yearsToOutcome.value)}
          onValueChange={(newValue) => {
            const selectedYear = possibleSliderYears.find((option) => String(option) === newValue)
            txInputs.yearsToOutcome.setValue(selectedYear ?? 0)
          }}
          size="2">
          <Select.Trigger radius="full" />
          <Select.Content>
            {possibleSliderYears.map((option) => (
              <Select.Item value={String(option)} key={option}>
                {option}
              </Select.Item>
            ))}
          </Select.Content>
        </Select.Root>
      </div>
    )
  }

  function onMultiplesSliderChange(newValueArr: number[]) {
    if (newValueArr.length !== 1) {
      console.error('error: more than one value in slider')
      return
    }
    context?.multiplesSlider.setActiveMultipleIndex(newValueArr[0])
  }

  const multiplesSliderProps = context.multiplesSlider
  const breakevenPercent = context.breakenvenMultipleIndex
    ? ((context.breakenvenMultipleIndex / (multiplesSliderProps.selectableMultiples.length - 1)) * 100).toFixed(2)
    : undefined

  const yearsToOutcome = context?.txInputs.yearsToOutcome.value

  return (
    <div className="pointer-events-auto w-full max-w-[538px] rounded-2xl border bg-white pb-4 pl-4 pr-4 pt-4 shadow-2xl">
      <div className="sliders w-full max-w-xl">
        <div className="w-full flex-grow">
          <div className="flex justify-between">
            <div className="mb-4 w-full text-base">
              <div className="text-sm opacity-60">Valuation in {yearsToOutcome} yrs</div>
              <div className="mt-[2px] text-2xl font-medium">
                {context.multiplesSlider.activeCompanyValuation
                  ? numberToAbbreviatedDollarString(context.multiplesSlider.activeCompanyValuation, 2, 1)
                  : ''}
                {context.pluralCashTx?.endSharePrice() && (
                  <span className="pl-2 text-sm font-normal opacity-50">
                    {numberToAbbreviatedDollarString(context.pluralCashTx?.endSharePrice(), 2, 1)} PPS
                  </span>
                )}
              </div>
            </div>
            <YearsSelect />
          </div>
          <div className="z-10">
            <Slider
              onValueChange={(newValue) => onMultiplesSliderChange(newValue)}
              value={
                defaultPossibleOutcomeMultiples[multiplesSliderProps.activeMultipleIndex] ===
                context?.selectedCompanyMultiple
                  ? [multiplesSliderProps.activeMultipleIndex]
                  : [findClosestMultipleIndex(context?.selectedCompanyMultiple, defaultPossibleOutcomeMultiples)]
              }
              min={0}
              max={multiplesSliderProps.selectableMultiples.length - 1}
              step={1}
              size={'3'}
              color="gray"
              highContrast
              className="z-20 w-full flex-grow"
            />
            <div className="relative z-10">
              {breakevenPercent && (
                <div
                  className="border-rounded absolute h-5 rounded-full border border-radix-outline"
                  style={{
                    left: `${breakevenPercent}%`,
                    marginLeft: '-1px',
                    top: '-15px',
                  }}></div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default function SliderIslandContainer({ children }: { children: React.ReactNode }) {
  return (
    <div className="relative w-full">
      <div className="relative z-50">
        <div className="pointer-events-none fixed bottom-4 left-2 right-2 flex flex-col items-center justify-center lg:bottom-8 lg:left-20 lg:right-0">
          <SliderIsland />
        </div>
      </div>
      <div className="h-full w-full">{children}</div>
    </div>
  )
}
