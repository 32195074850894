import { IonItem, IonSpinner } from '@ionic/react'
import { BackpackIcon, BarChartIcon, GlobeIcon, PersonIcon, TokensIcon } from '@radix-ui/react-icons'
import Sidenav, { SidenavItemType } from 'components/navigation/Sidenav'
import { RecalculateButton } from 'pages/adminSheet'
import { usePluralAuth } from 'providers/PluralAuthProvider'
import { PropsWithChildren, ReactNode } from 'react'
import { AuthStatus } from 'utils/authStatus'
import { trpc } from 'utils/trpc'
import { ReactComponent as AppIcon } from '../images/pluralLogos/app-wordmark-light.svg'

const SideNavTabs: SidenavItemType[] = [
  {
    href: '/admin',
    title: 'Overview',
    icon: <TokensIcon />,
  },
  {
    href: '/admin/users',
    title: 'Users',
    icon: <PersonIcon />,
  },

  {
    href: '/admin/entities',
    title: 'Legal Entities',
    icon: <BackpackIcon />,
  },
  {
    href: '/admin/marks',
    title: 'Marks',
    icon: <BarChartIcon />,
  },
  {
    href: '/admin/fund/1',
    title: 'Fund 1',
    icon: <GlobeIcon />,
    children: [
      {
        href: '/admin/fund/1/commits',
        title: 'Commits',
      },
      {
        href: '/admin/fund/1/investments',
        title: 'Investments',
      },
    ],
  },
  {
    href: '/admin/fund/2',
    title: 'Fund 2',
    icon: <GlobeIcon />,
    children: [
      {
        href: '/admin/fund/2/commits',
        title: 'Commits',
      },
      {
        href: '/admin/fund/2/investments',
        title: 'Investments',
      },
    ],
  },
  {
    href: '/admin/fund/3',
    title: 'Fund 3',
    icon: <GlobeIcon />,
    children: [
      {
        href: '/admin/fund/3/commits',
        title: 'Commits',
      },
      {
        href: '/admin/fund/3/investments',
        title: 'Investments',
      },
    ],
  },
]

export default function AdminSideNav() {
  return (
    <div
      className="bg-gray-50"
      style={{
        minWidth: '220px',
        flex: 1,
        marginRight: 'auto',
        borderRight: '1px solid lightgray',
        display: 'flex',
        flexDirection: 'column',
        gap: '30px',
      }}>
      <div className="flex flex-row items-center pl-3 pt-5">
        <AppIcon style={{ height: '36px', width: '85px' }} /> | Admin
      </div>

      <Sidenav items={SideNavTabs} />
      <RecalculateButton />
    </div>
  )
}

export function AdminPageContainer(props: PropsWithChildren<{}>) {
  const { isLoading: isAuthLoading, authStatus } = usePluralAuth()
  const isCompanyEditorQuery = trpc.attributedCompanyMetrics.isCompanyEditor.useQuery(undefined, {
    enabled: !isAuthLoading,
  })

  if (isAuthLoading || isCompanyEditorQuery.isLoading) {
    return (
      <div className="loader flex h-[100vh] w-full flex-col items-center justify-center">
        <IonItem>
          <IonSpinner name="lines-sharp"></IonSpinner>
        </IonItem>
      </div>
    )
  }
  if (authStatus !== AuthStatus.Allowed || !isCompanyEditorQuery.data) {
    return <div>not allowed</div>
  }
  return (
    <div className="flex flex h-full w-full flex-row overflow-hidden" style={{ height: '100vh' }}>
      <AdminSideNav />
      <div className="flex w-full flex-col overflow-auto">{props.children}</div>
    </div>
  )
}

export function AdminSheetPageContainer(
  props: PropsWithChildren<{
    title: string
    titleEndAdornment?: ReactNode
    endNode?: ReactNode
  }>,
) {
  return (
    <AdminPageContainer>
      <div className="flex w-full flex-col gap-6 p-12">
        <div className="flex flex-row items-center justify-between">
          <div className="flex flex-row items-center gap-2 text-2xl">
            {props.title} {props.titleEndAdornment}
          </div>
          {props.endNode}
        </div>
        {props.children}
      </div>
    </AdminPageContainer>
  )
}

export function SeveralSheetPageContainer(
  props: PropsWithChildren<{
    title: ReactNode
    titleEndAdornment?: ReactNode
    endNode?: ReactNode
  }>,
) {
  return (
    <div className="flex w-full flex-col gap-6 p-12">
      <div className="flex flex-row items-center justify-between">
        <div className="flex flex-row items-center gap-2 text-2xl">
          {props.title} {props.titleEndAdornment}
        </div>
        {props.endNode}
      </div>
      {props.children}
    </div>
  )
}
