import { useEffect, useState } from 'react'
import { isNone, isSome } from 'safety'
import { BasicButton } from '../../components/BasicButton'
import { usePluralAuth } from '../../providers/PluralAuthProvider'
import { trpc } from '../../utils/trpc'
import { useCompanySelectorContext } from './CompanySelectorContextProvider'
import { useCompanySelectorContext as useEnrichedCompanySelectorContext } from './massiveEnrichedCompany/MassiveEnrichedCompanySelectorContextProvider'

export default function SubscribeToCompany() {
  let { companyId } = useCompanySelectorContext()
  const { pluralCompanyId } = useEnrichedCompanySelectorContext()
  if (pluralCompanyId.value) {
    companyId = pluralCompanyId
  }
  const { user } = usePluralAuth()
  const [isSubscribed, setIsSubscribed] = useState<boolean | undefined>(undefined)

  const isSubscribedQuery = trpc.companySubscription.isUserSubscribed.useQuery(
    {
      companyId: companyId.value ? companyId.value : 0,
    },
    {
      enabled: isSome(companyId.value) && isSome(user),
    },
  )

  useEffect(() => {
    if (isSome(isSubscribedQuery.data)) {
      setIsSubscribed(isSubscribedQuery.data)
    }
  }, [isSubscribedQuery.data])

  if (isNone(user)) {
    return <div />
  }

  const unsubscribeMutation = trpc.companySubscription.unsubscribeFromCompany.useMutation({
    onSuccess: () => {
      isSubscribedQuery.refetch()
    },
  })
  const subscribeMutation = trpc.companySubscription.subscribeToCompany.useMutation({
    onSuccess: () => {
      isSubscribedQuery.refetch()
    },
  })

  return (
    <div>
      {isNone(isSubscribed) ? (
        <div />
      ) : isSubscribed === true ? (
        <BasicButton
          className="text-sm"
          variant="secondary"
          onClick={() => {
            if (isSome(companyId.value)) {
              setIsSubscribed(false)
              unsubscribeMutation.mutate({
                companyId: companyId.value,
              })
            }
          }}>
          Subscribed
        </BasicButton>
      ) : (
        <BasicButton
          className="text-sm"
          variant="primary"
          onClick={() => {
            if (isSome(companyId.value)) {
              setIsSubscribed(true)
              subscribeMutation.mutate({
                companyId: companyId.value,
              })
            }
          }}>
          Subscribe
        </BasicButton>
      )}
    </div>
  )
}
