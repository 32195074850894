import { FundingRound } from 'plural-shared/dataStructures'

export type { FundingRound }

export type EditingDateValuation = {
  rowId: string
  date?: Date
  valuation: number
}

export type GraphDataPoint = {
  date: Date
  value: number
}

export type Coefficients = {
  a: number
  b: number
  c: number
}

export type ExitScenario = {
  date: Date
  valuation: number
}

export type ExitScenarioColor = {
  r: number
  g: number
  b: number
}

export type CalculatedExitScenario = {
  exitScenario: ExitScenario
  todayValuation: number
  color: ExitScenarioColor
  data: GraphDataPoint[]
  graphMethodology: GraphMethodology
}

export type SampleData = {
  incorporationDate: Date
  fundingRounds: FundingRound[]
  exitScenarios: ExitScenario[]
  lastRoundSharePrice?: number
  desiredFundInterest?: number
  reserveMultiple?: number
}

export const ExitColors = [
  { r: 255, g: 89, b: 94 }, // red
  { r: 25, g: 130, b: 196 }, // blue
  { r: 138, g: 201, b: 38 }, // green
  { r: 106, g: 76, b: 147 }, // purple
  { r: 255, g: 202, b: 58 }, // yellow
]

export type GraphMethodology = 'MINIMUM_GROWTH_RATE' | 'BEST_FIT_EXPONENTIAL_CURVE'
