import { AdminSheetPageContainer } from 'admin/AdminSideNav'
import { UserSheet } from 'pages/adminSheet'

export default function AdminUsers() {
  return (
    <AdminSheetPageContainer title={`Users associated with active Legal Entities`}>
      <UserSheet />
    </AdminSheetPageContainer>
  )
}
