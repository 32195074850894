import { ColumnDef } from '@tanstack/react-table'
import { BasicButton } from 'components/BasicButton'
import SheetPage from 'pages/admin/AdminSheetPage'
import { PluralLink } from 'providers/NavigationProvider'
import { usePluralAuth } from 'providers/PluralAuthProvider'
import { useMemo } from 'react'
import { ArrayElement } from 'safety'
import { Fund, useFundsColumns } from 'several/hooks/Funds'
import { RouterOutputs, trpc } from 'utils/trpc'
import { UploadCloud } from 'lucide-react'

type Funds = RouterOutputs['fundFileRouter']['getFundsByUser']

export default function FundsSheetPage(props: { isLoading?: boolean }) {
  const { isLoading: isAuthLoading } = usePluralAuth()
  let fundQuery = trpc.fundFileRouter.getFundsByUser.useQuery(undefined, {
    enabled: !isAuthLoading,
  })
  let indicationQuery = trpc.indications.allIndicationsQuery.useQuery(undefined, {
    enabled: !isAuthLoading,
  })
  const funds = fundQuery.data ?? []
  const fundsToCompany = useMemo(
    () =>
      funds.reduce((acc: Record<string, string[]>, fund: ArrayElement<Funds>) => {
        acc[fund.id] = fund.companyIds
        return acc
      }, {}),
    [funds],
  )

  let columns: ColumnDef<Fund, any>[] = useFundsColumns({})

  return (
    <>
      <SheetPage
        paginate
        defaultSheetId={10}
        backBreadcrumb={{ href: '/dashboard/sheets/fund-snapshots', title: 'Fund Snapshots' }}
        getRowId={(row: any) => row.id}
        isLoading={fundQuery.isLoading || props.isLoading}
        columns={columns as ColumnDef<unknown>[]}
        data={funds}
        filterFns={{}}
        rightHeader={
          <PluralLink href={'/fund-snapshots/upload'}>
            <BasicButton attioStyle variant="primary" className="flex items-center gap-2">
              <UploadCloud className="h-[15px] w-[15px]" />
              Upload Snapshot
            </BasicButton>
          </PluralLink>
        }
        sortingFns={{}}
        indicationData={{
          fundsToCompany: fundsToCompany,
          activeIndicationCompanyIds: indicationQuery.data?.active ?? [],
          inactiveIndicationCompanyIds: indicationQuery.data?.inactive ?? [],
        }}
        // onNavigate={(newId) => {
        //     navigate(`/fund-upload`)
        // }}
        // onNavigatePush={(newId) => {
        //     pushNavigate(`/dashboard/sheets/funds/${newId}`)
        // }}
      />
    </>
  )
}
