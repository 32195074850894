import { useState } from 'react'

export interface TableColumnProps<T> {
  header: string
  render: (row: T) => React.ReactNode
}

export interface TableProps<T> {
  columns: TableColumnProps<T>[]
  rowData: T[]
  onRowClick?: (rowDatum: T) => void
  defaultRowCount?: number
  stickyHeader?: boolean
  stickyColumn?: boolean
  verticalBorders?: boolean
  hideHorizontalBorders?: boolean
  bgColor?: string
  maxHeight?: string
}

const TableStyles = {
  rowHeight: 'h-14',
  columnHeaderFont: 'font-normal',
  tablePadding: 'pl-4',
}
export function Table<T>(props: TableProps<T>) {
  const defaultRowCount = props.defaultRowCount ? props.defaultRowCount : 25
  const [rowCount, setRowCount] = useState(defaultRowCount || props.rowData.length)
  const rowDataConditional = props.rowData.length > 0 ? props.rowData : Array(defaultRowCount).fill(null)
  const rowDataRenderable: T[] = rowDataConditional.slice(0, rowCount)
  const shouldRenderShowMore = rowCount < props.rowData.length
  const didClickShowMore = () => {
    setRowCount(rowCount + 5)
  }

  const shouldRenderShowFewer = !shouldRenderShowMore && props.rowData.length > defaultRowCount
  const didClickShowFewer = () => {
    setRowCount(defaultRowCount)
  }
  const emptyClick = () => {}
  const onRowClickHandler = props.onRowClick ?? emptyClick
  const borderBottomClass = 'border-b'
  const borderRightClass = props.verticalBorders ? 'border-r' : ''
  const bgColor = props.bgColor ?? 'bg-white'

  return (
    <div className="w-full">
      <div className={`relative w-full overflow-auto rounded-md border ${props.maxHeight}`}>
        <table className="m-0 min-w-full table-fixed border-separate border-spacing-0 whitespace-nowrap border-none">
          {props.rowData && (
            <thead>
              <tr className="column-headers">
                {props.columns.map((column, index) => {
                  let borderRClass = index !== props.columns.length - 1 ? borderRightClass : ''
                  return (
                    <th
                      className={`w-25vw ${borderRClass} ${props.stickyHeader ? 'sticky top-0' : ''}  ${borderBottomClass} bg-table-background ${!index ? TableStyles.tablePadding : ''} ${!index && props.stickyColumn ? ' left-0 border-r' : ''} p-4 text-left ${!index && (props.stickyColumn || props.stickyHeader) ? 'sticky z-20' : 'z-10'}`}
                      key={`column-header-${index} ${TableStyles.columnHeaderFont}`}>
                      <div className={`text-table-text text-sm font-medium`}>{column.header}</div>
                    </th>
                  )
                })}
              </tr>
            </thead>
          )}
          <tbody>
            {rowDataRenderable.map((rowDatum, rowIndex) => {
              const borderClass =
                !props.hideHorizontalBorders && rowIndex !== rowDataRenderable.length - 1 ? borderBottomClass : ''
              const allowClick = props.onRowClick ? true : false
              return (
                <tr
                  className={`text-sm  ${allowClick ? 'cursor-pointer hover:bg-func-gray-hover-fill' : ''} ${TableStyles.rowHeight}`}
                  key={`row-${rowIndex}`}
                  onClick={() => onRowClickHandler(rowDatum)}>
                  {props.columns.map((column, columnIndex) => {
                    let cell: React.ReactNode //need to do this extra conditional on 4 lines below
                    if (rowDatum === null) {
                      cell = <div />
                    } else {
                      cell = column.render(rowDatum)
                    }
                    let borderRClass = columnIndex !== props.columns.length - 1 ? borderRightClass : ''
                    return columnIndex === 0 && props.stickyColumn ? (
                      <th
                        className={`relative sticky left-0 z-10 border-r ${bgColor} p-4  ${borderRClass} ${TableStyles.tablePadding} text-left  ${borderClass}`}
                        key={`cell-${rowIndex}-${columnIndex}`}>
                        {cell}
                      </th>
                    ) : (
                      <td
                        className={`pl-4 text-left ${borderClass} ${borderRClass}`}
                        key={`cell-${rowIndex}-${columnIndex}`}>
                        {cell}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
        {shouldRenderShowMore && (
          <div
            className={`cursor-pointer text-sm font-semibold text-literal-black-5 ${TableStyles.tablePadding} mb-4 mt-2 hover:text-func-black-clickable-text`}
            onClick={didClickShowMore}>
            Show more
          </div>
        )}
        {shouldRenderShowFewer && (
          <div
            className={`cursor-pointer text-sm font-semibold text-literal-black-5 ${TableStyles.tablePadding} mb-4 mt-2 hover:text-func-black-clickable-text`}
            onClick={didClickShowFewer}>
            Show fewer
          </div>
        )}
      </div>
    </div>
  )
}
