export class MultipleRange {
  possibleValues: number[];
  frequency: number;

  constructor(values: number[], frequency: number) {
    this.possibleValues = values;
    this.frequency = frequency;
  }

  randomInValues() {
    return this.possibleValues[
      Math.floor(Math.random() * this.possibleValues.length)
    ];
  }
}

export class MultipleRangeDistribution {
  ranges: MultipleRange[];
  numDeals: number;

  constructor(ranges: MultipleRange[], numDeals: number = 30) {
    // sum the frequencies and make sure they add up to 1
    const sum = ranges.reduce((acc, range) => acc + range.frequency, 0);
    if (Math.abs(sum - 1) > 0.0000001) {
      throw new Error(
        `MultipleRangeDistribution frequencies must add up to 1. Instead, they add up to ${sum}`
      );
    }
    this.ranges = ranges;
    this.numDeals = numDeals;
  }

  possibleOutcomeMultiples() {
    return this.ranges
      .flatMap((range) => range.possibleValues)
      .sort((a, b) => a - b);
  }

  randomizedOutcomeMultiple(): number {
    let random = Math.random(); // random number between 0 and 1
    // Determine which range this random number falls into
    let sum = 0;
    for (let range of this.ranges) {
      sum += range.frequency;
      if (random <= sum) {
        return range.randomInValues();
      }
    }
    throw new Error(
      `random number ${random} did not fall into any of the ranges`
    );
  }

  randomizedOutcomeMultiples(): number[] {
    let outcomes = [];

    for (let i = 0; i < this.numDeals; i++) {
      outcomes.push(this.randomizedOutcomeMultiple());
    }

    return outcomes.sort((a, b) => a - b);
  }
}
