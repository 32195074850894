import { IonItem, IonSpinner } from '@ionic/react'

export default function SharpSpinner() {
  return (
    <div>
      <IonItem style={{}}>
        <IonSpinner name="lines-sharp"></IonSpinner>
      </IonItem>
    </div>
  )
}
