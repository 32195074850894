import { useAuth0 } from '@auth0/auth0-react'
import { createContext, useContext, useEffect, useState } from 'react'

const AccessTokenContext = createContext<string>('')

export function AccessTokenProvider({ children }: any) {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0()
  const [accessToken, setAccessToken] = useState<string>('')

  useEffect(() => {
    function _getAccessTokenSilently() {
      try {
        getAccessTokenSilently({}).then((token) => {
          setAccessToken(token)
        })
      } catch (error) {
        console.error('error getting access token: ', error)
      }
    }
    if (isAuthenticated) {
      _getAccessTokenSilently()
    }
  }, [isAuthenticated, getAccessTokenSilently])

  return <AccessTokenContext.Provider value={accessToken}>{children}</AccessTokenContext.Provider>
}

export function useAccessToken() {
  const context = useContext(AccessTokenContext)
  if (context === undefined) {
    throw new Error('useAccessToken must be used within a AccessTokenProvider')
  }
  return context
}
