import React from 'react'

//create a react component that takes in a prop called "height"
//and returns a div with a height of that prop

interface VerticalBreakProps {
  height: number
}

function VerticalBreak(props: VerticalBreakProps) {
  return <div style={{ height: props.height }} />
}

export default VerticalBreak
