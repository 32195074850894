// import { txMultipleAboveMin } from "./txMultiple";

import { txMultipleCurve } from "../core/lookbackV2Contract";

export function companyCagrRequiredForTargetTxMultiple(
  targetTxMultiple: number
): number {
  // modified such that f(0) is target
  let f = secantTxMultiple(targetTxMultiple);
  // initial guesses
  let x0 = 0.2;
  let x1 = 0.8;
  let tolerance = 0.01;

  return secantMethodRootApproximation(f, x0, x1, tolerance);
}

function secantTxMultiple(targetTxMultiple: number): (x: number) => number {
  return (x: number) => targetTxMultiple - txMultipleCurve(x);
}

function secantMethodRootApproximation(
  f: (x: number) => number,
  x0: number,
  x1: number,
  tolerance: number,
  maxIterations: number = 1000
) {
  for (let i = 0; i < maxIterations; i++) {
    let fx1 = f(x1);
    let x2 = x1 - (fx1 * (x1 - x0)) / (fx1 - f(x0));
    if (Math.abs(f(x2)) < tolerance) {
      return x2;
    }
    x0 = x1;
    x1 = x2;
  }
  console.log("Max iterations reached");
  return x1; // Return the last computed value
}
