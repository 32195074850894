import { IonItem, IonSpinner } from '@ionic/react'
import { AnyColumnProps } from 'components/editable-table/ColumnProps'
import {
  BigIntDollarsCellFormat,
  CSVCellFormat,
  DateCellFormat,
  DollarsCellFormat,
  StringCellFormat,
} from 'components/editable-table/EditableTextCellFormat'
import ErrorAlertModal from 'components/ErrorAlertModal'
import { useState } from 'react'
import { ArrayElement, isSome, Optional } from 'safety'
import { EditableTable } from '../../components/editable-table/EditableTable'
import { useVanillaTRPC } from '../../providers/TRPCProvider'
import { RouterOutputs, trpc } from '../../utils/trpc'
import { useCompanySelectorContext } from './CompanySelectorContextProvider'
import { useCompanySelectorContext as useEnrichedCompanySelectorContext } from './massiveEnrichedCompany/MassiveEnrichedCompanySelectorContextProvider'

type RouterFinancingEvent = ArrayElement<RouterOutputs['company']['financingEventsWithPrice']>

enum ColumnHeaders {
  Date = 'Date',
  AmountRaised = 'Amount Raised',
  Valuation = 'Valuation',
  IssuePrice = 'Issue Price',
  Class = 'Class',
  Investors = 'Investors',
  Source = 'Source',
  UpdatedBy = 'Updated By',
  UpdatedAt = 'Updated At',
  Type = 'Type',
}

const financingEventTypeOptions = [
  'PREFERRED',
  'FOUNDERS_PREFERRED',
  'SAFE_CONVERSION',
  'SAFE',
  'THIRD_PARTY_TENDER',
  'FIRST_PARTY_TENDER',
  'COMMON',
]

type CreatedObject = {
  [ColumnHeaders.Date]: Date
  [ColumnHeaders.AmountRaised]: number
  [ColumnHeaders.Valuation]: number
  [ColumnHeaders.IssuePrice]: number
  [ColumnHeaders.Class]: string
  [ColumnHeaders.Investors]: string[]
  [ColumnHeaders.Type]: string
  [ColumnHeaders.Source]: string
}

export function CompanyFinancingDataTable() {
  let { selectedCompany } = useCompanySelectorContext()
  const { selectedBaseCompany } = useEnrichedCompanySelectorContext()
  if (selectedBaseCompany.value) {
    selectedCompany = selectedBaseCompany
  }
  const [error, setError] = useState<{ title: string; body: string } | undefined>(undefined)
  const vanillaClient = useVanillaTRPC()
  const financingDataQuery = trpc.company.financingEventsWithPrice.useQuery(
    { companyId: selectedCompany.value?.id ?? 0 },
    {
      enabled: isSome(selectedCompany.value),
      refetchOnWindowFocus: false,
    },
  )

  const financingData: RouterFinancingEvent[] = financingDataQuery.data ?? []

  const columns: AnyColumnProps<RouterFinancingEvent, CreatedObject>[] = [
    {
      header: ColumnHeaders.Class,
      textCellFormat: StringCellFormat,
      inputType: 'input',
      accessor: (row) => row.shareType,
      updateRemote: async (row: RouterFinancingEvent, newValue: Optional<string>) => {
        await vanillaClient.financingEvents.updateFinancingDataMutation.mutate({
          id: row.id,
          data: {
            shareType: newValue ?? '',
          },
        })
        financingDataQuery.refetch()
      },
      minWidthPixels: 120,
      placeholder: 'Share Class',
      createProps: {
        defaultOption: '',
        isRequired: false,
        createdObjectKey: ColumnHeaders.Class,
      },
    },
    {
      header: ColumnHeaders.Date,
      textCellFormat: DateCellFormat,
      inputType: 'input',
      updateRemote: async (row: RouterFinancingEvent, newValue: Optional<Date>) => {
        await vanillaClient.financingEvents.updateFinancingDataMutation.mutate({
          id: row.id,
          data: {
            date: newValue ? newValue.toISOString() : undefined,
          },
        })
        financingDataQuery.refetch()
      },
      minWidthPixels: 100,
      accessor: (row) => new Date(row.date),
      placeholder: '00/00/0000',
      createProps: {
        isRequired: true,
        createdObjectKey: ColumnHeaders.Date,
      },
    },
    {
      header: ColumnHeaders.AmountRaised,
      textCellFormat: BigIntDollarsCellFormat,
      inputType: 'input',
      accessor: (row) => row.amountRaised,
      updateRemote: async (row: RouterFinancingEvent, newValue: Optional<number>) => {
        await vanillaClient.financingEvents.updateFinancingDataMutation.mutate({
          id: row.id,
          data: {
            amountRaised: newValue,
          },
        })
        financingDataQuery.refetch()
      },
      placeholder: 'Amount Raised',
      createProps: {
        isRequired: false,
        createdObjectKey: ColumnHeaders.AmountRaised,
      },
    },
    {
      header: ColumnHeaders.Valuation,
      textCellFormat: BigIntDollarsCellFormat,
      inputType: 'input',
      accessor: (row) => row.postMoneyValuation,
      updateRemote: async (row: RouterFinancingEvent, newValue: Optional<number>) => {
        await vanillaClient.financingEvents.updateFinancingDataMutation.mutate({
          id: row.id,
          data: {
            postMoneyValuation: newValue,
          },
        })
        financingDataQuery.refetch()
      },
      placeholder: 'Valuation',
      createProps: {
        isRequired: false,
        createdObjectKey: ColumnHeaders.Valuation,
      },
    },
    {
      header: ColumnHeaders.IssuePrice,
      textCellFormat: DollarsCellFormat,
      inputType: 'input',
      accessor: (row) => row.issuePrice,
      updateRemote: async (row: RouterFinancingEvent, newValue: Optional<number>) => {
        await vanillaClient.financingEvents.updateFinancingDataMutation.mutate({
          id: row.id,
          data: {
            issuePrice: newValue,
          },
        })
        financingDataQuery.refetch()
      },
      placeholder: 'Issue Price',
      createProps: {
        defaultOption: undefined,
        isRequired: false,
        createdObjectKey: ColumnHeaders.IssuePrice,
      },
    },
    {
      header: ColumnHeaders.Investors,
      inputType: 'input',
      textCellFormat: CSVCellFormat,
      accessor: (row) => row.keyInvestors.map((investor) => investor.name),
      updateRemote: async (row: RouterFinancingEvent, newValue: Optional<string[]>) => {
        if (!newValue) return
        await vanillaClient.financingEvents.updateFinancingDataMutation.mutate({
          id: row.id,
          data: {
            keyInvestors: newValue,
          },
        })
        financingDataQuery.refetch()
      },
      placeholder: 'Investors',
      minWidthPixels: 200,
      createProps: {
        defaultOption: undefined,
        isRequired: false,
        createdObjectKey: ColumnHeaders.Investors,
      },
    },
    {
      header: ColumnHeaders.Type,
      inputType: 'multiSelect',
      options: financingEventTypeOptions.map((type) => type.toLowerCase()),
      accessor: (row) => (isSome(row.type) ? row.type.toLowerCase() : ''),
      updateRemote: async (row: RouterFinancingEvent, newValue: Optional<string>) => {
        await vanillaClient.financingEvents.updateFinancingDataMutation.mutate({
          data: {
            type: newValue ? newValue.toUpperCase() : undefined,
          },
          id: row.id,
        })
        financingDataQuery.refetch()
      },
      minWidthPixels: 110,
      createProps: {
        defaultOption: 'preferred',
        isRequired: false,
        createdObjectKey: ColumnHeaders.Type,
      },
    },
    {
      header: ColumnHeaders.Source,
      inputType: 'input',
      textCellFormat: StringCellFormat,
      updateRemote: async (row: RouterFinancingEvent, newValue: Optional<string>) => {
        await vanillaClient.financingEvents.updateFinancingDataMutation.mutate({
          data: { source: newValue ?? '' },
          id: row.id,
        })
        financingDataQuery.refetch()
      },
      minWidthPixels: 150,
      accessor: (row) => row.source,
      placeholder: 'source',
      createProps: {
        isRequired: false,
        createdObjectKey: ColumnHeaders.Source,
      },
    },
    {
      header: ColumnHeaders.UpdatedBy,
      inputType: 'input',
      textCellFormat: StringCellFormat,
      accessor: (row) => row.updatedByUser?.email ?? '',
    },
    {
      header: ColumnHeaders.UpdatedAt,
      inputType: 'input',
      textCellFormat: DateCellFormat,
      accessor: (row) => (row.updatedAt ? new Date(row.updatedAt) : null),
    },
  ]
  return (
    <div className="w-full">
      {error && (
        <ErrorAlertModal
          title={error.title}
          body={error.body}
          open
          onClose={() => {
            setError(undefined)
          }}
        />
      )}
      <div className=" w-full">
        {financingDataQuery.isLoading ? (
          <div className="loader mt-6 flex w-full flex-col items-center">
            <IonItem>
              <IonSpinner name="lines-sharp"></IonSpinner>
            </IonItem>
          </div>
        ) : (
          <div className="w-full">
            <EditableTable<RouterFinancingEvent, CreatedObject>
              onRowCreate={async (createdObject: CreatedObject) => {
                await vanillaClient.financingEvents.createFinancingDataMutation.mutate({
                  date: createdObject[ColumnHeaders.Date].toISOString(),
                  amountRaised: createdObject[ColumnHeaders.AmountRaised],
                  postMoneyValuation: createdObject[ColumnHeaders.Valuation],
                  shareType: createdObject[ColumnHeaders.Class],
                  keyInvestors: createdObject[ColumnHeaders.Investors],
                  type: createdObject[ColumnHeaders.Type].toUpperCase(),
                  issuePrice: createdObject[ColumnHeaders.IssuePrice],
                  companyId: selectedCompany.value?.id ?? 0,
                })
                financingDataQuery.refetch()
              }}
              columns={columns}
              rowData={financingData.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())}
              stickyColumn={true}
              deleteProps={{
                onDelete: async (rowIndex) => {
                  let id = (financingDataQuery.data as RouterFinancingEvent[])[rowIndex].id
                  try {
                    await vanillaClient.financingEvents.deleteFinancingDataQuery.query(id)
                  } catch (e: any) {
                    setError({
                      title: "Can't  delete financing round",
                      body: 'Every company must have at least one financing round with an Issue Price.',
                    })
                  }
                  financingDataQuery.refetch()
                },
                confirmationModalProps: {
                  title: 'Are you sure you wish to delete this financing round?',
                  body: 'This action is permanent and may impact the offer calculator.',
                },
              }}
            />
          </div>
        )}
      </div>
    </div>
  )
}
