import {
  MultipleRange,
  MultipleRangeDistribution,
} from "../multipleRangeDistribution";

// multiples loosely based on
//https://www.industryventures.com/insight/the-venture-capital-risk-and-return-matrix/
let multipleRanges = [
  new MultipleRange([0.3, 0.5, 0.6, 0.65, 0.7, 0.75, 0.8, 0.85, 0.9], 0.22),
  new MultipleRange([1.4, 1.5, 1.6], 0.3),
  new MultipleRange([2.3, 2.5, 2.7], 0.17),
  new MultipleRange([3.5, 3.8], 0.1),
  new MultipleRange([4.5, 4.8], 0.07),
  new MultipleRange([5.5], 0.04),
  new MultipleRange([6.5], 0.02),
  new MultipleRange([9, 12, 15, 18, 21, 24, 30], 0.08),
];

export const defaultLateStageMultipleRangeDistribution =
  new MultipleRangeDistribution(multipleRanges);
