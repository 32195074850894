import { yearsBetweenDates } from "./yearsBetweenDates";

export function calculateFixedCAGRCompanyValuationAtDate({
  cagr,
  exitValuation,
  exitDate,
  valuationDate,
}: {
  cagr: number;
  exitValuation: number;
  exitDate: Date;
  valuationDate: Date;
}): number {
  if (valuationDate.getTime() > exitDate.getTime()) {
    throw new Error("Valuation date must be before exit date");
  }
  const yearsDiff = yearsBetweenDates({ start: valuationDate, end: exitDate });
  const valuation = exitValuation * Math.pow(1 + cagr, -yearsDiff);
  return valuation;
}
