import { AccessTokenProvider } from './AccessTokenProvider'
import { Auth0Provider } from './Auth0Provider'
import { NavigationProvider } from './NavigationProvider'
import PluralAuthProvider from './PluralAuthProvider'
import { SnackbarProvider } from './SnackbarProvider'
import { TRPCProvider } from './TRPCProvider'
export default function Providers({ children }: any) {
  return (
    <Auth0Provider>
      <AccessTokenProvider>
        <SnackbarProvider>
          <TRPCProvider>
            <PluralAuthProvider>
              <NavigationProvider>{children}</NavigationProvider>
            </PluralAuthProvider>
          </TRPCProvider>
        </SnackbarProvider>
      </AccessTokenProvider>
    </Auth0Provider>
  )
}
