import { ChevronLeft, ChevronRight } from 'lucide-react'
import { SampleData } from 'pages/valuation-calculator/types'
import { useRef } from 'react'
// import { ReactComponent as AndurilLogo } from 'pages/valuation-calculator/sample-companies/logos/anduril-logo.svg'
import { ReactComponent as RampLogo } from 'pages/valuation-calculator/sample-companies/logos/ramp-logo.svg'
import { ReactComponent as RipplingLogo } from 'pages/valuation-calculator/sample-companies/logos/rippling-logo.svg'
import { ReactComponent as StripeLogo } from 'pages/valuation-calculator/sample-companies/logos/stripe-logo.svg'

const rampSampleData: SampleData = {
  incorporationDate: new Date(2017, 4 - 1, 19),
  fundingRounds: [
    { date: new Date(2017, 8 - 1, 10), valuation: 50_000_000 },
    { date: new Date(2018, 6 - 1, 10), valuation: 250_000_000 },
    { date: new Date(2019, 9 - 1, 10), valuation: 1_200_000_000 },
    { date: new Date(2021, 6 - 1, 16), valuation: 4_650_000_000 },
    { date: new Date(2022, 12 - 1, 1), valuation: 8_500_000_000 },
    { date: new Date(2023, 9 - 1, 19), valuation: 5_500_000_000 },
  ],
  exitScenarios: [
    { date: new Date(2026, 5 - 1, 31), valuation: 20_000_000_000 },
    { date: new Date(2026, 5 - 1, 30), valuation: 30_000_000_000 },
    { date: new Date(2026, 5 - 1, 30), valuation: 10_000_000_000 },
  ],
  lastRoundSharePrice: 21,
}

const andurilSampleData: SampleData = {
  incorporationDate: new Date(2017, 4 - 1, 20),
  fundingRounds: [
    { date: new Date(2017, 8 - 1, 11), valuation: 50_000_000 },
    { date: new Date(2018, 6 - 1, 11), valuation: 250_000_000 },
    { date: new Date(2019, 9 - 1, 11), valuation: 1_200_000_000 },
    { date: new Date(2021, 6 - 1, 17), valuation: 4_650_000_000 },
    { date: new Date(2022, 12 - 1, 2), valuation: 8_500_000_000 },
  ],
  exitScenarios: [
    { date: new Date(2026, 6 - 1, 1), valuation: 20_000_000_000 },
    { date: new Date(2026, 6 - 1, 1), valuation: 30_000_000_000 },
  ],
  lastRoundSharePrice: 16.52,
}

const ripplingSampleData: SampleData = {
  incorporationDate: new Date(2016, 12 - 1, 31),
  fundingRounds: [
    { date: new Date(2017, 3 - 1, 13), valuation: 35_000_000 },
    { date: new Date(2020, 8 - 1, 3), valuation: 1_200_000_000 },
    { date: new Date(2021, 1 - 1, 20), valuation: 6_500_000_000 },
    { date: new Date(2022, 5 - 1, 10), valuation: 11_250_000_000 },
    { date: new Date(2023, 3 - 1, 16), valuation: 11_250_000_000 },
  ],
  exitScenarios: [
    { date: new Date(2026, 5 - 1, 31), valuation: 15_000_000_000 },
    { date: new Date(2026, 5 - 1, 31), valuation: 20_000_000_000 },
    { date: new Date(2026, 5 - 1, 31), valuation: 25_000_000_000 },
  ],
  lastRoundSharePrice: 42.12,
}

const stripeSampleData: SampleData = {
  incorporationDate: new Date(2009, 4 - 1, 13),
  fundingRounds: [
    { date: new Date(2009, 6 - 1, 1), valuation: 1_200_000 },
    { date: new Date(2010, 11 - 1, 16), valuation: 20_000_000 },
    { date: new Date(2012, 2 - 1, 9), valuation: 100_000_000 },
    { date: new Date(2012, 6 - 1, 10), valuation: 500_000_000 },
    { date: new Date(2014, 12 - 1, 1), valuation: 1_750_000_000 },
    { date: new Date(2015, 6 - 1, 28), valuation: 5_000_000_000 },
    { date: new Date(2016, 11 - 1, 25), valuation: 9_250_000_000 },
    { date: new Date(2018, 9 - 1, 27), valuation: 20_450_000_000 },
    { date: new Date(2019, 9 - 1, 19), valuation: 35_250_000_000 },
    { date: new Date(2020, 4 - 1, 16), valuation: 36_000_000_000 },
    { date: new Date(2021, 3 - 1, 14), valuation: 95_000_000_000 },
    { date: new Date(2023, 3 - 1, 14), valuation: 55_000_000_000 },
  ],
  exitScenarios: [
    { date: new Date(2026, 6 - 1, 1), valuation: 120_000_000_000 },
    { date: new Date(2026, 6 - 1, 1), valuation: 50_000_000_000 },
    { date: new Date(2026, 6 - 1, 1), valuation: 180_000_000_000 },
  ],
  lastRoundSharePrice: 20.0,
}

export type SampleCompany = {
  id: string
  name: string
  color: string
  logo: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
  sampleData: SampleData
}

const companies: SampleCompany[] = [
  {
    id: 'ramp',
    name: 'Ramp',
    color: '#F7FF8B',
    sampleData: rampSampleData,
    logo: RampLogo,
  },
  // {
  //   id: 'anduril',
  //   name: 'Anduril',
  //   color: '#FFFFFF',
  //   sampleData: andurilSampleData,
  //   logo: AndurilLogo,
  // },
  {
    id: 'rippling',
    name: 'Rippling',
    color: '#E8BB5C',
    sampleData: ripplingSampleData,
    logo: RipplingLogo,
  },
  {
    id: 'stripe',
    name: 'Stripe',
    color: '#6972DD',
    sampleData: stripeSampleData,
    logo: StripeLogo,
  },
]

export default function SampleCompanies({ onSelect }: { onSelect: (company: SampleCompany) => void }) {
  const scrollRef = useRef<HTMLDivElement>(null)

  const onScrollLeft = () => {
    if (!scrollRef.current) {
      return
    }

    scrollRef.current.scrollTo({
      left: scrollRef.current.scrollLeft - scrollRef.current.clientWidth,
      behavior: 'smooth',
    })
  }

  const onScrollRight = () => {
    if (!scrollRef.current) {
      return
    }

    scrollRef.current.scrollTo({
      left: scrollRef.current.scrollLeft + scrollRef.current.clientWidth,
      behavior: 'smooth',
    })
  }

  return (
    <div className="mb-8 flex flex-row items-center gap-2">
      <button className="flex-none text-plural-sand-600" onClick={onScrollLeft}>
        <ChevronLeft />
      </button>

      <div className="no-scrollbar flex flex-1 flex-row gap-6 overflow-x-scroll py-2" ref={scrollRef}>
        {companies.map((c) => (
          <button
            key={c.id}
            className="inline-flex h-6 w-[102px] flex-none items-center justify-center rounded-md shadow-md transition-shadow focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50"
            style={{ background: c.color }}
            onClick={() => onSelect(c)}>
            <c.logo height="14px" />
          </button>
        ))}
      </div>

      <button className="flex-none text-plural-sand-600" onClick={onScrollRight}>
        <ChevronRight />
      </button>
    </div>
  )
}
