import { dateToShorthandMonthYearString, numberToReadableDollarString } from 'plural-shared/utils'
import { trpc } from 'utils/trpc'

function InfoItem(props: { label: string; value: string }) {
  return (
    <div key={`info-${props.label}`} className="w-full lg:w-auto">
      <div className="text-sm opacity-80">{props.label}</div>
      <div className="pt-1 text-xl lg:text-3xl">{props.value}</div>
    </div>
  )
}

export default function PortfolioGraphFooter(props: { fundId: number }) {
  const metadataQuery = trpc.portfolio.snapshotMetadata.useQuery({ fundId: props.fundId })
  const additionalMetadataQuery = trpc.portfolio.fundAdditionalMetadata.useQuery({ fundId: props.fundId })

  return (
    <div className="w-full">
      {metadataQuery.data && additionalMetadataQuery.data && (
        <div className="flex w-full flex-col flex-wrap space-y-4 lg:flex-row lg:space-x-12 lg:space-y-0">
          <InfoItem
            label={`Gross marked value ${dateToShorthandMonthYearString(new Date(metadataQuery.data.snapshotDate))}`}
            value={numberToReadableDollarString(metadataQuery.data.totalMarkValue, false)}
          />
          <InfoItem
            label="Total investments"
            value={numberToReadableDollarString(additionalMetadataQuery.data.totalInvestmentsMade, false)}
          />
          {/* <InfoItem
            label="Total commits"
            value={numberToReadableDollarString(additionalMetadataQuery.data.initialFundSize, false)}
          />
          <InfoItem
            label={`Gross value of Assets ${dateToShorthandMonthYearString(new Date(metadataQuery.data.snapshotDate))} w/o Fund II`}
            value={numberToReadableDollarString(additionalMetadataQuery.data.exclusiveGrossMarkedValue, false)}
          /> */}
        </div>
      )}
    </div>
  )
}

export function SelfValueGraphFooter(props: {
  totalInvestmentsMade: number
  totalMarkValue: number
  snapshotDate: string
}) {
  return (
    <div className="w-full">
      <div className="flex w-full flex-col flex-wrap space-y-4 lg:flex-row lg:space-x-12 lg:space-y-0">
        <InfoItem
          label={`Gross marked value ${dateToShorthandMonthYearString(new Date(props.snapshotDate))}`}
          value={numberToReadableDollarString(props.totalMarkValue, false)}
        />
        <InfoItem label="Total investments" value={numberToReadableDollarString(props.totalInvestmentsMade, false)} />
      </div>
    </div>
  )
}

export function AdminGraphFooter(props: {
  totalAssets: number
  totalInvestments: number
  totalCommits: number
  totalCarry: number
  totalManagementFees: number
}) {
  return (
    <div className="w-full">
      <div className="flex w-full flex-col flex-wrap space-y-4 lg:flex-row lg:space-x-12 lg:space-y-0">
        <InfoItem
          label={`Total Assets under Management`}
          value={numberToReadableDollarString(props.totalAssets, false)}
        />
        <InfoItem label="Total Investments" value={numberToReadableDollarString(props.totalInvestments, false)} />
        <InfoItem label="Total Commits" value={numberToReadableDollarString(props.totalCommits, false)} />
        <InfoItem label="Total Carry" value={numberToReadableDollarString(props.totalCarry, false)} />
        <InfoItem
          label="Total Management Fees"
          value={numberToReadableDollarString(props.totalManagementFees, false)}
        />
      </div>
    </div>
  )
}
