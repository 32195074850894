import React from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { Button } from '@radix-ui/themes'

const LoginButton = () => {
  const { loginWithRedirect } = useAuth0()
  const handleLogin = async () => {
    localStorage.setItem('hasViewedAllowlistNotice', 'false')
    await loginWithRedirect({
      appState: {
        returnTo: '/sensitivity',
      },
    })
  }

  return (
    <Button variant="soft" onClick={handleLogin}>
      Log in
    </Button>
  )
}

export default LoginButton
