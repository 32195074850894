import { useMemo, useCallback, useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

type Qp = string | number

export function useQueryParam<T extends number | string>(key: string, defaultValue: T) {
  const navigate = useNavigate()
  const location = useLocation()
  const queryParams = useMemo(() => new URLSearchParams(location.search), [location])
  const [value, setValue] = useState<T | undefined>(undefined)

  useEffect(() => {
    const param = queryParams.get(key)
    if (param !== null && param !== undefined) {
      setValue(typeof defaultValue === 'number' ? (Number(param) as T) : (param as T))
    } else {
      setValue(defaultValue)
    }
  }, [queryParams, key, defaultValue])

  const setQueryParam = useCallback(
    (value: T) => {
      queryParams.set(key, value.toString())
      navigate({ pathname: location.pathname, search: queryParams.toString() }, { replace: true })
    },
    [queryParams, navigate, location.pathname, key],
  )

  // Function to update multiple query parameters at once
  const setSelfAndAdditionalQueryParams = useCallback(
    (value: T, additionalParams: Record<string, Qp>) => {
      const newQueryParams = new URLSearchParams(location.search)
      newQueryParams.set(key, value.toString())
      Object.entries(additionalParams).forEach(([key, value]) => {
        newQueryParams.set(key, value.toString())
      })
      navigate({ pathname: location.pathname, search: newQueryParams.toString() }, { replace: true })
    },
    [navigate, location.pathname, location.search, key],
  )

  useEffect(() => {
    if (!queryParams.get(key)) {
      setQueryParam(defaultValue)
    }
  }, [queryParams, key, setQueryParam, defaultValue])

  return [value, setQueryParam, setSelfAndAdditionalQueryParams] as const
}
