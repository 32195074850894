import { useEffect } from 'react'
import { usePluralAuth } from '../providers/PluralAuthProvider'

export default function LogIn() {
  const { loginWithRedirect } = usePluralAuth()
  useEffect(() => {
    loginWithRedirect({
      appState: {
        returnTo: '/portfolio',
      },
    })
  }, [loginWithRedirect])

  return null
}
