import { useEffect } from 'react'

export default function BeforeUnload(props: { message: string }) {
  useEffect(() => {
    const handleBeforeUnload = (event: any) => {
      const message = props.message

      event.preventDefault() // Prevents the default browser action
      event.returnValue = message // Chrome requires returnValue to be set
      return message // This will be prompted to the user
    }

    window.addEventListener('beforeunload', handleBeforeUnload)

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [props.message])

  return <></>
}
