import { TrashIcon } from '@radix-ui/react-icons'
import { useQueryClient } from '@tanstack/react-query'
import { getQueryKey } from '@trpc/react-query'
import SidenavV2, { IndividualSidenavItem, SidenavItemV2Type } from 'components/navigation/SidenavV2'
import { Bell, Building2, Coins, LucideHelpingHand, PanelLeft, ScrollText, Wallet, Zap } from 'lucide-react'
import { usePluralNavigation } from 'providers/NavigationProvider'
import { usePluralNavigationUI } from 'providers/NavigationUIProvider'
import { usePluralAuth } from 'providers/PluralAuthProvider'
import { useLocation, useParams } from 'react-router'
import { useIsSmallScreen } from 'several/hooks/navigation'
import { trpc } from 'utils/trpc'
import { ReactComponent as AppIcon } from '../../images/pluralLogos/app-wordmark-light.svg'

const SideNavWidth = '220px'

const getSideNavTabs: (
  ownedCompanySheets: IndividualSidenavItem[],
  viewedCompanySheets: IndividualSidenavItem[],
  ownedFundingSheets: IndividualSidenavItem[],
  viewedFundingSheets: IndividualSidenavItem[],
  routePath: string,
  sharedSheets: IndividualSidenavItem[],
) => SidenavItemV2Type[] = (
  ownedCompanySheets: IndividualSidenavItem[],
  viewedCompanySheets: IndividualSidenavItem[],
  ownedFundingSheets: IndividualSidenavItem[],
  viewedFundingSheets: IndividualSidenavItem[],
  routePath: string,
  sharedSheets: IndividualSidenavItem[],
) => [
  {
    title: 'Search',
    children: [
      {
        href: '/dashboard/news',
        title: 'News',
        active: routePath.includes('news'),
        icon: (
          <div className="flex h-[16px] w-[16px] items-center justify-center rounded bg-blue-600">
            <Bell size={12} color="white" />
          </div>
        ),
      },
      {
        href: '/dashboard/sheets/company',
        title: 'Companies',
        active: routePath.includes('company'),
        icon: (
          <div className="flex h-[16px] w-[16px] items-center justify-center rounded bg-blue-600">
            <Building2 size={12} color={'white'} />
          </div>
        ),
      },
      {
        href: '/dashboard/sheets/funding',
        title: 'Financings',
        active: routePath.includes('funding'),
        icon: (
          <div className="flex h-[16px] w-[16px] items-center justify-center rounded bg-blue-600">
            <Coins size={12} color="white" />
          </div>
        ),
      },
      {
        href: '/dashboard/sheets/fund-snapshots',
        title: 'Fund Snapshots',
        active: routePath.includes('fund-snapshots'),
        icon: (
          <div className="flex h-[16px] w-[16px] items-center justify-center rounded bg-blue-600">
            <Wallet size={12} color="white" />
          </div>
        ),
      },
      {
        href: '/dashboard/sheets/holdings',
        title: 'Holdings',
        active: routePath.includes('holdings'),
        icon: (
          <div className="flex h-[16px] w-[16px] items-center justify-center rounded bg-blue-600">
            <LucideHelpingHand size={12} color="white" />
          </div>
        ),
      },
      {
        href: '/dashboard/sheets/indications',
        title: 'Indications',
        active: routePath.includes('indications'),
        icon: (
          <div className="flex h-[16px] w-[16px] items-center justify-center rounded bg-blue-600">
            <Zap size={12} color="white" />
          </div>
        ),
      },
      {
        href: '/dashboard/lists',
        title: 'Lists',
        active: routePath.endsWith('lists'),
        icon: (
          <div className="flex h-[16px] w-[16px] items-center justify-center rounded bg-blue-600">
            <ScrollText size={12} color={'white'} />
          </div>
        ),
      },
    ],
  },

  {
    title: 'Lists',

    children: [...ownedCompanySheets, ...ownedFundingSheets],
  },

  ...(sharedSheets.length > 0
    ? [
        {
          title: 'Shared',
          children: [...sharedSheets],
        },
      ]
    : []),
  ...(viewedCompanySheets.length + viewedFundingSheets.length > 0
    ? [
        {
          title: 'Followed',
          children: [...viewedCompanySheets, ...viewedFundingSheets],
        },
      ]
    : []),
]

export default function SeveralSideNav() {
  let { user: baseUser } = usePluralAuth()
  let userQuery = trpc.user.getSelfWithSheets.useQuery(undefined, {
    enabled: !!baseUser,
  })
  let user = userQuery.data
  let { navigate } = usePluralNavigation()

  let userSheets = user?.userToSheets ?? []

  let location = useLocation()
  let routePath = location.pathname.toLowerCase()
  let hideSheetMutation = trpc.sheetRouter.hideSheet.useMutation()
  let allSheetsQueryKey = getQueryKey(trpc.sheetRouter.getAllSheets)
  let queryClient = useQueryClient()
  let params = useParams()
  let sheetId = params.sheetId ? parseInt(params.sheetId) : undefined

  return (
    <div
      className="bg-gray-50"
      style={{
        minWidth: SideNavWidth,
        maxWidth: SideNavWidth,
        marginRight: 'auto',
        display: 'flex',
        flexDirection: 'column',
      }}>
      <DashHeaderPiece />
      <div className={'h-full w-full flex-1 border-r pt-8'}>
        <SidenavV2
          items={getSideNavTabs(
            [
              ...userSheets
                .filter((userToSheet: any) => {
                  return userToSheet.role === 'OWNER' && userToSheet.sheet.entityType === 'COMPANY'
                })
                .map((userToSheet: any) => {
                  return {
                    href: `/dashboard/sheets/company/${userToSheet.sheetId}`,
                    active: sheetId === userToSheet.sheetId,
                    title: userToSheet.sheet.name,
                    actions: [
                      // {
                      //   title: '',
                      //   icon: <></>,
                      //   onClick: () => {},
                      //   customComponent: <ShareSheetModalButton sheetId={userToSheet.sheetId} />,
                      // },
                      {
                        title: 'Delete',
                        icon: <TrashIcon />,
                        onClick: async () => {
                          await hideSheetMutation.mutateAsync(userToSheet.sheetId, undefined)
                          queryClient.invalidateQueries(allSheetsQueryKey)
                          userQuery.refetch()
                          if (sheetId === userToSheet.sheetId) {
                            navigate('/dashboard')
                          }
                        },
                      },
                    ],
                    icon: (
                      <div className={`h-[16px] w-[16px] ${sheetId === userToSheet.sheetId ? '' : 'bg-gray-50'}`}></div>
                    ),
                  }
                }),
            ],
            [
              ...userSheets
                .filter((userToSheet: any) => {
                  return userToSheet.role === 'FOLLOWER' && userToSheet.sheet.entityType === 'COMPANY'
                })
                .map((userToSheet: any) => {
                  return {
                    active: sheetId === userToSheet.sheetId,
                    href: `/dashboard/sheets/company/${userToSheet.sheetId}`,
                    title: userToSheet.sheet.name,
                    icon: (
                      <div className={`h-[16px] w-[16px] ${sheetId === userToSheet.sheetId ? '' : 'bg-gray-50'}`}></div>
                    ),
                  }
                }),
            ],
            [
              ...userSheets
                .filter((userToSheet: any) => {
                  return userToSheet.role === 'OWNER' && userToSheet.sheet.entityType === 'FUNDING_ROUND'
                })
                .map((userToSheet: any) => {
                  return {
                    active: sheetId === userToSheet.sheetId,
                    href: `/dashboard/sheets/funding/${userToSheet.sheetId}`,
                    title: userToSheet.sheet.name,
                    icon: (
                      <div className={`h-[16px] w-[16px] ${sheetId === userToSheet.sheetId ? '' : 'bg-gray-50'}`}></div>
                    ),
                    actions: [
                      {
                        title: 'Delete',
                        icon: <TrashIcon />,
                        onClick: async () => {
                          await hideSheetMutation.mutateAsync(userToSheet.sheetId, undefined)
                          queryClient.invalidateQueries(allSheetsQueryKey)
                          userQuery.refetch()
                          if (sheetId === userToSheet.sheetId) {
                            navigate('/dashboard')
                          }
                        },
                      },
                    ],
                  }
                }),
            ],
            [
              ...userSheets
                .filter((userToSheet: any) => {
                  return userToSheet.role === 'FOLLOWER' && userToSheet.sheet.entityType === 'FUNDING_ROUND'
                })
                .map((userToSheet: any) => {
                  return {
                    active: sheetId === userToSheet.sheetId,
                    href: `/dashboard/sheets/funding/${userToSheet.sheetId}`,
                    title: userToSheet.sheet.name,
                    icon: (
                      <div className={`h-[16px] w-[16px] ${sheetId === userToSheet.sheetId ? '' : 'bg-gray-50'}`}></div>
                    ),
                  }
                }),
            ],
            routePath,
            [
              ...userSheets
                .filter((userToSheet: any) => {
                  return userToSheet.role === 'VIEWER' || userToSheet.role === 'EDITOR'
                })
                .map((userToSheet: any) => {
                  return {
                    active: sheetId === userToSheet.sheetId,
                    href:
                      userToSheet.sheet.entityType === 'COMPANY'
                        ? `/dashboard/sheets/company/${userToSheet.sheetId}`
                        : `/dashboard/sheets/funding/${userToSheet.sheetId}`,
                    title: userToSheet.sheet.name,
                    icon: (
                      <div className={`h-[16px] w-[16px] ${sheetId === userToSheet.sheetId ? '' : 'bg-gray-50'}`}></div>
                    ),
                  }
                }),
            ],
          )}
        />
      </div>
    </div>
  )
}

export function DashHeaderPiece() {
  let isSmallScreen = useIsSmallScreen()
  let { setSideNavOpen } = usePluralNavigationUI()
  return isSmallScreen ? (
    <div
      className={`flex h-[50px]  min-h-[50px] flex-row items-center border-b ${isSmallScreen ? '' : 'border-r'}pl-3`}>
      <AppSmallIcon size={30} />
    </div>
  ) : (
    <div
      style={{
        width: SideNavWidth,
      }}
      className={`flex h-[60px]  min-h-[60px] flex-row items-center border-b border-r pl-3`}>
      <AppIcon style={{ height: '36px', width: '85px' }} /> | Dash
      <div className="ml-auto mr-2">
        <div
          className="text:gray rounded-full p-2 hover:bg-gray-100"
          onClick={() => {
            setSideNavOpen((open) => !open)
          }}>
          <PanelLeft size={16} color="gray" />
        </div>
      </div>
    </div>
  )
}

export function AppSmallIcon(props: { size?: number }) {
  return (
    <svg
      width={props.size ?? '106'}
      height={props.size ?? '105'}
      viewBox="0 0 106 105"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      {/* <rect x="0.5" width="105" height="105" rx="35" fill="#AD9486" /> */}
      <path
        d="M50.3151 53.7808C50.3151 35.1143 50.3151 19.7403 50.3151 17.7243C50.3151 17.6062 50.2078 17.5248 50.095 17.56C44.2324 19.3864 39.7524 39.7196 39.7524 53.7808C39.7524 67.8554 43.7921 88.214 50.0965 90.0068C50.209 90.0387 50.3151 89.9574 50.3151 89.8404C50.3151 87.8377 50.3151 72.4573 50.3151 53.7808Z"
        fill="#1C1919"
      />
      <path
        d="M37.1603 53.3164C37.1603 34.3436 43.7483 20.0076 44.4483 18.538C44.4775 18.4765 44.4319 18.4139 44.3639 18.4173C38.2432 18.7235 27.0569 38.2802 27.0569 53.3164C27.0569 68.3483 38.2371 88.8021 44.3588 89.1335C44.428 89.1373 44.4726 89.0697 44.4412 89.0079C43.7052 87.5621 37.1603 74.1207 37.1603 53.3164Z"
        fill="#1C1919"
      />
      <path
        d="M24.6219 53.3262C24.6219 34.6776 35.3629 22.8954 36.8515 21.352C36.9109 21.2905 36.864 21.1921 36.7791 21.2021C30.2192 21.98 15.4373 35.6374 15.4373 53.3262C15.4373 71.03 28.4495 84.2467 36.7334 86.3238C36.8214 86.3458 36.8865 86.2455 36.826 86.1779C35.2446 84.4136 24.6219 71.8719 24.6219 53.3262Z"
        fill="#1C1919"
      />
      <path
        d="M52.9368 53.7808C52.9368 35.1143 52.9368 19.7403 52.9368 17.7243C52.9368 17.6062 53.0442 17.5248 53.1569 17.56C59.0195 19.3864 63.4995 39.7196 63.4995 53.7808C63.4995 67.8554 59.4598 88.214 53.1554 90.0068C53.043 90.0387 52.9368 89.9574 52.9368 89.8404C52.9368 87.8377 52.9368 72.4573 52.9368 53.7808Z"
        fill="#1C1919"
      />
      <path
        d="M66.0155 53.3164C66.0155 34.3436 59.4275 20.0076 58.7275 18.538C58.6982 18.4765 58.7439 18.4139 58.8119 18.4173C64.9325 18.7235 76.1189 38.2802 76.1189 53.3164C76.1189 68.3483 64.9387 88.8021 58.817 89.1335C58.7477 89.1373 58.7032 89.0697 58.7346 89.0079C59.4706 87.5621 66.0155 74.1207 66.0155 53.3164Z"
        fill="#1C1919"
      />
      <path
        d="M78.656 53.3262C78.656 34.6776 67.915 22.8954 66.4264 21.352C66.367 21.2905 66.4138 21.1921 66.4988 21.2021C73.0587 21.98 87.8406 35.6374 87.8406 53.3262C87.8406 71.03 74.8283 84.2467 66.5445 86.3238C66.4565 86.3458 66.3913 86.2455 66.4519 86.1779C68.0333 84.4136 78.656 71.8719 78.656 53.3262Z"
        fill="#1C1919"
      />
    </svg>
  )
}
