import { IonItem, IonSpinner } from '@ionic/react'
import { Pencil1Icon } from '@radix-ui/react-icons'
import { useQueryClient } from '@tanstack/react-query'
import { getQueryKey } from '@trpc/react-query'
import AppModal from 'components/AppModal'
import AppTextField from 'components/AppTextfield'
import { BasicButton } from 'components/BasicButton'
import EditableTanstackTable from 'components/editable-tanstack-table/EditableTanstackTable'
import { Button } from 'components/ui/button'
import { ListMinus, ListPlus } from 'lucide-react'
import { TableFilterModel } from 'models/table'
import { ComponentProps, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { ArrayElement } from 'safety'
import Breadcrumbs from 'several/components/Breadcrumbs'
import ShareSheetModalButton from 'several/components/ShareSheetModalButton'
import { RouterOutputs, trpc } from 'utils/trpc'
import { v4 as uuid } from 'uuid'

type RouterSheet = RouterOutputs['sheetRouter']['getSheet']
type RouterColumn = ArrayElement<RouterSheet['columns']>
type RouterFilterGroup = ArrayElement<RouterSheet['filterGroups']>
type RouterFilter = ArrayElement<RouterFilterGroup['filters']>
export type RouterSingleSheet = ArrayElement<RouterOutputs['sheetRouter']['getAllSheets']>
type RouterUser = RouterOutputs['user']['getSelfWithSheets']

export default function SheetPage(props: {
  backBreadcrumb: { title: string; href: string }
  columns: ComponentProps<typeof EditableTanstackTable>['columns']
  data: ComponentProps<typeof EditableTanstackTable>['data']
  sortingFns: ComponentProps<typeof EditableTanstackTable>['sortingFns']
  filterFns: ComponentProps<typeof EditableTanstackTable>['filterFns']
  onNavigate?: (newSheetId: number) => void
  onNavigatePush?: (newSheetId: number) => void
  rightHeader?: React.ReactNode
  isLoading?: boolean
  getRowId: ComponentProps<typeof EditableTanstackTable>['getRowId']
  paginate?: boolean
  createFloatingFooterButtons?: React.ComponentProps<typeof EditableTanstackTable>['createFloatingFooterButtons']
  defaultSheetId?: number
  onRowCreate?: ComponentProps<typeof EditableTanstackTable>['onRowCreate']
  indicationData?: ComponentProps<typeof EditableTanstackTable>['indicationData']
  createChart?: ComponentProps<typeof EditableTanstackTable>['createChart']
  closedChartSize?: number
}) {
  let params = useParams()
  let sheetId = params.sheetId ? parseInt(params.sheetId) : props.defaultSheetId ?? 0
  let sheetQuery = trpc.sheetRouter.getSheet.useQuery(sheetId, {
    enabled: sheetId !== 0,
    keepPreviousData: false,
  })

  let userQuery = trpc.user.getSelfWithSheets.useQuery(undefined, {
    enabled: !props.isLoading,
  })
  let userQueryKey = getQueryKey(trpc.user.getSelfWithSheets)
  let queryClient = useQueryClient()
  let user = userQuery.data ?? ({} as RouterUser)
  let doesUserOwnSheet: boolean = !!(user as RouterUser)?.userToSheets?.find(
    (userToSheet: any) => userToSheet.sheetId === sheetId && userToSheet.role === 'OWNER',
  )

  let doesUserFollowSheet: boolean = !!(user as RouterUser)!.userToSheets?.find(
    (userToSheet: any) => userToSheet.sheetId === sheetId && userToSheet.role === 'FOLLOWER',
  )

  let followMutation = trpc.sheetRouter.followSheet.useMutation()
  let unfollowMutation = trpc.sheetRouter.unfollowSheet.useMutation()
  // const sheetKey =getQueryKey(trpc.post);

  let patchColumnMutation = trpc.sheetRouter.patchColumn.useMutation()
  let patchSheetMutation = trpc.sheetRouter.patchSheet.useMutation()
  let sheetQueryKey = getQueryKey(trpc.sheetRouter.getSheet, sheetId)

  let postFilterGroupMutation = trpc.sheetRouter.postColumnFilterGroup.useMutation()
  let putFilterMutation = trpc.sheetRouter.putColumnFilter.useMutation()
  let deleteFilterMutation = trpc.sheetRouter.deleteColumnFilter.useMutation()
  let deleteFilterGroupMutation = trpc.sheetRouter.deleteColumnFilterGroup.useMutation()
  let postSheetMutation = trpc.sheetRouter.postSheet.useMutation()
  let postColumnMutation = trpc.sheetRouter.postColumn.useMutation()

  let allSheetsQuery = trpc.sheetRouter.getAllSheets.useQuery()

  let allMutations = [
    patchColumnMutation,
    patchSheetMutation,
    postFilterGroupMutation,
    putFilterMutation,
    deleteFilterMutation,
    deleteFilterGroupMutation,
    postSheetMutation,
    postColumnMutation,
    sheetQuery,
  ]
  let sheet: RouterSheet | undefined = sheetQuery.data

  //monitor this if grows too large
  let extendedSheetsList: number[] = allSheetsQuery.data?.map((sheet) => sheet.id) ?? []

  let groupedFiltersQuery = trpc.sheetRouter.getGroupedSheetFiltersExtension.useQuery(extendedSheetsList)

  let initialFiltersExtensionMap: Record<number, TableFilterModel[][]> = groupedFiltersQuery.data ?? {}
  const sizedColumns = useMemo(() => {
    return props.columns.map((col) => {
      let sheetCol = sheet?.columns.find((sheetCol: RouterColumn) => sheetCol.field === col.id)
      return {
        ...col,
        size: sheetCol?.size ?? col.size,
      }
    })
  }, [props.columns, sheet?.columns])
  let [isPosting, setIsPosting] = useState(false)
  if (!sheet && !sheetQuery.isLoading) {
    return <div>'error loading sheet'</div>
  }

  let defaultFilters: TableFilterModel[][] = (sheet?.filterGroups ?? []).map((group: RouterFilterGroup) => {
    return group.filters.map((filter: RouterFilter) => {
      return {
        id: filter.id,
        value: filter.value,
        field: filter.field,
        operatorType: filter.operatorType,
      }
    })
  })

  let isSaving = allMutations.some((mutation) => mutation.isLoading)
  let isSavingFollow = followMutation.isLoading || unfollowMutation.isLoading
  const allowedRoles = ['OWNER', 'EDITOR', 'VIEWER']
  let userRole = (user as unknown as any)?.userToSheets?.find(
    (userToSheet: any) => userToSheet.sheetId === sheetId,
  )?.role
  let isAllowedRole = allowedRoles.includes(userRole ?? '')
  let isEditor = userRole === 'EDITOR'
  let isNotAllowed = sheet?.restricted && !isAllowedRole

  if (isNotAllowed) {
    return (
      <div className="flex h-[100vh] w-full items-center justify-center text-xl font-medium">
        Not allowed to view this sheet
      </div>
    )
  }

  let spaceAboveTable = 190 + (props.closedChartSize ?? 0)

  return (
    <div className="flex w-full flex-col gap-3">
      <div className="flex flex-row items-center justify-between  px-6">
        <div className="flex flex-row items-center gap-2 py-3">
          <div className="flex flex-row items-center gap-2">
            <Breadcrumbs
              crumbs={[
                {
                  title: 'Lists',
                },
                props.backBreadcrumb,
                ...(sheetId === props.defaultSheetId
                  ? []
                  : [
                      {
                        title: sheet?.name ?? '',
                      },
                    ]),
              ]}
            />
            {sheetId === props.defaultSheetId || !doesUserOwnSheet ? undefined : (
              <EditNameModalButton
                onSaveSheet={async (sheetName) => {
                  await patchSheetMutation.mutateAsync({ id: sheet!.id, data: { name: sheetName } })
                  queryClient.invalidateQueries(userQueryKey)
                  queryClient.invalidateQueries(sheetQueryKey)
                  await allSheetsQuery.refetch()
                }}
                defaultSheetName={sheet?.name ?? 'List'}
              />
            )}
          </div>
        </div>
        <div className="ml-auto items-end self-end">{props.rightHeader}</div>

        {!doesUserOwnSheet && sheetId !== props.defaultSheetId && !isAllowedRole ? (
          <BasicButton
            variant="gray"
            onClick={async () => {
              if (doesUserFollowSheet) {
                await unfollowMutation.mutateAsync(sheetId)
              } else {
                await followMutation.mutateAsync(sheetId)
              }
              queryClient.invalidateQueries(userQueryKey)
            }}
            attioStyle>
            {doesUserFollowSheet ? <ListMinus className={'h-[15px]'} /> : <ListPlus className={'h-[15px]'} />}
            {doesUserFollowSheet ? 'Unfollow' : 'Follow'}
          </BasicButton>
        ) : (
          <div></div>
        )}
        {sheetId && doesUserOwnSheet ? <ShareSheetModalButton sheetId={sheetId} /> : undefined}
      </div>
      <div>
        {sheet && !sheetQuery.isLoading && !isPosting && spaceAboveTable ? (
          <EditableTanstackTable
            createChart={props.createChart}
            indicationData={props.indicationData}
            tableKey={sheetId.toString()}
            defaultFiltersExtensionMap={initialFiltersExtensionMap}
            allowFilterByListEntity={sheet?.entityType === 'COMPANY' ? 'company' : 'fundingRound'}
            fullPage
            isLoading={sheetQuery.isLoading || props.isLoading}
            getRowId={props.getRowId}
            createFloatingFooterButtons={props.createFloatingFooterButtons}
            paginate={props.paginate}
            borders="top"
            isSavingFollow={isSavingFollow}
            canSave={doesUserOwnSheet || isEditor}
            disabledSave={doesUserOwnSheet || isEditor ? false : true}
            confirmOnLeave
            defaultGlobalSearchString={sheet?.searchString ?? ''}
            isSaving={isSaving}
            stickyHeader
            maxHeightStyle={`calc(100vh - ${spaceAboveTable.toString()}px)`}
            maxHeight={`h-[calc(100vh - ${spaceAboveTable.toString()}px)]`}
            columns={sizedColumns}
            data={props.data}
            sortingFns={props.sortingFns}
            filterFns={props.filterFns}
            defaultSorting={{
              id: sheet?.sortField ?? '',
              desc: !!sheet?.sortDesc,
            }}
            defaultFilters={defaultFilters}
            defaultColumnOrder={(sheet?.columns ?? [])
              .sort((a: RouterColumn, b: RouterColumn) => a.order - b.order)
              .map((col: RouterColumn) => col.field)}
            defaultColumnVisibility={(sheet?.columns ?? []).reduce(
              (acc: { [field: string]: boolean }, col: RouterColumn) => {
                acc[col.field] = !col.isHidden
                return acc
              },
              {} as { [field: string]: boolean },
            )}
            bgColor="bg-[#FAF9FF]"
            onSave={
              sheetId === props.defaultSheetId
                ? undefined
                : async (columnVis, columnOrder, sortingState, filterGroups, searchString, columnSizes) => {
                    if (!sheet) {
                      return
                    }
                    for (const column of props.columns) {
                      let col = (sheet?.columns ?? []).find((col: RouterColumn) => col.field === column.id)
                      if (!col && column.id) {
                        await postColumnMutation.mutateAsync({
                          field: column.id,
                          isHidden: !columnVis[column.id ?? ''],
                          order: columnOrder.indexOf(column.id ?? ''),
                          sheetId: sheet.id,
                          size: columnSizes[column.id ?? ''],
                        })
                      } else if (col) {
                        let newCol = {
                          ...col,
                          isHidden: !columnVis[column.id ?? ''],
                          order: columnOrder.indexOf(column.id ?? ''),
                          size: columnSizes[column.id ?? ''],
                        }
                        await patchColumnMutation.mutateAsync({ id: newCol.id, data: newCol })
                      }
                    }

                    await patchSheetMutation.mutateAsync({
                      id: sheet.id,
                      data: {
                        sortField: sortingState.id,
                        sortDesc: sortingState.desc,
                        searchString: searchString,
                      },
                    })

                    for (const filterGroup of filterGroups) {
                      let existingGroupId = sheet.filterGroups.find(
                        (group: RouterFilterGroup) =>
                          group.filters.filter((filter: RouterFilter) => filterGroup.find((f) => f.id === filter.id))
                            .length > 0,
                      )?.id
                      if (!existingGroupId) {
                        let response = await postFilterGroupMutation.mutateAsync({
                          sheetId: sheet.id,
                        })
                        existingGroupId = response.id
                      }
                      for (const filter of filterGroup) {
                        putFilterMutation.mutateAsync({
                          columnFilterGroupId: existingGroupId,
                          id: filter.id,
                          field: filter.field,
                          operatorType: filter.operatorType,
                          value: filter.value,
                        })
                      }
                    }
                    let allFilters = filterGroups.flat()
                    for (const filterGroup of sheet.filterGroups) {
                      let filterGroupStillExists = false
                      for (const filter of filterGroup.filters) {
                        if (allFilters.find((f) => f.id === filter.id)) {
                          filterGroupStillExists = true
                        } else {
                          await deleteFilterMutation.mutateAsync(filter.id)
                        }
                      }
                      if (!filterGroupStillExists) {
                        await deleteFilterGroupMutation.mutateAsync(filterGroup.id)
                      }
                    }
                    sheetQuery.refetch()
                  }
            }
            onRowCreate={props.onRowCreate}
            onCreate={async (
              columnVis,
              columnOrder,
              sortingState,
              filterGroups,
              tableName,
              searchString,
              columnSizes,
            ) => {
              setIsPosting(true)
              let newSheet = await postSheetMutation.mutateAsync({
                name: tableName,
                sortField: sortingState.id,
                sortDesc: sortingState.desc,
                searchString: searchString,
                entityType: sheet?.entityType ?? 'COMPANY',
              })
              await allSheetsQuery.refetch()
              queryClient.invalidateQueries(userQueryKey)
              await Promise.all(
                props.columns.map(async (column) => {
                  if (column.id) {
                    return await postColumnMutation.mutateAsync({
                      field: column.id,
                      isHidden: !columnVis[column.id ?? ''],
                      order: columnOrder.indexOf(column.id ?? ''),
                      sheetId: newSheet.id,
                      size: columnSizes[column.id ?? ''],
                    })
                  }
                }),
              )
              await Promise.all(
                filterGroups.map(async (filterGroup) => {
                  let response = await postFilterGroupMutation.mutateAsync({
                    sheetId: newSheet.id,
                  })
                  for (const filter of filterGroup) {
                    return putFilterMutation.mutateAsync({
                      columnFilterGroupId: response.id,
                      field: filter.field,
                      operatorType: filter.operatorType,
                      value: filter.value,
                      id: uuid(),
                    })
                  }
                }),
              )
              setIsPosting(false)
              props.onNavigatePush?.(newSheet.id)
            }}
          />
        ) : (
          <div className="flex h-[calc(100vh)] w-full items-center justify-center">
            <IonItem>
              <IonSpinner name="lines-sharp"></IonSpinner>
            </IonItem>
          </div>
        )}
      </div>
    </div>
  )
}

export function EditNameModalButton(props: {
  onSaveSheet: (sheetName: string) => void
  defaultSheetName?: string
  disabled?: boolean
}) {
  let [isOpen, setIsOpen] = useState(false)
  let [sheetName, setSheetName] = useState(props.defaultSheetName ?? '')
  useEffect(() => {
    setSheetName(props.defaultSheetName ?? '')
  }, [props.defaultSheetName])

  return (
    <>
      <div
        className={
          'cursor-pointer rounded border p-1 text-sm hover:bg-gray-100' +
          (props.disabled ? ' cursor-none opacity-50' : '')
        }
        onClick={() => {
          if (!props.disabled) {
            setIsOpen(true)
          }
        }}>
        <Pencil1Icon className="h-[15px]" />
      </div>
      <AppModal
        open={isOpen}
        onClose={() => {
          setIsOpen(false)
        }}>
        <div className="flex min-w-[750px] flex-col gap-4 p-4">
          <div className="font-sm text-lg">Edit Sheet Name</div>
          <AppTextField
            value={sheetName}
            onChange={(e) => setSheetName(e.target.value)}
            placeholder="Sheet Name"
            fullWidth
          />
          <Button
            className="ml-auto w-[125px]"
            onClick={() => {
              props.onSaveSheet(sheetName)
              setIsOpen(false)
              setSheetName('')
            }}>
            Save
          </Button>
        </div>
      </AppModal>
    </>
  )
}
