
export default function AcceptableUsePolicy() {
  return (
    <div className="mb-24 mt-24 flex pl-2 pr-2 lg:pl-16 lg:pr-16 items-start flex-wrap flex-col lg:flex-row">
      <aside className="lg:w-1/4 w-full p-6 lg:sticky lg:top-[20vh]">
        <nav className="lg:border-r pr-4">
          <ul>
            <li className="mb-2">
              <a href="/terms" className="block py-2 px-4 rounded-lg hover:bg-slate-100 font-medium">Terms of Use</a>
            </li>
            <li className="mb-2">
              <a href="/privacy" className="block py-2 px-4 rounded-lg hover:bg-slate-100 font-medium">Privacy Policy</a>
            </li>
            <li className="mb-2">
              <a href="/subprocessors" className="block py-2 px-4 rounded-lg hover:bg-slate-100 font-medium">Sub-processors</a>
            </li>
            <li className="mb-2">
              <a href="/terms/ai" className="block py-2 px-4 rounded-lg hover:bg-slate-100 font-medium">AI Terms</a>
            </li>
            <li className="mb-2">
              <a href="/aup" className="block py-2 px-4 rounded-lg hover:bg-slate-100 font-medium">Acceptable Use Policy</a>
            </li>
          </ul>
        </nav>
      </aside>

      <main className="lg:w-3/4 w-full p-6">
        <h1 className="text-3xl font-bold mb-8">Acceptable Use Policy</h1>
        <section className="mb-10">
          <p className="mb-4">
            This Acceptable Use Policy governs you or your organization's (collectively, “you” or “your”) use of anything that Massive Tech, Inc. (“Plural”), makes available, including its online software-as-a-service products and services, downloadable software, websites, agents, SDKs, APIs, and other code (collectively, the “Services”), and describes acceptable and unacceptable conduct for the Services. If you violate this policy, or if you encourage, allow, or assist others to violate this policy, Plural may suspend or terminate your access to the Services. This policy may change as Plural grows and evolves, so please check back regularly for updates and modifications to this page. Capitalized terms used but not defined in this policy have the meaning set forth (for such term or its substantive equivalent) in the agreement(s) between you and Plural that governs your use of the Services, such as Plural’s Terms of Service or Master Subscription Agreement, the Plural AI Terms, or another applicable master subscription agreement (“Service Terms”).
          </p>
          <h2 className="text-xl font-bold mb-6">1. General Usage Restrictions</h2>
          <p className="mb-4">
            Except as Plural otherwise agrees in writing or to the extent a restriction is prohibited by law, you agree not to do, and not to assist, permit, or enable Plural, any third party, or any Extension to do, any of the following:
          </p>
          <ul className="list-disc ml-6">
            <li><p className="mb-2">
              disassemble, reverse engineer, decode, or decompile any part of the Services;
            </p></li>
            <li><p className="mb-2">
              use any robot, spider, scraper, data mining tool, data gathering or extraction tool, or any other automated means, to access, collect, copy, or record the Services;
            </p></li>
            <li><p className="mb-2">
              copy, rent, lease, sell, loan, transfer, assign, sublicense, resell, distribute, modify, alter, or create derivative works of any part of Plural or any of Plural’s intellectual property;
            </p></li>
            <li><p className="mb-2">
              use the Services in a manner that impacts the stability of Plural’s servers, the operation or performance of the Services or any other user’s use of the Services, or the behavior of other applications using the Services;
            </p></li>
            <li><p className="mb-2">
              use the Services (including, without limitation, by uploading to the Services, storing on the Services, using the Services to generate, using information or data generated through the Services, or otherwise providing Plural with access to any information, data, or other content) in any manner or for any purpose that: (i) infringes, violates, or promotes the infringement or violation of any applicable law, regulation, legal requirement, contractual obligation, or right of any person or entity, including intellectual property rights, rights of privacy, or rights of personality; (ii) is fraudulent, false, deceptive, or defamatory, including phishing; (iii) promotes hatred, violence, or harm against any individual or group; or (iv) otherwise may be harmful, obscene, or objectionable to Plural, its providers, its suppliers, its users, or others;
            </p></li>
            <li><p className="mb-2">
              overload, flood, spam, or mail-bomb the Services; or otherwise use the Services in a manner that interferes with or creates an undue burden on the Services, including by using the Services to send unsolicited communications, promotions, advertisements, or spam;
            </p></li>
            <li><p className="mb-2">
              use or display the Services in competition with us, to develop competing products or services, for benchmarking or competitive analysis of Plural or the Services, or otherwise to Plural’s detriment or disadvantage;
            </p></li>
            <li><p className="mb-2">
              attempt to interfere with, compromise the system integrity or security of, or decipher any transmissions to or from the servers running the Services;
            </p></li>
            <li><p className="mb-2">
              generate or transmit viruses, worms, malware, or other software agents or harmful code through the Services;
            </p></li>
            <li><p className="mb-2">
              impersonate another person or misrepresent your affiliation with a person or entity, hide or attempt to hide your identity, or otherwise use the Services for any invasive or fraudulent purpose, including phishing;
            </p></li>
            <li><p className="mb-2">
              use or attempt to use a log-in credential other than your own, share passwords or authentication credentials for the Services, or otherwise circumvent Plural’s pricing, Plural’s fees, or the measures Plural may use to prevent or restrict access to the Services, to enforce limitations on use of the Services, or to charge fees for each user of the Services; or
            </p></li>
            <li><p className="mb-2">
              identify or refer to Plural or the Services in a manner that could reasonably imply an endorsement by Plural, or a relationship or affiliation between you or a third party and Plural, other than your permitted use of the Services under your Service Terms, without Plural’s express written consent.
            </p></li>
          </ul>
        </section>
        <section className="mb-10">
          <h2 className="text-xl font-bold mb-6">2. Plural AI Usage Restrictions</h2>
          <p className="mb-4">
            In addition to complying with the General Usage Restrictions above, if you use Plural AI: (i) you agree to comply with, and you will require each of your individual users (e.g., Permitted Users) to comply with, the applicable Third-Party AI Provider terms and policies available at <a href="https://withplural.com/terms/ai" className="text-blue-600">https://withplural.com/terms/ai</a>, as updated from time to time; (ii) you must be at least 18 years old; and (iii) you agree not to do, and not to assist, permit, or enable Plural, any third party, or any Extension to do, any of the following:
          </p>
          <ul className="list-disc ml-6">
            <li><p className="mb-2">
              attempt to gain unauthorized access to Plural AI or its related systems or networks;
            </p></li>
            <li><p className="mb-2">
              access Plural AI to build a competitive product or service, including foundation models or other large scale models;
            </p></li>
            <li><p className="mb-2">
              include in any Input or attempt to generate Output containing: (i) information under regulatory or contractual handling requirements (e.g., Payment Card Industry Data Security Standards); (ii) government issued personal identifiers; (iii) biometric identifiers; (iv) health information subject to the Health Insurance Portability and Accountability Act of 1996 (HIPAA) or similar regulations; or (v) any data constituting “sensitive personal information,” “sensitive data,” “special categories of data”, or similar terms defined under applicable data protection laws;
            </p></li>
            <li><p className="mb-2">
              use Plural AI or Output in, or in association with, the operation of any hazardous environments or critical systems that may lead to serious bodily injury or death or cause environmental or property damage;
            </p></li>
            <li><p className="mb-2">
              use internet search or browse functionality included in Plural AI: (i) to collect nonpublic information you do not otherwise have the right to collect; (ii) to engage or participate in DDoS attacks; (iii) for automatic ticket purchasing; (iv) in connection with raffles, lottery, or gambling; (v) for gaming or trading of in-game items/currency; (vi) to access media streaming- related domains; (vii) to trade crypto/virtual currency or NFT; (viii) for search engine optimization manipulation; (ix) to post on classified, used goods, or resell sites; or (x) to complete surveys in return for a benefit or anything of value;
            </p></li>
            <li><p className="mb-2">
              use Plural AI for clinical purposes (for clarity, non-clinical research, scheduling, or other administrative tasks are not restricted), as a substitute for professional medical advice, or in any manner that is overseen by or requires clearance or approval from any applicable regulatory authority;
            </p></li>
            <li><p className="mb-2">
              use Plural AI: (i) for mass surveillance, racial profiling, or any use that violates or encourages the violation of basic human rights or other applicable laws and regulations; (ii) to provide fully automated decision making in connection with use cases involving critical processes or the risk of loss of life, property or impact on an individual’s legal rights; (iii) in a manner that impersonates another for deceptive purposes or conceals the fact that a user is interacting with AI; (iv) to send unsolicited, abusive, or deceptive messages of any type; (v) to host or distribute harmful, misleading, disparaging, or offensive content; or (vi) to disrupt or gain (or with the intent to disrupt or gain) unauthorized access to data, services, networks, or computing environments within or external to Plural AI; or
            </p></li>
            <li><p className="mb-2">
              engage in any use of Plural AI that is prohibited or considered “high-risk” under the EU AI Act or any other law or regulation applicable to your use of the Services.
            </p></li>
          </ul>
        </section>
      </main>
    </div>
  )
}
