import { useEffect, useState } from 'react'
import { useLocation, useNavigationType } from 'react-router-dom'
export function useIsSmallScreen(variant: 'sm' | 'md' | undefined = 'sm') {
  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  })

  useEffect(() => {
    const handleResize = () => {
      setScreenSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  if (variant === 'md') {
    return screenSize.width < 1100 || screenSize.height < 650
  }

  return screenSize.width < 768 || screenSize.height < 600
}

export function useHasPreviousPage() {
  const [hasPreviousPage, setHasPreviousPage] = useState(false)
  const location = useLocation()
  const navigationType = useNavigationType()

  useEffect(() => {
    if (window.history.state && window.history.state.idx > 0) {
      setHasPreviousPage(true)
    } else {
      setHasPreviousPage(false)
    }
  }, [location, navigationType])

  return hasPreviousPage
}
