import { Line, LineChart, ResponsiveContainer, XAxis, YAxis } from 'recharts'

function getDateYearsAndMonthsAgo(yearsAgo: number, monthsAgo: number): Date {
  const currentDate = new Date()
  const targetYear = currentDate.getFullYear() - yearsAgo
  const targetMonth = currentDate.getMonth() - monthsAgo
  const targetDate = new Date(currentDate.setFullYear(targetYear))
  targetDate.setMonth(targetMonth)
  return targetDate
}

export default function SparklineChart({
  scatterData,
  color = 'black',
  untilToday = false,
  linear = true,
  continueLine = false,
  timeAgo,
}: {
  scatterData: {
    data: {
      x: Date
      y: number
    }[]
    label: string
    stroke?: string
    fill?: string
  }[]
  color?: 'red' | 'green' | 'black'
  untilToday?: boolean
  linear?: boolean
  continueLine?: boolean
  timeAgo?: { years: number; months: number }
}) {
  let colorString = color === 'green' ? '#22C55E' : color
  const dates = scatterData.flatMap((ds) => ds.data.map((point) => point.x.getTime())) // Collect all date timestamps
  const minDate = Math.min(...dates)
  const maxDate = Math.max(...dates)
  let maxValue = Math.max(...scatterData.flatMap((ds) => ds.data.map((point) => point.y)))
  let minValue = Math.min(...scatterData.flatMap((ds) => ds.data.map((point) => point.y)))
  let lengthOfXAxis = maxDate - minDate
  let yAxisBoundaries = [minValue, Math.ceil((maxValue * 1.1) / 10) * 10]
  let xAxisBoundaries = [
    minDate - lengthOfXAxis * 0.05,
    untilToday ? new Date().getTime() : maxDate + lengthOfXAxis * 0.05,
  ]

  let specifiedTimeAgo = timeAgo ? getDateYearsAndMonthsAgo(timeAgo.years, timeAgo.months) : undefined

  if (specifiedTimeAgo) {
    xAxisBoundaries[0] = specifiedTimeAgo.getTime()
  }

  return (
    <div className={'max-w-[60px]'}>
      <ResponsiveContainer width="100%" height={30}>
        <LineChart height={30}>
          <XAxis
            allowDataOverflow={true}
            dataKey="x"
            type="number"
            domain={xAxisBoundaries}
            axisLine={false}
            display="none"
            width={0}
            height={0}
          />
          <YAxis dataKey="y" domain={yAxisBoundaries} axisLine={false} display="none" width={0} />
          {scatterData
            .filter((ds) => ds.data.length)
            .map((ds, index) => {
              const lastPoint = ds.data.sort((a, b) => a.x.getTime() - b.x.getTime())[ds.data.length - 1]
              const lastX = lastPoint.x.getTime()
              const lastY = lastPoint.y
              return (
                <>
                  <Line
                    key={ds.label}
                    type={linear ? 'linear' : 'monotone'}
                    width={20}
                    data={ds.data}
                    dataKey="y"
                    name={ds.label}
                    stroke={colorString}
                    dot={false}
                    isAnimationActive={false}
                  />
                  {continueLine && (
                    <Line
                      isAnimationActive={false}
                      key={`${ds.label}-dotted`}
                      tooltipType="none"
                      dataKey="y"
                      type="linear"
                      data={[
                        { x: lastX, y: lastY },
                        { x: xAxisBoundaries[1] - 10, y: lastY },
                      ]}
                      stroke={colorString}
                      strokeDasharray="3 3"
                      dot={false}></Line>
                  )}
                </>
              )
            })}
        </LineChart>
      </ResponsiveContainer>
    </div>
  )
}
