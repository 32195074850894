import { calculateCAGR } from "plural-shared/utils";
import {
  IrrDistributionGenerator,
  IrrDistributionGraph,
  IrrDistributionGraphData,
} from "../IrrDistributionGenerator";
import { defaultLPInterestFundSettings } from "./defaultLPInterestFundSettings";
import { defaultLPInterestMultipleRangeDistribution } from "./defaultLPInterestMultipleRangeDistribution";
import { v2DealIrrforMultiple } from "../v2DealIrrforMultiple";
import { FundSimulationOutcome } from "../FundSimulationOutcome";

export interface LPInterestSimulationResult {
  numIndivDealsPerDealSim: number;
  equityDealIrrGraphData: IrrDistributionGraphData;
  pluralDealIrrGraphData: IrrDistributionGraphData;
  yearsToLiquidity: number;
}

export interface LPInterestFundSimulationResult {
  numFundsPerFundSim: number;
  grossFundIrrGraphData: IrrDistributionGraphData;
  netFundIrrGraphData: IrrDistributionGraphData;
  yearsToLiquidity: number;
  grossFundIrrMetrics: {
    median: number;
    mean: number;
    range: number;
    lowerQuartile: number;
    upperQuartile: number;
  };
  medianNetFundIrr: number;
}

export function makeIndivLPInterestSimulation(): LPInterestSimulationResult {
  const companyMultiplesForIndivDistros =
    defaultLPInterestMultipleRangeDistribution.randomizedOutcomeMultiples();

  const equityIrrGenerator = (
    companyMultiples: number[],
    index: number
  ): number => {
    let companyMultiple = companyMultiples[index];
    let irr = calculateCAGR({
      returnMultiple: companyMultiple,
      years: defaultLPInterestFundSettings.yearsToLiquidity,
    });
    return irr;
  };

  const singleDealIrrGenerator = (
    companyMultiples: number[],
    index: number
  ): number => {
    let companyMultiple = companyMultiples[index];
    let { yearsToLiquidity, reserveRatio, fundMinCagr } =
      defaultLPInterestFundSettings;
    return v2DealIrrforMultiple(
      companyMultiple,
      yearsToLiquidity,
      reserveRatio,
      fundMinCagr
    );
  };

  let equityDealIrrs = new IrrDistributionGenerator(
    companyMultiplesForIndivDistros,
    equityIrrGenerator
  );
  let pluralDealIrrs = new IrrDistributionGenerator(
    companyMultiplesForIndivDistros,
    singleDealIrrGenerator
  );
  let indivRanges = [0.1, 0.15, 0.2, 0.25];
  equityDealIrrs.makeGroupedFrequenciesWithCustomRanges(indivRanges);
  pluralDealIrrs.makeGroupedFrequenciesWithCustomRanges(indivRanges);

  return {
    numIndivDealsPerDealSim:
      defaultLPInterestMultipleRangeDistribution.numDeals,
    equityDealIrrGraphData: new IrrDistributionGraph(
      equityDealIrrs.groupedIrrFrequencies,
      0.6
    ).graphData(),
    pluralDealIrrGraphData: new IrrDistributionGraph(
      pluralDealIrrs.groupedIrrFrequencies,
      0.6
    ).graphData(),
    yearsToLiquidity: defaultLPInterestFundSettings.yearsToLiquidity,
  };
}

export function makeLPInterestFundSimulation(): LPInterestFundSimulationResult {
  const makeFundDistSimulationOutcomes = (
    numFunds: number
  ): FundSimulationOutcome[] => {
    let fundSimulationOutcomes = [];
    for (let i = 0; i < numFunds; i++) {
      let outcomeCompanyMultiples =
        defaultLPInterestMultipleRangeDistribution.randomizedOutcomeMultiples();
      let simulationOutcome = new FundSimulationOutcome(
        outcomeCompanyMultiples,
        defaultLPInterestFundSettings
      );
      fundSimulationOutcomes.push(simulationOutcome);
    }
    return fundSimulationOutcomes;
  };

  const numFundsPerFundSim = 15;
  const fundSimsForFundDistros =
    makeFundDistSimulationOutcomes(numFundsPerFundSim);

  let fundGrossIrrGenerator = (
    materials: FundSimulationOutcome[],
    index: number
  ): number => {
    return materials[index].fundGrossIRR();
  };
  let fundNetIrrGenerator = (
    materials: FundSimulationOutcome[],
    index: number
  ): number => {
    return materials[index].fundNetIRR();
  };

  const numGroups = 6;
  let grossFundIrrs = new IrrDistributionGenerator(
    fundSimsForFundDistros,
    fundGrossIrrGenerator
  );
  let netFundIrrs = new IrrDistributionGenerator(
    fundSimsForFundDistros,
    fundNetIrrGenerator
  );
  let fundRanges = [0.18, 0.2, 0.22, 0.24, 0.26];
  grossFundIrrs.makeGroupedFrequenciesWithCustomRanges(fundRanges);

  let grossFundIrrMetrics = {
    median: grossFundIrrs.medianIrr(),
    mean: grossFundIrrs.meanIrr(),
    range: grossFundIrrs.irrRange(),
    lowerQuartile: grossFundIrrs.firstQuartileIrr(),
    upperQuartile: grossFundIrrs.thirdQuartileIrr(),
  };
  let medianNetFundIrr = netFundIrrs.medianIrr();

  return {
    numFundsPerFundSim,
    grossFundIrrGraphData: new IrrDistributionGraph(
      grossFundIrrs.groupedIrrFrequencies,
      0.7
    ).graphData(),
    netFundIrrGraphData: new IrrDistributionGraph(
      netFundIrrs.groupedIrrFrequencies,
      0.7
    ).graphData(),
    grossFundIrrMetrics,
    yearsToLiquidity: defaultLPInterestFundSettings.yearsToLiquidity,
    medianNetFundIrr,
  };
}
