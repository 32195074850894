export function dateToShorthandMonthYearString(date: Date): string {
  const monthNames = [
    "Jan.",
    "Feb.",
    "March",
    "April",
    "May",
    "June",
    "July",
    "Aug.",
    "Sept.",
    "Oct.",
    "Nov.",
    "Dec.",
  ];
  const month = monthNames[date.getMonth()];
  const year = date.getFullYear().toString().slice(2);
  return `${month} '${year}`;
}
