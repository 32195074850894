// AuthContext.tsx
import { LogoutOptions, RedirectLoginOptions, useAuth0 } from '@auth0/auth0-react'
import { createContext, useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { isNone, Optional } from 'safety'
import { AuthStatus } from '../utils/authStatus'
import { getHasViewedAllowlistNotice } from '../utils/getHasViewedAllowlistNotice'
import { RouterOutputs } from '../utils/trpc'
import { useAccessToken } from './AccessTokenProvider'
import { useVanillaTRPC } from './TRPCProvider'

type PluralUser = RouterOutputs['user']['getOrCreate']['user']

interface PluralAuthContextProps {
  authStatus: AuthStatus
  isLoading: boolean
  possiblyRejectedUserEmail: Optional<string>
  loginWithRedirect: (options?: RedirectLoginOptions | undefined) => Promise<void>
  logout: (options?: LogoutOptions | undefined) => Promise<void>
  numAuthAttempts: number
  user: Optional<PluralUser>
}

const AuthContext = createContext<PluralAuthContextProps>({
  authStatus: AuthStatus.None,
  isLoading: true,
  possiblyRejectedUserEmail: undefined,
  loginWithRedirect: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  numAuthAttempts: 0,
  user: undefined,
})

export default function PluralAuthProvider({ children }: any) {
  const {
    isAuthenticated: isAuth0Authenticated,
    user: auth0User,
    isLoading: isAuth0Loading,
    loginWithRedirect,
    logout,
  } = useAuth0()
  const [authStatus, setAuthStatus] = useState(AuthStatus.None)
  const [isPluralLoading, setIsPluralLoading] = useState(true)
  const [numAuthAttempts, setNumAuthAttempts] = useState(0)
  const [user, setUser] = useState<Optional<PluralUser>>(undefined)
  const vanillaTRPC = useVanillaTRPC()
  const accessToken = useAccessToken()
  const userEmail = auth0User?.email
  const navigate = useNavigate()
  const location = useLocation()

  function isUserMissingPersonalInfo(user: PluralUser | undefined) {
    return user?.firstName === null || user?.lastName === null
  }

  useEffect(() => {
    // we won't try to do highnote auth if there's no user, so kill the loading
    if (isNone(auth0User) && !isAuth0Loading) {
      setIsPluralLoading(false)
    }
  }, [auth0User, isAuth0Loading])

  useEffect(() => {
    // protects from running until able to use DB
    // this will only get callled after auth0 already logged you in
    if (!isAuth0Authenticated || isAuth0Loading || !accessToken || !userEmail) {
      return
    }
    setIsPluralLoading(true)
    vanillaTRPC.user.getOrCreate
      .mutate({
        userEmail,
      })
      .then((userOutput) => {
        if (userOutput.isAllowed) {
          if (isUserMissingPersonalInfo(userOutput.user) && !(location.pathname === '/onboarding')) {
            navigate('/onboarding', { state: { returnTo: location.pathname } })
          }
          setAuthStatus(AuthStatus.Allowed)
          setUser(userOutput.user)
        } else {
          const hasViewedAllowlistNotice = getHasViewedAllowlistNotice()
          if (!hasViewedAllowlistNotice && !(location.pathname === '/no-allowlist')) {
            navigate('/no-allowlist', { state: { returnTo: location.pathname } })
          }
          setAuthStatus(AuthStatus.NoAllowlist)
          setUser(undefined)
        }
        setIsPluralLoading(false)
        setNumAuthAttempts((prev) => prev + 1)
      })
      .catch((err) => {
        console.log('err in plural auth: ', err)
        setUser(undefined)
        setIsPluralLoading(false)
        setAuthStatus(AuthStatus.None)
        setNumAuthAttempts((prev) => prev + 1)
      })
  }, [isAuth0Authenticated, vanillaTRPC, isAuth0Loading, accessToken, userEmail, navigate])

  return (
    <AuthContext.Provider
      value={{
        authStatus: authStatus,
        isLoading: isPluralLoading || isAuth0Loading,
        loginWithRedirect: loginWithRedirect,
        logout: logout,
        possiblyRejectedUserEmail: userEmail,
        numAuthAttempts: numAuthAttempts,
        user: user,
      }}>
      {children}
    </AuthContext.Provider>
  )
}

export const usePluralAuth = () => useContext(AuthContext)
