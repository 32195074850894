import { TextField } from '@radix-ui/themes'
import { useCallback, useEffect, useState } from 'react'
import { BasicButton } from '../../components/BasicButton'
import { usePluralAuth } from '../../providers/PluralAuthProvider'
import { useVanillaTRPC } from '../../providers/TRPCProvider'
import { trpc } from '../../utils/trpc'

function TextInput({
  value,
  setter,
  label,
  placeholder,
}: {
  value: string
  setter: (value: string) => void
  label: string
  placeholder?: string
}) {
  return (
    <div className="mt-2 w-full">
      <label className="text-sm opacity-70">{label}</label>
      <TextField.Root>
        <TextField.Input placeholder={placeholder ?? ''} value={value} onChange={(e) => setter(e.target.value)} />
      </TextField.Root>
    </div>
  )
}

export default function Allowlist() {
  const [email, setEmail] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [message, setMessage] = useState('')
  const vanillaClient = useVanillaTRPC()

  const { user, isLoading } = usePluralAuth()
  const allowlistManipulatorsQuery = trpc.allowlist.allowlistManipulatorEmails.useQuery()

  const allowlistManipulatorEmails = allowlistManipulatorsQuery.data ?? []
  const isManipulator = allowlistManipulatorEmails.some((manipulator) => manipulator === user?.email)

  const sumbitAllowlist = useCallback(async () => {
    if (email.length === 0) {
      setMessage('Email is required')
      return
    }
    vanillaClient.allowlist.addUser
      .mutate({
        email,
        firstName,
        lastName,
      })
      .then(() => {
        setMessage('User added to allowlist. They will receive an email to sign up.')
        setEmail('')
        setFirstName('')
        setLastName('')
      })
  }, [email, firstName, lastName, vanillaClient])

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        sumbitAllowlist()
      }
    }

    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [sumbitAllowlist]) // Empty array ensures this effect runs only once on mount

  if (isLoading || allowlistManipulatorsQuery.isLoading) return null

  return (
    <div className="flex flex-col items-center">
      <div>
        <div className="mt-8 text-base font-medium">
          Allowlist
          <br /> Editor
        </div>
        {isManipulator ? (
          <div className="mt-6 w-72">
            <TextInput value={email} setter={setEmail} label="Email" />
            <TextInput value={firstName} setter={setFirstName} label="First Name (opt.)" />
            <TextInput value={lastName} setter={setLastName} label="Last Name (opt.)" />
            <BasicButton variant="secondary" onClick={sumbitAllowlist} isLoading={false} className="mt-4 text-sm">
              Submit
            </BasicButton>
            <div className="mt-4 text-sm">{message}</div>
          </div>
        ) : (
          <div className="mt-8">sign in with an appropriate account to edit the allowlist</div>
        )}
      </div>
    </div>
  )
}
