import { calculateCAGR } from "../../utils";
import {
  TargetMultipleGeneratorInputs,
  TargetMultipleOutcomeGenerator,
} from "./TargetMultipleOutcomeGenerator";
import {
  ValueShareSecondaryComparableTxGeneratorInputs,
  ValueShareTxWithSecondaryComparable,
} from "./ValueShareSecondaryComparableTxGenerator";

export interface ValueShareCashAdjustedTxPriceLineDatapoint {
  companyOutcomeValuation: number;
  cashAdjustedSharePrice: number;
}

export class ValueShareCashAdjustedTxPriceLineGenerator {
  private inputs: ValueShareSecondaryComparableTxGeneratorInputs;
  private datapoints: ValueShareCashAdjustedTxPriceLineDatapoint[] = [];

  constructor(inputs: ValueShareSecondaryComparableTxGeneratorInputs) {
    this.inputs = inputs;
  }

  centerMultiple(): number {
    let generatorInputs: TargetMultipleGeneratorInputs = {
      ...this.inputs,
      reserveRatio: this.inputs.reserveRatio,
      yearsToOutcome: this.inputs.yearsToOutcome,
    };
    let generator = new TargetMultipleOutcomeGenerator(generatorInputs);
    let multipleRequired =
      generator.companyMultipleRequiredForTargetCashAdjustedTxMultiple(1.0);
    return multipleRequired;
  }

  generateDatapoints(
    multiples: number[]
  ): ValueShareCashAdjustedTxPriceLineDatapoint[] {
    let cashAdjustedTxSharePrices = multiples.map((mult) => {
      let cagrFromBase = calculateCAGR({
        returnMultiple: mult,
        years: this.inputs.yearsToOutcome,
      });
      let generator = new ValueShareTxWithSecondaryComparable({
        ...this.inputs,
        companyCagrFromBase: cagrFromBase,
      });
      return generator.cashAdjustedTxSharePrice();
    });
    let companyOutcomeValuations = multiples.map((mult) => {
      return (
        mult * this.inputs.baseSharePrice * this.inputs.companySharesOutstanding
      );
    });
    this.datapoints = multiples.map((_, i) => {
      return {
        companyOutcomeValuation: companyOutcomeValuations[i],
        cashAdjustedSharePrice: cashAdjustedTxSharePrices[i],
      };
    });
    return this.datapoints;
  }
}
