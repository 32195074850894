import { Cross1Icon } from '@radix-ui/react-icons'
import { createContext, PropsWithChildren, useContext } from 'react'
import ReactDOM from 'react-dom'

const CloseContext = createContext<(() => void) | undefined>(undefined)

export type AppModalProps = PropsWithChildren<{
  open: boolean
  onClose?: () => void
  noPortal?: boolean
  fullWidth?: boolean
  maxWidth?: number
}>
export default function AppModal(props: AppModalProps) {
  return props.open ? (
    props.noPortal ? (
      <CloseContext.Provider value={props.onClose}>
        <div
          className="fixed bottom-0 left-0 right-0 top-0 z-50 flex h-full w-full items-center justify-center bg-black bg-opacity-50"
          onClick={(e) => {
            e.stopPropagation()
            props.onClose && props.onClose()
          }}>
          <div
            className={`${props.fullWidth ? 'w-full' : ''} flex max-w-[${props.maxWidth ? props.maxWidth : 850}px] flex-col overflow-hidden rounded bg-white`}
            onClick={(e) => {
              e.stopPropagation()
            }}>
            {props.children}
          </div>
        </div>
      </CloseContext.Provider>
    ) : (
      ReactDOM.createPortal(
        <CloseContext.Provider value={props.onClose}>
          <div
            className="fixed bottom-0 left-0 right-0 top-0 z-50 flex h-full w-full items-center justify-center bg-black bg-opacity-50"
            onClick={(e) => {
              e.stopPropagation()
              props.onClose && props.onClose()
            }}>
            <div
              className={`${props.fullWidth ? 'w-full' : ''} flex max-w-[${props.maxWidth ? props.maxWidth : 850}px] flex-col overflow-hidden rounded bg-white`}
              onClick={(e) => {
                e.stopPropagation()
              }}>
              {props.children}
            </div>
          </div>
        </CloseContext.Provider>,
        document.body,
      )
    )
  ) : (
    <></>
  )
}

function AppModalHeader(props: PropsWithChildren<{}>) {
  const onClose = useContext(CloseContext)
  const isText = typeof props.children === 'string'
  return (
    <div className="flex w-full flex-row items-center justify-between px-5 py-4">
      {isText ? <div className="text-large font-medium">{props.children} </div> : props.children}
      {onClose && (
        <button className="cursor-pointer border-none outline-none" onClick={onClose}>
          <Cross1Icon />
        </button>
      )}
    </div>
  )
}

function AppModalBody(props: PropsWithChildren<{}>) {
  return <div className="flex-column flex px-5 pb-5">{props.children}</div>
}
AppModal.Header = AppModalHeader
AppModal.Body = AppModalBody
