
import { Pie, PieChart, Tooltip, Cell } from 'recharts';

export type CustomTooltipProps = {
  payload?: any;
  label?: any;
  active?: boolean;
};

type TwoLayerPieChartProps = {
  chartData: {
    innerData: { name: string; value: number; color: string }[];
    outerData: { name: string; value: number; color: string }[];
  };
  customTooltip: React.FC<CustomTooltipProps>;
};

const TwoLayerPieChart: React.FC<TwoLayerPieChartProps> = ({ chartData, customTooltip }) => (
  <PieChart width={800} height={500}>
    <Pie
      data={chartData.innerData}
      dataKey="value"
      nameKey="name"
      cx="50%"
      cy="50%"
      outerRadius={200}
      labelLine={false}
      label={false}
      isAnimationActive={false}
    >
      {chartData.innerData.map((entry, index) => (
        <Cell key={`cell-${index}`} fill={entry.color} />
      ))}
    </Pie>
    <Pie
      data={chartData.outerData}
      dataKey="value"
      nameKey="name"
      cx="50%"
      cy="50%"
      innerRadius={210}
      outerRadius={250}
      labelLine={false}
      label={false}
      isAnimationActive={false}
    >
      {chartData.outerData.map((entry, index) => (
        <Cell key={`cell-${index}`} fill={entry.color} />
      ))}
    </Pie>
    <Tooltip content={customTooltip} />
  </PieChart>
);

export default TwoLayerPieChart;
