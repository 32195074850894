import { numberToPercentChangeString, numberToReadableDollarString } from 'plural-shared/utils'
import { useSimulatorContext } from './CashSimulatorContext'

export default function ValuationGraphHeader() {
  const context = useSimulatorContext()
  if (!context) {
    return null
  }
  const { pluralCashTx, txInputs } = context
  if (!pluralCashTx || !txInputs || !txInputs.secondarySharePrice.value) {
    return null
  }
  const cashAdjustedSharePrice = pluralCashTx.cashAdjustedTxSharePrice()
  const cashAdjustedSharePricePercentDeltaFromSecondary =
    cashAdjustedSharePrice / txInputs.secondarySharePrice.value - 1
  return (
    <div>
      <div className="mt-4 flex items-end text-3xl">
        <div className="font-semibold">
          {pluralCashTx ? numberToReadableDollarString(pluralCashTx.cashAdjustedTxSharePrice()) : ''}
        </div>
        <div className="mb-[2px] ml-1 text-base font-normal opacity-60">Effective PPS</div>
      </div>
      <div
        className={`text-sm font-medium ${
          Math.abs(cashAdjustedSharePricePercentDeltaFromSecondary - 0) < 0.001
            ? 'text-func-black-0'
            : cashAdjustedSharePricePercentDeltaFromSecondary < 0
              ? 'text-tickers-red'
              : 'text-tickers-green'
        }`}>
        {numberToPercentChangeString(cashAdjustedSharePricePercentDeltaFromSecondary, 1)}{' '}
        <span className="text-sm font-normal text-black opacity-60">
          from secondary at {numberToReadableDollarString(txInputs.secondarySharePrice.value)} / sh
        </span>
      </div>
    </div>
  )
}
