import { cn } from 'lib/utils'
import { HTMLAttributes } from 'react'

export default function Card({ className, ...rest }: HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      {...rest}
      className={cn(
        'relative w-full text-left rounded-lg bg-plural-sand-400 border border-plural-sand-500 p-4',
        className
      )}
    />
  )
}
